import { Directive, ElementRef, forwardRef, HostListener, inject, input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UserSettings } from '../../../../models/core';
import { UserStore } from '../../../../signal-store';
import { UtilsService } from '../../../services';

@Directive({
  selector: '[thousandSeparator]',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ThousandSeparatorDirective),
      multi: true,
    },
  ],
})
export class ThousandSeparatorDirective implements ControlValueAccessor {
  utilsService = inject(UtilsService);
  userStore = inject(UserStore);

  decimals = input<number>(null);
  thousandSeparator = input<boolean>(false);

  private el: HTMLInputElement;
  private userSettings: UserSettings;

  constructor(private elementRef: ElementRef) {
    this.el = this.elementRef.nativeElement;
    this.userSettings = this.userStore.calculatedUserSettings();
  }

  // ControlValueAccessor methods
  writeValue(value: any): void {
    this.el.value = value ? this.formatNumber(value) : value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.el.disabled = isDisabled;
  }

  @HostListener('blur')
  onBlur(): void {
    if (this.onTouched) {
      this.onTouched();
    }
  }

  // Event listener for input changes
  @HostListener('input', ['$event.target.value'])
  onInput(val: string): void {
    const endsWithDot = val.endsWith(this.userSettings?.decimalSeparator) && this.decimals();
    const value = this.utilsService
      .revertLocaleNumber(val, this.userSettings?.decimalSeparator, this.userSettings?.thousandSeparator);

    // Handle minus sign
    if (val === '-') {
      this.onChange(null);
      return;
    }

    if (value === null || isNaN(value)) {
      this.onChange(null);
      this.el.value = null;
      return;
    }

    this.onChange(value);

    const formattedValue = this.formatNumber(value);
    this.el.value = `${formattedValue}${endsWithDot ? this.userSettings?.decimalSeparator : ''}`;
  }

  // Format number with thousand separators
  private formatNumber(value: number): string {
    return this.utilsService.formatLocaleNumber(
      value,
      this.decimals(),
      this.userSettings?.decimalSeparator,
      this.userSettings?.thousandSeparator,
      this.thousandSeparator(),
    );
  }

  // Placeholder methods for ControlValueAccessor
  private onChange = (_: any) => {};
  private onTouched = () => {};
}
