import { NgModule } from '@angular/core';
import { SharedComponentsModule } from '../../../shared/modules/shared-components.module';
import { TenantSettingModalComponent } from './tenant-setting-modal/tenant-setting-modal.component';
import { TenantSettingsComponent } from './tenant-settings.component';
import { TenantSettingsService } from './tenant-settings.service';

@NgModule({
  declarations: [TenantSettingsComponent, TenantSettingModalComponent],
  imports: [SharedComponentsModule],
  exports: [TenantSettingsComponent, TenantSettingModalComponent],
  providers: [TenantSettingsService],
})
export class TenantSettingsModule {}
