import { Injectable, inject } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { TenantStore } from '../../signal-store';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  router = inject(Router);
  tenantStore = inject(TenantStore);

  constructor() {}

  tenantNavigate(commands: any[], extras?: NavigationExtras) {
    this.router.navigate([`/${this.tenantStore.selectedTenantName()}/${commands.join('/')}`], extras);
  }
}
