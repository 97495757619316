<div mat-dialog-title fxLayout fxLayoutAlign="space-between center">
    <h5>{{ "My Drive" }}</h5>
    <button mat-icon-button [mat-dialog-close]="undefined">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <app-data-list-command [hidefilter]="true" (applyfilters)="searchFiles($event)">
        <div>
            <button [disabled]="currentFolderId() === rootFolderId || isLoading()" mat-icon-button (click)="goBack()">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <button mat-icon-button (click)="addNewFolder()">
                <mat-icon>create_new_folder</mat-icon>
            </button>
            <button mat-icon-button color="primary" (click)="uploadFile()">
                <mat-icon>upload</mat-icon>
            </button>
        </div>
        <app-data-list
            class="data-list"
            [loading]="isLoading()"
            [tablecolumns]="tableColumns"
            [tabledata]="tableData()"
            [tableoptions]="tableOptions"
            (actionselected)="onAction($event)"
            (rowclicked)="selectRow($event)" />
    </app-data-list-command>
</mat-dialog-content>
<mat-dialog-actions fxLayout fxLayoutGap="12px">
    <div fxFlex>
        <!-- Can't use cipo-attachments here because it will cause a loop (cipo-attachments -> attachments-dialog -> cipo-attachments) -->
        <mat-chip-set class="attachments-chip-set">
            @for (item of attachments(); track $index) {
                <mat-chip [title]="item.name" (click)="chipClick(item)" (removed)="removeChip(item)">
                    <img matChipAvatar [src]="item | imageSource" />
                    {{ item.name }}
                    <button matChipRemove>
                        <mat-icon class="material-icons-outlined">delete</mat-icon>
                    </button>
                </mat-chip>
            }
        </mat-chip-set>
    </div>
    <div fxFlex="none">
        <button mat-button [mat-dialog-close]="undefined">{{ "general.cancel" | translate }}</button>
        <button mat-button mat-raised-button color="primary" [disabled]="!canSave()" (click)="onSave()">
            {{ "general.save" | translate }}
        </button>
    </div>
</mat-dialog-actions>
