export enum DateFormatType {
  mdy = 0,
  dmy = 1,
}

export enum TimeFormatType {
  h12 = 0,
  h24 = 1,
}

export enum DecimalSeparatorType {
  Point = 0,
  Comma = 1,
}

export enum ThousandSeparatorType {
  Comma = 0,
  Point = 1,
}

export const DateFormats = new Map<DateFormatType, string>([
  [DateFormatType.mdy, 'MM/DD/YYYY'],
  [DateFormatType.dmy, 'DD/MM/YYYY'],
]);

export const TimeFormats = new Map<TimeFormatType, string>([
  [TimeFormatType.h12, 'hh:mm A'],
  [TimeFormatType.h24, 'HH:mm'],
]);

export const DecimalSeparator = new Map<DecimalSeparatorType, string>([
  [DecimalSeparatorType.Point, '.'],
  [DecimalSeparatorType.Comma, ','],
]);

export const ThousandSeparator = new Map<ThousandSeparatorType, string>([
  [ThousandSeparatorType.Comma, ','],
  [ThousandSeparatorType.Point, '.'],
]);
