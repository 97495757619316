import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ContextInfo } from '../../system/context/context.model';

@Injectable({
  providedIn: 'root',
})
export class JsToNgService {
  refreshUser = new Subject<boolean>();
  refreshContextData = new BehaviorSubject(false);
  context = new BehaviorSubject<ContextInfo>(undefined);
  closedModalData = new Subject<unknown>();

  refreshContext() {
    this.refreshContextData.next(true);
  }

  setContext(context: ContextInfo) {
    this.context.next(context);
  }

  dialogClosed(dialogData: unknown) {
    this.closedModalData.next(dialogData);
  }

  refreshUserData() {
    this.refreshUser.next(true);
  }
}
