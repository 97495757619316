import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GridSearchEntityInstance } from '../../../models/module/grid';
import { DataListViewAction } from '../../../models/module/main/dataListViewAction';
import { DataCardsViewService } from '../../../module/instance/data-card/data-cards-view/data-cards-view.service';
import { DemoCardsActions, DemoCardsEntityInstances } from '../../data';
import { DemoCardsService } from './demo-cards.service';
import { ScreenTypeEnum } from '../../../models/module/screen';

@Component({
  selector: 'app-demo-cards-view',
  templateUrl: './demo-cards-view.component.html',
  providers: [{ provide: DataCardsViewService, useClass: DemoCardsService }],
})
export class DemoCardsViewComponent {
  screenType = ScreenTypeEnum.Card;
  rows = DemoCardsEntityInstances;
  actions: DataListViewAction[] = [];

  constructor(private _snackBar: MatSnackBar) {
    this.actions = [...DemoCardsActions].map(d => ({ ...d, click: item => this.openSnackBar(d, item) }));
  }

  private openSnackBar(action: DataListViewAction, item: GridSearchEntityInstance) {
    this._snackBar.open(action.name + ' ' + item.full_doc_num, null, {
      duration: 5000,
    });
  }
}
