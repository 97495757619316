import { FormFieldModel } from '../../models/module/fields/main';

export const MOCK_FORM_FIELDS: FormFieldModel[] = [
  {
    id: 1359,
    name: 'text',
    label: 'text',
    hasMultipleValues: true,
    typeId: 1,
    type: 1,
    displayTypeId: 1,
    displayType: 1,
    restrictions: [
      {
        mimeTypes: [],
        mimeTypesDataSource: [],
        key: 8,
      },
    ],
    formattings: [],
    isSystem: false,
    isMandatory: false,
    inUse: false,
    fieldOrigin: 1,
    x: 0,
    y: 1,
    cols: 3,
    rows: 1,
    triggeredFields: [],
    value: ['text 1', 'somne'],
    isParent: false,
    parentHasDefaultValue: false,
    childFieldIds: [],
    isFilter: false,
    currentDateTime: false,
    previousActionInstanceId: 231463,
    screensId: [349],
  },
  {
    id: 1360,
    name: 'test multi',
    label: 'test multi',
    hasMultipleValues: true,
    typeId: 1,
    type: 1,
    displayTypeId: 2,
    displayType: 2,
    restrictions: [
      {
        mimeTypes: [],
        mimeTypesDataSource: [],
        key: 8,
      },
    ],
    formattings: [],
    isSystem: false,
    isMandatory: false,
    inUse: false,
    fieldOrigin: 1,
    x: 0,
    y: 0,
    cols: 6,
    rows: 1,
    triggeredFields: [],
    value: ['dasda', 'asd'],
    isParent: false,
    parentHasDefaultValue: false,
    childFieldIds: [],
    isFilter: false,
    currentDateTime: false,
    previousActionInstanceId: 231463,
    screensId: [349],
  },
  {
    id: 1361,
    name: 'Test radio',
    label: 'Test radio',
    hasMultipleValues: true,
    typeId: 1,
    type: 1,
    displayTypeId: 4,
    displayType: 4,
    restrictions: [
      {
        mimeTypes: [],
        mimeTypesDataSource: [],
        key: 8,
      },
    ],
    formattings: [],
    isSystem: false,
    isMandatory: false,
    dataSourceId: -29,
    inUse: false,
    fieldOrigin: 1,
    x: 8,
    y: 1,
    cols: 2,
    rows: 1,
    triggeredFields: [],
    value: ['2', '1', '4'],
    dataSources: [
      {
        isDisabled: false,
        key: '2',
        value: 'Day',
        order: 1,
      },
      {
        isDisabled: false,
        key: '1',
        value: 'Payment Period',
        order: 2,
      },
      {
        isDisabled: false,
        key: '4',
        value: 'Month',
        order: 3,
      },
    ],
    isParent: false,
    parentHasDefaultValue: false,
    childFieldIds: [],
    isFilter: false,
    currentDateTime: false,
    previousActionInstanceId: 231787,
    screensId: [349],
  },
  {
    id: 1362,
    name: 'test',
    label: 'test',
    hasMultipleValues: true,
    typeId: 7,
    type: 7,
    displayTypeId: 8,
    displayType: 8,
    restrictions: [],
    formattings: [],
    isSystem: false,
    isMandatory: true,
    inUse: false,
    fieldOrigin: 1,
    x: 0,
    y: 2,
    cols: 12,
    rows: 1,
    triggeredFields: [],
    value: [
      {
        '1363': ['sdad', 'assad'],
        '1364': [2.0, 23.0],
        '1382': null,
      },
      {
        '1363': ['53wsa', 'as'],
        '1364': [43.0, 31.43],
        '1382': null,
      },
    ],
    aggregateValue: {},
    isParent: false,
    parentHasDefaultValue: false,
    childFieldIds: [],
    isFilter: false,
    currentDateTime: false,
    previousActionInstanceId: 231463,
    screensId: [349],
  },
  {
    id: 1365,
    name: 'date weather',
    label: 'date weather',
    hasMultipleValues: false,
    typeId: 4,
    type: 4,
    displayTypeId: 1,
    displayType: 1,
    restrictions: [
      {
        mimeTypes: [],
        mimeTypesDataSource: [],
        key: 5,
        value: 3,
      },
    ],
    formattings: [],
    isSystem: false,
    isMandatory: false,
    expression: '',
    inUse: false,
    fieldOrigin: 1,
    x: 6,
    y: 3,
    cols: 6,
    rows: 1,
    triggeredFields: [],
    value: '2024-06-02T00:00:00',
    isParent: false,
    parentHasDefaultValue: false,
    childFieldIds: [],
    isFilter: false,
    currentDateTime: false,
    usedOnWeather: true,
    previousActionInstanceId: 231463,
    screensId: [349],
  },
  {
    id: 1366,
    name: 'test weather',
    label: 'test weather',
    hasMultipleValues: true,
    typeId: 11,
    type: 11,
    displayTypeId: 10,
    displayType: 10,
    restrictions: [],
    formattings: [],
    isSystem: false,
    isMandatory: false,
    inUse: false,
    fieldOrigin: 1,
    x: 0,
    y: 5,
    cols: 12,
    rows: 1,
    triggeredFields: [],
    isParent: false,
    parentHasDefaultValue: false,
    childFieldIds: [],
    isFilter: false,
    currentDateTime: false,
    weatherDateId: 1365,
    screensId: [349],
  },
  {
    id: 1378,
    name: 'editor',
    label: 'editor',
    hasMultipleValues: true,
    typeId: 1,
    type: 1,
    displayTypeId: 9,
    displayType: 9,
    restrictions: [
      {
        mimeTypes: [],
        mimeTypesDataSource: [],
        key: 2,
      },
      {
        mimeTypes: [],
        mimeTypesDataSource: [],
        key: 8,
      },
    ],
    formattings: [],
    isSystem: false,
    isMandatory: false,
    inUse: false,
    fieldOrigin: 1,
    x: 6,
    y: 4,
    cols: 6,
    rows: 1,
    triggeredFields: [],
    value: [
      '<p><strong>Editor&nbsp;</strong>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Error, maiores necessitatibus blanditiis sed eaque quaeamet, quo at ducimus illo sunt consectetur animi mollitia recusandae, autem delectus perspiciatis doloremque cumque?</p>',
      '<p id="isPasted"><br></p><p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Error, maiores necessitatibus blanditiis sed eaque quae amet, quo at ducimus illo sunt consectetur animi mollitia recusandae, autem delectus perspiciatis doloremque cumque?</p>',
    ],
    isParent: false,
    parentHasDefaultValue: false,
    childFieldIds: [],
    isFilter: false,
    currentDateTime: false,
    previousActionInstanceId: 231463,
    screensId: [349],
  },
  {
    id: 1385,
    name: 'altitude number',
    label: 'altitude number',
    hasMultipleValues: false,
    typeId: 2,
    type: 2,
    displayTypeId: 1,
    displayType: 1,
    restrictions: [
      {
        mimeTypes: [],
        mimeTypesDataSource: [],
        key: 7,
      },
      {
        mimeTypes: [],
        mimeTypesDataSource: [],
        key: 4,
        value: 2,
      },
    ],
    formattings: [
      {
        key: 1,
        value: 1,
      },
      {
        key: 2,
        value: 2,
      },
    ],
    isSystem: false,
    isMandatory: false,
    expression: '',
    inUse: false,
    fieldOrigin: 1,
    x: 3,
    y: 1,
    cols: 2,
    rows: 1,
    triggeredFields: [1526],
    isParent: false,
    parentHasDefaultValue: false,
    childFieldIds: [],
    isFilter: false,
    currentDateTime: false,
    previousActionInstanceId: 231463,
    screensId: [349],
  },
  {
    id: 1393,
    name: 'test select',
    label: 'test select',
    hasMultipleValues: true,
    typeId: 1,
    type: 1,
    displayTypeId: 3,
    displayType: 3,
    restrictions: [
      {
        mimeTypes: [],
        mimeTypesDataSource: [],
        key: 8,
      },
    ],
    formattings: [],
    isSystem: false,
    isMandatory: false,
    dataSourceId: -25,
    inUse: false,
    fieldOrigin: 1,
    x: 6,
    y: 0,
    cols: 6,
    rows: 1,
    triggeredFields: [],
    isParent: false,
    parentHasDefaultValue: false,
    childFieldIds: [],
    isFilter: false,
    currentDateTime: false,
    screensId: [349],
  },
  {
    id: 1394,
    name: 'test single radio',
    label: 'test single radio',
    hasMultipleValues: false,
    typeId: 1,
    type: 1,
    displayTypeId: 4,
    displayType: 4,
    restrictions: [
      {
        mimeTypes: [],
        mimeTypesDataSource: [],
        key: 2,
      },
      {
        mimeTypes: [],
        mimeTypesDataSource: [],
        key: 8,
      },
    ],
    formattings: [],
    isSystem: false,
    isMandatory: false,
    dataSourceId: -25,
    inUse: false,
    fieldOrigin: 1,
    x: 5,
    y: 1,
    cols: 2,
    rows: 1,
    triggeredFields: [],
    dataSources: [
      {
        isDisabled: false,
        key: '1',
        value: 'Payment Period',
        order: 1,
      },
      {
        isDisabled: false,
        key: '2',
        value: 'Day',
        order: 2,
      },
      {
        isDisabled: false,
        key: '4',
        value: 'Month',
        order: 3,
      },
    ],
    isParent: false,
    parentHasDefaultValue: false,
    childFieldIds: [],
    isFilter: false,
    currentDateTime: false,
    previousActionInstanceId: 231463,
    screensId: [349],
  },
  {
    id: 1395,
    name: 'test yesno',
    label: 'test yesn',
    hasMultipleValues: false,
    typeId: 3,
    type: 3,
    displayTypeId: 5,
    displayType: 5,
    restrictions: [],
    formattings: [],
    isSystem: false,
    isMandatory: false,
    inUse: false,
    fieldOrigin: 1,
    x: 0,
    y: 3,
    cols: 6,
    rows: 1,
    triggeredFields: [],
    value: false,
    isParent: false,
    parentHasDefaultValue: false,
    childFieldIds: [],
    isFilter: false,
    currentDateTime: false,
    previousActionInstanceId: 231463,
    screensId: [349],
  },
  {
    id: 1436,
    name: 'multi numbers',
    label: 'multi numbers',
    hasMultipleValues: true,
    typeId: 2,
    type: 2,
    displayTypeId: 1,
    displayType: 1,
    restrictions: [
      {
        mimeTypes: [],
        mimeTypesDataSource: [],
        key: 7,
      },
      {
        mimeTypes: [],
        mimeTypesDataSource: [],
        key: 4,
        value: 1,
      },
    ],
    formattings: [
      {
        key: 1,
        value: 1,
      },
      {
        key: 2,
        value: 2,
      },
    ],
    isSystem: false,
    isMandatory: false,
    expression: '',
    inUse: false,
    fieldOrigin: 1,
    x: 0,
    y: 4,
    cols: 2,
    rows: 1,
    triggeredFields: [],
    isParent: false,
    parentHasDefaultValue: false,
    childFieldIds: [],
    isFilter: false,
    currentDateTime: false,
    screensId: [349],
  },
  {
    id: 1438,
    name: 'multi money',
    label: 'multi money',
    hasMultipleValues: true,
    typeId: 2,
    type: 2,
    displayTypeId: 1,
    displayType: 1,
    restrictions: [
      {
        mimeTypes: [],
        mimeTypesDataSource: [],
        key: 7,
      },
      {
        mimeTypes: [],
        mimeTypesDataSource: [],
        key: 4,
        value: 1,
      },
    ],
    formattings: [
      {
        key: 1,
        value: 2,
      },
      {
        key: 2,
        value: 2,
      },
    ],
    isSystem: false,
    isMandatory: false,
    expression: '',
    inUse: false,
    fieldOrigin: 1,
    x: 2,
    y: 4,
    cols: 2,
    rows: 1,
    triggeredFields: [],
    isParent: false,
    parentHasDefaultValue: false,
    childFieldIds: [],
    isFilter: false,
    currentDateTime: false,
    screensId: [349],
  },
  {
    id: 1440,
    name: 'multi percent',
    label: 'multi percent',
    hasMultipleValues: true,
    typeId: 2,
    type: 2,
    displayTypeId: 1,
    displayType: 1,
    restrictions: [
      {
        mimeTypes: [],
        mimeTypesDataSource: [],
        key: 7,
      },
      {
        mimeTypes: [],
        mimeTypesDataSource: [],
        key: 4,
        value: 1,
      },
    ],
    formattings: [
      {
        key: 1,
        value: 3,
      },
      {
        key: 2,
        value: 2,
      },
    ],
    isSystem: false,
    isMandatory: false,
    expression: '',
    inUse: false,
    fieldOrigin: 1,
    x: 4,
    y: 4,
    cols: 2,
    rows: 1,
    triggeredFields: [],
    isParent: false,
    parentHasDefaultValue: false,
    childFieldIds: [],
    isFilter: false,
    currentDateTime: false,
    screensId: [349],
  },
  {
    id: 1487,
    name: 'Attach file',
    label: 'Attach file',
    hasMultipleValues: true,
    typeId: 5,
    type: 5,
    displayTypeId: 6,
    displayType: 6,
    restrictions: [
      {
        mimeTypes: [3, 4, 9, 16],
        mimeTypesDataSource: [
          {
            key: 3,
            value: 'image/png',
            description: '.jpg',
          },
          {
            key: 4,
            value: 'image/png',
            description: '.png',
          },
          {
            key: 9,
            value: 'application/msword',
            description: '.doc',
          },
          {
            key: 16,
            value: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            description: '.docx',
          },
        ],
        key: 6,
      },
    ],
    formattings: [],
    isSystem: false,
    isMandatory: false,
    inUse: false,
    fieldOrigin: 1,
    x: 0,
    y: 6,
    cols: 4,
    rows: 1,
    triggeredFields: [],
    isParent: false,
    parentHasDefaultValue: false,
    childFieldIds: [],
    isFilter: false,
    currentDateTime: false,
    screensId: [349],
  },
  {
    id: 1492,
    name: 'check',
    label: 'check default',
    hasMultipleValues: false,
    typeId: 3,
    type: 3,
    displayTypeId: 5,
    displayType: 5,
    restrictions: [
      {
        mimeTypes: [],
        mimeTypesDataSource: [],
        key: 3,
      },
    ],
    formattings: [],
    isSystem: false,
    isMandatory: false,
    inUse: false,
    fieldOrigin: 1,
    x: 4,
    y: 6,
    cols: 2,
    rows: 1,
    triggeredFields: [],
    value: true,
    isParent: false,
    parentHasDefaultValue: false,
    childFieldIds: [],
    isFilter: false,
    currentDateTime: false,
    previousActionInstanceId: 0,
    screensId: [349],
  },
  {
    id: 1522,
    name: 'new formula',
    label: 'ocheni new formula',
    hasMultipleValues: false,
    typeId: 2,
    type: 2,
    displayTypeId: 1,
    displayType: 1,
    restrictions: [
      {
        mimeTypes: [],
        mimeTypesDataSource: [],
        key: 7,
      },
      {
        mimeTypes: [],
        mimeTypesDataSource: [],
        key: 4,
        value: 2,
      },
    ],
    formattings: [
      {
        key: 1,
        value: 1,
      },
      {
        key: 2,
        value: 2,
      },
    ],
    isSystem: false,
    isMandatory: false,
    expression: 'SUM({{144}})+13',
    inUse: false,
    fieldOrigin: 1,
    x: 6,
    y: 6,
    cols: 3,
    rows: 1,
    triggeredFields: [],
    isParent: false,
    parentHasDefaultValue: false,
    childFieldIds: [],
    isFilter: false,
    currentDateTime: false,
    previousActionInstanceId: 0,
    screensId: [349],
  },
  {
    id: 1523,
    name: 'ejqkwjeqe',
    label: 'ejqkwjeqe',
    hasMultipleValues: false,
    typeId: 2,
    type: 2,
    displayTypeId: 1,
    displayType: 1,
    restrictions: [
      {
        mimeTypes: [],
        mimeTypesDataSource: [],
        key: 7,
      },
      {
        mimeTypes: [],
        mimeTypesDataSource: [],
        key: 4,
        value: 2,
      },
    ],
    formattings: [
      {
        key: 1,
        value: 1,
      },
      {
        key: 2,
        value: 2,
      },
    ],
    isSystem: false,
    isMandatory: false,
    expression: 'SUM({{943_76}})+123',
    inUse: false,
    fieldOrigin: 1,
    x: 9,
    y: 6,
    cols: 3,
    rows: 1,
    triggeredFields: [],
    isParent: false,
    parentHasDefaultValue: false,
    childFieldIds: [],
    isFilter: false,
    currentDateTime: false,
    previousActionInstanceId: 0,
    screensId: [349],
  },
  {
    id: 1524,
    name: 'A module',
    label: 'A module',
    hasMultipleValues: true,
    typeId: 10,
    type: 10,
    displayTypeId: 3,
    displayType: 3,
    restrictions: [],
    formattings: [],
    isSystem: false,
    isMandatory: false,
    dataSourceId: 76,
    inUse: false,
    fieldOrigin: 2,
    x: 0,
    y: 7,
    cols: 6,
    rows: 1,
    relationId: 76,
    triggeredFields: [1526, 1523],
    isParent: false,
    parentHasDefaultValue: false,
    childFieldIds: [],
    isFilter: false,
    currentDateTime: false,
    screensId: [349],
  },
  {
    id: 1526,
    name: 'relation formula',
    label: 'relation formula',
    hasMultipleValues: false,
    typeId: 2,
    type: 2,
    displayTypeId: 1,
    displayType: 1,
    restrictions: [
      {
        mimeTypes: [],
        mimeTypesDataSource: [],
        key: 7,
      },
      {
        mimeTypes: [],
        mimeTypesDataSource: [],
        key: 4,
        value: 1,
      },
    ],
    formattings: [
      {
        key: 1,
        value: 1,
      },
      {
        key: 2,
        value: 2,
      },
    ],
    isSystem: false,
    isMandatory: false,
    expression: 'SUM({{992_76}})+SUM({{1385}})',
    inUse: false,
    fieldOrigin: 1,
    x: 6,
    y: 7,
    cols: 4,
    rows: 1,
    triggeredFields: [],
    isParent: false,
    parentHasDefaultValue: false,
    childFieldIds: [],
    isFilter: false,
    currentDateTime: false,
    previousActionInstanceId: 0,
    screensId: [349],
  },
  {
    id: 1656,
    name: 'single attach',
    label: 'single attach',
    hasMultipleValues: false,
    typeId: 5,
    type: 5,
    displayTypeId: 6,
    displayType: 6,
    restrictions: [
      {
        mimeTypes: [],
        mimeTypesDataSource: [],
        key: 6,
      },
    ],
    formattings: [],
    isSystem: false,
    isMandatory: false,
    inUse: false,
    fieldOrigin: 1,
    x: 0,
    y: 9,
    cols: 12,
    rows: 1,
    triggeredFields: [],
    isParent: false,
    parentHasDefaultValue: false,
    childFieldIds: [],
    isFilter: false,
    currentDateTime: false,
    screensId: [349],
  },
  {
    id: 1871,
    name: 'test wather2',
    label: 'test wather2',
    hasMultipleValues: true,
    typeId: 11,
    type: 11,
    displayTypeId: 10,
    displayType: 10,
    restrictions: [],
    formattings: [],
    isSystem: false,
    isMandatory: false,
    inUse: false,
    fieldOrigin: 1,
    x: 0,
    y: 10,
    cols: 12,
    rows: 1,
    triggeredFields: [],
    isParent: false,
    parentHasDefaultValue: false,
    childFieldIds: [],
    isFilter: false,
    currentDateTime: false,
    weatherDateId: 1365,
    screensId: [349],
  },
  {
    id: 179,
    hasMultipleValues: false,
    typeId: 50,
    type: 50,
    displayTypeId: 1,
    displayType: 1,
    restrictions: [],
    formattings: [],
    isSystem: false,
    isMandatory: false,
    inUse: false,
    fieldOrigin: 0,
    x: 0,
    y: 8,
    cols: 12,
    rows: 1,
    annotation:
      '<p><span class="wsc-grammar-problem" data-grammar-rule="UPPERCASE_SENTENCE_START" data-grayt-phrase="test" data-wsc-id="lxelzuskqsfyfbrmj" data-wsc-lang="en_US">test</span> annotation</p>',
    isParent: false,
    parentHasDefaultValue: false,
    isFilter: false,
    currentDateTime: false,
    screensId: [],
  },
];
