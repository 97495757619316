<div class="cipo cipo-cover" fxLayout="column" fxLayoutAlign="center center">
    <img fxFlex="none" src="/src/assets/images/CIPO_logo_white.png" alt="Cipo" width="200px" />
    <div fxFlex="grow">
        <mat-card>
            <div class="loading-content">
                @if (loading()) {
                    <div>{{ "general.loadingMessage" | translate }}</div>
                } @else {
                    <ng-content></ng-content>
                }
            </div>
            @if (loading()) {
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            }
        </mat-card>
    </div>
</div>
