import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';
import { map, Observable, of } from 'rxjs';
import { GridSearchEntityInstance } from '../../../../models/module/grid';
import { ItvDateTimeWithRestrictionsPipe } from '../../../../shared/pipes/itv-date.pipe';
import { ItvNumberWithFormattingsPipe } from '../../../../shared/pipes/itv-number.pipe';
import { LocaleDateAdapter } from '../../../../shared/services/date.adapter';
import { LocaleService } from '../../../../shared/services/locale.service';
import { CalendarBaseViewComponent } from '../calendar-base-view.component';
import { CalendarDate, CalendarTile, CalendarTileDoc, CalendarViewMode } from '../calendar.model';
import { CalendarService } from '../calendar.service';

@Component({
  selector: 'app-calendar-year-view',
  templateUrl: './calendar-year-view.component.html',
  styleUrls: ['./calendar-year-view.component.scss'],
})
export class CalendarYearViewComponent extends CalendarBaseViewComponent {
  @Output() onMonthClick = new EventEmitter<CalendarDate>();

  constructor(
    protected localeService: LocaleService,
    protected dateAdapter: LocaleDateAdapter,
    protected calendarService: CalendarService,
    protected dialog: MatDialog,
    protected itvDateTimePipe: ItvDateTimeWithRestrictionsPipe,
    protected itvNumberPipe: ItvNumberWithFormattingsPipe,
  ) {
    super(localeService, dateAdapter, calendarService, dialog, itvDateTimePipe, itvNumberPipe);
  }

  monthClick(date: Date) {
    const dt = moment(date);
    const calendarDate = {
      date: dt.toDate(),
      year: dt.year(),
      month: dt.month(),
      week: dt.isoWeek(),
      day: dt.date(),
    };
    this.onMonthClick.emit(calendarDate);
  }

  getTiles(empty: boolean, docs?: GridSearchEntityInstance[]): Observable<CalendarTile[]> {
    const result: CalendarTile[] = [];

    const day = moment(new Date(this.date.year, 0, 1));

    return of(empty ? [] : this.processDocs(CalendarViewMode.Month, docs)).pipe(
      map((processedDocs: CalendarTileDoc[]) => {
        this.startDate = day.toDate();
        for (let i = 0; i < 12; i++) {
          const dt = day.toDate();
          const dtMonth = day.month();
          const monthName = day.format('MMMM');
          const isToday = this.date.month == dtMonth;

          const tile = {
            viewMode: CalendarViewMode.Year,
            date: dt,
            year: this.date.year,
            month: dtMonth,
            week: day.isoWeek(),
            day: 1,
            text: monthName,
            isToday: isToday,
            docs: [],
          } as CalendarTile;
          if (!empty) {
            tile.docs = this.getTileDocs(tile, processedDocs);
          }
          result.push(tile);

          day.add(1, 'M');
        }
        this.endDate = day.toDate();
        return result;
      }),
    );
  }

  getTileDocs(tile: CalendarTile, docs: CalendarTileDoc[]): CalendarTileDoc[] {
    const found = (docs || []).filter(d => d.ref.year === tile.year && d.ref.month === tile.month);
    return found;
  }
}
