import { cipo } from "cipo";

var tenantController = function ($scope, $timeout) {
    $scope.openMenu = function ($mdMenu, ev) {
        $mdMenu.open(ev);
        $timeout(function () {
            $scope.focusSearch = true;
            $timeout(function () {
                $scope.focusSearch = false;
            }, 300);
        }, 300);
    };
};

cipo.controller("tenantController", tenantController);
