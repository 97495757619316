import { cipo } from 'cipo';

cipo.controller('managementreportsController',
function ($transition$, $scope, Model, Message, URI, userService) {
    const routeParams = $transition$.params();

    Object.defineProperty($scope, 'operations', {
        get: function () { return userService.getOperationsForModule(routeParams.id); }
    });

    var dataURL = URI.SYSTEM.PBI_CONFIG;
    Model[dataURL.method](dataURL, { url: { moduleId: routeParams.id }, urltype: 'obj' })
        .then(function (r) {
            $scope.config = r;
        })
        .catch(function (e) {
            Message.error(e);
            console.error('faild to run reports', e);
        });

    $scope.parent = {
        printReport: function () {
            var elementToPrint = $(document).find('#reportContainer')[0];
            var reportToPrint = powerbi.get(elementToPrint);

            reportToPrint.print()
                .catch(error => { Message.error('Cannot print report'); });
        }
    };
});  