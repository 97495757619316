import { Injectable, inject } from '@angular/core';
import { HttpService } from './http.service';
import { Permission } from '../../models/permission';
import { Role } from '../../models/module/main';
import { User } from '../../models/core';
import { MenuModel, MenuModuleTypeEnum, RouteCodeEnum } from '../../models/menu';
import { TenantStore } from '../../signal-store';
import { HYBRID_MODULES, RoutesDictionaryByCode } from '../../legacy/constants';
import { map, Observable, of } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  private http = inject(HttpService);
  private tenantStore = inject(TenantStore);
  private tenantName = '';
  private contractId = 0;
  private router = inject(Router);
  private route = '';
  //   will be used in userStore as the first activeMenuItem
  activeMenuItem = null;

  getModuleUserRole(id: number, contractId?: number) {
    const params = this.http.buildHttpParams({
      id,
      permissionId: Permission.Create,
      ...(contractId ? { contractId } : {}),
    });
    return this.http.get<Role[]>('_api/ModuleDefinition/ModuleRoleUserDict', { httpOptions: { params } });
  }

  getUserData() {
    return this.http.get<User>('_api/Main/UserData', { useLoader: false });
  }

  getUserMenu(contractId: number): Observable<MenuModel[]> {
    this.tenantName = this.tenantStore.selectedTenant()?.name?.toLowerCase();
    if (!this.tenantName) {
      return of([]);
    }
    const params = this.http.buildHttpParams({ contractId });
    return this.http.get<MenuModel[]>('_api/Menu/UserMenu', { useLoader: false, httpOptions: { params } }).pipe(
      map(items => {
        this.contractId = contractId;
        this.route = this.router.url.substring(1);
        items.forEach(item => this.applyUrlPath(item));
        return items;
      }),
    );
  }

  applyUrlPath(menuItem: MenuModel) {
    if (menuItem.children) {
      menuItem.children.forEach(item => this.applyUrlPath(item));
    } else {
      const { moduleId, code, perContract, moduleTypeId } = menuItem;

      if (moduleId < 0 && !HYBRID_MODULES.includes(moduleId)) {
        let tempPath = `${this.tenantName}/${RoutesDictionaryByCode[code]}`;
        switch (code) {
          case RouteCodeEnum.ProgressPayment:
            tempPath += `/${this.contractId}/0/0`;
            break;
          case RouteCodeEnum.SOV:
            tempPath += `/${this.contractId}/0`;
            break;
          case RouteCodeEnum.ManagementReports:
            tempPath += `/${this.contractId}/${moduleId}`;
            break;
          default:
            break;
        }
        menuItem.urlPath = tempPath;
      } else {
        if (moduleTypeId === MenuModuleTypeEnum.Reporting) {
          menuItem.urlPath = `${this.tenantName}/${RoutesDictionaryByCode.MREP}/0/${moduleId}`;
        } else {
          const ctrId = perContract ? this.contractId : 0;
          menuItem.urlPath = `${this.tenantName}/${menuItem.url ? 'url' : 'dm/' + ctrId}/${moduleId}`;
        }
      }

      if (this.route === menuItem.urlPath) {
        this.activeMenuItem = menuItem;
      }
    }
  }

  getUnreadNotifications(contractId: number) {
    const params = this.http.buildHttpParams({ contractId });
    return this.http.get<number>('_api/Folder/TotalUnreadCount', { httpOptions: { params }, useLoader: false });
  }
}
