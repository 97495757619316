<!-- 
    TODO: Move cipo-layout in a separate component, with a separate route (tenant)
    The pages that tenant specific, should not be inside cipo-layout. This will ensure that the requests that are placed inside store's onInit wil not be called
    This is not possible right now, because ui-view needs to be initialized at startup, so this should be done after switching to Angular the entire app

    cipo class is added to separate angular styles from angularjs styles 
  -->
<cipo-layout [showHeader]="showHeader()">
    @if (userStore.isLoading()) {
        <cipo-loading [loading]="true"></cipo-loading>
    }
    <div [ngClass]="!userStore.isLoading() && !userStore.angularJsRoute() ? 'full-height cipo' : 'display--none'">
        <router-outlet></router-outlet>
    </div>
    <div [ngClass]="!userStore.isLoading() && userStore.angularJsRoute() ? 'full-height' : 'display--none'">
        <div ui-view id="index_view"></div>
    </div>
</cipo-layout>
