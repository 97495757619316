import { ScreenFieldModel } from "../../models/module/fields/main";
import { GridViewModel } from "../../models/module/grid";

export const DemoCardsScreenFieldModel: GridViewModel<ScreenFieldModel[]> = {
    "records": 18,
    "data": [
        {
            "id": -52,
            "name": "Adjusted Contract Completion Date",
            "systemFieldName": "adjusted_completion_date",
            "label": "Construction finish",
            "typeId": 4,
            "displayTypeId": 1,
            "formattings": [],
            "restrictions": [
                {
                    "mimeTypes": [],
                    "mimeTypesDataSource": [],
                    "key": 5,
                    "value": 3
                }
            ],
            "x": 0,
            "y": 10,
            "cols": 6,
            "rows": 1,
            "fieldOrigin": 3,
            "isFilter": false,
            "canRemove": true,
            "isContractSystemField": true,
            "currentDateTime": false,
            "labelFormatting": {
                "id": 14,
                "showColon": false,
                "fontStyle": 0,
                "fontSize": 5,
                "fontColor": "#000",
                "alignHorizontal": 1,
                "alignVertical": 2,
                "labelValueAlignment": 1
            },
            "valueFormatting": {
                "id": 13,
                "showColon": false,
                "size": 50,
                "fontStyle": 1,
                "fontSize": 5,
                "fontColor": "#000000",
                "alignHorizontal": 3,
                "alignVertical": 2,
                "labelValueAlignment": 1
            }
        },
        {
            "id": -42,
            "name": "Adjusted Contract Total",
            "systemFieldName": "adjusted_contract_total",
            "label": "Contract value to date",
            "typeId": 2,
            "displayTypeId": 1,
            "formattings": [
                {
                    "key": 1,
                    "value": 2
                },
                {
                    "key": 2,
                    "formattingValue": 2
                }
            ],
            "restrictions": [
                {
                    "mimeTypes": [],
                    "mimeTypesDataSource": [],
                    "key": 7
                }
            ],
            "x": 0,
            "y": 5,
            "cols": 6,
            "rows": 1,
            "fieldOrigin": 3,
            "isFilter": false,
            "canRemove": true,
            "isContractSystemField": true,
            "currentDateTime": false,
            "labelFormatting": {
                "id": 20,
                "showColon": false,
                "fontStyle": 0,
                "fontSize": 5,
                "fontColor": "#000",
                "alignHorizontal": 1,
                "alignVertical": 2,
                "labelValueAlignment": 1
            },
            "valueFormatting": {
                "id": 19,
                "showColon": false,
                "size": 50,
                "fontStyle": 0,
                "fontSize": 5,
                "fontColor": "#000",
                "alignHorizontal": 3,
                "alignVertical": 2,
                "labelValueAlignment": 1
            }
        },
        {
            "id": -56,
            "name": "Contingency",
            "systemFieldName": "contingency",
            "label": "Contingency",
            "typeId": 2,
            "displayTypeId": 1,
            "formattings": [
                {
                    "key": 1,
                    "value": 2
                },
                {
                    "key": 2,
                    "formattingValue": 2
                }
            ],
            "restrictions": [
                {
                    "mimeTypes": [],
                    "mimeTypesDataSource": [],
                    "key": 7
                }
            ],
            "x": 6,
            "y": 4,
            "cols": 6,
            "rows": 1,
            "fieldOrigin": 3,
            "isFilter": false,
            "canRemove": true,
            "isContractSystemField": true,
            "currentDateTime": false,
            "labelFormatting": {
                "id": 8,
                "showColon": false,
                "fontStyle": 0,
                "fontSize": 5,
                "fontColor": "#000",
                "alignHorizontal": 3,
                "alignVertical": 2,
                "labelValueAlignment": 1
            },
            "valueFormatting": {
                "id": 7,
                "showColon": false,
                "size": 50,
                "fontStyle": 0,
                "fontSize": 5,
                "fontColor": "#000",
                "alignHorizontal": 3,
                "alignVertical": 2,
                "labelValueAlignment": 1
            }
        },
        {
            "id": -72,
            "name": "Contract Days Late",
            "systemFieldName": "contract_days_late",
            "label": "Contract Days Late",
            "typeId": 2,
            "displayTypeId": 15,
            "formattings": [],
            "restrictions": [],
            "x": 6,
            "y": 10,
            "cols": 1,
            "rows": 1,
            "fieldOrigin": 3,
            "isFilter": true,
            "canRemove": true,
            "isContractSystemField": true,
            "currentDateTime": false,
            "labelFormatting": {
                "id": 2,
                "showColon": true,
                "fontStyle": 1,
                "fontSize": 4,
                "fontColor": "#000",
                "alignHorizontal": 1,
                "alignVertical": 2
            },
            "valueFormatting": {
                "id": 1,
                "showColon": false,
                "fontStyle": 0,
                "fontSize": 5,
                "fontColor": "#000",
                "alignHorizontal": 3,
                "alignVertical": 2,
                "labelValueAlignment": 1
            }
        },
        {
            "id": 71,
            "name": "Contract Description",
            "systemFieldName": "contract_descr",
            "label": "Description",
            "typeId": 1,
            "displayTypeId": 1,
            "formattings": [],
            "restrictions": [],
            "x": 0,
            "y": 1,
            "cols": 12,
            "rows": 1,
            "fieldOrigin": 1,
            "isFilter": false,
            "canRemove": true,
            "isContractSystemField": false,
            "currentDateTime": false,
            "labelFormatting": {
                "id": 33,
                "showColon": true,
                "fontStyle": 3,
                "fontSize": 5,
                "fontColor": "#000",
                "alignHorizontal": 1,
                "alignVertical": 3,
                "labelValueAlignment": 1
            },
            "valueFormatting": {
                "id": 34,
                "showColon": false,
                "size": 100,
                "fontStyle": 0,
                "fontSize": 5,
                "fontColor": "#000",
                "alignHorizontal": 1,
                "alignVertical": 3,
                "labelValueAlignment": 1
            }
        },
        {
            "id": 72,
            "name": "Contract Name",
            "systemFieldName": "contract_name",
            "label": "Contract Name",
            "typeId": 1,
            "displayTypeId": 1,
            "formattings": [],
            "restrictions": [],
            "x": 4,
            "y": 0,
            "cols": 8,
            "rows": 1,
            "fieldOrigin": 1,
            "isFilter": false,
            "canRemove": true,
            "isContractSystemField": false,
            "currentDateTime": false,
            "labelFormatting": {
                "id": 29,
                "showColon": true,
                "fontStyle": 1,
                "fontSize": 4,
                "fontColor": "#000",
                "alignHorizontal": 1,
                "alignVertical": 2
            },
            "valueFormatting": {
                "id": 30,
                "showColon": false,
                "size": 100,
                "fontStyle": 5,
                "fontSize": 4,
                "fontColor": "#333333",
                "alignHorizontal": 1,
                "alignVertical": 3,
                "labelValueAlignment": 1
            }
        },
        {
            "id": 73,
            "name": "Contract No",
            "systemFieldName": "contract_no",
            "label": "Contract No",
            "typeId": 1,
            "displayTypeId": 1,
            "formattings": [],
            "restrictions": [],
            "x": 0,
            "y": 0,
            "cols": 4,
            "rows": 1,
            "fieldOrigin": 1,
            "isFilter": false,
            "canRemove": true,
            "isContractSystemField": false,
            "currentDateTime": false,
            "labelFormatting": {
                "id": 31,
                "showColon": true,
                "fontStyle": 1,
                "fontSize": 4,
                "fontColor": "#000",
                "alignHorizontal": 1,
                "alignVertical": 2
            },
            "valueFormatting": {
                "id": 32,
                "showColon": false,
                "size": 100,
                "fontStyle": 1,
                "fontSize": 3,
                "fontColor": "#333333",
                "alignHorizontal": 1,
                "alignVertical": 3,
                "labelValueAlignment": 1
            }
        },
        {
            "id": -54,
            "name": "Current Percent Complete Amount",
            "systemFieldName": "current_percent_complete_amount",
            "label": "% Complete (Value)",
            "typeId": 2,
            "displayTypeId": 17,
            "formattings": [
                {
                    "key": 1,
                    "value": 3
                },
                {
                    "key": 2,
                    "formattingValue": 0
                }
            ],
            "restrictions": [
                {
                    "mimeTypes": [],
                    "mimeTypesDataSource": [],
                    "key": 7
                }
            ],
            "x": 0,
            "y": 3,
            "cols": 9,
            "rows": 1,
            "fieldOrigin": 3,
            "isFilter": false,
            "canRemove": true,
            "isContractSystemField": true,
            "currentDateTime": false,
            "labelFormatting": {
                "id": 12,
                "showColon": true,
                "fontStyle": 1,
                "fontSize": 5,
                "fontColor": "#000",
                "alignHorizontal": 1,
                "alignVertical": 2,
                "labelValueAlignment": 1
            },
            "valueFormatting": {
                "id": 11,
                "showColon": false,
                "size": 50,
                "fontStyle": 0,
                "fontSize": 5,
                "fontColor": "#000",
                "alignHorizontal": 3,
                "alignVertical": 2,
                "labelValueAlignment": 1
            }
        },
        {
            "id": -55,
            "name": "Current Percent Complete Duration",
            "systemFieldName": "current_percent_complete_duration",
            "label": "% Complete (Time)",
            "typeId": 2,
            "displayTypeId": 16,
            "formattings": [
                {
                    "key": 1,
                    "value": 3
                },
                {
                    "key": 2,
                    "formattingValue": 0
                }
            ],
            "restrictions": [
                {
                    "mimeTypes": [],
                    "mimeTypesDataSource": [],
                    "key": 7
                }
            ],
            "x": 0,
            "y": 2,
            "cols": 9,
            "rows": 1,
            "fieldOrigin": 3,
            "isFilter": false,
            "canRemove": true,
            "isContractSystemField": true,
            "currentDateTime": false,
            "labelFormatting": {
                "id": 10,
                "showColon": true,
                "fontStyle": 1,
                "fontSize": 5,
                "fontColor": "#000",
                "alignHorizontal": 1,
                "alignVertical": 2,
                "labelValueAlignment": 1
            },
            "valueFormatting": {
                "id": 9,
                "showColon": false,
                "size": 50,
                "fontStyle": 0,
                "fontSize": 5,
                "fontColor": "#000",
                "alignHorizontal": 3,
                "alignVertical": 2,
                "labelValueAlignment": 1
            }
        },
        {
            "id": -71,
            "customFieldId": 1,
            "name": "line",
            "label": "Line",
            "typeId": 12,
            "displayTypeId": 11,
            "x": 0,
            "y": 7,
            "cols": 12,
            "rows": 1,
            "fieldOrigin": 0,
            "formattings": [],
            "restrictions": [],
            "isFilter": false,
            "canRemove": true,
            "isContractSystemField": false,
            "currentDateTime": false,
            "labelFormatting": {
                "id": 1,
                "showColon": true,
                "fontStyle": 1,
                "fontSize": 4,
                "fontColor": "#000",
                "alignHorizontal": 1,
                "alignVertical": 2
            },
            "valueFormatting": {
                "id": 2,
                "showColon": false,
                "fontStyle": 0,
                "fontSize": 4,
                "fontColor": "#782626",
                "alignHorizontal": 3,
                "alignVertical": 2,
                "labelValueAlignment": 1
            }
        },
        {
            "id": -45,
            "name": "Original Contract Completion Date",
            "systemFieldName": "original_contract_completion_date",
            "label": "Intended completion",
            "typeId": 4,
            "displayTypeId": 1,
            "formattings": [],
            "restrictions": [
                {
                    "mimeTypes": [],
                    "mimeTypesDataSource": [],
                    "key": 5,
                    "value": 3
                }
            ],
            "x": 0,
            "y": 9,
            "cols": 6,
            "rows": 1,
            "fieldOrigin": 3,
            "isFilter": false,
            "canRemove": true,
            "isContractSystemField": true,
            "currentDateTime": false,
            "labelFormatting": {
                "id": 16,
                "showColon": false,
                "fontStyle": 0,
                "fontSize": 5,
                "fontColor": "#000",
                "alignHorizontal": 1,
                "alignVertical": 2,
                "labelValueAlignment": 1
            },
            "valueFormatting": {
                "id": 15,
                "showColon": false,
                "size": 50,
                "fontStyle": 0,
                "fontSize": 5,
                "fontColor": "#000",
                "alignHorizontal": 3,
                "alignVertical": 2,
                "labelValueAlignment": 1
            }
        },
        {
            "id": -44,
            "name": "Original Contract Start Date",
            "systemFieldName": "original_contract_start_date",
            "label": "Construction start",
            "typeId": 4,
            "displayTypeId": 1,
            "formattings": [],
            "restrictions": [
                {
                    "mimeTypes": [],
                    "mimeTypesDataSource": [],
                    "key": 5,
                    "value": 3
                }
            ],
            "x": 0,
            "y": 8,
            "cols": 6,
            "rows": 1,
            "fieldOrigin": 3,
            "isFilter": false,
            "canRemove": true,
            "isContractSystemField": true,
            "currentDateTime": false,
            "labelFormatting": {
                "id": 18,
                "showColon": false,
                "fontStyle": 0,
                "fontSize": 5,
                "fontColor": "#000",
                "alignHorizontal": 1,
                "alignVertical": 2,
                "labelValueAlignment": 1
            },
            "valueFormatting": {
                "id": 17,
                "showColon": false,
                "size": 50,
                "fontStyle": 0,
                "fontSize": 5,
                "fontColor": "#000",
                "alignHorizontal": 3,
                "alignVertical": 2,
                "labelValueAlignment": 1
            }
        },
        {
            "id": -39,
            "name": "Orignal Contract Value",
            "systemFieldName": "orig_contract_value",
            "label": "Original contract value",
            "typeId": 2,
            "displayTypeId": 1,
            "formattings": [
                {
                    "key": 1,
                    "value": 2
                },
                {
                    "key": 2,
                    "formattingValue": 2
                }
            ],
            "restrictions": [
                {
                    "mimeTypes": [],
                    "mimeTypesDataSource": [],
                    "key": 7
                }
            ],
            "x": 0,
            "y": 4,
            "cols": 6,
            "rows": 1,
            "fieldOrigin": 3,
            "isFilter": false,
            "canRemove": true,
            "isContractSystemField": true,
            "currentDateTime": false,
            "labelFormatting": {
                "id": 26,
                "showColon": false,
                "fontStyle": 0,
                "fontSize": 5,
                "fontColor": "#000",
                "alignHorizontal": 1,
                "alignVertical": 2,
                "labelValueAlignment": 1
            },
            "valueFormatting": {
                "id": 25,
                "showColon": false,
                "size": 50,
                "fontStyle": 0,
                "fontSize": 5,
                "fontColor": "#000",
                "alignHorizontal": 3,
                "alignVertical": 2,
                "labelValueAlignment": 1
            }
        },
        {
            "id": -69,
            "name": "Project Manager",
            "systemFieldName": "project_manager",
            "label": "Project Manager",
            "typeId": 1,
            "displayTypeId": 1,
            "formattings": [],
            "restrictions": [],
            "x": 6,
            "y": 8,
            "cols": 6,
            "rows": 2,
            "fieldOrigin": 3,
            "isFilter": false,
            "canRemove": true,
            "isContractSystemField": true,
            "currentDateTime": false,
            "labelFormatting": {
                "id": 4,
                "showColon": false,
                "fontStyle": 0,
                "fontSize": 5,
                "fontColor": "#0042aa",
                "alignHorizontal": 2,
                "alignVertical": 2,
                "labelValueAlignment": 1
            },
            "valueFormatting": {
                "id": 3,
                "showColon": false,
                "fontStyle": 1,
                "fontSize": 3,
                "fontColor": "#444444",
                "alignHorizontal": 2,
                "alignVertical": 2,
                "labelValueAlignment": 3
            }
        },
        {
            "id": -57,
            "name": "Remaining Contingency",
            "systemFieldName": "remaining_contingency",
            "label": "Contingency left",
            "typeId": 2,
            "displayTypeId": 1,
            "formattings": [
                {
                    "key": 1,
                    "value": 2
                },
                {
                    "key": 2,
                    "formattingValue": 2
                }
            ],
            "restrictions": [
                {
                    "mimeTypes": [],
                    "mimeTypesDataSource": [],
                    "key": 7
                }
            ],
            "x": 6,
            "y": 5,
            "cols": 6,
            "rows": 1,
            "fieldOrigin": 3,
            "isFilter": false,
            "canRemove": true,
            "isContractSystemField": true,
            "currentDateTime": false,
            "labelFormatting": {
                "id": 6,
                "showColon": false,
                "fontStyle": 0,
                "fontSize": 5,
                "fontColor": "#000",
                "alignHorizontal": 3,
                "alignVertical": 2,
                "labelValueAlignment": 1
            },
            "valueFormatting": {
                "id": 5,
                "showColon": false,
                "size": 50,
                "fontStyle": 0,
                "fontSize": 5,
                "fontColor": "#000",
                "alignHorizontal": 3,
                "alignVertical": 2,
                "labelValueAlignment": 1
            }
        },
        {
            "id": -19,
            "name": "state_name",
            "systemFieldName": "state_name",
            "label": "Status",
            "typeId": 1,
            "displayTypeId": 12,
            "formattings": [],
            "restrictions": [],
            "x": 8,
            "y": 2,
            "cols": 3,
            "rows": 2,
            "fieldOrigin": 3,
            "isFilter": false,
            "canRemove": true,
            "isContractSystemField": false,
            "currentDateTime": false,
            "labelFormatting": {
                "id": 28,
                "showColon": true,
                "fontStyle": 1,
                "fontSize": 4,
                "fontColor": "#000",
                "alignHorizontal": 1,
                "alignVertical": 2
            },
            "valueFormatting": {
                "id": 27,
                "showColon": false,
                "fontStyle": 1,
                "fontSize": 4,
                "fontColor": "#000",
                "alignHorizontal": 3,
                "alignVertical": 3,
                "labelValueAlignment": 1
            }
        },
        {
            "id": -41,
            "name": "Total Approved Change Order Cost",
            "systemFieldName": "total_approved_co_cost",
            "label": "Change order value",
            "typeId": 2,
            "displayTypeId": 13,
            "formattings": [
                {
                    "key": 1,
                    "value": 2
                },
                {
                    "key": 2,
                    "formattingValue": 2
                }
            ],
            "restrictions": [
                {
                    "mimeTypes": [],
                    "mimeTypesDataSource": [],
                    "key": 7
                }
            ],
            "x": 0,
            "y": 6,
            "cols": 6,
            "rows": 1,
            "fieldOrigin": 3,
            "isFilter": false,
            "canRemove": true,
            "isContractSystemField": true,
            "currentDateTime": false,
            "labelFormatting": {
                "id": 22,
                "showColon": false,
                "fontStyle": 0,
                "fontSize": 5,
                "fontColor": "#000",
                "alignHorizontal": 3,
                "alignVertical": 2,
                "labelValueAlignment": 1
            },
            "valueFormatting": {
                "id": 21,
                "showColon": false,
                "fontStyle": 1,
                "fontSize": 5,
                "fontColor": "#000",
                "alignHorizontal": 3,
                "alignVertical": 2,
                "labelValueAlignment": 1
            }
        },
        {
            "id": -40,
            "name": "Total Approved Payment Amount",
            "systemFieldName": "total_approved_pp_amount",
            "label": "Total earned to date",
            "typeId": 2,
            "displayTypeId": 1,
            "formattings": [
                {
                    "key": 1,
                    "value": 2
                },
                {
                    "key": 2,
                    "formattingValue": 2
                }
            ],
            "restrictions": [
                {
                    "mimeTypes": [],
                    "mimeTypesDataSource": [],
                    "key": 7
                }
            ],
            "x": 6,
            "y": 6,
            "cols": 6,
            "rows": 1,
            "fieldOrigin": 3,
            "isFilter": false,
            "canRemove": true,
            "isContractSystemField": true,
            "currentDateTime": false,
            "labelFormatting": {
                "id": 24,
                "showColon": false,
                "fontStyle": 0,
                "fontSize": 5,
                "fontColor": "#000",
                "alignHorizontal": 3,
                "alignVertical": 2,
                "labelValueAlignment": 1
            },
            "valueFormatting": {
                "id": 23,
                "showColon": false,
                "size": 50,
                "fontStyle": 0,
                "fontSize": 5,
                "fontColor": "#000",
                "alignHorizontal": 3,
                "alignVertical": 2,
                "labelValueAlignment": 1
            }
        },
    ]
};
