<div mat-dialog-title fxLayout fxLayoutAlign="space-between center">
    <h5>{{ "Add field" }}</h5>
    <button mat-icon-button [mat-dialog-close]="undefined">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <div>
        <h4>Field Summary</h4>
    </div>
    <form [formGroup]="form">
        <div class="cipo-bg--primary-100 p-a">
            <h4>Field details</h4>
            <div fxLayout fxLayout.lt-md="column" fxLayoutGap="16px">
                <div fxFlex fxLayoutGap="16px" fxLayout="column">
                    <mat-form-field class="cipo-input hide-subscript full-width">
                        <mat-label>Name</mat-label>
                        <input matInput formControlName="name" />
                    </mat-form-field>
                    <mat-form-field class="cipo-input hide-subscript full-width">
                        <mat-label>Label</mat-label>
                        <input matInput formControlName="label" />
                    </mat-form-field>
                    <mat-form-field class="cipo-input hide-subscript full-width">
                        <mat-label>Hint</mat-label>
                        <input matInput formControlName="hint" />
                    </mat-form-field>
                </div>
                <div fxFlex="50">
                    <mat-form-field class="cipo-input hide-subscript full-width">
                        <mat-label>Default value</mat-label>
                        <input matInput formControlName="defaultValue" />
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="cipo-bg--accent-50 p-a m-t">
            <h4>Field Options</h4>
            <div fxLayout fxLayout.lt-md="column">
                <div fxLayout="column" fxFlex>
                    <mat-checkbox formControlName="multiple">Multiple</mat-checkbox>
                    <mat-checkbox formControlName="required">Required</mat-checkbox>
                    <mat-checkbox formControlName="readonly">Readonly</mat-checkbox>
                    <mat-checkbox formControlName="showSeparator">Show thousand separator</mat-checkbox>
                    <mat-checkbox formControlName="isFilter">Always show this field on filters</mat-checkbox>
                </div>
                <div fxFlex fxLayoutGap="12px">
                    <mat-form-field class="cipo-input hide-subscript full-width">
                        <mat-label>Type</mat-label>
                        <mat-select formControlName="type">
                            <mat-option *ngFor="let type of fieldTypes()" [value]="type.key">
                                <mat-icon>{{ ICONS[type.key] }}</mat-icon>
                                {{ type.value }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="cipo-input hide-subscript full-width">
                        <mat-label>Max length</mat-label>
                        <input matInput type="number" formControlName="maximumLength" />
                    </mat-form-field>
                    <mat-form-field class="cipo-input full-width">
                        <mat-label>Decimals</mat-label>
                        <input matInput type="number" formControlName="decimals" />
                        <mat-hint>If the value is 0 or unset, the number will be an integer</mat-hint>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="undefined">{{ "general.cancel" | translate }}</button>
    <button mat-button mat-raised-button color="primary" [disabled]="!form.valid || !form.dirty" (click)="saveField()">
        {{ "general.save" | translate }}
    </button>
</mat-dialog-actions>
