import { ChangeDetectionStrategy, Component, signal, inject } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { Language, LanguageNames } from '../../models/system/language';
import { ColorPaletteComponent } from './../../demo/angular/demo-theme/color-palette/color-palette.component';
import { LocaleService } from '../../shared/services/locale.service';
import { SharedComponentsModule } from '../../shared/modules';
import { CipoTheme, CipoThemeMode, TenantStore, UserStore } from '../../signal-store';
import { CipoImgBase64 } from '../../shared/components/img-base64/img-base64.component';
import { MsalAuthService } from '../../shared/services';

@Component({
  selector: 'app-account-menu',
  templateUrl: './account-menu.component.html',
  styleUrls: ['./account-menu.component.scss'],
  standalone: true,
  imports: [SharedComponentsModule, CipoImgBase64],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountMenuComponent {
  router = inject(Router);
  userStore = inject(UserStore);
  tenantStore = inject(TenantStore);
  authService = inject(MsalAuthService);
  isOpen = signal(false);
  lang = Language.English;

  constructor(
    private localeService: LocaleService,
    private dialog: MatDialog,
  ) {}

  get languageType(): typeof Language {
    return Language;
  }

  get languageTypes(): number[] {
    var t = Object.keys(Language)
      .filter(l => !isNaN(Number(l)))
      .map(l => Number(l));
    return t;
  }

  changeLanguage(language: Language) {
    this.lang = language;
    const langName = LanguageNames.get(this.lang);
    this.localeService.setLocale(langName);
    this.userStore.setLanguage(langName);
  }

  changeTheme(color: CipoTheme) {
    this.userStore.updateTheme({ color });
  }

  changeThemeMode(mode: CipoThemeMode) {
    this.userStore.updateTheme({ mode });
  }

  signOut() {
    this.authService.logout();
  }

  openPalette() {
    this.dialog.open(ColorPaletteComponent, {
      panelClass: ['cipo-dialog', 'classic'],
    });
  }

  goToProfile() {
    this.router.navigate([this.tenantStore.selectedTenantName(), 'userprofile']);
  }

  goToTenants() {
    if (this.userStore.menu.isOpen()) {
      this.userStore.toggleMenu();
    }
    this.tenantStore.selectTenant(null);
    this.router.navigate(['tenant-list']);
  }
}
