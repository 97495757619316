import { DestroyRef, Directive, ElementRef, inject } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { combineLatest, debounceTime, fromEvent } from 'rxjs';
import { UserStore } from '../../../signal-store';

@Directive({
  selector: '[appendColor]',
})
export class AppendColorDirective {
  private userStore = inject(UserStore);
  theme$ = toObservable(this.userStore.theme.color).pipe(takeUntilDestroyed());
  themeMode$ = toObservable(this.userStore.theme.mode).pipe(takeUntilDestroyed());
  constructor(
    private el: ElementRef,
    private destroyRef: DestroyRef,
  ) {}

  ngAfterViewInit() {
    const element = this.el.nativeElement as HTMLInputElement;
    const { color, mode } = this.userStore.theme();
    let themeElementRef = document.querySelector<HTMLElement>(`.${color}-theme.${mode}-mode`);

    combineLatest([this.theme$, this.themeMode$])
      .pipe(takeUntilDestroyed(this.destroyRef), debounceTime(1))
      .subscribe(([theme, themeMode]) => {
        themeElementRef = document.querySelector(`.${theme}-theme.${themeMode}-mode`);
        const computedStyles = getComputedStyle(themeElementRef);
        element.value = computedStyles.getPropertyValue(`--${element.id}`);
      });

    fromEvent(element, 'input')
      .pipe(debounceTime(500), takeUntilDestroyed(this.destroyRef))
      .subscribe(event => {
        const color = (event.target as HTMLInputElement).value;
        navigator.clipboard.writeText(color);
        themeElementRef.style.setProperty(`--${element.id}`, color);
      });
  }
}
