import { Component } from '@angular/core';
import { DataListViewAction } from '../../../models/module/main/dataListViewAction';
import { ScreenModel } from '../../../models/module/screen';
import { DataCardSettingsService } from '../../../module/definition/data-card/data-card-settings/data-card-settings.service';
import { DemoDataCardSettingsService } from './demo-data-card-settings.service';

@Component({
  selector: 'app-demo-data-card-settings.component',
  templateUrl: './demo-data-card-settings.component.html',
  providers: [{ provide: DataCardSettingsService, useClass: DemoDataCardSettingsService }],
})
export class DemoDataCardSettingsComponent {
  actions: DataListViewAction[] = [];

  screen: ScreenModel = {
    anyFieldInUse: false,
    id: 194,
    inUse: false,
    isStartScreen: false,
    name: 'Default',
    roleIds: [],
    type: 3,
    version: 1,
    versionCount: 1,
    versionId: 35,
  };
}
