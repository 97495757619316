import { cipo } from 'cipo';

cipo.controller('filecontractmanagerController',
    function ($transition$, $scope, $state, APP_CONTENT, Explorer1, userService, FileManagerConfig) {
        const params = $transition$.params();
        $scope.moduleName = userService.system.mNames[$state.current.code];
        $scope.content = APP_CONTENT;

        Object.defineProperty($scope, 'module', {
            get: function () { return userService.system.modules[$state.current.code]; }
        });

        $scope.tempOps = { Read: true, Create: true, Delete: true, Update: true };
        
        $scope.explorerData = FileManagerConfig.projectFiles;

        Object.defineProperty($scope.explorerData.tree.urlParams, 'entityInstanceId', {
            get: function () {
                return userService.system.userdata.contractEntityInstanceId;
            },
            set: function() {}
        });

        // ensure context is properly loaded
        userService.system.context = userService.getContextWhenContractIsIncomplete();

        $scope.loadExplorer = function (storageType) {
            $scope.explorer = new Explorer1({
                navigateTo: function (route, params) {
                    $state.go(route, params);
                },
                abbreviation: 'FCM',
                layout: 1,
                hasGlobal: true,
                rootName: "...",
                treeSetup: {
                    treeOptions: {
                        nodeChildren: "folders",
                        allowDeselect: false,
                        dirSelectable: true,
                        multiSelection: false,
                        isLeaf: function (node) { if ((node.id == -10) || (node.id == -15)) return true; else return false; },
                        injectClasses: {
                            ul: "a1",
                            li: "a2",
                            liSelected: "a7",
                            iExpanded: "a3",
                            iCollapsed: "a4",
                            iLeaf: "a5",
                            label: "a6",
                            labelSelected: "a8"
                        }
                    },
                    orderby: ""
                },
                urlsData: storageType,
                managerSetup: {
                    search: true,
                    customFilters: [
                        { key: "isGlobal", value: false, label: "Global Search", type: "checkbox" },
                        { key: "showAllPhotos", value: false, label: "Show images only", type: "checkbox" }
                    ],
                    columns: {
                        1: [
                            { name: "_name", label: 'Name', type: 'checkbox', replacementFor: "name", onTablet: true, onPhone: true, width: 30, fieldTypeId: 1 },
                            { name: "createdBy", replacementFor: "createdBy", label: 'Owner', type: 'text', onTablet: true, onPhone: false, width: 25, fieldTypeId: 1 },
                            { name: "createdOn", replacementFor: "createdOn", label: 'Created On', type: 'date', onTablet: true, onPhone: false, width: 15, fieldTypeId: 4, options: userService.userSettings().datetimeFormat },
                            { name: "updatedOn", replacementFor: "updatedOn", label: 'Last Modified', type: 'date', onTablet: true, onPhone: false, width: 15, fieldTypeId: 4, options: userService.userSettings().datetimeFormat },
                            { name: "_hasShared", replacementFor: "hasShared", label: 'Shared', type: 'checkbox', onTablet: true, onPhone: false, width: 8, fieldTypeId: 1 },
                            { name: "length", replacementFor: "length", label: 'File Size', type: 'checkbox', onTablet: true, onPhone: false, width: 15 } // fieldTypeId: 2
                        ],
                        2: [
                            { name: "_big_name", label: 'Name', type: 'checkbox' }
                        ]
                    },
                    searchColumns: [
                        { name: "_name", label: 'Name', type: 'checkbox', replacementFor: "name", onTablet: true, onPhone: true, width: 25, fieldTypeId: 1 },
                        { name: "folderPath", replacementFor: "folderPath", label: 'Folder', type: 'checkbox',  onTablet: true, onPhone: true, width: 25, fieldTypeId: 1 },
                        { name: "createdBy", replacementFor: "createdBy", label: 'Owner', type: 'text', onTablet: true, onPhone: false, width:  20, fieldTypeId: 1 },
                        { name: "createdOn", replacementFor: "createdOn", label: 'Created On', type: 'date', onTablet: true, onPhone: false, width: 10, fieldTypeId: 4, options: userService.userSettings().datetimeFormat },
                        { name: "updatedOn", replacementFor: "updatedOn", label: 'Last Modified', type: 'date', onTablet: true, onPhone: false, width: 10, fieldTypeId: 4, options: userService.userSettings().datetimeFormat },
                        // { name: "isContractGlobal", replacementFor: "isContractGlobal", label: 'Global', type: 'text', onTablet: true, onPhone: true, width: 10, fieldTypeId: 3 },
                        { name: "length", label: 'File Size', type: 'checkbox', onTablet: true, onPhone: false, width: 10 } // fieldTypeId: 2
                    ],
                    globalSearchColumns: [
                        { name: "_name", label: 'Name', type: 'checkbox', replacementFor: "name", onTablet: true, onPhone: true, width: 20, fieldTypeId: 1 },
                        { name: "folderPath", replacementFor: "folderPath", label: 'Folder', type: 'checkbox',  onTablet: true, onPhone: true, width: 15, fieldTypeId: 1 },
                        { name: "contractName", replacementFor: "contractName", label: 'Contract', type: 'checkbox',  onTablet: true, onPhone: true, width: 15, fieldTypeId: 1 },
                        { name: "createdBy", replacementFor: "createdBy", label: 'Owner', type: 'text', onTablet: true, onPhone: false, width: 10, fieldTypeId: 1 },
                        { name: "createdOn", replacementFor: "createdOn", label: 'Created On', type: 'date', onTablet: true, onPhone: false, width: 10, fieldTypeId: 4, options: userService.userSettings().datetimeFormat },
                        { name: "updatedOn", replacementFor: "updatedOn", label: 'Last Modified', type: 'date', onTablet: true, onPhone: false, width: 10, fieldTypeId: 4, options: userService.userSettings().datetimeFormat },
                        // { name: "isContractGlobal", replacementFor: "isContractGlobal", label: 'Global', type: 'text', onTablet: true, onPhone: true, width: 10, fieldTypeId: 3 },
                        { name: "length", label: 'File Size', type: 'checkbox', onTablet: true, onPhone: false, width: 10 } // fieldTypeId: 2
                    ],
                    recycleBinColumns: [
                        { name: "_name", label: 'Name', type: 'checkbox', replacementFor: "name", onTablet: true, onPhone: true, width: 25, fieldTypeId: 1 },
                        //{ name: "contractName", replacementFor: "contractName", label: 'Contract', type: 'checkbox',  onTablet: true, onPhone: true, width: 15, fieldTypeId: 1 },
                        { name: "updatedBy", replacementFor: "updatedBy", label: 'Deleted By', type: 'text', onTablet: true, onPhone: false, width: 10, fieldTypeId: 1 },
                        { name: "updatedOn", replacementFor: "updatedOn", label: 'Deleted On', type: 'date', onTablet: true, onPhone: false, width: 20, fieldTypeId: 4, options: userService.userSettings().datetimeFormat },
                        { name: "pathWhenDeleted", replacementFor: "pathWhenDeleted", label: 'Original Location', type: 'checkbox', onTablet: true, onPhone: true, width: 20, fieldTypeId: 1 },
                        // { name: "updatedOn", replacementFor: "updatedOn", label: 'Last Modified', type: 'text', onTablet: true, onPhone: false, width: 10, fieldTypeId: 4, options: userService.userSettings().datetimeFormat },
                        // { name: "isContractGlobal", replacementFor: "isContractGlobal", label: 'Global', type: 'text', onTablet: true, onPhone: true, width: 10, fieldTypeId: 3 },
                        { name: "length", label: 'File Size', type: 'checkbox', onTablet: true, onPhone: false, width: 15 } // fieldTypeId: 2
                    ],
                    archiveColumns: [
                        { name: "archiveName", label: 'Name', type: 'checkbox', replacementFor: "archiveName", onTablet: true, onPhone: true, width: 30, fieldTypeId: 1 },
                        { name: "archiveDescription", replacementFor: "archiveDescription", label: 'Description', type: 'checkbox', onTablet: true, onPhone: true, width: 27, fieldTypeId: 1 },
                        { name: "createdOn", replacementFor: "createdOn", label: 'Created On', type: 'date', onTablet: true, onPhone: false, width: 15, fieldTypeId: 4, options: userService.userSettings().datetimeFormat },
                        { name: "createdBy", replacementFor: "createdBy", label: 'Created By', type: 'text', onTablet: true, onPhone: false, width: 25, fieldTypeId: 1 },                           
                        { name: "filesNumber", replacementFor: "filesNumber", label: 'No. of Files', type: 'checkbox', onTablet: true, onPhone: false, width: 8 }
                    ],
                    archiveFileColumns: [
                        { name: "archiveFileName", label: 'Name', type: 'checkbox', replacementFor: "archiveFileName", onTablet: true, onPhone: true, width: 30, fieldTypeId: 1 },
                        { name: "module", replacementFor: "module", label: 'Module', type: 'checkbox', onTablet: true, onPhone: true, width: 30, fieldTypeId: 1 },
                        { name: "createdOn", replacementFor: "createdOn", label: 'Created On', type: 'date', onTablet: true, onPhone: false, width: 15, fieldTypeId: 4, options: userService.userSettings().datetimeFormat },
                        { name: "createdBy", replacementFor: "createdBy", label: 'Created By', type: 'text', onTablet: true, onPhone: false, width: 15, fieldTypeId: 1 },
                        { name: "fileSize", replacementFor: "fileSize", label: 'File Size', type: 'checkbox', onTablet: true, onPhone: false, width: 15 }
                    ]
                },
                context: userService.system.context,
            });

            // Open file detail based on state params
            if (typeof params.f != "undefined" && params.f.length) {
                $scope.explorer.fileDetails({ id: params.f });
            }
        };

        $scope.loadExplorer($scope.explorerData);

        $scope.$watch(
            function () {
                return userService.system.context.contract?.entityInstanceId ?? userService.system.context.project?.entityInstanceId ?? userService.system.context.program?.entityInstanceId;
            },
            function () {
                if ($scope.explorer) {
                    $scope.explorer.context = userService.system.context;
                    $scope.explorer.contextChanged();
                }
            }
        );
    });
