import { cipo } from 'cipo';

cipo.factory("DataListConfig", function ($q, Model, URI, Message, FIELDS_CONFIG, 
    AggregateOperators, ModuleDictionaries, Dictionaries, $timeout, Name) {
    var DataListConfig = Model.extend(function (moduleId, relationId) {
        var self = this;
        self.relationId = relationId || null;
        self.fieldsConfig = FIELDS_CONFIG();

        self.currentFieldsLookup = {};

        self.AggregateOperators = [];
        self.bottomFormulas = [];
        self.bottomFormulasLookup = {};

        // set bottom formulas ready
        for (var key in AggregateOperators) {
            if (AggregateOperators.hasOwnProperty(key)) {
                self.AggregateOperators.push({ key: parseInt(key), value: AggregateOperators[key] });
                var op = { key: parseInt(key), value: AggregateOperators[key], fields: [] };
                self.bottomFormulas.push(op);
                self.bottomFormulasLookup[key] = op;
            }
                
        }

        for (var index = 0; index < self.bottomFormulas.length; index++) {
            (function (i) {
                Object.defineProperty(self.bottomFormulas[i], "visibleOnTablet", {
                    get: function () {
                        var visible = false;
                        for (var j = 0; j < self.bottomFormulas[i].fields.length; j++) {
                            if ((self.currentFieldsLookup[self.bottomFormulas[i].fields[j]] || {}).onTablet) {
                                visible = true;
                                break;
                            }
                        }
                        return visible;
                    }
                })

                Object.defineProperty(self.bottomFormulas[i], "visibleOnPhone", {
                    get: function () {
                        var visible = false;
                        for (var j = 0; j < self.bottomFormulas[i].fields.length; j++) {
                            if ((self.currentFieldsLookup[self.bottomFormulas[i].fields[j]] || {}).onPhone) {
                                visible = true;
                                break;
                            }
                        }
                        return visible;
                    }
                })

            })(index);
            
        }

        Object.defineProperty(self, "hasBottomFormulas", {
            get: function () {
                var hasBottomFormulas = false;
                for (var i = 0; i < self.bottomFormulas.length; i++) {
                    if (self.bottomFormulas[i].fields.length) {
                        hasBottomFormulas = true;
                        break;
                    }
                }
                return hasBottomFormulas;
            }
        } )

        self.inGrid = {
            list: [],
            isBusy: false,
            _searchTerm: ""
        };
        self.offGrid = {
            list: [],
            isBusy: false,
            _searchTerm: ""
        };

        self.isBusy = false;
        self.editMode = false;
        self.aggregateMode = false;
        self.complexMode = false;

        Object.defineProperty(self, "noLeaveView", {
            get: function () {
                var noLeaveView = self.isBusy || self.inGrid.isBusy || self.offGrid.isBusy || self.editMode || self.aggregateMode || self.complexMode || self.isShowReports;
                
                return noLeaveView;
            }
        })

        self.bckpOnGridList = [];
        
        self.relationId = relationId || null;
        self.moduleId = moduleId || ModuleDictionaries.moduleInfo.moduleId;
        self.init();

    });

    DataListConfig.prototype.selectAllOptions = function (items, model) {
        for (var i = 0; i < items.length; i++) {
            items[i].isUsed = true;
            model.push(items[i].key);
        }
    }

    DataListConfig.prototype.deselectAllOptions = function (items, model) {
        for (var i = 0; i < items.length; i++) {
            if (!items[i].isDisabled && items[i].isUsed) {
                items[i].isUsed = false;
                model.splice(model.indexOf(items[i].key), 1);
            }
            
            
        }
    }

    DataListConfig.prototype.saveFieldsReport = function () {
        var self = this;
        var p = $q.defer();
        self.isBusy = true;
        var urlData = URI.FIELD_DEFINITION.SYNC_FIELDS_ON_REPORTS;
        self[urlData.method](urlData, { url: {}, body: self.reportsInfo.fieldIds }, { headers: { moduleId: self.moduleId } })
            .then(function (r) {
                self.isShowReports = false;
                p.resolve(r);
                Message.info('Report fields updated.')
            })
            .catch(function (e) { p.reject(e); Message.dberror(e); })
            .finally(function (e) { self.isBusy = false;  })
        return p.promise;
    }

    DataListConfig.prototype.showReportsConfig = function () {
        var self = this;
        self.isShowReports = true;
        var urlData = URI.FIELD_DEFINITION.FIELDS_ON_REPORTS;
        var p = $q.defer();
        self.reportsInfo = {
            fieldIds: [],
            loadingFields: true,
            allFields: [],
            _searchTerm: ""
        };
        self[urlData.method](urlData, {}, { headers: { moduleId: self.moduleId } })
            .then(function (r) {
                self.reportsInfo.allFields = r || [];
                // isUsed
                if ((r || []).length) {
                    for (var i = 0; i < r.length; i++) {
                        if (r[i].isUsed) self.reportsInfo.fieldIds.push(r[i].key);
                    }
                }
                p.resolve(r);
            })
            .catch(function (e) { p.reject(e); })
            .finally(function (e) { self.reportsInfo.loadingFields = false; })
        return p.promise;

    }

    DataListConfig.prototype.setPrimarySort = function (item) {
        var self = this;
        for (var i = 0; i < self.inGrid.list.length; i++) {
            if (self.inGrid.list[i].id != item.id) {
                self.inGrid.list[i].isPrimarySort = false;
                self.inGrid.list[i].isDescending = true;
            }
            
        }
        if (item.isPrimarySort) item.isDescending = !item.isDescending;
        else {
            item.isPrimarySort = true;
        }
    }


    DataListConfig.prototype.init = function () {
        var self = this;
        self.topFormulas = { };
        if (!self.topFormulas.dashboard) self.topFormulas.dashboard = [];

        self.get_List(true)
            .then(function () {
                self.get_topFormulas();
            })
            .catch(function () {

            })
            .finally(function () {
                // self.get_List();
            })

        
    }

    DataListConfig.prototype.setShowColorCode = function (list, item) {
        var showColorCode = item.showColorCode;
        (list || []).forEach(e => e.showColorCode = false);
        item.showColorCode = !showColorCode;
    }

    DataListConfig.prototype.editLabel = function (item) {
        var self = this;
        self.isBusy = true;
        item.editableItem = new Name(item, "label", "Label");
    }


    DataListConfig.prototype.applyFilter = function (event, visibleOnGrid) {
        var self = this;
        var listName = visibleOnGrid ? "inGrid" : "offGrid";
        event.stopPropagation();
        if (event.keyCode != 13) {
            if (self[listName].timeout) $timeout.cancel(self[listName].timeout);
            self[listName].timeout = $timeout(function () {
                // field._getDataSources();
                self.filterList(visibleOnGrid);
                $timeout.cancel(self[listName].timeout);
            }, 1000);
        } else {
            if (self[listName].timeout) $timeout.cancel(self[listName].timeout);
            self.filterList(visibleOnGrid);
            // field._getDataSources();
        }
    }

    DataListConfig.prototype.filterList = function (visibleOnGrid) {
        var self = this;
        var listName = visibleOnGrid ? "inGrid" : "offGrid";
        var searchTerm = self[listName]._searchTerm.toLowerCase();
        for (var i = 0; i < self[listName].list.length; i++) {
            if (((self[listName].list[i].name || '').toLowerCase()).indexOf(searchTerm) == -1
                && ((self[listName].list[i].label || '').toLowerCase()).indexOf(searchTerm) == -1
                && ((self.fieldsConfig.fieldTypes[self[listName].list[i].typeId] || '').toLowerCase()).indexOf(searchTerm) == -1
                && (((self.fieldsConfig.displayTypeDict[self[listName].list[i].displayTypeId] || {}).name || '').toLowerCase()).indexOf(searchTerm) == -1 ) {
                self[listName].list[i].isHide = true;
            } else self[listName].list[i].isHide = false;
        }
    }

    DataListConfig.prototype.updateField = function (item, isPrintToggle) {
        var self = this;
        var toSend;
        if (isPrintToggle) {
            item.printable = !item.printable;
            toSend = item;
            item.isBusy = true;
        }
        if (item.editableItem) {
            item.editableItem.form.loading = true;
            toSend = item.editableItem.properties;
        }
        var dataURL = URI.MODULE_DATA_LIST.UPDATE_FIELD;
        self[dataURL.method](dataURL, { url: {}, urltype: 'obj', body: toSend },
            { headers: { moduleId: self.moduleId } })
            .then(function () {
                Message.info("Field updated successfully");
                if (item.editableItem) {
                    item.label = item.editableItem.properties.label;
                    item.editableItem = null;
                    self.isBusy = false;
                }

                self.isModified = true;

                item.label = toSend.label;
                item.printable = toSend.printable;
            })
            .catch(function (e) {
                Message.dberror(e);
                if (item.editableItem) {
                    item.editableItem.form.loading = false;
                }
                if (isPrintToggle) {
                    item.printable = !item.printable;
                }
            })
            .finally(function () {
                item.isBusy = false;
            })
    }

    DataListConfig.prototype.cancelEditList = function () {
        var self = this;
        self.inGrid.list = angular.copy(self.bckpOnGridList);

        self.processBottomFormulasInfo(self.inGrid.list);

        self.inGrid._searchTerm = "";
        self.filterList(true);
        self.offGrid._searchTerm = "";
        self.filterList();
        self.editMode = false;
    }

    DataListConfig.prototype.resetBottomFormulas = function () {
        var self = this;
        for (var i = 0; i < self.bottomFormulas.length; i++) {
            self.bottomFormulas[i].fields = [];
        }
    }

    DataListConfig.prototype.setBottomFormulasInfoOnField = function (field) {
        var self = this;
        field.operators = [];
        for (var j = 0; j < self.AggregateOperators.length; j++) {
            var op = angular.copy(self.AggregateOperators[j]);
            op.isUsed = false;
            if ((field.aggregateFunctions || []).indexOf(op.key) != -1) {
                op.isUsed = true;
                if (self.bottomFormulasLookup[op.key].fields.indexOf(field.dataListFieldId) == -1)
                    self.bottomFormulasLookup[op.key].fields.push(field.dataListFieldId);
            }
            field.operators.push(op);
        }
    }

    DataListConfig.prototype.processBottomFormulasInfo = function (list) {
        var self = this;
        self.aggregatableFieldsNo = 0;
        self.currentFieldsLookup = {};
        self.resetBottomFormulas();

            for (var i = 0; i < list.length; i++) {
                // set bottom formulas on numeric fields and add numeric fields to bottom formulas, if operators already set
                if ((list[i].typeId == 2 || list[i].typeId == 9) && !list[i].hasMultipleValues) {
                    self.setBottomFormulasInfoOnField(list[i]);

                self.aggregatableFieldsNo++;
            }
            self.currentFieldsLookup[list[i].dataListFieldId] = list[i];
        }

        
    }

    DataListConfig.prototype.get_List = function (visibleOnGrid) {
        var self = this;
        var p = $q.defer();
        if (!visibleOnGrid) self.editMode = true;

        if (visibleOnGrid) self.resetBottomFormulas();

        var listName = visibleOnGrid ? "inGrid" : "offGrid";
        
        self[listName]._searchTerm = "";

        self[listName].isBusy = true;
        self[listName].options = {
            beforeDrop: function (e) {
                processNode(e.source.nodeScope.$modelValue);
                return true;
            },
            dropped: function (e) {
                if (!e.dest.nodesScope.nodropEnabled) {
                    self.processDrop(e);
                    return true;
                }
                else return false;
            },
            nodropEnabled: visibleOnGrid ? false : true
        }

        var urlParams = {};

        

        if (self.relationId) urlParams = { url: { relationId: self.relationId }, urltype: 'obj' };

        var urlData = visibleOnGrid ? URI.MODULE_DATA_LIST.SEARCH : URI.MODULE_DATA_LIST.MODULE_FIELDS;
        self[urlData.method](urlData, urlParams, { headers: { moduleId: self.moduleId } })
            .then(function (r) {
                // self.fieldsList = r;
                
                if (r && (r.data || []).length && visibleOnGrid) {
                    self.id = r.data[0].dataListId;
                    self.defaultField = null;
                    

                    for (var i = 0; i < r.data.length; i++) {
                        
                        if (!r.data[i].width) r.data[i].width = 1;
                        if (r.data[i].position == 0) r.data[i].position = i;
                        //set flags
                        if ((1 & r.data[i].priority) != 0) r.data[i].onPhone = true;
                        else r.data[i].onPhone = false;
                        if ((2 & r.data[i].priority) != 0) r.data[i].onTablet = true;
                        else r.data[i].onTablet = false;

                        r.data[i].isHide = false;
                    }



                    // set bottom formulas on numeric fields and add numeric fields to bottom formulas,
                    // if operators already set
                    self.processBottomFormulasInfo(r.data);

                    // set width
                    r.data = self.set_fieldsWidth(r.data);

                    self.bckpOnGridList = angular.copy(r.data);
                                            

                }
                // onPhone 1 onTablet 2
                self[listName].list = r.data;

                if (!visibleOnGrid) self.filterList();
                

                p.resolve(r);
            })
            .catch(function (e) {
                p.reject();
                Message.dberror(e);
                console.error(e);
            })
            .finally(function () {
                self[listName].isBusy = false;
                self[listName].isFieldsLoaded = true;
            })
        return p.promise;
    }

    DataListConfig.prototype.set_fieldsWidth = function (fields) {
        var self = this;
        var parts = 0;
        var fields = fields || self.inGrid.list || [];
        for (var i = 0; i < fields.length; i++) {
            parts += fields[i].width;
            //fields[i].widthPercentage = 100 / parts * fields[i].width;
        }
        for (var i = 0; i < fields.length; i++) {
            //parts += fields[i].width;
            fields[i].widthPercentage = 100 / parts * fields[i].width;
        }
        return fields;

    }

    DataListConfig.prototype.addFieldToList = function (field, e) {
        var self = this;
        processNode(field);
        self.inGrid.list.push(field);
        self.offGrid.list.splice(self.offGrid.list.indexOf(field), 1);
        self.set_fieldsWidth();
    }

    DataListConfig.prototype.processDrop = function (e) {
        var self = this;
        // e.source.nodeScope.$modelValue
        var fields = e.dest.nodesScope.$modelValue;
        self.set_fieldsWidth(fields);
        //self.isBusy = true;
        //self.sync(fields);
        //self.get_List();
    }

    var processNode = function (field) {
        //var self = this;
        //var fields = e.dest.nodesScope.$modelValue;
        var field = field || {};
        field.width = 1;
        // field.priority = 3;
        field.onPhone = true;
        field.onTablet = true;
        
        field.printable = true;
        field.isDescending = true;
        field.isPrimarySort = false;
        

    }

    var compare = function (a, b) {
        
        var ret = a.label > b.label ? 1 : -1;
        ret = a.label == b.label ? 0 : ret;
        return ret;
    }


    DataListConfig.prototype.removeItem = function (scope, item) {
        var self = this;
        //var p = $q.defer();
        // scope.remove();

        self.inGrid.list.splice(self.inGrid.list.indexOf(item), 1);

            // if number
            if (item.typeId == 2 || item.typeId == 9) {
                // if bottom formulas present
                if (item.aggregateFunctions.length) {
                    // reset operators
                    for (var i = 0; i < item.operators.length; i++) {
                        item.operators[i].isUsed = false;
                    }

                // remove from bottom formulas
                for (var i = 0; i < item.aggregateFunctions.length; i++) {
                    self.bottomFormulasLookup[item.aggregateFunctions[i]].fields.splice(self.bottomFormulasLookup[item.aggregateFunctions[i]].fields.indexOf(item.dataListFieldId), 1);
                }

                    item.aggregateFunctions = [];
                }
            }

            self.offGrid.list.push(item);
            self.offGrid.list.sort(compare);
        }

    DataListConfig.prototype.processFieldsPreSave = function (fields) {
        if (fields && fields.length) {
            for (var i = 0; i < fields.length; i++) {
                fields[i].position = i+1;
                fields[i].priority = (fields[i].onPhone ? 1 : 0) + (fields[i].onTablet ? 2 : 0);
                delete fields[i].onPhone;
                delete fields[i].onTablet;
                delete fields[i].widthPercentage;
                // delete fields[i].onTablet;
            }
        }
    }

    DataListConfig.prototype.sync = function () {
        var self = this;
        var p = $q.defer();
        var fields = angular.copy(self.inGrid.list);
        self.inGrid.isBusy = true;
        self.isBusy = true;
        
        self.processFieldsPreSave(fields);
        var urlData = URI.MODULE_DATA_LIST.SYNC_FIELDS;
        var urlParams = {};

        if (self.relationId) urlParams = { relationId: self.relationId };

        self[urlData.method](urlData, { url: urlParams, urltype: 'obj', body: fields },
            { headers: { moduleId: self.moduleId } })
            .then(function (r) {
                Message.info("Fields updated successfully");
                self.isModified = true;
                self.get_List(true);
                self.get_List();
                p.resolve();
            })
            .catch(function (e) {
                p.reject();
                self.inGrid.isBusy = false;
                Message.dberror(e);
            })
            .finally(function () {
                self.isBusy = false;
            })
        return p.promise;
    }


    DataListConfig.prototype.toggleOperator = function (event, op, dataListFieldId) {
        var self = this;
        if (op.isUsed) {
            self.currentFieldsLookup[dataListFieldId].aggregateFunctions.splice(self.currentFieldsLookup[dataListFieldId].aggregateFunctions.indexOf(op.key), 1);
            self.bottomFormulasLookup[op.key].fields.splice(self.bottomFormulasLookup[op.key].fields.indexOf(dataListFieldId), 1);
        } else {
            self.currentFieldsLookup[dataListFieldId].aggregateFunctions.push(op.key);
            self.bottomFormulasLookup[op.key].fields.push(dataListFieldId);
        }
        op.isUsed = !op.isUsed;


    }

    DataListConfig.prototype.editBottomFormulas = function () {
        var self = this;

        self.aggregateMode = true;

    }
    DataListConfig.prototype.syncAggregate = function () {
        var self = this;

        // self.aggregateMode = true;
        var dataURL = URI.MODULE_AGGREGATE_FORMULA.SYNC_FIELD_FORMULAS;

        var toSend = [];
        for (var i = 0; i < self.bottomFormulas.length; i++) {
            if (self.bottomFormulas[i].fields.length) toSend.push(self.bottomFormulas[i]);
        }

        self.isBusy = true;

        self[dataURL.method](dataURL, { url: { dataListId: self.id }, urltype: 'obj', body: toSend },
            { headers: { moduleId: self.moduleId } })
            .then(function () {
                Message.info("Formulas updated successfully");



                self.bckpOnGridList = angular.copy(self.inGrid.list);
                self.isModified = true;
                
            })
            .catch(function (e) {
                Message.dberror(e);
                
            })
            .finally(function () {
                self.isBusy = false; 
            })


    }

    DataListConfig.prototype.cancelAggregate = function () {
        var self = this;
        self.inGrid.list = angular.copy(self.bckpOnGridList);

        self.processBottomFormulasInfo(self.inGrid.list);

        self.aggregateMode = false;

    }



    DataListConfig.prototype.editTopFormulas = function () {
        var self = this;

        self.topFormulaInit();
        self.complexMode = true;

    }

    DataListConfig.prototype.closeTopFormulas = function () {
        var self = this;

        self.complexMode = false;
        self.get_topFormulas();

    }

    DataListConfig.prototype.saveTopFormula = function () {
        var self = this;
        
        var p = $q.defer();
        var dataURL = self.currentFormula.id ? URI.MODULE_COMPLEX_FORMULA.EDIT : URI.MODULE_COMPLEX_FORMULA.ADD;
        var urlParams = { dataListId: self.id };

        var toSend = {
            x: self.currentFormula.x || 0,
            y: self.currentFormula.y || 0,
            cols: self.currentFormula.cols || 6,
            rows: self.currentFormula.rows || 1,
            id: self.currentFormula.id || null,
            expression: self.currentFormula.expression,
            formattings: self.currentFormula.formattings,
            label: self.currentFormula.label,
            typeId: self.currentFormula.typeId,
            displayTypeId: self.currentFormula.displayTypeId,
            minItemCols: 3,
            maxItemRows: 1
        }
        self.isBusy = true;
        

        self[dataURL.method](dataURL, { url: urlParams, urltype: 'obj', body: toSend }, { headers: { moduleId: self.moduleId } })
            .then(function (r) {
                if (!toSend.id) toSend.id = r;
                if (!self.topFormulas.backupDashboard[toSend.id]) self.topFormulas.dashboard.push(toSend);
                else {
                    for (var key in toSend) {
                        if (toSend.hasOwnProperty(key)) {
                            self.topFormulas.backupDashboard[toSend.id][key] = toSend[key];
                        }
                    }
                }
                
                self.currentFormula = null;
                p.resolve(r);
                Message.info("Formula saved successfully");
                self.syncTopFormulas();
            })
            .catch(function (e) {
                console.error(e);
                p.reject(e);
                Message.dberror(e);
            })
            .finally(function () {
                self.isBusy = false;
            })
        return p.promise;
    }

    DataListConfig.prototype.get_topFormulas = function () {
        var self = this;

        var p = $q.defer();
        var dataURL = URI.MODULE_COMPLEX_FORMULA.ALL;
        var urlParams = { dataListId: self.id };

        self[dataURL.method](dataURL, { url: urlParams, urltype: 'obj' }, { headers: { moduleId: self.moduleId } })
            .then(function (r) {
                self.topFormulas.dashboard = r;
                self.topFormulas.backupDashboard = self.createReference(self.topFormulas.dashboard);
                p.resolve(r);
            })
            .catch(function (e) {
                console.error(e);
                p.reject(e);
            })
            .finally(function () {
                self.isBusy = false;
            })
        return p.promise;

    }

    DataListConfig.prototype.topFormulaInit = function () {
        var self = this;
        self.numericFields = [];
        self.numericFieldsLookup = {};
        self.dateFieldsLookup = {};
        Dictionaries.ComplexFormulaFields(null, { moduleId: self.moduleId })
            .then(function (r) {
                self.numericFields = r;
                for (var i = 0; i < r.length; i++) {
                    self.numericFieldsLookup[r[i].key] = r[i];
                }
            })

        var options = {
            gridType: 'scrollVertical',
            compactType: 'none',
            initCallback: self.gridster_methods["gridInit"],
            destroyCallback: self.gridster_methods["gridDestroy"],
            itemChangeCallback: self.gridster_methods["itemChange"],
            itemResizeCallback: self.gridster_methods["itemResize"],
            itemInitCallback: self.gridster_methods["itemInit"],
            itemRemovedCallback: self.gridster_methods["itemRemoved"],
            margin: 10,
            outerMargin: true,
            outerHeight: 500,
            mobileBreakpoint: 300,
            minCols: 12,
            maxCols: 12,
            minRows: 20,
            maxRows: 20,
            maxItemCols: 20,
            minItemCols: 1,
            maxItemRows: 3,
            minItemRows: 1,
            maxItemArea: 2500,
            minItemArea: 1,
            defaultItemCols: 1,
            defaultItemRows: 1,
            fixedColWidth: 250,
            fixedRowHeight: 250,
            enableEmptyCellClick: false,
            enableEmptyCellContextMenu: false,
            enableEmptyCellDrop: false,
            enableEmptyCellDrag: false,
            emptyCellClickCallback: self.gridster_methods["emptyCellClick"],
            emptyCellContextMenuCallback: self.gridster_methods["emptyCellClick"],
            emptyCellDropCallback: self.gridster_methods["emptyCellClick"],
            emptyCellDragCallback: self.gridster_methods["emptyCellClick"],
            emptyCellDragMaxCols: 50,
            emptyCellDragMaxRows: 50,
            draggable: {
                delayStart: 0,
                enabled: true,
                stop: self.gridster_methods["eventStop"]
            },
            resizable: {
                delayStart: 0,
                enabled: true,
                stop: self.gridster_methods["eventStop"]
            },
            swap: true,
            pushItems: true,
            disablePushOnDrag: false,
            disablePushOnResize: false,
            pushDirections: { north: true, east: true, south: true, west: true },
            pushResizeItems: false,
            displayGrid: 'always', //onDrag&Resize
            disableWindowResize: false,
            disableWarnings: false,
            scrollToNewItems: true
        };

        self.topFormulas.options = {
            mincols: 12,
            maxcols: 12,
            minrows: 3,
            maxRows: 3,
            maxItemCols: 12,
            outerHeight: 200
        };

        for (var key in options) {
            if (options.hasOwnProperty(key) && !self.topFormulas.options[key]) {
                self.topFormulas.options[key] = options[key];
            }
        }


    }

    DataListConfig.prototype.gridster_methods = {

        emptyCellClick: function (event, item) {
            // console.log('empty cell click', event, item);
            // self.dashboard.push(item);
            // this.$applyAsync();
        },

        eventStop: function (item, itemComponent, event) {
            // console.log('eventStop', item, itemComponent, event);
        },

        itemChange: function (item, itemComponent) {
            // console.log('itemChanged', item, itemComponent, this, self);
            //self.dirtyDashboard = true;
        },

        itemResize: function (item, itemComponent) {
            // console.log('itemResized', item, itemComponent);
            //this.api.optionsChanged();
        },

        itemInit: function (item, itemComponent) {
            //console.log('itemInitialized', item, itemComponent);
        },

        itemRemoved: function (item, itemComponent) {
            // console.log('itemRemoved', item, itemComponent, this);
            // itemComponent.gridster.options.api.optionsChanged();
        },
        gridInit: function (grid) {
            // console.log('gridInit', grid);
        },
        gridDestroy: function (grid) {
            // console.log('gridDestroy', grid);
        }
    }

    DataListConfig.prototype.initFormula = function (formula) {
        var self = this;
        self.currentFormula = formula || {
            label: "",
            id: null,
            typeId: 2,
            displayTypeId: 1,
            cols: 4,
            formattings: [],
            rows: 1,
            
            
        };

        var additionalInfo = {
            errors: { label: "" },
            minItemCols: 3,
            maxItemRows: 1,
            formula: [],
        }

        for (var key in additionalInfo) {
            if (additionalInfo.hasOwnProperty(key))
                self.currentFormula[key] = additionalInfo[key];
        }
    }

    DataListConfig.prototype.removeItemTF = function (field, e) {
        var self = this;
        // put in timeout so that the html is updated properly
        $timeout(function () {
            self.topFormulas.dashboard.splice(self.topFormulas.dashboard.indexOf(field), 1);
        }, 0);
    }

    DataListConfig.prototype.createReference = function (fields) {
        var self = this;
        var reference = {};
        if (fields.length) {
            for (var i = 0; i < fields.length; i++) {
                reference[fields[i].id] = {
                    x: fields[i].x,
                    y: fields[i].y,
                    cols: fields[i].cols,
                    rows: fields[i].rows
                };
            }
        }
        return reference;
    }

    DataListConfig.prototype.checkDirty = function () {
        var self = this;
        if (!self.loadingFields) return !angular.equals(self.topFormulas.backupDashboard, self.createReference(self.topFormulas.dashboard, true));
        else return false;


    }

    DataListConfig.prototype.syncTopFormulas = function () {
        var self = this;
        var p = $q.defer();
        var dataURL = URI.MODULE_COMPLEX_FORMULA.SYNC;
        var urlParams = { dataListId: self.id };
        var toSend = [], field = {};
        self.isBusy = true;
        for (var i = 0; i < self.topFormulas.dashboard.length; i++) {
            field = {
                x: self.topFormulas.dashboard[i].x || 0,
                y: self.topFormulas.dashboard[i].y || 0,
                cols: self.topFormulas.dashboard[i].cols || 6,
                rows: self.topFormulas.dashboard[i].rows || 1,
                id: self.topFormulas.dashboard[i].id || null,
                expression: self.topFormulas.dashboard[i].expression,
                formattings: self.topFormulas.dashboard[i].formattings,
                label: self.topFormulas.dashboard[i].label,
                typeId: self.topFormulas.dashboard[i].typeId,
                displayTypeId: self.topFormulas.dashboard[i].displayTypeId,
                minItemCols: 3,
                maxItemRows: 1
            }

            toSend.push(field);
        }

        self[dataURL.method](dataURL, { url: urlParams, urltype: 'obj', body: toSend }, { headers: { moduleId: self.moduleId } })
            .then(function (r) {
                
                p.resolve(r);
                self.topFormulas.backupDashboard = self.createReference(self.topFormulas.dashboard);
                Message.info("Formulas updated successfully.");
                self.isModified = true;
            })
            .catch(function (e) {
                console.error(e);
                p.reject(e);
                Message.dberror(e);
            })
            .finally(function () {
                self.isBusy = false;
            })
        return p.promise;
    }


    DataListConfig.prototype.get_Details = function (field) {
        var self = this, obj;
        var p = $q.defer();
        self.isBusy = true;
        if (!field) {
            obj = { id: self.properties.id, screenId: self.properties.screenId };
        } else {
            obj = field;
        }
        var dataURL = obj.screenId ? URI.SCREEN_FIELDS.GET : URI.FIELD_DEFINITION.GET;
        var urlParams = { id: obj.id };
        if (obj.screenId) urlParams.screenId = obj.screenId;
        self[dataURL.method](dataURL, { url: urlParams, urltype: 'obj' }, { headers: { moduleId: self.moduleId } })
            .then(function (r) {
                
                p.resolve(r);
            })
            .catch(function (e) {
                console.error(e);
                p.reject(e);
            })
            .finally(function () {
                self.isBusy = false;
            })
        return p.promise;
    }




    return DataListConfig;
});
