<div class="p-a-xl">
    <div class="p-a-xl" fxLayout fxLayoutGap="12px">
        <button mat-raised-button color="accent" (click)="logForm()">Log</button>
        <button mat-raised-button color="primary" (click)="addField()">Add Field</button>
        <mat-checkbox (change)="changeRequired($event.checked)">Required state (all)</mat-checkbox>
        <mat-checkbox (change)="changeReadonly($event.checked)">Readonly state (all)</mat-checkbox>
    </div>

    <div fxLayout.gt-sm="row wrap" fxLayout.lt-md="column" fxLayoutGap="12px">
        @for (control of form().controls | keyvalue; track $index) {
            <div fxFlex.gt-sm="45" class="m-t">
                @switch (control.value.fieldData.type) {
                    @case (FIELD_TYPES.Text) {
                        <cipo-text [control]="$any(control.value)" />
                    }
                    @case (FIELD_TYPES.Number) {
                        <!-- readonly is needed only if the state can be changed at runtime -->
                        <cipo-number
                            [control]="$any(control.value)"
                            [required]="control.value.fieldData.required"
                            [readonly]="control.value.fieldData.readonly" />
                    }
                    @case (FIELD_TYPES.Listbox) {
                        <cipo-listbox [control]="$any(control.value)" />
                    }
                    @case (FIELD_TYPES.Checkbox) {
                        <cipo-checkbox [control]="$any(control.value)" />
                    }
                    @case (FIELD_TYPES.Attachment) {
                        <cipo-attachment [control]="$any(control.value)" />
                    }
                    @case (FIELD_TYPES.Select) {
                        <cipo-select [control]="$any(control.value)" />
                    }

                    @default {
                        <cipo-text [control]="$any(control.value)" />
                    }
                }
            </div>
        }
    </div>
</div>
