import { cipo } from 'cipo';
import moment from 'moment';

cipo.directive("emailConversation", function () {
    return {
        restrict: "E",
        replace: true,
        scope: {
            "conversation": "="
        },
        templateUrl: "/ng/views/directives/system/emailConversation.html",
        controller: function ($scope, $window, $timeout, moduleService, fileService, PROJECT_FILE_MODULE_ID, userService) {
            if ($scope.conversation.emails.length) {
                $scope.conversation.emails.forEach(email => {
                    email.properties.sent = email.properties.sent
                        ? new Date(moment(email.properties.sent).toString() + ' UTC')
                        : email.properties.sent;
                });
            }
            $scope.openMenu = function ($mdMenu, ev) {
                $mdMenu.open(ev);
            };
            $scope.datetimeFilter = userService.userSettings().datetimeFormat;
            $scope.hasPermissionOnProjectFile = userService.getModuleIdentifierById(PROJECT_FILE_MODULE_ID);
            $scope.setStatus = function () {
                if ($scope.conversation.emails.length > 4) {
                    $scope.multiCollapse = true;
                    $scope.hiddenDrafts = 0;
                    $scope.hiddenMessages = 0;
                    for (var i = 0; i < $scope.conversation.emails.length; i++) {
                        if (i < $scope.conversation.emails.length - 1) {
                            if (!$scope.conversation.emails[i].hasDraft) {
                                $scope.conversation.emails[i].emailCollapse = true;
                            }
                            if (i > 0 && i < $scope.conversation.emails.length - 2) {
                                $scope.conversation.emails[i].emailHide = true;
                                $scope.hiddenMessages++;
                                if ($scope.conversation.emails[i].hasDraft)
                                    $scope.hiddenDrafts++;
                            }
                        }
                    }
                } else {
                    $scope.multiCollapse = false;
                    for (var i = 0; i < $scope.conversation.emails.length - 1; i++) {
                        if (!$scope.conversation.emails[i].hasDraft) {
                            $scope.conversation.emails[i].emailCollapse = true;
                        }
                    }
                }
            }

            moduleService.getFileModuleNames()
                .then(function (r) {
                    $scope.projectFilesName = moduleService.projectFilesName;
                    $scope.myDriveName = moduleService.myDriveName;
                });

            $scope.switchStatus = function (message) {
                message.emailCollapse = !message.emailCollapse;
            }

            $scope.showMultipleAttachments = function (attachments, isSaveTo, isProjectFiles) {
                fileService.showMultipleAttachments(attachments, isSaveTo, isProjectFiles);
            }

            $scope.$watch(function () { return $scope.conversation.emails.length }, function (newValue, oldValue) {
                $scope.setStatus();
            }, true);

            $scope.expandBulk = function () {
                $scope.multiCollapse = false;
            }
            if ($scope.conversation.emails && $scope.conversation.emails.length) {
                for (var i = 0; i < $scope.conversation.emails.length; i++) {

                    if ($scope.conversation.emails[i].draft.length) {
                        var draft = $scope.conversation.emails[i].draft[0];
                        draft.templateWatch = $scope.$watch(function () { return draft.properties.templateId; }, function (n, o) {
                            if (n != o) draft.set_Template(n, draft.properties.contractId);
                        });
                    }
                }
            }

            $scope.isReversed = false;
            $scope.reverseThread = function () {
                $scope.conversation.emails.reverse();
                $scope.isReversed = !$scope.isReversed;
            }

            $scope.dateFilter = userService.userSettings().dateFormat;

            $scope.send = function (message) {
                message.draft[0].templateWatch();
                $scope.conversation.send(message);
                if (!message.draft[0].isValid) {
                    $scope.formValidationWatcher(message);
                }
            }

            $scope.formValidationWatcher = function (email) {
                $scope.$watch(function () { return email.draft[0].form.data; }, function (n, o) {
                    if (n != o) {
                        email.draft[0].form.validate();

                        if (email.draft[0].form.isValid) {
                            $scope.conversation.disableBtns = false;
                            $scope.formValidationWatcher(email);
                        }
                    }
                }, true);
            }

            $scope.reply = function (conversation, email, index) {
                conversation.reply(email);
                $scope.scrollToEditor(index);
                email.draft[0].templateWatch = $scope.$watch(function () { return email.draft[0].properties.templateId; }, function (n, o) {
                    if (n != o) email.draft[0].set_Template(n);
                });
                // watch if the form gets dirty
                var clearWatch = $scope.$watch(function () { return email.draft[0].form.dirty; }, function (newParam, oldParam) {
                    if (newParam == true) {
                        conversation.saveDraft(email);
                        // stop watch listener
                        clearWatch();
                    }
                });
            };

            $scope.replyAll = function (conversation, email, index) {
                conversation.replyAll(email);
                $scope.scrollToEditor(index);
                email.draft[0].templateWatch = $scope.$watch(function () { return email.draft[0].properties.templateId; }, function (n, o) {
                    if (n || o) email.draft[0].set_Template(n);
                });
                // watch if the form gets dirty
                var clearWatch = $scope.$watch(function () { return email.draft[0].form.dirty && email.draft[0].form.touched; }, function (newParam, oldParam) {
                    if (newParam == true) {
                        conversation.saveDraft(email);
                        // stop watch listener
                        clearWatch();
                    }
                });
            };

            $scope.forward = function (conversation, email, index) {
                conversation.forward(email);
                $scope.scrollToEditor(index);
                email.draft[0].templateWatch = $scope.$watch(function () { return email.draft[0].properties.templateId; }, function (n, o) {
                    if (n != o) email.draft[0].set_Template(n);
                });
                // watch if the form gets dirty
                var clearWatch = $scope.$watch(function () { return email.draft[0].form.dirty && email.draft[0].form.touched; }, function (newParam, oldParam) {
                    if (newParam == true) {
                        conversation.saveDraft(email);
                        // stop watch listener
                        clearWatch();
                    }
                });
            };
            $scope.messagesToPrint = [];

            $scope.printMe = function (email, contentOnly) {
                $scope.printAll([email], contentOnly);
            }

            $scope.printAll = function (emails, contentOnly) {
                if ($("#section-to-print").length > -1) {
                    $("#section-to-print").remove();
                }

                var div = $('<div />').appendTo('body');
                div.attr('id', 'section-to-print');

                $scope.messagesToPrint = (emails || []).map(e => contentOnly ? e.properties.content : e);
                $('#print-content-only').html($scope.messagesToPrint.join('<hr/>'));

                $timeout(function () {
                    if (contentOnly) {
                        $("#section-to-print").html($("#print-content-only").html());
                    } else {
                        $("#section-to-print").html($("#print-message").html());
                    }

                    $window.print();
                }, 400);
            }

            $scope._open = function (x) {
                return $scope.conversation.openAttachment(x);
            }

            $scope._openInNewTab = function (x) {
                return $scope.conversation.openAttachmentInNewTab(x);
            }

            $scope._save = function (x) {
                return $scope.conversation.saveAttachment(x);
            }

            $scope.scrollToEditor = function (index) {
                $timeout(function () {
                    var message = $(`#message-${index}`);
                    var editor = $(message).find(`#editor-${index}`);

                    $('html, body').animate({
                        scrollTop: editor.offset().top - 275
                    }, 400, function () {
                        editor.focus();
                    });
                }, 400);
            }

            $scope.isContractClosed = function () {
                return userService.isContractClosed($scope.conversation.contractId);
            }
        }
    }
});
