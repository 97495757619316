import { Injectable } from '@angular/core';
import { VirtualScrollStrategy, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Subject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable()
export class DataListScrollStrategy implements VirtualScrollStrategy {
  private rowHeight!: number;
  private headerHeight!: number;
  private dataLength = 0;
  private readonly indexChange = new Subject<number>();

  private viewport: CdkVirtualScrollViewport;

  public scrolledIndexChange: Observable<number>;

  constructor() {
    this.scrolledIndexChange = this.indexChange.pipe(distinctUntilChanged());
  }

  public attach(viewport: CdkVirtualScrollViewport): void {
    this.viewport = viewport;
    this.onDataLengthChanged();
  }

  onContentScrolled(): void {
    this.updateContent();
  }

  onDataLengthChanged(): void {
    if (this.viewport) {
      this.viewport.setTotalContentSize(this.dataLength * this.rowHeight);
      this.updateContent();
    }
  }

  setDataLength(length: number): void {
    this.dataLength = length;
    this.onDataLengthChanged();
  }

  setScrollHeight(rowHeight: number, headerHeight: number) {
    this.rowHeight = rowHeight;
    this.headerHeight = headerHeight;
    this.updateContent();
  }

  detach(): void {}
  onContentRendered(): void {}
  onRenderedOffsetChanged(): void {}
  scrollToIndex(index: number, behavior: ScrollBehavior): void {}

  private updateContent(): void {
    if (!this.viewport) {
      return;
    }

    const amount = Math.ceil(this.viewport.getViewportSize() / this.rowHeight);
    const offset = this.viewport.measureScrollOffset() - this.headerHeight;
    const buffer = Math.ceil(amount / 2);

    const skip = Math.round(offset / this.rowHeight);
    const index = Math.max(0, skip);
    const start = Math.max(0, index - buffer);
    const end = Math.min(this.dataLength, index + amount + buffer);

    this.viewport.setRenderedContentOffset(this.rowHeight * start);
    this.viewport.setRenderedRange({ start, end });

    this.indexChange.next(index);
  }
}
