import { cipo } from 'cipo';

cipo.controller('modalDetailsController',
    function ($transition$, $scope, $mdDialog, $state, userService, URL_ACTIONS) {
        const params = $transition$.params();
        var moduleId = params.moduleId || 0;
        var moduleCode = userService.getModuleIdentifierById(moduleId);
        
        var module = userService.system.modules[moduleCode];
        
        if (module?.moduleId) {
            userService.system.selectedModuleId = module?.moduleId;
            
            var entityId = params.entityId || 0;
            var screenId = params.screenId * 1;
            var item = {
                isInitiator: true,
                itemId: entityId,
                fromItemId: (params.urlActionId == URL_ACTIONS.CLONE) ? (params.urlActionEntityId || 0) : 0,
                presetId: (params.urlActionId == URL_ACTIONS.PRESET) ? (params.urlActionEntityId || 0) : 0,
            };

            $mdDialog.show({
                locals: { item: item, obj: {}, module: module, contractId: null, smallerFullscreen: false, screenId: screenId },
                controller: 'SaveWorkflowEntityInstanceController',
                templateUrl: '/ng/views/dynamics/modals/saveWorkflowEntityInstance.html',
                parent: angular.element(document.body),
                fullscreen: true,
                escapeToClose: false,
                multiple: true,
                clickOutsideToClose: false
            });

            $scope.$on('$destroy', function () {
                $mdDialog.hide();
            })
        }
        else {
            $state.go('e404');
        }
    });