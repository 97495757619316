import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { KeyValueModel } from '../../models/common';
import { TenantSettingsEnum } from '../../models/tenant';
import { HttpService } from '../../shared/services';

@Injectable({
  providedIn: 'root',
})
export class UserSettingsService {
  http = inject(HttpService);

  getUserSettings(): Observable<KeyValueModel<TenantSettingsEnum, string>[]> {
    return this.http.get<KeyValueModel<TenantSettingsEnum, string>[]>('_api/User/GetUserSettingsDict', {
      useLoader: false,
    });
  }
}
