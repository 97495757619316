import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import { MsalRedirectComponent } from '@azure/msal-angular';
import * as angular from 'angular';
import { GridsterComponent, GridsterItemComponent } from 'angular-gridster2';
import { MsalAuthService, UserService } from '../shared/services';
import { FooterComponent } from './footer/footer.component';
import { LayoutComponent } from './layout/layout.component';

export const Ng1ModuleSystem = angular
  .module('ng1module.system', [])
  .directive('appLayout', downgradeComponent({ component: LayoutComponent }) as angular.IDirectiveFactory)
  .directive('appFooter', downgradeComponent({ component: FooterComponent }) as angular.IDirectiveFactory)
  .directive('gridster', downgradeComponent({ component: GridsterComponent }) as angular.IDirectiveFactory)
  .directive('gridsterItem', downgradeComponent({ component: GridsterItemComponent }) as angular.IDirectiveFactory)
  .directive('appRedirect', downgradeComponent({ component: MsalRedirectComponent }) as angular.IDirectiveFactory)
  .factory('msalAuthService', downgradeInjectable(MsalAuthService) as any)
  .factory('userServiceNg1', downgradeInjectable(UserService) as any)
  .name;
