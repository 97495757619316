import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LegacyIcon, LegacyIcons } from '../../../legacy/constants';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'itv-module-icon',
  standalone: true,
  imports: [MatIconModule],
  templateUrl: './module-icon.component.html',
  styleUrls: ['./module-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModuleIconComponent {
  @Input('iconid') iconId?: number;
  @Input() color?: string;
  @Input() size?: number;

  private defaultIcon: LegacyIcon;
  private defaultSize = 24;

  constructor(private icons: LegacyIcons) {
    this.defaultIcon = icons.byKey.get(0);
  }

  get name(): string {
    return this.icons.byKey.get(this.iconId || this.defaultIcon.key)?.name || this.defaultIcon.name;
  }

  get sizeOrDefault(): string {
    return `${this.size || this.defaultSize}px`;
  }
}
