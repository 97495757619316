import { cipo } from "cipo";
var userProfileController = function (
	$state,
	$scope,
	$timeout,
	Upload,
	URI,
	authService,
	userService,
	Model,
	Message,
	UserProfile,
	fileService,
) {
	var get_modulesList = function () {
		$scope.loadingModulesList = true;
		$scope.modulesList = [];

		var dataURL = URI.NOTIFICATION.MODULES_LIST;
		var params = {
			contractId: userService.system.userdata.contractId || 0,
		};

		Model[dataURL.method](dataURL, { url: params, urltype: "obj" })
			.then(function (r) {
				$scope.modulesList = r;
			})
			.catch(function (e) {
				Message.dberror(e);
			})
			.finally(function () {
				$scope.loadingModulesList = false;
			});
	};

	$scope.userProfile = new UserProfile();

	$scope.toggleNotification = function (item) {
		item.loading = true;
		var dataURL = URI.NOTIFICATION.TOGGLE_PER_MODULE;
		var params = {
			contractId: userService.system.userdata.contractId || 0,
			moduleId: item.key,
		};

		Model[dataURL.method](dataURL, { url: params, urltype: "obj" })
			.then(function (r) {
				Message.info("Notification updated sucessfully.");
				item.isUsed = !item.isUsed;
			})
			.catch(function (e) {
				Message.dberror(e);
			})
			.finally(function () {
				item.loading = false;
			});
	};

	$scope.toggleNotifications = function (isOn) {
		var dataURL = URI.NOTIFICATION.TOGGLE_GLOBAL;
		var params = {
			contractId: userService.system.userdata.contractId || 0,
			isOn: isOn ? 1 : 0,
		};

		Model[dataURL.method](dataURL, { url: params, urltype: "obj" })
			.then(function (r) {
				Message.info("Notifications updated sucessfully.");
				get_modulesList();
			})
			.catch(function (e) {
				Message.dberror(e);
			});
	};

	userService.mimeTypes();
	$scope.editNotifications = function () {
		$scope.editingNotifications = !$scope.editingNotifications;
		if ($scope.editNotifications) get_modulesList();
	};

	$scope.uploadFiles = function (file, errFiles) {
		$scope.f = file;
		$scope.errFile = errFiles && errFiles[0];

		fileService.setMimeTypeFromExt(file);

		if (file) {
			$scope.isUploading = true;

			var urlParamString = "?mimeType=" + file.mimeTypeQueryString;
			file.upload = Upload.upload({
				url:
					URI.MY_CIPO_DRIVE.UPLOAD_FILE_AS_USER_LOGO.toString() +
					urlParamString,
				method: URI.MY_CIPO_DRIVE.UPLOAD_FILE_AS_USER_LOGO.method.toString(),
				data: { file: file },
			});

			file.upload.then(
				function (response) {
					$timeout(function () {
						file.result = response.data;
						userService.User();
						$scope.isUploading = false;
					});
				},
				function (response) {
					if (response.status > 0)
						$scope.errorMsg = response.status + ": " + response.data;
					$scope.isUploading = false;
				},
				function (evt) {
					file.progress = Math.min(
						100,
						parseInt((100.0 * evt.loaded) / evt.total),
					);
				},
			);
		}
	};

	$scope.editSignature = function () {
		$scope.isEditSign = !$scope.isEditSign;
	};

	$scope.trapClik = function (event) {
		event.stopPropagation();
	};

	// ex top.js
	Object.defineProperty($scope, "userService", {
		get: function () {
			return userService;
		},
	});

	Object.defineProperty($scope, "currentRoles", {
		get: function () {
			return userService.system.userRoleNames.join(", ");
		},
	});

	$scope.trapClik = function (event) {
		event.stopPropagation();
	};

	$scope.passwordChange = function () {
		authService.passwordChange();
	};

	$scope.profileEdit = function () {
		authService.profileEdit();
	};
};

cipo.controller("userprofileController", userProfileController);
