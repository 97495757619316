import { Component, OnInit, computed, inject } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Router } from '@angular/router';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { TranslateModule } from '@ngx-translate/core';
import { RouteCodeEnum } from '../../models/menu';
import { Permission } from '../../models/permission';
import { NgToJsService } from '../../ng2/services/ng-to-js.service';
import { MsalAuthService } from '../../shared/services/auth.service';
import { TenantStore, UserStore } from '../../signal-store';
import { AccountMenuComponent } from '../account/account-menu.component';
import { ContextInfo, ContextTree } from '../context/context.model';
import { ContextViewComponent } from '../context/view/context-view.component';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    FlexLayoutModule,
    MatBadgeModule,
    MatToolbarModule,
    ContextViewComponent,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    TranslateModule,
    AccountMenuComponent,
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  authService = inject(MsalAuthService);
  angularJsService = inject(NgToJsService);
  tenantStore = inject(TenantStore);
  userStore = inject(UserStore);
  router = inject(Router);

  canOpenContract = computed(() =>
    this.userStore.getModuleByCode(RouteCodeEnum.Contracts)?.operations?.find(op => op.id === Permission.Read),
  );
  canOpenCorrespondence = computed(() =>
    this.userStore.getModuleByCode(RouteCodeEnum.Correspondence)?.operations?.find(op => op.id === Permission.Read),
  );

  constructor() {
    this.angularJsService.refreshingContext.subscribe();
  }

  ngOnInit(): void {
    this.angularJsService.changeContext(this.userStore.userData().context, false);
  }

  logoClick() {
    this.router.navigate([this.tenantStore.selectedTenantName(), 'dashboard']);
  }

  openContracts() {
    this.angularJsService.editContract();
  }

  contextChanged(context: ContextInfo) {
    if (this.getEntityInstanceId(this.userStore.userData().context) === this.getEntityInstanceId(context)) {
      return;
    }
    this.angularJsService.changeContext(context, true);
    this.userStore.updateContext(context);
  }

  goToCorrespondence() {
    this.userStore.setActiveMenuByUrl('emails');
    this.router.navigate([this.tenantStore.selectedTenantName(), 'emails']);
  }

  contextDataLoaded(contextTree: ContextTree) {
    this.angularJsService.contextDataLoaded(contextTree);
  }

  private getEntityInstanceId(context: ContextInfo): number {
    return (
      context?.contract?.entityInstanceId ?? context?.project?.entityInstanceId ?? context?.program?.entityInstanceId
    );
  }
}
