import * as angular from 'angular';
import moment from "moment-timezone";
import uiRouter from "@uirouter/angularjs";

export const global = angular.module("Global", []);
global.constant("system", {});

import { Ng1ModuleSystem } from '../src/app/system/system.ng1-module';
import { Ng1ModuleDefinition } from '../src/app/module/definition/module-definition.ng1-module';
import { Ng1ModuleInstance } from '../src/app/module/instance/module-instance.ng1-module';
import { Ng1ModuleLegacy } from '../src/app/legacy/legacy.ng1-module';
import { Ng1Files } from 'src/app/files/files.ng1-module';
import { LegacyRoutes } from '../src/app/legacy/constants/routes.constant';
import { TENANT } from 'src/app/shared/consts';

//cannot be injected therefore we create a new instance
const legacyRoutes = new LegacyRoutes();

// App module runs all the other modules
export const cipo = angular.module('cipo',
        [
            'components',
            'rzTable',
            'ui.bootstrap',
            'ui.sortable',
            'ui.tree',
            'ngSanitize',
            'ngMaterial',
            'mdColorPicker',
            'ngCookies',
            'ngMessages',
            'ui.select',
            'dialogs.main',
            'RoutesModule',
            'LocalStorageModule',
            'Global',
            'flow',
            'ngFileUpload',
            'ngFileSaver',
            'treeControl',
            uiRouter,
            Ng1ModuleInstance,
            Ng1ModuleDefinition,
            Ng1ModuleSystem,
            Ng1ModuleLegacy,
            Ng1Files,
        ]);

    cipo.config(function ($mdThemingProvider) {
        $mdThemingProvider.definePalette('amazingPaletteName', {
            '50': '000000',
            '100': '000000',
            '200': '0000ff',
            '300': 'e57373',
            '400': 'ef5350',
            '500': '646668', // selected option in selects
            '600': '147863', // primary hover
            '700': 'd32f2f',
            '800': '1ab393', // primary
            '900': 'b71c1c',
            'A100': 'ff8a80',
            'A200': 'ff5252',
            'A400': 'ff1744',
            'A700': 'd50000',
            'contrastDefaultColor': 'light',    // whether, by default, text (contrast)
            // on this palette should be dark or light

            'contrastDarkColors': ['50', '100', //hues which contrast should be 'dark' by default
                '200', '300', '400', 'A100'],
            'contrastLightColors': undefined    // could also specify this if default was 'dark'
        });

        $mdThemingProvider.theme('default')
            .primaryPalette('amazingPaletteName')

        var cipoCustomMap = $mdThemingProvider.extendPalette('amazingPaletteName', {
            // '500': '#00ff00', focus select check multiselect
            'contrastDefaultColor': 'light'
        });
        var cipoAccent = $mdThemingProvider.extendPalette('amber', {
            '800': '#28b293', // primary
            'contrastDefaultColor': 'light'
        });

        // Register the new color palette map with the name <code>neonRed</code>
        $mdThemingProvider.definePalette('cipoCustom', cipoCustomMap);
        $mdThemingProvider.definePalette('cipoAccent', cipoAccent);

        $mdThemingProvider.theme('default')
            .primaryPalette('cipoCustom', {
                'default': '800', // by default use shade 400 from the pink palette for primary intentions
                'hue-1': '300', // use shade 100 for the <code>md-hue-1</code> class
                'hue-2': '600', // use shade 600 for the <code>md-hue-2</code> class
                'hue-3': 'A100' // use shade A100 for the <code>md-hue-3</code> class
            })
            // If you specify less than all of the keys, it will inherit from the
            // default shades
            .accentPalette('cipoAccent', {
                // 
                'default': '800' // use shade 200 for default, and keep all other shades the same
            });
    });

    cipo.config(function (treeConfig) {
        treeConfig.treeClass = 'angular-ui-tree';
        treeConfig.emptyTreeClass = 'angular-ui-tree-empty';
        treeConfig.dropzoneClass = 'angular-ui-tree-dropzone';
        treeConfig.hiddenClass = 'angular-ui-tree-hidden';
        treeConfig.nodesClass = 'angular-ui-tree-nodes';
        treeConfig.nodeClass = 'angular-ui-tree-node';
        treeConfig.handleClass = 'angular-ui-tree-handle';
        treeConfig.placeholderClass = 'angular-ui-tree-placeholder';
        treeConfig.dragClass = 'angular-ui-tree-drag';
        treeConfig.dragThreshold = 3;
        treeConfig.defaultCollapsed = false;
        treeConfig.appendChildOnHover = true;
    });

    cipo.config(function (localStorageServiceProvider) {
        localStorageServiceProvider.setStorageType('sessionStorage');
    });

    cipo.config(['dialogsProvider', function (dialogsProvider) {
        dialogsProvider.useBackdrop('static');
        dialogsProvider.useEscClose(false);
        dialogsProvider.useCopy(false);
        dialogsProvider.setSize('sm');

    }]);

    cipo.config(['$locationProvider', function ($locationProvider) {
        $locationProvider.html5Mode(true);
    }]);

    cipo.config(['$stateProvider', 'routeResolverProvider', 
        function ($stateProvider, routeResolver) {
            
            var route = routeResolver.route;
            var sroutes = legacyRoutes.systemList;
            var troutes = legacyRoutes.appList;

            for (var i = 0; i < sroutes.length; i++) {
                var r = sroutes[i];
                if (r.name) {
                    r.properties.code = r.code;
                    $stateProvider.state(r.name, route.resolve(r.route, r.folder, r.controller, r.view, r.properties));
                }
            }

            $stateProvider.state('void', {
                template: ''
            });

            $stateProvider.state('tenant', {
                url: "/:tenantIdentifier",
                controller: "tenantController",
                templateUrl: "./ng/views/tenant/tenant_index.html",
                resolve: {
                    _isLoggedIn: ['authService', function (authService) {
                        return authService.isLoggedIn();
                    }],
                    _hasTenantsLoaded: ['$q', 'userService', '_isLoggedIn', '$transition$',
                        function ($q, userService, _isLoggedIn, $transition$) {
                            const params = $transition$.params();
                            var p = $q.defer();
                            var tp = $q.defer();

                            if (_isLoggedIn && !userService.hasTenantsLoaded) {
                                userService.Tenants(params.tenantIdentifier)
                                    .then(function () { p.resolve(true); })
                                    .catch(function (e) { console.error(e); p.resolve(false); })
                                    .finally(function () { tp.resolve(); });
                            }
                            else {
                                userService.setTenant(params.tenantIdentifier);
                                p.resolve(userService.hasTenantsLoaded);
                                tp.resolve();
                            }

                            tp.promise.then(function () {
                                var tenantStatus = userService.getTenantStatus();
                                if (!_isLoggedIn || tenantStatus === null || tenantStatus == 1) {
                                    return;
                                }
                            })

                            return p.promise;
                        }],
                    _hasUserLoaded: ['$q', 'userService', '_isLoggedIn', '_hasTenantsLoaded',
                        function ($q, userService, _isLoggedIn, _hasTenantsLoaded) {
                            var p = $q.defer();
                            const currentTenant = sessionStorage.getItem(TENANT)
                            const tenant = currentTenant === 'null' ? null : currentTenant;

                            if (_isLoggedIn && _hasTenantsLoaded && !userService.isUserLoaded && tenant) {
                                userService.User()
                                    .then(function () { p.resolve(true); })
                                    .catch(function () { p.resolve(false); });
                            }
                            else {
                                p.resolve(userService.isUserLoaded);
                            }

                            return p.promise;
                        }],
                        _hasModulesLoaded: ['$q', '$transition$', 'userService', '_isLoggedIn', '_hasUserLoaded',
                            function ($q, $transition$, userService, _isLoggedIn, _hasUserLoaded) {
                                var p = $q.defer();
    
                                if (userService.tenantId == -1) {
                                    p.resolve(true);
                                    return p.promise;
                                }
    
                                const params = $transition$.params();
                                var contractId = params.contractId ?? userService.system.userdata.contractId;
                                if (isNaN(Number(userService.system.userdata.contractId))) {
                                    userService.system.userdata.contractId = contractId;
                                }
    
                                if (_isLoggedIn && _hasUserLoaded && !userService.hasModulesLoaded && !userService.system.userdata.disabledOnTenant) {
                                    userService.Modules(contractId)
                                        .then(function () { p.resolve(true); })
                                        .catch(function () { p.resolve(false); });
                                }
                                else {
                                    p.resolve(userService.hasModulesLoaded)
                                }
    
                                return p.promise;
                            }]
                }
            });
            
            $stateProvider.state('404', {
                templateUrl: "./ng/views/system/e404.html"
            });

            $stateProvider.state('tenant.404', {
                templateUrl: "./ng/views/system/e404.html",
                error: true
            });

            $stateProvider.state('tenant.disabled', {
                templateUrl: "./ng/views/system/disabled.html",
                error: true
            });

            for (var i = 0; i < troutes.length; i++) {
                var r = troutes[i];
                if (r.name) {
                    r.name = 'tenant.' + r.name;
                    r.properties.code = r.code;
                    r.properties.error = r.error;
                    $stateProvider.state(r.name, route.resolve(r.route, r.folder, r.controller, r.view, r.properties));
                }

                $stateProvider.state('tenant.' + r.name + '.main', {
                    url: "/"
                });

                $stateProvider.state(r.name + '.details', {
                    url: '/details/:moduleId/:urlActionId/:urlActionEntityId/:entityId/:screenId?',
                    controller: 'modalDetailsController',
                    templateUrl: "./ng/views/system/modalDetails.html"
                });
            }
        }]);

    cipo.config(['$controllerProvider', '$compileProvider', '$filterProvider', '$provide',
        function ($controllerProvider, $compileProvider, $filterProvider, $provide) {
            // $compileProvider.debugInfoEnabled(false);

            cipo.register =
            {
                controller: $controllerProvider.register,
                directive: $compileProvider.directive,
                filter: $filterProvider.register,
                factory: $provide.factory,
                service: $provide.service
            };
        }]);

    cipo.config(['$httpProvider', function ($httpProvider) {
        // Disable cache
        if (!$httpProvider.defaults.headers.get) {
            $httpProvider.defaults.headers.get = {};
        }
        $httpProvider.defaults.headers.get['If-Modified-Since'] = 'Mon, 26 Jul 1997 05:00:00 GMT';
        $httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache';
        $httpProvider.defaults.headers.get['Pragma'] = 'no-cache';

        // Add interceptor
        $httpProvider.interceptors.push('authInterceptor');
    }]);

    cipo.run(['$rootScope', 'userService', '$transitions',
        function ($rootScope, userService, $transitions) {
            $rootScope.childRedirect = false;
            $transitions.onStart({ }, function() {
                // Prevents the redirection to the login page in case of an error when loading tenant list
                if (userService.error && userService.error === true) {
                    return;
                }
            })
            
            $transitions.onSuccess({ }, function(transition) {
                const params = transition.params();
                var contractId = params.contractId ? params.contractId * 1 : undefined;

                if (contractId && userService.system.userdata.contractId != contractId) {
                    userService.system.userdata.contractId = contractId;
                    return;
                }
                
                // change context behavior based on the module
                userService.changeContextBehavior(transition.to().code);
            })
        }]);

    cipo.config(["$provide", function ($provide) {
            return $provide.decorator("$http", ["$delegate", function ($delegate) {
                var cacheBustVersion = new Date().getTime();
                var get = $delegate.get;
                $delegate.get = function (url, config) {
                    // The template check is to avoid breaking AngularUI ui-bootstrap-tpls.js: "template/accordion/accordion-group.html"
                    // The rest are examples for any other assets that should not use cache busting
                    if (!!~url.indexOf('/ng/views/')) {
                        // Append ?v=[cacheBustVersion] to url
                        url += (url.indexOf("?") === -1 ? "?" : "&");
                        url += "v=" + cacheBustVersion;
                    }
                    return get(url, config);
                };
                return $delegate;
            }]);
        }]);

    cipo.directive('resizer', function ($document) {
        return {
            restrict: 'AE',
            scope: {
                options: '='
            },
            link: function ($scope, $element, $attrs) {

                $element.on('mousedown', function (event) {
                    event.preventDefault();

                    $document.on('mousemove', mousemove);
                    $document.on('mouseup', mouseup);
                });

                var options = {
                    resizer: 'vertical',
                    resizerMax: 500,
                    resizerMin: 100,
                    resizerWidth: 6,
                    resizerTop: 0,
                    resizerBottom: 0,
                    containerPadding: 15,
                    resizerLeft: "#left",
                    resizerRight: ["#right"],
                }

                for (var key in options)
                    if (options.hasOwnProperty(key)) options[key] = $scope.options[key] || options[key];

                $element.css({
                    'width':options.resizerWidth + 'px',
                    'top': options.resizerTop + 'px',
                    'bottom': options.resizerBottom + 'px',
                 });

                function mousemove(event) {

                    if (options.resizer == 'vertical') {
                        // Handle vertical resizer
                        var x = event.pageX - $(options.resizerLeft).offset().left;

                        if (x > options.resizerMax) {
                            x = parseInt(options.resizerMax);
                        }
                        if (x < options.resizerMin) {
                            x = parseInt(options.resizerMin);
                        }

                                               
                        $(options.resizerLeft).css({
                            width: x + 'px'
                        });

                        for (var i = 0; i < options.resizerRight.length; i++) {
                            $(options.resizerRight[i]).css({
                                'padding-left': x + options.containerPadding + 'px'
                            });
                        }

                        
                        

                    } else {
                        // Handle horizontal resizer
                        var y = window.innerHeight - event.pageY;

                        $element.css({
                            bottom: y + 'px'
                        });

                        $(options.resizerTop).css({
                            bottom: (y + parseInt(options.resizerHeight)) + 'px'
                        });
                        $(options.resizerBottom).css({
                            height: y + 'px'
                        });
                    }
                }

                function mouseup() {
                    $document.unbind('mousemove', mousemove);
                    $document.unbind('mouseup', mouseup);
                }
            }
        }
    });


    cipo.directive('tableresizer', function ($document) {
        return {
            restrict: 'AE',
            scope: {
                // options: '='
            },
            link: function ($scope, $element, $attrs) {
                
                 var options = {
                    resizer: 'vertical',
                    resizerMax: 1000,
                    resizerMin: 100,
                    resizerWidth: 6,
                    
                }

                $element.on('mousedown', function (event) {
                    $scope.parentTd = $element.closest("th");
                    event.preventDefault();
                    event.stopPropagation();

                    $scope.parentTable = $element.closest("table");
                    $scope.initialX = event.pageX;
                    $scope.initialTableWidth = $($scope.parentTable).width();
                    $scope.containerWidth = $($element.closest(".scrollable")).width();

                    $(($element.closest("th")).siblings() ).each(function (i, elem) {
                        $(elem).css({
                            'width': $(elem).outerWidth() + 'px'
                        });
                    });

                    //var nextTd = ($element.closest("th")).next("th");
                    
                    $($scope.parentTd).css({
                        width: "auto"
                    });
                    // var prevTd = ($element.closest("th")).prev("th");
                    //$(prevTd).css({
                    //    width: "auto"
                    //});

                    $document.on('mousemove', mousemove);
                    $document.on('mouseup', mouseup);
                });

               

                //for (var key in options)
                //    if (options.hasOwnProperty(key)) options[key] = $scope.options[key] || options[key];

                //$element.css({
                //    'width':options.resizerWidth + 'px',
                // });

                function mousemove(event) {

                    // Handle vertical resizer
                    var x = event.pageX - $($scope.parentTd).offset().left;
                    

                    if (x > options.resizerMax) {
                        x = parseInt(options.resizerMax);
                    }
                    if (x < options.resizerMin) {
                        x = parseInt(options.resizerMin);
                    }
                    var mouseMoved = event.pageX - $scope.initialX;

                    $($scope.parentTd).css({
                        width: x + 'px'
                    });

                    

                    if ($scope.initialTableWidth + mouseMoved > $scope.containerWidth) {
                        $($scope.parentTable).css({
                            'width': $scope.initialTableWidth + mouseMoved + 'px'
                        });
                    } else {
                        $($scope.parentTable).css({
                            'width': $scope.containerWidth + 'px'
                        });
                    }
                    

                    
                }

                function mouseup() {
                    $document.unbind('mousemove', mousemove);
                    $document.unbind('mouseup', mouseup);
                }
            }
        }
    });

    // Directive for pie charts, pass in title and data only    
    cipo.directive('hcPieChart', function ($timeout) {
        return {
            restrict: 'E',
            template: '<div></div>',
            scope: {
                title: '@',
                data: '='
            },
            link: function (scope, element) {
                var options = {
                    chart: {
                        backgroundColor: '#fcfdfe',
                        type: 'pie',
                    },
                    credits: {
                        enabled: false
                    },

                    title: {
                        text: ""
                    },
                    tooltip: {
                        enabled: false,
                        pointFormat: '{point.name}: <b>{point.percentage:.1f}%</b>'
                    },
                    exporting: {
                        buttons: {
                            contextButton: {
                                menuItems: ["viewFullscreen", "separator", "downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG"]
                            }
                        }
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            innerSize: '10%',
                            size: '70%',
                            dataLabels: {
                                enabled: true,
                                format: '<b>{point.name}</b>: {point.percentage:.1f}%',
                                style: { fontFamily: 'Arial, Helvetica, sans-serif', lineHeight: '1em', fontSize: '11px' }
                            }
                        }
                    },
                    series: [{
                        name: scope.title,
                        data: scope.data
                    }],

                };
                // wait for resize
                var rtime;
                var timeout = false;
                var delta = 200;

                function resizeend() {
                    if (new Date() - rtime < delta) {
                        setTimeout(resizeend, delta);
                    } else {
                        timeout = false;
                        // console.error('Done resizing');
                        redrawchart();
                    }
                }

                var test = function () {
                    rtime = new Date();
                    if (timeout === false) {
                        timeout = true;
                        setTimeout(resizeend, delta);
                    }
                }

                function redrawchart() {
                    var chart = Highcharts.chart(element[0], options);
                    var w = $(element).closest(".pieWrapper").width();
                    // setsize will trigger the graph redraw 
                    chart.setSize(
                        w, w * (3 / 4), false
                    );
                }

                //$(window).resize(redrawchart);
                window.addEventListener('resize', test, false);
                $timeout(redrawchart, 0);
                scope.$watch(function () {
                    return scope.data;
                }, function (newVal) {
                    if (newVal) {
                        options.series[0].data = scope.data;
                        $timeout(redrawchart, 0);
                    }
                }, true);
                scope.$on('$destroy', function () {
                    window.removeEventListener('resize', test, false);
                });
            }
        };
    });

    cipo.filter('makePositive', function () {
        return function (num) {
            if (num) return Math.abs(num);

        }
    });

    cipo.directive("fileread", [function () {
        return {
            scope: {
                fileread: "="
            },
            link: function (scope, element, attributes) {
                element.bind("change", function (changeEvent) {
                    var reader = new FileReader();
                    reader.onload = function (loadEvent) {
                        scope.$apply(function () {
                            scope.fileread = loadEvent.target.result;
                        });
                    }
                    reader.readAsDataURL(changeEvent.target.files[0]);
                });
            }
        }
    }]);

    cipo.directive('signature', function ($state, userService) {
        return {
            restrict: 'E',
            template: '<div></div>',
            scope: {
                options: '='
            },
            link: function (scope, element) {
                scope.$watch(function () {
                    return scope.options;
                },
                    function (n) {
                        if (n && n.sign) {
                            n.signatureId = n.signatureId || 0;
                            n.signatureToken = n.signatureToken || '';
                            HelloSign.init(n.clientId);
                            HelloSign.open({
                                url: n.url,
                                allowCancel: true,
                                skipDomainVerification: !n.isProd,
                                messageListener: function (eventData) {
                                    n.setSigned(n.signatureId, n.signatureToken);
                                    n.callback();
                                    if (n.redirect)
                                        $state.go("tenant." + userService.firstPage, { tenantIdentifier: userService.getTenantIdentifierById(userService.tenantId) })
                                }
                            });
                        }
                    });
            }
        };
    });

    cipo.directive('triggerfocus', ['$timeout', function ($timeout) {
        return {
            scope: {
                "triggerfocus": "=",
                "triggerbody": "=?",
                "triggerindex": "=?",
            },
            link: function (scope, element, attrs) {
                // console.error(scope, element, attrs);
                // if ($(element[0]).hasClass('ng-invalid-number')) console.error('hasClass!');
                scope.$watch(function () {
                    return scope.triggerfocus;
                }, function (newVal) {
                    if (newVal === true) {
                        $timeout(function () {
                            element[0].focus();
                            scope.triggerFocus = false;
                        });
                    }
                });
            }
        };
    }]);




    cipo.directive('scrolldiv', function ($window, $timeout) {
        return {
            restrict: 'A',
            link: function (scope, element, attr) {
                var divToScroll, divAdded;
                divToScroll = angular.element(document.getElementById('divtoscroll'));
                divAdded = angular.element(document.getElementById('addedDiv'));
                if (divToScroll.length != 0) {
                    divToScroll.animate({ scrollTop: divToScroll.scrollTop() + divAdded.offset().top }, 'slow');
                }
                else {
                    $("html, body").animate({ scrollTop: divAdded.offset().top - 150 }, 'slow');
                }

                $("#addedDiv").removeAttr("id");


            }
        };
    });

    cipo.directive('scrolldivtoselectedchild', function () {
        return {
            restrict: 'A',
            scope: {
                "nodeid": "="
            },
            link: function (scope, element, attr) {
                var divToScroll = $(element), divAdded;
                var offsetTop = function (el) {
                    var offset = el.offset();
                    return offset ? offset.top : 0;
                }
                
                scope.$watch(function () {
                    return scope.nodeid;
                }, function (n, o) {
                    if (n) {
                        divAdded = angular.element(document.getElementById('node' + n));

                        if (divToScroll.length != 0) {
                            divToScroll.animate({ scrollTop: (divToScroll.scrollTop() - offsetTop(divToScroll) + offsetTop(divAdded)) }, 'slow');
                        }
                        else {
                            $("html, body").animate({ scrollTop: offsetTop(divAdded) - 150 }, 'slow');
                        }
                    }
                });
            }
        };
    });

    cipo.directive('scrolldivtobottom', function ($window, $timeout) {
        return {
            restrict: 'A',
            scope: {
                "flag": "="
            },
            link: function (scope, element, attr) {
                scope.$watch(function () {
                    return scope.flag;
                }, function (n, o) {
                    if (n) {
                        $(element).animate({ scrollTop: $(element)[0].scrollHeight }, 'slow');
                    }
                });



            }
        };
    });

    cipo.directive('scrollpagetotopofdiv', function ($window, $timeout) {
        return {
            restrict: 'A',
            scope: {
                "scrolltotop": "=",
                "isinit": "=?",
            },
            link: function (scope, element, attr) {
                var divToScroll;
                divToScroll = angular.element(document.getElementById(attr.id));

                scope.$watch(function () {
                    return scope.scrolltotop;
                }, function (n, o) {
                    if (n) {
                        if (!scope.isinit)
                            $("html, body").animate({ scrollTop: $("#" + attr.id).offset().top - 200 }, 'slow');
                        else $("html, body").animate({ scrollTop: 0 }, 'slow');
                    }
                });

            }
        };
    });

    cipo.directive('setmaxheight', function ($window) {
        return {
            restrict: 'A',
            link: function (scope, element, attr) {
                var maxHeight = $window.innerHeight - 190;
                $(element).css('max-height', maxHeight + 'px');
            }
        };
    });

    cipo.directive('flexiblesearch', function ($window, $timeout) {
        return {
            link: function (scope, el, attr) {
                var calcSize = function (extra) {
                    var extra = extra || 0;
                    var logoSize = (angular.element('.logo').is(':visible')) ?
                        angular.element('.logo').outerWidth(true) : 0;
                    var topnavSize = angular.element($window).width();
                    var rightLinksSize = angular.element('.navbar-right').outerWidth(true);
                    var availableSize = topnavSize - (100 + extra + logoSize + rightLinksSize);
                    // $(el).find(':text').val(availableSize);
                    // $(el).find(':text').css('background-color', 'red');
                    $(el).css('width', availableSize + 'px');
                }

                angular.element('.logoimg').bind('load', function () {
                    calcSize(20);
                });

                angular.element($window).bind('resize', function () {
                    calcSize();
                });
            }
        }
    });

    cipo.directive('fullPageLoader', function () {
        return {
            restrict: 'AE',
            scope: {
                'pageLoader': '='
            },
            link: function ($scope, el, attrs) {
                $scope.$watch(function () {
                    return $scope.pageLoader;
                }, function (n, o) {
                    if (n) {
                        $("body").append('<div class="pageOverlay" style="z-index: 50000"><div class="sk-spinner sk-spinner-wave">\
                                            <div class="sk-rect1"></div>\
                                            <div class="sk-rect2"></div>\
                                            <div class="sk-rect3"></div>\
                                            <div class="sk-rect4"></div>\
                                            <div class="sk-rect5"></div>\
                                        </div></div>');
                    } else {
                        $(".pageOverlay").remove();
                    }

                });
            }
        }

    });

    cipo.directive('closeWhenClickedOutside', function () {
        return {
            restrict: 'A',
            scope: {
                'trigger': '=',
                'condition': '='
            },
            link: function ($scope, el, attr) {
                angular.element(document).bind('click', function (event) {
                    if (!$(event.target).closest(el).length) {
                        if ($scope.condition) {
                            $scope.trigger();
                        }
                    }
                });
            }
        }
    });

    cipo.directive('attrcond', function () {
        return {
            restrict: 'A',
            scope: {
                "attrcond": "="
            },
            link: function ($scope, el, attrs) {
                $scope.$watch($scope.attrcond, function () {
                    if ($scope.attrcond) {
                        $(el).attr(attrs['attradd'].toString(), attrs['attradd'].toString());
                    } else {
                        $(el).removeAttr(attrs['attradd']);
                    }
                });
            }
        }
    });

    cipo.directive('addAttr', function () {
        return {
            restrict: 'A',
            scope: {
                "attrProperties": "="
            },
            link: function ($scope, el, attrs) {
                if (typeof $scope.attrProperties != "undefined") {
                    for (var key in $scope.attrProperties) {

                        if ($scope.attrProperties.hasOwnProperty(key)) {
                            $(el).attr(key, $scope.attrProperties[key]);
                        }
                    }
                }
            }
        }
    });

    cipo.directive('toggleClass', function () {
        return {
            restrict: 'A',
            scope: {
                "triggeredBy": "=",
                "classToToggle": "="
            },
            link: function ($scope, el, attrs) {
                var classToToggle = attrs.classToToggle;

                $scope.$watch(function () { return $scope.triggeredBy; }, function (n, o) {
                    if (n == false) {
                        //$(collapseTarget).addClass(classtotoggle);
                        $(el).addClass(classToToggle);
                    }
                    if (n) {
                        //$(collapseTarget).removeClass(classtotoggle);
                        $(el).removeClass(classToToggle);
                    }
                });

            }
        }

    });

    cipo.directive('toggleattachmentform', function () {
        return {
            restrict: 'A',
            scope: {
                "triggeredBy": "=",
                "classToToggle": "="
            },
            link: function ($scope, el, attrs) {
                var classToToggle = attrs.classToToggle;
                var parent = $(el).closest("form-layout").find(".formWrapper");
                $scope.$watch(function () { return $scope.triggeredBy; }, function (n, o) {
                    if (n == false) {
                        //$(collapseTarget).addClass(classtotoggle);
                        $(el).addClass(classToToggle);
                        parent.addClass("overlayed");
                    }
                    if (n) {
                        //$(collapseTarget).removeClass(classtotoggle);
                        $(el).removeClass(classToToggle);
                        parent.removeClass("overlayed");
                    }
                });

            }
        }

    });

    cipo.directive('toggleClassSimple', function () {
        return {
            restrict: 'A',
            scope: {
                "classToToggle": "="
            },
            link: function ($scope, el, attrs) {
                var classToToggle = attrs.classToToggle;

                el.on("click", function () {
                    $(el).toggleClass('expanded');
                    $(el).next().toggleClass(classToToggle);
                });
            }
        }
    });

    cipo.directive('tabWatcher', function () {
        return {
            require: ['mdTabs'],
            restrict: 'A',
            link: function (scope, el, attrs, controllers) {
                var mdTabsCtrl = controllers[0];
                var origSelectFn = mdTabsCtrl.select;

                // overwrite original function with our own
                mdTabsCtrl.select = function (index, canSkipClick) {
                    // emit an event with accept/reject functions
                    scope.$emit('tab-change', {
                        index: index,
                        accept: function () {
                            return origSelectFn(index, canSkipClick);
                        },
                        reject: function () { }
                    });
                };
            }
        }
    });

    cipo.directive('reportsd', function ($timeout) {
        return {
            restrict: 'EA',
            scope: {
                "config": "="
            },
            template: '<div id="reportContainer"></div>',
            replace: true,
            link: function ($scope, el, attrs) {
                $scope.$watch(function () { return $scope.config }, function (n, o) {
                    if (n) {
                        var accessToken = n.embedToken.token;
                        var embedUrl = n.embedUrl || '';
                        var embedReportId = n.id || '';
                        var models = window['powerbi-client'].models;

                        var c = {
                            type: 'report',
                            tokenType: models.TokenType.Embed,
                            accessToken: accessToken,
                            embedUrl: embedUrl,
                            id: embedReportId,
                            permissions: models.Permissions.All,
                            settings: {
                                filterPaneEnabled: false,
                                navContentPaneEnabled: true
                            }
                        };

                        var reportContainer = $(document).find('#reportContainer')[0];

                        var report = powerbi.embed(reportContainer, c);
                    }
                });
            }
        }
    });

    cipo.filter('trustContent', ['$sce', function ($sce) {
        return function (input) {
            return input ? $sce.trustAsHtml(input.toString()) : input;
        }
    }]);

    cipo.filter('highlight', ['$sce', function ($sce) {
        return function (text, phrase) {
            if (phrase) text = text.replace(new RegExp('(' + phrase + ')', 'gi'),
                '<span class="highlighted">$1</span>')

            return $sce.trustAsHtml(text)
        }
    }]);

    cipo.directive("managerrowssswap", function ($document, $window, $timeout) {
        return {
            restrict: 'A',
            scope: {
                manager: "="
            },
            link: function (scope, element, attributes) {
                var isScrolledIntoView = function(elem) {
                    var docViewTop = $(window).scrollTop();
                    var docViewBottom = docViewTop + $(window).height();

                    var elemTop = ($(elem).offset() || {}).top || 0;
                    var elemBottom = elemTop + $(elem).height();

                    return ((elemBottom >= docViewTop) && (elemTop <= docViewBottom));
                }
                $document.on('scroll', function () {
                    var loadMoreItemsVisible = isScrolledIntoView('.loadMoreItems');
                    if ($window.scrollY == 0) {
                        $timeout(function () {
                            if ((scope.manager.topRows || []).length) {
                                var toAdd = scope.manager.topRows.splice(-scope.manager.objectsPerPage, scope.manager.objectsPerPage);
                                scope.manager.rows = [...toAdd, ...scope.manager.rows];

                                if (scope.manager.rows.length > scope.manager.maximumRows) {
                                    var items = scope.manager.itemsEnd % scope.manager.objectsPerPage || scope.manager.objectsPerPage;
                                    var removed = scope.manager.rows.splice(-items, items);
                                    scope.manager.bottomRows = [...removed, ...scope.manager.bottomRows];
                                }
                            }
                        }, 0);
                    }
                    else if (loadMoreItemsVisible) {
                        $timeout(function () {
                            if ((scope.manager.bottomRows || []).length) {
                                var items = scope.manager.itemsEnd % scope.manager.objectsPerPage || scope.manager.objectsPerPage;
                                var toAdd = scope.manager.bottomRows.splice(0, items);
                                var removed = scope.manager.rows.splice(0, scope.manager.objectsPerPage);
                                scope.manager.topRows = [...scope.manager.topRows, ...removed];
                                scope.manager.rows = [...scope.manager.rows, ...toAdd];
                            }
                            if (scope.manager.rows.length > scope.manager.maximumRows) {
                                var removed = scope.manager.rows.splice(0, scope.manager.objectsPerPage);
                                scope.manager.topRows = [...scope.manager.topRows, ...removed];
                            }
                        }, 0);
                    }
                });
            }
        }
    });

    cipo.filter('filterManagerData', function () {
        return function (value, conditionArray, separatorsArray) {
            var conditions = {
                ">": function (val, ev) { return val > ev },
                "<": function (val, ev) { return val < ev },
                "==": function (val, ev) { return val == ev },
                "!=": function (val, ev) { return val != ev }
            }
            var conditionArray = conditionArray || [];
            var separatorsArray = separatorsArray || [];
            if (conditionArray.length) {
                if (typeof conditionArray[0] == "string") {

                    if (conditions[conditionArray[0]](value, conditionArray[1])) {
                        if (separatorsArray.length) {
                            if (conditionArray[3])
                                return separatorsArray[0] + value + separatorsArray[1]
                            else return separatorsArray[0] + separatorsArray[1]
                        }
                        else return value;
                    } else return conditionArray[2];

                } else if (Object.prototype.toString.call(conditionArray[0]) == "[object Array]") {
                    for (var i = 0; i < conditionArray.length; i++) {
                        if (conditions[conditionArray[i][0]](value, conditionArray[i][1])) {
                            if (conditionArray[i][3])
                                return separatorsArray[i][0] + value + separatorsArray[i][1]
                            else return separatorsArray[i][0] + separatorsArray[i][1];
                        }
                    }
                    return conditionArray[0][2];
                }
            }
            else {
                return separatorsArray[0] + value + separatorsArray[1]
            }

        }
    });

    cipo.filter('convertManagerData', function () {
        return function (value, conditionArray, separatorsArray) {
            if (!value) return "-";

            var conditions = {
                ">": function (val, ev) { return val > ev },
                ">=": function (val, ev) { return val >= ev },
                "<": function (val, ev) { return val < ev },
                "==": function (val, ev) { return val == ev },
                "!=": function (val, ev) { return val != ev }
            }

            var calculate = {
                "/": function (val, ev) { return val / ev }
            }

            var conditionArray = conditionArray || [];
            var separatorsArray = separatorsArray || [];

            if (conditionArray.length) {
                for (var i = 0; i < conditionArray.length; i++) {
                    if (conditions[conditionArray[i][0]](value, conditionArray[i][1])) {
                        if (conditionArray[i][3])
                            if (conditionArray[i][4]) {
                                return separatorsArray[i][0] + Math.round(calculate[conditionArray[i][4][0]](value, conditionArray[i][4][1])) + separatorsArray[i][1]
                            } else return separatorsArray[i][0] + value + separatorsArray[i][1]
                        else return separatorsArray[i][0] + separatorsArray[i][1];
                    }
                }
            }
            //return conditionArray[0][2];

        }
    });

    cipo.filter('propsFilter', function () {
        return function (items, props) {
            var out = [];

            if (angular.isArray(items)) {
                items.forEach(function (item) {
                    var itemMatches = false;

                    var keys = Object.keys(props);

                    for (var i = 0; i < keys.length; i++) {
                        var prop = keys[i];
                        var text = props[prop].toLowerCase();

                        if (item[prop].toString().toLowerCase().indexOf(text) !== -1) {
                            itemMatches = true;

                            break;
                        }
                    }

                    if (itemMatches) {
                        out.push(item);
                    }
                });
            }
            else {
                // Let the output be the input untouched
                out = items;
            }

            return out;
        };
    });

    cipo.filter('itvNumberWithFormattings', ['userService', function (userService) {
        return function (value, formattings, restrictions, currency, percentageSymbol, hasMultipleValues) {
            return userService.formatNumberWithFormattings(value, formattings, restrictions, currency ?? { symbol: '' }, percentageSymbol, hasMultipleValues ?? false);
        }
    }]);

    cipo.filter('itvNumber', ['userService', function (userService) {
        return function (value, decimals, showComma, showCurrency, showPercentage, currency, percentageSymbol) {
            return userService.formatNumber(value, decimals ?? 0, showComma ?? true, showCurrency ?? false, showPercentage ?? false, currency ?? { symbol: '' }, percentageSymbol);
        }
    }]);

    cipo.filter('itvCurrency', ['userService', function (userService) {
        return function (value, currency, decimals, showComma) {
            return userService.formatCurrency(value, currency ?? { symbol: '' }, decimals ?? 0, showComma ?? true);
        }
    }]);

    cipo.filter('momentdate', ['userService', function (userService) {
        return function (date, format) {
            return userService.formatDateTimeCustom(date, format);
        }
    }]);

    cipo.filter('itvDateWithRestrictions', ['userService', function (userService) {
        return function (value, restrictions) {
            return userService.formatDateTimeBasedOnRestrictions(value, restrictions);
        }
    }]);

    cipo.directive('dtmomentpicker', [
        '$timeout', 'userService',
        function ($timeout, userService) {
            return {
                restrict: 'EA',
                require: 'ngModel',
                scope: {
                    options: '=?',
                    onChange: '&?',
                    onClick: '&?'
                },
                link: function ($scope, $element, $attrs, ngModel) {
                    var initialConfig = false;

                    var zonesNameByOffset = {};

                    var getZoneName = function () {
                        var offset = -userService.system.userdata?.timeZoneOffsetMinutes ?? 0;
                        if (zonesNameByOffset[offset]) {
                            return zonesNameByOffset[offset];
                        }

                        var zone;
                        moment.tz.countries().forEach(function (country) {
                            if (zone) {
                                return;
                            }
                            var zones = moment.tz.zonesForCountry(country, true);
                            var foundZone = (zones || []).find(z => z.offset === offset);
                            if (foundZone) {
                                zone = foundZone.name;
                            }
                        });
                        if (zone) {
                            zonesNameByOffset[offset] = zone;
                            return zone;
                        }
                        return undefined;
                    }

                    $scope.$watch('options', function (newValue, oldValue) {
                        if (initialConfig) {
                            var dtp = $element.data('DateTimePicker');
                            var hasTime = false;
                            $.map(newValue, function (value, key) {
                                value && dtp[key](value);
                                if (key === 'format' && value && value.toString().toLowerCase().includes('hh:mm')) {
                                    hasTime = true;
                                }
                            });
                            var zone = getZoneName();
                            if (hasTime && zone) {
                                dtp.timeZone(zone);
                            }
                        }

                        initialConfig = true;
                    });

                    ngModel.$render = function () {
                        if (!$element.data('DateTimePicker')) {
                            $element.datetimepicker();
                            initialConfig = true;
                        }

                        if (moment.isMoment(ngModel.$$rawModelValue)) {
                            ngModel.$setViewValue(ngModel.$$rawModelValue);
                        }
                        if (ngModel.$viewValue && $element.data('DateTimePicker')) {
                            $element.data('DateTimePicker').date(ngModel.$viewValue);
                        }
                    };

                    $element.on('dp.change', function (e) {
                        $timeout(function () {
                            $scope.$apply(function () {
                                ngModel.$setViewValue(e.date);
                            });
                            if (typeof $scope.onChange === "function") {
                                $scope.onChange();
                            }
                        });
                    });

                    $element.on('dp.hide', function (e) {
                        $timeout(function () {
                            $scope.$apply(function () {
                                ngModel.$setViewValue(e.date);
                            });
                        });
                    });

                    $element.on('click', function () {
                        $timeout(function () {
                            if (typeof $scope.onClick === "function") {
                                $scope.onClick();
                            }
                        });
                    });

                    $timeout(function () {
                        if (ngModel.$viewValue !== undefined && ngModel.$viewValue !== null) {
                            if (!moment.isMoment(ngModel.$viewValue)) {
                                var date = ngModel.$viewValue;
                                if (date && date.indexOf && date.indexOf("Z") == -1)
                                    date = date + "Z";
                                ngModel.$setViewValue(moment(date));
                            }
                            if (ngModel.$viewValue && $element.data('DateTimePicker')) {
                                $element.data('DateTimePicker').date(ngModel.$viewValue);
                            }
                        }
                    });
                }
            };
        }
    ]);

    cipo.directive('decimalPlaces', function($parse) {
        var decimalSeparatorISO = '.';
        var decimalSeparator = decimalSeparatorISO;
        var getReEscapedDecimalSeparator = function () {
            return '(\\' + decimalSeparator + ')';
        }
        var getReDecimalsCheck = function (decimals) {
            return '([0-9]{1,' + decimals + '})';
        }
        var getReInteger = function () {
            return '(-?)([0-9]*)';
        }
        var getReCheckInteger = function () {
            return '^' + getReInteger() + '$';
        }
        var getReCheckIntegerAndSeparator = function (decimals) {
            if (!decimals) {
            	return getReCheckInteger();
            }
            return '^' + getReInteger() + getReEscapedDecimalSeparator() + '$';
        }
        var getReCheckForDecimals = function (decimals) {
            if (!decimals) {
            	return getReCheckInteger();
            }
            return '^' + getReInteger() + getReEscapedDecimalSeparator() + getReDecimalsCheck(decimals) + '$';
        }
        var restoreOldValue = function (field, oldValue) {
            field.value = oldValue;
            field.focus();
            field.setSelectionRange(oldValue.length, oldValue.length);
        }
        return {
            link: function(scope, element, attr) {
                var decimals = $parse(attr.decimalPlaces)(scope);
                if (decimals === undefined) {
                    // no decimals set, allow everything
                    return;
                }

                decimalSeparator = $parse(attr.decimalSeparator)(scope) ?? decimalSeparatorISO;
                
                var re1 = new RegExp(getReCheckForDecimals(decimals));
                var re2 = new RegExp(getReCheckIntegerAndSeparator(decimals));
                var re3 = new RegExp(getReCheckInteger());
                var oldValue;
                
                element.bind('keydown', function(e) {
                    oldValue = e.target.value;
                });                
                element.bind('keyup', function(e) {
                    var value = e.target.value;
                    
                    if (isNaN(Number(value?.toString().replace(decimalSeparator, decimalSeparatorISO))) || !decimals && (e.key == decimalSeparator || value?.toString()?.endsWith(decimalSeparator))) {
                        // not a valid number 
                        // or decimal separator character is pressed but no decimals are allowed
                        restoreOldValue(e.target, oldValue);
                    }
                    else if (!(re1.test(value) || re2.test(value) || re3.test(value))) {
                        // string does not have the same number of decimals
                        restoreOldValue(e.target, oldValue);
                    }
                });
            }
        };
    });
