import { Currency } from "../../models/system";

export interface ContextInfoPart {
  id: number;
  name: string;
  no?: string;
  entityInstanceId?: number;
  moduleId: number;
  moduleAbbr: string;
  moduleIconId?: number;
  moduleColor?: string;
  currencyId?: number;
  isArchived?: boolean;
  isClosed?: boolean;
  isUsed?: boolean;
  hasPermission?: boolean;
}

export interface ContextInfo {
  program?: ContextInfoPart;
  project?: ContextInfoPart;
  contract?: ContextInfoPart;
}

export interface ContextIds {
  programId: number;
  projectId: number;
  contractId: number;
}

export interface ContextTree {
  programs: ContextProgram[];
  modules: ContextModule[];
  currencies: Currency[];
}

export interface ContextContract {
  id: number;
  entityInstanceId: number;
  moduleId: number;
  name: string;
  no: string;
  isUsed: boolean;
  isArchived: boolean;
  isClosed: boolean;
  currencyId?: number;
  hasPermission?: boolean;
}

export interface ContextProject {
  id: number;
  entityInstanceId: number;
  moduleId: number;
  name: string;
  no?: string;
  currencyId?: number;
  hasPermission?: boolean;
  contracts: ContextContract[];
}

export interface ContextProgram {
  id?: number;
  entityInstanceId?: number;
  moduleId?: number;
  name?: string;
  no?: string;
  currencyId?: number;
  hasPermission?: boolean;
  projects: ContextProject[];
}

export interface ContextModule {
  moduleId: number;
  abbr: string;
  iconId?: number;
  color: string;
}

export enum ShowTypeEnum {
  Active = 1,
  OnlyClosed = 2,
  OnlyArchived = 3,
  All = 4,
}
