<div class="p-a-lg">
    <h1>This is a h1 element</h1>
    <h2>This is a h2 element</h2>
    <h3>This is a h3 element</h3>
    <h4>This is a h4 element</h4>
    <h5>This is a h5 element</h5>
    <h6>This is a h6 element</h6>
    <p>This is a default paragraph element</p>
    <p class="cipo-text--regular-lg">This is a regular-lg paragraph element</p>
    <p class="cipo-text--regular-md">This is a regular-md paragraph element</p>
    <p class="cipo-text--regular-sm">This is a regular-sm paragraph element</p>
    <p class="cipo-text--medium-lg">This is a medium-lg paragraph element</p>
    <p class="cipo-text--medium-md">This is a medium-md paragraph element</p>
    <p class="cipo-text--medium-sm">This is a medium-sm paragraph element</p>
    <p class="cipo-text--bold-md">This is a bold-md paragraph element</p>
</div>
