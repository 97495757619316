import { inject, Pipe, PipeTransform } from '@angular/core';
import { MeasurementSystem } from '../../../models/module/fields/enums/measurementSystem';
import { TenantSettingsEnum } from '../../../models/tenant';
import { TenantStore } from '../../../signal-store';
import { UtilsService } from '../../services';
import { Measurements, MeasurementsConversion, MeasurementType } from './measurement.model';

@Pipe({
  name: 'measurement',
})
export class MeasurementPipe implements PipeTransform {
  tenantStore = inject(TenantStore);
  utilsService = inject(UtilsService);

  transform(
    value: number | string | null | undefined,
    measurementType: MeasurementType,
    convertFromMeasurementSystem?: keyof typeof MeasurementSystem,
  ): string {
    const measurementSystem = this.tenantStore.getSetting(TenantSettingsEnum.measurement_system)
      .calculatedValue as keyof typeof MeasurementSystem;
    let measurement = '';
    if (typeof measurementSystem === 'string') {
      measurement = Measurements[measurementSystem][measurementType] ?? '';
      if (convertFromMeasurementSystem) {
        value = this.convertValue(value, measurementType, convertFromMeasurementSystem, measurementSystem);
      }
    }
    if (value !== undefined && measurement) {
      return `${value} ${measurement}`;
    }
    return '-';
  }

  private convertValue(
    value: number | string,
    measurementType: MeasurementType,
    convertFromMeasurementSystem: keyof typeof MeasurementSystem,
    convertToMeasurementSystem: keyof typeof MeasurementSystem,
  ): string {
    if (Number.isNaN(Number(value))) {
      return undefined;
    }
    value = Number(value);
    if (convertFromMeasurementSystem !== convertToMeasurementSystem) {
      // convert the value to the target measurement system
      value = MeasurementsConversion[convertFromMeasurementSystem][convertToMeasurementSystem][measurementType](value);
    }

    // round the value to 1 decimal place
    return this.utilsService.transformToNumberOrCurrencyString(value, 1);
  }
}
