import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { computed } from '@angular/core';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';

import { ContextContract, ContextProgram, ContextProject, ContextTree } from '../system/context/context.model';
import { Currency } from '../models/system';

export type ContextState = {
  programs: ContextProgram[];
  currencies: Currency[];
};

type ContextPartIds = {
  entityInstanceId?: number;
  no?: string;
};

type ContextContractIds = ContextPartIds & { contractId?: number };
type ContextProjectIds = ContextPartIds & { projectId?: number };
type ContextProgramIds = ContextPartIds & { programId?: number };

const initialState: ContextState = {
  programs: [],
  currencies: [],
};

export const ContextStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withDevtools('context'),
  withComputed(store => {
    const getProjects = () =>
      (store.programs() || []).reduce<ContextProject[]>((acc, program) => {
        return [...acc, ...(program.projects || [])];
      }, []);
    return {
      projects: computed(() => getProjects()),
      contracts: computed(() =>
        getProjects().reduce<ContextContract[]>((acc, project) => {
          return [...acc, ...(project.contracts || [])];
        }, []),
      ),
    };
  }),
  withMethods(store => {
    return {
      set: (data: Pick<ContextTree, 'currencies' | 'programs'>) => {
        patchState(store, { programs: data.programs, currencies: data.currencies });
      },
      getCurrency: (id: number) => store.currencies().find(c => c.currencyId === id),
      getProgram: ({ programId, entityInstanceId, no }: ContextProgramIds) =>
        store.programs().find(p => p.id === programId || p.entityInstanceId === entityInstanceId || p.no === no),
      getProject: ({ projectId, entityInstanceId, no }: ContextProjectIds) =>
        store.projects().find(p => p.id === projectId || p.entityInstanceId === entityInstanceId || p.no === no),
      getContract: ({ contractId, entityInstanceId, no }: ContextContractIds) =>
        store.contracts().find(c => c.id === contractId || c.entityInstanceId === entityInstanceId || c.no === no),
    };
  }),
);
