import { cipo } from 'cipo';

cipo.factory("TemplateMod", function ($q, Model, URI, Message, Form, TEMPLATES_TYPES, Dictionaries, ModuleDictionaries, MAX_NR_VISIBLE_SELECT_ITEMS, userService) {
    const ORIENTATION_PORTRAIT = 0;
    const ORIENTATION_LANDSCAPE = 1;

    var TemplateMod = Model.extend(function (moduleId, isNotification, isGroup, screenId, obj) {


        var self = this;

        self.sources = isNotification ? [] : isGroup ? ["allRoles", "templatesWithoutConditionals", "conditionalOperators"] : ["allRoles", "headers", "footers", "startScreens"];
        self.moduleId = moduleId || null;
        self.options = {};
        self.descriptionOptions = {};
        self.isNotification = isNotification || false;
        self.isGroup = isGroup || false;
        self.screenId = screenId;

        self.isConditionsLoaded = false;
        self.conditionsList = [];
        self.betweenOperatorId = 13;

        // Initialize moduleInfo, probably is already initialized and we just need to add screenId too there.
        // For allRoles dictionary we will need moduleTypeId that probably is already initialized
        var moduleInfo = ModuleDictionaries.moduleInfo || { moduleId: moduleId, screenId: screenId };
        moduleInfo.screenId = screenId;
        ModuleDictionaries.init(moduleInfo);

        self.properties = {
            id: null,
            name: "",
            description: "",
            definition: "",
            roleIds: [],

            typeName: moduleId == -11 ? TEMPLATES_TYPES[2] : isNotification ? TEMPLATES_TYPES[3] : TEMPLATES_TYPES[1]
        };
        if (!isNotification && !isGroup) {
            self.properties.useDefaultFooter = false;
            self.properties.useDefaultHeader = false;
            self.properties.isLandscape = null;
            self.properties.headerId = null;
            self.properties.footerId = null;
            self.properties.marginLeft = null;
            self.properties.marginRight = null;
            self.properties.marginTop = null;
            self.properties.marginBottom = null;
            self.properties.screenId = screenId;
            self.properties.annt = "<h3 style='padding-top: 15px;'>Printout margins (inches):</h3>";
        }
        else if (isGroup) {
            self.properties.conditionalsTemplate = [];
            self.properties.fieldsTypes = { "1": [], "2": [], "3": [] };
            self.properties.operators = [];
            self.properties.conditionFieldsDict = [];
            self.properties.defaultTemplateId = null;
            self.properties.screenId = screenId;
        }
        else if (isNotification) {
            self.properties.useForTag = false;
        }
        if (obj) {
            for (var key in obj) {
                if (obj.hasOwnProperty(key)) {
                    self.properties[key] = obj[key];

                }
            }
            if (obj.isLandscape !== undefined) {
                self.properties.isLandscape = obj.isLandscape ? ORIENTATION_LANDSCAPE : ORIENTATION_PORTRAIT;
            }
        }
        self.loadingDetails = true;
        self.loadingDict = true;

        // If we have an id, then load data
        if (!self.properties.id) {
            // Create form
            self.createForm();
        }
    });

    TemplateMod.prototype.get_FieldType = function (id) {
        var self = this;
        var type;
        for (var key in self.properties.fieldsTypes) {
            if (self.properties.fieldsTypes.hasOwnProperty(key)) {
                if (self.properties.fieldsTypes[key].indexOf(id) != -1) {
                    type = key;
                    break;
                }
            }
        }
        return type;
    }

    TemplateMod.prototype.getFieldDatasource = function (dataSourceId) {
        var p = $q.defer();
        var self = this;
        var urlData = URI.FIELD_DEFINITION.DATASOURCE_LIST;
        var bodyParams = {
            id: dataSourceId,
            selectedIds: [],
            search: {
                criteria: "",
                isCurrent: false, page: 1, pagesize: MAX_NR_VISIBLE_SELECT_ITEMS
            }
        }

        self[urlData.method](urlData, { body: bodyParams }, { headers: { moduleId: userService.system.selectedModuleId } })
            .then(function (r) {
                p.resolve(r.data);
            }).catch(function (e) {
                Message.dberror(e);
                p.resolve([]);
            })

        return p.promise;
    }

    TemplateMod.prototype.get_FieldDataSourceId = function (id) {
        var self = this;
        return self.properties?.conditionFieldsDict?.find(o => o.key == id)?.dataSourceId;
    }

    TemplateMod.prototype.createConditionForm = function (item) {
        var self = this;
        var form = new Form(item);
        var x = self.get_FieldType(item.fieldId) || 0;
        if (item.value === undefined)
            item.value = null;
        //create form
        var isValueRequired = (op) => op && ![9, 10, 11, 12].includes(op);
        var formContent = {
            fieldId: { label: 'If Field', type: 'select', options: self.properties.conditionFieldsDict, validation: { required: true } },
            templateId: { label: 'To printout', type: 'select', options: ModuleDictionaries.dataSources.templatesWithoutConditionals.data, validation: { required: true } },
            operatorId: { label: 'Operator', type: 'select', options: item.id ? self.properties.operators[x] : [], validation: { required: true } },
            value: { label: 'Value', type: item.dataSourceId ? 'select': 'text' },
            value2: { label: 'Second Value', type: item.dataSourceId ? 'select': 'text', visible: item.operatorId == self.betweenOperatorId },
        };
        if (item.dataSourceId) {
            formContent.value.options = item.options || [];
            formContent.value2.options = item.options || [];
        }
        if (isValueRequired(item.operatorId)) {
            formContent.value.validation = { required: true };
            if (item.operatorId == self.betweenOperatorId)
                formContent.value2.validation = { required: true };
        }
        else {
            formContent.value.editMode = false;
            formContent.value2.editMode = false;
        }
        if (item.id) form.editMode = false;
        form.set_Description(formContent);
        var onClose = function (field) {
            console.error('changed', self, field);
            item.value = null;
            item.value2 = null;
            form.data.operatorId = null;
            form.set_Description(
                {
                    operatorId: {
                        label: 'Operator', type: 'select',
                        options: self.properties.operators[self.get_FieldType(item.fieldId) || 0],
                        validation: { required: true },
                        onClose: function (field) {
                            operatorIdOnClose(field);
                            form.fieldsList.operatorId.onClose = this.onClose;
                        }
                    },
                    fieldId: {
                        label: 'If Field', type: 'select',
                        options: self.properties.conditionFieldsDict,
                        validation: { required: true },
                        onClose: function (field) {
                            onClose(field);
                            form.fieldsList.fieldId.onClose = this.onClose;
                        }
                    }
                }, true);

            operatorIdOnClose();
        }
        form.fieldsList.fieldId.onClose = function (field) {
            onClose(field);
            form.fieldsList.fieldId.onClose = this.onClose;
        };
        form.fieldsList.operatorId.onClose = function (field) {
            operatorIdOnClose(field);
            form.fieldsList.operatorId.onClose = this.onClose;
        };
        var operatorIdOnClose = function (field) {
            item.value = null;
            item.value2 = null;
            var value2Needed = field && field._value && field._value == self.betweenOperatorId || false;
            var notEditable = !field || !isValueRequired(field._value);
            var dataSourceId = self.get_FieldDataSourceId(item.fieldId);
            if (dataSourceId) {
                if (item.options?.length) {
                    var fieldContent = {
                        value: { label: 'Value', type: 'select', options: item.options },
                        value2: { label: 'Second Value', type: 'select', options: item.options, visible: value2Needed },
                    };
                    if (isValueRequired(field?._value)) {
                        fieldContent.value.validation = { required: true };
                    }
                    if (value2Needed) {
                        fieldContent.value2.validation = { required: true };
                    }
                    form.set_Description(fieldContent, true);
                    form.fieldsList.value.editMode = !notEditable;
                    form.fieldsList.value2.editMode = !notEditable;
                    self.setGrid(form, value2Needed);
                } else {
                    self.getFieldDatasource(dataSourceId)
                        .then(function(data) {
                            // set options to not get them every time
                            item.options = data;

                            var fieldContent = {
                                value: { label: 'Value', type: 'select', options: data },
                                value2: { label: 'Second Value', type: 'select', options: data, visible: value2Needed },
                            };
                            if (isValueRequired(field?._value)) {
                                fieldContent.value.validation = { required: true };
                            }
                            if (value2Needed) {
                                fieldContent.value2.validation = { required: true };
                            }
                            form.set_Description(fieldContent, true);
                            form.fieldsList.value.editMode = !notEditable;
                            form.fieldsList.value2.editMode = !notEditable;
                            self.setGrid(form, value2Needed);
                        });
                }
            } else {
                if (notEditable) {
                    form.set_Description(
                        {
                            value: { label: 'Value', type: 'text' },
                            value2: { label: 'Second Value', type: 'text', visible: value2Needed },
                        }, true);
                    form.fieldsList.value.editMode = false;
                    form.fieldsList.value2.editMode = false;
                    self.setGrid(form, value2Needed);
                }
                else {
                    var fieldContent = {
                        value: { label: 'Value', type: 'text', validation: { required: true } }
                    };
                    if (value2Needed)
                        fieldContent.value2 = { label: 'Second Value', type: 'text', validation: { required: true }, visible: value2Needed };
                    else
                        fieldContent.value2 = { label: 'Second Value', type: 'text', visible: value2Needed };

                    form.set_Description(fieldContent, true);
                    form.fieldsList.value.editMode = true;
                    form.fieldsList.value2.editMode = true;
                    self.setGrid(form, value2Needed);
                }
            }
        }
        self.setGrid(form, item.operatorId == self.betweenOperatorId);
        form.store_Data();

        return form;
    }

    TemplateMod.prototype.setGrid = function (form, isBetween) {
        if (isBetween) {
            form.fieldsList.value2.visible = true;
            form.setTemplate('grid', [
                { fieldId: 20, operatorId: 20, value: 20, value2: 20, templateId: 20 }
            ]);
        }
        else {
            form.setTemplate('grid', [
                { fieldId: 25, operatorId: 25, value: 25, value2: 25, templateId: 25 }
            ]);
        }
    }

    TemplateMod.prototype.getRoles = function () {
        return ModuleDictionaries.dataSources.allRoles.data;
    }

    TemplateMod.prototype.getTemplatesDict = function () {
        return ModuleDictionaries.dataSources.templatesWithoutConditionals.data;
    }

    TemplateMod.prototype.getUsedRoles = function () {
        return (this.getRoles() || []).filter(r => r.isUsed);
    }

    TemplateMod.prototype.createForm = function () {
        var self = this;
        self.form = new Form(self.properties);
        self.form.initializing = true;
        //create form

        ModuleDictionaries.getDicts(self.sources)
            .then(function () {
                if (!self.isGroup) {

                    if (!self.isNotification && ModuleDictionaries.dataSources.startScreens.data.length === 1)
                        self.properties.screenId = ModuleDictionaries.dataSources.startScreens.data[0].key;

                    // Get description placeholders
                    self.get_PlaceholdersDescription()
                        .then(function () {
                            self.initForm();
                        })
                }
                else {
                    // Set operators
                    self.properties.operators = { "1": [], "2": [], "3": [], "0": ModuleDictionaries.dataSources.conditionalOperators.data };
                    if ((ModuleDictionaries.dataSources.conditionalOperators.data || []).length) {
                        for (var i = 0; i < ModuleDictionaries.dataSources.conditionalOperators.data.length; i++) {
                            self.properties.operators[ModuleDictionaries.dataSources.conditionalOperators.data[i].typeId].push(ModuleDictionaries.dataSources.conditionalOperators.data[i]);
                        }
                    }

                    // Get fields
                    Dictionaries.ConditionalFields({ moduleId: self.moduleId }, { screenId: self.screenId, onlyCurrentScreen: true })
                        .then(function (r) {

                            if (r.length) {
                                for (var i = 0; i < r.length; i++) {
                                    var key = r[i].key;
                                    var typeId = r[i].typeId;
                                    if (typeId == 2 || typeId == 5) {
                                        self.properties.fieldsTypes[2].push(key);
                                    } else if (self.properties.fieldsTypes[typeId]) {
                                        self.properties.fieldsTypes[typeId].push(key);
                                    }
                                }
                            }
                            self.properties.conditionFieldsDict = r;

                            self.set_conditionals();

                            self.initForm();

                            self.isConditionsLoaded = true;
                        })
                }
            })
    }

    TemplateMod.prototype.set_conditionals = function () {
        var self = this;

        // reset value
        self.conditionsList = [];
        // Set conditionals
        if (self.properties.conditionalsTemplate.length) {
            for (var i = 0; i < self.properties.conditionalsTemplate.length; i++) {
                self.conditionsList.push(self.createConditionForm(self.properties.conditionalsTemplate[i]));
            }
        }
    }

    TemplateMod.prototype.get_conditionals = function () {
        var self = this;
        var p = $q.defer();
        var urlData = URI.TEMPLATES.GET_CONDITIONALS;
        self.isConditionsLoaded = false;
        self.conditionsList = [];
        self[urlData.method](urlData, { url: { id: self.properties.id }, urltype: 'obj' }, { headers: { moduleId: self.moduleId } })
            .then(function (result) {
                self.properties.conditionalsTemplate = result;
                self.set_conditionals();
                self.isConditionsLoaded = true;

                p.resolve();
            })
            .catch(function (e) {
                self.form.catch(e);
                self.form.loading = false;
                p.reject();
            })
            .finally(function () {
                self.isBusy = false;
            })

        return p.promise;
    }

    TemplateMod.prototype.initForm = function () {
        var self = this;

        var form = {
            name: { label: 'Name', type: 'text', validation: { required: true, maxChars: 100 } },
            description: {
                label: self.isNotification ? 'Subject' : 'Description',
                restrictions: self.isNotification ? [{key:8, restrictionValue: 250}] : [],
                type: self.isNotification ? 'editor' : 'textarea',
                validation: self.isNotification ? {} : { maxChars: 100 },
                hints: self.isNotification ? self.descriptionOptions : null,
                noToolbarEditor: true,
                warning: self.isNotification && self.properties.id == null ? 'Need to save Notification first to have placeholders in the Subject !' : ''
            }
        };
        var grid = [];
        if (!self.isNotification && !self.isGroup) {
            form.roleIds = {
                label: 'Roles', type: 'select', multiple: true,
                options: self.getUsedRoles(), validation: { required: true }
            };
            form.marginBottom = { label: 'Bottom', type: 'number', validation: { isNumber: true, greaterThan: 0 } };
            form.annt = { label: 'Printout margins', type: 'annotation', annotation: self.properties.annt };
            form.marginTop = { label: 'Top', type: 'number', validation: { isNumber: true, greaterThan: 0 } };
            form.marginLeft = { label: 'Left', type: 'number', validation: { isNumber: true, greaterThan: 0 } };
            form.marginRight = { label: 'Right', type: 'number', validation: { isNumber: true, greaterThan: 0 } };
            grid = [
                { name: 50, roleIds: 50 },
                { description: 100 }
            ];
            if (self.properties.typeName != TEMPLATES_TYPES[2]) {
                form.headerId = { label: 'Select header', type: 'select', options: ModuleDictionaries.dataSources.headers.data };
                form.footerId = { label: 'Select footer', type: 'select', options: ModuleDictionaries.dataSources.footers.data };
                form.useDefaultHeader = { label: 'Use default header', type: 'checkbox' };
                form.useDefaultFooter = { label: 'Use default footer', type: 'checkbox' };
                form.isLandscape = { label: 'Default orientation', type: 'radio', options: [{ key: ORIENTATION_PORTRAIT, value: "Portrait" }, { key: ORIENTATION_LANDSCAPE, value: "Landscape" }] }
                grid.push({ headerId: 50, footerId: 50 })
                grid.push({ useDefaultHeader: 33, useDefaultFooter: 33, isLandscape: 33 });
            }
            grid.push({ annt: 100 });
            grid.push({ marginLeft: 25, marginRight: 25, marginTop: 25, marginBottom: 25 });

        } else if (self.isGroup) {

            form.roleIds = {
                label: 'Roles', type: 'select', multiple: true,
                options: self.getUsedRoles(), validation: { required: true }
            };
            form.defaultTemplateId = { label: 'Default printout', type: 'select', options: ModuleDictionaries.dataSources.templatesWithoutConditionals.data, validation: { required: true } };


            grid = [
                { name: 50, roleIds: 50 },
                { description: 50, defaultTemplateId: 50 }
            ];

        } else {

            form.useForTag = { label: 'Use for Tag', type: 'checkbox', visible: !self.properties.isUsed || self.properties.useForTag };

            grid = [
                { name: 80, useForTag: 20 },
                { description: 100 }
            ];
        }
        self.form.set_Description(form);

        self.form.setTemplate('grid', grid);

        self.form.store_Data();

        self.form.initializing = false;
    }

    TemplateMod.prototype.createDefinitionForm = function () {
        var self = this;
        //create form for definition
        self.definitionForm = new Form(self.properties);
        self.definitionForm.initializing = true;
        self.definitionForm.loading = true;
    }

    TemplateMod.prototype.save = function () {
        var self = this;
        var p = $q.defer();
        var urlData = self.properties.id ? URI.TEMPLATES.UPDATE : URI.TEMPLATES.CREATE;
        self.form.validate();

        if (self.properties.isLandscape !== undefined) {
            self.properties.isLandscape = self.properties.isLandscape == ORIENTATION_LANDSCAPE;
        }

        self.form.loading = true;
        if (self.form.isValid) {
            self.isBusy = true;
            self[urlData.method](urlData, { body: self.properties }, { headers: { moduleId: self.moduleId } })
                .then(function (result) {
                    if (!self.properties.id) self.properties.id = result;
                    Message.info('Template saved successfully');
                    p.resolve();
                })
                .catch(function (e) {
                    self.form.catch(e);
                    self.form.loading = false;
                    p.reject();
                })
                .finally(function () {
                    self.isBusy = false;
                })
        }
        else {
            self.form.loading = false;
            p.reject();
        }

        return p.promise;
    }

    TemplateMod.prototype.set_default = function () {
        var self = this;
        var p = $q.defer();
        var urlData = URI.TEMPLATES.SET_DEFAULT_TEMPLATE;
        self[urlData.method](urlData, { url: { id: self.properties.id }, urltype: 'obj' }, { headers: { moduleId: self.moduleId } })
            .then(function (result) {
                Message.info('Template set default successfully');
                p.resolve();
            })
            .catch(function (e) {
                p.reject();
                Message.dberror(e);
            })
            .finally(function () {

            })

        return p.promise;
    }

    TemplateMod.prototype.set_GroupDefault = function () {
        var self = this;
        var p = $q.defer();
        var urlData = URI.TEMPLATES.SET_DEFAULT_GROUP_TEMPLATE;
        self[urlData.method](urlData, { url: { id: self.properties.id }, urltype: 'obj' }, { headers: { moduleId: self.moduleId } })
            .then(function (result) {
                Message.info('Conditional template set default successfully');
                p.resolve();
            })
            .catch(function (e) {
                p.reject();
                Message.dberror(e);
            })
            .finally(function () {

            })

        return p.promise;
    }

    TemplateMod.prototype.set_tag = function () {
        var self = this;
        var p = $q.defer();
        var urlData = URI.TEMPLATES.SET_TAG_TEMPLATE;
        self[urlData.method](urlData, { url: { id: self.properties.id }, urltype: 'obj' }, { headers: { moduleId: self.moduleId } })
            .then(function (result) {
                Message.info('Template set as tag successfully');
                p.resolve();
            })
            .catch(function (e) {
                p.reject();
                Message.dberror(e);
            })
            .finally(function () {

        })

        return p.promise;
    }

    TemplateMod.prototype.unset_default = function () {
        var self = this;
        var p = $q.defer();
        var urlData = URI.TEMPLATES.UNSET_TAG_TEMPLATE;
        self[urlData.method](urlData, { url: { id: self.properties.id }, urltype: 'obj' }, { headers: { moduleId: self.moduleId } })
            .then(function (result) {
                Message.info('Template unset as tag successfully');
                p.resolve();
            })
            .catch(function (e) {
                p.reject();
                Message.dberror(e);
            })
            .finally(function () {

            })

        return p.promise;
    }

    TemplateMod.prototype.delete = function () {
        var self = this;
        var p = $q.defer();
        self.deleting = true;
        self.deletingMsg = "Deleting template...";
        var urlData = URI.TEMPLATES.DELETE;
        self[urlData.method](urlData, { url: { id: self.properties.id }, urltype: 'obj' }, { headers: { moduleId: self.moduleId } })
            .then(function (result) {
                Message.info('Template deleted successfully');
                p.resolve();
            })
            .catch(function (e) {
                self.deleting = false;
                self.deletingMsg = "Failed to delete template. Try again?";
                p.reject();
                Message.dberror(e);
            })
            .finally(function () {

            })

        return p.promise;
    }

    TemplateMod.prototype.init = function () {
        var self = this;
        var p = $q.defer();
        self.createDefinitionForm();
        self.isBusy = true;
        //var all = $q.all([, self.get_Data()]);
        var label = self.isNotification ? 'Notification content' : 'Printout content';
        self.get_Placeholders()
            .then(function () {
                self.definitionForm.set_Description({
                    definition: {
                        label: label, type: 'editor', hints: self.options
                    }
                });
                self.definitionForm.store_Data();
                self.definitionForm.initializing = false;
                self.get_Data();
                p.resolve();
            })
            .catch(function (e) { console.error(e); p.reject(); })
        return p.promise;
    }

    TemplateMod.prototype.get_Data = function () {
        var self = this;
        self.loadingDetails = true;
        self.isBusy = true;
        var p = $q.defer();
        var urlData = URI.TEMPLATES.GET;
        self[urlData.method](urlData, { url: { id: self.properties.id }, urltype: 'obj' }, { headers: { moduleId: self.moduleId } })
            .then(function (result) {
                self.properties.definition = result.definition;
                self.definitionForm.set_Data(self.properties);
                self.definitionForm.store_Data();
                p.resolve();
            })
            .catch(function (e) {
                p.reject();
                Message.dberror(e);
            })
            .finally(function () {
                self.loadingDetails = false;
                self.definitionForm.loading = false;
                self.isBusy = false;
            })

        return p.promise;
    }
    TemplateMod.prototype.checkDirty = function () {
        var self = this;
        if (self.definitionForm.dirty) return true;
        else return false;
    }
    TemplateMod.prototype.get_Placeholders = function () {
        var self = this;
        var p = $q.defer();
        self.loadingDict = true;
        var urlData = URI.TEMPLATES.DICT;
        self[urlData.method](urlData, { url: { id: self.properties.id }, urltype: 'obj' }, { headers: { moduleId: self.moduleId } })
            .then(function (result) {
                self.options = result;
                p.resolve();
            })
            .catch(function (e) {
                p.reject();
                Message.dberror(e);
            })
            .finally(function () {
                self.loadingDict = false;
            })

        return p.promise;
    }

    TemplateMod.prototype.get_PlaceholdersDescription = function () {
        var self = this;
        var p = $q.defer();
        self.loadingDict = true;
        var urlData = URI.TEMPLATES.DESCRIPTION_DICT;
        self[urlData.method](urlData, { url: { id: self.properties.id }, urltype: 'obj' }, { headers: { moduleId: self.moduleId } })
            .then(function (result) {
                self.descriptionOptions = result;
                p.resolve();
            })
            .catch(function (e) {
                p.reject();
                Message.dberror(e);
            })
            .finally(function () {
                self.loadingDict = false;
            })

        return p.promise;
    }

    TemplateMod.prototype.update = function () {
        var self = this;
        self.loadingDetails = true;

        var p = $q.defer();
        var urlData = URI.TEMPLATES.TEMPLATE;
        self.isBusy = true;
        self[urlData.method](urlData, { body: self.properties }, { headers: { moduleId: self.moduleId } })
            .then(function (result) {
                self.definitionForm.set_Data(self.properties);
                self.definitionForm.store_Data();
                Message.info('Template updated successfully');
                p.resolve();
            })
            .catch(function (e) {
                p.reject(e);
                Message.dberror(e);
            })
            .finally(function () {
                self.loadingDetails = false;
                self.isBusy = false;
            })

        return p.promise;
    }

    TemplateMod.prototype.saveGroup = function () {
        var self = this;
        var p = $q.defer();
        var urlData = self.properties.id ? URI.TEMPLATES.UPDATE_GROUP : URI.TEMPLATES.CREATE_GROUP;
        self.form.validate();
        self.form.loading = true;
        if (self.form.isValid) {
            self.isBusy = true;
            self[urlData.method](urlData, { body: self.properties }, { headers: { moduleId: self.moduleId } })
                .then(function (result) {
                    if (!self.properties.id)
                        self.properties.id = result;
                    Message.info('Template saved successfully');
                    p.resolve();
                })
                .catch(function (e) {
                    self.form.catch(e);
                    self.form.loading = false;
                    p.reject();
                })
                .finally(function () {
                    self.isBusy = false;
                })
        }
        else {
            self.form.loading = false;
            p.reject();
        }

        return p.promise;
    }

    TemplateMod.prototype.deleteGroup = function () {
        var self = this;
        var p = $q.defer();
        self.deleting = true;
        self.deletingMsg = "Deleting template...";
        var urlData = URI.TEMPLATES.DELETE_GROUP;
        self[urlData.method](urlData, { url: { id: self.properties.id }, urltype: 'obj' }, { headers: { moduleId: self.moduleId } })
            .then(function () {
                Message.info('Template deleted successfully');
                p.resolve();
            })
            .catch(function (e) {
                self.deleting = false;
                self.deletingMsg = "Failed to delete template. Try again?";
                p.reject();
                Message.dberror(e);
            })

        return p.promise;
    }

    TemplateMod.prototype.addCondition = function () {
        var self = this;
        var item = {
            id: null,
            groupId: self.properties.id,
            templateId: null,
            fieldId: null,
            operatorId: null,
            value: "",
            value2: "",
            position: null
        };
        self.isBusy = true;
        self.conditionsList.splice(0, 0, self.createConditionForm(item));
    }

    TemplateMod.prototype.editCondition = function (item) {
        this.isBusy = true;
        item.editMode = true;
    }

    TemplateMod.prototype.saveCondition = function (item) {
        var self = this;
        item.validate();

        // We need to skip when we create a new group, we save all at ones
        if (!self.properties.id && item.isValid) {
            this.isBusy = false;
            item.editMode = false;
            // Set this bit to not remove item on cancel
            item.newItemCreated = true;
            // Save conditional
            self.properties.conditionalsTemplate.push(item.data);
            // Return from function
            return;
        }
        // Save it when we already have a conditional template saved
        if (item.isValid) {
            item.loading = true;
            self.isBusy = true;
            var dataURL = item.data.id ? URI.TEMPLATES.UPDATE_CONDITION : URI.TEMPLATES.CREATE_CONDITION;
            self[dataURL.method](dataURL, { body: item.data }, { headers: { moduleId: self.moduleId } })
                .then(function (result) {
                    if (!item.data.id)
                        item.data.id = result;

                    self.isModified = true;
                    self.get_conditionals();
                    Message.info("Condition saved successfully");
                })
                .catch(function (e) {
                    item.loading = false;
                    item.catch(e);
                })
                .finally(function () {
                    item.loading = false;
                    item.editMode = false;
                    self.isBusy = false;
                })
        }
    }

    TemplateMod.prototype.cancelCondition = function (item) {
        var self = this;
        if (!item.data.id && !item.newItemCreated) {
            self.conditionsList.splice(0, 1);
        } else if (!item.newItemCreated) {
            item.restore_Data();
            item.editMode = false;
            self.setGrid(item, item.data.operatorId == self.betweenOperatorId);
        }
        else {
            item.editMode = false;
        }
        self.isBusy = false;
    }

    TemplateMod.prototype.deleteConditionDialog = function (item) {
        var self = this;
        // We need to skip when we create a new group, we save all at ones
        if (!self.properties.id) {
            self.conditionsList.splice(self.conditionsList.indexOf(item), 1);
            return;
        }
        item.loading = true;
        var dataURL = URI.TEMPLATES.DELETE_CONDITION;
        self[dataURL.method](dataURL, { url: { id: item.data.id }, urltype: 'obj' }, { headers: { moduleId: self.moduleId } })
            .then(function () {
                self.conditionsList.splice(self.conditionsList.indexOf(item), 1);
                self.properties.conditionalsTemplate.splice(self.properties.conditionalsTemplate.indexOf(item.data), 1);
                self.isModified = true;
                Message.info("Condition deleted successfully");
            })
            .catch(function (e) {
                Message.dberror(e);
                console.error(e);
            })
            .finally(function () {
                item.loading = false;
                self.isBusy = false;
            })
    }

    TemplateMod.prototype.sync = function () {
        var self = this;
        // We need to skip when we create a new group, we save all at ones
        if (!self.properties.id)
            return;
        var toSend = [], data = {};
        var dataURL = URI.TEMPLATES.SYNC_CONDITIONS;
        if (self.conditionsList.length) {
            for (var i = 0; i < self.conditionsList.length; i++) {
                data.key = self.conditionsList[i].data.id;
                data.order = i;
                toSend.push(angular.copy(data));
            }
        }
        self.isBusy = true;
        self[dataURL.method](dataURL, { url: { id: self.properties.id }, urltype: 'obj', body: toSend }, { headers: { moduleId: self.moduleId } })
            .then(function (r) {
                self.get_conditionals();
                Message.info("Order updated successfully");
            })
            .catch(function (e) {
                Message.dberror(e);
                console.error(e);
            })
            .finally(function () {
                self.isBusy = false;
            });
    }

    TemplateMod.prototype.changeOrder = function (item, isDown) {
        var self = this;
        var i = self.conditionsList.indexOf(item);
        self.conditionsList.splice(i, 1);
        if (!isDown) self.conditionsList.splice(i - 1, 0, item);
        else self.conditionsList.splice(i + 1, 0, item);
        self.sync();
    }

    return TemplateMod;
});
