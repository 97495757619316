import { Component, computed, inject, input, signal } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  DateTimeAdapter,
  OWL_DATE_TIME_FORMATS,
  OWL_DATE_TIME_LOCALE,
  OwlDateTimeModule,
} from '@danielmoncada/angular-datetime-picker';
import { MomentDateTimeAdapter, OwlMomentDateTimeModule } from '@danielmoncada/angular-datetime-picker-moment-adapter';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { TranslateModule } from '@ngx-translate/core';
import moment from 'moment';

import { UserSettings } from '../../../../models/core';
import { TenantStore, UserStore } from '../../../../signal-store';
import { CipoFormModule } from '../../../modules/forms.module';
import { CipoTextViewComponent } from '../cipo-text-view/cipo-text-view.component';
import { CipoDateTimeConfig, CipoDateTimeControl, CipoDateTimePickerType } from '../common';
import { DateTimeFormatService } from './date-time-format.service';

// this is used to get the
const DATE_TIME_TYPE_CONVERTER = {
  date: 'calendar',
  time: 'timer',
  dateTime: 'both',
} as const;

@Component({
  selector: 'cipo-date-time',
  standalone: true,
  imports: [
    OwlDateTimeModule,
    OwlMomentDateTimeModule,
    CipoFormModule,
    FlexLayoutModule,
    CipoTextViewComponent,
    TranslateModule,
  ],
  providers: [
    { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
    {
      provide: OWL_DATE_TIME_FORMATS,
      useFactory: (dateTimeFormatService: DateTimeFormatService) => dateTimeFormatService.getFormats(),
      deps: [DateTimeFormatService],
    },
    {
      provide: OWL_DATE_TIME_LOCALE,
      useFactory: (dateTimeFormatService: DateTimeFormatService) => dateTimeFormatService.getLocale(),
      deps: [DateTimeFormatService],
    },
  ],
  templateUrl: './cipo-date-time.component.html',
})
export class CipoDateTimeComponent {
  userStore = inject(UserStore);
  tenantStore = inject(TenantStore);
  dateTimeTypeConverter = DATE_TIME_TYPE_CONVERTER;
  fieldData = signal<CipoDateTimeConfig>({});
  maxDate = signal<string>(null);
  tenant12hourFormat = computed(() => this.userStore.calculatedUserSettings()?.timeFormat?.includes('A'));
  dateTimeControl = new FormControl<string | string[]>(null);
  userSettings: UserSettings;

  constructor() {
    this.userSettings = this.userStore.calculatedUserSettings();
  }

  formControl = input<CipoDateTimeControl, CipoDateTimeControl>(null, {
    transform: control => {
      this.onFieldDataChange(control);
      return control;
    },
    alias: 'control',
  });

  onFieldDataChange(control: CipoDateTimeControl) {
    const fieldData = control.fieldData ?? {};
    this.fieldData.set(fieldData);
    this.maxDate.set(fieldData.maxDate);
  }

  changed(date: Date) {
    if (
      this.fieldData().selectMode !== 'range' ||
      this.fieldData().pickerType === 'time' ||
      !this.fieldData().maxRange
    ) {
      return;
    }
    const rangeEndDate = moment(date).add(this.fieldData().maxRange, 'day');
    const tempMaxDate = moment.min([rangeEndDate, moment(this.fieldData().maxDate)]);
    this.maxDate.set((this.fieldData().maxDate ? tempMaxDate : rangeEndDate).toISOString());
  }

  resetMaxDate() {
    this.maxDate.set(this.fieldData().maxDate);
  }
}
