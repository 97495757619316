<div class="p-a-xl">
    <h2>Cipo DateTime fields</h2>

    <div fxLayout="row wrap" fxLayoutAlign="space-between">
        @for (item of form().controls | keyvalue; track $index) {
            <div fxFlex="32" class="m-b">
                <cipo-date-time [control]="item.value" />
            </div>
        }
    </div>
</div>
