import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { setUpLocationSync } from "@angular/router/upgrade";
import { AppModule } from "./src/app/app.module";
import { get } from "scriptjs";
import { environment } from "./src/environments/environment";

get("https://cdnjs.cloudflare.com/ajax/libs/d3/5.16.0/d3.js", () => {});
get(
	"https://s3.amazonaws.com/cdn.hellosign.com/public/js/hellosign-embedded.LATEST.min.js",
	() => {},
);
get(
	environment.production
		? "https://pspdfkit.cipo.cloud/pspdfkit.js"
		: "https://pspdfkit-dev.cipo.cloud/pspdfkit.js",
	() => {},
);

/*
get('./Scripts/leadtools/Leadtools.js', () => {
    get('./Scripts/leadtools/Leadtools.Controls.js', () => {
        get('./Scripts/leadtools/Leadtools.Annotations.Engine.js', () => {
            get('./Scripts/leadtools/Leadtools.Annotations.Designers.js', () => { });
            get('./Scripts/leadtools/Leadtools.Annotations.Rendering.JavaScript.js', () => { });
            get('./Scripts/leadtools/Leadtools.Annotations.Automation.js', () => { });
            get('./Scripts/leadtools/Leadtools.ImageProcessing.Main.js', () => { });
            get('./Scripts/leadtools/Leadtools.ImageProcessing.Color.js', () => { });
            get('./Scripts/leadtools/Leadtools.ImageProcessing.Core.js', () => { });
            get('./Scripts/leadtools/Leadtools.ImageProcessing.Effects.js', () => { });
            get('./Scripts/leadtools/Leadtools.Document.js', () => { });
            get('./Scripts/leadtools/Leadtools.Document.Viewer.js', () => { });
        });
    });
});
*/

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.then((ref) => {
		const upgrade = (<any>ref.instance).upgrade;
		// bootstrap angular1
		upgrade.bootstrap(document.body, ["cipo"]);
		setUpLocationSync(upgrade);
	});
