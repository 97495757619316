import { cipo } from 'cipo';

const MODULE_PROGRESS_PAYMENT = -3;
const MODULE_SOV = -4;

cipo.factory("DashboardModule", function (Model, DASHBOARD_STATUS_TYPES, URI, Manager, $mdDialog, userService) {
    var DashboardModule = Model.extend(function (obj, callbackStateGo) {
        var self = this;
        self.properties = {};
        if (obj) {
            for (var key in obj) {
                if (obj.hasOwnProperty(key)) {
                    self.properties[key] = obj[key];

                }
                self.properties.turnaroundDays = Math.ceil(parseInt(self.properties.turnaroundDays || 0));
            }
            
        }
        self.items = [];
        self.moduleCode = userService.getModuleIdentifierById(self.properties.moduleId);
        self.programName = userService.system.modules['PRG']?.name || 'Program';
        self.projectName = userService.system.modules['P']?.name || 'Project';
        self.contractName = userService.system.modules['CT']?.name || 'Contract';
        self.callbackStateGo = callbackStateGo || function() {};
        self.manager = null;
        self.set_Filters();
        
    });

    DashboardModule.prototype.set_Filters = function () {
        var self = this;
        self.pieData = [];
        var pieData = [];
        self.filters = [
            {
                name: 'Overdue',
                counts: self.properties.countOverdue || 0,
                filter: DASHBOARD_STATUS_TYPES[1],
                css: "dashboardOverdueText",
                color: '#B05470',
                active: false
            },
            {
                name: 'Open',
                counts: self.properties.countOpen || 0,
                filter: DASHBOARD_STATUS_TYPES[2],
                css: "successText",
                color: '#54b095',
                active: false
            },
            {
                name: 'In my court',
                counts: self.properties.countInMyCourt || 0,
                filter: DASHBOARD_STATUS_TYPES[4],
                css: "warningText",
                color: '#f7ad59',
                active: false
            },
            {
                name: 'Closed',
                counts: self.properties.countClosed || 0,
                filter: DASHBOARD_STATUS_TYPES[5],
                css: "neutralText",
                color: '#898989',
                active: false
            }
        ]

        for (var i = 0; i < self.filters.length; i++) {
            var slice = {};
            if (self.filters[i].name != "In my court") {
                slice.name = self.filters[i].name;
                slice.y = Math.round(parseInt(self.filters[i].counts) * 100 / parseInt(self.properties.countTotal || 1));
                if (self.filters[i].color) slice.color = self.filters[i].color;
                pieData.push(slice);
                // console.error('slice', slice);
            }
            
        }
        self.pieData = pieData;
    }

    DashboardModule.prototype.reloadCounts = function () {
        var self = this;
        var dataURL = URI.DASHBOARD.MODULE_COUNTS;
        self[dataURL.method](dataURL, { url: { contractId: userService.system.userdata.contractId, moduleId: self.properties.moduleId }, urltype: 'obj' })
            .then(function (r) {

                if (r && r.length) {
                    for (var key in r[0]) {
                        if (r[0].hasOwnProperty(key)) {
                            self.properties[key] = r[0][key];

                        }
                    }
                    self.set_Filters();
                }
            })
            .catch(function (e) {
                console.error(e);
            })
            .finally(function () {
                
            })
    }

    DashboardModule.prototype.togleItems = function (rollupInfo, f) {
        var self = this;
        // if(f) f.active = !f.active;
        if (rollupInfo) {
            //
            rollupInfo = {
                contractId: rollupInfo.contractId || 0,
                projectId: rollupInfo.projectId || 0,
                programId: rollupInfo.programId || 0
            }
        }
        if ((self.manager && !f) || (self.manager && f && f.filter == self.manager.urlParams.statusType)) {
            self.manager = null;
        } else {
            self.loading = true;
            var params = angular.copy(rollupInfo);
            params.moduleId = self.properties.moduleId;
            if (f) params.statusType = f.filter;
            // params.contractId = userService.system.userdata.contractId;
            // if (projectId) params.projectId = projectId;

            self.loadManagerData(params);
        }
    }

    DashboardModule.prototype.loadManagerData = function (params) {
        var self = this;
        var dataURL = URI.DASHBOARD.SEARCH;
        self.manager = new Manager({
            objectsPerPage: 10,
            dataURL: dataURL,
            dataWrapper: "data",
            hasActionBar: false,
            options: {
                multiselect: false
            },
            urlParams: params,
            rowOnClick: function (row) {
                self.saveItem(row);
            },
            parseData: function (data) {
                var data = data || [];

                for (var i = 0; i < data.length; i++) {

                    //  if (data[i].hasApproval) data[i].state_name = 'In Approval';
                    if (data[i].needs_approval) data[i].state_name = 'In Approval';

                    //data[i].statusName = "<span style='display: block; text-align: center; text-transform: uppercase; color: #fff; background-color:"
                    //    + data[i]["statusColor"] + ";'>" + data[i].statusName + "</span>";
                    // data[i].state_color = data[i].statusColor || "#ccc";
                    //  data[i].state_text_color = data[i].statusColor || "#ccc";

                }

                return data;
            }

        });

        self.manager.set_Columns([
            { name: 'contractNumber', label: self.contractName, type: 'text', onPhone: true, onTablet: true },
            { name: 'documentNumber', label: 'Document', type: 'text', onPhone: true, onTablet: true },
            { name: 'state_name', label: 'Status', type: 'state', onPhone: true, onTablet: true }
        ]);

        self.manager.loadPage();
    }

    DashboardModule.prototype.saveItem = function (item) {
        var self = this;
        var contractId = (typeof item != 'undefined') ? parseInt(item.contractId) : 0;

        if (item.moduleId == MODULE_PROGRESS_PAYMENT || item.moduleId == MODULE_SOV) {
            self.callbackStateGo(item.moduleId, item.contractId, item.entityInstanceId);
            return;
        }

        var item = {
            itemId: (typeof item != 'undefined') ? parseInt(item.entityInstanceId) : 0,
            fromItem: 0
        }

        // set user selected contract when is different than the user contract id
        if (contractId && userService.system.userdata.contractId != contractId) {
            userService.setContract(contractId);
            userService.triggerMenuRefresh();
        }
        
        $mdDialog.show({
            locals: {
                item: item,
                module: { moduleId: self.properties.moduleId, code: self.moduleCode, name: self.properties.moduleName },
                contractId: contractId,
                smallerFullscreen: false,
                screenId: null
            },
            controller: 'SaveWorkflowEntityInstanceController',
            templateUrl: '/ng/views/dynamics/modals/saveWorkflowEntityInstance.html',
            parent: angular.element(document.body),
            //targetEvent: ev,
            fullscreen: true,
            escapeToClose: false,
            clickOutsideToClose: false
        })
            .then(function (result) {
                self.reloadCounts();
                //self.manager.page = 1;
                self.manager.loadPage();
            }, function () {
                //self.manager.loadPage();
                //self.reloadCounts();
            });
    };

    return DashboardModule;
});
