import { FilterModel } from './filterModel';
import { SortModel } from './sortModel';

export interface SearchPagedList {
  page?: number;
  pagesize?: number;
}

export interface SearchViewModel extends SearchPagedList {
  id?: number;
  criteria?: string;
  filter?: FilterModel;
  sort?: SortModel[];
  isCurrent?: boolean;
  showDisabled?: boolean;
}
