import { AfterViewInit, Component, ElementRef, inject, input, signal, viewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { TranslateModule } from '@ngx-translate/core';

import { UserSettings } from '../../../../models/core';
import { CipoFormModule } from '../../../modules/forms.module';
import { UtilsService } from '../../../services';
import { CipoTextViewComponent } from '../cipo-text-view/cipo-text-view.component';
import { CipoNumberConfig, CipoNumberControl } from '../common';
import { ThousandSeparatorDirective } from './thousand-separator.directive';
import { UserStore } from '../../../../signal-store';

@Component({
  selector: 'cipo-number',
  standalone: true,
  imports: [CipoFormModule, TranslateModule, ThousandSeparatorDirective, CipoTextViewComponent],
  templateUrl: './cipo-number.component.html',
})
export class CipoNumberComponent implements AfterViewInit {
  utilsService = inject(UtilsService);
  userStore = inject(UserStore);
  fieldData = signal<CipoNumberConfig>({});
  canClearInput = signal(true);
  chipInput = viewChild<ElementRef<HTMLInputElement>>('chipInput');
  numberInput = viewChild<ElementRef<HTMLInputElement>>('numberInput');
  formatting = signal('1.0-0');

  userSettings: UserSettings;
  selectedChipIndex: number;
  chipsField = new FormControl<string[]>([]);

  formControl = input<CipoNumberControl, CipoNumberControl>(null, {
    transform: control => {
      this.onFieldDataChange(control);
      return control;
    },
    alias: 'control',
  });

  /* this input is used to update required state of the chips field.
  May be also used to update single input required state (if needed) */
  required = input<boolean, boolean>(false, {
    transform: val => {
      if (this.fieldData()?.multiple) {
        this.chipsField.setValidators(this.formControl().validator);
        this.chipsField.updateValueAndValidity();
      }
      return val;
    },
  });

  readonly = input<boolean, boolean>(false, {
    transform: val => {
      this.fieldData.update(fieldData => ({ ...fieldData, readonly: val }));
      return val;
    },
  });

  constructor() {
    this.userSettings = this.userStore.calculatedUserSettings();
  }

  ngAfterViewInit(): void {
    if (this.formControl()?.fieldData?.focused && this.numberInput()) {
      this.numberInput().nativeElement.focus();
    }
  }

  onFieldDataChange(control: CipoNumberControl) {
    const fieldData = control.fieldData ?? {};

    this.fieldData.set(this.utilsService.updateCipoFieldConfigIfCurrencyIsSet(fieldData));
    const { readonly, suffixIcon, preventClearInput, multiple } = fieldData;
    multiple && this.chipsField.setValidators(control.validator);
    this.canClearInput.set(!readonly && !preventClearInput && !suffixIcon);
  }

  add(event: MatChipInputEvent) {
    const { chipInput, value } = event;
    if (!value) {
      return;
    }
    const formattedVal = this.utilsService.revertLocaleNumber(
      value,
      this.userSettings?.decimalSeparator,
      this.userSettings?.thousandSeparator,
    );
    if (formattedVal === null || isNaN(formattedVal)) {
      return;
    }
    const val = (this.formControl().value || []) as number[];
    if (this.selectedChipIndex !== undefined) {
      val[this.selectedChipIndex] = formattedVal;
      this.selectedChipIndex = undefined;
    } else {
      val.push(formattedVal);
    }
    this.formControl().setValue(val);
    chipInput.clear();
  }

  remove(index: number) {
    const val = this.formControl().value as number[];
    val.splice(index, 1);
    this.formControl().setValue(val);
  }

  edit(index: number) {
    this.selectedChipIndex = index;
    this.chipInput().nativeElement.value = this.utilsService.formatLocaleNumber(
      this.formControl().value[index],
      this.fieldData().decimals,
      this.userSettings?.decimalSeparator,
      this.userSettings?.thousandSeparator,
      this.fieldData().showThousandSeparator,
    );
    const values = this.formControl().value as number[];
    this.formControl().setValue(values);
    this.chipInput().nativeElement.focus();
  }

  formatNumber(value: number): string {
    return this.utilsService.formatLocaleNumber(
      value,
      this.fieldData().decimals,
      this.userSettings?.decimalSeparator,
      this.userSettings?.thousandSeparator,
      this.fieldData().showThousandSeparator,
      true,
    );
  }
}
