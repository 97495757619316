<div mat-dialog-title fxLayout fxLayoutAlign="space-between center">
    <h5>{{ "fileFolderPermissions.selectPeople" | translate }}</h5>
    <button mat-icon-button (click)="closeDialog()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <div class="p-t-md full-height">
        <cipo-searchable-listbox [(values)]="listBoxValues" [config]="listBoxConfig()" [options]="listBoxOptions()">
            <mat-button-toggle-group
                [(value)]="allow"
                (change)="loadAvailablePeople($event.value)"
                aria-label="Permissions">
                <mat-button-toggle [value]="true">{{ "fileFolderPermissions.allow" | translate }}</mat-button-toggle>
                <mat-button-toggle [value]="false">{{ "fileFolderPermissions.deny" | translate }}</mat-button-toggle>
            </mat-button-toggle-group>
        </cipo-searchable-listbox>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="closeDialog()">{{ "general.close" | translate }}</button>
    <button mat-raised-button color="primary" (click)="returnPeople()">{{ "general.save" | translate }}</button>
</mat-dialog-actions>
