import { Component } from '@angular/core';
import { MaterialModule } from '../../../shared/modules';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';

@Component({
  selector: 'app-demo-angular-button',
  templateUrl: './demo-angular-button.component.html',
  standalone: true,
  imports: [FlexLayoutModule, MaterialModule],
})
export class DemoAngularButtonComponent {}
