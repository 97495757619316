import { Injectable, inject } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, MaybeAsync, Router } from '@angular/router';
import { map, take } from 'rxjs/operators';

import { TenantStore } from '../../signal-store';
import { Tenant } from '../../models/module/tenant';

@Injectable({
  providedIn: 'root',
})
export class TenantGuard implements CanActivate {
  tenantStore = inject(TenantStore);
  router = inject(Router);

  canActivate(next: ActivatedRouteSnapshot): MaybeAsync<boolean> {
    const potentialTenant = next.params['tenantId'].toLowerCase();

    const checkTenant = (tenant: Tenant) => {
      return tenant.name.toLowerCase() === potentialTenant;
    };

    // if there is a selectedTenant:
    const currentTenant = this.tenantStore.selectedTenant();
    if (currentTenant && checkTenant(currentTenant)) {
      return true;
    }

    // if the availableTenants are loaded
    const selectCurrentTenant = (availableTenants: Tenant[]) => {
      const selectedTenant = availableTenants.find(checkTenant);
      if (selectedTenant) {
        this.tenantStore.selectTenant(selectedTenant);
        return true;
      }

      this.router.navigate(['/']);
      return false;
    };

    const availableTenants = this.tenantStore.availableTenants();
    if (availableTenants?.length) {
      return selectCurrentTenant(availableTenants);
    } else {
      return this.tenantStore.loadTenants().pipe(
        map(availableTenants => selectCurrentTenant(availableTenants)),
        take(1),
      );
    }
  }
}
