import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DevGuard, TenantGuard } from './shared/guards';
import { MsalGuard } from '@azure/msal-angular';

const routes: Routes = [
  //   Routes outside tenant context
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'demo',
    canActivate: [DevGuard, MsalGuard],
    loadChildren: () => import('./demo/demo-routing.module').then(m => m.DemoRoutingModule),
  },
  {
    path: 'tenant-list',
    canActivate: [MsalGuard],
    loadComponent: () => import('./system/tenant-list/tenant-list.component').then(c => c.TenantListComponent),
  },
  {
    path: 'login',
    loadComponent: () => import('./system/login/login.component').then(c => c.LoginComponent),
  },
  {
    path: 'postLogin',
    loadComponent: () => import('./system/post-login/post-login.component').then(c => c.PostLoginComponent),
  },
  {
    path: 'register',
    loadComponent: () => import('./system/register/register.component').then(c => c.RegisterComponent),
  },
  {
    path: '404',
    loadComponent: () => import('./system/not-found/not-found.component').then(c => c.NotFoundComponent),
  },

  {
    path: ':tenantId',
    canActivate: [TenantGuard],
    children: [
      { path: '', redirectTo: 'personal-dashboard', pathMatch: 'full' },
      //   {
      //     path: 'user-profile',
      //     loadComponent: () =>
      //       import('./module/instance/user-profile/user-profile.component').then(c => c.UserProfileComponent),
      //   },
      {
        path: 'personal-dashboard',
        loadComponent: () => import('./module/instance/dashboard/dashboard.component').then(c => c.DashboardComponent),
      },
      {
        path: 'holidays',
        loadComponent: () => import('./module/instance/holidays/holidays.component').then(c => c.HolidaysComponent),
      },
      {
        path: 'url/:id',
        loadComponent: () =>
          import('./module/instance/iframe-url/iframe-url.component').then(c => c.IframeUrlComponent),
      },
      {
        path: 'office',
        loadComponent: () => import('./module/instance/office/office.component').then(c => c.OfficeComponent),
      },
      {
        path: '404',
        loadComponent: () => import('./system/not-found/not-found.component').then(c => c.NotFoundComponent),
      },
      {
        path: '**',
        loadComponent: () => import('./ng2/angular-js/angular-js.component').then(c => c.AngularJsComponent),
      },
      //   Will implement this after having all routes in Angular
      //   {
      //     path: '**',
      //     loadComponent: () => import('./system/not-found/not-found.component').then(c => c.NotFoundComponent),
      //   },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
