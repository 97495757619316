import { Injectable, inject } from '@angular/core';
import { HttpService } from './http.service';
import { Tenant } from '../../models/module/tenant';

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  http = inject(HttpService);

  getTenants() {
    return this.http.get<Tenant[]>('_api/Main/Tenants', { useLoader: false });
  }
}
