<div mat-dialog-title fxLayout fxLayoutAlign="space-between center">
    <h5>
        @switch (dialogData.modalType) {
            @case ("rename") {
                {{ "fileExplorer.rename" | translate }}
            }
            @case ("newFolder") {
                {{ "fileExplorer.addNewFolder" | translate }}
            }
            @case ("newFile") {
                {{ "fileExplorer.upload" | translate }}
            }
        }
    </h5>
    <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <div class="p-t">
        @switch (dialogData.modalType) {
            @case ("newFile") {
                @if (!multiple() && uploader.queue.length > 0) {
                    <div fxLayoutAlign="center center" class="cipo-bg--primary-50 my-drop-zone text--center p-a-xl">
                        {{ "fileExplorer.dropZoneMessage" | translate }}
                    </div>
                } @else {
                    <div
                        fxLayoutAlign="center center"
                        ng2FileDrop
                        [ngClass]="{ 'nv-file-over': hasBaseDropZoneOver }"
                        (fileOver)="fileOver($event)"
                        [uploader]="uploader"
                        (click)="fileUploader.click()"
                        class="cipo-bg--primary-100 my-drop-zone text--center p-a-xl">
                        {{ "fileExplorer.dropZoneMessage" | translate }}
                    </div>
                }
                <input
                    [disabled]="!multiple() && uploader.queue.length > 0"
                    type="file"
                    #fileUploader
                    ng2FileSelect
                    [uploader]="uploader"
                    [multiple]="multiple()"
                    class="display--none" />
                @for (item of uploader.queue; track $index) {
                    <div fxLayout fxLayoutAlign="space-between center" class="m-t">
                        <div fxLayout fxLayoutGap="16px" fxLayoutAlign="start center">
                            @if (item.isError) {
                                <mat-icon class="material-icons-outline" class="cipo-text--warn-500">error</mat-icon>
                            } @else {
                                <img [src]="item.file['preview']" class="file-image" />
                            }
                            <label>{{ item.file.name }}</label>
                        </div>
                        <button mat-icon-button (click)="item.remove()" color="warn">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                    @if (item.isError) {
                        <p class="cipo-text--warn-500 m-a-0">{{ "fileExplorer.uploadError" | translate }}</p>
                    } @else {
                        <mat-progress-bar [value]="item.progress" />
                    }
                }
            }
            @default {
                <div class="p-t-lg">
                    <cipo-text [control]="name" />
                </div>
            }
        }
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close>{{ "general.cancel" | translate }}</button>
    <button mat-raised-button color="primary" [disabled]="!valid()" (click)="save()">
        {{ (dialogData.modalType === "newFile" ? "fileExplorer.upload" : "general.save") | translate }}
    </button>
</mat-dialog-actions>
