import { Component } from '@angular/core';

import { ScheduledEmailService } from '../../../module/instance/scheduled-emails/scheduled-emails.service';
import { DemoScheduledEmailsService } from './demo-scheduled-emails.service';

@Component({
  selector: 'app-demo-scheduled-emails',
  template: '<div class="p-a-xl"><app-scheduled-emails/></div>',
  providers: [{ provide: ScheduledEmailService, useClass: DemoScheduledEmailsService }],
})
export class DemoScheduledEmailsComponent {}
