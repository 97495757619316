import { cipo } from 'cipo';

cipo.controller('pdfMarkupAddController',
    function ($scope, $state, APP_CONTENT, FileManagerConfig, Explorer1, userService, $mdDialog) {
        // VARIABLES
        $scope.content = APP_CONTENT;
        $scope.moduleName = userService.system.mNames[$state.current.code];
        $scope.tempOps = { Read: true, Create: true, Delete: true, Update: true };
        $scope.title = 'Select Project File(s)';
        $scope.explorerData = FileManagerConfig.projectFiles;

        // FUNCTIONS
        $scope.close = _close;
        $scope.navigateTo = _navigateTo;
        $scope.getLiveOperations = _getLiveOperations;
        $scope.loadExplorer = _loadExplorer;

        // INIT
        $scope.getLiveOperations();

        // WATCHERS
        $scope.$watch(function () {
            return userService.isLoadingMenu;
        },
            function (n, o) {
                if (!n) {
                    if (!$scope.explorer) $scope.loadExplorer($scope.explorerData);
                    else if (!userService.isLoadingMenu && $scope.module) {
                        $scope.explorer.urlsData.tree.urlParams.contractId = userService.system.userdata.contractId;
                        $scope.explorer.manager.urlParams = $scope.explorer.setParams();
                        $scope.explorer.getFolderContent();
                    }
                }
            });

        Object.defineProperty($scope.explorerData.tree.urlParams, 'contractId', {
            get: function () {
                return userService.system.userdata.contractId;
            },
            set: function() {}
        });

            function _loadExplorer(storageType) {
                // storageType = $scope.userStorage;
                $scope.explorer = new Explorer1({
                    // operations: $scope.tempOps,
                    navigateTo: function (route, parameters) {
                        $scope.navigateTo(route, parameters);
                    },
                    closeDialog: function(documentId, fileId, fileName) {
                        $scope.close(documentId, fileId, fileName);
                    },
                    isPdfMarkup: true,
                    accept: 'application/pdf,image/png,image/jpeg',
                    abbreviation: 'FCM',
                    layout: 1,
                    hasGlobal: true,
                    rootName: "...",
                    treeSetup: {
                        treeOptions: {
                            nodeChildren: "folders",
                            dirSelectable: true,
                            isLeaf: function (node) { return false; },
                            multiSelection: false,
                            injectClasses: {
                                ul: "a1",
                                li: "a2",
                                liSelected: "a7",
                                iExpanded: "a3",
                                iCollapsed: "a4",
                                iLeaf: "a5",
                                label: "a6",
                                labelSelected: "a8"

                        }
                    },
                    orderby: ""
                },
                urlsData: storageType,
                managerSetup: {
                    search: false,

                    columns: {
                        1: [
                            { name: "_name", label: 'Name', type: 'checkbox', replacementFor: "name", onTablet: true, onPhone: true, width: 30, fieldTypeId: 1 },
                            { name: "createdBy", label: 'Owner', type: 'text', onTablet: true, onPhone: true, width: 25, fieldTypeId: 1 },
                            { name: "createdOn", label: 'Created On', type: 'date', onTablet: true, onPhone: false, width: 15 },
                            { name: "updatedOn", label: 'Last Modified', type: 'date', onTablet: true, onPhone: false, width: 15 },
                            { name: "length", label: 'File Size', type: 'checkbox', onTablet: true, onPhone: true, width: 15, fieldTypeId: 16 }
                        ],
                        2: [
                            { name: "_big_name", label: 'Name', type: 'checkbox' }
                        ]
                    }
                }
            });
        };

        $scope.$watch(function () {
            return userService.isReloadGrid;
        },
            function (n, o) {
                if (n) {
                    $scope.explorer.manager.loading = true;
                    $scope.explorer.manager.loadPage();
                    userService.isReloadGrid = false;

                }
            });

        // FUNCTION DECLARATIONS
        function _close(documentId = null, fileId = null, fileName = null) {
            documentId
                ? $mdDialog.hide({ documentId: documentId, fileId: fileId, fileName: fileName })
                : $mdDialog.cancel();
        }

        function _navigateTo(route, parameters) {
            $state.go(route, parameters);
        }

        function _getLiveOperations() {
            Object.defineProperty($scope, 'module', {
                get: function () { return userService.system.modules[$state.current.code]; }
            });
            Object.defineProperty($scope, 'operations', {
                get: function () { return userService.getOperationsFor($state.current.code); }
            });
            Object.defineProperty($scope, 'userService', {
                get: function () { return userService; }
            });
        }
    });
