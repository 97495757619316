import { inject, Pipe, PipeTransform } from '@angular/core';
import { Currency } from '../../models/system/currency';
import { UtilsService } from '../services';

@Pipe({ name: 'itvCurrency' })
export class ItvCurrencyPipe implements PipeTransform {
  utilsService = inject(UtilsService);

  transform(
    value: number | string,
    currency: Currency | null | undefined = undefined,
    decimals: number = 0,
    showComma: boolean = true,
  ): string {
    const showCurrency = currency !== null && currency !== undefined;
    return this.utilsService.transformToNumberOrCurrencyString(
      value,
      decimals,
      showComma,
      showCurrency,
      false,
      currency,
    );
  }
}
