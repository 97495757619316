import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable } from 'rxjs';

import { ListResponse } from '../../../../../models/helpers';
import { SearchFileModel, FileItemModel, FileChunkParams } from '../../../../../models/module/fields/main';
import { HttpService } from '../../../../../shared/services';
import { IFileService } from './file-factory.service';
import {
  AddChunkedVersionParams,
  CreateFolderParams,
  DuplicateVersionBody,
  DuplicateVersionParams,
  EditFolderParams,
  GetUploadUrlParams,
  GetVersionUrlParams,
  RenameFileParams,
  SaveFileInfoBody,
  SaveFileInfoParams,
} from '..';
import { FILE_SIZES } from 'src/app/shared/consts';

@Injectable({ providedIn: 'root' })
export class ProjectFileService implements IFileService {
  httpService = inject(HttpService);
  translate = inject(TranslateService);

  getFileUrl(fileId: number, versionId: number, isPreview?: boolean) {
    const params = this.httpService.buildHttpParams({ fileId, versionId, ...(isPreview ? { isPreview } : {}) });
    return this.httpService.getText('_api/ProjectFolderFile/GetFileURL', { httpOptions: { params } });
  }

  getFilesUrl(versionIds: number[]) {
    return this.httpService.put<{ versionId: number; url: string }[]>('_api/ProjectFolderFile/GetFilesURL', {
      files: versionIds.map(versionId => ({ versionId })),
    });
  }

  getFile<T>(fileId: number, entityInstanceId: number, versionId?: number): Observable<T> {
    const params = this.httpService.buildHttpParams({ fileId, entityInstanceId, versionId });
    return this.httpService.get<T>(`_api/ProjectFolderFile/GetFile`, {
      useLoader: false,
      httpOptions: { params },
    });
  }

  searchFiles(props: SearchFileModel) {
    const params = this.httpService.buildHttpParams({ entityInstanceId: props.entityInstanceId });
    return this.httpService
      .post<
        ListResponse<FileItemModel>
      >('_api/ProjectFolderFile/Search', props, { httpOptions: { params: params }, useLoader: false })
      .pipe(map(response => response.data));
  }

  getFileUploadUrl(body: GetUploadUrlParams) {
    const params = this.httpService.buildHttpParams({
      parentId: body.parentId,
      mimeType: body.mimeType,
      entityInstanceId: body.entityInstanceId,
      ...(body.isCreateVersion ? { isCreateVersion: body.isCreateVersion } : {}),
    });
    return '_api/ProjectFolderFile/UploadFile?' + params.toString();
  }

  getFileNewVersionUrl(body: GetVersionUrlParams) {
    const params = this.httpService.buildHttpParams({
      fileId: body.fileId,
      mimeType: body.mimeType,
      entityInstanceId: body.entityInstanceId,
    });
    return '_api/ProjectFolderFile/AddVersion?' + params.toString();
  }

  generateFileId() {
    return this.httpService
      .get<{ physicalFileId: string }>('_api/ProjectFolderFile/GenerateFileId', { useLoader: false })
      .pipe(map(response => response.physicalFileId));
  }

  uploadFileInChunks(fileName: string): Observable<void> {
    return this.httpService.get(`_api/ProjectFolderFile/UploadFileInChunks?fileName=${fileName}`, { useLoader: false });
  }

  uploadChunk(fileId: string, formData: FormData): Observable<void> {
    return this.httpService.post(`_api/ProjectFolderFile/UploadChunk?fileId=${fileId}`, formData, { useLoader: false });
  }

  saveFileInfo(params: SaveFileInfoParams, body: SaveFileInfoBody) {
    const httpParams = this.httpService.buildHttpParams(params);
    return this.httpService.post<FileItemModel>('_api/ProjectFolderFile/SaveFileInfo', body, {
      useLoader: false,
      httpOptions: { params: httpParams },
    });
  }

  addChunkedVersion(params: AddChunkedVersionParams, body: SaveFileInfoBody) {
    const httpParams = this.httpService.buildHttpParams(params);
    return this.httpService.post<FileItemModel>('_api/ProjectFolderFile/AddChunkedVersion', body, {
      useLoader: false,
      httpOptions: { params: httpParams },
    });
  }

  renameFile(body: RenameFileParams): Observable<void> {
    const params = this.httpService.buildHttpParams({ entityInstanceId: body.entityInstanceId });
    return this.httpService.put(`_api/ProjectFolderFile/RenameFile`, body, {
      httpOptions: { params: params },
      useLoader: false,
    });
  }

  createFolder(body: CreateFolderParams): Observable<void> {
    const params = this.httpService.buildHttpParams({ entityInstanceId: body.entityInstanceId });
    return this.httpService.post(`_api/ProjectFolderFile/CreateFolder`, body, {
      httpOptions: { params: params },
      useLoader: false,
    });
  }

  editFolder(body: EditFolderParams): Observable<void> {
    const params = this.httpService.buildHttpParams({ entityInstanceId: body.entityInstanceId });
    return this.httpService.put(`_api/ProjectFolderFile/EditFolder`, body, {
      httpOptions: { params: params },
      useLoader: false,
    });
  }

  downloadFileFromUrl(url: string) {
    try {
      const a = document.createElement('a');
      a.href = url;
      a.download = 'file';
      a.click();
      window.URL.revokeObjectURL(url);
    }
    catch (e) {
      console.error('Error downloading file from url', url, e);
    }
  }

  getChunkData({ file, start, end, chunks }: FileChunkParams) {
    const chunk = file.slice(start, end);
    const formData = new FormData();
    formData.append('file', chunk, file.name);
    formData.append('chunk', Math.ceil(start / FILE_SIZES.chunkSize).toString());
    formData.append('chunks', chunks.toString());
    formData.append('fileName', file.name);
    return formData;
  }

  duplicateVersion(params: DuplicateVersionParams, body: DuplicateVersionBody) {
    const httpParams = this.httpService.buildHttpParams(params);
    return this.httpService.post<number>('_api/ProjectFolderFile/DuplicateVersion', body, {
      useLoader: false,
      httpOptions: { params: httpParams },
    });
  }
}
