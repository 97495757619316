import { cipo } from 'cipo';
import moment from 'moment';
import { LegacyIcons } from 'src/app/legacy/constants';
import { FormattingType, FormattingValueType, RestrictionType } from 'src/app/models/module/fields/enums';
import { DATE_TIME_FORMATS } from 'src/app/shared/consts';
import { environment } from 'src/environments/environment';

const EMPTY = '-';

cipo.factory('userService', ['$http', '$q', 'localStorageService', 'URI', 'authService', 'Dictionaries', 'jsToNg', 'userServiceNg1',
    function ($http, $q, localStorageService, URI, authService, Dictionaries, jsToNg, userServiceNg1)
    {
        var userService = {};
        userService.redirectPage = null;

        userService.init = function ()
        {
            userService.tenantId = null;
            userService.hasTenantsLoaded = false;
            userService.isCurrentContractPeriodsChanged = false;
            userService.hasModulesLoaded = false;
            userService.isUserLoaded = false;
            userService.isAppBooted = false;
            userService.isReloadGrid = false;
            userService.isReloadModuleInformation = false;
            userService.targetState = null;
            userService.firstPage = 'dashboard';

            userService.system = {
                userdata: {
                    contractId: 0,
                    // True if user has any roles for correspondence with read permission
                    hasCorrespondenceAccess: false
                },
                context: {
                    program: undefined,
                    project: undefined,
                    contract: undefined,
                },
                contextTree: {
                    currencies: [],
                    modules: [],
                    programs: [],
                    loaded: false,
                },
                contextUseOnlyContract: true,
                userRoleNames: [],
                userRoleAbbrevs: [],
                impList: [],
                tenants: [],
                tenantsLookup: {},
                contracts: [],
                menu: [],
                modules: {},
                modulesList: [],
                permissions: {},
                mNames: {},
                emailCounts: 0,
                icons: [],
                mimeTypes: {dict: {}, list: []},
                selectedModuleId: null,
                workflowId: null,
                oldWorkflowId: null
            };

        };

        userService.init();

        // clear
        userService.clearData = function ()
        {
            userService.init();
        };

        userService.get_EmailCounts = function () {
            var p = $q.defer();
            $http[URI.EMAIL.UNREAD_THREAD_COUNT.method](`${URI.EMAIL.UNREAD_THREAD_COUNT}?contractId=${userService.system.userdata.contractId}`.toString())
                .then(function (r) {
                    userService.system.emailCounts = r.data;
                    p.resolve(r);
                })
                .catch(function (e) { console.error(e); p.reject(); });

            return p.promise;
        }

        userService.Icons = function ()
        {
            var p = $q.defer();
            var icons = new LegacyIcons();

            $http[URI.ICONS.DICT.method](URI.ICONS.DICT.toString())
                .then(function (r)
                {
                    if (r && r.data.length)
                    {
                        for (var i = 0; i < r.data.length; i++)
                        {
                            icons.byKey.get(r.data[i].key).isFavorite = r.data[i].isFavorite;
                            icons.byKey.get(r.data[i].key).value = r.data[i].value;
                        }
                    }
                    userService.system.icons = icons;
                    p.resolve(icons);
                })
                .catch(function (e)
                {
                    p.reject(e);
                });

            return p.promise;
        };

        userService.mimeTypes = function ()
        {
            var p = $q.defer();
            var mimetypes = { dict: {}, list: [] };
            $http[URI.FIELD_DEFINITION.MIME_TYPES_DICT.method](URI.FIELD_DEFINITION.MIME_TYPES_DICT.toString())
                .then(function (r)
                {
                    if (r && r.data.length)
                    {
                        for (var i = 0; i < r.data.length; i++)
                        {
                            mimetypes.list.push(r.data[i]);
                            mimetypes.dict[r.data[i].key] = r.data[i];
                        }
                    }
                    userService.system.mimeTypes = mimetypes;
                    p.resolve();
                })
                .catch(function (e)
                {
                    console.error(e);
                    p.reject(e);
                    // Message.dberror(e);
                });

            return p.promise;
        };

        userService.getRolesForContract = function (contractId)
        {
            var p = $q.defer();

            userService.system.userRoleNames = [];
            userService.system.userRoleAbbrevs = [];

            if (contractId)
                $http.get(environment.baseApiUrl + '_api/Main/UserRolesForContract?contractId=' + contractId)
                    .then(function (r)
                    {
                        if (r && r.data && r.data.length)
                        {
                            var userRoleNames = [], userRoleAbbrevs = [];

                            for (var i = 0; i < r.data.length; i++)
                            {
                                userRoleNames.push(r.data[i].roleName);
                                userRoleAbbrevs.push(r.data[i].abbr);
                            }

                            userService.system.userRoleNames = userRoleNames;
                            userService.system.userRoleAbbrevs = userRoleAbbrevs;
                        }

                        p.resolve(r);
                    })
                    .catch(function (e) { console.error(e); p.reject(); });

            return p.promise;
        };

        userService.User = function ()
        {
            var p = $q.defer();

            $q.all([
                $http.get(environment.baseApiUrl + '_api/Main/UserData'), 
                Dictionaries.TenantSettings()
            ]).then(function (r) {
                if (window.StonlyWidget) {
                    window.StonlyWidget('identify', r[0].data.id.toString());
                }

                // To be removed after adding to store
                localStorage.setItem('INFINITE_SCROLL', r[0].data.loadMore ? '1' : '0');

                userService.system.userdata.signature = null;

                for (var key in r[0].data) {
                    if (key !== 'context') {
                        userService.system.userdata[key] = r[0].data[key];
                    }
                }

                userService.system.isMenuFolderAutoCollapse = (r[1] || []).some(s => s.name == 'menu_folder_auto_collapse' && s.value == '1');
                
                userService.isUserLoaded = true;
                userService.Icons();

                p.resolve(r[0]);
            })
            .catch(function (e)
            {
                console.error(e); 
                p.reject();
            });

            return p.promise;
        };
        
        userService.Tenants = function (tenantIdentifier)
        {
            var p = $q.defer();

            if (userService.hasTenantsLoaded) {
                p.resolve();
                return p.promise;
            }

            tenantIdentifier = tenantIdentifier || null;

            $http.get(environment.baseApiUrl + '_api/Main/Tenants')
                .then(function (r)
                {
                    userService.hasTenantsLoaded = true;
                    userService.system.tenants = r.data;
                    userService.setTenant(tenantIdentifier);

                    if (r.data && r.data.length)
                    {
                        for (var i = 0; i < r.data.length; i++)
                        {
                            userService.system.tenantsLookup[r.data[i].id] = r.data[i];
                        }
                    }

                    p.resolve(r);
                })
                .catch(function (e)
                {
                    if (e.status === 401)
                    {
                        authService.logout();
                    }

                    p.reject(e);
                });

            return p.promise;
        };

        userService.Reload_Tenant_Logos = function ()
        {
            var p = $q.defer();

            $http.get(environment.baseApiUrl + '_api/Main/Tenants')
                .then(function (r)
                {
                    if (r.data && r.data.length)
                    {
                        for (var i = 0; i < r.data.length; i++)
                        {
                            userService.system.tenantsLookup[r.data[i].id].logo = r.data[i].logo;
                            userService.system.tenantsLookup[r.data[i].id].bigLogo = r.data[i].bigLogo;
                        }
                    }
                    p.resolve(r);
                })
                .catch(function (e) { console.error(e); p.reject(); });

            return p.promise;
        };

        userService.closeContract = function (statusType, entityInstanceId) {
            // 1 - close
            // 2 - reopen
            // 3 - archive
            // redo context only if we are changing current contract
            if (entityInstanceId == userService.system.context.contract?.entityInstanceId) {
                // update the current context
                userService.setContext({
                    ...userService.system.context,
                    contract: {
                        ...userService.system.context.contract,
                        isArchived: statusType == 3,
                        isClosed: statusType == 1
                    }
                });
            }
            else {
                // force refresh of the context on Angular to update the changed contract
                userService.refreshContext();
            }
        }

        userService.refreshContext = function () {
            jsToNg.refreshContext();
        }

        userService.Modules = function (contractId)
        {
            var p = $q.defer();
            contractId = contractId || 0;
            userService.isLoadingMenu = true;
            userService.hasModulesLoaded = false; 
            $http.get(environment.baseApiUrl + '_api/Menu/UserMenu?contractId=' + contractId)
                .then(function (r)
                {
                    userService.system.menu = r.data;
                    userService.system.modules = userService.parseModulesList(r.data);
                    userService.parseModuleNames();
                    userService.get_EmailCounts();
                    p.resolve(r);
                })
                .catch(function (e) { console.error(e); p.reject(); })
                .finally(function () { 
                    userService.isLoadingMenu = false; 
                    userService.hasModulesLoaded = true; 
                });

            return p.promise;
        };

        userService.parseModuleNames = function ()
        {
            var names = {};

            for (var key in userService.system.modules)
            {
                if (userService.system.modules.hasOwnProperty(key))
                {
                    names[key] = userService.system.modules[key].name;
                }
            }

            userService.system.mNames = names;
        };

        userService.bfs = function (tree, childrenKey, idKey, result)
        {
            if (!tree[childrenKey] || tree[childrenKey].length === 0)
            {
                return;
            }

            for (var i = 0; i < tree[childrenKey].length; i++)
            {
                var child = { roles: [], ...tree[childrenKey][i]};

                    if (child['typeId'] === 2 || child['typeId'] === 3)
                    {
                        result[child[idKey]] = child;
                        userService.system.modulesList.push(child);
                    }

                userService.bfs(child, childrenKey, idKey, result);
            }

            return;
        };

        userService.parseModulesList = function (modules)
        {
            var modulesList = {},
                modules = modules || [],
                dataTree = { 'children': modules };
            userService.bfs(dataTree, 'children', 'code', modulesList);
            
            return modulesList;
        };

        userService.getOperationsFor = function (code)
        {
            code = code || '_no_code';
            var operations = {};

            if (userService.system.modules[code])
            {
                if (userService.system.modules[code].operations)
                {
                    for (var i = 0; i < userService.system.modules[code].operations.length; i++)
                    {
                        operations[userService.system.modules[code].operations[i].name] = true;
                    }
                }
            }

            return operations;
        };

        userService.getOperationsForModule = function (moduleId)
        {
            var operations = {};

            var module = userService.system.modulesList.find(m => m.moduleId == moduleId);
            if (module) {
                return userService.getOperationsFor(module.code);
            }

            return operations;
        };

        userService.getWorkflow = function (moduleId) {            
            var p = $q.defer();
            $http[URI.MODULE.GET_WORKFLOW.method](`${URI.MODULE.GET_WORKFLOW}?entityInstanceId=${userService.system.userdata.contractEntityInstanceId || 0}&moduleId=${moduleId}`.toString())
                .then(function (r) {
                    userService.system.oldWorkflowId = userService.system.workflowId;
                    userService.system.workflowId = r.data;
                    p.resolve();
                })
                .catch(function (e) {
                    console.error(e);
                    p.reject(e);
                });

            return p.promise;
        }

        userService.getModuleIdentifierById = function (moduleId) {
            if (!userService.hasModulesLoaded) {
                return undefined;
            }
          
            moduleId = moduleId || 0;
            var retval = null;

            for (var key in userService.system.modules) {
                if (userService.system.modules[key]['moduleId'] == moduleId) {
                    retval = userService.system.modules[key]['code'];
                    break;
                }
            }

            return retval;
        };

        userService.getModuleObjectById = function (moduleId) {
            moduleId = moduleId || 0;
            var retval = null;

            for (var key in userService.system.modules) {
                if (userService.system.modules[key]['moduleId'] == moduleId) {
                    retval = userService.system.modules[key];
                }
            }

            return retval;
        };

        userService.getPermissions = function (code)
        {
            code = code || 'no_code';

            var permissions = {};

            if (userService.system.modules[code])
            {
                for (var i = 0; i <= userService.system.modules[code].operations; i++)
                {
                    permissions[userService.system.modules[code].operations[i].Name] = true;
                }
            }

            return permissions;
        };

        userService.getPermissionsIdList = function (code) {
            code = code || 'no_code';
            if (userService.system.modules[code])
            {
                var permissions = (userService.system.modules[code].operations || []).map(o => o.id);
                return permissions;
            }
            return [];
        };

        userService.currentTenant = function () {
            var sTenant = localStorageService.get('CurrentTenant');
            return userService.tenantId !== null
                ? userService.tenantId 
                : typeof sTenant !== 'undefined'
                    ? sTenant : null;
        };

        userService.changeTenant = function (tenantId)
        {
            var p = $q.defer();

            // set tenant id
            userService.tenantId = tenantId;

            // set tenant in session
            localStorageService.set('CurrentTenant', tenantId);
            
            p.resolve();

            return p.promise;
        };

        userService.getTenantIdentifierById = function (tenantId)
        {
            tenantId = tenantId || -1;
            if (tenantId > 0)
            {
                var selectedTenant = userService.system.tenants
                    .filter(function (o)
                    {
                        return o.id === tenantId;
                    });
                return selectedTenant[0] ? selectedTenant[0].name.toString().toLowerCase() : null;
            } 
            
            return undefined;
        };

        userService.getTenantIdByIdentifier = function (tenantIdentifier)
        {
            if (tenantIdentifier !== undefined)
            {
                var selectedTenant = userService.system.tenants
                    .filter(function (o)
                    {
                        var name = o.name ? o.name.toString().toLowerCase() : '';
                        var identifier = tenantIdentifier ? tenantIdentifier.toString().toLowerCase() : null;
                        return name === identifier;
                    });

                return selectedTenant[0] ? selectedTenant[0].id : null;
            }

            return -1;
        };

        userService.getTenantStatus = function ()
        {
            if (userService.tenantId === -1)
                return userService.tenantId;

            var selectedTenant = userService.system.tenants
                .filter(function (o)
                {
                    return o.id === userService.tenantId;
                });

            return selectedTenant[0] ? selectedTenant[0].statusId : null;
        };

        userService.setTenant = function (tenantIdentifier)
        {
            var p = $q.defer();
            tenantIdentifier = tenantIdentifier || null;
            var tenantId = userService.getTenantIdByIdentifier(tenantIdentifier);

            // reset data on tenant change
            if (tenantId !== userService.tenantId)
            {
                userService.hasModulesLoaded = false;
                userService.isUserLoaded = false;
            }

            // set tenant id
            userService.tenantId = tenantId;

            // set tenant in session
            // localStorageService.set('CurrentTenant', tenantId);

            return p.promise;
        };

        userService.setContract = function (contractId) {
            userService.setContext({...{
                contract: { 
                    id: contractId * 1
                },
            }});
        };

        userService.setContext = function (context, addLastUsedContract) {
            var p = $q.defer();

            if (!context) {
                userService.system.context = undefined;
                p.resolve();
                return p.promise;
            }

            var contractChanged = context.contract?.id !== userService.system.userdata.contractId;
            var lastContextEntityInstanceId = userService.system.lastContext?.contract?.entityInstanceId ?? userService.system.lastContext?.project?.entityInstanceId ?? userService.system.lastContext?.program?.entityInstanceId;
            var contextEntityInstanceId = userService.system.context?.contract?.entityInstanceId ?? userService.system.context?.project?.entityInstanceId ?? userService.system.context?.program?.entityInstanceId;
            var contextChanged = contextEntityInstanceId !== lastContextEntityInstanceId;

            if (contractChanged || contextChanged) {
                userService.system.context = {...context};
            }
            else {
                addLastUsedContract = false;
            }
            
            if (contractChanged && context.contract) {
                userService.system.userdata.contractId = context.contract.id;
                userService.system.userdata.contractEntityInstanceId = context.contract.entityInstanceId;
                userService.system.lastContext = {...context};

                userService.getRolesForContract(context.contract.id);
                userService.isReloadGrid = true;
            }
            else if (contextChanged) {
                userService.system.userdata.contractEntityInstanceId = undefined;
                userService.system.lastContext = {...context};
            }
        
            if (addLastUsedContract !== false) {
                $http.post(environment.baseApiUrl + '_api/Contracts/AddLastUsedContext', context)
                    .then(function () {
                        p.resolve();
                    })
                    .catch(function (e) { console.error(e); p.reject(); });
            }
            else {
                p.resolve();
            }

            return p.promise;
        };

        userService.changeContextBehavior = function (moduleAbbr) {
            // only Project Files will have a context selection
            // other modules will have a contract selection
            if (moduleAbbr !== 'FCM') {
                userService.system.contextUseOnlyContract = true;
                if (userService.system.lastContext) {
                    userService.setContext(userService.system.lastContext, false);
                }
                else {
                    userService.ensureContractIsSelected();
                }
            }
            else {
                userService.system.contextUseOnlyContract = false;
                userService.setContext({...userService.system.context}, false);
                userService.ensureContractIsSelected();
            }
        }

        userService.ensureContractIsSelected = function () {
            if (!userService.system.userdata.id) {
                // skip if the user is not loaded
                return;
            }

            var contextEntityInstanceId = userService.system.context?.contract?.entityInstanceId ?? userService.system.context?.project?.entityInstanceId ?? userService.system.context?.program?.entityInstanceId;

            if (contextEntityInstanceId) {
                userService.setContext({...userService.system.context});
            }
        }

        userService.setContextTree = function (contextTree) {
            userService.system.contextTree = contextTree || {};
            userService.system.contextTree.loaded = true;
            userService.system.context = userService.getContextWhenContractIsIncomplete();
            userService.ensureContractIsSelected();
        }

        userService.getContextProjects = function () {
            if (!userService.system.contextTree || !userService.system.contextTree.loaded) {
                return [];
            }
            var projects = (userService.system.contextTree.programs || []).reduce((acc, program) => {
                acc.push(...(program.projects || []));
                return acc;
              }, []);
            return projects || [];
        }

        userService.getContextProject = function (projectId, entityInstanceId, projectNo) {
            var project = userService.getContextProjects().find(p => 
                (p.id * 1) === (projectId * 1) 
                || (p.entityInstanceId * 1) === (entityInstanceId * 1) 
                || p.no === projectNo);
            return project;
        }

        userService.getContextProgram = function (programId, entityInstanceId, programNo) {
            var program = userService.getContextProjects().find(p => 
                (p.id * 1) === (programId * 1) 
                || (p.entityInstanceId * 1) === (entityInstanceId * 1) 
                || p.no === programNo);
            return program;
        }

        userService.getContextContracts = function () {
            var contracts = userService.getContextProjects().reduce((acc, project) => {
                acc.push(...(project.contracts || []));
                return acc;
              }, []);
            return contracts || [];
        }

        userService.getContextContract = function (contractId, entityInstanceId, contractNo) {
            var contract = userService.getContextContracts().find(c => 
                (c.id * 1) === (contractId * 1) 
                || (c.entityInstanceId * 1) === (entityInstanceId * 1) 
                || c.no === contractNo);
            return contract;
        }

        userService.getProjectForContract = function (contractId, entityInstanceId, contractNo) {
            var project = userService.getContextProjects().find(p => p.contracts.some(c => 
                (c.id * 1) === (contractId * 1) 
                || (c.entityInstanceId * 1) === (entityInstanceId * 1) 
                || c.no === contractNo));
            return project;
        }

        userService.getProgramForContract = function (contractId, entityInstanceId, contractNo) {
            var project = userService.getProjectForContract(contractId, entityInstanceId, contractNo);
            var program = (userService.system.contextTree.programs || []).find(p => (p.projects || []).some(j => 
                (j.id * 1) === (project?.id * 1) 
                || (j.entityInstanceId * 1) === (project?.entityInstanceId * 1) 
                || j.no === project?.no));
            return program;
        }

        userService.getContextWhenContractIsIncomplete = function () {
            var ctx = {...userService.system.context};
            var contract = ctx?.contract;
            if (!contract?.id || !contract?.entityInstanceId) {
                ctx.contract = userService.getContextContract(contract?.id, contract?.entityInstanceId);
            }
            if (!ctx.project) {
                ctx.project = userService.getProjectForContract(contract?.id, contract?.entityInstanceId);
            }
            if (!ctx.program) {
                ctx.program = userService.getProgramForContract(contract?.id, contract?.entityInstanceId);
            }
            return ctx;
        }

        userService.isContractClosed = function (contractId, entityInstanceId, contractNo) {
            var contract = userService.getContextContract(contractId, entityInstanceId, contractNo);
            return contract && (contract.isArchived === true || contract.isClosed === true);
        }

        userService.addZ =  function (date) {
            var ret = date || null;
            if (date && date.indexOf && date.indexOf("Z") == -1)
                ret = date + "Z";
            return ret;
        }

        userService.momentDate = function (date) {
            if (!date || date === EMPTY) {
                return null;
            }
            return moment.isMoment(date) ? date : moment.utc(userService.addZ(date));
        }
        
        userService.momentDateTime = function (date, restrictions) {
            if (!date || date === EMPTY) {
                return null;
            }
            var isDate = (restrictions || []).some(r => r.key == 5 && r.value == 3);
            var dt = moment.isMoment(date) ? date : moment.utc(userService.addZ(date));
            if (restrictions && isDate) return dt;
            return dt.utcOffset(userService.system.userdata?.timeZoneOffsetMinutes ?? 0);
        }
      
        userService.userSettings = function () {
            return userServiceNg1.getUserSettings() ?? {
                dateFormat: 'MM/DD/YYYY',
                datetimeFormat: 'MM/DD/YYYY hh:mm A',
                timeFormat: 'hh:mm A',
                decimalSeparator: '.',
                thousandSeparator: ',',
            };
        }

        userService.formatDate = function (date) {
            return userService.momentDate(date)?.format(userService.userSettings().dateFormat) ?? EMPTY;
        }

        userService.formatTime = function (time) {
            return userService.momentDateTime('1970-01-02T' + time)?.format(userService.userSettings().timeFormat) ?? EMPTY;
        }

        userService.formatTimeFromDateTime = function (date) {
            return userService.momentDateTime(date)?.format(userService.userSettings().timeFormat) ?? EMPTY;
        }

        userService.formatDateTime = function (date) {
            return userService.momentDateTime(date)?.format(userService.userSettings().datetimeFormat) ?? EMPTY;
        }

        userService.formatDateTimeCustom = function (date, format) {
            var hasTime = (format || '').toLowerCase().includes('hh:mm');
            var dt = hasTime ? userService.momentDateTime(date) : userService.momentDate(date);
            return dt?.format(format) ?? EMPTY;
        }

        userService.formatDateTimeBasedOnRestrictionsLookup = function (date, restrictionsLookup) {
            if (restrictionsLookup[5] == 5) {
                return userService.formatTimeFromDateTime(date);
            } else if (restrictionsLookup[5] == 3) {
                return userService.formatDate(date);
            }
            return userService.formatDateTime(date);
        }

        userService.formatDateTimeBasedOnRestrictions = function (date, restrictions) {
            // defaults to date format if no restrictions are passed in
            var isDate = (restrictions || []).some(r => r.key == 5 && r.value == 3);
            var isTime = (restrictions || []).some(r => r.key == 5 && r.value == 5);
            if (isTime) {
                return userService.formatTimeFromDateTime(date);
            } else if (isDate) {
                return userService.formatDate(date);
            }
            return userService.formatDateTime(date);
        }

        userService.getDateTimeFormatBasedOnRestrictions = function (restrictions) {
            // defaults to date format if no restrictions are passed in
            var isDate = (restrictions || []).some(r => r.key == 5 && r.value == 3);
            var isTime = (restrictions || []).some(r => r.key == 5 && r.value == 5);
            return isDate ? userService.userSettings().dateFormat : isTime ? userService.userSettings().timeFormat : userService.userSettings().datetimeFormat;
        }

        userService.formatDateToIsoBasedOnRestrictionsLookup = function (date, restrictionsLookup) {
            // set format based on restriction
            var isDate = restrictionsLookup[5] == 3;
            return isDate ? userService.momentDate(date)?.format(DATE_TIME_FORMATS.dateIso) : userService.momentDate(date)?.toISOString();
        }

        userService.formatDateToIsoBasedOnRestrictions = function (date, restrictions) {
            // defaults to date format if no restrictions are passed in
            var isDate = (restrictions || []).length === 0 || (restrictions || []).some(r => r.key == 5 && r.value == 3);
            // set format based on restriction
            return isDate ? userService.momentDate(date)?.format(DATE_TIME_FORMATS.dateIso) : userService.momentDate(date)?.toISOString();
        }

        userService.formatDateToIsoBasedOnPickerType = function (date, pickerType) {
            if (!pickerType) {
                return date;
            }
            if (!date) {
                return null;
            }
            if (pickerType == "datepicker") {
                return userService.momentDate(date)?.format(DATE_TIME_FORMATS.dateIso);
            } else if (pickerType == "datetimepiker" || pickerType == "timepicker") {
                return userService.momentDateTime(date)?.toISOString();
            }
            return null;
        }

        userService.daysDiff = function (date, dateToSubstract) {
            if (dateToSubstract) {
                return userService.momentDate(date).diff(userService.momentDate(dateToSubstract), 'days');
            }
            return userService.momentDate(date).diff(moment(), 'days');
        }

        userService.formatNumber = function (value, decimals, showComma, showCurrency, showPercentage, currency, percentageSymbol) {
            if (value === undefined || value === null) {
                return '';
            }

            decimals = decimals ?? 0;
            showComma = showComma ?? true;
            showCurrency = showCurrency ?? false;
            showPercentage = showPercentage ?? false;
            percentageSymbol = percentageSymbol ?? '%';

            const currencySymbolDefault = '$';
            const leftSideDefault = true;
            const noSpaceDefault = true;
            const userSettings = userService.userSettings();
            const decimalSeparator = userSettings.decimalSeparator ?? '.';
            const thousandSeparator = userSettings.thousandSeparator ?? ',';
          
            let newValue = value;
            if (showCurrency && value?.toString().includes(currency?.symbol ?? currencySymbolDefault)) {
                return value.toString();
            } else if (showPercentage && value?.toString().includes(percentageSymbol)) {
                return value.toString();
            }
        
            const numberValue = typeof newValue == 'string' ? Number(newValue?.toString()) : newValue;
            if (numberValue === undefined || numberValue === null || isNaN(numberValue)) {
                return value?.toString();
            }
        
            const numberValueAbs = Math.abs(numberValue);
        
            if (showCurrency && (currency === null || currency === undefined)) {
                currency = { symbol: currencySymbolDefault };
            }
        
            const numberLocale = userService.getNumberLocale(decimalSeparator, thousandSeparator);
            const options = {
                minimumFractionDigits: decimals,
                maximumFractionDigits: decimals,
                useGrouping: showComma,
            };
            const ret = new Intl.NumberFormat(numberLocale, options).format(numberValueAbs);
        
            const prefixNegative = numberValue < 0 ? EMPTY : '';
        
            if (showPercentage) {
                return `${prefixNegative}${ret}${percentageSymbol}`;
            }
        
            if (showCurrency) {
                const leftSide = currency.leftSide ?? leftSideDefault;
                const noSpace = currency.noSpace ?? noSpaceDefault;
            
                const prefixSpace = !noSpace && leftSide ? ' ' : '';
                const suffixSpace = !noSpace && !leftSide ? ' ' : '';
                const prefixCurrency = leftSide ? currency.symbol : '';
                const suffixCurrency = !leftSide ? currency.symbol : '';
            
                if (noSpace) {
                    return `${prefixNegative}${prefixCurrency}${ret}${suffixCurrency}`;
                }
            
                return `${prefixCurrency}${prefixSpace}${prefixNegative}${ret}${suffixSpace}${suffixCurrency}`;
            }
        
            return `${prefixNegative}${ret}`;
        }

        userService.getNumberLocale = function (decimalSeparator, thousandSeparator) {
            if (decimalSeparator === ',' && thousandSeparator === '.') {
                return 'ro-RO';
            }
            return 'en-US';
        }

        userService.formatNumberWithFormattings = function (value, formattings, restrictions, currency, percentageSymbol, hasMultipleValues) {
            const numberFormatting = (formattings || []).find(f => f.key == FormattingType.Number);
            const decimalFormatting = (formattings || []).find(f => f.key == FormattingType.Decimals);
            const showComma = !!(restrictions || []).find(f => f.key == RestrictionType.ShowThousandSeparator);
            const showCurrency = numberFormatting && numberFormatting.value == FormattingValueType.Money;
            const showPercentage = numberFormatting && numberFormatting.value == FormattingValueType.Percentage;

            let decimals = decimalFormatting ? decimalFormatting.formattingValue : 0;

            if (!hasMultipleValues) {
                return userService.formatNumber(
                    value,
                    decimals,
                    showComma,
                    showCurrency,
                    showPercentage,
                    currency,
                    percentageSymbol
                );
            }
            else {
                return ((value || '').split(', ') || []).map(v => (
                    userService.formatNumber(
                        v,
                        decimals,
                        showComma,
                        showCurrency,
                        showPercentage,
                        currency,
                        percentageSymbol
                    )
                )).join(', ');
            }
        }

        userService.formatCurrency = function (value, currency, decimals, showComma) {
            const showCurrency = currency !== null && currency !== undefined;
            return userService.formatNumber(
                value,
                decimals,
                showComma,
                showCurrency,
                false,
                currency
            );
        }

        userService.revertLocaleNumber = function (value) {
            const userSettings = userService.userSettings();
            const decimalSeparator = userSettings.decimalSeparator ?? '.';
            const thousandSeparator = userSettings.thousandSeparator ?? ',';
            const baseValue = value?.toString().replaceAll(thousandSeparator, '').replace(decimalSeparator, '.');
            const newValue = parseFloat(baseValue);
            return isNaN(newValue) ?  undefined : newValue;
        }

        // triggers
        userService.triggerMenuRefresh = function ()
        {
            return userService.Modules(userService.system.userdata.contractId);
        };

        userService.triggerPermissionsRefresh = function ()
        {
            var p = $q.defer();

            userService.PGrid().then(function (r)
            {
                userService.system.pGrid = r.permissions;
                userService.system.mNames = r.names;
                p.resolve();
            }).catch(function (e)
            {
                p.reject(e);
            });

            return p.promise;
        };

        return userService;
    }]);
