import { CdkDragDrop, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output, computed, effect, inject, signal } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { debounceTime, take } from 'rxjs';
import { KeyValueModel, KeyValueType } from '../../../models/common';
import { SearchViewModel } from '../../../models/module/grid';
import { TenantSettingsEnum } from '../../../models/tenant';
import { CipoSelectComponent } from '../../../shared/components/fields/cipo-select/cipo-select.component';
import { CipoFieldTypes, CipoListControl } from '../../../shared/components/fields/common';
import { DEBOUNCE_TIMES } from '../../../shared/consts';
import { CipoFormModule } from '../../../shared/modules';
import { NotificationService, UserService, UtilsService } from '../../../shared/services';
import { TenantStore } from '../../../signal-store';
import { AssignUsersDialogComponent } from './assign-users-dialog/assign-users-dialog.component';
import { SelectUsersParameters } from './assign-users-dialog/assign-users-dialog.model';
import { Assignments, AssignmentsPipe, AssignmentsService, ModuleIds, SyncRolePositionModel, SyncUsersAndRolesModel } from './common';
import { PrimaryUsersDialogComponent } from './primary-users-dialog/primary-users-dialog.component';

@Component({
  selector: 'cipo-assignments',
  standalone: true,
  imports: [
    TranslateModule,
    CipoFormModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    NgxSkeletonLoaderModule,
    CipoSelectComponent,
    DragDropModule,
    AssignmentsPipe,
  ],
  providers: [AssignmentsService, FormControl],
  templateUrl: './assignments.component.html',
  styleUrls: ['./assignments.component.scss'],
})
export class AssignmentsComponent implements OnInit {
  translate = inject(TranslateService);
  assignmentsService = inject(AssignmentsService);
  notification = inject(NotificationService);
  utilsService = inject(UtilsService);
  userService = inject(UserService);

  tenantStore = inject(TenantStore);

  @Output('roleschanged') rolesChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output('assignmentschanged') assignmentsChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output('onclose') onClose: EventEmitter<void> = new EventEmitter<void>();
  @Input('entityinstanceid') entityInstanceId: number;
  @Input('moduleid') moduleId: number;
  @Input('hasupdate') hasUpdate: boolean;

  MODULE_IDS = ModuleIds;
  assignments = signal<Assignments[]>([]);
  initialAssignments: Assignments[] = [];
  initialSearchAssignments = computed(() => {
    const searchValue = this.searchValue();
    return this.assignments()?.filter(
      row =>
        row?.userName?.toString()?.toLowerCase()?.includes(searchValue?.toLowerCase()) ||
        row?.userOrgName?.toString()?.toLowerCase()?.includes(searchValue?.toLowerCase()) ||
        row?.userEmail?.toString()?.toLowerCase()?.includes(searchValue?.toLowerCase()) ||
        row?.roles?.some(r => r.value?.toString()?.toLowerCase()?.includes(searchValue?.toLowerCase())),
    );
  });
  anyAssignmentsChanged = computed(() => {
    return this.assignments()?.some(a => a?.deleting || a?.edited || a?.isNew);
  });
  totalAllocationHours = computed(() => {
    return this.assignments()
      ?.map(d => d?.allocationHoursPerMonth ?? 0)
      ?.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  });
  searchModel: SearchViewModel = {};
  searchValue = signal('');
  searchControl = new FormControl('');
  propagateHierarchy = signal(false);
  loading = signal(false);
  saving = signal(false);
  ordering = signal(false);
  userRateEnabled = signal(false);
  userAllocationEnabled = signal(false);
  assignmentDetailsPercent: number = 70;
  roles: KeyValueType<number>[] = [];
  arrayControls: Record<number, CipoListControl> = {};
  disabledForm = computed(() => this.ordering() || this.saving() || this.entityInstanceLocked());

  entityInstanceLocked = signal(false);
  @Input('entityinstancelocked')
  set _entityInstanceLocked(value: boolean) {
    this.entityInstanceLocked.set(value);

    if (Object.keys(this.arrayControls).length) {
      this.disableEnableControls(value);
    }
  }

  constructor(private dialog: MatDialog) {
    effect(() => {
      const disabled = this.ordering() || this.saving();

      // Check to avoid multiplication
      if (Object.values(this.arrayControls)[0]?.disabled === disabled) {
        return;
      }

      this.disableEnableControls(disabled);
    });
  }

  ngOnInit(): void {
    const tenantSettings = this.tenantStore.getSettings([
      TenantSettingsEnum.user_allocation,
      TenantSettingsEnum.user_rate,
    ]);
    this.userRateEnabled.set(
      this.utilsService.convertValueToBoolean(
        tenantSettings.find(tenantSetting => tenantSetting.id == TenantSettingsEnum.user_rate).value,
      ),
    );
    this.userAllocationEnabled.set(
      this.utilsService.convertValueToBoolean(
        tenantSettings.find(tenantSetting => tenantSetting.id == TenantSettingsEnum.user_allocation).value,
      ),
    );

    if (this.userRateEnabled()) {
      this.assignmentDetailsPercent -= 10;
    }
    if (this.userAllocationEnabled()) {
      this.assignmentDetailsPercent -= 10;
    }
    this.searchControl.valueChanges
      .pipe(debounceTime(DEBOUNCE_TIMES.default))
      .subscribe(value => this.searchValue.set(value));
    this.assignmentsService.getRoles().subscribe(r => {
      this.roles = r.map(({ key, value }) => ({ key, value }));
      this.loadData();
    });
  }

  disableEnableControls(disabled: boolean) {
    Object.values(this.arrayControls).forEach(control => {
      if (disabled) {
        control.disable({ emitEvent: false });
      } else {
        control.enable({ emitEvent: false });
      }
    });
  }

  loadData() {
    // clear criteria and assignments
    this.searchModel.criteria = '';
    this.assignments.set([]);
    this.loading.set(true);

    this.assignmentsService.getAssignments(this.searchModel, this.entityInstanceId).subscribe(a => {
      this.assignments.set(a.data);
      this.initialAssignments = structuredClone(this.assignments());
      this.generateControls();
      this.loading.set(false);
    });
  }

  generateControls() {
    this.arrayControls = {};

    for (let index = 0; index < this.assignments().length; index++) {
      const control = new CipoListControl(this.assignments()[index].roleIds, {
        label: 'assignments.roles',
        multiple: true,
        type: CipoFieldTypes.Select,
        options: this.roles,
      });

      control.valueChanges.pipe(take(1)).subscribe(x => {
        this.assignments.update(assignments => {
          assignments[index].edited = true;
          return [...assignments];
        });
        this.rolesChanged.emit(true);
      });

      this.arrayControls[this.assignments()[index].userId] = control;
    }

    if (this.entityInstanceLocked()) {
      this.disableEnableControls(true);
    }
  }

  deleteAssignment(index: number) {
    this.rolesChanged.emit(true);
    const userId = this.assignments()[index].userId;

    this.assignments.update(assignments => {
      assignments[index].deleting = true;
      return [...assignments];
    });
    this.arrayControls[userId].setValue([]);
    this.arrayControls[userId].disable();
  }

  restoreDeleteAssignment(index: number) {
    const assignment = this.assignments()[index];

    this.assignments.update(assignments => {
      assignments[index].deleting = false;
      return [...assignments];
    });
    this.arrayControls[assignment.userId].setValue(assignment.roleIds);
    this.arrayControls[assignment.userId].enable();
  }

  somethingChange(index: number) {
    this.assignments.update(assignments => {
      assignments[index].edited = true;
      return [...assignments];
    });
    this.rolesChanged.emit(true);
  }

  saveAssignments() {
    // clear search on save
    this.clearInput();

    if (this.assignments()?.length) {
      this.saving.set(true);

      for (let index = 0; index < this.assignments().length; index++) {
        const roleIds = this.arrayControls[this.assignments()[index].userId]?.value ?? [];

        this.assignments()[index].roleIds = roleIds;
        this.assignments()[index].roles = this.roles.filter(r => roleIds.includes(r.key));
      }

      const syncModel: SyncUsersAndRolesModel = {
        id: this.entityInstanceId,
        propagateOnHierarchy: this.propagateHierarchy(),
        users: this.assignments().filter(a => !a.deleting),
      };

      this.assignmentsService.syncAssignments(syncModel).subscribe({
        complete: () => {
          this.notification.success('assignments.successfully');
          this.loadData();
          this.rolesChanged.emit(false);
          this.assignmentsChanged.emit(true);
          this.saving.set(false);
          this.propagateHierarchy.set(false);
        },
        error: () => {
          this.saving.set(false);
        },
      });
    }
  }

  clearInput() {
    this.searchControl.setValue('');
  }

  openSelectUsersModel() {
    const params: SelectUsersParameters = {
      entityInstanceId: this.entityInstanceId,
      existingUsers: this.assignments()
        .filter(a => !a.deleting)
        .map(a => a.userId),
    };

    this.dialog
      .open<AssignUsersDialogComponent, SelectUsersParameters, Assignments[]>(AssignUsersDialogComponent, {
        panelClass: ['cipo-dialog', 'classic'],
        ...this.userService.getResponsiveDialogSize().md,
        height: '65%',
        data: params,
      })
      .afterClosed()
      .subscribe(assignments => {
        if (assignments?.length) {
          // clear search on save
          this.clearInput();

          this.assignments.set([...assignments, ...this.assignments()]);
          this.generateControls();

          this.assignmentsChanged.emit(true);
        }
      });
  }

  openPrimaryUsersModel() {
    this.dialog
      .open(PrimaryUsersDialogComponent, {
        panelClass: ['cipo-dialog', 'classic', 'full-height'],
        height: '770px',
        data: this.entityInstanceId,
        ...this.userService.getResponsiveDialogSize().sm,
      })
      .afterClosed()
      .subscribe((status: boolean) => {
        if (status) {
          this.loadData();
        }
      });
  }

  drop(asignment: Assignments, event: CdkDragDrop<KeyValueModel<number, string>[]>) {
    moveItemInArray(asignment.roles, event.previousIndex, event.currentIndex);
  }

  saveOrdering(index: number) {
    const assignment = this.assignments()[index];
    // set order
    for (let index = 0; index < assignment.roles.length; index++) {
      assignment.roles[index].order = index + 1;
    }

    this.assignments.update(assignments => {
      assignments[index] = assignment;
      return [...assignments];
    });

    const model: SyncRolePositionModel = {
      entityInstanceId: this.entityInstanceId,
      userId: assignment.userId,
      roles: assignment.roles,
    };

    this.assignmentsService.syncRolePosition(model).subscribe(() => {
      this.notification.success('assignments.rolesSuccessfully');
      this.ordering.set(false);
      this.loadData();
    });
  }

  discardChanges() {
    // clear search on save
    this.clearInput();
    this.assignments.set(structuredClone(this.initialAssignments));
    this.propagateHierarchy.set(false);
    this.generateControls();
    this.rolesChanged.emit(false);
  }

  onCloseEmit() {
    this.onClose.emit();
  }
}
