import { cipo } from 'cipo';
import { environment } from 'src/environments/environment';

cipo.controller('ausersController',
    function ($transition$, $scope, $state, $location, Manager, rememberManagerService, Dictionaries, Message, URI, ACTIONS, userService, $mdDialog)
    {
        $scope.moduleName = userService.system.mNames[$state.current.code];
        $scope.showDisabled = false;
        $scope.module = userService.system.modules[$state.current.code];
        $scope.roles = [];

        // get live operations
        Object.defineProperty($scope, 'operations', {
            get: function () { return userService.getOperationsFor($state.current.code); }
        });

        $scope.sendList = function (data)
        {
            var listToSend = [];

            if (Object.prototype.toString.call(data) === '[object Array]')
            {
                if (data.length)
                {
                    for (var i = 0; i < data.length; i++)
                    {
                        if (typeof data[i].inviteId !== 'undefined')
                        {
                            listToSend.push(data[i].inviteId);
                        }
                    }
                }
            } else
            {
                if (typeof data.inviteId !== 'undefined')
                {
                    listToSend.push(data.inviteId);
                }
            }

            return listToSend;
        };

        $scope.manager = new Manager({
            objectsPerPage: 20,
            dataURL: URI.USER.SEARCH,
            dataWrapper: "data",
            options: {
                multiselect: $scope.operations.Update || $scope.operations.Delete ? true : false
            },
            otherParams: {
                ShowDisabled: $scope.showDisabled
            },
            rowOnClick: function (row)
            {
                $scope.editUser(row);
            },
            statusFilter: {
                model: $scope.showDisabled,
                name: "Filter",
                managerParam: "ShowDisabled",
                dataArr: [
                    {
                        Key: true,
                        Val: "Show All"
                    },
                    {
                        Key: false,
                        Val: "Show Active"
                    }
                ],
                type: "select"
            },
            filters: [
            ],
            parseData: function (data, columns)
            {
                var data = data || [], retval = [];

                    for (var i = 0; i < data.length; i++)
                    {
                        if (data[i].logo) data[i]._email = '<img class="tableSmallTmb" src="data:'
                            + data[i].mimeType + ';base64,' + data[i].logo + '" />' + data[i].email;
                        else data[i]._email = '<i class="tableIcon noProfilePic"></i>' + data[i].email;
                        data[i]._roles = (data[i].roleIds || []).map(r => $scope.roles[r]||'').join(', ');
                        retval.push(data[i]);
                    }

                    return retval;
                },
                leftActions: [
                    {
                        setProperties: ACTIONS.INVITE,
                        conditionOnTop: function ()
                        {
                            if ($scope.operations.Create)
                            {
                                return true;
                            }
                            else
                            {
                                return false;
                            }
                        },
                        click: function (rows)
                        {
                            $scope.editUser();
                        }
                    }
                ],
                actions: [
                    {
                        setProperties: ACTIONS.REFRESH,
                        click: function ()
                        {
                            $scope.manager.refresh();
                        }
                    },
                    {
                        setProperties: ACTIONS.DETAILS,
                        condition: function (row) {
                            if (row.operations.Update)
                                return true;
                            else return false;
                        },
                        click: function (row)
                        {
                            $scope.editUser(row);
                        }
                    },
                    {
                        setProperties: ACTIONS.VIEW,
                        condition: function (row) {
                            if (!row.operations.Update)
                                return true;
                            else return false;
                        },
                        click: function (row) {
                            $scope.editUser(row);
                        }
                    },
                    {
                        setProperties: ACTIONS.REINVITE,
                        condition: function (row)
                        {
                            return row.status === 1 ? true : false;
                        },
                        click: function (row)
                        {
                            $scope.reinviteUser(row.id);
                        }
                    },
                    {
                        setProperties: ACTIONS.ACTIVATE,
                        displayOnRow: $scope.operations.Update ? true : false,
                        condition: function (row)
                        {
                            if (row.status === 0)
                                return true;
                            else return false;
                        },
                        conditionOnTop: function ()
                        {
                            if ($scope.operations.Update) return true;
                            else return false;
                        },
                        click: function (row) {
                            $scope.enableUser(row.id);
                        }
                    },
                    {
                        setProperties: ACTIONS.DEACTIVATE,
                        displayOnRow: $scope.operations.Update ? true : false,
                        condition: function (row)
                        {
                            if (row.status === 2)
                                return true;
                            else return false;
                        },
                        conditionOnTop: function ()
                        {
                            if ($scope.operations.Update) return true;
                            else return false;
                        },
                        click: function (row) {
                            $scope.disableUser(row.id);
                        }
                    },
                    {
                        setProperties: ACTIONS.UNINVITE,
                        displayOnTop: false,
                        displayOnRow: true,
                        condition: function (row) {
                            return row.status === 1 ? true : false;
                        },
                        click: function (row) {
                            $scope.uninviteUser(row.id);
                        }
                    },
                    {
                        setProperties: ACTIONS.CHANGE_EMAIL,
                        condition: function (row) {
                            if (row.status != 0)
                                return true;
                            else return false;
                        },
                        click: function (row) {
                            $scope.changeUserEmail(row.id);
                        }
                    },
                    {
                        //do not show this menu in PROD
                        setProperties: ACTIONS.DELETE,
                        name: "Delete (for testing only)",
                        displayOnTop: false,
                        displayOnRow: location.origin.indexOf('app.cipo.cloud') != -1 ? false : true,
                        conditionOnTop: function () {
                            return location.origin.indexOf('app.cipo.cloud') != -1 ? false : true;
                        },
                        click: function (row) {
                            $scope.testDeleteUser(row.id);
                        }
                    },
                    {
                        setProperties: ACTIONS.MORE,
                        conditionOnTop: function ()
                        {
                            if ($scope.operations.Update) return true;
                            else return false;
                        },
                        actions: [
                            {
                                setProperties: ACTIONS.ACTIVATE,
                                click: function (rows)
                                {
                                    $scope.activateUser($scope.sendList(rows));
                                }
                            },
                            {
                                setProperties: ACTIONS.DEACTIVATE,
                                click: function (rows)
                                {
                                    $scope.deactivateUser($scope.sendList(rows));
                                }
                            }

                    ]
                }
            ]
        });

        var statusLookup = {
            2: { name: 'Active', class: 'basic' },
            1: { name: 'Pending', class: 'warning' },
            0: { name: 'Deactivated', class: 'deactivated' }
        };

        $scope.manager.set_Columns([
            { name: '_email', label: 'Email', type: 'checkbox', replacementFor: 'email', onTablet: true, onPhone: true, width: 35, fieldTypeId: 1 },
            { name: 'firstName', label: 'First Name', type: 'text', onTablet: true, onPhone: false, width: 15, fieldTypeId: 1 },
            { name: 'lastName', label: 'Last Name', type: 'text', onTablet: true, onPhone: false, width: 15, fieldTypeId: 1 },
            { name: 'organization', label: 'Organization', type: 'text', onTablet: true, onPhone: true, width: 20, fieldTypeId: 1 },
            { name: "lastLogin", label: 'Last Sign In', type: 'date', replacementFor: "lastLogin", onTablet: true, onPhone: true, width: 13, fieldTypeId: 4 },
            { name: 'status', label: 'Status', type: 'status', lookupArr: statusLookup, onTablet: true, onPhone: true, width: 10 },
            { name: 'createdBy', label: 'Created By', type: 'text', onTablet: true, onPhone: false, width: 15, fieldTypeId: 1 },
            { name: 'dateAdded', label: 'Date Added', type: 'date', onTablet: true, onPhone: false, width: 15, fieldTypeId: 1 },
            { name: '_roles', label: 'Role', type: 'text', noSort: true, onTablet: false, onPhone: false, width: 20, whiteSpace: 'initial' },
        ]);

        Dictionaries.AllRoles().then(function(roles) {
            roles.forEach(r => $scope.roles[r.key] = r.value);

            $scope.manager.loading = true;
            $scope.manager = rememberManagerService.restore($scope.manager, $scope.module.moduleId, 
                function() {
                    $scope.manager.loading = false;
                },
                function() {
                    $scope.manager.loadPage()
                    .finally(function () {
                        $scope.manager.loading = false;
                    });
                }
            );

        })

        $scope.changeUserEmail = function (userId) {              
            var dialogLocals = {
                data: {
                    title: "New email",                       
                    buttonLabels: [
                        { key: 'confirm', value: 'Change Email' },
                        { key: 'cancel', value: 'Cancel' },
                    ],
                    userId: userId,
                    manage: $scope.manager
                }
            }

            $mdDialog.show({
                templateUrl: '/ng/views/admin/modals/changeEmail.html',
                locals: dialogLocals,
                controller: 'changeEmailController',
                parent: angular.element(document.body)
            })
                .then(function () {
                    $scope.manager.loadPage();       
                })
                .catch(function (e) {
                    Message.dberror(e);
                })
                .finally(function () {
                    
                });           
        };

        $scope.reinviteUser = function (userId)
        {
            var params = {};
            if (userId) params.userId = userId;
            $scope.manager[URI.USER.REINVITE.method](URI.USER.REINVITE, { url: params, urltype: 'obj' })
                .then(function ()
                {
                    Message.info('Invitation resent successfully');
                })
                .catch(function (e)
                {
                    Message.dberror(e);
                })
                .finally(function ()
                {
                    //$scope.manager.loadPage();
                });
        };

        var saving = false;
        $scope.editUser = function (obj, fromurl, ev)
        {
            var fromurl = fromurl || false;
            var obj = angular.copy(obj);
            if (!saving)
            {
                saving = true;
                $mdDialog.show({
                    locals: { obj: obj, operations: $scope.operations, module: $scope.module },
                    controller: 'editUserController',
                    templateUrl: '/ng/views/admin/modals/editUser.html',
                    parent: angular.element(document.body),
                    targetEvent: ev,
                    fullscreen: true,
                    escapeToClose: false,
                    clickOutsideToClose: false
                })
                    .then(function (result)
                    {
                        $scope.manager.page = 1;
                        $scope.manager.loadPage();
                        $location.search({ sid: null });
                        userService.triggerMenuRefresh();
                        saving = false;
                    }, function ()
                    {
                        // $scope.manager.loadPage();
                        $location.search({ sid: null });
                        userService.triggerMenuRefresh();
                        saving = false;
                    });
            }
        };
        if ($transition$.params().sid)
        {
            $scope.editUser({ inviteId: $transition$.params().sid }, true);
        }

        var confirm = function (title, text, ev)
        {
            return $mdDialog.confirm()
                .title(title)
                .textContent(text)
                .ariaLabel('Confirm Dialogue')
                .targetEvent(ev)
                .ok('Proceed')
                .cancel('Cancel');
        };

        $scope.disableUser = function (userId) {
            var params = {};
            if (userId) params.userId = userId;

            $mdDialog.show(confirm('Disable User', 'Are you sure you want to disable the selected user?'))
                .then(function () {
                    $scope.manager.loading = true;
                    $scope.manager[URI.USER.DISABLE2.method](URI.USER.DISABLE2, { url: params, urltype: 'obj' })
                        .then(function () {
                            Message.info('User successfully deactivated');
                        })
                        .catch(function (e) {
                            Message.dberror(e);
                        })
                        .finally(function () {
                            $scope.manager.loadPage();
                        });
                })
                .catch(function () {
                    //cancel pressed
                });
        };

        $scope.deactivateUser = function (list)
        {
            var list = list || [];

            if (list.length)
            {
                //var d = dialogs.confirm('Deactivate User', 'Are you sure you want to deactivate the selected User(s)?');
                $mdDialog.show(confirm('Disable Users', 'Are you sure you want to disable the selected User(s)?'))
                    .then(function ()
                    {
                        $scope.manager.loading = true;
                        $scope.manager[URI.USER.DISABLE.method](URI.USER.DISABLE, { list: list })
                            .then(function ()
                            {
                                Message.info('User(s) deactivated successfully');
                            })
                            .catch(function (e)
                            {
                                Message.dberror(e, $scope.manager.rows, 'inviteId', 'email');
                            })
                            .finally(function ()
                            {
                                $scope.manager.loadPage();
                            });
                    })
                    .catch(function ()
                    {
                        //cancel pressed
                    });
            } else
            {
                Message.info('Please select the item(s) to deactivate');
            }
        };

        $scope.deleteUser = function (list)
        {
            var list = list || [];

            if (list.length)
            {
                //var d = dialogs.confirm('Delete User', 'Are you sure you want to delete the selected User(s)?');
                $mdDialog.show(confirm('Delete Users', 'Are you sure you want to delete the selected User(s)?'))
                    .then(function ()
                    {
                        $scope.manager.loading = true;
                        $scope.manager[URI.USER.DELETE.method](URI.USER.DELETE, { list: list })
                            .then(function ()
                            {
                                Message.info('User(s) deleted successfully');
                            })
                            .catch(function (e)
                            {
                                Message.dberror(e, $scope.manager.rows, 'inviteId', 'email');
                            })
                            .finally(function ()
                            {
                                $scope.manager.loadPage();
                            });
                    })
                    .catch(function ()
                    {
                        //cancel pressed
                    });
            } else
            {
                Message.info('Please select the item(s) to delete');
            }
        };

        $scope.uninviteUser = function (userId) {               
            var params = {};
            if (userId) params.userId = userId;

            $mdDialog.show(confirm('Uninvite User', 'This action will remove this user`s invitation. Do you want to continue?'))
                .then(function () {
                    $scope.manager.loading = true;
                    $scope.manager.post(environment.baseApiUrl + '_api/User/Uninvite', { url: params, urltype: 'obj' })
                        .then(function () {
                            Message.info('User successfully uninvited');
                        })
                        .catch(function (e) {
                            Message.dberror(e);
                        })
                        .finally(function () {
                            $scope.manager.loadPage();
                        });
                })
                .catch(function () {
                    //cancel pressed
                });
        };

        $scope.testDeleteUser = function (id) {
            var id = id || 0;

            $mdDialog.show(confirm('Delete User', 'This is only for testing purposes, please only delete users you created and have no activity. Continue?'))
                .then(function () {
                    $scope.manager.loading = true;
                    $scope.manager.put(environment.baseApiUrl + '_api/User', { url: { userId: id } })
                        .then(function () {
                            Message.info('User deleted successfully');
                        })
                        .catch(function (e) {
                            Message.dberror(e);
                        })
                        .finally(function () {
                            $scope.manager.loadPage();
                        });
                })
                .catch(function () {
                    //cancel pressed
                });
        };

        $scope.enableUser = function (userId) {
            var params = {};
            if (userId) params.userId = userId;

            $mdDialog.show(confirm('Enable User', 'Are you sure you want to enable the selected user?'))
                .then(function () {
                    $scope.manager.loading = true;
                    $scope.manager[URI.USER.ENABLE2.method](URI.USER.ENABLE2, { url: params, urltype: 'obj' })
                        .then(function () {
                            Message.info('User successfully activated');
                        })
                        .catch(function (e) {
                            Message.dberror(e);
                        })
                        .finally(function () {
                            $scope.manager.loadPage();
                        });
                })
                .catch(function () {
                    //cancel pressed
                });
        };

        $scope.activateUser = function (list)
        {
            var list = list || [];

            if (list.length)
            {
                //var d = dialogs.confirm('Activate User', 'Are you sure you want to activate the selected User(s)?');
                $mdDialog.show(confirm('Enable Users', 'Are you sure you want to enable the selected User(s)?'))
                    .then(function ()
                    {
                        $scope.manager.loading = true;
                        $scope.manager[URI.USER.ENABLE.method](URI.USER.ENABLE, { list: list })
                            .then(function ()
                            {
                                Message.info('User(s) activated successfully');
                            })
                            .catch(function (e)
                            {
                                Message.dberror(e, $scope.manager.rows, 'inviteId', 'email');
                            })
                            .finally(function ()
                            {
                                $scope.manager.loadPage();
                            });
                    })
                    .catch(function ()
                    {
                        //cancel pressed
                    });
            } else
            {
                Message.info('Please select the item(s) to activate');
            }
        };

        $scope.$watch(function () {
            return userService.isReloadGrid;
        },
            function (n, o) {
                if (n) {
                    $scope.manager.loadPage();
                    userService.isReloadGrid = false;
                }
            });

    });
