<div mat-dialog-title fxLayout fxLayoutAlign="space-between center">
    <h5>{{ moduleParameter.moduleName }}</h5>
    <button mat-icon-button (click)="onClose()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <div class="p-t" fxLayout fxLayoutAlign="space-between center" fxLayoutGap="16px">
        <mat-form-field class="cipo-input hide-subscript p-0" fxFlex>
            <mat-label>{{ "moduleParameter.value" | translate }}</mat-label>
            <input matInput [formControl]="valueControl" type="number" />
        </mat-form-field>
        <mat-form-field class="cipo-input hide-subscript p-0" fxFlex>
            <mat-label>{{ "moduleParameter.moduleValue" | translate }}</mat-label>
            <input matInput disabled [value]="moduleParameter.moduleValue" type="number" />
        </mat-form-field>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="onClose()">{{ "general.close" | translate }}</button>
    <button mat-raised-button color="primary" (click)="onSave()">{{ "general.save" | translate }}</button>
</mat-dialog-actions>
