import { DataListField } from 'src/app/shared/components/data-list/common';

// Display types data
export const DISPLAY_TYPES_FIELDS = [
  {
    id: -2,
    systemName: 'full_doc_num',
    name: 'full_doc_num',
    hasMultipleValues: false,
    relationId: 0,
    typeId: 1,
    displayTypeId: 1,
    label: 'No.',
    position: 1,
    priority: 3,
    width: 1.0,
    printable: true,
    showThumbnail: false,
    isPrimarySort: true,
    isDescending: true,
    isFilter: false,
    isPayment: false,
    inUse: false,
    formattings: [],
    restrictions: [],
  },
  {
    id: 622,
    name: 'text1',
    hasMultipleValues: false,
    relationId: 0,
    typeId: 1,
    displayTypeId: 1,
    label: 'text1',
    position: 2,
    priority: 3,
    width: 1.0,
    printable: true,
    showThumbnail: false,
    isPrimarySort: false,
    isDescending: true,
    isFilter: true,
    isPayment: false,
    inUse: false,
    formattings: [],
    restrictions: [
      {
        mimeTypes: [],
        mimeTypesDataSource: [],
        key: 8,
      },
    ],
  },
  {
    id: 623,
    name: 'multiline1',
    hasMultipleValues: false,
    relationId: 0,
    typeId: 1,
    displayTypeId: 2,
    label: 'multiline1',
    position: 3,
    priority: 3,
    width: 1.0,
    printable: true,
    showThumbnail: false,
    isPrimarySort: false,
    isDescending: true,
    isFilter: true,
    isPayment: false,
    inUse: false,
    formattings: [],
    restrictions: [
      {
        mimeTypes: [],
        mimeTypesDataSource: [],
        key: 8,
      },
    ],
  },
  {
    id: 639,
    name: 'editor',
    hasMultipleValues: false,
    relationId: 0,
    typeId: 1,
    displayTypeId: 9,
    label: 'editor',
    position: 4,
    priority: 3,
    width: 1.0,
    printable: true,
    showThumbnail: false,
    isPrimarySort: false,
    isDescending: false,
    isFilter: false,
    isPayment: false,
    inUse: false,
    formattings: [],
    restrictions: [
      {
        mimeTypes: [],
        mimeTypesDataSource: [],
        key: 8,
      },
    ],
  },
  {
    id: 625,
    name: 'radiogrp1',
    hasMultipleValues: false,
    dataSourceId: -10,
    relationId: 0,
    typeId: 1,
    displayTypeId: 4,
    label: 'radiogrp1',
    position: 5,
    priority: 3,
    width: 1.0,
    printable: true,
    showThumbnail: false,
    isPrimarySort: false,
    isDescending: true,
    isFilter: false,
    isPayment: false,
    inUse: false,
    formattings: [],
    restrictions: [
      {
        mimeTypes: [],
        mimeTypesDataSource: [],
        key: 8,
      },
    ],
  },
  {
    id: 624,
    name: 'select1',
    hasMultipleValues: false,
    dataSourceId: -6,
    relationId: 0,
    typeId: 1,
    displayTypeId: 3,
    label: 'select1',
    position: 6,
    priority: 3,
    width: 1.0,
    printable: true,
    showThumbnail: false,
    isPrimarySort: false,
    isDescending: true,
    isFilter: false,
    isPayment: false,
    inUse: false,
    formattings: [],
    restrictions: [
      {
        mimeTypes: [],
        mimeTypesDataSource: [],
        key: 8,
      },
    ],
  },
  {
    id: 629,
    name: 'number4',
    hasMultipleValues: false,
    relationId: 0,
    typeId: 2,
    displayTypeId: 1,
    label: 'number percentage decimals 2',
    position: 7,
    priority: 3,
    width: 1.0,
    printable: true,
    showThumbnail: false,
    isPrimarySort: false,
    isDescending: true,
    isFilter: false,
    isPayment: false,
    inUse: false,
    formattings: [
      {
        key: 1,
        value: 3,
      },
      {
        key: 2,
        value: 2,
      },
    ],
    restrictions: [
      {
        mimeTypes: [],
        mimeTypesDataSource: [],
        key: 7,
      },
      {
        mimeTypes: [],
        mimeTypesDataSource: [],
        key: 4,
        value: 1,
      },
    ],
  },
  {
    id: 628,
    name: 'number3',
    hasMultipleValues: false,
    relationId: 0,
    typeId: 2,
    displayTypeId: 1,
    label: 'number money decimals 2',
    position: 8,
    priority: 3,
    width: 1.0,
    printable: true,
    showThumbnail: false,
    isPrimarySort: false,
    isDescending: true,
    isFilter: false,
    isPayment: false,
    inUse: false,
    formattings: [
      {
        key: 1,
        value: 2,
      },
      {
        key: 2,
        value: 2,
      },
    ],
    restrictions: [
      {
        mimeTypes: [],
        mimeTypesDataSource: [],
        key: 7,
      },
      {
        mimeTypes: [],
        mimeTypesDataSource: [],
        key: 4,
        value: 1,
      },
    ],
  },
  {
    id: 627,
    name: 'number2',
    hasMultipleValues: false,
    relationId: 0,
    typeId: 2,
    displayTypeId: 1,
    label: 'number decimals 2',
    position: 9,
    priority: 3,
    width: 1.0,
    printable: true,
    showThumbnail: false,
    isPrimarySort: false,
    isDescending: true,
    isFilter: false,
    isPayment: false,
    inUse: false,
    formattings: [
      {
        key: 1,
        value: 1,
      },
      {
        key: 2,
        value: 2,
      },
    ],
    restrictions: [
      {
        mimeTypes: [],
        mimeTypesDataSource: [],
        key: 7,
      },
      {
        mimeTypes: [],
        mimeTypesDataSource: [],
        key: 4,
        value: 1,
      },
    ],
  },
  {
    id: 626,
    name: 'number1',
    hasMultipleValues: false,
    relationId: 0,
    typeId: 2,
    displayTypeId: 1,
    label: 'number 0 decimals',
    position: 10,
    priority: 3,
    width: 1.0,
    printable: true,
    showThumbnail: false,
    isPrimarySort: false,
    isDescending: true,
    isFilter: false,
    isPayment: false,
    inUse: false,
    formattings: [
      {
        key: 1,
        value: 1,
      },
      {
        key: 2,
        value: 0,
      },
    ],
    restrictions: [
      {
        mimeTypes: [],
        mimeTypesDataSource: [],
        key: 7,
      },
      {
        mimeTypes: [],
        mimeTypesDataSource: [],
        key: 4,
        value: 1,
      },
    ],
  },
  {
    id: 633,
    name: 'date3',
    hasMultipleValues: false,
    relationId: 0,
    typeId: 4,
    displayTypeId: 1,
    label: 'time',
    position: 11,
    priority: 3,
    width: 1.0,
    printable: true,
    showThumbnail: false,
    isPrimarySort: false,
    isDescending: true,
    isFilter: true,
    isPayment: false,
    inUse: false,
    formattings: [],
    restrictions: [
      {
        mimeTypes: [],
        mimeTypesDataSource: [],
        key: 5,
        value: 5,
      },
    ],
  },
  {
    id: 632,
    name: 'date2',
    hasMultipleValues: false,
    relationId: 0,
    typeId: 4,
    displayTypeId: 1,
    label: 'date time',
    position: 12,
    priority: 3,
    width: 1.0,
    printable: true,
    showThumbnail: false,
    isPrimarySort: false,
    isDescending: true,
    isFilter: true,
    isPayment: false,
    inUse: false,
    formattings: [],
    restrictions: [
      {
        mimeTypes: [],
        mimeTypesDataSource: [],
        key: 5,
        value: 4,
      },
    ],
  },
  {
    id: 631,
    name: 'date1',
    hasMultipleValues: false,
    relationId: 0,
    typeId: 4,
    displayTypeId: 1,
    label: 'date',
    position: 13,
    priority: 3,
    width: 1.0,
    printable: true,
    showThumbnail: false,
    isPrimarySort: false,
    isDescending: true,
    isFilter: true,
    isPayment: false,
    options: 'yyyy-MM-dd',
    inUse: false,
    formattings: [],
    restrictions: [
      {
        mimeTypes: [],
        mimeTypesDataSource: [],
        key: 5,
        value: 3,
      },
    ],
  },
  {
    id: 634,
    name: 'attachment',
    hasMultipleValues: false,
    relationId: 0,
    typeId: 5,
    displayTypeId: 6,
    label: 'attachment single',
    position: 14,
    priority: 3,
    width: 1.0,
    printable: true,
    showThumbnail: false,
    isPrimarySort: false,
    isDescending: true,
    isFilter: false,
    isPayment: false,
    inUse: false,
    formattings: [],
    restrictions: [
      {
        mimeTypes: [],
        mimeTypesDataSource: [],
        key: 6,
      },
    ],
  },
  {
    id: -19,
    systemName: 'state_name',
    name: 'state_name',
    hasMultipleValues: false,
    relationId: 0,
    typeId: 1,
    displayTypeId: 1,
    label: 'Status',
    position: 15,
    priority: 3,
    width: 1.0,
    printable: true,
    showThumbnail: false,
    isPrimarySort: false,
    isDescending: true,
    isFilter: false,
    isPayment: false,
    inUse: false,
    formattings: [],
    restrictions: [],
  },
  {
    id: -73,
    systemName: 'due_date',
    name: 'due_date',
    hasMultipleValues: false,
    relationId: 0,
    typeId: 4,
    displayTypeId: 1,
    label: 'Due Date',
    printable: false,
    showThumbnail: false,
    isPrimarySort: false,
    isDescending: false,
    isFilter: true,
    isPayment: false,
    inUse: false,
    formattings: [],
    restrictions: [],
  },
  {
    id: -72,
    systemName: 'contract_days_late',
    name: 'contract_days_late',
    hasMultipleValues: false,
    relationId: 0,
    typeId: 2,
    displayTypeId: 1,
    label: 'Contract Days Late',
    printable: false,
    showThumbnail: false,
    isPrimarySort: false,
    isDescending: false,
    isFilter: true,
    isPayment: false,
    inUse: false,
    formattings: [],
    restrictions: [],
  },
  {
    id: -38,
    systemName: 'formula: 1 if ag_resp_ds>ds_op and [%near_overdue]/100*ag_resp_ds<ds_op',
    name: 'near_overdue',
    hasMultipleValues: false,
    relationId: 0,
    typeId: 3,
    displayTypeId: 5,
    label: 'Near Overdue',
    printable: false,
    showThumbnail: false,
    isPrimarySort: false,
    isDescending: false,
    isFilter: true,
    isPayment: false,
    inUse: false,
    formattings: [],
    restrictions: [],
  },
  {
    id: -37,
    systemName: 'formula: 1 if agency_response_days < days_opened',
    name: 'overdue',
    hasMultipleValues: false,
    relationId: 0,
    typeId: 3,
    displayTypeId: 5,
    label: 'Overdue',
    printable: false,
    showThumbnail: false,
    isPrimarySort: false,
    isDescending: false,
    isFilter: true,
    isPayment: false,
    inUse: false,
    formattings: [],
    restrictions: [],
  },
  {
    id: -25,
    systemName: 'formula: permission to do something',
    name: 'in_my_court',
    hasMultipleValues: false,
    relationId: 0,
    typeId: 3,
    displayTypeId: 5,
    label: 'In my court',
    printable: false,
    showThumbnail: false,
    isPrimarySort: false,
    isDescending: false,
    isFilter: true,
    isPayment: false,
    inUse: false,
    formattings: [],
    restrictions: [],
  },
  {
    id: -20,
    systemName: 'default_name',
    name: 'state_type',
    hasMultipleValues: false,
    dataSourceId: -11,
    relationId: 0,
    typeId: 1,
    displayTypeId: 3,
    label: 'Status Type',
    printable: false,
    showThumbnail: false,
    isPrimarySort: false,
    isDescending: false,
    isFilter: true,
    isPayment: false,
    inUse: false,
    formattings: [],
    restrictions: [],
  },
];
export const DISPLAY_TYPES_RESPONSE = {
  records: 3,
  data: [
    {
      entity_instance_id: 66063,
      doc_num_increment: 3,
      full_doc_num: 'disptypes-003',
      is_draft: false,
      is_current: true,
      revision: 0,
      per_contract: true,
      created_by: 100174,
      updated_on: '2024-03-11T00:00:00',
      created_by_role_id: 1,
      created_on: '2024-03-11T09:25:04.753',
      initiator: 'Adrian Deac',
      initiator_role: 'AD',
      updated_by: 'Adrian Deac',
      assignee: '-',
      assigneerole: 'UA',
      assigneeorganization: 'CIPO Admin',
      date_submitted: '2024-03-11T09:25:04.757',
      contract_module_type_id: -1,
      daysopened: 1,
      ballincourt: null,
      ballincourtrole: null,
      state_name: 'Open',
      state_color: '#0091ff',
      state_text_color: '#fff',
      state_type: 'Open',
      current_state_id: 337,
      closed_on: null,
      due_date: '2024-04-10T00:00:00',
      entity_id: 67,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 64,
      needs_signed: false,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: false,
      project_manager: null,
      near_overdue: 'False',
      overdue: 'False',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 266,
      screen_name: 'Display types default Start Screen',
      '-2': null,
      '-2_sort': null,
      '622': null,
      '622_sort': null,
      '623': null,
      '623_sort': null,
      '639': null,
      '639_sort': null,
      '625': 'Cc',
      '625_sort': 'Cc',
      '624': 'CIP',
      '624_sort': 'CIP',
      '629': '33.00%',
      '629_sort': '000000000000000000000000000000000000000033.0000000',
      '628': '$33.00',
      '628_sort': '000000000000000000000000000000000000000033.0000000',
      '627': '33.00',
      '627_sort': '000000000000000000000000000000000000000033.0000000',
      '626': '33',
      '626_sort': '000000000000000000000000000000000000000033.0000000',
      '633': '2024-03-11T09:24:00Z',
      '633_sort': '2024-03-11T09:24:00Z',
      '632': '2024-03-01T09:24:00Z',
      '632_sort': '2024-03-01T09:24:00Z',
      '631': '2024-03-01T00:00:00Z',
      '631_sort': '2024-03-01T00:00:00Z',
      '634': null,
      '634_sort': null,
      '-19': null,
      '-19_sort': null,
    },
    {
      entity_instance_id: 66062,
      doc_num_increment: 2,
      full_doc_num: 'disptypes-002',
      is_draft: false,
      is_current: true,
      revision: 0,
      per_contract: true,
      created_by: 100174,
      updated_on: '2024-03-11T00:00:00',
      created_by_role_id: 1,
      created_on: '2024-03-11T09:22:39.66',
      initiator: 'Adrian Deac',
      initiator_role: 'AD',
      updated_by: 'Adrian Deac',
      assignee: '-',
      assigneerole: 'UA',
      assigneeorganization: 'CIPO Admin',
      date_submitted: '2024-03-11T09:22:39.663',
      contract_module_type_id: -1,
      daysopened: 1,
      ballincourt: null,
      ballincourtrole: null,
      state_name: 'Open',
      state_color: '#0091ff',
      state_text_color: '#fff',
      state_type: 'Open',
      current_state_id: 337,
      closed_on: null,
      due_date: '2024-04-10T00:00:00',
      entity_id: 67,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 64,
      needs_signed: false,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: false,
      project_manager: null,
      near_overdue: 'False',
      overdue: 'False',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 266,
      screen_name: 'Display types default Start Screen',
      '-2': null,
      '-2_sort': null,
      '622':
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      '622_sort':
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      '623':
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. \n\nLorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. \n\nIt was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      '623_sort':
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. \n\nLorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. \n\nIt was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      '639':
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      '639_sort':
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      '625': 'Bcc',
      '625_sort': 'Bcc',
      '624': 'Acquisition',
      '624_sort': 'Acquisition',
      '629': null,
      '629_sort': null,
      '628': '$55.00',
      '628_sort': '000000000000000000000000000000000000000055.0000000',
      '627': '23.00',
      '627_sort': '000000000000000000000000000000000000000023.0000000',
      '626': null,
      '626_sort': null,
      '633': '2024-03-11T09:20:00Z',
      '633_sort': '2024-03-11T09:20:00Z',
      '632': '2024-03-03T09:22:00Z',
      '632_sort': '2024-03-03T09:22:00Z',
      '631': '2024-03-02T00:00:00Z',
      '631_sort': '2024-03-02T00:00:00Z',
      '634': null,
      '634_sort': null,
      '-19': null,
      '-19_sort': null,
    },
    {
      entity_instance_id: 66061,
      doc_num_increment: 1,
      full_doc_num: 'disptypes-001',
      is_draft: false,
      is_current: true,
      revision: 0,
      per_contract: true,
      created_by: 100174,
      updated_on: '2024-03-11T00:00:00',
      created_by_role_id: 1,
      created_on: '2024-03-11T09:11:24.467',
      initiator: 'Adrian Deac',
      initiator_role: 'AD',
      updated_by: 'Adrian Deac',
      assignee: '-',
      assigneerole: 'UA',
      assigneeorganization: 'CIPO Admin',
      date_submitted: '2024-03-11T09:11:24.52',
      contract_module_type_id: -1,
      daysopened: 1,
      ballincourt: null,
      ballincourtrole: null,
      state_name: 'Open',
      state_color: '#0091ff',
      state_text_color: '#fff',
      state_type: 'Open',
      current_state_id: 337,
      closed_on: null,
      due_date: '2024-04-10T00:00:00',
      entity_id: 67,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 64,
      needs_signed: false,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: false,
      project_manager: null,
      near_overdue: 'False',
      overdue: 'False',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 266,
      screen_name: 'Display types default Start Screen',
      '-2': null,
      '-2_sort': null,
      '622': 'some text',
      '622_sort': 'some text',
      '623': 'first line\nsecond line',
      '623_sort': 'first line\nsecond line',
      '639': 'some text',
      '639_sort': 'some text',
      '625': 'To',
      '625_sort': 'To',
      '624': 'CFD',
      '624_sort': 'CFD',
      '629': '56.05%',
      '629_sort': '000000000000000000000000000000000000000056.0500000',
      '628': '$12.04',
      '628_sort': '000000000000000000000000000000000000000012.0400000',
      '627': '11.23',
      '627_sort': '000000000000000000000000000000000000000011.2300000',
      '626': '4',
      '626_sort': '000000000000000000000000000000000000000004.0000000',
      '633': '2024-03-11T09:12:00Z',
      '633_sort': '2024-03-11T09:12:00Z',
      '632': '2024-03-04T09:10:00Z',
      '632_sort': '2024-03-04T09:10:00Z',
      '631': '2024-03-03T00:00:00Z',
      '631_sort': '2024-03-03T00:00:00Z',
      '634': 'emwd.png',
      '634_sort': 'emwd.png',
      '-19': null,
      '-19_sort': null,
    },
  ],
};

export const MOCK_FIELDS: DataListField[] = [
  {
    id: -2,
    systemName: 'full_doc_num',
    name: 'full_doc_num',
    hasMultipleValues: false,
    relationId: 0,
    typeId: 1,
    displayTypeId: 1,
    label: '#',
    position: 1,
    priority: 3,
    width: 1.0,
    printable: true,
    showThumbnail: false,
    isPrimarySort: true,
    isDescending: true,
    isFilter: false,
    isPayment: false,
    inUse: false,
    formattings: [],
    restrictions: [],
  },
  {
    id: 93,
    name: 'Description',
    hasMultipleValues: false,
    relationId: 0,
    typeId: 1,
    displayTypeId: 9,
    label: 'Description',
    position: 2,
    priority: 2,
    width: 3.0,
    printable: true,
    showThumbnail: false,
    isPrimarySort: false,
    isDescending: true,
    isFilter: false,
    isPayment: false,
    inUse: false,
    formattings: [],
    restrictions: [],
  },
  {
    id: 189,
    name: 'Section #',
    hasMultipleValues: false,
    dataSourceId: 22,
    relationId: 0,
    typeId: 1,
    displayTypeId: 3,
    label: 'Section #',
    position: 3,
    priority: 0,
    width: 1.0,
    printable: true,
    showThumbnail: false,
    isPrimarySort: false,
    isDescending: true,
    isFilter: false,
    isPayment: false,
    inUse: false,
    formattings: [],
    restrictions: [],
  },
  {
    id: 225,
    name: 'We are transmitting the following',
    hasMultipleValues: false,
    dataSourceId: 13,
    relationId: 0,
    typeId: 1,
    displayTypeId: 4,
    label: 'Transmitted',
    position: 4,
    priority: 0,
    width: 1.0,
    printable: true,
    showThumbnail: false,
    isPrimarySort: false,
    isDescending: true,
    isFilter: false,
    isPayment: false,
    inUse: false,
    formattings: [],
    restrictions: [],
  },
  {
    id: -19,
    systemName: 'state_name',
    name: 'state_name',
    hasMultipleValues: false,
    relationId: 0,
    typeId: 1,
    displayTypeId: 1,
    label: 'Status',
    position: 5,
    priority: 3,
    width: 1.0,
    printable: true,
    showThumbnail: false,
    isPrimarySort: false,
    isDescending: true,
    isFilter: false,
    isPayment: false,
    inUse: false,
    formattings: [],
    restrictions: [],
  },
  {
    id: -6,
    systemName: 'formula: closed_on - opened_on',
    name: 'daysopened',
    hasMultipleValues: false,
    relationId: 0,
    typeId: 2,
    displayTypeId: 1,
    label: 'Days Opened',
    position: 6,
    priority: 2,
    width: 1.0,
    printable: true,
    showThumbnail: false,
    isPrimarySort: false,
    isDescending: true,
    isFilter: false,
    isPayment: false,
    inUse: false,
    formattings: [
      {
        key: 2,
        value: 0,
      },
    ],
    restrictions: [
      {
        mimeTypes: [],
        mimeTypesDataSource: [],
        key: 4,
        value: 2,
      },
      {
        mimeTypes: [],
        mimeTypesDataSource: [],
        key: 7,
      },
    ],
  },
  {
    id: -10,
    systemName: 'abbr',
    name: 'ballincourtrole',
    hasMultipleValues: false,
    relationId: 0,
    typeId: 1,
    displayTypeId: 1,
    label: 'Ball In Court',
    position: 7,
    priority: 2,
    width: 1.0,
    printable: true,
    showThumbnail: false,
    isPrimarySort: false,
    isDescending: true,
    isFilter: false,
    isPayment: false,
    inUse: false,
    formattings: [],
    restrictions: [],
  },
  {
    id: -73,
    systemName: 'due_date',
    name: 'due_date',
    hasMultipleValues: false,
    relationId: 0,
    typeId: 4,
    displayTypeId: 1,
    label: 'Due Date',
    printable: false,
    showThumbnail: false,
    isPrimarySort: false,
    isDescending: false,
    isFilter: true,
    isPayment: false,
    inUse: false,
    formattings: [],
    restrictions: [],
  },
  {
    id: -72,
    systemName: 'contract_days_late',
    name: 'contract_days_late',
    hasMultipleValues: false,
    relationId: 0,
    typeId: 2,
    displayTypeId: 1,
    label: 'Contract Days Late',
    printable: false,
    showThumbnail: false,
    isPrimarySort: false,
    isDescending: false,
    isFilter: true,
    isPayment: false,
    inUse: false,
    formattings: [],
    restrictions: [],
  },
  {
    id: -38,
    systemName: 'formula: 1 if ag_resp_ds>ds_op and [%near_overdue]/100*ag_resp_ds<ds_op',
    name: 'near_overdue',
    hasMultipleValues: false,
    relationId: 0,
    typeId: 3,
    displayTypeId: 5,
    label: 'Near Overdue',
    printable: false,
    showThumbnail: false,
    isPrimarySort: false,
    isDescending: false,
    isFilter: true,
    isPayment: false,
    inUse: false,
    formattings: [],
    restrictions: [],
  },
  {
    id: -37,
    systemName: 'formula: 1 if agency_response_days < days_opened',
    name: 'overdue',
    hasMultipleValues: false,
    relationId: 0,
    typeId: 3,
    displayTypeId: 5,
    label: 'Overdue',
    printable: false,
    showThumbnail: false,
    isPrimarySort: false,
    isDescending: false,
    isFilter: true,
    isPayment: false,
    inUse: false,
    formattings: [],
    restrictions: [],
  },
  {
    id: -25,
    systemName: 'formula: permission to do something',
    name: 'in_my_court',
    hasMultipleValues: false,
    relationId: 0,
    typeId: 3,
    displayTypeId: 5,
    label: 'In my court',
    printable: false,
    showThumbnail: false,
    isPrimarySort: false,
    isDescending: false,
    isFilter: true,
    isPayment: false,
    inUse: false,
    formattings: [],
    restrictions: [],
  },
  {
    id: -20,
    systemName: 'default_name',
    name: 'state_type',
    hasMultipleValues: false,
    dataSourceId: -11,
    relationId: 0,
    typeId: 1,
    displayTypeId: 3,
    label: 'Status Type',
    printable: false,
    showThumbnail: false,
    isPrimarySort: false,
    isDescending: false,
    isFilter: true,
    isPayment: false,
    inUse: false,
    formattings: [],
    restrictions: [],
  },
];
export const MOCK_RESPONSE = {
  records: 50,
  data: [
    {
      entity_instance_id: 64430,
      doc_num_increment: 247,
      full_doc_num: '247.1',
      is_draft: 0,
      is_current: 1,
      revision: 0,
      per_contract: true,
      created_by: 118,
      updated_on: '2023-12-14T00:00:00',
      created_by_role_id: 125,
      created_on: '2023-12-14T17:23:36.63',
      initiator: 'Jennifer Morgenstern',
      initiator_role: 'GC',
      updated_by: 'Jennifer Morgenstern',
      assignee: null,
      assigneerole: null,
      assigneeorganization: 'EMWD',
      date_submitted: '2023-12-14T17:23:44.213',
      contract_module_type_id: -1,
      daysopened: 0,
      ballincourt: null,
      ballincourtrole: null,
      state_name: 'AF',
      state_color: '#064a80',
      state_text_color: '#ffffff',
      state_type: 'Approved',
      current_state_id: 122,
      closed_on: '2023-12-14T00:00:00',
      due_date: '2023-12-28T00:00:00',
      entity_id: 24,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 18,
      needs_signed: 0,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: 0,
      project_manager: null,
      near_overdue: 'False',
      overdue: 'False',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 69,
      screen_name: 'Non AIS Submittals',
      '-2': null,
      '-2_sort': null,
      '93': 'Design Consultant - Spec. 1502S',
      '93_sort': 'Design Consultant - Spec. 1502S',
      '189': '01531',
      '189_sort': '01531',
      '225': 'Material Submittal',
      '225_sort': 'Material Submittal',
      '-19': null,
      '-19_sort': null,
      '-6': null,
      '-6_sort': null,
      '-10': null,
      '-10_sort': null,
    },
    {
      entity_instance_id: 63984,
      doc_num_increment: 246,
      full_doc_num: '246.1',
      is_draft: 0,
      is_current: 1,
      revision: 0,
      per_contract: true,
      created_by: 118,
      updated_on: '2023-12-07T00:00:00',
      created_by_role_id: 125,
      created_on: '2023-12-07T21:52:45.93',
      initiator: 'Jennifer Morgenstern',
      initiator_role: 'GC',
      updated_by: 'Jennifer Morgenstern',
      assignee: null,
      assigneerole: null,
      assigneeorganization: 'EMWD',
      date_submitted: '2023-12-07T21:52:51.59',
      contract_module_type_id: -1,
      daysopened: 0,
      ballincourt: null,
      ballincourtrole: null,
      state_name: 'AF',
      state_color: '#064a80',
      state_text_color: '#ffffff',
      state_type: 'Approved',
      current_state_id: 122,
      closed_on: '2023-12-07T00:00:00',
      due_date: '2023-12-21T00:00:00',
      entity_id: 24,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 18,
      needs_signed: 0,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: 0,
      project_manager: null,
      near_overdue: 'False',
      overdue: 'False',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 69,
      screen_name: 'Non AIS Submittals',
      '-2': null,
      '-2_sort': null,
      '93': 'Training DC - 1502S',
      '93_sort': 'Training DC - 1502S',
      '189': '01531',
      '189_sort': '01531',
      '225': 'Shop Drawing(s)',
      '225_sort': 'Shop Drawing(s)',
      '-19': null,
      '-19_sort': null,
      '-6': null,
      '-6_sort': null,
      '-10': null,
      '-10_sort': null,
    },
    {
      entity_instance_id: 63440,
      doc_num_increment: 245,
      full_doc_num: '245.1',
      is_draft: 0,
      is_current: 1,
      revision: 0,
      per_contract: true,
      created_by: 99,
      updated_on: '2023-11-29T00:00:00',
      created_by_role_id: 125,
      created_on: '2023-11-29T14:27:14.593',
      initiator: 'Amy Eichperger',
      initiator_role: 'GC',
      updated_by: 'Amy Eichperger',
      assignee: null,
      assigneerole: null,
      assigneeorganization: 'EMWD',
      date_submitted: '2023-11-29T14:32:41.04',
      contract_module_type_id: -1,
      daysopened: 0,
      ballincourt: null,
      ballincourtrole: null,
      state_name: 'A',
      state_color: '#064a80',
      state_text_color: '#ffffff',
      state_type: 'Approved',
      current_state_id: 123,
      closed_on: '2023-11-29T00:00:00',
      due_date: '2023-12-13T00:00:00',
      entity_id: 24,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 18,
      needs_signed: 0,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: 0,
      project_manager: null,
      near_overdue: 'False',
      overdue: 'False',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 69,
      screen_name: 'Non AIS Submittals',
      '-2': null,
      '-2_sort': null,
      '93': 'Test',
      '93_sort': 'Test',
      '189': '00000',
      '189_sort': '00000',
      '225': 'Contract Document',
      '225_sort': 'Contract Document',
      '-19': null,
      '-19_sort': null,
      '-6': null,
      '-6_sort': null,
      '-10': null,
      '-10_sort': null,
    },
    {
      entity_instance_id: 63208,
      doc_num_increment: 244,
      full_doc_num: '244.1',
      is_draft: 0,
      is_current: 1,
      revision: 0,
      per_contract: true,
      created_by: 118,
      updated_on: '2023-11-27T00:00:00',
      created_by_role_id: 125,
      created_on: '2023-11-27T17:25:59.203',
      initiator: 'Jennifer Morgenstern',
      initiator_role: 'GC',
      updated_by: 'Jennifer Morgenstern',
      assignee: null,
      assigneerole: null,
      assigneeorganization: 'EMWD',
      date_submitted: '2023-11-27T17:26:04.607',
      contract_module_type_id: -1,
      daysopened: 0,
      ballincourt: null,
      ballincourtrole: null,
      state_name: 'BF',
      state_color: '#064a80',
      state_text_color: '#ffffff',
      state_type: 'Approved',
      current_state_id: 120,
      closed_on: '2023-11-27T00:00:00',
      due_date: '2023-12-11T00:00:00',
      entity_id: 24,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 18,
      needs_signed: 0,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: 0,
      project_manager: null,
      near_overdue: 'False',
      overdue: 'False',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 69,
      screen_name: 'Non AIS Submittals',
      '-2': null,
      '-2_sort': null,
      '93': 'Training new Engineer',
      '93_sort': 'Training new Engineer',
      '189': '01600',
      '189_sort': '01600',
      '225': 'Shop Drawing(s)',
      '225_sort': 'Shop Drawing(s)',
      '-19': null,
      '-19_sort': null,
      '-6': null,
      '-6_sort': null,
      '-10': null,
      '-10_sort': null,
    },
    {
      entity_instance_id: 62621,
      doc_num_increment: 243,
      full_doc_num: '243.1',
      is_draft: 0,
      is_current: 1,
      revision: 0,
      per_contract: true,
      created_by: 118,
      updated_on: '2023-11-16T00:00:00',
      created_by_role_id: 124,
      created_on: '2023-11-16T18:56:08.677',
      initiator: 'Jennifer Morgenstern',
      initiator_role: 'CA',
      updated_by: 'Jennifer Morgenstern',
      assignee: '-',
      assigneerole: 'UA',
      assigneeorganization: 'EMWD',
      date_submitted: '2023-11-16T18:56:44.95',
      contract_module_type_id: -1,
      daysopened: 113,
      ballincourt: 'EMWD',
      ballincourtrole: 'CA',
      state_name: 'In Review',
      state_color: '#0091ff',
      state_text_color: '#ffffff',
      state_type: 'Open',
      current_state_id: 116,
      closed_on: null,
      due_date: '2023-11-30T00:00:00',
      entity_id: 24,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 18,
      needs_signed: 0,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: 0,
      project_manager: null,
      near_overdue: 'True',
      overdue: 'True',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 69,
      screen_name: 'Non AIS Submittals',
      '-2': null,
      '-2_sort': null,
      '93': 'Training DC RFP 3623',
      '93_sort': 'Training DC RFP 3623',
      '189': '01190',
      '189_sort': '01190',
      '225': 'Material Submittal',
      '225_sort': 'Material Submittal',
      '-19': null,
      '-19_sort': null,
      '-6': null,
      '-6_sort': null,
      '-10': null,
      '-10_sort': null,
    },
    {
      entity_instance_id: 62318,
      doc_num_increment: 242,
      full_doc_num: '242.1',
      is_draft: 0,
      is_current: 1,
      revision: 0,
      per_contract: true,
      created_by: 118,
      updated_on: '2023-11-14T00:00:00',
      created_by_role_id: 125,
      created_on: '2023-11-14T15:18:11.073',
      initiator: 'Jennifer Morgenstern',
      initiator_role: 'GC',
      updated_by: 'Jennifer Morgenstern',
      assignee: '-',
      assigneerole: 'UA',
      assigneeorganization: 'EMWD',
      date_submitted: '2023-11-14T15:18:18.21',
      contract_module_type_id: -1,
      daysopened: 115,
      ballincourt: 'EMWD',
      ballincourtrole: 'CA',
      state_name: 'In Review',
      state_color: '#0091ff',
      state_text_color: '#ffffff',
      state_type: 'Open',
      current_state_id: 116,
      closed_on: null,
      due_date: '2023-11-28T00:00:00',
      entity_id: 24,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 18,
      needs_signed: 0,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: 0,
      project_manager: null,
      near_overdue: 'True',
      overdue: 'True',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 69,
      screen_name: 'Non AIS Submittals',
      '-2': null,
      '-2_sort': null,
      '93': 'Consultant Inspectors',
      '93_sort': 'Consultant Inspectors',
      '189': '01600',
      '189_sort': '01600',
      '225': 'Material Submittal',
      '225_sort': 'Material Submittal',
      '-19': null,
      '-19_sort': null,
      '-6': null,
      '-6_sort': null,
      '-10': null,
      '-10_sort': null,
    },
    {
      entity_instance_id: 61998,
      doc_num_increment: 241,
      full_doc_num: '241.1',
      is_draft: 0,
      is_current: 1,
      revision: 0,
      per_contract: true,
      created_by: 118,
      updated_on: '2023-11-08T00:00:00',
      created_by_role_id: 125,
      created_on: '2023-11-08T21:19:32.44',
      initiator: 'Jennifer Morgenstern',
      initiator_role: 'GC',
      updated_by: 'Jennifer Morgenstern',
      assignee: null,
      assigneerole: null,
      assigneeorganization: 'EMWD',
      date_submitted: '2023-11-08T21:19:36.663',
      contract_module_type_id: -1,
      daysopened: 0,
      ballincourt: 'CIPO Cloud',
      ballincourtrole: 'GC',
      state_name: 'CFR',
      state_color: '#990000',
      state_text_color: '#ffffff',
      state_type: 'Approved',
      current_state_id: 125,
      closed_on: '2023-11-08T00:00:00',
      due_date: '2023-11-22T00:00:00',
      entity_id: 24,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 18,
      needs_signed: 0,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: 0,
      project_manager: null,
      near_overdue: 'False',
      overdue: 'False',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 69,
      screen_name: 'Non AIS Submittals',
      '-2': null,
      '-2_sort': null,
      '93': 'Cnslt Review - 1484S-1490W',
      '93_sort': 'Cnslt Review - 1484S-1490W',
      '189': '01381',
      '189_sort': '01381',
      '225': 'Shop Drawing(s)',
      '225_sort': 'Shop Drawing(s)',
      '-19': null,
      '-19_sort': null,
      '-6': null,
      '-6_sort': null,
      '-10': null,
      '-10_sort': null,
    },
    {
      entity_instance_id: 61853,
      doc_num_increment: 240,
      full_doc_num: '240.1',
      is_draft: 0,
      is_current: 1,
      revision: 0,
      per_contract: true,
      created_by: 118,
      updated_on: '2023-11-07T00:00:00',
      created_by_role_id: 125,
      created_on: '2023-11-06T20:42:41.827',
      initiator: 'Jennifer Morgenstern',
      initiator_role: 'GC',
      updated_by: 'Jennifer Morgenstern',
      assignee: null,
      assigneerole: null,
      assigneeorganization: 'EMWD',
      date_submitted: '2023-11-06T20:42:47.44',
      contract_module_type_id: -1,
      daysopened: 1,
      ballincourt: 'CIPO Cloud',
      ballincourtrole: 'GC',
      state_name: 'CFR',
      state_color: '#990000',
      state_text_color: '#ffffff',
      state_type: 'Approved',
      current_state_id: 125,
      closed_on: '2023-11-07T00:00:00',
      due_date: '2023-11-20T00:00:00',
      entity_id: 24,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 18,
      needs_signed: 0,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: 0,
      project_manager: null,
      near_overdue: 'False',
      overdue: 'False',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 69,
      screen_name: 'Non AIS Submittals',
      '-2': null,
      '-2_sort': null,
      '93': 'Cnslt CA Review - 1508W',
      '93_sort': 'Cnslt CA Review - 1508W',
      '189': '01600',
      '189_sort': '01600',
      '225': 'Material Submittal',
      '225_sort': 'Material Submittal',
      '-19': null,
      '-19_sort': null,
      '-6': null,
      '-6_sort': null,
      '-10': null,
      '-10_sort': null,
    },
    {
      entity_instance_id: 60006,
      doc_num_increment: 239,
      full_doc_num: '239.1',
      is_draft: 0,
      is_current: 1,
      revision: 0,
      per_contract: true,
      created_by: 118,
      updated_on: '2023-10-31T00:00:00',
      created_by_role_id: 125,
      created_on: '2023-10-12T13:50:13.93',
      initiator: 'Jennifer Morgenstern',
      initiator_role: 'GC',
      updated_by: 'Laura Lengyel',
      assignee: null,
      assigneerole: null,
      assigneeorganization: 'EMWD',
      date_submitted: '2023-10-12T13:50:24.007',
      contract_module_type_id: -1,
      daysopened: 19,
      ballincourt: null,
      ballincourtrole: null,
      state_name: 'A',
      state_color: '#064a80',
      state_text_color: '#ffffff',
      state_type: 'Approved',
      current_state_id: 123,
      closed_on: '2023-10-31T00:00:00',
      due_date: '2023-10-26T00:00:00',
      entity_id: 24,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 18,
      needs_signed: 0,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: 0,
      project_manager: null,
      near_overdue: 'False',
      overdue: 'False',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 69,
      screen_name: 'Non AIS Submittals',
      '-2': null,
      '-2_sort': null,
      '93': 'Training for new Inspector',
      '93_sort': 'Training for new Inspector',
      '189': '01600',
      '189_sort': '01600',
      '225': 'Material Submittal',
      '225_sort': 'Material Submittal',
      '-19': null,
      '-19_sort': null,
      '-6': null,
      '-6_sort': null,
      '-10': null,
      '-10_sort': null,
    },
    {
      entity_instance_id: 59701,
      doc_num_increment: 238,
      full_doc_num: '238.1',
      is_draft: 0,
      is_current: 1,
      revision: 0,
      per_contract: true,
      created_by: 118,
      updated_on: '2023-10-20T00:00:00',
      created_by_role_id: 125,
      created_on: '2023-10-06T19:57:25.883',
      initiator: 'Jennifer Morgenstern',
      initiator_role: 'GC',
      updated_by: 'Jennifer Morgenstern',
      assignee: null,
      assigneerole: null,
      assigneeorganization: 'EMWD',
      date_submitted: '2023-10-06T19:57:54.15',
      contract_module_type_id: -1,
      daysopened: 0,
      ballincourt: null,
      ballincourtrole: null,
      state_name: 'BF',
      state_color: '#064a80',
      state_text_color: '#ffffff',
      state_type: 'Approved',
      current_state_id: 120,
      closed_on: '2023-10-06T00:00:00',
      due_date: '2023-10-20T00:00:00',
      entity_id: 24,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 18,
      needs_signed: 0,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: 0,
      project_manager: null,
      near_overdue: 'False',
      overdue: 'False',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 69,
      screen_name: 'Non AIS Submittals',
      '-2': null,
      '-2_sort': null,
      '93': 'Design Consultant Training',
      '93_sort': 'Design Consultant Training',
      '189': '01050',
      '189_sort': '01050',
      '225': 'Shop Drawing(s)',
      '225_sort': 'Shop Drawing(s)',
      '-19': null,
      '-19_sort': null,
      '-6': null,
      '-6_sort': null,
      '-10': null,
      '-10_sort': null,
    },
    {
      entity_instance_id: 51891,
      doc_num_increment: 237,
      full_doc_num: '237.1',
      is_draft: 0,
      is_current: 1,
      revision: 0,
      per_contract: true,
      created_by: 118,
      updated_on: '2023-07-25T00:00:00',
      created_by_role_id: 125,
      created_on: '2023-07-25T19:51:44.93',
      initiator: 'Jennifer Morgenstern',
      initiator_role: 'GC',
      updated_by: 'Jennifer Morgenstern',
      assignee: null,
      assigneerole: null,
      assigneeorganization: 'EMWD',
      date_submitted: '2023-07-25T19:51:51.757',
      contract_module_type_id: -1,
      daysopened: 0,
      ballincourt: null,
      ballincourtrole: null,
      state_name: 'AF',
      state_color: '#064a80',
      state_text_color: '#ffffff',
      state_type: 'Approved',
      current_state_id: 122,
      closed_on: '2023-07-25T00:00:00',
      due_date: '2023-08-08T00:00:00',
      entity_id: 24,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 18,
      needs_signed: 0,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: 0,
      project_manager: null,
      near_overdue: 'False',
      overdue: 'False',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 69,
      screen_name: 'Non AIS Submittals',
      '-2': null,
      '-2_sort': null,
      '93': 'Training for new CA 1429W',
      '93_sort': 'Training for new CA 1429W',
      '189': '01430',
      '189_sort': '01430',
      '225': 'Material Submittal',
      '225_sort': 'Material Submittal',
      '-19': null,
      '-19_sort': null,
      '-6': null,
      '-6_sort': null,
      '-10': null,
      '-10_sort': null,
    },
    {
      entity_instance_id: 49539,
      doc_num_increment: 236,
      full_doc_num: '236.1',
      is_draft: 0,
      is_current: 1,
      revision: 0,
      per_contract: true,
      created_by: 99,
      updated_on: '2023-06-21T00:00:00',
      created_by_role_id: 125,
      created_on: '2023-06-21T14:11:30',
      initiator: 'Amy Eichperger',
      initiator_role: 'GC',
      updated_by: 'Amy Eichperger',
      assignee: '-',
      assigneerole: 'UA',
      assigneeorganization: 'EMWD',
      date_submitted: '2023-06-21T14:11:50.18',
      contract_module_type_id: -1,
      daysopened: 261,
      ballincourt: null,
      ballincourtrole: null,
      state_name: 'NEW',
      state_color: '#e91e63',
      state_text_color: '#ffffff',
      state_type: 'Open',
      current_state_id: 112,
      closed_on: null,
      due_date: '2023-07-05T00:00:00',
      entity_id: 24,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 18,
      needs_signed: 0,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: 0,
      project_manager: null,
      near_overdue: 'True',
      overdue: 'True',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 69,
      screen_name: 'Non AIS Submittals',
      '-2': null,
      '-2_sort': null,
      '93': 'Test download print',
      '93_sort': 'Test download print',
      '189': '01011',
      '189_sort': '01011',
      '225': 'Material Submittal',
      '225_sort': 'Material Submittal',
      '-19': null,
      '-19_sort': null,
      '-6': null,
      '-6_sort': null,
      '-10': null,
      '-10_sort': null,
    },
    {
      entity_instance_id: 49421,
      doc_num_increment: 235,
      full_doc_num: '235.1',
      is_draft: 0,
      is_current: 1,
      revision: 0,
      per_contract: true,
      created_by: 99,
      updated_on: '2023-06-19T00:00:00',
      created_by_role_id: 125,
      created_on: '2023-06-19T18:56:28.91',
      initiator: 'Amy Eichperger',
      initiator_role: 'GC',
      updated_by: 'Amy Eichperger',
      assignee: null,
      assigneerole: null,
      assigneeorganization: 'EMWD',
      date_submitted: '2023-06-19T18:56:42.03',
      contract_module_type_id: -1,
      daysopened: 0,
      ballincourt: null,
      ballincourtrole: null,
      state_name: 'A',
      state_color: '#064a80',
      state_text_color: '#ffffff',
      state_type: 'Approved',
      current_state_id: 123,
      closed_on: '2023-06-19T00:00:00',
      due_date: '2023-07-03T00:00:00',
      entity_id: 24,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 18,
      needs_signed: 0,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: 0,
      project_manager: null,
      near_overdue: 'False',
      overdue: 'False',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 161,
      screen_name: 'AIS Material Submittals Only',
      '-2': null,
      '-2_sort': null,
      '93': 'Test AIS Printout',
      '93_sort': 'Test AIS Printout',
      '189': '01 7419',
      '189_sort': '01 7419',
      '225': 'Material Submittal',
      '225_sort': 'Material Submittal',
      '-19': null,
      '-19_sort': null,
      '-6': null,
      '-6_sort': null,
      '-10': null,
      '-10_sort': null,
    },
    {
      entity_instance_id: 34977,
      doc_num_increment: 234,
      full_doc_num: '234.1',
      is_draft: 0,
      is_current: 1,
      revision: 0,
      per_contract: true,
      created_by: 99,
      updated_on: '2023-06-19T00:00:00',
      created_by_role_id: 125,
      created_on: '2022-11-03T19:41:13.607',
      initiator: 'Amy Eichperger',
      initiator_role: 'GC',
      updated_by: 'Amy Eichperger',
      assignee: '-',
      assigneerole: 'UA',
      assigneeorganization: 'EMWD',
      date_submitted: '2023-06-19T18:51:59.563',
      contract_module_type_id: -1,
      daysopened: 263,
      ballincourt: null,
      ballincourtrole: null,
      state_name: 'NEW',
      state_color: '#e91e63',
      state_text_color: '#ffffff',
      state_type: 'Open',
      current_state_id: 112,
      closed_on: null,
      due_date: '2023-07-03T00:00:00',
      entity_id: 24,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 18,
      needs_signed: 0,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: 0,
      project_manager: null,
      near_overdue: 'True',
      overdue: 'True',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 69,
      screen_name: 'Non AIS Submittals',
      '-2': null,
      '-2_sort': null,
      '93': 'Test AIS',
      '93_sort': 'Test AIS',
      '189': '00220',
      '189_sort': '00220',
      '225': 'Material Submittal',
      '225_sort': 'Material Submittal',
      '-19': null,
      '-19_sort': null,
      '-6': null,
      '-6_sort': null,
      '-10': null,
      '-10_sort': null,
    },
    {
      entity_instance_id: 49416,
      doc_num_increment: 233,
      full_doc_num: '233.1',
      is_draft: 0,
      is_current: 1,
      revision: 0,
      per_contract: true,
      created_by: 99,
      updated_on: '2023-06-19T00:00:00',
      created_by_role_id: 125,
      created_on: '2023-06-19T18:49:25.827',
      initiator: 'Amy Eichperger',
      initiator_role: 'GC',
      updated_by: 'Amy Eichperger',
      assignee: '-',
      assigneerole: 'UA',
      assigneeorganization: 'EMWD',
      date_submitted: '2023-06-19T18:49:31.087',
      contract_module_type_id: -1,
      daysopened: 263,
      ballincourt: null,
      ballincourtrole: null,
      state_name: 'NEW',
      state_color: '#e91e63',
      state_text_color: '#ffffff',
      state_type: 'Open',
      current_state_id: 112,
      closed_on: null,
      due_date: '2023-07-03T00:00:00',
      entity_id: 24,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 18,
      needs_signed: 0,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: 0,
      project_manager: null,
      near_overdue: 'True',
      overdue: 'True',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 161,
      screen_name: 'AIS Material Submittals Only',
      '-2': null,
      '-2_sort': null,
      '93': 'Test',
      '93_sort': 'Test',
      '189': '00100',
      '189_sort': '00100',
      '225': 'Material Submittal',
      '225_sort': 'Material Submittal',
      '-19': null,
      '-19_sort': null,
      '-6': null,
      '-6_sort': null,
      '-10': null,
      '-10_sort': null,
    },
    {
      entity_instance_id: 49413,
      doc_num_increment: 232,
      full_doc_num: '232.1',
      is_draft: 0,
      is_current: 1,
      revision: 0,
      per_contract: true,
      created_by: 99,
      updated_on: '2023-06-19T00:00:00',
      created_by_role_id: 125,
      created_on: '2023-06-19T18:26:54.547',
      initiator: 'Amy Eichperger',
      initiator_role: 'GC',
      updated_by: 'Amy Eichperger',
      assignee: null,
      assigneerole: null,
      assigneeorganization: 'EMWD',
      date_submitted: '2023-06-19T18:27:22.817',
      contract_module_type_id: -1,
      daysopened: 0,
      ballincourt: null,
      ballincourtrole: null,
      state_name: 'A',
      state_color: '#064a80',
      state_text_color: '#ffffff',
      state_type: 'Approved',
      current_state_id: 123,
      closed_on: '2023-06-19T00:00:00',
      due_date: '2023-07-03T00:00:00',
      entity_id: 24,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 18,
      needs_signed: 0,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: 0,
      project_manager: null,
      near_overdue: 'False',
      overdue: 'False',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 161,
      screen_name: 'AIS Material Submittals Only',
      '-2': null,
      '-2_sort': null,
      '93': 'Test AIS Certificate',
      '93_sort': 'Test AIS Certificate',
      '189': '01312',
      '189_sort': '01312',
      '225': 'Material Submittal',
      '225_sort': 'Material Submittal',
      '-19': null,
      '-19_sort': null,
      '-6': null,
      '-6_sort': null,
      '-10': null,
      '-10_sort': null,
    },
    {
      entity_instance_id: 49043,
      doc_num_increment: 231,
      full_doc_num: '231.1',
      is_draft: 0,
      is_current: 1,
      revision: 0,
      per_contract: true,
      created_by: 118,
      updated_on: '2023-06-14T00:00:00',
      created_by_role_id: 125,
      created_on: '2023-06-14T15:51:48.153',
      initiator: 'Jennifer Morgenstern',
      initiator_role: 'GC',
      updated_by: 'Jennifer Morgenstern',
      assignee: '-',
      assigneerole: 'UA',
      assigneeorganization: 'EMWD',
      date_submitted: '2023-06-14T15:51:53.27',
      contract_module_type_id: -1,
      daysopened: 268,
      ballincourt: 'EMWD',
      ballincourtrole: 'CA',
      state_name: 'In Review',
      state_color: '#0091ff',
      state_text_color: '#ffffff',
      state_type: 'Open',
      current_state_id: 116,
      closed_on: null,
      due_date: '2023-06-28T00:00:00',
      entity_id: 24,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 18,
      needs_signed: 0,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: 0,
      project_manager: null,
      near_overdue: 'True',
      overdue: 'True',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 69,
      screen_name: 'Non AIS Submittals',
      '-2': null,
      '-2_sort': null,
      '93': 'Enter title of Submittal',
      '93_sort': 'Enter title of Submittal',
      '189': '01400',
      '189_sort': '01400',
      '225': 'Material Submittal',
      '225_sort': 'Material Submittal',
      '-19': null,
      '-19_sort': null,
      '-6': null,
      '-6_sort': null,
      '-10': null,
      '-10_sort': null,
    },
    {
      entity_instance_id: 48364,
      doc_num_increment: 230,
      full_doc_num: '230.1',
      is_draft: 0,
      is_current: 1,
      revision: 0,
      per_contract: true,
      created_by: 118,
      updated_on: '2023-06-05T00:00:00',
      created_by_role_id: 124,
      created_on: '2023-06-05T17:37:09.18',
      initiator: 'Jennifer Morgenstern',
      initiator_role: 'CA',
      updated_by: 'Jennifer Morgenstern',
      assignee: '-',
      assigneerole: 'UA',
      assigneeorganization: 'EMWD',
      date_submitted: '2023-06-05T17:37:41.477',
      contract_module_type_id: -1,
      daysopened: 277,
      ballincourt: 'EMWD',
      ballincourtrole: 'CA',
      state_name: 'In Review',
      state_color: '#0091ff',
      state_text_color: '#ffffff',
      state_type: 'Open',
      current_state_id: 116,
      closed_on: null,
      due_date: '2023-06-19T00:00:00',
      entity_id: 24,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 18,
      needs_signed: 0,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: 0,
      project_manager: null,
      near_overdue: 'True',
      overdue: 'True',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 69,
      screen_name: 'Non AIS Submittals',
      '-2': null,
      '-2_sort': null,
      '93': 'CIPO Training CDM Smith',
      '93_sort': 'CIPO Training CDM Smith',
      '189': '01140',
      '189_sort': '01140',
      '225': 'Material Submittal',
      '225_sort': 'Material Submittal',
      '-19': null,
      '-19_sort': null,
      '-6': null,
      '-6_sort': null,
      '-10': null,
      '-10_sort': null,
    },
    {
      entity_instance_id: 47494,
      doc_num_increment: 229,
      full_doc_num: '229.1',
      is_draft: 0,
      is_current: 1,
      revision: 0,
      per_contract: true,
      created_by: 118,
      updated_on: '2023-05-19T00:00:00',
      created_by_role_id: 125,
      created_on: '2023-05-19T17:08:45.357',
      initiator: 'Jennifer Morgenstern',
      initiator_role: 'GC',
      updated_by: 'Jennifer Morgenstern',
      assignee: null,
      assigneerole: null,
      assigneeorganization: 'EMWD',
      date_submitted: '2023-05-19T17:08:50.737',
      contract_module_type_id: -1,
      daysopened: 0,
      ballincourt: null,
      ballincourtrole: null,
      state_name: 'BF',
      state_color: '#064a80',
      state_text_color: '#ffffff',
      state_type: 'Approved',
      current_state_id: 120,
      closed_on: '2023-05-19T00:00:00',
      due_date: '2023-06-02T00:00:00',
      entity_id: 24,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 18,
      needs_signed: 0,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: 0,
      project_manager: null,
      near_overdue: 'False',
      overdue: 'False',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 69,
      screen_name: 'Non AIS Submittals',
      '-2': null,
      '-2_sort': null,
      '93': "Training for 1429W CA's",
      '93_sort': "Training for 1429W CA's",
      '189': '03200',
      '189_sort': '03200',
      '225': 'Material Submittal',
      '225_sort': 'Material Submittal',
      '-19': null,
      '-19_sort': null,
      '-6': null,
      '-6_sort': null,
      '-10': null,
      '-10_sort': null,
    },
    {
      entity_instance_id: 47364,
      doc_num_increment: 228,
      full_doc_num: '228.1',
      is_draft: 0,
      is_current: 1,
      revision: 0,
      per_contract: true,
      created_by: 118,
      updated_on: '2023-05-19T00:00:00',
      created_by_role_id: 125,
      created_on: '2023-05-18T13:50:12.583',
      initiator: 'Jennifer Morgenstern',
      initiator_role: 'GC',
      updated_by: 'Jennifer Morgenstern',
      assignee: '-',
      assigneerole: 'UA',
      assigneeorganization: 'EMWD',
      date_submitted: '2023-05-18T13:50:18.863',
      contract_module_type_id: -1,
      daysopened: 295,
      ballincourt: 'EMWD',
      ballincourtrole: 'CA',
      state_name: 'In Review',
      state_color: '#0091ff',
      state_text_color: '#ffffff',
      state_type: 'Open',
      current_state_id: 116,
      closed_on: null,
      due_date: '2023-06-01T00:00:00',
      entity_id: 24,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 18,
      needs_signed: 0,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: 0,
      project_manager: null,
      near_overdue: 'True',
      overdue: 'True',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 69,
      screen_name: 'Non AIS Submittals',
      '-2': null,
      '-2_sort': null,
      '93': 'Training new Inspector',
      '93_sort': 'Training new Inspector',
      '189': '02080',
      '189_sort': '02080',
      '225': 'Material Submittal',
      '225_sort': 'Material Submittal',
      '-19': null,
      '-19_sort': null,
      '-6': null,
      '-6_sort': null,
      '-10': null,
      '-10_sort': null,
    },
    {
      entity_instance_id: 47333,
      doc_num_increment: 227,
      full_doc_num: '227.1',
      is_draft: 0,
      is_current: 1,
      revision: 0,
      per_contract: true,
      created_by: 118,
      updated_on: '2023-05-17T00:00:00',
      created_by_role_id: 125,
      created_on: '2023-05-17T20:39:25.513',
      initiator: 'Jennifer Morgenstern',
      initiator_role: 'GC',
      updated_by: 'Jennifer Morgenstern',
      assignee: null,
      assigneerole: null,
      assigneeorganization: 'EMWD',
      date_submitted: '2023-05-17T20:39:30.357',
      contract_module_type_id: -1,
      daysopened: 0,
      ballincourt: null,
      ballincourtrole: null,
      state_name: 'A',
      state_color: '#064a80',
      state_text_color: '#ffffff',
      state_type: 'Approved',
      current_state_id: 123,
      closed_on: '2023-05-17T00:00:00',
      due_date: '2023-05-31T00:00:00',
      entity_id: 24,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 18,
      needs_signed: 0,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: 0,
      project_manager: null,
      near_overdue: 'False',
      overdue: 'False',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 69,
      screen_name: 'Non AIS Submittals',
      '-2': null,
      '-2_sort': null,
      '93': 'Training for Design Consultant',
      '93_sort': 'Training for Design Consultant',
      '189': '01610',
      '189_sort': '01610',
      '225': 'Material Submittal',
      '225_sort': 'Material Submittal',
      '-19': null,
      '-19_sort': null,
      '-6': null,
      '-6_sort': null,
      '-10': null,
      '-10_sort': null,
    },
    {
      entity_instance_id: 46741,
      doc_num_increment: 226,
      full_doc_num: '226.1',
      is_draft: 0,
      is_current: 1,
      revision: 0,
      per_contract: true,
      created_by: 118,
      updated_on: '2023-05-08T00:00:00',
      created_by_role_id: 125,
      created_on: '2023-05-08T14:27:18.927',
      initiator: 'Jennifer Morgenstern',
      initiator_role: 'GC',
      updated_by: 'Jennifer Morgenstern',
      assignee: '-',
      assigneerole: 'UA',
      assigneeorganization: 'EMWD',
      date_submitted: '2023-05-08T14:27:24.823',
      contract_module_type_id: -1,
      daysopened: 305,
      ballincourt: 'EMWD',
      ballincourtrole: 'CA',
      state_name: 'In Review',
      state_color: '#0091ff',
      state_text_color: '#ffffff',
      state_type: 'Open',
      current_state_id: 116,
      closed_on: null,
      due_date: '2023-05-22T00:00:00',
      entity_id: 24,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 18,
      needs_signed: 0,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: 0,
      project_manager: null,
      near_overdue: 'True',
      overdue: 'True',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 69,
      screen_name: 'Non AIS Submittals',
      '-2': null,
      '-2_sort': null,
      '93': 'Training Submittal for Engineer',
      '93_sort': 'Training Submittal for Engineer',
      '189': '01450',
      '189_sort': '01450',
      '225': 'Material Submittal',
      '225_sort': 'Material Submittal',
      '-19': null,
      '-19_sort': null,
      '-6': null,
      '-6_sort': null,
      '-10': null,
      '-10_sort': null,
    },
    {
      entity_instance_id: 44320,
      doc_num_increment: 225,
      full_doc_num: '225.1',
      is_draft: 0,
      is_current: 1,
      revision: 0,
      per_contract: true,
      created_by: 118,
      updated_on: '2023-04-20T00:00:00',
      created_by_role_id: 125,
      created_on: '2023-04-20T13:48:57.287',
      initiator: 'Jennifer Morgenstern',
      initiator_role: 'GC',
      updated_by: 'Jennifer Morgenstern',
      assignee: '-',
      assigneerole: 'UA',
      assigneeorganization: 'EMWD',
      date_submitted: '2023-04-20T13:49:21.273',
      contract_module_type_id: -1,
      daysopened: 323,
      ballincourt: 'EMWD',
      ballincourtrole: 'CA',
      state_name: 'In Review',
      state_color: '#0091ff',
      state_text_color: '#ffffff',
      state_type: 'Open',
      current_state_id: 116,
      closed_on: null,
      due_date: '2023-05-04T00:00:00',
      entity_id: 24,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 18,
      needs_signed: 0,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: 0,
      project_manager: null,
      near_overdue: 'True',
      overdue: 'True',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 69,
      screen_name: 'Non AIS Submittals',
      '-2': null,
      '-2_sort': null,
      '93': 'Training Submittal for Inspector',
      '93_sort': 'Training Submittal for Inspector',
      '189': '01455',
      '189_sort': '01455',
      '225': 'Shop Drawing(s)',
      '225_sort': 'Shop Drawing(s)',
      '-19': null,
      '-19_sort': null,
      '-6': null,
      '-6_sort': null,
      '-10': null,
      '-10_sort': null,
    },
    {
      entity_instance_id: 42566,
      doc_num_increment: 224,
      full_doc_num: '224.1',
      is_draft: 0,
      is_current: 1,
      revision: 0,
      per_contract: true,
      created_by: 118,
      updated_on: '2023-03-21T00:00:00',
      created_by_role_id: 125,
      created_on: '2023-03-21T16:55:02.223',
      initiator: 'Jennifer Morgenstern',
      initiator_role: 'GC',
      updated_by: 'Jennifer Morgenstern',
      assignee: '-',
      assigneerole: 'UA',
      assigneeorganization: 'EMWD',
      date_submitted: '2023-03-21T16:56:12.457',
      contract_module_type_id: -1,
      daysopened: 353,
      ballincourt: 'EMWD',
      ballincourtrole: 'CA',
      state_name: 'In Review',
      state_color: '#0091ff',
      state_text_color: '#ffffff',
      state_type: 'Open',
      current_state_id: 116,
      closed_on: null,
      due_date: '2023-04-04T00:00:00',
      entity_id: 24,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 18,
      needs_signed: 0,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: 0,
      project_manager: null,
      near_overdue: 'True',
      overdue: 'True',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 69,
      screen_name: 'Non AIS Submittals',
      '-2': null,
      '-2_sort': null,
      '93': 'Submittal 1403S K&J',
      '93_sort': 'Submittal 1403S K&J',
      '189': '02513',
      '189_sort': '02513',
      '225': 'Material Submittal',
      '225_sort': 'Material Submittal',
      '-19': null,
      '-19_sort': null,
      '-6': null,
      '-6_sort': null,
      '-10': null,
      '-10_sort': null,
    },
    {
      entity_instance_id: 42141,
      doc_num_increment: 223,
      full_doc_num: '223.1',
      is_draft: 0,
      is_current: 1,
      revision: 0,
      per_contract: true,
      created_by: 118,
      updated_on: '2023-03-14T00:00:00',
      created_by_role_id: 124,
      created_on: '2023-03-14T19:36:49.527',
      initiator: 'Jennifer Morgenstern',
      initiator_role: 'CA',
      updated_by: 'Jennifer Morgenstern',
      assignee: null,
      assigneerole: null,
      assigneeorganization: 'EMWD',
      date_submitted: '2023-03-14T19:36:54.3',
      contract_module_type_id: -1,
      daysopened: 0,
      ballincourt: null,
      ballincourtrole: null,
      state_name: 'AF',
      state_color: '#064a80',
      state_text_color: '#ffffff',
      state_type: 'Approved',
      current_state_id: 122,
      closed_on: '2023-03-14T00:00:00',
      due_date: '2023-03-28T00:00:00',
      entity_id: 24,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 18,
      needs_signed: 0,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: 0,
      project_manager: null,
      near_overdue: 'False',
      overdue: 'False',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 69,
      screen_name: 'Non AIS Submittals',
      '-2': null,
      '-2_sort': null,
      '93': 'Training for 1403S - MWH',
      '93_sort': 'Training for 1403S - MWH',
      '189': '01012',
      '189_sort': '01012',
      '225': 'Material Submittal',
      '225_sort': 'Material Submittal',
      '-19': null,
      '-19_sort': null,
      '-6': null,
      '-6_sort': null,
      '-10': null,
      '-10_sort': null,
    },
    {
      entity_instance_id: 42045,
      doc_num_increment: 222,
      full_doc_num: '222.1',
      is_draft: 0,
      is_current: 1,
      revision: 0,
      per_contract: true,
      created_by: 118,
      updated_on: '2023-03-14T00:00:00',
      created_by_role_id: 123,
      created_on: '2023-03-13T15:06:58.087',
      initiator: 'Jennifer Morgenstern',
      initiator_role: 'CAR',
      updated_by: 'Jennifer Morgenstern',
      assignee: '-',
      assigneerole: 'UA',
      assigneeorganization: 'EMWD',
      date_submitted: '2023-03-13T15:07:03.77',
      contract_module_type_id: -1,
      daysopened: 361,
      ballincourt: 'EMWD',
      ballincourtrole: 'CA',
      state_name: 'In Review',
      state_color: '#0091ff',
      state_text_color: '#ffffff',
      state_type: 'Open',
      current_state_id: 116,
      closed_on: null,
      due_date: '2023-03-27T00:00:00',
      entity_id: 24,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 18,
      needs_signed: 0,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: 0,
      project_manager: null,
      near_overdue: 'True',
      overdue: 'True',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 69,
      screen_name: 'Non AIS Submittals',
      '-2': null,
      '-2_sort': null,
      '93': 'Submittal  School for 1403S HDR - Section B',
      '93_sort': 'Submittal  School for 1403S HDR - Section B',
      '189': '01050',
      '189_sort': '01050',
      '225': 'Shop Drawing(s)',
      '225_sort': 'Shop Drawing(s)',
      '-19': null,
      '-19_sort': null,
      '-6': null,
      '-6_sort': null,
      '-10': null,
      '-10_sort': null,
    },
    {
      entity_instance_id: 41924,
      doc_num_increment: 221,
      full_doc_num: '221.1',
      is_draft: 0,
      is_current: 1,
      revision: 0,
      per_contract: true,
      created_by: 118,
      updated_on: '2023-03-09T00:00:00',
      created_by_role_id: 125,
      created_on: '2023-03-09T18:13:13.667',
      initiator: 'Jennifer Morgenstern',
      initiator_role: 'GC',
      updated_by: 'Jennifer Morgenstern',
      assignee: null,
      assigneerole: null,
      assigneeorganization: 'EMWD',
      date_submitted: '2023-03-09T18:13:18.483',
      contract_module_type_id: -1,
      daysopened: 0,
      ballincourt: 'CIPO Cloud',
      ballincourtrole: 'GC',
      state_name: 'BFR',
      state_color: '#990000',
      state_text_color: '#ffffff',
      state_type: 'Approved',
      current_state_id: 124,
      closed_on: '2023-03-09T00:00:00',
      due_date: '2023-03-23T00:00:00',
      entity_id: 24,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 18,
      needs_signed: 0,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: 0,
      project_manager: null,
      near_overdue: 'False',
      overdue: 'False',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 69,
      screen_name: 'Non AIS Submittals',
      '-2': null,
      '-2_sort': null,
      '93': 'Test Submittal for CA 1382W',
      '93_sort': 'Test Submittal for CA 1382W',
      '189': '01722',
      '189_sort': '01722',
      '225': 'Material Submittal',
      '225_sort': 'Material Submittal',
      '-19': null,
      '-19_sort': null,
      '-6': null,
      '-6_sort': null,
      '-10': null,
      '-10_sort': null,
    },
    {
      entity_instance_id: 23779,
      doc_num_increment: 220,
      full_doc_num: '220.1',
      is_draft: 0,
      is_current: 1,
      revision: 0,
      per_contract: true,
      created_by: 99,
      updated_on: '2023-03-14T00:00:00',
      created_by_role_id: 125,
      created_on: '2022-03-23T16:52:25.27',
      initiator: 'Amy Eichperger',
      initiator_role: 'GC',
      updated_by: 'Jennifer Morgenstern',
      assignee: null,
      assigneerole: null,
      assigneeorganization: 'EMWD',
      date_submitted: '2022-10-20T15:36:14.073',
      contract_module_type_id: -1,
      daysopened: 8,
      ballincourt: 'CIPO Cloud',
      ballincourtrole: 'GC',
      state_name: 'CFR',
      state_color: '#990000',
      state_text_color: '#ffffff',
      state_type: 'Approved',
      current_state_id: 125,
      closed_on: '2022-10-28T00:00:00',
      due_date: '2022-11-03T00:00:00',
      entity_id: 24,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 18,
      needs_signed: 0,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: 0,
      project_manager: null,
      near_overdue: 'False',
      overdue: 'False',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 69,
      screen_name: 'Non AIS Submittals',
      '-2': null,
      '-2_sort': null,
      '93': 'Test Email to CA when new submital submitted',
      '93_sort': 'Test Email to CA when new submital submitted',
      '189': '00000',
      '189_sort': '00000',
      '225': 'Material Submittal',
      '225_sort': 'Material Submittal',
      '-19': null,
      '-19_sort': null,
      '-6': null,
      '-6_sort': null,
      '-10': null,
      '-10_sort': null,
    },
    {
      entity_instance_id: 31834,
      doc_num_increment: 219,
      full_doc_num: '219.1',
      is_draft: 0,
      is_current: 1,
      revision: 0,
      per_contract: true,
      created_by: 118,
      updated_on: '2022-10-06T00:00:00',
      created_by_role_id: 123,
      created_on: '2022-09-15T13:22:28.217',
      initiator: 'Jennifer Morgenstern',
      initiator_role: 'CAR',
      updated_by: 'Jennifer Morgenstern',
      assignee: '-',
      assigneerole: 'UA',
      assigneeorganization: 'EMWD',
      date_submitted: '2022-09-15T13:22:33.017',
      contract_module_type_id: -1,
      daysopened: 540,
      ballincourt: 'EMWD',
      ballincourtrole: 'CA',
      state_name: 'In Review',
      state_color: '#0091ff',
      state_text_color: '#ffffff',
      state_type: 'Open',
      current_state_id: 116,
      closed_on: null,
      due_date: '2022-09-29T00:00:00',
      entity_id: 24,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 18,
      needs_signed: 0,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: 0,
      project_manager: null,
      near_overdue: 'True',
      overdue: 'True',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 69,
      screen_name: 'Non AIS Submittals',
      '-2': null,
      '-2_sort': null,
      '93': 'Concrete Mix - Testing for Inspector',
      '93_sort': 'Concrete Mix - Testing for Inspector',
      '189': '01500',
      '189_sort': '01500',
      '225': 'Material Submittal',
      '225_sort': 'Material Submittal',
      '-19': null,
      '-19_sort': null,
      '-6': null,
      '-6_sort': null,
      '-10': null,
      '-10_sort': null,
    },
    {
      entity_instance_id: 27953,
      doc_num_increment: 218,
      full_doc_num: '218.1',
      is_draft: 0,
      is_current: 1,
      revision: 0,
      per_contract: true,
      created_by: 99,
      updated_on: '2022-08-16T00:00:00',
      created_by_role_id: 125,
      created_on: '2022-06-22T17:59:34.01',
      initiator: 'Amy Eichperger',
      initiator_role: 'GC',
      updated_by: 'Jennifer Morgenstern',
      assignee: null,
      assigneerole: null,
      assigneeorganization: 'EMWD',
      date_submitted: '2022-06-23T16:57:01.097',
      contract_module_type_id: -1,
      daysopened: 54,
      ballincourt: null,
      ballincourtrole: null,
      state_name: 'AF',
      state_color: '#064a80',
      state_text_color: '#ffffff',
      state_type: 'Approved',
      current_state_id: 122,
      closed_on: '2022-08-16T00:00:00',
      due_date: '2022-07-07T00:00:00',
      entity_id: 24,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 18,
      needs_signed: 0,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: 0,
      project_manager: null,
      near_overdue: 'False',
      overdue: 'False',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 69,
      screen_name: 'Non AIS Submittals',
      '-2': null,
      '-2_sort': null,
      '93': 'Test email notificaition',
      '93_sort': 'Test email notificaition',
      '189': '00220',
      '189_sort': '00220',
      '225': 'Material Submittal',
      '225_sort': 'Material Submittal',
      '-19': null,
      '-19_sort': null,
      '-6': null,
      '-6_sort': null,
      '-10': null,
      '-10_sort': null,
    },
    {
      entity_instance_id: 21691,
      doc_num_increment: 217,
      full_doc_num: '217.1',
      is_draft: 0,
      is_current: 1,
      revision: 0,
      per_contract: true,
      created_by: 99,
      updated_on: '2022-10-06T00:00:00',
      created_by_role_id: 125,
      created_on: '2022-02-14T17:19:24.447',
      initiator: 'Amy Eichperger',
      initiator_role: 'GC',
      updated_by: 'Jill Lloyd',
      assignee: null,
      assigneerole: null,
      assigneeorganization: 'EMWD',
      date_submitted: '2022-06-22T18:01:11.67',
      contract_module_type_id: -1,
      daysopened: 55,
      ballincourt: null,
      ballincourtrole: null,
      state_name: 'AF',
      state_color: '#064a80',
      state_text_color: '#ffffff',
      state_type: 'Approved',
      current_state_id: 122,
      closed_on: '2022-08-16T00:00:00',
      due_date: '2022-07-06T00:00:00',
      entity_id: 24,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 18,
      needs_signed: 0,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: 0,
      project_manager: null,
      near_overdue: 'False',
      overdue: 'False',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 69,
      screen_name: 'Non AIS Submittals',
      '-2': null,
      '-2_sort': null,
      '93': 'Test',
      '93_sort': 'Test',
      '189': 'CONTRACT',
      '189_sort': 'CONTRACT',
      '225': 'Contract Document',
      '225_sort': 'Contract Document',
      '-19': null,
      '-19_sort': null,
      '-6': null,
      '-6_sort': null,
      '-10': null,
      '-10_sort': null,
    },
    {
      entity_instance_id: 26533,
      doc_num_increment: 216,
      full_doc_num: '216.1',
      is_draft: 0,
      is_current: 1,
      revision: 0,
      per_contract: true,
      created_by: 118,
      updated_on: '2022-06-29T00:00:00',
      created_by_role_id: 125,
      created_on: '2022-05-18T14:30:36.73',
      initiator: 'Jennifer Morgenstern',
      initiator_role: 'GC',
      updated_by: 'Amy Eichperger',
      assignee: '-',
      assigneerole: 'UA',
      assigneeorganization: 'EMWD',
      date_submitted: '2022-05-18T14:30:43.41',
      contract_module_type_id: -1,
      daysopened: 660,
      ballincourt: 'EMWD',
      ballincourtrole: 'CA',
      state_name: 'In Review',
      state_color: '#0091ff',
      state_text_color: '#ffffff',
      state_type: 'Open',
      current_state_id: 116,
      closed_on: null,
      due_date: '2022-06-01T00:00:00',
      entity_id: 24,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 18,
      needs_signed: 0,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: 0,
      project_manager: null,
      near_overdue: 'True',
      overdue: 'True',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 69,
      screen_name: 'Non AIS Submittals',
      '-2': null,
      '-2_sort': null,
      '93': 'Lighting - Training Submittal',
      '93_sort': 'Lighting - Training Submittal',
      '189': '11200',
      '189_sort': '11200',
      '225': 'Material Submittal',
      '225_sort': 'Material Submittal',
      '-19': null,
      '-19_sort': null,
      '-6': null,
      '-6_sort': null,
      '-10': null,
      '-10_sort': null,
    },
    {
      entity_instance_id: 23455,
      doc_num_increment: 215,
      full_doc_num: '215.1',
      is_draft: 0,
      is_current: 1,
      revision: 0,
      per_contract: true,
      created_by: 118,
      updated_on: '2022-10-20T00:00:00',
      created_by_role_id: 125,
      created_on: '2022-03-16T18:41:33.877',
      initiator: 'Jennifer Morgenstern',
      initiator_role: 'GC',
      updated_by: 'Amy Eichperger',
      assignee: null,
      assigneerole: null,
      assigneeorganization: 'EMWD',
      date_submitted: '2022-04-21T19:16:12.627',
      contract_module_type_id: -1,
      daysopened: 182,
      ballincourt: null,
      ballincourtrole: null,
      state_name: 'A',
      state_color: '#064a80',
      state_text_color: '#ffffff',
      state_type: 'Approved',
      current_state_id: 123,
      closed_on: '2022-10-20T00:00:00',
      due_date: '2022-05-05T00:00:00',
      entity_id: 24,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 18,
      needs_signed: 0,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: 0,
      project_manager: null,
      near_overdue: 'False',
      overdue: 'False',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 69,
      screen_name: 'Non AIS Submittals',
      '-2': null,
      '-2_sort': null,
      '93': 'Test Sub #1',
      '93_sort': 'Test Sub #1',
      '189': '00000',
      '189_sort': '00000',
      '225': 'Material Submittal',
      '225_sort': 'Material Submittal',
      '-19': null,
      '-19_sort': null,
      '-6': null,
      '-6_sort': null,
      '-10': null,
      '-10_sort': null,
    },
    {
      entity_instance_id: 18343,
      doc_num_increment: 214,
      full_doc_num: '214.1',
      is_draft: 0,
      is_current: 1,
      revision: 0,
      per_contract: true,
      created_by: 99,
      updated_on: '2022-04-13T00:00:00',
      created_by_role_id: 125,
      created_on: '2021-11-29T20:08:49.087',
      initiator: 'Amy Eichperger',
      initiator_role: 'GC',
      updated_by: 'Amy Eichperger',
      assignee: '-',
      assigneerole: 'UA',
      assigneeorganization: 'EMWD',
      date_submitted: '2022-04-13T16:03:19.343',
      contract_module_type_id: -1,
      daysopened: 695,
      ballincourt: null,
      ballincourtrole: null,
      state_name: 'NEW',
      state_color: '#e91e63',
      state_text_color: '#ffffff',
      state_type: 'Open',
      current_state_id: 112,
      closed_on: null,
      due_date: '2022-04-27T00:00:00',
      entity_id: 24,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 18,
      needs_signed: 0,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: 0,
      project_manager: null,
      near_overdue: 'True',
      overdue: 'True',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 69,
      screen_name: 'Non AIS Submittals',
      '-2': null,
      '-2_sort': null,
      '93': 'Test',
      '93_sort': 'Test',
      '189': '00230',
      '189_sort': '00230',
      '225': 'Contract Document',
      '225_sort': 'Contract Document',
      '-19': null,
      '-19_sort': null,
      '-6': null,
      '-6_sort': null,
      '-10': null,
      '-10_sort': null,
    },
    {
      entity_instance_id: 27579,
      doc_num_increment: 212,
      full_doc_num: '212.3',
      is_draft: 0,
      is_current: 1,
      revision: 2,
      per_contract: true,
      created_by: 99,
      updated_on: '2023-11-16T00:00:00',
      created_by_role_id: 124,
      created_on: '2022-06-13T14:00:17.21',
      initiator: 'Amy Eichperger',
      initiator_role: 'CA',
      updated_by: 'Jennifer Morgenstern',
      assignee: '-',
      assigneerole: 'UA',
      assigneeorganization: 'EMWD',
      date_submitted: '2022-06-23T16:11:29.347',
      contract_module_type_id: -1,
      daysopened: 624,
      ballincourt: 'EMWD',
      ballincourtrole: 'CA',
      state_name: 'In Review',
      state_color: '#0091ff',
      state_text_color: '#ffffff',
      state_type: 'Open',
      current_state_id: 116,
      closed_on: null,
      due_date: '2022-07-07T00:00:00',
      entity_id: 24,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 18,
      needs_signed: 0,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: 0,
      project_manager: null,
      near_overdue: 'True',
      overdue: 'True',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 69,
      screen_name: 'Non AIS Submittals',
      '-2': null,
      '-2_sort': null,
      '93': 'Test adding attachment',
      '93_sort': 'Test adding attachment',
      '189': '00100',
      '189_sort': '00100',
      '225': 'Material Submittal',
      '225_sort': 'Material Submittal',
      '-19': null,
      '-19_sort': null,
      '-6': null,
      '-6_sort': null,
      '-10': null,
      '-10_sort': null,
    },
    {
      entity_instance_id: 22528,
      doc_num_increment: 211,
      full_doc_num: '211.1',
      is_draft: 0,
      is_current: 1,
      revision: 0,
      per_contract: true,
      created_by: 99,
      updated_on: '2022-02-28T00:00:00',
      created_by_role_id: 125,
      created_on: '2022-02-28T18:48:05.61',
      initiator: 'Amy Eichperger',
      initiator_role: 'GC',
      updated_by: 'Amy Eichperger',
      assignee: '-',
      assigneerole: 'UA',
      assigneeorganization: 'EMWD',
      date_submitted: '2022-02-28T18:51:39.46',
      contract_module_type_id: -1,
      daysopened: 739,
      ballincourt: 'EMWD',
      ballincourtrole: 'CA',
      state_name: 'In Review',
      state_color: '#0091ff',
      state_text_color: '#ffffff',
      state_type: 'Open',
      current_state_id: 116,
      closed_on: null,
      due_date: '2022-03-14T00:00:00',
      entity_id: 24,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 18,
      needs_signed: 0,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: 0,
      project_manager: null,
      near_overdue: 'True',
      overdue: 'True',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 69,
      screen_name: 'Non AIS Submittals',
      '-2': null,
      '-2_sort': null,
      '93': 'Test Submittal for MWD Review/response',
      '93_sort': 'Test Submittal for MWD Review/response',
      '189': '01612',
      '189_sort': '01612',
      '225': 'Material Submittal',
      '225_sort': 'Material Submittal',
      '-19': null,
      '-19_sort': null,
      '-6': null,
      '-6_sort': null,
      '-10': null,
      '-10_sort': null,
    },
    {
      entity_instance_id: 22530,
      doc_num_increment: 210,
      full_doc_num: '210.1',
      is_draft: 0,
      is_current: 1,
      revision: 0,
      per_contract: true,
      created_by: 99,
      updated_on: '2022-02-28T00:00:00',
      created_by_role_id: 125,
      created_on: '2022-02-28T18:49:13.853',
      initiator: 'Amy Eichperger',
      initiator_role: 'GC',
      updated_by: 'Amy Eichperger',
      assignee: '-',
      assigneerole: 'UA',
      assigneeorganization: 'EMWD',
      date_submitted: '2022-02-28T18:50:04.733',
      contract_module_type_id: -1,
      daysopened: 739,
      ballincourt: 'EMWD',
      ballincourtrole: 'CA',
      state_name: 'In Review',
      state_color: '#0091ff',
      state_text_color: '#ffffff',
      state_type: 'Open',
      current_state_id: 116,
      closed_on: null,
      due_date: '2022-03-14T00:00:00',
      entity_id: 24,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 18,
      needs_signed: 0,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: 0,
      project_manager: null,
      near_overdue: 'True',
      overdue: 'True',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 69,
      screen_name: 'Non AIS Submittals',
      '-2': null,
      '-2_sort': null,
      '93': 'Test Submittal for review/comments by MWD',
      '93_sort': 'Test Submittal for review/comments by MWD',
      '189': '01310',
      '189_sort': '01310',
      '225': 'Shop Drawing(s)',
      '225_sort': 'Shop Drawing(s)',
      '-19': null,
      '-19_sort': null,
      '-6': null,
      '-6_sort': null,
      '-10': null,
      '-10_sort': null,
    },
    {
      entity_instance_id: 21116,
      doc_num_increment: 209,
      full_doc_num: '209.1',
      is_draft: 0,
      is_current: 1,
      revision: 0,
      per_contract: true,
      created_by: 118,
      updated_on: '2022-02-14T00:00:00',
      created_by_role_id: 125,
      created_on: '2022-02-01T19:49:01.517',
      initiator: 'Jennifer Morgenstern',
      initiator_role: 'GC',
      updated_by: 'Amy Eichperger',
      assignee: '-',
      assigneerole: 'UA',
      assigneeorganization: 'EMWD',
      date_submitted: '2022-02-14T17:12:01.75',
      contract_module_type_id: -1,
      daysopened: 753,
      ballincourt: null,
      ballincourtrole: null,
      state_name: 'NEW',
      state_color: '#e91e63',
      state_text_color: '#ffffff',
      state_type: 'Open',
      current_state_id: 112,
      closed_on: null,
      due_date: '2022-02-28T00:00:00',
      entity_id: 24,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 18,
      needs_signed: 0,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: 0,
      project_manager: null,
      near_overdue: 'True',
      overdue: 'True',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 69,
      screen_name: 'Non AIS Submittals',
      '-2': null,
      '-2_sort': null,
      '93': 'Testing retracting submittal',
      '93_sort': 'Testing retracting submittal',
      '189': '00000',
      '189_sort': '00000',
      '225': 'Contract Document',
      '225_sort': 'Contract Document',
      '-19': null,
      '-19_sort': null,
      '-6': null,
      '-6_sort': null,
      '-10': null,
      '-10_sort': null,
    },
    {
      entity_instance_id: 20598,
      doc_num_increment: 208,
      full_doc_num: '208.1',
      is_draft: 0,
      is_current: 1,
      revision: 0,
      per_contract: true,
      created_by: 118,
      updated_on: '2022-01-20T00:00:00',
      created_by_role_id: 125,
      created_on: '2022-01-19T22:38:18.45',
      initiator: 'Jennifer Morgenstern',
      initiator_role: 'GC',
      updated_by: 'Admin ',
      assignee: '-',
      assigneerole: 'UA',
      assigneeorganization: 'EMWD',
      date_submitted: '2022-01-19T22:41:35.247',
      contract_module_type_id: -1,
      daysopened: 779,
      ballincourt: 'EMWD',
      ballincourtrole: 'CA',
      state_name: 'In Review',
      state_color: '#0091ff',
      state_text_color: '#ffffff',
      state_type: 'Open',
      current_state_id: 116,
      closed_on: null,
      due_date: '2022-02-02T00:00:00',
      entity_id: 24,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 18,
      needs_signed: 0,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: 0,
      project_manager: null,
      near_overdue: 'True',
      overdue: 'True',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 69,
      screen_name: 'Non AIS Submittals',
      '-2': null,
      '-2_sort': null,
      '93': 'Training rejecting a submittal',
      '93_sort': 'Training rejecting a submittal',
      '189': '00100',
      '189_sort': '00100',
      '225': 'Shop Drawing(s)',
      '225_sort': 'Shop Drawing(s)',
      '-19': null,
      '-19_sort': null,
      '-6': null,
      '-6_sort': null,
      '-10': null,
      '-10_sort': null,
    },
    {
      entity_instance_id: 20185,
      doc_num_increment: 207,
      full_doc_num: '207.1',
      is_draft: 0,
      is_current: 1,
      revision: 0,
      per_contract: true,
      created_by: 118,
      updated_on: '2022-01-11T00:00:00',
      created_by_role_id: 125,
      created_on: '2022-01-11T14:36:47.46',
      initiator: 'Jennifer Morgenstern',
      initiator_role: 'GC',
      updated_by: 'Jennifer Morgenstern',
      assignee: '-',
      assigneerole: 'UA',
      assigneeorganization: 'EMWD',
      date_submitted: '2022-01-11T14:37:42.92',
      contract_module_type_id: -1,
      daysopened: 787,
      ballincourt: 'EMWD',
      ballincourtrole: 'CA',
      state_name: 'In Review',
      state_color: '#0091ff',
      state_text_color: '#ffffff',
      state_type: 'Open',
      current_state_id: 116,
      closed_on: null,
      due_date: '2022-01-25T00:00:00',
      entity_id: 24,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 18,
      needs_signed: 0,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: 0,
      project_manager: null,
      near_overdue: 'True',
      overdue: 'True',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 69,
      screen_name: 'Non AIS Submittals',
      '-2': null,
      '-2_sort': null,
      '93': 'Training Submittals Jeff/Corey',
      '93_sort': 'Training Submittals Jeff/Corey',
      '189': '01310',
      '189_sort': '01310',
      '225': 'Shop Drawing(s)',
      '225_sort': 'Shop Drawing(s)',
      '-19': null,
      '-19_sort': null,
      '-6': null,
      '-6_sort': null,
      '-10': null,
      '-10_sort': null,
    },
    {
      entity_instance_id: 23720,
      doc_num_increment: 206,
      full_doc_num: '206.2',
      is_draft: 0,
      is_current: 1,
      revision: 1,
      per_contract: true,
      created_by: 99,
      updated_on: '2022-04-07T00:00:00',
      created_by_role_id: 124,
      created_on: '2022-03-22T22:39:28.35',
      initiator: 'Amy Eichperger',
      initiator_role: 'CA',
      updated_by: 'Amy Eichperger',
      assignee: '-',
      assigneerole: 'UA',
      assigneeorganization: 'EMWD',
      date_submitted: '2022-04-07T17:35:17.46',
      contract_module_type_id: -1,
      daysopened: 701,
      ballincourt: null,
      ballincourtrole: null,
      state_name: 'NEW',
      state_color: '#e91e63',
      state_text_color: '#ffffff',
      state_type: 'Open',
      current_state_id: 112,
      closed_on: null,
      due_date: '2022-04-21T00:00:00',
      entity_id: 24,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 18,
      needs_signed: 0,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: 0,
      project_manager: null,
      near_overdue: 'True',
      overdue: 'True',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 69,
      screen_name: 'Non AIS Submittals',
      '-2': null,
      '-2_sort': null,
      '93': 'Training Example #2 for Leland - Test',
      '93_sort': 'Training Example #2 for Leland - Test',
      '189': '03300',
      '189_sort': '03300',
      '225': 'Material Submittal',
      '225_sort': 'Material Submittal',
      '-19': null,
      '-19_sort': null,
      '-6': null,
      '-6_sort': null,
      '-10': null,
      '-10_sort': null,
    },
    {
      entity_instance_id: 16178,
      doc_num_increment: 205,
      full_doc_num: '205.3',
      is_draft: 0,
      is_current: 1,
      revision: 2,
      per_contract: true,
      created_by: 99,
      updated_on: '2022-03-15T00:00:00',
      created_by_role_id: 124,
      created_on: '2021-10-18T18:30:02.62',
      initiator: 'Amy Eichperger',
      initiator_role: 'CA',
      updated_by: 'Amy Eichperger',
      assignee: '-',
      assigneerole: 'UA',
      assigneeorganization: 'EMWD',
      date_submitted: '2022-03-15T21:00:37.61',
      contract_module_type_id: -1,
      daysopened: 724,
      ballincourt: null,
      ballincourtrole: null,
      state_name: 'NEW',
      state_color: '#e91e63',
      state_text_color: '#ffffff',
      state_type: 'Open',
      current_state_id: 112,
      closed_on: null,
      due_date: '2022-03-29T00:00:00',
      entity_id: 24,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 18,
      needs_signed: 0,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: 0,
      project_manager: null,
      near_overdue: 'True',
      overdue: 'True',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 69,
      screen_name: 'Non AIS Submittals',
      '-2': null,
      '-2_sort': null,
      '93': 'Training Example #1 for Leland',
      '93_sort': 'Training Example #1 for Leland',
      '189': '02221',
      '189_sort': '02221',
      '225': 'Material Submittal',
      '225_sort': 'Material Submittal',
      '-19': null,
      '-19_sort': null,
      '-6': null,
      '-6_sort': null,
      '-10': null,
      '-10_sort': null,
    },
    {
      entity_instance_id: 15568,
      doc_num_increment: 204,
      full_doc_num: '204.1',
      is_draft: 0,
      is_current: 1,
      revision: 0,
      per_contract: true,
      created_by: 158,
      updated_on: '2021-10-05T00:00:00',
      created_by_role_id: 123,
      created_on: '2021-10-05T20:53:44.933',
      initiator: 'Sylvia Zamora',
      initiator_role: 'CAR',
      updated_by: 'Sylvia Zamora',
      assignee: '-',
      assigneerole: 'UA',
      assigneeorganization: 'EMWD',
      date_submitted: '2021-10-05T20:53:48.397',
      contract_module_type_id: -1,
      daysopened: 885,
      ballincourt: null,
      ballincourtrole: null,
      state_name: 'NEW',
      state_color: '#e91e63',
      state_text_color: '#ffffff',
      state_type: 'Open',
      current_state_id: 112,
      closed_on: null,
      due_date: '2021-10-19T00:00:00',
      entity_id: 24,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 18,
      needs_signed: 0,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: 0,
      project_manager: null,
      near_overdue: 'True',
      overdue: 'True',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 69,
      screen_name: 'Non AIS Submittals',
      '-2': null,
      '-2_sort': null,
      '93': 'Test video part 2',
      '93_sort': 'Test video part 2',
      '189': 'CONTRACT',
      '189_sort': 'CONTRACT',
      '225': 'Contract Document',
      '225_sort': 'Contract Document',
      '-19': null,
      '-19_sort': null,
      '-6': null,
      '-6_sort': null,
      '-10': null,
      '-10_sort': null,
    },
    {
      entity_instance_id: 15567,
      doc_num_increment: 203,
      full_doc_num: '203.1',
      is_draft: 0,
      is_current: 1,
      revision: 0,
      per_contract: true,
      created_by: 99,
      updated_on: '2021-10-05T00:00:00',
      created_by_role_id: 125,
      created_on: '2021-10-05T20:39:57.64',
      initiator: 'Amy Eichperger',
      initiator_role: 'GC',
      updated_by: 'Amy Eichperger',
      assignee: '-',
      assigneerole: 'UA',
      assigneeorganization: 'EMWD',
      date_submitted: '2021-10-05T20:41:03.57',
      contract_module_type_id: -1,
      daysopened: 885,
      ballincourt: null,
      ballincourtrole: null,
      state_name: 'NEW',
      state_color: '#e91e63',
      state_text_color: '#ffffff',
      state_type: 'Open',
      current_state_id: 112,
      closed_on: null,
      due_date: '2021-10-19T00:00:00',
      entity_id: 24,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 18,
      needs_signed: 0,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: 0,
      project_manager: null,
      near_overdue: 'True',
      overdue: 'True',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 69,
      screen_name: 'Non AIS Submittals',
      '-2': null,
      '-2_sort': null,
      '93': 'Test renumber to 25.1',
      '93_sort': 'Test renumber to 25.1',
      '189': 'CONTRACT',
      '189_sort': 'CONTRACT',
      '225': 'Contract Document',
      '225_sort': 'Contract Document',
      '-19': null,
      '-19_sort': null,
      '-6': null,
      '-6_sort': null,
      '-10': null,
      '-10_sort': null,
    },
    {
      entity_instance_id: 14204,
      doc_num_increment: 202,
      full_doc_num: '202.1',
      is_draft: 0,
      is_current: 1,
      revision: 0,
      per_contract: true,
      created_by: 99,
      updated_on: '2021-12-07T00:00:00',
      created_by_role_id: 123,
      created_on: '2021-09-02T20:47:49.527',
      initiator: 'Amy Eichperger',
      initiator_role: 'CAR',
      updated_by: 'Amy Eichperger',
      assignee: '-',
      assigneerole: 'UA',
      assigneeorganization: 'EMWD',
      date_submitted: '2021-09-02T20:47:52.717',
      contract_module_type_id: -1,
      daysopened: 918,
      ballincourt: 'EMWD',
      ballincourtrole: 'CA',
      state_name: 'In Review',
      state_color: '#0091ff',
      state_text_color: '#ffffff',
      state_type: 'Open',
      current_state_id: 116,
      closed_on: null,
      due_date: '2021-09-16T00:00:00',
      entity_id: 24,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 18,
      needs_signed: 0,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: 0,
      project_manager: null,
      near_overdue: 'True',
      overdue: 'True',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 69,
      screen_name: 'Non AIS Submittals',
      '-2': null,
      '-2_sort': null,
      '93': 'Blah',
      '93_sort': 'Blah',
      '189': '00220',
      '189_sort': '00220',
      '225': 'Material Submittal',
      '225_sort': 'Material Submittal',
      '-19': null,
      '-19_sort': null,
      '-6': null,
      '-6_sort': null,
      '-10': null,
      '-10_sort': null,
    },
    {
      entity_instance_id: 12994,
      doc_num_increment: 201,
      full_doc_num: '201.1',
      is_draft: 0,
      is_current: 1,
      revision: 0,
      per_contract: true,
      created_by: 158,
      updated_on: '2021-09-02T00:00:00',
      created_by_role_id: 125,
      created_on: '2021-07-21T20:44:28.22',
      initiator: 'Sylvia Zamora',
      initiator_role: 'GC',
      updated_by: 'Amy Eichperger',
      assignee: '-',
      assigneerole: 'UA',
      assigneeorganization: 'EMWD',
      date_submitted: '2021-07-21T20:45:15.113',
      contract_module_type_id: -1,
      daysopened: 961,
      ballincourt: 'EMWD',
      ballincourtrole: 'CA',
      state_name: 'In Review',
      state_color: '#0091ff',
      state_text_color: '#ffffff',
      state_type: 'Open',
      current_state_id: 116,
      closed_on: null,
      due_date: '2021-08-04T00:00:00',
      entity_id: 24,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 18,
      needs_signed: 0,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: 0,
      project_manager: null,
      near_overdue: 'True',
      overdue: 'True',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 69,
      screen_name: 'Non AIS Submittals',
      '-2': null,
      '-2_sort': null,
      '93': 'Concrete',
      '93_sort': 'Concrete',
      '189': '03300',
      '189_sort': '03300',
      '225': 'Material Submittal',
      '225_sort': 'Material Submittal',
      '-19': null,
      '-19_sort': null,
      '-6': null,
      '-6_sort': null,
      '-10': null,
      '-10_sort': null,
    },
    {
      entity_instance_id: 21508,
      doc_num_increment: 115,
      full_doc_num: '115.1',
      is_draft: 0,
      is_current: 1,
      revision: 0,
      per_contract: true,
      created_by: 99,
      updated_on: '2022-02-09T00:00:00',
      created_by_role_id: 125,
      created_on: '2022-02-09T20:53:43.833',
      initiator: 'Amy Eichperger',
      initiator_role: 'GC',
      updated_by: 'Amy Eichperger',
      assignee: '-',
      assigneerole: 'UA',
      assigneeorganization: 'EMWD',
      date_submitted: '2022-02-09T20:53:58.653',
      contract_module_type_id: -1,
      daysopened: 758,
      ballincourt: null,
      ballincourtrole: null,
      state_name: 'NEW',
      state_color: '#e91e63',
      state_text_color: '#ffffff',
      state_type: 'Open',
      current_state_id: 112,
      closed_on: null,
      due_date: '2022-02-23T00:00:00',
      entity_id: 24,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 18,
      needs_signed: 0,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: 0,
      project_manager: null,
      near_overdue: 'True',
      overdue: 'True',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 69,
      screen_name: 'Non AIS Submittals',
      '-2': null,
      '-2_sort': null,
      '93': 'SWPPP or Notice of Intent for Storm Water Pollution Prevention',
      '93_sort': 'SWPPP or Notice of Intent for Storm Water Pollution Prevention',
      '189': 'CONTRACT',
      '189_sort': 'CONTRACT',
      '225': 'Contract Document',
      '225_sort': 'Contract Document',
      '-19': null,
      '-19_sort': null,
      '-6': null,
      '-6_sort': null,
      '-10': null,
      '-10_sort': null,
    },
    {
      entity_instance_id: 21511,
      doc_num_increment: 114,
      full_doc_num: '114.1',
      is_draft: 0,
      is_current: 1,
      revision: 0,
      per_contract: true,
      created_by: 99,
      updated_on: '2022-02-09T00:00:00',
      created_by_role_id: 125,
      created_on: '2022-02-09T20:58:30.443',
      initiator: 'Amy Eichperger',
      initiator_role: 'GC',
      updated_by: 'Amy Eichperger',
      assignee: '-',
      assigneerole: 'UA',
      assigneeorganization: 'EMWD',
      date_submitted: '2022-02-09T21:01:06.483',
      contract_module_type_id: -1,
      daysopened: 758,
      ballincourt: null,
      ballincourtrole: null,
      state_name: 'NEW',
      state_color: '#e91e63',
      state_text_color: '#ffffff',
      state_type: 'Open',
      current_state_id: 112,
      closed_on: null,
      due_date: '2022-02-23T00:00:00',
      entity_id: 24,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 18,
      needs_signed: 0,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: 0,
      project_manager: null,
      near_overdue: 'True',
      overdue: 'True',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 69,
      screen_name: 'Non AIS Submittals',
      '-2': null,
      '-2_sort': null,
      '93': 'SWPPP or Notice of Intent for Storm Water Pollution Prevention',
      '93_sort': 'SWPPP or Notice of Intent for Storm Water Pollution Prevention',
      '189': 'CONTRACT',
      '189_sort': 'CONTRACT',
      '225': 'Contract Document',
      '225_sort': 'Contract Document',
      '-19': null,
      '-19_sort': null,
      '-6': null,
      '-6_sort': null,
      '-10': null,
      '-10_sort': null,
    },
    {
      entity_instance_id: 5160,
      doc_num_increment: 110,
      full_doc_num: '110.1',
      is_draft: 0,
      is_current: 1,
      revision: 0,
      per_contract: true,
      created_by: 134,
      updated_on: '2021-07-28T00:00:00',
      created_by_role_id: 125,
      created_on: '2021-03-24T21:29:09.317',
      initiator: 'Leed Electric, Inc.',
      initiator_role: 'GC',
      updated_by: 'Amy Eichperger',
      assignee: '-',
      assigneerole: 'UA',
      assigneeorganization: 'GSE Construction Company, Inc.',
      date_submitted: '2021-03-24T21:29:39.533',
      contract_module_type_id: -1,
      daysopened: 1080,
      ballincourt: 'EMWD',
      ballincourtrole: 'CA',
      state_name: 'In Review',
      state_color: '#0091ff',
      state_text_color: '#ffffff',
      state_type: 'Open',
      current_state_id: 116,
      closed_on: null,
      due_date: '2021-04-07T00:00:00',
      entity_id: 24,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 18,
      needs_signed: 0,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: 0,
      project_manager: null,
      near_overdue: 'True',
      overdue: 'True',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 69,
      screen_name: 'Non AIS Submittals',
      '-2': null,
      '-2_sort': null,
      '93': 'SWPPP or Notice of Intent for Storm Water Pollution Prevention',
      '93_sort': 'SWPPP or Notice of Intent for Storm Water Pollution Prevention',
      '189': 'CONTRACT',
      '189_sort': 'CONTRACT',
      '225': 'Contract Document',
      '225_sort': 'Contract Document',
      '-19': null,
      '-19_sort': null,
      '-6': null,
      '-6_sort': null,
      '-10': null,
      '-10_sort': null,
    },
    {
      entity_instance_id: 21690,
      doc_num_increment: 37,
      full_doc_num: '037.1',
      is_draft: 0,
      is_current: 1,
      revision: 0,
      per_contract: true,
      created_by: 99,
      updated_on: '2022-02-14T00:00:00',
      created_by_role_id: 125,
      created_on: '2022-02-14T17:14:46.197',
      initiator: 'Amy Eichperger',
      initiator_role: 'GC',
      updated_by: 'Amy Eichperger',
      assignee: '-',
      assigneerole: 'UA',
      assigneeorganization: 'EMWD',
      date_submitted: '2022-02-14T17:25:00.183',
      contract_module_type_id: -1,
      daysopened: 753,
      ballincourt: null,
      ballincourtrole: null,
      state_name: 'NEW',
      state_color: '#e91e63',
      state_text_color: '#ffffff',
      state_type: 'Open',
      current_state_id: 112,
      closed_on: null,
      due_date: '2022-02-28T00:00:00',
      entity_id: 24,
      contract_id: 48,
      cntrct_entity_instance_id: 3684,
      is_closed_state: false,
      is_archived_state: false,
      contract_no: '008',
      module_id: 18,
      needs_signed: 0,
      in_my_court: 'False',
      permission_list: '11,13,14,15,16,2,20,21,22,3,4,5,6,7,8,9',
      needs_approval: 0,
      project_manager: null,
      near_overdue: 'True',
      overdue: 'True',
      entity_template_id: null,
      template_name: null,
      template_description: null,
      screen_id: 69,
      screen_name: 'Non AIS Submittals',
      '-2': null,
      '-2_sort': null,
      '93': 'Test incorrect Document Number',
      '93_sort': 'Test incorrect Document Number',
      '189': 'CONTRACT',
      '189_sort': 'CONTRACT',
      '225': 'Contract Document',
      '225_sort': 'Contract Document',
      '-19': null,
      '-19_sort': null,
      '-6': null,
      '-6_sort': null,
      '-10': null,
      '-10_sort': null,
    },
  ],
};
