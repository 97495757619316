import { cipo } from 'cipo';

cipo.controller('pdfMarkupController',
	function ($transition$,$scope, $q, $state, $http, $compile, fileService, rememberManagerService, Manager, Message, URI, ACTIONS, userService, $mdDialog) {
		// VARIABLES
		const params = $transition$.params();
		$scope.display = false;
		$scope.manager = null;
		$scope.module;
		$scope.moduleCode;
		$scope.moduleName;
		$scope.moduleId;
		$scope.entityInstanceId = userService.system.context.contract?.entityInstanceId ?? userService.system.context.project?.entityInstanceId ?? userService.system.context.program?.entityInstanceId;
		$scope.documentId = typeof params.d != "undefined" ? params.d.toString() : "";
		$scope.fileId = typeof params.f != "undefined" ? params.f.toString() : "";
		$scope.fileName = typeof params.n != "undefined" ? params.n.toString() : "";
		$scope.userDict = [];

			// FUNCTIONS
			$scope.refreshModule = _refreshModule;
			$scope.permissionsCheck = _permissionsCheck;
			$scope.getOperations = _getOperations;
			$scope.notFound = _notFound;
			$scope.loadManager = _loadManager;
			$scope.getGridColumns = _getGridColumns;
			$scope.addNew = _addNew;
			$scope.deleteDocument = _deleteDocument;
			$scope.viewDetail = _viewDetail;
			$scope.getFlattenedDocument = _getFlattenedDocument;			
			$scope.emailDocument = _emailDocument;
			$scope.printDocument = _printDocument;
			$scope.viewSource = _viewSource;
			$scope.getIconButton = _getIconButton;
			$scope.setCurrent = _setCurrent;
			$scope.getUserDict = _getUserDict;
			$scope.getUserDisplayName = _getUserDisplayName;
			$scope.getDisplayDate = _getDisplayDate;
			$scope.getComments = _getComments;
			$scope.isContractClosed = _isContractClosed;

		// INIT
		// Get module info
		$scope.refreshModule();
		// Redirect to 404 if user doesn't have permissions in module
		$scope.permissionsCheck();
		// If documentId in route, load pspdfkit in detail view. Otherwise, load manager.
		$scope.documentId.length
			? $scope.viewDetail($scope.documentId, $scope.fileId, $scope.fileName)
			: $scope.getUserDict()
				.then(() => {
					$scope.loadManager();
				});

			// WATCHERS
			// WATCH FOR CHANGES TO CONTRACT
			$scope.$watch(function () {
				return userService.system.context;
			},
			function (newVal) {
				if (typeof newVal !== 'undefined' && newVal.contract && $scope.entityInstanceId != newVal.contract.entityInstanceId) {
					// Get modules for new contract id
					userService.Modules(newVal.contract.id)
						.then(function (res) {
							$scope.refreshModule();
							// If user has pdf markup permissions on this contract, just reload the data
							if ($scope.module) {
								$scope.entityInstanceId = newVal.contract.entityInstanceId;
								$scope.manager.urlParams.entityInstanceId = newVal.contract.entityInstanceId;
								$scope.loadManager();
								$scope.manager.loadPage()
									.finally(function () {
										$scope.manager.loading = false;
									});
							} else {
								$scope.notFound();
							}
						});
				}
			});

		$scope.$watch(function () {
			return userService.isReloadGrid;
		},
			function (n, o) {
				if (n) {
					if ($scope.manager) {
						$scope.manager.loading = true;
						$scope.getGridColumns();
					}
					userService.isReloadGrid = false;
				}
			});

		// FUNCTION DEFINITIONS
		function _refreshModule() {
			$scope.moduleCode = $state.current.code;
			$scope.moduleName = userService.system.mNames[$scope.moduleCode];
			$scope.module = userService.system.modules[$scope.moduleCode];
			$scope.moduleId = $scope.module
				? $scope.module.moduleId
				: null;
		}

		// Redirect user tot 404 page if they don't have pdf markup permissions
		function _permissionsCheck() {
			if ($scope.module) {
				Object.defineProperty($scope, 'operations', {
					get: function () { return $scope.getOperations() }
				});

				$scope.display = true;
			} else {
				$scope.notFound();
			}
		}

		function _getOperations(moduleCode = null) {
			moduleCode = moduleCode || $scope.moduleCode;
			return userService.getOperationsFor(moduleCode);
		}

		// 404 redirect
		function _notFound(isDisplay = false) {
			$scope.display = isDisplay;
			$state.go('tenant.404', null, { tenantIdentifier: params.tenantIdentifier, location: false });
		}

		// Load manager with grid of PDFs this user has marked up
		function _loadManager() {
			if ($scope.module) {
				$scope.manager = new Manager({
					objectsPerPage: 20,
					hideFilters: true,
					hideVersions: true,
					// Give grid/list option
					hideLayoutOptions: false,
					// grid layout
					layout: 2,
					defaultColumns: 1,
					// This drives which html template we're using... ?
					gridClass: 'pdfMarkupGrid',
					// gridClass: 'pdfMarkupGrid',
					dataURL: URI.PDF_MARKUP.GET_GRID,
					urlParams: {
						entityInstanceId: $scope.entityInstanceId,
						moduleId: $scope.moduleId
					},
					moduleId: $scope.moduleId,
					moduleName: $scope.moduleName,
					moduleCode: $scope.moduleCode,
					entityInstanceId: $scope.entityInstanceId,
					contractNo: $scope.module.perContract ? userService.system.context.contract?.no : "",
					correspondenceOperations: $scope.getOperations('C'),
					options: {
						multiselect: false
					},
					rowOnClick: function (row) {
						$scope.viewDetail(row.documentId, row.fileId, row.displayName);
					},
					leftActions: [
						{
							setProperties: ACTIONS.CREATE,
							conditionOnTop: function () {
								if ($scope.isContractClosed()) return false;
								return $scope.getOperations().Create;
							},
							click: function () {
								$scope.addNew();
							}
						},
					],
					actions: [
						// Top actions
						{
							setProperties: ACTIONS.REFRESH,
							click: function () {
								$scope.manager.refresh();
							}
						},
						// Row actions
						{
							setProperties: ACTIONS.DELETE,
							alwaysOnTop: false,
							alwaysOnRow: true,
							condition: function (row) {
								if ($scope.isContractClosed()) return false;
								if (row.isDeletable && $scope.getOperations().Delete)
									return true;

								return false;
							},
							click: function (row) {
								$scope.deleteDocument(row);
							}
						},
						{
							setProperties: ACTIONS.DETAILS,
							click: function (row) {
								$scope.viewDetail(row.documentId, row.fileId, row.displayName);
							}
						},
						{
							setProperties: ACTIONS.DOWNLOAD,
							name: 'Download PDF',
							alwaysOnRow: true,
							click: function (row) {
								$scope.manager.downloadByUrl(row.downloadLink, row.displayName);
							},
							condition: function () {
								if ($scope.getOperations('C').Read)
									return true;

								return false;
							}
						},
						{
							setProperties: ACTIONS.EMAIL,
							name: 'Email Document',
							alwaysOnRow: true,
							alwaysOnTop: false,
							click: function (row) {
								$scope.emailDocument(row);
							},
							condition: function () {
								if ($scope.getOperations('C').Read)
									return true;

									return false;
								}
							},
							{
								setProperties: ACTIONS.DOCUMENTS,
								name: "Print Document",
								alwaysOnRow: true,
								alwaysOnTop: false,
								click: function (row) {
									$scope.printDocument(row);
								},
								condition: function () {
									if ($scope.getOperations().Print)
										return true;

									return false;
								}
							},
							{
								setProperties: ACTIONS.DELETE,
								name: "Delete Document",
								alwaysOnRow: true,
								alwaysOnTop: false,
								click: function (row) {
									$scope.deleteDocument(row);
								},
								condition: function () {
									if ($scope.isContractClosed()) return false;
									return $scope.getOperations().Delete ? true : false;
								}
							},
							// TODO: Need to also implement file location so we can show the file in the proper folder
							// {
							// 	setProperties: ACTIONS.VIEW_SOURCE,
							// 	alwaysOnRow: true,
							// 	alwaysOnTop: false,
							// 	click: function (row) {
							// 		$scope.viewSource(row);
							// 	}
							// }
						],
						dataWrapper: 'data',
						parseData: function (data, columns) {
							var data = data || [], retval = [], columnsLookup = {}, columns = columns || [];
							for (var j = 0; j < columns.length; j++) {
								columnsLookup[columns[j].name] = columns[j];
							}

						for (var i = 0; i < data.length; i++) {
							var cid = `previewContainer${i}`;
							var id = `preview${i}`;
							var preview = data[i].preview !== undefined 
								? `data:image/png;base64,${data[i].preview}`
								: data[i].previewLink;
							data[i]._display_name = '<h3 style="margin: 5px 0 0;" class="ellipsedText">' + data[i].displayName + '</h3>';
							data[i]._preview = `<div id="${cid}" class="pdf-markup-preview-container loading">
														<img id="${id}" style="display: none" onload="(function(){ $('#${cid}').removeClass('loading'); setTimeout(function(){ $('#${id}').fadeIn() }, 500); } ).call(this)" src="${preview}" />
													</div>`;

							// List view - text columns
							data[i]._display_name_list = $(data[i]._display_name).text();
							data[i]._file_version = data[i].fileVersion;
							data[i]._updated_by = $scope.getUserDisplayName(data[i].updatedBy);
							data[i]._updated_on = $scope.getDisplayDate(data[i].updatedOn);
							data[i]._created_by = $scope.getUserDisplayName(data[i].createdBy);
							data[i]._created_on = $scope.getDisplayDate(data[i].createdOn);

							// Counts
							data[i]._counts = '<div style="z-index: 1000" class="pdf-markup-button-container">';
							//data[i]._counts += `${$scope.getIconButton('mdi-comment', data[i].commentCount)}`;
							//// data[i]._counts += `${$scope.getIconButton('mdi-attachment', data[i].attachmentCount)}`;
							data[i]._counts += `${$scope.getIconButton('mdi-link', data[i].associationCount)}`;
							data[i]._counts += '</div>';
							$compile(data[i]._counts);

							retval.push(data[i]);
						}

						return retval;
					},
				});
			}

			$scope.getGridColumns();
		}

		function _getGridColumns() {
			if ($scope.module) {
				var result = { userIsInitiator: false, docNumAutoIncrementStart: 0, hasStartScreenFields: false };
				$scope.initiator = result.userIsInitiator ? result.userIsInitiator : false;

				$scope.manager.set_Columns({
					1: [
						{ name: '_display_name_list', label: 'Name', type: 'text', width: 55, onTablet: true, onPhone: true },
						{ name: '_file_version', label: 'Version', type: 'text', width: 5, onTablet: true, onPhone: false },
						{ name: '_updated_by', label: 'Updated By', type: 'text', width: 10, onTablet: true, onPhone: false },
						{ name: '_updated_on', label: 'Last Updated', type: 'text', width: 10, onTablet: true, onPhone: false },
						{ name: '_created_by', label: 'Owner', type: 'text', width: 10, onTablet: true, onPhone: false },
						{ name: '_created_on', label: 'Created On', type: 'text', width: 10, onTablet: true, onPhone: false }
					],
					2: [
						{ name: '_display_name', label: 'Name', type: 'checkbox' },
						{ name: '_preview', label: 'Preview', type: 'checkbox' },
						{ name: '_counts', label: 'Associations', type: 'checkbox' }
					]
				});

				$scope.manager.loading = true;
				$scope.manager = rememberManagerService.restore($scope.manager, $scope.module.moduleId, 
					function() {
						$scope.manager.loading = false;
					},
					function() {
						$scope.manager.loadPage()
						.finally(function () {
							$scope.manager.loading = false;
						});
					}
				);

			}
		}

		function _addNew() {
			$mdDialog.show({
				locals: {},
				controller: 'pdfMarkupAddController',
				templateUrl: '/ng/views/admin/modals/pdfMarkupAdd.html',
				parent: angular.element(document.body),
				targetEvent: event,
				fullscreen: true,
				escapeToClose: false,
				//multiple: true,
				clickOutsideToClose: false,
				disableParentScroll: true,
				multiple: true
			})
				.then(function (res) {
					$scope.viewDetail(res.documentId, res.fileId, res.fileName);
				}, function (result) {
					if (!$scope.manager)
						$scope.loadManager();

					$scope.manager.page = 1;
					$scope.manager.loadPage();
					$scope.setCurrent();
				});
		}

			function _deleteDocument2(row) {
				if (!row.isDeletable) {
					Message.error('This document cannot be deleted.');
					return;
				}
			}

		function _setCurrent(documentId = '', fileId = '', fileName = '') {
			$scope.documentId = documentId;
			$scope.fileId = fileId;
			$scope.fileName = fileName;
			$state.go('.', { d: documentId, f: fileId, n: fileName }, { notify: false });
		}

		// Gets JWT token for document and then loads it
		function _viewDetail(documentId, fileId, fileName, event) {
			$scope.setCurrent(documentId, fileId, fileName);
			$mdDialog.show({
				locals: {
					module: userService.system.modules[$scope.moduleCode],
					documentId: documentId,
					fileUrl: null,
					fileId: fileId,
					title: fileName,
					mimeType: 'application/pdf',
					entityInstanceId: userService.system.context.contract?.entityInstanceId,
					operations: $scope.operations
				},
				controller: 'pspdfkitViewerController',
				templateUrl: '/ng/views/admin/modals/pspdfkitViewer.html',
				parent: angular.element(document.body),
				targetEvent: event,
				fullscreen: true,
				escapeToClose: false,
				clickOutsideToClose: false,
				disableParentScroll: true,
				multiple: true
			})
				.then(function (result) {
					if (!$scope.manager)
						$scope.loadManager();

					$scope.manager.page = 1;
					$scope.manager.loadPage();
					$scope.setCurrent();

				}, function () {
					if (!$scope.manager)
						$scope.loadManager();

					$scope.manager.loadPage();
					$scope.setCurrent();
				});
		}

		function _getFlattenedDocument(row) {
			var p = $q.defer();
			// Flattened documents are always converted to PDF regardless of original file format
			var updatedFileName = row.displayName.slice(row.displayName.length - 3) == 'pdf'
				? row.displayName
				: row.displayName.slice(0, row.displayName.length - 3) + 'pdf';

			$http.get(`${URI.PDF_MARKUP.GET_FLATTENED_DOCUMENT}?documentId=${row.documentId}&moduleId=${$scope.moduleId}&entityInstanceId=${$scope.entityInstanceId}`)
				.then(function (res) {
					var file = fileService.createFile(res.data, updatedFileName, 'application/pdf');
					p.resolve(file);
				})
				.catch(function (err) {
					console.error('Failed to get flattened PDF', err);
					p.reject(err);
				});

			return p.promise;
		}

			var confirm = function (title, text, isMultiple) {
				var dialog = $mdDialog.confirm({
					title: title,
					textContent: text,
					ariaLabel: 'Confirm Dialogue',
					ok: 'Proceed',
					cancel: 'Cancel',
					multiple: isMultiple || false
				});
				return dialog;
			}

			function _deleteDocument(row) {
				$scope.manager.loading = true;

				$mdDialog.show(confirm('Delete Document', 'Are you sure you want to delete the \"' + row.displayName + '\" document?'))
					.then(function () {						
						$http[URI.PDF_MARKUP.DELETE_DOC.method](URI.PDF_MARKUP.DELETE_DOC.toString() + '?entityInstanceId=' + $scope.entityInstanceId + '&fileId=' + row.fileId)
							.then(function () {
								Message.info('Document deleted successfully');
								$scope.manager.page = 1;
								$scope.manager.loadPage();
								$scope.setCurrent();
							})
							.catch(function (e) {
								console.error(e);
								Message.error('Error deleting \"' + row.displayName + '\". The document could not be deleted.');
								$scope.manager.loading = false;
							})
							.finally(function () {

							});
					})
					.catch(function (err) {
						console.error('Document deletion canceled.');
						$scope.manager.loading = false;
					})						
			}

			function _emailDocument(row) {
				$scope.manager.loading = true;
				$scope.getFlattenedDocument(row)
					.then(function (pdf) {
						$scope.manager.emailPdf($scope, pdf);
					})
					.catch(function (err) {
						console.error('Email failed.');
						$scope.manager.loading = false;
					});
			}

		function _printDocument(row) {
			$scope.manager.loading = true;
			/*
				TODO: Need to add option to include linked docs (and eventually images) when printing.
				Would like to include a "drawing summary" on each print at the end of the doc, regardless.
			*/
			$scope.getFlattenedDocument(row)
				.then(function (pdf) {
					$scope.manager.printPdf(pdf);
				})
				.catch(function (err) {
					console.error('Print failed.');
					$scope.manager.loading = false;
				});
		}

		function _viewSource(row) {
			$state.go('tenant.filecontractmanager', { tenantIdentifier: params.tenantIdentifier, f: row.fileId });
		}

		/// Returns an HTML button for markup counts since the manager can't use ng-if when parsing data.
		function _getIconButton(icon, count) {
			var button = `<md-button class="md-icon-button pdf-markup-button">
			<i class="mdi ${icon}"></i>`;
			var closingTag = '</md-button>';

			return count > 0
				? `${button}<span class="pdf-markup-button-count">${count}</span>${closingTag}`
				: `${button}${closingTag}`;
		}

		// Returns a list of users for the current contract
		function _getUserDict() {
			var deferred = $q.defer();
			$http.get(`${URI.PDF_MARKUP.GET_USER_DICT}?entityInstanceId=${$scope.entityInstanceId}`)
				.then(function (res) {
					$scope.userDict = res.data;
					deferred.resolve();
				})
				.catch(function (err) {
					console.error('Failed to get user dict', err);
					deferred.reject(err);
				});

			return deferred.promise;
		}

		// Returns a display value by user id
		function _getUserDisplayName(userId) {
			const user = $scope.userDict.find(x => x.id == userId);
			return user ? user.name : '';
		}

		function _getDisplayDate(datetime) {
			return userService.formatDate(datetime);
		}

		function _getComments() {
			//comments button clicked!
		}

		function _isContractClosed() {
			return userService.isContractClosed(null, $scope.entityInstanceId);
		}
	});
