export const DEBOUNCE_TIMES = {
  default: 500,
  short: 200,
} as const;

export const DATE_TIME_FORMATS = {
  date: 'YYYY-MM-DD',
  time: 'HH:mm:ss',
  dateTime: 'YYYY-MM-DD HH:mm',
  dateTimeIso: '', // equivalent to ISO8601, zulu format
  dateIso: 'YYYY-MM-DD[T00:00:00Z]',
} as const;

export const FILE_SIZE_CONVERT = {
  KB: 1024,
  MB: 1024 * 1024,
  GB: 1024 * 1024 * 1024,
} as const;

export const FILE_SIZES = {
  maxFileSize: 30 * 1024 * 1024, // 30MB
  chunkSize: 25 * 1024 * 1024, // 20MB
} as const;
