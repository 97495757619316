<div class="cipo">
    @if (label()) {
        <p class="froala-label" [ngClass]="{ 'cipo-text--warn-700': invalid() }">
            {{ label() }}
            <span *ngIf="required() && editMode()">*</span>
        </p>
    }
    @if (editMode()) {
        <div class="cipo-froala" [ngClass]="{ error: invalid() }">
            <textarea [froalaEditor]="froalaOptions" [(froalaModel)]="content" required></textarea>
            @if (hint()) {
                <p class="m-t-sm m-b-0">{{ hint() }}</p>
            }
            @if (tributeOptions().variables) {
                <p class="text-size--label m-t-sm">{{ "FROALA.variablesHint" | translate }}</p>
            }
            @if (tributeOptions().mentions) {
                <p class="text-size--label m-t-sm">{{ "FROALA.mentionsHint" | translate }}</p>
            }
            @if (invalid()) {
                <p class="text-size--label m-t-sm error">{{ "FROALA.required" | translate }}</p>
            }
        </div>
    } @else {
        <div [froalaView]="content() ?? ''" class="p-b-sm border-bottom--dashed view-mode"></div>
    }
</div>
