import { AsyncValidatorFn, FormArray, FormControl, FormControlOptions, FormGroup, ValidatorFn } from '@angular/forms';
import { KeyValueListType, KeyValueType } from '../../../../models/common';
import { AttachmentModel, FormFieldValueType, MimeTypesEnum } from '../../../../models/module/fields/main';
import { TableFieldConfig } from '../../../../module/definition/table-field/common';
import { Currency } from '../../../../models/system';

type CipoFieldBaseConfig = {
  type?: CipoFieldTypes;
  label?: string;
  placeholder?: string;
  hint?: string;
  fieldDescription?: string;
  cleanReadOnly?: boolean;
  readonly?: boolean;
  required?: boolean;
  prefix?: string;
  prefixIcon?: string;
  suffix?: string;
  suffixIcon?: string;
  preventClearInput?: boolean;
  position?: {
    x: number;
    y: number;
    cols: number;
    rows: number;
  };
};

export type CipoTextConfig = CipoFieldBaseConfig & {
  maxLength?: number;
  multiple?: boolean;
  multiline?: boolean;
  focused?: boolean;
};

export type CipoNumberConfig = CipoFieldBaseConfig & {
  showThousandSeparator?: boolean;
  min?: number;
  max?: number;
  decimals?: number;
  multiple?: boolean;
  currency?: Currency;
  focused?: boolean;
};

// TODO: change options type to allow number key. Avoid any.
export type CipoListConfig = CipoFieldBaseConfig & {
  options?: KeyValueListType<any>[];
  columns?: number;
  multiple?: boolean;
  disabled?: boolean;
};

export type CipoCheckboxConfig = CipoFieldBaseConfig;

export type CipoDateTimeSelectMode = 'single' | 'range';
export type CipoDateTimePickerType = 'dateTime' | 'date' | 'time';

export type CipoDateTimeConfig = CipoFieldBaseConfig & {
  minDate?: string;
  maxDate?: string;
  maxRange?: number;
  hour12Timer?: boolean;
  selectMode?: CipoDateTimeSelectMode;
  pickerType?: CipoDateTimePickerType;
};

export type CipoAttachmentConfig = CipoFieldBaseConfig & {
  maxFiles?: number;
  maxFileSize?: number;
  allowedMimeTypes?: MimeTypesEnum[];
  showMultipleSave?: boolean;
};

export type CipoTableConfig = CipoFieldBaseConfig & {
  tableForm?: FormArray<CipoFormGroup>;
  tableFields?: TableFieldConfig[];
  showPosition?: boolean;
};

/** Union of all field configs that can be inside a table field */
export type CipoTableFieldsConfig = CipoTextConfig &
  CipoNumberConfig &
  CipoDateTimeConfig &
  CipoListConfig &
  CipoCheckboxConfig &
  CipoAttachmentConfig;

/** Union of all possible configs */
export type CipoFieldConfig = CipoTextConfig &
  CipoNumberConfig &
  CipoDateTimeConfig &
  CipoListConfig &
  CipoCheckboxConfig &
  CipoAttachmentConfig &
  CipoTableConfig;

export enum CipoFieldTypes {
  Text = 'Text',
  Number = 'Number',
  DateTime = 'DateTime',
  Select = 'Select',
  Listbox = 'Listbox',
  Checkbox = 'Checkbox',
  Attachment = 'Attachment',
  RichText = 'RichText',
  Annotation = 'Annotation',
  Table = 'Table',
}

export interface FormItem {
  id: string;
  value?: CipoFieldValue;
  fieldOptions?: CipoFieldConfig;
}

export type CipoFieldValue = FormFieldValueType; //string | number | boolean | string[] | number[] | AttachmentModel[];
export type CipoFormGroup<TValue = CipoFieldValue, TField = CipoFieldConfig> = FormGroup<{
  [key: string]: CipoControl<TValue, TField>;
}>;

export class CipoControl<TValue = CipoFieldValue, TField = CipoFieldConfig> extends FormControl<TValue> {
  fieldData?: TField;

  constructor(
    formState: TValue,
    fieldData?: TField,
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | FormControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
  ) {
    super(formState, validatorOrOpts, asyncValidator);
    this.fieldData = fieldData;
  }
}

export class CipoTextControl extends CipoControl<string | string[], CipoTextConfig> {}

export class CipoNumberControl extends CipoControl<number | number[], CipoNumberConfig> {}

export class CipoDateTimeControl extends CipoControl<string | string[], CipoDateTimeConfig> {}

// TODO: check above from CipoListConfig
export class CipoListControl extends CipoControl<any, CipoListConfig> {}

export class CipoCheckboxControl extends CipoControl<boolean, CipoCheckboxConfig> {}

export class CipoAttachmentControl extends CipoControl<AttachmentModel[], CipoAttachmentConfig> {}

export type CipoForm = FormGroup<{ [x: string]: CipoControl }>;
