import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { ToastrModule } from 'ngx-toastr';

import { LegacyIcons } from '../../legacy/constants';
import { ModuleIconListComponent } from '../components/module-icon-list/module-icon-list.component';
import { ModuleIconComponent } from '../components/module-icon/module-icon.component';
import { NumberProgressBarComponent } from '../components/number-progress-bar/number-progress-bar.component';
import { MaterialModule } from './material.module';
import { PipesModule } from './pipes.module';
import { DataListComponent } from '../components/data-list/data-list.component';
import { DataListCommandComponent } from '../components/data-list/data-list-command/data-list-command.component';
import { FroalaComponent } from 'src/app/shared/components/froala/froala.component';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { DataListFiltersComponent } from 'src/app/shared/components/data-list/data-list-command/filters/data-list-filters.component';

@NgModule({
  declarations: [
    NumberProgressBarComponent,
    ModuleIconListComponent,
    DataListComponent,
    DataListCommandComponent,
    FroalaComponent,
  ],
  exports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    NgxSkeletonLoaderModule,
    PipesModule,
    TranslateModule,
    NumberProgressBarComponent,
    ModuleIconComponent,
    ModuleIconListComponent,
    DataListComponent,
    DataListCommandComponent,
    FroalaComponent,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ModuleIconComponent,
    FlexLayoutModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgxSkeletonLoaderModule,
    PipesModule,
    TranslateModule,
    DataListFiltersComponent,
    ToastrModule.forRoot(), // ToastrModule added
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
  ],
  providers: [LegacyIcons],
})
export class SharedComponentsModule {}
