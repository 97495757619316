import { cipo } from 'cipo';

cipo.factory('officeService', function ($location, userService) {

    var officeService = {};

    officeService.open = function(row, readOnly) {
        const data = {
            fileId: row.versionId,
            fileName: row.name,
            ext: row.name.substring(row.name.lastIndexOf(".") + 1),
            action: readOnly ? 'view' : 'edit',
            readOnly: readOnly,
        };

        var tenantIdentifier = userService.getTenantIdentifierById(userService.tenantId);
        var baseUrl = $location.absUrl().replace($location.url(), '');
        var url = baseUrl + '/' + tenantIdentifier + '/office?f=' + officeService.officeEncodeData(data);
        window.open(url, "_blank");
    }

    officeService.encodeText = function(text) {
        const bytes = new TextEncoder().encode(text);
        const binString = String.fromCodePoint(...bytes);
        return btoa(binString);
    }

    officeService.encodeData = function(data) {
        const text = JSON.stringify(data);
        return officeService.encodeText(text);
    }

    officeService.officeEncodeData = function(data) {
        const ed = officeService.encodeData(data).replaceAll('=', '');
        return officeService.encodeText(`eY5CUm${ed}oFBQ70`).replaceAll('=', '');
    }

    return officeService;
});