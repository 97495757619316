import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, forkJoin, map, of, tap } from 'rxjs';

import { KeyValueType } from '../../../models/common';
import { MainListModel } from '../../../models/list/mainListModel';
import {
  SETTINGS_TYPES,
  TenantSetting,
  TenantSettingListIds,
  TenantSettingListValues,
  TenantSettingsEnum,
} from '../../../models/tenant';
import { TableColumn } from '../../../shared/components/data-list/common';
import { HttpService } from '../../../shared/services';

@Injectable()
export class TenantSettingsService {
  translate = inject(TranslateService);
  http = inject(HttpService);

  updateSetting(setting: TenantSetting) {
    return this.http
      .put<void>(`_api/TenantSettings/Update`, setting, { useLoader: false })
      .pipe(map(() => setting.value));
  }

  getTenantSettings(): Observable<TenantSetting[]> {
    return this.http.get<TenantSetting[]>('_api/TenantSettings/List', { useLoader: false }).pipe(
      map(settings =>
        settings.map(s => {
          const type = SETTINGS_TYPES[s.id];
          let value = s.value;
          switch (type) {
            case 'rangePercent':
            case 'boolean':
              value = +s.value;
              break;
            case 'select':
              value = !isNaN(+s.value) ? +s.value : s.value;
          }
          return {
            ...s,
            type,
            value,
          };
        }),
      ),
    );
  }

  getSettingOptions(id: TenantSettingsEnum): Observable<KeyValueType<number | string>[]> {
    if (Array.from(TenantSettingListValues.keys()).includes(id)) {
      return of(TenantSettingListValues.get(id));
    }
    switch (id) {
      case TenantSettingsEnum.load_more:
        const res = [1, 2].map(key => ({ key, value: this.translate.instant('tenantSetting.loadMore.' + key) }));
        return of(res);
      case TenantSettingsEnum.time_zone:
        return this.http
          .get<KeyValueType[]>('_api/TenantSettings/Timezones', { useLoader: false })
          .pipe(map(timezones => timezones.map(tz => ({ key: tz.value, value: tz.value }))));
      default:
        return of([]);
    }
  }

  getListsValues(): Observable<MainListModel[]> {
    const keys = Array.from(TenantSettingListIds.keys());
    return forkJoin(
      keys.map(id => this.http.get<MainListModel>(`_api/ListsDefinition/Details?id=${TenantSettingListIds.get(id)}`)),
    ).pipe(
      tap(lists =>
        lists.forEach((list, index) =>
          TenantSettingListValues.set(
            keys[index],
            (list.elements || []).map(e => ({ key: e.id, value: e.name })),
          ),
        ),
      ),
    );
  }

  getColumns(): Observable<TableColumn[]> {
    return this.translate.get('general.yes').pipe(
      map(() => [
        {
          id: 2,
          name: 'name',
          displayName: this.translate.instant('general.table.name'),
          sortable: true,
          width: 2,
        },
        {
          id: 3,
          name: 'description',
          displayName: this.translate.instant('general.table.description'),
          sortable: true,
          width: 5,
        },
        {
          id: 4,
          name: 'value',
          displayName: this.translate.instant('general.table.value'),
          width: 2,
        },
      ]),
    );
  }
}
