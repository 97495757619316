<mat-toolbar class="header-wrapper cipo-bg--primary-100" [class.menu-open]="userStore.menu.isOpen()">
    <button
        mat-icon-button
        [class.menu-button-close]="userStore.menu.isOpen()"
        aria-label="Menu"
        (click)="userStore.toggleMenu()"
        data-test="cy_sidebar-toggle">
        <mat-icon>{{ userStore.menu.isOpen() ? "close" : "menu" }}</mat-icon>
    </button>
    <img src="/src/assets/images/CIPO_logo.png" width="95" class="logo-wrapper" (click)="logoClick()" />
    <div class="context-container">
        <cipo-context-view
            [context]="userStore.userData().context"
            (contextChange)="contextChanged($event)"
            (contextData)="contextDataLoaded($event)"></cipo-context-view>
    </div>
    <div fxLayout fxLayoutGap="12px" fxLayoutAlign="end center">
        @if (canOpenContract()) {
            <button
                fxShow
                fxHide.lt-md
                mat-icon-button
                (click)="openContracts()"
                [title]="'header.editContract' | translate">
                <mat-icon class="material-symbols-outlined">contract_edit</mat-icon>
            </button>
        }
        @if (canOpenCorrespondence()) {
            <button
                fxShow
                fxHide.lt-md
                mat-icon-button
                (click)="goToCorrespondence()"
                [title]="'header.goToCorrespondence' | translate">
                <mat-icon class="material-symbols-outlined" [matBadge]="userStore.unreadNotifications() || null">
                    mail
                </mat-icon>
            </button>
        }

        <app-account-menu></app-account-menu>
    </div>
</mat-toolbar>
