import { cipo } from 'cipo';

cipo.directive("topformula", function (AggregateOperators, AggregateOperatorsDate, FIELDS_CONFIG, validationService, Dictionaries, Message) {
    return {
        restrict: "E",
        replace: true,
        scope: {
            "datalist": "=",
            "isDateResult": "=",
            "disabled": "=?"
        },
        templateUrl: "/ng/views/directives/tabdetails/topformula.html",
        controller: function ($scope) {
            $scope.isNegativeError = false;

            // process existing formula
            // $scope.datalist.currentFormula.expression = '10+SUM({{1970}})*21-AVG({{1997}})*15';
            var doom = [], index = 0;

            // operands
            $scope.functionsDict = [];
            $scope.functionsDateDict = [];
            var functionsLookup = {};

            $scope.disabled = $scope.disabled || false;

            $scope.operatorDict = [
                { key: 1, type: "operator", value: "+", class: "mdi mdi-plus", forDate: true },
                { key: 2, type: "operator", value: "-", class: "mdi mdi-minus", forDate: true },
                { key: 3, type: "operator", value: "*", class: "mdi mdi-close", forDate: false },
                { key: 4, type: "operator", value: "/", class: "mdi mdi-division", forDate: false },
            ]

            $scope.operatorDateDict = [
                { key: 1, type: "operator", value: "+", class: "mdi mdi-plus" },
                { key: 2, type: "operator", value: "-", class: "mdi mdi-minus" }
            ]

            $scope.dateFormulaDict = [
                { key: 1, value: "DATEADD", type: "function", visible: $scope.isDateResult, hasDatePart: true, has2ndParameter: "number", has3rdParameter: "date", 
                  description: "Returns a new datetime value by adding an interval to the specified datepart of the specified date." },
                { key: 2, value: "DATEDIFF", type: "function", visible: !$scope.isDateResult, hasDatePart: true, has2ndParameter: "date", has3rdParameter: "date", 
                  description: "Returns the number of date or time datepart boundaries, crossed between two specified dates." }
            ]

            $scope.dateFormulaDictVisibleCount = $scope.dateFormulaDict.filter(f => f.visible).length;

            $scope.datePartDict = [
                { key: "day", value: "day" },
                { key: "week", value: "week" },
                { key: "month", value: "month" },
                { key: "year", value: "year" }
            ]

            var operatorsLookup = {
                "+": $scope.operatorDict[0],
                "-": $scope.operatorDict[1],
                "*": $scope.operatorDict[2],
                "/": $scope.operatorDict[3]
            }

            var CONFIG = angular.copy(FIELDS_CONFIG());

            $scope.checkNumber = function (n) {
                $scope.isNegativeError = (n * 1) < 0;
            }

            $scope.setFormulara = function () {
                // first check if it is just a number
                // ..
                if ($scope.datalist.currentFormula.expression) {
                    $scope.isOperatorTurn = true;

                    // split the expression
                    for (var i = 0; i < $scope.datalist.currentFormula.expression.length; i++) {
                        if (["/", "*", "+", "-"].indexOf($scope.datalist.currentFormula.expression[i]) != -1) {
                            // We can have fields with minus, exclude that minus
                            if ((i - 2) < 0 || (i - 2) >= 0 && $scope.datalist.currentFormula.expression.substring(i - 2, i) != '{{') {
                                doom.push($scope.datalist.currentFormula.expression.substring(index, i));
                                doom.push($scope.datalist.currentFormula.expression[i]);
                                index = i + 1;
                            }
                        }
                        if (["(", ")"].indexOf($scope.datalist.currentFormula.expression[i]) != -1) {
                            if (!$scope.verifyNotAggregate(i, $scope.datalist.currentFormula.expression[i])) {
                                doom.push($scope.datalist.currentFormula.expression.substring(index, i));
                                doom.push($scope.datalist.currentFormula.expression[i]);
                                index = i + 1;
                            }
                        }
                        if (i == $scope.datalist.currentFormula.expression.length - 1)
                            doom.push($scope.datalist.currentFormula.expression.substring(index, $scope.datalist.currentFormula.expression.length));
                    }

                    for (var i = 0; i < doom.length; i++) {
                        if (doom[i].indexOf("DATEADD") == 0 || doom[i].indexOf("DATEDIFF") == 0) {
                            var functionName = doom[i].substring(0, doom[i].indexOf('('));
                            var parameters = doom[i].substring(doom[i].indexOf('(') + 1, doom[i].indexOf(')')).split(',');
                            var selectedFunction = angular.copy($scope.dateFormulaDict.find(f => f.value == functionName));
                            selectedFunction.isDate = true;

                            if (selectedFunction.hasDatePart && parameters && parameters.length > 0) {
                                selectedFunction.datePart = $scope.datePartDict.find(f => f.value == parameters[0]);
                            }
                            if (selectedFunction.has2ndParameter && parameters && parameters.length > 1) {
                                if (parameters[1].indexOf('{{') == 0) {
                                    var fieldId = parameters[1].replace('{{', '').replace('}}', '');
                                    selectedFunction.secondParameter = $scope.datalist.numericFieldsLookup[fieldId] || $scope.datalist.dateFieldsLookup[fieldId];
                                    if (selectedFunction.has2ndParameter == 'number') {
                                        selectedFunction.showNumberField = true;
                                    }
                                } else {
                                    selectedFunction.secondParameter = parseInt(parameters[1]);
                                }
                            }
                            if (selectedFunction.has3rdParameter && parameters && parameters.length > 2) {
                                if (parameters[2].indexOf('{{') == 0) {
                                    var fieldId = parameters[2].replace('{{', '').replace('}}', '');
                                    selectedFunction.thirdParameter = $scope.datalist.numericFieldsLookup[fieldId] || $scope.datalist.dateFieldsLookup[fieldId];
                                    if (selectedFunction.has3rdParameter == 'number') {
                                        selectedFunction.showNumberField = true;
                                    }
                                } else {
                                    selectedFunction.thirdParameter = parameters[2];
                                }
                            }
                            $scope.datalist.currentFormula.formula.push(selectedFunction);
                        }else if (["SUM", "AVG", "MIN", "MAX", "COUNT"].indexOf(doom[i].substring(0, 3)) != -1) {
                            var selectedFunction = angular.copy(functionsLookup[doom[i].substring(0, 3)]);
                            var fieldId = doom[i].substring(6, doom[i].length - 3);
                            selectedFunction.field = $scope.datalist.numericFieldsLookup[fieldId] || $scope.datalist.dateFieldsLookup[fieldId];
                            selectedFunction.isDate = $scope.datalist.dateFieldsLookup.hasOwnProperty(fieldId);
                            $scope.datalist.currentFormula.formula.push(selectedFunction);
                        } else if (["/", "*", "+", "-"].indexOf(doom[i]) != -1) {
                            $scope.datalist.currentFormula.formula.push(operatorsLookup[doom[i]]);
                        } else if (["(", ")"].indexOf(doom[i]) != -1) {
                            if (doom[i] == "(")
                                $scope.datalist.currentFormula.formula.push({ key: -10, type: "parenthesis", value: "(" });
                            else
                                $scope.datalist.currentFormula.formula.push({ key: -11, type: "parenthesis", value: ")" });
                        } else {
                            if (doom[i])
                                $scope.datalist.currentFormula.formula.push({ value: parseFloat(doom[i]), type: "number" });
                        }
                    }
                }

                $scope.datalist.currentFormula.numericFields = $scope.datalist.numericFields;
                $scope.datalist.currentFormula.dateFields = $scope.datalist.dateFields;
                $scope.datalist.isBusy = false;
            }

            $scope.verifyNotAggregate = function (i, parenthesis) {
                if (parenthesis == "(" && $scope.datalist.currentFormula.expression.length >= (i + 3) && $scope.datalist.currentFormula.expression.substring(i + 1, i + 3) == '{{')
                    return true;

                if (parenthesis == "(" && i > 6 && $scope.datalist.currentFormula.expression.substring(i - 7, i) == 'DATEADD')
                    return true;

                if (parenthesis == "(" && i > 7 && $scope.datalist.currentFormula.expression.substring(i - 8, i) == 'DATEDIFF')
                    return true;

                if (parenthesis == ")" && i > 2 && $scope.datalist.currentFormula.expression.substring(i - 2, i) == '}}')
                    return true;

                return false;
            }

            $scope.initFormula = function () {

                $scope.datalist.currentFormula = $scope.datalist.currentFormula || {
                    label: $scope.datalist.label,
                    id: $scope.datalist.id,
                    typeId: 2,
                    displayTypeId: 1,
                    cols: 4,
                    formattings: [],
                    rows: 1,
                    expression: $scope.datalist.expression
                };

                var additionalInfo = {
                    errors: { label: "" },
                    minItemCols: 3,
                    maxItemRows: 1,
                    formula: [],
                    numericFields: [],
                    dateFields: []
                }

                for (var key in additionalInfo) {
                    if (additionalInfo.hasOwnProperty(key))
                        $scope.datalist.currentFormula[key] = additionalInfo[key];
                }

                // defaults by typeId and displayTypeId
                var formulaDefaults = CONFIG.fieldTypesMapping[$scope.datalist.currentFormula.typeId][$scope.datalist.currentFormula.displayTypeId];

                // attach them to the formula field
                $scope.datalist.currentFormula.formattingsList = [];
                $scope.datalist.currentFormula.numericFields = [];
                $scope.datalist.currentFormula.dateFields = [];

                // replace defaults with formula formattings
                if (($scope.datalist.currentFormula.formattings || []).length) {
                    for (var i = 0; i < $scope.datalist.currentFormula.formattings.length; i++) {
                        CONFIG.formattingsMapping[$scope.datalist.currentFormula.formattings[i].key].value = $scope.datalist.currentFormula.formattings[i].value;
                        CONFIG.formattingsMapping[$scope.datalist.currentFormula.formattings[i].key].formattingValue = $scope.datalist.currentFormula.formattings[i].formattingValue;
                        CONFIG.formattings[CONFIG.formattingsMapping[$scope.datalist.currentFormula.formattings[i].key].name].value = $scope.datalist.currentFormula.formattings[i].value;
                        CONFIG.formattings[CONFIG.formattingsMapping[$scope.datalist.currentFormula.formattings[i].key].name].formattingValue = $scope.datalist.currentFormula.formattings[i].formattingValue;
                    }
                }

                for (var i = 0; i < formulaDefaults.formattingsList.length; i++) {
                    var formatting = CONFIG.formattings[formulaDefaults.formattingsList[i]];
                    if (!formatting.value) formatting.value = formulaDefaults.defaultFormattingIds[i];
                    $scope.datalist.currentFormula.formattingsList.push(formatting);
                }

                for (var key in AggregateOperators) {
                    if (AggregateOperators.hasOwnProperty(key)) {
                        $scope.functionsDict.push({ key: parseInt(key), value: AggregateOperators[key], type: "function", field: null });

                        functionsLookup[AggregateOperators[key]] = {
                            key: parseInt(key),
                            value: AggregateOperators[key],
                            type: "function",
                            field: null
                        };
                    }
                }

                for (var key in AggregateOperatorsDate) {
                    if (AggregateOperatorsDate.hasOwnProperty(key)) {
                        $scope.functionsDateDict.push({ key: parseInt(key), value: AggregateOperatorsDate[key], type: "function", field: null });
                    }
                }

                $scope.datalist.isBusy = true;

                // Get fields if numericFields are null
                if (!$scope.datalist.numericFields && !$scope.datalist.dateFields) {
                    Dictionaries.ModuleFieldsRelationByType({ fieldId: $scope.datalist.id }, { moduleId: $scope.datalist.moduleId })
                        .then(function (r) {
                            if (r) {
                                $scope.datalist.numericFields = r.filter(f => !f.isDate);
                                $scope.datalist.dateFields = r.filter(f => f.isDate);

                                $scope.datalist.numericFieldsLookup = {};
                                $scope.datalist.dateFieldsLookup = {};

                                for (var i = 0; i < r.length; i++) {
                                    if (r[i].isDate) {
                                        $scope.datalist.dateFieldsLookup[r[i].key] = r[i];
                                    }
                                    else {
                                        $scope.datalist.numericFieldsLookup[r[i].key] = r[i];
                                    }
                                }
                            }

                            $scope.setFormulara();
                        })
                }
                else {
                    $scope.setFormulara();
                }
            }

            $scope.initFormula();

            $scope.$watch(function () {
                return $scope.datalist.currentFormula;
            }, function () {
                if (!$scope.datalist.currentFormula) {

                    doom = [];
                    index = 0;
                    $scope.functionsDict = [];
                    $scope.functionsDateDict = [];
                    functionsLookup = {};

                    $scope.initFormula();
                }
            });

            // operands actions
            var clearOperatorError = function () {
                if ($scope.datalist.currentFormula.formula[$scope.datalist.currentFormula.formula.length - 1])
                    $scope.datalist.currentFormula.formula[$scope.datalist.currentFormula.formula.length - 1].hasError = false;
            }

            $scope.addFormula = function () {
                clearOperatorError();
                $scope.datalist.currentFormula.formula.push(angular.copy($scope.functionsDict[0]));
                $scope.isOperatorTurn = true;
            }

            $scope.filterNumericFieldForDate = function () {
                return $scope.datalist.numericFields.filter(n => n.isNumberFormatting);
            }

            $scope.addDate = function () {
                clearOperatorError();

                // get last operator
                var operators = $scope.datalist.currentFormula.formula.filter(o => o.type == 'operator');
                // Check if we have any operators
                if (operators && operators.length) {
                    var lastOperator = operators.pop();
                    if ($scope.operatorDateDict.filter(o => o.key == lastOperator.key).length == 0) {
                        Message.error('Last operator is not allowed for date, please change it before adding the date field.');
                        return;
                    }
                }

                var formula = angular.copy($scope.dateFormulaDict.find(f => f.visible));
                formula.isDate = true;
                formula.showNumberField = true;
                formula.datePart = $scope.datePartDict.find(d => d.key == 'day');

                $scope.datalist.currentFormula.formula.push(formula);
                $scope.isOperatorTurn = true;
            }

            $scope.addOpperator = function () {
                $scope.datalist.currentFormula.formula.push(angular.copy($scope.operatorDict[0]));
                $scope.isOperatorTurn = false;
            }

            $scope.addNumber = function () {
                clearOperatorError();
                $scope.datalist.currentFormula.formula.push({ value: 0, type: "number" });
                $scope.isOperatorTurn = true;
            }

            $scope.addStartParenthesis = function () {
                $scope.datalist.currentFormula.formula.push({ key: -10, type: "parenthesis", value: "(" });
            }

            $scope.addEndParenthesis = function () {
                $scope.datalist.currentFormula.formula.push({ key: -11, type: "parenthesis", value: ")" });
            }

            $scope.changeFunction = function (item, index) {
                var oldValueField = $scope.datalist.currentFormula.formula[index].field;
                $scope.datalist.currentFormula.formula[index] = angular.copy(item);
                $scope.datalist.currentFormula.formula[index].field = oldValueField;
            }

            $scope.changeDatePart = function (item, index) {
                $scope.datalist.currentFormula.formula[index].datePart = angular.copy(item);
            }

            $scope.switchNumberParameter = function (index) {
                $scope.datalist.currentFormula.formula[index].secondParameter = null;
                $scope.datalist.currentFormula.formula[index].showNumberField = !$scope.datalist.currentFormula.formula[index].showNumberField;
            }

            $scope.change2ndParameter = function (item, index) {
                $scope.datalist.currentFormula.formula[index].secondParameter = angular.copy(item);
            }

            $scope.change3rdParameter = function (item, index) {
                $scope.datalist.currentFormula.formula[index].thirdParameter = angular.copy(item);
            }

            $scope.disableParenthesis = function (isStart) {
                if ($scope.datalist.currentFormula.formula && $scope.datalist.currentFormula.formula.length) {
                    var lastFormula = $scope.datalist.currentFormula.formula[$scope.datalist.currentFormula.formula.length - 1];

                    if (isStart)
                        return lastFormula && lastFormula.type == 'parenthesis' && lastFormula.value == ')'
                            || lastFormula && lastFormula.type != 'operator' && lastFormula.value != '(';
                    else
                        return lastFormula && lastFormula.type == 'parenthesis' && lastFormula.value == '('
                            || lastFormula && lastFormula.type == 'operator';
                }

                return false;
            }

            $scope.changeField = function (f, index) {
                $scope.datalist.currentFormula.formula[index].field = f;
                $scope.datalist.currentFormula.formula[index].hasError = false;
            }
            $scope.changeOperator = function (item, index) {
                $scope.datalist.currentFormula.formula[index] = item;
            }

            $scope.removeItem = function (item) {
                index = $scope.datalist.currentFormula.formula.indexOf(item);
                if ($scope.datalist.currentFormula.formula[index + 1]) $scope.datalist.currentFormula.formula.splice(index + 1, 1);
                $scope.datalist.currentFormula.formula.splice(index, 1);
            }

            $scope.removeOperator = function () {
                $scope.datalist.currentFormula.formula.pop();
            }

            var currentFormulaBkp;

            $scope.saveRemove = function () {
                if (!$scope.datalist.currentFormula.formula.length) {
                    $scope.isOperatorTurn = false;
                } else {
                    if ($scope.datalist.currentFormula.formula[$scope.datalist.currentFormula.formula.length - 1].type == "operator")
                        $scope.isOperatorTurn = false;
                    else $scope.isOperatorTurn = true;
                }
                $scope.isDeleteMode = false;
                currentFormulaBkp = angular.copy($scope.datalist.currentFormula.formula);
            }

            $scope.enterDeleteMode = function () {
                $scope.isDeleteMode = true;
                currentFormulaBkp = angular.copy($scope.datalist.currentFormula.formula);
            }

            $scope.cancelDeleteMode = function () {
                $scope.isDeleteMode = false;
                $scope.datalist.currentFormula.formula = angular.copy(currentFormulaBkp);
            }

            $scope.openMenu = function ($mdMenu, ev) {
                $mdMenu.open(ev);
            };

            $scope.saveFormula = function () {

                var result = validationService.validateExpression($scope.datalist.currentFormula, $scope.datalist.typeId);

                $scope.datalist.currentFormula.expression = result[0];
                $scope.datalist.currentFormula.formattings = result[1];

                $scope.datalist.saveTopFormula();
            }

            $scope.$on("$mdMenuClose", function () {
                try {
                    $scope.datalist.searchFields = '';
                    $scope.datalist.searchDateFields = '';
                } catch { }
            });

            $scope.getOperators = function (index) {

                if (index > 0 && $scope.datalist.currentFormula.formula[index - 1].isDate) {
                    return $scope.operatorDict.filter(f => f.forDate);
                }

                if ((index + 1) < $scope.datalist.currentFormula.formula.length && $scope.datalist.currentFormula.formula[index + 1].isDate) {
                    return $scope.operatorDict.filter(f => f.forDate);
                }

                return $scope.operatorDict;
            }
        }
    }
});
