import { inject, Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { KeyValueRestrictionModel, RestrictionType, RestrictionValueType } from '../../models/module/fields/enums';
import { UserStore } from '../../signal-store';

@Pipe({ name: 'itvDateTimeWithRestrictions' })
export class ItvDateTimeWithRestrictionsPipe implements PipeTransform {
  userStore = inject(UserStore);
  dateCustomPipe = inject(ItvDateTimeCustomFormatPipe);

  transform(value: string | Date, restrictions: KeyValueRestrictionModel[] = [], defaultValue: string = ''): string {
    const dateFormat = this.userStore.calculatedUserSettings()?.dateFormat;
    const timeFormat = this.userStore.calculatedUserSettings()?.timeFormat;
    return this.transformValue(value, restrictions, defaultValue, dateFormat, timeFormat);
  }

  private transformValue(
    value: string | Date,
    restrictions: KeyValueRestrictionModel[],
    defaultValue: string,
    dateFormat: string,
    timeFormat: string,
  ): string {
    const datetimeFormatting = (restrictions || []).find(f => f.key == RestrictionType.DateTime);
    if (!datetimeFormatting || !dateFormat || !timeFormat) {
      return defaultValue;
    }

    // when formatting with restriction, only datetime restriction handles timezone
    let handleTimezone = false;

    let format = undefined;
    switch (datetimeFormatting.value) {
      case RestrictionValueType.Date:
        format = dateFormat;
        break;
      case RestrictionValueType.DateTime:
        format = `${dateFormat} ${timeFormat}`;
        handleTimezone = true;
        break;
      case RestrictionValueType.Time:
        format = timeFormat;
        handleTimezone = true;
        break;
      default:
        handleTimezone = true;
        return defaultValue;
    }

    return this.dateCustomPipe.transform(value, format, handleTimezone, defaultValue);
  }
}

@Pipe({ name: 'itvDateTimeCustomFormat' })
export class ItvDateTimeCustomFormatPipe implements PipeTransform {
  userStore = inject(UserStore);

  transform(value: string | Date, format: string, handleTimezone: boolean = true, defaultValue: string = ''): string {
    if (!value || !format) {
      return defaultValue;
    }

    if (typeof value == 'string') {
      const hasTimezone = value.endsWith('Z');
      if (handleTimezone && !hasTimezone) {
        value = value + 'Z';
      } else if (!handleTimezone && hasTimezone) {
        value = value.slice(0, -1);
      }
    }

    const timeZoneOffsetMinutes = !handleTimezone ? 0 : (this.userStore.userData()?.timeZoneOffsetMinutes ?? 0);
    return moment.utc(value).utcOffset(timeZoneOffsetMinutes).format(format);
  }
}

@Pipe({ name: 'itvDate' })
export class ItvDatePipe implements PipeTransform {
  userStore = inject(UserStore);
  dateCustomPipe = inject(ItvDateTimeCustomFormatPipe);

  transform(value: string | Date, defaultValue: string = ''): string {
    const dateFormat = this.userStore.calculatedUserSettings()?.dateFormat;

    if (!dateFormat) {
      return defaultValue;
    }

    return this.dateCustomPipe.transform(value, dateFormat, false, defaultValue);
  }
}

@Pipe({ name: 'itvTime' })
export class ItvTimePipe implements PipeTransform {
  userStore = inject(UserStore);
  dateCustomPipe = inject(ItvDateTimeCustomFormatPipe);
  timeRegexp = /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])(:([0-5]?[0-9]))?$/;

  transform(value: string | Date, defaultValue: string = ''): string {
    const timeFormat = this.userStore.calculatedUserSettings()?.timeFormat;

    if (!timeFormat) {
      return defaultValue;
    }

    const dateTimeValue = value instanceof Date ? value : this.isTimeString(value) ? `1970-01-02T${value}` : value;
    return this.dateCustomPipe.transform(dateTimeValue, timeFormat, true, defaultValue);
  }

  isTimeString(str: string) {
    return this.timeRegexp.test(str);
  }
}

@Pipe({ name: 'itvDateTime' })
export class ItvDateTimePipe implements PipeTransform {
  userStore = inject(UserStore);
  dateCustomPipe = inject(ItvDateTimeCustomFormatPipe);

  transform(value: string | Date, defaultValue: string = ''): string {
    const datetimeFormat = this.userStore.calculatedUserSettings()?.datetimeFormat;

    if (!datetimeFormat) {
      return defaultValue;
    }

    return this.dateCustomPipe.transform(value, datetimeFormat, true, defaultValue);
  }
}

@Pipe({ name: 'itvFromNow' })
export class ItvFromNowPipe implements PipeTransform {
  userStore = inject(UserStore);
 
  transform(value: string, handleTimezone = true): string {
    let val = value;
    if (handleTimezone && !value.endsWith('Z')) {
      val = value + 'Z';
    }

    const timeZoneOffsetMinutes = !handleTimezone ? 0 : (this.userStore.userData()?.timeZoneOffsetMinutes ?? 0);
    return moment.utc(value).utcOffset(timeZoneOffsetMinutes).fromNow();
  }
}
