import { Injectable, inject } from '@angular/core';

import { HttpService } from '../../../../shared/services';
import { ProgressPaymentPeriod } from './progress-payment.model';

@Injectable({
  providedIn: 'root',
})
export class ProgressPaymentService {
  private http = inject(HttpService);

  getPpPeriod(entityInstanceId: number) {
    const params = this.http.buildHttpParams({ entityInstanceId });
    return this.http.get<ProgressPaymentPeriod>(`_api/PaymentPeriod/GetFromEntityInstance`, {
      httpOptions: { params },
      useLoader: false,
    });
  }
}
