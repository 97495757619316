<div fxLayout="column" class="p-t-md p-a-md">
    <div fxLayout fxLayoutAlign="end center">
        @if ((moduleId == MODULE_IDS.Program || moduleId == MODULE_IDS.Project) && anyAssignmentsChanged()) {
            <mat-checkbox
                [disabled]="loading() || disabledForm()"
                [checked]="propagateHierarchy()"
                (change)="propagateHierarchy.set($event.checked)"
                class="m-r-sm">
                {{ "assignments.propagateHierarchy" | translate }}
            </mat-checkbox>
        }
        <button mat-button (click)="openSelectUsersModel()" class="m-r-sm" [disabled]="loading() || disabledForm()">
            <mat-icon>group_add</mat-icon>
            {{ "assignments.assignment" | translate }}
        </button>
        <button mat-button (click)="openPrimaryUsersModel()" class="m-r-sm" [disabled]="loading() || disabledForm()">
            <mat-icon>person_check</mat-icon>
            {{ "assignments.setPrimaryUsers" | translate }}
        </button>
        <button mat-icon-button (click)="loadData()" class="m-r-sm" [disabled]="loading() || disabledForm()">
            <mat-icon>refresh</mat-icon>
        </button>
        <mat-form-field class="cipo-input hide-subscript p-0">
            <mat-label>{{ "assignments.search" | translate }}</mat-label>
            <input [readonly]="loading() || this.ordering() || this.saving()" matInput [formControl]="searchControl" />
            @if (searchControl.value) {
                <button
                    matSuffix
                    [title]="'assignments.clear' | translate"
                    mat-icon-button
                    (click)="clearInput()"
                    [disabled]="loading() || this.ordering() || this.saving()">
                    <mat-icon>close</mat-icon>
                </button>
            } @else {
                <button
                    matSuffix
                    [title]="'assignments.search' | translate"
                    mat-icon-button
                    [disabled]="loading() || this.ordering() || this.saving()">
                    <mat-icon>search</mat-icon>
                </button>
            }
        </mat-form-field>
    </div>
    <div class="m-t-xxl">
        @if (loading()) {
            <div fxLayoutAlign="center center">
                <mat-spinner></mat-spinner>
            </div>
        } @else {
            @if (!assignments()?.length) {
                <h4 fxLayout fxLayoutAlign="center center" class="m-t-xxl">
                    {{ "assignments.noAssignments" | translate }}
                </h4>
            } @else {
                @if (userAllocationEnabled() && hasUpdate) {
                    <div fxLayoutAlign="center center" class="m-b-xxl">
                        {{ "assignments.totalAllocation" | translate }}: {{ totalAllocationHours() }}
                        {{ "assignments.hoursPerMonth" | translate }}
                    </div>
                }
                <mat-accordion>
                    @for (assignment of initialSearchAssignments(); track $index) {
                        <mat-expansion-panel
                            #panel
                            hideToggle
                            [ngClass]="{
                                'border-all--solid warn': assignment.deleting || assignment.edited,
                                'border-all--solid new': assignment.isNew,
                            }">
                            <mat-expansion-panel-header class="height--auto p-a-0">
                                <div
                                    fxLayout
                                    fxLayoutGap="24px"
                                    fxLayoutAlign="space-between center"
                                    class="full-width p-y-sm p-x"
                                    (click)="$event.stopPropagation()">
                                    <div [fxFlex]="assignmentDetailsPercent" fxLayout="column">
                                        <div fxLayout fxLayoutAlign="start end" class="m-a-0">
                                            @if (assignment.isNew) {
                                                <span class="new-box p-x-xs m-r-sm">
                                                    {{ "assignments.new" | translate }}
                                                </span>
                                            }
                                            <span class="text--bold title-assignment">
                                                {{ assignment.userName }}
                                            </span>
                                            <span class="m-l-xs info-assignment">({{ assignment.userEmail }})</span>
                                        </div>
                                        @if (assignment.isPending) {
                                            <span class="pending-box p-x-xs">
                                                {{ "assignments.pending" | translate }}
                                            </span>
                                        }
                                        <span class="info-assignment">{{ assignment.userOrgName }}</span>
                                        @if (!assignment.deleting) {
                                            <div class="info-assignment">
                                                {{ "assignments.roles" | translate }}:
                                                {{ arrayControls[assignment.userId].value | assignments: roles }}
                                            </div>
                                        } @else {
                                            <div class="warning-box info-assignment">
                                                {{ "assignments.removeWarning" | translate }}
                                            </div>
                                        }
                                    </div>
                                    @if (userRateEnabled() && hasUpdate) {
                                        <div class="m-t-sm">
                                            <mat-form-field appearance="outline">
                                                <mat-label>{{ "assignments.rate" | translate }}</mat-label>
                                                <input
                                                    [disabled]="disabledForm() || assignment.deleting"
                                                    matInput
                                                    type="number"
                                                    min="0"
                                                    oninput="this.value = !isNaN(Number(this.value)) && Number(this.value) >= 0 ? Math.abs(this.value) : null"
                                                    [(ngModel)]="assignment.userRate"
                                                    (ngModelChange)="somethingChange($index)" />
                                            </mat-form-field>
                                        </div>
                                    }
                                    @if (userAllocationEnabled() && hasUpdate) {
                                        <div class="m-t-sm">
                                            <mat-form-field appearance="outline">
                                                <mat-label>{{ "assignments.allocation" | translate }}</mat-label>
                                                <input
                                                    [disabled]="disabledForm() || assignment.deleting"
                                                    matInput
                                                    type="number"
                                                    min="0"
                                                    oninput="this.value = !isNaN(Number(this.value)) && Number(this.value) >= 0 ? Math.abs(this.value) : null"
                                                    [(ngModel)]="assignment.allocationHoursPerMonth"
                                                    (ngModelChange)="somethingChange($index)" />
                                            </mat-form-field>
                                        </div>
                                    }
                                    <div fxFlex fxLayout>
                                        @if (assignment.roleIds?.length > 1) {
                                            <button
                                                fxFlex="none"
                                                (click)="panel.toggle(); ordering.set(true)"
                                                mat-icon-button
                                                [disabled]="disabledForm() || assignment.deleting"
                                                [title]="'assignments.reorderRoles' | translate"
                                                class="m-t">
                                                <mat-icon>format_list_numbered</mat-icon>
                                            </button>
                                        } @else {
                                            <div fxFlex="none" class="icon-placeholder"></div>
                                        }
                                        <cipo-select
                                            fxFlex="grow"
                                            class="m-t-sm"
                                            [control]="arrayControls[assignment.userId]" />
                                        @if (!assignment.deleting) {
                                            <button
                                                fxFlex="none"
                                                (click)="deleteAssignment($index)"
                                                mat-icon-button
                                                class="m-t-md"
                                                [title]="'general.table.delete' | translate"
                                                [disabled]="disabledForm() || assignment.deleting">
                                                <mat-icon>delete</mat-icon>
                                            </button>
                                        } @else {
                                            <button
                                                fxFlex="none"
                                                (click)="restoreDeleteAssignment($index)"
                                                mat-icon-button
                                                class="m-t-md"
                                                [title]="'general.cancel' | translate"
                                                [disabled]="disabledForm()">
                                                <mat-icon>close</mat-icon>
                                            </button>
                                        }
                                    </div>
                                </div>
                            </mat-expansion-panel-header>
                            <div fxLayout fxLayoutAlign="end" fxLayoutGap="8px">
                                <button
                                    color="primary"
                                    (click)="saveOrdering($index)"
                                    [title]="'general.save' | translate"
                                    mat-mini-fab>
                                    <mat-icon>check</mat-icon>
                                </button>
                                <button
                                    (click)="ordering.set(false); panel.toggle()"
                                    [title]="'general.cancel' | translate"
                                    mat-icon-button>
                                    <mat-icon>close</mat-icon>
                                </button>
                            </div>
                            <div
                                class="p-t-md"
                                fxLayout="row wrap"
                                cdkDropList
                                (cdkDropListDropped)="drop(assignment, $event)">
                                @for (role of assignment.roles; track role) {
                                    <div
                                        fxLayout="center center"
                                        fxLayoutGap="4px"
                                        class="reorder-box border-all--solid"
                                        cdkDrag>
                                        <mat-icon class="material-symbols-outlined">drag_pan</mat-icon>
                                        <span>{{ role.value }}</span>
                                    </div>
                                }
                            </div>
                        </mat-expansion-panel>
                    }
                </mat-accordion>
            }
        }
    </div>
</div>
<div
    fxLayoutAlign="end center"
    class="save-button full-width p-a-sm"
    [ngClass]="{ normal: !anyAssignmentsChanged(), warning: anyAssignmentsChanged() }">
    <button class="m-r-md" mat-button (click)="onCloseEmit()" [disabled]="!entityInstanceLocked() && disabledForm()">
        {{ "general.close" | translate }}
    </button>
    @if (anyAssignmentsChanged()) {
        <button class="m-r-md" mat-button (click)="discardChanges()" [disabled]="disabledForm()">
            {{ "general.discard" | translate }}
        </button>
    }
    <button mat-raised-button class="m-r-xxl" color="primary" (click)="saveAssignments()" [disabled]="disabledForm()">
        {{ "general.update" | translate }}
    </button>
</div>
