<div class="tenant-account-logos" [matMenuTriggerFor]="account">
    <cipo-img-base64
        [base64]="tenantStore.selectedTenant()?.logo"
        [height]="40"
        fxShow
        fxHide.lt-md
        placeholder="/src/assets/images/CIPO NoLogo.png"></cipo-img-base64>
    @if (userStore.userData()?.logo) {
        <cipo-img-base64
            [base64]="userStore.userData()?.logo"
            [height]="40"
            [radius]="'50%'"
            placeholder="/src/assets/images/CIPO NoLogo.png"></cipo-img-base64>
    } @else {
        <mat-icon class="user-nologo">account_circle</mat-icon>
    }
</div>

<mat-menu #account="matMenu" class="reversed-menu">
    <div (click)="$event.stopPropagation()" class="user-container">
        <p class="user-name">{{ userStore.userData().displayName }}</p>
        <label>{{ userStore.userData().organizationName }}</label>
    </div>
    <button mat-menu-item (click)="goToProfile()">
        <mat-icon>account_circle</mat-icon>
        <span>{{ "header.userProfile" | translate }}</span>
    </button>
    @if (tenantStore.availableTenants().length !== 1) {
        <button mat-menu-item (click)="goToTenants()">
            <mat-icon class="material-symbols-outlined">tenancy</mat-icon>
            <span>{{ "header.switchTenant" | translate }}</span>
        </button>
    }
    <button mat-menu-item (click)="signOut()">
        <mat-icon>logout</mat-icon>
        <span>{{ "header.signOut" | translate }}</span>
    </button>
    @if (userStore.devMode()) {
        <button mat-menu-item [matMenuTriggerFor]="theme">
            <mat-icon>palette</mat-icon>
            <span>{{ "header.theme.color" | translate }}</span>
        </button>
        <button mat-menu-item [matMenuTriggerFor]="themeMode">
            <mat-icon>contrast</mat-icon>
            <span>{{ "header.theme.mode" | translate }}</span>
        </button>
        <button mat-menu-item [matMenuTriggerFor]="language">
            <mat-icon>language</mat-icon>
            <span>{{ "header.language.title" | translate }}</span>
        </button>
        <button mat-menu-item (click)="openPalette()">
            <mat-icon>invert_colors</mat-icon>
            <span>{{ "header.openPalette" | translate }}</span>
        </button>
    }
</mat-menu>

<mat-menu #theme="matMenu">
    <button mat-menu-item (click)="changeTheme('default')">
        <mat-icon class="default-color">opacity</mat-icon>
        <span>{{ "header.theme.default" | translate }}</span>
    </button>
    <button mat-menu-item (click)="changeTheme('orange')">
        <mat-icon class="orange-color">opacity</mat-icon>
        <span>{{ "header.theme.orange" | translate }}</span>
    </button>
</mat-menu>

<mat-menu #themeMode="matMenu">
    <button mat-menu-item (click)="changeThemeMode('light')">
        <mat-icon class="cipo-text--accent-500">light_mode</mat-icon>
        <span>{{ "header.theme.light" | translate }}</span>
    </button>
    <button mat-menu-item (click)="changeThemeMode('dark')">
        <mat-icon>dark_mode</mat-icon>
        <span>{{ "header.theme.dark" | translate }}</span>
    </button>
    <button mat-menu-item (click)="changeThemeMode('browser')">
        <mat-icon>web</mat-icon>
        <span>{{ "header.theme.browser" | translate }}</span>
    </button>
</mat-menu>

<mat-menu #language="matMenu">
    <button mat-menu-item (click)="changeLanguage(languageType.English)">
        <span>{{ "header.language.english" | translate }}</span>
    </button>
    <button mat-menu-item (click)="changeLanguage(languageType.Romanian)">
        <span>{{ "header.language.romanian" | translate }}</span>
    </button>
</mat-menu>
