import { FieldTypeEnum, KeyValueRestrictionModel, KeyValueFormattingModel, DisplayType } from '../enums';
import { AttachmentModel } from './attachment.model';

export interface FormFieldModel {
  id: number;
  name?: string;
  label?: string;
  hasMultipleValues?: boolean;
  type?: FieldTypeEnum;
  displayType?: DisplayType;
  restrictions?: KeyValueRestrictionModel[];
  formattings?: KeyValueFormattingModel[];
  isSystem?: boolean;
  isMandatory?: boolean;
  inUse?: boolean;
  fieldOrigin?: number;
  x?: number;
  y?: number;
  cols?: number;
  rows?: number;
  triggeredFields?: unknown[];
  value?: FormFieldValueType;
  isParent?: boolean;
  parentHasDefaultValue?: boolean;
  childFieldIds?: unknown[];
  isFilter?: boolean;
  aggregateValue?: unknown;
  expression?: unknown;
  weatherDateId?: number;
  screensId?: number[];
  usedOnWeather?: boolean;
  relationId?: number;
  annotation?: string;
  // will remove these
  previousActionInstanceId?: number;
  currentDateTime?: boolean;
  typeId?: number;
  displayTypeId?: number;
  dataSourceId?: number;
  dataSources?: FieldDataSourceModel[];
}

export interface FieldDataSourceModel {
  isDisabled: boolean;
  key: string;
  value: string;
  order: number;
}

export type BaseFieldValueType = string | number | boolean | string[] | number[] | AttachmentModel | AttachmentModel[];
export type TableFieldValueType = Record<string, BaseFieldValueType>[];

export type FormFieldValueType = BaseFieldValueType | TableFieldValueType;
