import { Injectable, inject } from '@angular/core';
import { CanActivate, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { TenantStore } from '../../signal-store';

@Injectable({
  providedIn: 'root',
})
export class DevGuard implements CanActivate {
  tenantStore = inject(TenantStore);
  constructor(private router: Router) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (environment.production || !this.tenantStore.selectedTenant()) {
      // Redirect to root if it's not a development environment or there is no tenant selected
      return this.router.createUrlTree(['/']);
    }
    return true;
  }
}
