import { cipo } from 'cipo';
import PSPDFKit from 'pspdfkit';
import { environment } from 'src/environments/environment';

cipo.factory("PSPDFKitInstance", function (Model, PSPDFKitAnnotationInspector, URI, PSPDFKIT_CONFIG, $http, $q, $timeout, $mdDialog, Message, userService) {
    //constructor extending Model
    var PSPDFKitInstance = Model.extend(function (obj) {
        var self = this;

        /* FUNCTIONS */
        self.isSupportedMimeType = _isSupportedMimeType;
        self.getToken = _getToken;
        self.viewDocument = _viewDocument;
        self.markupDocument = _markupDocument;
        self.getViewConfig = _getViewConfig;
        self.getMarkupConfig = _getMarkupConfig;
        self.isEditableAnnotation = _isEditableAnnotation;
        self.hasUpdateAllScope = _hasUpdateAllScope;
        self.getToolbarItems = _getToolbarItems;
        self.defaultListeners = _defaultListeners;
        self.handleListener = _handleListener;
        self.connectedUserListener = _connectedUserListener;
        self.documentLinkClickHandler = _documentLinkClickHandler;
        self.setConnectedUsers = _setConnectedUsers;
        self.getUsers = _getUsers;
        self.lockViewport = _lockViewport;
        self.getVersions = _getVersions;
        self.isViewOnly = _isViewOnly;
        self.showDocumentLinkDialog = _showDocumentLinkDialog;
        self.setToolbarItemPropertyValue = _setToolbarItemPropertyValue;
        self.createNode = _createNode;
        self.placeIcon = _placeIcon;
        self.createAnnotation = _createAnnotation;
        self.deleteAnnotationLink = _deleteAnnotationLink;
        self.deleteAnnotation = _deleteAnnotation;
        self.deleteAnnotationEvent = _deleteAnnotationEvent;
        self.getAnnotations = _getAnnotations;
        self.getAnnotationDetails = _getAnnotationDetails;
        self.getAssociationDetails = _getAssociationDetails;
        self.getDateTime = _getDateTime;
        self.cancelPlaceIcon = _cancelPlaceIcon;
        self.setToolbarItemOnPress = _setToolbarItemOnPress;
        /* END FUNCTIONS */

        /* VARIABLES */
        const shapes = ['ellipse', 'ink', 'line', 'polyline', 'polygon', 'rectangle'];
        const defaultColor = new PSPDFKit.Color({ r: 255, g: 0, b: 0 });
        var defaultAnnotationPresets = null;
        obj = obj || {};
        self.userDict = [];
        self.connectedUsers = [];
        self.isLoading = obj.isLoading || function() {};
        self.instance = null;
        self.documentId = null;
        self.versions = [];
        self.selectedVersion = null;
        self.placeIconCallback = obj.placeIconCallback || function () {};
        // Required for edit mode
        self.moduleId = obj.moduleId || null;
        self.supportedMimeTypes = PSPDFKIT_CONFIG.MIME_TYPES;
        // Required
        self.entityInstanceId = obj.entityInstanceId || 0;
        // Required
        self.currentUserId = obj.currentUserId || null;
        // Required
        self.toolbarItems = [
            { type: 'sidebar-thumbnails', isMarkup: false },
            { type: 'sidebar-document-outline', isMarkup: false },
            { type: 'sidebar-annotations', isMarkup: true },
            { type: 'sidebar-bookmarks', isMarkup: true },
            { type: 'pager', isMarkup: false },
            { type: 'layout-config', isMarkup: false },
            { type: 'zoom-mode', isMarkup: false },
            { type: 'zoom-out', isMarkup: false },
            { type: 'zoom-in', isMarkup: false },
            { type: 'pan', isViewer: true },
            { type: 'document-editor', isMarkup: true },
            { type: 'spacer', isMarkup: false },
            {
                type: 'custom',
                id: 'documents',
                title: 'CIPO Documents',
                icon: '/Content/pspdfkit/icons/cipo_documents.png',
                onPress: self.showDocumentLinkDialog,
                dropdownGroup: "cipo-dropdown-group",
                selected: false,
                isMarkup: true, 
            },
            {
                type: 'custom',
                id: 'add-association',
                title: 'Add Association',
                icon: '/Content/pspdfkit/icons/add_association_1.png',
                onPress: self.placeIcon,
                dropdownGroup: "cipo-dropdown-group",
                selected: false,
                isMarkup: true, 
            },
            { type: 'annotate', isMarkup: true },
            { type: 'ink', isMarkup: true },
            { type: 'highlighter', isMarkup: true },
            { type: 'text-highlighter', isMarkup: true },
            { type: 'ink-eraser', isMarkup: true },
            { type: 'line', isMarkup: true },
            { type: 'arrow', isMarkup: true },
            { type: 'rectangle', isMarkup: true },
            { type: 'ellipse', isMarkup: true },
            { type: 'polygon', isMarkup: true },
            { type: 'polyline', isMarkup: true },
            { type: 'note', isMarkup: true },
            { type: 'text', isMarkup: true },
            { type: 'image', isMarkup: true },
            { type: 'print', isMarkup: false },
            { type: 'search', isMarkup: false }
        ];
        self.eventListeners = {
            connectedClients: {
                isDefault: true,
                target: null,
                event: 'instant.connectedClients.change',
                callback: (clients) => {
                    self.connectedUserListener(clients);
                },
            },
            deletedAnnotations: {
                isDefault: true,
                target: null,
                event: 'annotations.delete',
                callback: (deletedAnnotations) => {
                    self.deleteAnnotationEvent(deletedAnnotations);
                },
            },
        };
        // Custom annotation definitions
        self.annotationsMap = {};
        self.annotationsMap[PSPDFKIT_CONFIG.ANNOTATION_TYPES.DOCUMENT_LINK] = {
            annotationClass: PSPDFKit.Annotations.ImageAnnotation,
            clickHandler: self.documentLinkClickHandler,
            customDataKey: 'moduleTypeInstanceId',
            description: 'Document Association',
            details: [],
            eventListeners: {
                pagePress: {
                    target: null,
                    event: 'page.press',
                    callback: (click) => {
                        // We turn off the listener after a single click because that's all we need to capture
                        self.handleListener(self.annotationsMap.documentLink.eventListeners.pagePress, false);
                        document.body.style.cursor = 'default';
                        self.showDocumentLinkDialog(null, null, click);
                    }
                }
            },
            icon: 'Content/pspdfkit/icons/cipo96x96.png',
            nodeData: {
                title: 'Document Association',
                sections: [
                    // Standard annotation editing options for PSPDFKit (Alex does not want this for now)
                    // { label: 'Icon style', type: 'annotation', collapseByDefault: true }
                ]
            },
            getDetails: (customData) => {
                return self.getAnnotationDetails(customData);
            },
            navigate: (customData) => {
                // state go, add option to open doc in new page if single URLs allow that
                Message.info('This feature is not yet implemented');
            },
            edit: (customData) => {
                // Will pop up mini dialog with form to edit the 
            },
            delete: (customData) => {
                // Need to add delete button to UI if possible so this can be triggered
                //currenly it is done only via pressing the 'Delete' key
                Message.info('This feature is not yet implemented');
            }
        };
        self.annotationInspector = new PSPDFKitAnnotationInspector({
            instance: self.instance,
            customAnnotationsMap: self.annotationsMap,
            entityInstanceId: self.entityInstanceId
        });
        /* END VARIABLES */

        /* PRIVATE FUNCTION DECLARATION */
        function shapeIterator(value, index, array) {
            defaultAnnotationPresets[value] = {
                strokeColor: defaultColor,
            }
        }

        // Returns true if mime type is supported
        function _isSupportedMimeType(mimeType) {
            // TODO: Get data from API. best practice
            return self.supportedMimeTypes.includes(mimeType);
        }

        // Returns a JWT and the user's associated permissions
        function _getToken(documentId) {
            var deferred = $q.defer();

            self[URI.PDF_MARKUP.GET_TOKEN.method](`${URI.PDF_MARKUP.GET_TOKEN}?documentId=${documentId}&moduleId=${self.moduleId}&entityInstanceId=${self.entityInstanceId}`)
                .then(function (res) {
                    deferred.resolve(res);
                })
                .catch(function (err) {
                    console.error('Failed to get PSPDFKit JWT', err);
                    deferred.reject(err);
                });

            return deferred.promise;
        }

        // Opens a document in the PSPDFKit viewer
        function _viewDocument(fileUrl, fileId) {
            self.isLoading(true);
            var config = self.getViewConfig(fileUrl);
            $timeout(() => {
                PSPDFKit.load(config)
                    .then(instance => {
                        self.isLoading(false);
                        self.getVersions(fileId);
                    }).catch((error) => {
                        console.error(error);
                        self.isLoading(false);
                    });
            });
        }

        // Opens a document from the PSPDFKit server
        function _markupDocument(documentId, fileId, isCurrent) {
            self.isLoading(true);
            self.documentId = documentId;
            self.getToken(documentId)
                .then((auth) => {
                    self.getAssociationDetails()
                        .then((associations) => {
                            self.annotationsMap[PSPDFKIT_CONFIG.ANNOTATION_TYPES.DOCUMENT_LINK].details = associations;
                            var config = self.getMarkupConfig(documentId, auth.jwt, auth.permissions, isCurrent);
                            PSPDFKit.load(config)
                                .then((instance) => {
                                    defaultAnnotationPresets = instance.annotationPresets
                                    defaultAnnotationPresets['arrow'] = {
                                        lineCaps: { end: "openArrow" },
                                        strokeColor: defaultColor,
                                    }
                                    shapes.forEach(shapeIterator);
                                    instance.setAnnotationPresets(defaultAnnotationPresets);
                                    self.isLoading(false);
                                    self.instance = instance;
                                    self.annotationInspector.instance = self.instance;
                                    self.instance.setAnnotationCreatorName(self.currentUserId.toString());
                                    self.setConnectedUsers(instance.connectedClients.groupBy(c => c.userId).toJS());
                                    self.defaultListeners(true);
                                    self.setToolbarItemOnPress();
                                    self.getVersions(fileId, documentId);
                                }).catch((error) => {
                                    console.error(error);
                                    self.isLoading(false);
                                });
                        })
                        .catch(err => {
                            Message.dberror(err);
                        });
                });
        }

        // Sets markup config used to load PSPDFKit document
        function _getViewConfig(fileUrl) {
            const baseUrl = `${window.location.protocol}//${window.location.host}/assets/`;
            return {
                baseUrl,
                container: '#pspdfkit',
                document: fileUrl,
                licenseKey: PSPDFKIT_CONFIG.LICENSE_KEY(),
                toolbarItems: self.getToolbarItems(false),
                editableAnnotationTypes: [],
                isAPStreamRendered: () => { return true; },
                disableForms: false
            };
        }

        // Sets markup config used to load PSPDFKit document
        function _getMarkupConfig(documentId, jwt, permissions, isCurrent) {
            const baseUrl = `${window.location.protocol}//${window.location.host}/assets/`;
            return {
                baseUrl,
                serverUrl: environment.pspdfkitUrl,
                container: '#pspdfkit',
                documentId: documentId,
                authPayload: { jwt: jwt },
                instant: true,
                styleSheets: [
                    "Content/pspdfkit/styles/annotation-inspector.css"
                ],
                // Get appropriate toolbar items based on whether the document version is current
                toolbarItems: self.getToolbarItems(isCurrent, permissions),
                // Callback fn
                isEditableAnnotation: annotation => { return self.isEditableAnnotation(annotation, permissions, isCurrent); },
                isAPStreamRendered: () => { return true; },
                disableForms: false,
                annotationTooltipCallback: annotation => { return self.annotationInspector.getAnnotationInspector(annotation); },
                initialViewState: new PSPDFKit.ViewState({ enableAnnotationToolbar: false }),
            };
        }

        // Returns true if an annotation can be edited
        function _isEditableAnnotation(annotation, permissions, isCurrent) {
            return isCurrent && (self.hasUpdateAllScope(permissions) || annotation.creatorName == self.currentUserId);
        }

        // Returns true if any update permissions exist with scope of 'all'
        function _hasUpdateAllScope(permissions) {
            // permission id 3: Update
            // scope id 1: All
            return permissions.filter(x => { return x.permissionId == 3 && x.scopeId == 1 }).length > 0;
        }

        function _setToolbarItemOnPress() {
            self.instance.setToolbarItems((items) =>
                items.map((item) => {
                    if (item.type != "custom") {
                        item.onPress = self.cancelPlaceIcon;
                    }

                    return item;
                })
            );
        }

        // Gets toolbar items
        function _getToolbarItems(isMarkup, permissions) {
            // TODO: Tie permissions into custom toolbar items
            return isMarkup
                ? self.toolbarItems.filter(x => !x.isViewer)
                : self.toolbarItems.filter(x => !x.isMarkup);
        }

        function _defaultListeners(addEventListener) {
            for (const listener in self.eventListeners) {
                if (self.eventListeners.hasOwnProperty(listener) && self.eventListeners[listener].isDefault) {
                    self.handleListener(self.eventListeners[listener], addEventListener);
                }
            }
        }

        // Adds/removes event handlers from a PSPDFKit instance
        function _handleListener(eventListener, addEventListener) {
            const addOrRemove = addEventListener
                ? 'addEventListener'
                : 'removeEventListener';

            if (self.instance) {
                eventListener.target
                    ? self.instance[eventListener.target][addOrRemove](eventListener.event, eventListener.callback)
                    : self.instance[addOrRemove](eventListener.event, eventListener.callback);
            }
        }

        // Event listener used to set the connected users
        function _connectedUserListener(clients) {
            self.setConnectedUsers(clients.groupBy(c => c.userId).toJS());
        }

        // Creates an annotation for document link
        function _createAnnotation(click, customData, annotationType) {
            var deferred = $q.defer();
            var iconSize = Math.trunc((self.instance.pageInfoForIndex(click.pageIndex).width / 100) * 2.5);
            customData.annotationType = annotationType;

            if (annotationType == PSPDFKIT_CONFIG.ANNOTATION_TYPES.DOCUMENT_LINK) {
                fetch(new Request(self.annotationsMap[annotationType].icon))
                    .then(response => response.blob())
                    .then(function (blob) {
                        self.instance.createAttachment(blob)
                            .then((imageAttachmentId) => {
                                const annotation = new PSPDFKit.Annotations.ImageAnnotation({
                                    // action: 
                                    pageIndex: click.pageIndex,
                                    contentType: "image/png",
                                    customData: customData,
                                    imageAttachmentId,
                                    description: self.annotationsMap[annotationType].description,
                                    boundingBox: new PSPDFKit.Geometry.Rect({
                                        left: click.point.x,
                                        top: click.point.y,
                                        width: iconSize,
                                        height: iconSize
                                    })
                                });
                                self.instance.create(annotation)
                                    .then(function (createdAnnotation) {
                                        self.getAssociationDetails(customData.moduleTypeInstanceId)
                                            .then(createdAnnotationDetail => {
                                                self.annotationsMap[PSPDFKIT_CONFIG.ANNOTATION_TYPES.DOCUMENT_LINK].details.push(createdAnnotationDetail);
                                            })
                                        deferred.resolve();
                                    })
                                    .catch(function (err) { deferred.reject(err); });
                            })
                            .catch(function (err) { deferred.reject(err); });
                    })
                    .catch(function (err) { deferred.reject(err); });
            }

            return deferred.promise;
        }

        function _deleteAnnotationLink (documentId, moduleTypeInstanceId, entityInstanceId) {
            $http.delete(`${URI.PDF_MARKUP.DELETE_DOC_LINK_ID}?entityInstanceId=${entityInstanceId}&documentId=${documentId}&moduleTypeInstanceId=${moduleTypeInstanceId}`)
                .then(function () {
                    Message.info('Reference deleted successfully.');
                })
                .catch(function (err) {
                    Message.dberror(err);
                });
        }

        function _deleteAnnotationEvent(deleatedAnnotations) {
            if (deleatedAnnotations) {
                var annotation = deleatedAnnotations.get(0);
                if (annotation && annotation.customData && annotation.customData.annotationType == 'documentLink') {
                    self.deleteAnnotationLink(self.documentId, annotation.customData.moduleTypeInstanceId, self.entityInstanceId);
                }
            }
        }

        function _deleteAnnotation(kvp, annotationType) {
            var deferred = $q.defer();
            var isDeleted = false;
            var filters = [
                { key: 'annotationType', value: annotationType },
                kvp
            ];

            // Loop through pages of annotations, then annotations, to find matching annotation to delete.
            //same listener for pressing the 'Delete' key
            self.getAnnotations(self.annotationsMap[annotationType].annotationClass, filters)
                .then(function (annotations) {
                    switch (annotations.length) {
                        case 0:
                            console.error('Annotation not found', filters);
                            deferred.resolve(isDeleted);
                            break;
                        case 1:
                            self.instance.delete(annotations[0].id)
                                .then(() => {
                                    isDeleted = true;
                                })
                                .catch(err => {
                                    console.error(`Failed to delete annotation ${annotation.id} on page ${(annotation.pageIndex + 1)}`);
                                    console.error(err);
                                    isDeleted = false;
                                })
                                .finally(() => {
                                    deferred.resolve(isDeleted);
                                });
                            break;
                        default:
                            console.error(`More than 1 ${(annotationType.split(/(?=[A-Z])/).join(' ').toLowerCase())} found on filter match`, filters);
                            deferred.resolve(isDeleted);
                            break;
                    }
                });
            return deferred.promise;
        }
        
        function _getAnnotations(annotationClass = null, filters = null) {
            var deferred = $q.defer();
            var promises = [];

            for (var i = 0; i < self.instance.totalPageCount; i++) {
                promises.push(self.instance.getAnnotations(i).then(data => {
                    var annotations = annotationClass
                        ? data.filter(x => { return x instanceof annotationClass; }).toJS()
                        : data.toJS();

                    if (filters && filters.length) {
                        filters.forEach(filter => {
                            annotations = annotations.filter(x => { return x.customData && x.customData.hasOwnProperty(filter.key) && x.customData[filter.key] == filter.value; });
                        });
                    }

                    return annotations;
                }));
            }

            var annotations = [];
            $q.all(promises).then(result => {
                result.forEach(a => {
                    annotations = annotations.concat(a);
                });
                deferred.resolve(annotations);
            });

            return deferred.promise;
        }

        function _cancelPlaceIcon(event, id) {
            self.placeIconCallback && self.placeIconCallback(false);
            self.handleListener(self.annotationsMap.documentLink.eventListeners.pagePress, false);
            document.body.style.cursor = 'default';
        }

        function _showDocumentLinkDialog(event, id, click = null) {         
            self.cancelPlaceIcon();
            
            $mdDialog.show({
                locals: {
                    documentId: self.documentId,
                    entityInstanceId: self.entityInstanceId,
                    annotationClick: click,
                    createAnnotation: self.createAnnotation,
                    deleteAnnotation: self.deleteAnnotation
                },
                controller: 'pdfMarkupDocumentLinkController',
                templateUrl: '/ng/views/admin/modals/pdfMarkupDocumentLink.html',
                parent: angular.element(document.body),
                fullscreen: false,
                escapeToClose: false,
                clickOutsideToClose: false,
                multiple: true
            })
                .then(function (data) {
                    // console.log('$mdDialog.hide() called', data);
                }, function (data) {
                    if (typeof data === 'boolean' && data === true) {
                        self.placeIcon();
                    }
                });
        }

        function _documentLinkClickHandler(event) {
            // event.preventDefault();
            // if (event.nativeEvent.type == 'click') {
            var annotation = event.annotation.toJS();
            // console.log('event', event);
            // console.log('annotation', annotation);
            // }
        }

        // Sets connected users
        function _setConnectedUsers(users) {
            // TODO: Returning new list of data. Current code will need to be refactored to
            // account for new data model
            self.getUsers()
                .then(function (userDict) {
                    // Object keys are user ids since we pass in clients grouped by user id
                    var connectedUserIds = Object.keys(users)
                        .filter(key => { return key != self.currentUserId; });

                    // Get names of other connected users to display in toolbar
                    self.connectedUsers = userDict
                        .filter(user => { return connectedUserIds.includes(user.id.toString()); });
                });
        }

        // Returns a list of users for the current contract
        function _getUsers() {
            var deferred = $q.defer();

            // Get user dictionary if not yet gotten
            if (self.userDict.length) {
                deferred.resolve(self.userDict);
            } else {
                self[URI.PDF_MARKUP.GET_USER_DICT.method](`${URI.PDF_MARKUP.GET_USER_DICT}?entityInstanceId=${self.entityInstanceId}`)
                    .then(function (userDict) {
                        self.userDict = userDict;
                        deferred.resolve(self.userDict);
                    })
                    .catch(function (err) {
                        console.error('Failed to get flattened PDF', err);
                        deferred.reject(err);
                    });
            }

            return deferred.promise;
        }

        // Locks/unlocks viewport for optimal PSPDFKit viewing
        function _lockViewport(isLockViewport) {
            var lockedContent = 'width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no';
            var defaultContent = 'initial-scale=1';
            document.querySelector("meta[name=viewport]").setAttribute('content', isLockViewport ? lockedContent : defaultContent);
        }

        // Gets versions of the current file
        function _getVersions(fileId, documentId = null) {
            if (!fileId || self.versions.length)
                return;

            self.isBusy = true;
            var uri = URI.PDF_MARKUP.GET_VERSIONS;
            self[uri.method](`${uri}?entityInstanceId=${self.entityInstanceId}&fileId=${fileId}&isMarkup=${(documentId != null)}`)
                .then(function (data) {
                    self.versions = data;
                    // Default selected version is current version on load
                    self.selectedVersion = self.versions.find(x => x.isCurrent);
                })
                .catch(function (err) {
                    console.error(err);
                })
                .finally(function () {
                    self.isBusy = false;
                });
        }

        // Returns true if the selected version should be loaded with the view-only config
        function _isViewOnly() {
            return self.selectedVersion.documentId == null;
        }

        function _setToolbarItemPropertyValue(id, prop, val) {
            self.instance.setToolbarItems(items => {
                let item = items.find(item => item.type == 'custom' && item.id == id);
                item[prop] = val;
                return items;
            });
        }

        function _createNode(className) {
            const icon = document.createElement("i");
            icon.className = className;
            return icon;
        }

        function _placeIcon() {
            self.placeIconCallback && self.placeIconCallback(true);
            document.body.style.cursor = 'crosshair';
            self.handleListener(self.annotationsMap.documentLink.eventListeners.pagePress, true);
        }

        function _getAnnotationDetails(customData) {
            switch (customData.annotationType) {
                case PSPDFKIT_CONFIG.ANNOTATION_TYPES.DOCUMENT_LINK:
                    let details = self.annotationsMap[PSPDFKIT_CONFIG.ANNOTATION_TYPES.DOCUMENT_LINK].details
                        .find(x => x.moduleTypeInstanceId == customData.moduleTypeInstanceId);

                    var tenant = userService.system.tenantsLookup[userService.tenantId];
                    var url = window.location.origin + '/' + tenant.name + '/dm/' + (userService.system.context.contract?.id || 0)
                        + '/' + details.moduleId + '/details/' + details.moduleId + '/0/0/' + details.entityId + '/' + details.screenId;

                    if (details.name.startsWith("Report-")) {
                        var desc = details.name.substring(0, details.name.indexOf(',') + 1) + " ";
                        var dateStr = details.name.substring(details.name.indexOf(',') + 1).trim();
                        details.name = desc + userService.formatDate(dateStr);
                    }

                    return [
                        { key: 'Name', value: '<a href="' + url.toLowerCase() + '" target="_PARENT">' + details.name + '</a>' },
                        { key: 'Created By', value: details.createdBy },
                        { key: 'Created On', value: self.getDateTime(details.createdOn) }
                    ];
            }
        }

        function _getAssociationDetails(moduleTypeInstanceId = null) {
            var deferred = $q.defer();
            var uri = `${URI.PDF_MARKUP.GET_ASSOCIATION_DETAILS}?entityInstanceId=${self.entityInstanceId}&documentId=${self.documentId}`;
            if (moduleTypeInstanceId != null)
                uri += `&moduleTypeInstanceId=${moduleTypeInstanceId}`;
            $http.get(uri)
                .then(res => {
                    deferred.resolve(res.data);
                })
                .catch(err => {
                    deferred.reject(err);
                });

            return deferred.promise;
        }

        function _getDateTime(dateTime) {
            return userService.formatDate(dateTime);
        }
        /* END PRIVATE FUNCTION DECLARATION */
    });

    /* PUBLIC FUNCTION DECLARATION */
    // Opens PSPDFKit in viewer/standalone mode
    PSPDFKitInstance.prototype.view = function (fileUrl, fileId, mimeType) {
        var self = this;
        if (!self.isSupportedMimeType(mimeType)) {
            return;
        }

        self.lockViewport(true);
        self.viewDocument(fileUrl, fileId);
    }

    // Opens PSPDFKit in edit mode (PSPDFKit instance)
    PSPDFKitInstance.prototype.markup = function (documentId, fileId = null, isCurrent = true) {
        var self = this;
        if (!documentId) {
            // console.error('Invalid document id');
            return;
        }

        self.lockViewport(true);
        self.documentId = documentId;
        self.markupDocument(documentId, fileId, isCurrent);
    }

    // Removes the instance event listeners, unloads the instance, and unlocks the viewport. 
    PSPDFKitInstance.prototype.unload = function (isViewOnly) {
        var self = this;
        if (!isViewOnly) {
            self.defaultListeners(false);
        }

        var element = document.querySelector('#pspdfkit');
        if (element) {
            PSPDFKit.unload(element);
            self.lockViewport(false);
        }
    }

    // Changes the version of the currently loaded document
    PSPDFKitInstance.prototype.changeVersion = function (newVersion) {
        var self = this;

        // Unload current version
        self.unload(self.isViewOnly());

        // Set new version
        self.selectedVersion = newVersion;

        // Load new version
        self.isViewOnly()
            ? self.view(newVersion.fileUrl, newVersion.fileId, newVersion.mimeType)
            : self.markup(newVersion.documentId, newVersion.fileId, newVersion.isCurrent);
    }

    // Removes the instance event listeners, unloads the instance, and unlocks the viewport. 
    PSPDFKitInstance.prototype.saveDocCovers = function (isViewOnly, fileId, documentId) {
        var self = this;
        if (isViewOnly) {
            return;
        }

        // Save doc cover as an image Blob file to server
        var uri = URI.PDF_MARKUP.SAVE_DOC_COVERS;

        self[uri.method](`${uri}?entityInstanceId=${self.entityInstanceId}&fileId=${fileId}&documentId=${documentId}`,
            { headers: { tenantId: userService.tenantId } })
            .then(function (res) {
            })
            .catch(function (err) {
                self.manager.loading = false;
                console.error(err);
                Message.error(`An error occurred while attempting to save the document cover ${payload.length == 1 ? 'this PDF' : 'the selected PDFs'}.`);
            });
    }

    /* END PUBLIC FUNCTION DECLARATION */

    return PSPDFKitInstance;
});
