import { Component, signal } from '@angular/core';
import { ContextInfo } from '../../../system/context/context.model';
import {
  DemoContextInfo,
  DemoContextInfoProgramSelected,
  DemoContextInfoProjectSelected,
} from '../../data/demo-context';
import { ContextService } from '../../../system/context/context.service';
import { DemoContextService } from './demo-context.service';
import { ContextViewComponent } from '../../../system/context/view/context-view.component';

@Component({
  selector: 'app-demo-context',
  templateUrl: './demo-context.component.html',
  styleUrls: ['./demo-context.component.scss'],
  standalone: true,
  imports: [ContextViewComponent],
  providers: [{ provide: ContextService, useClass: DemoContextService }],
})
export class DemoContextComponent {
  context = signal<ContextInfo>(DemoContextInfo);
  contextProjectSelected = signal<ContextInfo>(DemoContextInfoProjectSelected);
  contextProgramSelected = signal<ContextInfo>(DemoContextInfoProgramSelected);
}
