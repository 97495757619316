import { cipo } from 'cipo';

cipo.factory("Name", function (Model, Form) {
    var Name = Model.extend(function (obj, nameKey, labelName) {
        var self = this;
        var nameKey = nameKey || 'name';
        var labelName = labelName || "Name";
        self.properties = {};
        if (obj) {
            for (var key in obj) {
                if (obj.hasOwnProperty(key)) {
                    self.properties[key] = obj[key];
                }
            }
        } else {
            self.properties[nameKey] = "";
        }

        self.form = new Form(self.properties);
        
        var form = {};
        form[nameKey] = { label: labelName, type: 'text', validation: { required: true, minChars: 1, maxChars: 820 } }
        self.form.set_Description(form);
        self.form.store_Data();
        self.form.fieldsList[nameKey].setFocus();
    });

    return Name;
});
