<div class="p-a-xl">
    <h2>Cipo Number Fields</h2>

    <div fxLayout="row wrap" fxLayoutGap="12px">
        @for (control of form().controls | keyvalue; track $index) {
            <div fxFlex="30" fxFlexAlign="end" class="m-t">
                <cipo-number [control]="control.value" />
            </div>
        }
    </div>
</div>
