export type ProgressPaymentPeriod = {
  id: number;
  contractId: number;
  scheduleId: number;
  startDate: string;
  endDate: string;
  durationDays: number;
  amount: number;
  hasMultiplePayments: boolean;
  isDraft: boolean;
  entityInstances: PpPeriodInstance[];
  isMultiplePeriod: boolean;
  status: string;
  stateTypeListElmntConstId: number;
  color: string;
  textColor: string;
  allowsPaymentRequest: boolean;
  allowsDelete: boolean;
  completedPaymentsExistAfter: boolean;
  pendingPaymentsExistBefore: boolean;
  isJoined: boolean;
  existsProgressPayments: boolean;
};

// is it PP instance?
export type PpPeriodInstance = {
  entityInstanceId: number;
  iAmInitiator: boolean;
  isDraft: boolean;
};
