<h5 mat-dialog-title>Color palette</h5>
<div fxLayout
     mat-dialog-content
     fxLayoutGap="60px">
    <div>
        <p class="m-x-0">Primary color</p>
        <div class="p-a-sm"
             fxLayoutAlign="end center">
            <p class="m-y-0">900:</p>
            <input type="color"
                   #color
                   appendColor
                   id="primary-900" />
        </div>
        <div class="p-a-sm"
             fxLayoutAlign="end center">
            <p class="m-y-0">800:</p>
            <input type="color"
                   #color
                   appendColor
                   id="primary-800" />
        </div>
        <div class="p-a-sm"
             fxLayoutAlign="end center">
            <p class="m-y-0">700:</p>
            <input type="color"
                   #color
                   appendColor
                   id="primary-700" />
        </div>
        <div class="p-a-sm"
             fxLayoutAlign="end center">
            <p class="m-y-0">600:</p>
            <input type="color"
                   #color
                   appendColor
                   id="primary-600" />
        </div>
        <div class="p-a-sm"
             fxLayoutAlign="end center">
            <p class="m-y-0">500:</p>
            <input type="color"
                   #color
                   appendColor
                   id="primary-500" />
        </div>
        <div class="p-a-sm"
             fxLayoutAlign="end center">
            <p class="m-y-0">400:</p>
            <input type="color"
                   #color
                   appendColor
                   id="primary-400" />
        </div>
        <div class="p-a-sm"
             fxLayoutAlign="end center">
            <p class="m-y-0">300:</p>
            <input type="color"
                   #color
                   appendColor
                   id="primary-300" />
        </div>
        <div class="p-a-sm"
             fxLayoutAlign="end center">
            <p class="m-y-0">200:</p>
            <input type="color"
                   #color
                   appendColor
                   id="primary-200" />
        </div>
        <div class="p-a-sm"
             fxLayoutAlign="end center">
            <p class="m-y-0">100:</p>
            <input type="color"
                   #color
                   appendColor
                   id="primary-100" />
        </div>
        <div class="p-a-sm"
             fxLayoutAlign="end center">
            <p class="m-y-0">50:</p>
            <input type="color"
                   #color
                   appendColor
                   id="primary-50" />
        </div>
    </div>

    <div>
        <p class="m-x-0">Secondary color</p>
        <div class="p-a-sm"
             fxLayoutAlign="end center">
            <p class="m-y-0">900:</p>
            <input type="color"
                   #color
                   appendColor
                   id="accent-900" />
        </div>
        <div class="p-a-sm"
             fxLayoutAlign="end center">
            <p class="m-y-0">800:</p>
            <input type="color"
                   #color
                   appendColor
                   id="accent-800" />
        </div>
        <div class="p-a-sm"
             fxLayoutAlign="end center">
            <p class="m-y-0">700:</p>
            <input type="color"
                   #color
                   appendColor
                   id="accent-700" />
        </div>
        <div class="p-a-sm"
             fxLayoutAlign="end center">
            <p class="m-y-0">600:</p>
            <input type="color"
                   #color
                   appendColor
                   id="accent-600" />
        </div>
        <div class="p-a-sm"
             fxLayoutAlign="end center">
            <p class="m-y-0">500:</p>
            <input type="color"
                   #color
                   appendColor
                   id="accent-500" />
        </div>
        <div class="p-a-sm"
             fxLayoutAlign="end center">
            <p class="m-y-0">400:</p>
            <input type="color"
                   #color
                   appendColor
                   id="accent-400" />
        </div>
        <div class="p-a-sm"
             fxLayoutAlign="end center">
            <p class="m-y-0">300:</p>
            <input type="color"
                   #color
                   appendColor
                   id="accent-300" />
        </div>
        <div class="p-a-sm"
             fxLayoutAlign="end center">
            <p class="m-y-0">200:</p>
            <input type="color"
                   #color
                   appendColor
                   id="accent-200" />
        </div>
        <div class="p-a-sm"
             fxLayoutAlign="end center">
            <p class="m-y-0">100:</p>
            <input type="color"
                   #color
                   appendColor
                   id="accent-100" />
        </div>
        <div class="p-a-sm"
             fxLayoutAlign="end center">
            <p class="m-y-0">50:</p>
            <input type="color"
                   #color
                   appendColor
                   id="accent-50" />
        </div>
    </div>

    <div>
        <p class="m-x-0">Warn color</p>
        <div class="p-a-sm"
             fxLayoutAlign="end center">
            <p class="m-y-0">900:</p>
            <input type="color"
                   #color
                   appendColor
                   id="warn-900" />
        </div>
        <div class="p-a-sm"
             fxLayoutAlign="end center">
            <p class="m-y-0">800:</p>
            <input type="color"
                   #color
                   appendColor
                   id="warn-800" />
        </div>
        <div class="p-a-sm"
             fxLayoutAlign="end center">
            <p class="m-y-0">700:</p>
            <input type="color"
                   #color
                   appendColor
                   id="warn-700" />
        </div>
        <div class="p-a-sm"
             fxLayoutAlign="end center">
            <p class="m-y-0">600:</p>
            <input type="color"
                   #color
                   appendColor
                   id="warn-600" />
        </div>
        <div class="p-a-sm"
             fxLayoutAlign="end center">
            <p class="m-y-0">500:</p>
            <input type="color"
                   #color
                   appendColor
                   id="warn-500" />
        </div>
        <div class="p-a-sm"
             fxLayoutAlign="end center">
            <p class="m-y-0">400:</p>
            <input type="color"
                   #color
                   appendColor
                   id="warn-400" />
        </div>
        <div class="p-a-sm"
             fxLayoutAlign="end center">
            <p class="m-y-0">300:</p>
            <input type="color"
                   #color
                   appendColor
                   id="warn-300" />
        </div>
        <div class="p-a-sm"
             fxLayoutAlign="end center">
            <p class="m-y-0">200:</p>
            <input type="color"
                   #color
                   appendColor
                   id="warn-200" />
        </div>
        <div class="p-a-sm"
             fxLayoutAlign="end center">
            <p class="m-y-0">100:</p>
            <input type="color"
                   #color
                   appendColor
                   id="warn-100" />
        </div>
        <div class="p-a-sm"
             fxLayoutAlign="end center">
            <p class="m-y-0">50:</p>
            <input type="color"
                   #color
                   appendColor
                   id="warn-50" />
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-flat-button
            color="accent"
            (click)="copyColorsJson()">Copy colors json</button>
</div>