<mat-dialog-content>
    @if (folder.id) {
        <mat-tab-group mat-stretch-tabs="false">
            <mat-tab label="{{ 'folderDialog.properties' | translate }}">
                <div class="p-t-xxl">
                    <ng-container [ngTemplateOutlet]="inputs"></ng-container>
                </div>
                <div fxLayout fxLayoutAlign="center center" class="p-y-sm m-t-xxl">
                    <label fxFlex="30">{{ "folderDialog.createdOn" | translate }}</label>
                    <p class="m-a-0" fxFlex>{{ folder.createdOn | itvDate }}</p>
                </div>
                <div fxLayout fxLayoutAlign="center center" class="border-top--solid p-y-sm">
                    <label fxFlex="30">{{ "folderDialog.createdBy" | translate }}</label>
                    <p class="m-a-0" fxFlex>{{ folder.createdBy }}</p>
                </div>
                <div fxLayout fxLayoutAlign="center center" class="border-top--solid p-y-sm">
                    <label fxFlex="30">{{ "folderDialog.updatedOn" | translate }}</label>
                    <p class="m-a-0" fxFlex>{{ folder.updatedOn | itvDate }}</p>
                </div>
                <div fxLayout fxLayoutAlign="center center" class="border-top--solid p-y-sm">
                    <label fxFlex="30">{{ "folderDialog.updatedBy" | translate }}</label>
                    <p class="m-a-0" fxFlex>{{ folder.updatedBy }}</p>
                </div>
                <div fxLayout fxLayoutAlign="center center" class="border-top--solid p-y-sm">
                    <label fxFlex="30">{{ "folderDialog.location" | translate }}</label>
                    <p class="m-a-0" fxFlex>{{ folder.folderPath }}</p>
                </div>
                @if (folder.moduleName) {
                    <div fxLayout fxLayoutAlign="center center" class="border-top--solid p-y-sm">
                        <label fxFlex="30">{{ "folderDialog.destinationForModule" | translate }}</label>
                        <p class="m-a-0" fxFlex>{{ folder.moduleName + " (" + folder.moduleAbbr + ")" }}</p>
                    </div>
                }
            </mat-tab>
            @if (canChangePermissions) {
                <mat-tab label="{{ 'folderDialog.permissions' | translate }}">
                    <app-permissions
                        [folderId]="folder.id"
                        [entityInstanceId]="entityInstanceId"
                        [rootModuleId]="rootModuleId"
                        (permissionChanged)="permissionChangedEvent($event)"></app-permissions>
                </mat-tab>
            }
        </mat-tab-group>
    } @else {
        <ng-container class="p-t-lg" [ngTemplateOutlet]="inputs"></ng-container>
    }
</mat-dialog-content>
<mat-dialog-actions class="m-t-md" fxLayout fxLayoutAlign="end center" fxLayoutGap="8px">
    <button mat-raised-button (click)="onCloseClick()" [disabled]="saving()">{{ "general.close" | translate }}</button>
    <button
        mat-raised-button
        [disabled]="saving()"
        [disabled]="(!form.dirty && !permissionChanged) || (!form.valid && !permissionChanged)"
        color="primary"
        (click)="onSaveClick()">
        {{ (folder.id ? "general.save" : "general.add") | translate }}
    </button>
</mat-dialog-actions>

<ng-template #inputs>
    <div fxLayout="column">
        <cipo-text #textInput class="full-width" [control]="form.controls.nameControl" />
        <cipo-listbox [control]="form.controls.globalControl" />
        <div fxLayoutGap="4px" fxLayoutAlign="start start" class="small-info-text m-t-lg m-b-sm">
            <mat-icon fxFlex="none" class="cipo-icon cipo-icon--sm material-symbols-outlined m-t-xs">info</mat-icon>
            <span fxLayout="row wrap">
                @switch (form.value.globalControl) {
                    @case (GLOBAL_TYPE.NotGlobal.toString()) {
                        {{ "folderDialog.notGlobalInfo" | translate }}
                    }
                    @case (GLOBAL_TYPE.Global.toString()) {
                        {{ "folderDialog.globalInfo" | translate }}
                    }
                    @case (GLOBAL_TYPE.GlobalPerParent.toString()) {
                        {{ "folderDialog.globalPerParentInfo" | translate }}
                    }
                }
            </span>
        </div>
        @if (folder.description) {
            <div fxLayoutGap="4px" fxLayoutAlign="start start" class="small-info-text m-t-md m-b-sm">
                <mat-icon fxFlex="none" class="cipo-icon cipo-icon--sm material-symbols-outlined m-t-xs">info</mat-icon>
                <span fxLayout="row wrap">{{ "fileDialog.description" | translate }} {{ folder.description }}</span>
            </div>
        }
    </div>
</ng-template>
