import { inject, Pipe, PipeTransform } from '@angular/core';
import {
  FormattingType,
  FormattingValueType,
  KeyValueFormattingModel,
  KeyValueRestrictionModel,
  RestrictionType,
} from '../../models/module/fields/enums';
import { Currency } from '../../models/system/currency';
import { UtilsService } from '../services';

@Pipe({ name: 'itvNumberWithFormattings' })
export class ItvNumberWithFormattingsPipe implements PipeTransform {
  itvNumberPipe = inject(ItvNumberPipe);

  transform(
    value: number | string | null | undefined,
    formattings: KeyValueFormattingModel[] = [],
    restrictions: KeyValueRestrictionModel[] = [],
    currency: Currency | null | undefined = undefined,
    percentageSymbol: string = '%',
  ): string {
    const numberFormatting = (formattings || []).find(f => f.key == FormattingType.Number);
    const decimalFormatting = (formattings || []).find(f => f.key == FormattingType.Decimals);
    const showComma = !!(restrictions || []).find(f => f.key == RestrictionType.ShowThousandSeparator);
    const showCurrency = numberFormatting && numberFormatting.value == FormattingValueType.Money;
    const showPercentage = numberFormatting && numberFormatting.value == FormattingValueType.Percentage;
    const decimals = decimalFormatting?.formattingValue ?? 0;

    return this.itvNumberPipe.transform(
      value,
      decimals,
      showComma,
      showCurrency,
      showPercentage,
      currency,
      percentageSymbol,
    );
  }
}

@Pipe({ name: 'itvNumber' })
export class ItvNumberPipe implements PipeTransform {
  utilsService = inject(UtilsService);

  transform(
    value: number | string | null | undefined,
    decimals: number = 0,
    showComma: boolean = true,
    showCurrency: boolean = false,
    showPercentage: boolean = false,
    currency: Currency | null | undefined = undefined,
    percentageSymbol: string = '%',
  ): string {
    return this.utilsService.transformToNumberOrCurrencyString(
      value,
      decimals,
      showComma,
      showCurrency,
      showPercentage,
      currency,
      percentageSymbol,
    );
  }
}
