import { Injectable } from '@angular/core';

import { FormFieldModel } from '../../../../models/module/fields/main';
import { CipoFieldTypes, CipoFieldValue, MappedFieldModel, FormItem } from '.';
import {
  DisplayType,
  FieldTypeEnum,
  FormattingType,
  FormattingValueType,
  KeyValueFormattingModel,
  KeyValueRestrictionModel,
  RestrictionType,
  RestrictionValueType,
} from '../../../../models/module/fields/enums';

@Injectable({
  providedIn: 'root',
})
export class FieldsService {
  mapFormFieldModels(fields: FormFieldModel[]): MappedFieldModel[] {
    return fields.map(field => this.mapFormFieldModel(field));
  }

  mapFormFieldModel(field: FormFieldModel): MappedFieldModel {
    const {
      id,
      name,
      value,
      label,
      displayType,
      formattings,
      restrictions,
      hasMultipleValues,
      annotation,
      dataSourceId,
      dataSources,
      isMandatory,
      x,
      y,
      rows,
      cols,
    } = field;

    const mappedField: MappedFieldModel = {
      id,
      name,
      label,
      value: annotation ?? value,
      type: this.getFormFieldType(field),
    };

    if (dataSourceId) mappedField.dataSourceId = dataSourceId;
    if (dataSources) mappedField.dataSources = dataSources;
    if (isMandatory) mappedField.required = true;
    if (hasMultipleValues) mappedField.multiple = displayType !== DisplayType.MultiLine;
    if (displayType === DisplayType.MultiLine) mappedField.multiline = true;
    if (x || y || cols || rows) {
      mappedField.position = { x, y, cols, rows };
    }

    return {
      ...mappedField,
      ...(restrictions && this.mapRestrictions(restrictions, formattings)),
      ...(formattings && this.mapFormattings(formattings)),
    };
  }

  private getFormFieldType(field: FormFieldModel): CipoFieldTypes {
    const { displayType, type } = field;
    switch (type) {
      case FieldTypeEnum.Attachment:
        return CipoFieldTypes.Attachment;
      case FieldTypeEnum.YesNo:
        return CipoFieldTypes.Checkbox;
      case FieldTypeEnum.Date:
        return CipoFieldTypes.DateTime;
      case FieldTypeEnum.Text:
        switch (displayType) {
          case DisplayType.RichText:
            return CipoFieldTypes.RichText;
          case DisplayType.Select:
            return CipoFieldTypes.Select;
          case DisplayType.Radio:
            return CipoFieldTypes.Listbox;
          default:
            return CipoFieldTypes.Text;
        }
      case FieldTypeEnum.Number:
        return CipoFieldTypes.Number;
      case FieldTypeEnum.Annotation:
        return CipoFieldTypes.Annotation;
      case FieldTypeEnum.Table:
        return CipoFieldTypes.Table;
    }
    // to be completed

    return CipoFieldTypes.Text;
  }

  private mapRestrictions(restrictions: KeyValueRestrictionModel[], formattings: KeyValueFormattingModel[]) {
    const restrictionProps: Partial<MappedFieldModel> = {};
    restrictions.forEach(r => {
      switch (r.key) {
        case RestrictionType.ReadOnly:
          restrictionProps.readonly = true;
          break;
        case RestrictionType.NeedsToBeChecked:
        case RestrictionType.Required:
          restrictionProps.required = true;
          break;
        case RestrictionType.Number:
          restrictionProps.decimals = (formattings || []).find(f => f.key === FormattingType.Decimals)?.formattingValue;
          break;
        case RestrictionType.DateTime:
          switch (r.value) {
            case RestrictionValueType.Date:
              restrictionProps.dateType = 'date';
              break;
            case RestrictionValueType.Time:
              restrictionProps.dateType = 'time';
              break;
            case RestrictionValueType.DateTime:
              restrictionProps.dateType = 'dateTime';
              break;
          }
          break;
        case RestrictionType.MimeTypes:
          restrictionProps.mimeTypes = r.mimeTypes;
          restrictionProps.mimeTypesDataSource = r.mimeTypesDataSource;
          break;
        case RestrictionType.ShowThousandSeparator:
          restrictionProps.showThousandSeparator = true;
          break;
        case RestrictionType.MaximumLength:
          restrictionProps.maxLength = r.restrictionValue;
      }
    });
    return restrictionProps;
  }

  private mapFormattings(formattings: KeyValueFormattingModel[]) {
    const formattingProps: Partial<MappedFieldModel> = {};
    formattings.forEach(f => {
      switch (f.key) {
        case FormattingType.Number:
          switch (f.value) {
            case FormattingValueType.Percentage:
              formattingProps.suffix = '%';
              break;
            case FormattingValueType.Money:
              formattingProps.isCurrency = true;
              break;
          }
          break;
        case FormattingType.Text:
          if (f.value === FormattingValueType.Multiline) {
            formattingProps.multiline = true;
          }
          break;
      }
    });
    return formattingProps;
  }

  mapFieldsToFormItems(fields: MappedFieldModel[]): FormItem[] {
    return fields.map(field => {
      const { id, value, dataSources, dateType, ...props } = field;
      return {
        id: id.toString(),
        value,
        fieldOptions: {
          options: dataSources ?? [],
          ...props,
        },
      };
    });
  }
}
