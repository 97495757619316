<div class="p-a-xl">
    <h3>Context with programs, projects and contracts, where a contract is selected by default</h3>
    <cipo-context-view [context]="context()" />

    <br />

    <h3>Context with programs, projects and contracts, where a project is selected by default</h3>
    <cipo-context-view [context]="contextProjectSelected()" />

    <br />

    <h3>Context with programs, projects and contracts, where a program is selected by default</h3>
    <cipo-context-view [context]="contextProgramSelected()" />

    <br />

    <h3>Context used in places where only contracts are used</h3>
    <cipo-context-view [context]="context()" />

    <br />

    <h3>Context with one contract, full tree shown</h3>
    <cipo-context-view />

    <br />

    <h3>Context with one contract, only contract is shown</h3>
    <cipo-context-view />

    <br />

    <h3>Context with no contract</h3>
    <cipo-context-view />
</div>
