import { cipo } from 'cipo';

cipo.controller('SaveWorkflowEntityInstanceController',
function ($state, $scope, WorkflowEntityInstance, Message, rememberManagerService, URI, URL_ACTIONS, 
    item, contractId, module, smallerFullscreen, userService, currencyService, screenId, $timeout, Upload, $document, $mdDialog, MODAL) 
{

    var itemId = parseInt(item.itemId) || 0;
    var fromItemId = parseInt(item.fromItemId) || 0;
    var module = module || {};

    $scope.moduleName = module.name;
    $scope.isInitiator = item.isInitiator || false;
    $scope.relationId = item.relationId;
    $scope.smallerFullscreen = smallerFullscreen || false;
    $scope.loadAssignments = false;
    $scope.modalInfo = MODAL;
    $scope.rolesChanged = false;

    currencyService.getAndSetCurrencyById($scope);

    $scope.loadInstance = function (itemId, fromItemId, moduleId) {
     
        // preserve isModified if changing between revisions
        var wasModified = ($scope.entityInstance || {}).isModified || false;

        $scope.entityInstance = new WorkflowEntityInstance({
            moduleId: moduleId,
            moduleCode: module.code,
            moduleName: module.name,
            entityInstanceId: itemId || null,
            fromEntityInstanceId: fromItemId || null,
            perContract: module.perContract,
            contractId: contractId,
            screenId: screenId,
            onFormInit: function (data) {
                currencyService.setCurrencyOnFormInit($scope, data);
            },
            onFormChange: function(fieldId, newValue, data, fields) {
                currencyService.setCurrencyOnFormChange($scope, fieldId, newValue, data, fields);
            },
        });
   
        if (item.presetId) {
            rememberManagerService.saveTemplateId(item.presetId);
   
            $scope.entityInstance.presetId = item.presetId;
            $scope.entityInstance.get_PresetData()
                .then(function () {
                    $scope.entityInstance.init();
                })
   
        } else $scope.entityInstance.init();
   
        $scope.entityInstance.isModified = wasModified;
    };
   
    $scope.loadAnotherInstance = function (eiId, moduleId, aId, aeId) {
        if ($state.current.name == 'tenant.dm.details') {
            var params = {
                id: moduleId,
                contractId: userService.system.userdata.contractId,
                urlActionId: aId || 0,
                urlActionEntityId: aeId || 0,
                entityId: eiId
            };
   
            try {
                $state.transitionTo($state.current.name, params, {
                    reload: true,
                    inherit: true,
                    notify: true
                });
            }
            catch (e) {
                console.error(e);
            }
        } else {
            $scope.loadInstance(eiId, aeId, moduleId);
        }
    }
   
    $scope.$on("$mdMenuClose", function () {
        try {
            $scope.entityInstance.propertiesScreen.data.searchUser = '';
        } catch { }
    });
   
    var pagetitle = $document[0].title;
    // unused?
    Object.defineProperty($scope, 'operations', {
        get: function () { return userService.getOperationsFor(module.code) }
    });

    $scope.hasUpdateOperation = $scope.operations['Update'] !== undefined && $scope.operations['Update'] !== null;
   
    // Get new workflow id and after load instance
    userService.getWorkflow(module.moduleId)
        .then(function() {
            $scope.loadInstance(itemId, fromItemId, module.moduleId);
        });
   
    $scope.openMenu = function ($mdMenu, ev) {
        // originatorEv = ev;
        $mdMenu.open(ev);
    };
   
    $scope.disabledBtn = false;
   
    $scope.duplicate = function () {
        $scope.loadAnotherInstance(0, null, URL_ACTIONS.CLONE, $scope.entityInstance.entityInstanceId);
    }

    $scope.uploadFiles = function (file, errFiles, isXer) {
        $scope.f = file;
        $scope.errFile = errFiles && errFiles[0];
        var params = "?entityInstanceId=" + $scope.entityInstance.entityInstanceId;
        var urlData = isXer ? URI.P.IMPORT_XER_FILE : URI.P.IMPORT_MS_PROJECT_FILE;
        if (file) {
            file.upload = Upload.upload({
                method: urlData.method,
                url: urlData.toString() + params,
                data: { file: file }
            });

                file.upload.then(function (response) {
                    $timeout(function () {
                        file.result = response.data;
                        Message.info("File imported successfully");
                        $scope.sov.get_categories();
                    });
                }, function (response) {
                    if (response.status > 0)
                        $scope.errorMsg = response.status + ': ' + response.data;
                    Message.dberror(response);
                }, function (evt) {
                    file.progress = Math.min(100, parseInt(100.0 *
                        evt.loaded / evt.total));
                });
        }
    }

    $scope.close = function () {
        if ($scope.entityInstance?.entityInstanceId) {
            rememberManagerService.saveInstanceId($scope.entityInstance.entityInstanceId);
        }
        else {
            rememberManagerService.reset();
        }

        $document[0].title = 'CIPO® Software - ' + pagetitle;

        if ($state.current.name == 'tenant.dm.details')
            $state.go('^');
        else
            $mdDialog.hide();
    }

    // prevent tab change if unsaved changes
    $scope.$on('tab-change', function (event, tabEvent) {
        if (!$scope.rolesChanged) { 
            tabEvent.accept();
        }
        else {
            tabEvent.reject();
        }
    });

    $scope.rolesChangedEmit = function(status) {
        $scope.rolesChanged = status;
    }

    $scope.closeAdjustments = function() {
        $scope.close();    
    }

    $scope.assignmentsChanged = function(status) {
        if (status) {
            userService.refreshContext();

            if ($scope.$root && $scope.$root.$$phase != '$apply' && $scope.$root.$$phase != '$digest') {
                $scope.$apply();
            }
        }
    }
});