import { Injectable } from '@angular/core';
import { RouteCodeEnum } from '../../models/menu';

export const RoutesDictionaryByCode: Partial<Record<RouteCodeEnum, string>> = {
  // [RouteCodeEnum.ChangeOrder]: "",
  [RouteCodeEnum.CompanyProfile]: 'conf',
  [RouteCodeEnum.Contacts]: 'externalcontacts',
  [RouteCodeEnum.Contracts]: 'contracts',
  [RouteCodeEnum.Correspondence]: 'emails',
  [RouteCodeEnum.Dashboard]: 'personal-dashboard',
  [RouteCodeEnum.Entity]: 'entities',
  [RouteCodeEnum.FileContractManager]: 'filecontractmanager',
  [RouteCodeEnum.FinancialSchedules]: 'financials',
  [RouteCodeEnum.Holidays]: 'holidays',
  [RouteCodeEnum.ListsManagement]: 'listsmanagement',
  [RouteCodeEnum.ManagementReports]: 'managementreports',
  [RouteCodeEnum.Maps]: 'maps',
  [RouteCodeEnum.Menu]: 'amenu',
  [RouteCodeEnum.Modules]: 'modules',
  [RouteCodeEnum.MyCIPODrive]: 'mycipodrive',
  [RouteCodeEnum.Organizations]: 'aorg',
  [RouteCodeEnum.Payments]: 'payments',
  [RouteCodeEnum.PDFMarkup]: 'pdfMarkup',
  [RouteCodeEnum.PowerBI]: 'reports',
  // [RouteCodeEnum.Programs]: "",
  // [RouteCodeEnum.Projects]: "",
  [RouteCodeEnum.ProgressPayment]: 'progresspayment',
  [RouteCodeEnum.Roles]: 'aroles',
  [RouteCodeEnum.Schedules]: 'schedules',
  [RouteCodeEnum.SmartMap]: 'smartmap',
  [RouteCodeEnum.SOV]: 'sov',
  [RouteCodeEnum.TenantSettings]: 'tenantsettings',
  [RouteCodeEnum.Users]: 'ausers',
};

export const HYBRID_MODULES = [-2, -10, -23];

@Injectable()
export class LegacyRoutes {
  definition: LegacyRouteDefinition[] = [
    { code: 'e404', api: false, name: 'e404', folder: 'system', error: true },

    { code: 'D', api: true, name: 'dashboard', folder: 'core' },
    {
      code: 'SIGN',
      api: false,
      name: 'user_sign',
      route: '/user_sign?s',
      folder: 'core',
      properties: { system: true },
    },
    { code: 'WLC', api: false, name: 'welcome', folder: 'core' },
    { code: 'IDP', api: false, name: 'idp', folder: 'core' },
    { code: 'SM', api: true, name: 'smartmap', folder: 'core' },
    { code: 'TST', api: true, name: 'tenantsettings', folder: 'core' },
    { code: 'PY', api: true, name: 'payments', folder: 'core' }, //OBSOLETE
    {
      code: 'C',
      api: true,
      name: 'emails',
      route: '/emails?f&e',
      folder: 'core',
      properties: { reloadOnSearch: false },
    },

    { code: 'FM', api: true, name: 'filemanager', folder: 'files' },
    { code: 'FCM', api: true, name: 'filecontractmanager', route: '/filecontractmanager?f', folder: 'files' },
    { code: 'MCD', api: true, name: 'mycipodrive', folder: 'files' },

    { code: 'CP', api: true, name: 'conf', folder: 'admin' },
    { code: 'SCH', api: true, name: 'schedules', folder: 'admin' },
    { code: 'FSCH', api: true, name: 'financials', folder: 'admin' },
    { code: 'PBI', api: true, name: 'reports', folder: 'admin' },
    { code: 'PDFM', api: true, name: 'pdfMarkup', route: '/pdfMarkup?d&f&n', folder: 'admin' },
    { code: 'LSTM', api: true, name: 'listsmanagement', folder: 'admin' },

    { code: 'M', api: true, name: 'modules', folder: 'admin' },
    { code: 'E', api: true, name: 'entities', folder: 'admin' },
    { code: 'U', api: true, name: 'ausers', route: '/ausers?sid', folder: 'admin' },
    { code: 'CT', api: true, name: 'contracts', folder: 'admin' },
    { code: 'CTA', api: true, name: 'externalcontacts', folder: 'admin' },
    { code: 'R', api: true, name: 'aroles', folder: 'admin' },
    { code: 'O', api: true, name: 'aorg', route: '/aorg?sid', folder: 'admin' },
    { code: 'MN', api: true, name: 'amenu', folder: 'admin' },
    { code: 'MAPS', api: true, name: 'maps', folder: 'admin' },
    { code: 'USER', api: true, name: 'userprofile', folder: 'admin' },

    {
      code: 'DYN-PP',
      api: true,
      name: 'progresspayment',
      folder: 'core',
      route: '/progresspayment/:contractId/:periodId/:entityInstanceId',
    },
    { code: 'DYN-SOV', api: true, name: 'sov', folder: 'core', route: '/sov/:contractId/:entityInstanceId' },
    { code: 'DYN-MOD', api: true, name: 'dm', folder: 'dynamics', route: '/dm/:contractId/:id' },
    { code: 'URL-MOD', api: true, name: 'url', folder: 'dynamics', route: '/url/:id' },
    {
      code: 'DYN-MREP',
      api: true,
      name: 'managementreports',
      folder: 'dynamics',
      route: '/managementreports/:contractId/:id',
    },
  ];

  list: LegacyRouteDefinition[] = [];
  systemList: LegacyRouteDefinition[] = [];
  appList: LegacyRouteDefinition[] = [];
  byCode: Map<string, LegacyRouteDefinition> = new Map<string, LegacyRouteDefinition>();

  constructor() {
    this.init();
  }

  init() {
    for (let i = 0; i < this.definition.length; i++) {
      var r = this.definition[i];
      if (r.name) {
        r.route = r.route || '/' + r.name;
        r.controller = r.controller || r.name;
        r.view = r.view || r.name;
        r.folder = r.folder || r.name;

        if (!r.properties) {
          r.properties = {};
        }

        this.list.push(r);
        if (r.properties.system) {
          this.systemList.push(r);
        } else {
          this.appList.push(r);
        }
      }
    }

    for (let j = 0; j < this.list.length; j++) {
      if (this.list[j].code) {
        this.byCode.set(this.list[j].code, this.list[j]);
      }
    }
  }
}

export interface LegacyRouteDefinition {
  code: string;
  api: boolean;
  name: string;
  folder: string;
  route?: string;
  controller?: string;
  view?: string;
  error?: boolean;
  properties?: LegacyRouteDefinitionProperties;
}

export interface LegacyRouteDefinitionProperties {
  system?: boolean;
  code?: string;
  error?: boolean;
  reloadOnSearch?: boolean;
}
