import { Injectable, inject } from '@angular/core';
import { AUTH_TOKEN, DIALOG_SIZE, TENANT } from 'src/app/shared/consts';
import { BREAKPOINTS } from '../../../app-breakpoints.module';
import { UserSettings } from '../../models/core';
import { UserStore } from '../../signal-store';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private userStore = inject(UserStore);

  getUserSettings(): UserSettings {
    return this.userStore.calculatedUserSettings();
  }

  getUserHeaders() {
    return {
      Authorization: 'Bearer ' + localStorage.getItem(AUTH_TOKEN),
      TenantId: sessionStorage.getItem(TENANT),
    };
  }

  getResponsiveDialogSize(): typeof DIALOG_SIZE {
    const windowWidth = window.innerWidth;
    const { full, lg, md, sm, xl } = DIALOG_SIZE;
    if (windowWidth < BREAKPOINTS.sm) {
      return { full, xl, lg: xl, md: xl, sm: xl };
    }
    if (windowWidth < BREAKPOINTS.md) {
      return { full, xl, lg: xl, md: lg, sm: md };
    }
    return DIALOG_SIZE;
  }
}
