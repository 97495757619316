import { cipo } from 'cipo';

cipo.factory('authInterceptor', ['$q', '$location', 'localStorageService',
    function ($q, $location, localStorageService) {

    var authInterceptorServiceFactory = {};

    var _request = function (config) {

        config.headers = config.headers || {};

        // alter behaviour for _api calls
        if (config.url.includes('_api')) {
            var tenantId = localStorageService.get('CurrentTenant');

            config.headers.tenantId = tenantId ? tenantId : 0;

            var token = localStorage.getItem('msal.idtoken');
            if (token) {
                config.headers.Authorization = 'Bearer ' + token;
            }
        }

        return config;
    };

    var _responseError = function (rejection) {
        if (rejection.status === 401) {
            $location.url('/login');
        }

        return $q.reject(rejection);
    };

    authInterceptorServiceFactory.request = _request;
    authInterceptorServiceFactory.responseError = _responseError;

    return authInterceptorServiceFactory;
}]);
