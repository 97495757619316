import { Pipe, PipeTransform } from '@angular/core';
import { KeyValueType } from '../../../../models/common';

@Pipe({
  name: 'assignments',
  standalone: true
})
export class AssignmentsPipe implements PipeTransform {

  transform(roleIds: number[], roles: KeyValueType<number>[]): string {
    return roles.filter(r => roleIds.includes(r.key)).map(a => a.value).join(', ');
  }
}
