import { cipo } from 'cipo';

cipo.controller('archiveSelectorController',
    function ($scope, $http, URI, $mdDialog, data, Message) {
        $scope.data = data.archiveOptions;
        $scope.title = data.title;
        $scope.showSelectUnselect = data.showSelectUnselect;
        $scope.confirmLabel = formatLabel(data.buttonLabels.find(x => x.key == 'confirm').value);
        $scope.cancelLabel = formatLabel(data.buttonLabels.find(x => x.key == 'cancel').value);
        $scope.description = data.description;
        $scope.selected = data.selected;

        $scope.confirm = function () {
            $mdDialog.hide(null);

            var filter = {};
            var modulesCheckboxes = [];
            
            $http[URI.MODULES.SEARCH.method](URI.MODULES.SEARCH.toString(), filter)
                .then(function (result) {
                    //console.log("result", result, result.data);                       
                    if (result.data && result.data.data.length > 0) {
                        for (var i = 0; i < result.data.data.length; i++) {
                            if (result.data.data[i].perContract == true) {
                                modulesCheckboxes.push({
                                    key: result.data.data[i].id,
                                    value: result.data.data[i].name,
                                    label: result.data.data[i].name,
                                    description: result.data.data[i].abbreviation
                                });
                            }
                        }
                    }
                })
                .catch(function (e) {
                    console.error(e);
                    Message.dberror(e);
                })

            var dialogLocals = {
                data: {
                    title: "Please select the modules you would like to create a download for:",                       
                    description: " ",
                    showSelectUnselect: true,
                    buttonLabels: [
                        { key: 'confirm', value: 'Create Module Download' },
                        { key: 'cancel',  value: 'Cancel' },
                    ],                        
                    checkboxes: modulesCheckboxes
                }
            }

            $mdDialog.show({
                templateUrl: '/ng/views/admin/modals/archiveModuleSelector.html',
                locals: dialogLocals,
                controller: 'archiveModuleSelectorController',
                parent: angular.element(document.body)
            })
                .then(function (result) {                                        

                }, function () {
                    
                });
        }

        $scope.cancel = function () {
            $mdDialog.hide(null);
        }

        $scope.selectAll = function () {
            for (var i = 0; i < $scope.data.length; i++) {
                $scope.data[i].value = true;
            }
        }

        $scope.deselectAll = function () {
            for (var i = 0; i < $scope.data.length; i++) {
                $scope.data[i].value = false;
            }
        }

        function formatLabel(label) {
            return label.charAt(0).toUpperCase() + label.slice(1).toLowerCase();
        }
    });
