import { Component, EventEmitter, inject, Input, OnInit, Output, signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ProjectFileModel } from '../../models/files/project-file.model';
import { Permission } from '../../models/permission';
import { AddNewComponent } from '../../module/instance/attachments-dialog/add-new/add-new.component';
import {
  DuplicateVersionBody,
  DuplicateVersionParams,
  ProjectFilesAddNewModel,
} from '../../module/instance/my-drive/common';
import {
  FileFactoryService,
  FileServiceType,
  IFileService,
} from '../../module/instance/my-drive/common/services/file-factory.service';
import { CipoImgBase64 } from '../../shared/components/img-base64/img-base64.component';
import { SharedComponentsModule } from '../../shared/modules';
import { NotificationService, UserService } from '../../shared/services';
import { PermissionsComponent } from '../permissions/permissions.component';
import { PeopleModel } from '../permissions/permissions.model';
import { PermissionsService } from '../permissions/permissions.service';

@Component({
  selector: 'app-file-dialog',
  standalone: true,
  providers: [PermissionsService, FileFactoryService],
  imports: [SharedComponentsModule, PermissionsComponent, CipoImgBase64],
  templateUrl: './file-dialog.component.html',
})
export class FileDialogComponent implements OnInit {
  translate = inject(TranslateService);
  notificate = inject(NotificationService);
  permissionsService = inject(PermissionsService);
  dialog = inject(MatDialog);
  userService = inject(UserService);
  fileFactoryService = inject(FileFactoryService);

  hasUpdatePermission = signal<boolean>(false);
  loading = signal<boolean>(true);
  peoplePermissionsList: PeopleModel[];
  permissionChanged: boolean = false;
  permissionsRemoved = signal(false);
  @Input('rootmoduleid') rootModuleId: number;
  @Input('canchangepermissions') canChangePermissions: boolean;
  @Input('fileid') fileId: number;
  @Input('permissions') permissions: number[] = [];
  @Input('entityinstanceid') entityInstanceId: number;
  @Output('onsave') onSave: EventEmitter<void> = new EventEmitter<void>();
  @Output('onclose') onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  file: ProjectFileModel;

  private filesService: IFileService;

  ngOnInit(): void {
    this.hasUpdatePermission.set(this.permissions.some(p => p === Permission.Update) ?? false);

    this.filesService = this.fileFactoryService.getServiceType(
      this.entityInstanceId ? FileServiceType.ProjectFiles : FileServiceType.MyDrive,
    );

    this.getFile();
  }

  getFile(versionId?: number) {
    this.loading.set(true);

    this.filesService.getFile<ProjectFileModel>(this.fileId, this.entityInstanceId, versionId).subscribe({
      next: f => {
        this.file = f;
        this.loading.set(false);
      },
      error: () => {
        this.loading.set(false);
      },
    });
  }

  changeVersion(versionId: number) {
    this.getFile(versionId);
  }

  onCloseClick() {
    this.onClose.emit(this.permissionsRemoved());
  }

  onSaveClick() {
    // save if we did some changes to the permissions
    if (this.permissionChanged) {
      this.permissionsService
        .syncFilePermissions(this.peoplePermissionsList, this.file.id, this.entityInstanceId)
        .subscribe(() => {
          this.notificate.success(this.translate.instant('fileFolderPermissions.savePermissions'));
          this.onSave.emit();
        });
    }
  }

  permissionChangedEvent(list: PeopleModel[]) {
    if (!list?.length) {
      this.peoplePermissionsList = list;
      this.permissionsRemoved.set(true);
    } else {
      this.peoplePermissionsList = list;
      this.permissionChanged = true;
    }
  }

  isCurrentVersion(versionId?: number) {
    return (versionId ?? this.file.versions?.find(v => v.isCurrent)?.id) === this.file.versionId;
  }

  downloadFile() {
    this.filesService.downloadFileFromUrl(this.file.url);
  }

  uploadFile() {
    this.dialog
      .open<AddNewComponent, ProjectFilesAddNewModel>(AddNewComponent, {
        panelClass: ['cipo-dialog', 'classic'],
        data: {
          modalType: 'newFile',
          parentId: this.file.parentId,
          entityInstanceId: this.entityInstanceId,
          fileId: this.file.id,
        },
        ...this.userService.getResponsiveDialogSize().sm,
      })
      .afterClosed()
      .subscribe(() => {
        this.getFile();
      });
  }

  duplicateVersion() {
    const params: DuplicateVersionParams = { entityInstanceId: this.entityInstanceId, roleId: 0 };
    const body: DuplicateVersionBody = { fileId: this.file.id, versionId: this.file.versionId };

    this.loading.set(true);

    this.filesService.duplicateVersion(params, body).subscribe(versionId => {
      this.getFile(versionId);
    });
  }
}
