import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs';

import { DataListCommandFilterType, FiltersToApply } from './../common';
import { DEBOUNCE_TIMES } from 'src/app/shared/consts';
import { TenantStore } from '../../../../signal-store';

@Component({
  selector: 'app-data-list-command',
  templateUrl: './data-list-command.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataListCommandComponent implements OnInit {
  tenantStore = inject(TenantStore);
  searchControl = new FormControl('');
  filterIsClear: boolean;
  filtersToApply: FiltersToApply;

  @ViewChild('sidenav') sidenav: MatSidenav;

  @Input() filters: DataListCommandFilterType[] = [];
  @Input() hidefilter = false;
  @Input() hideReload = false;
  @Input() sidenavclass = '';

  @Output() searchcriteria = new EventEmitter<string>();
  @Output() applyfilters = new EventEmitter<FiltersToApply>();

  ngOnInit(): void {
    this.searchControl.valueChanges.pipe(debounceTime(DEBOUNCE_TIMES.default)).subscribe(() => this.applyFilters());
  }

  clearInput() {
    this.searchControl.reset();
  }

  resetFilter() {
    this.filterIsClear = true;
    this.filtersToApply.filters = null;
    this.applyFilters();
  }

  applyFilters(filters?: FiltersToApply) {
    if (this.hidefilter) {
      this.applyfilters.emit({
        criteria: this.searchControl.value,
      });
      return;
    }

    this.filterIsClear = !filters?.filters;
    this.filtersToApply = filters;
    if (filters) {
      this.sidenav.toggle();
    }

    this.emitApplyFilters();
  }

  emitApplyFilters() {
    this.applyfilters.emit({
      ...this.filtersToApply,
      criteria: this.searchControl.value === '' ? null : this.searchControl.value,
    });
  }
}
