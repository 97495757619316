import { cipo } from 'cipo';

cipo.directive("attachmentdirective", function (ACTIONS, URI, APP_CONTENT, Upload, $timeout, $q, Manager, Name, MODAL, userService, Message, Model, fileService) {
    return {
        restrict: "A",
        // replace: true,
        scope: {
            "data": "=",
            // "showmanager": "=?",
            // "issingle": "=?",
            "fielddata": "="
        },
        templateUrl: "/ng/views/directives/system/attachmentdirective.html",
        controller: function ($scope) {
            $scope.modalInfo = MODAL;
            $scope.indicative = ($scope.fielddata || {}).fieldId ||($scope.fielddata || {}).id || 0;

            $scope.content = APP_CONTENT;
            // $scope.isCreateVersion = true;

            $scope.fielddata.showmanager = false;
            // if field config attachment or form field
            if (typeof (($scope.fielddata.properties || {}).hasMultipleValues) != 'undefined') {
                $scope.fielddata.hasMultipleValues = $scope.fielddata.properties.hasMultipleValues;
            } else {
                $scope.fielddata.hasMultipleValues = typeof ($scope.fielddata.hasMultipleValues) != 'undefined' ? $scope.fielddata.hasMultipleValues : true;
            }
            
            if (!$scope.data || !$scope.data.length || $scope.data.length == 1 && !$scope.data[0]) $scope.data = [];

            if (typeof $scope.fielddata.row != 'undefined') $scope.indicative = $scope.indicative.toString() + $scope.fielddata.row.toString();

            $scope.breadcrumb = [];
            if (!$scope.currentFolder) $scope.currentFolder = { id: -1 };
            var setClass = function (mimetype) {
                return mimetype ? mimetype.replace(/\//g, '-').replace(/\./g, '-') : "";
            };
            // $scope.fielddata.showmanager = false;
            $scope.breadcrumbItemWidth = 100;
            $scope.removeAttachment = function (file) {
                $scope.data.splice($scope.data.indexOf(file), 1);
                filesIds.splice(filesIds.indexOf(file.id), 1);
                if (managerLookup && managerLookup[file.id]) managerLookup[file.id].selected = false;
            }

            $scope.menuActions = {
                remove: function (x) { return $scope.removeAttachment(x); }
            };

            $scope.changeFolder = function (folder, fromBreadcrumb) {
                $scope.currentFolder = folder;
                $scope.manager.criteria = undefined;
                $scope.reloadManager();
                if (!fromBreadcrumb) $scope.breadcrumb.push(folder);
                else {
                    $scope.breadcrumb.length = $scope.breadcrumb.indexOf(folder) + 1;
                }
                if ($scope.breadcrumb.length) $scope.breadcrumbItemWidth = 100 / ($scope.breadcrumb.length + 1);
            }
            $scope.back = function () {
                if ($scope.breadcrumb.length > 1) $scope.currentFolder = $scope.breadcrumb[$scope.breadcrumb.length - 2];
                else $scope.currentFolder = { id: -1 };
                $scope.breadcrumb.length -= 1;
                $scope.reloadManager();
            }

            $scope.reloadManager = function () {
                //$scope.manager.dataURL = URI.MY_CIPO_DRIVE.SEARCH;
                //$scope.manager.otherParams = { id: $scope.currentFolder.id, criteria: undefined };
                //$scope.manager.dataWrapper = ["folders", "files"];
                loadManagerStuff();
            }

            $scope.hideManager = function () {
                $scope.fielddata.showmanager = false;
                $scope.uploadingFiles = false;

                $scope.editingItem = false;
            }
            var filesIds = [];
            if ($scope.data && $scope.data.length) {
                for (var i = 0; i < $scope.data.length; i++) {
                    filesIds.push($scope.data[i].id);
                    $scope.data[i].class = "fileInfo " + setClass($scope.data[i].mimeType || (userService.system.mimeTypes.dict[$scope.data[i].mimeTypeId] || {}).description);
                }

            }
            $scope.addAttachment = function (file, isFromUpload) {

                //if (typeof $scope.issingle == 'undefined')
                //    if (typeof ($scope.fielddata || {}).hasMultipleValues != 'undefined') $scope.issingle = !$scope.fielddata.hasMultipleValues;
                //    else $scope.issingle = false;

                if (!$scope.fielddata.hasMultipleValues) {
                    if (filesIds.length) {
                        if (filesIds[0] == file.id) return;
                        else {
                            if (managerLookup[$scope.data[0].id]) managerLookup[$scope.data[0].id].selected = false;
                        }
                    } 
                    $scope.data[0] = file;
                    filesIds[0] = file.id;
                    file.selected = true;
                } else {
                    if (filesIds.indexOf(file.id) == -1) {
                        filesIds.push(file.id);
                        file.class = "fileInfo " + setClass(file.mimeType);
                        $scope.data.push(file);
                        file.selected = true;
                    } else {
                        $scope.data.splice(filesIds.indexOf(file.id), 1);
                        filesIds.splice(filesIds.indexOf(file.id), 1);
                        file.selected = false;
                        // if it is from upload, it is the same file, different version
                        if (isFromUpload) {
                            $scope.addAttachment(file);
                        }
                        
                    }
                }

            }
            $scope.uploadFiles = function () {
                $scope.uploadingFiles = true;
                $scope.filesToUpload = [];
            }
            $scope.closeModal = function () {
                $scope.uploadingFiles = false;
                $scope.editingItem = false;
                if ($scope.uploadedFiles) loadManagerStuff();
            }

            $scope.loadManager = function () {
                // $scope.fielddata.showmanager = true;
                $scope.manager = new Manager({
                    dataURL: URI.MY_CIPO_DRIVE.SEARCH,
                    dataWrapper: ['data'],
                    otherParams: { 
                        id: function () { return $scope.currentFolder.id; }, 
                        criteria: function () { return $scope.manager.criteria; } 
                    },
                    noFilters: true,
                    // search: false,
                    //layout: self.layout,
                    menuClass: "inlineManager",
                    rowOnClick: function (row) {
                        if (row.isFolder) {
                            $scope.changeFolder(row);
                        } else {
                            $scope.addAttachment(row);
                        }
                    },
                    parseData: function (data, columns) {
                        try {
                            var data = data || [], retval = [];
                            for (var i = 0; i < data.length; i++) {
                                var sharedClass = data[i].id == -2 ? "sharedFolder" : "";
                                data[i]._name = data[i].name;
                                if (!data[i].isFolder) {
                                    if (data[i].thumbnail50)
                                        data[i]._name = '<img class="tableSmallTmb" src="data:'
                                            + data[i].mimeType + ';base64,' + data[i].thumbnail50 + '" />'
                                            + data[i].name;
                                    else data[i]._name = '<span class="fileInfo ' + setClass(data[i].mimeType) + '">' + data[i].name + '</span>';
                                } else {
                                    data[i]._name = '<span class="fileInfo folderIcon ' + sharedClass + '">' + data[i].name + '</span>';
                                }
                                data[i]._big_name = data[i].name;
                                if (!data[i].isFolder) {
                                    if (data[i].thumbnail200)
                                        data[i]._big_name = '<span class="thumbWrapper"><img src="data:'
                                            + data[i].mimeType + ';base64,' + data[i].thumbnail200 + '" /></span><span class="ellipsedText fileInfo '
                                            + setClass(data[i].mimeType) + '">' + data[i].name + '</span>';
                                    else data[i]._big_name = '<span class="bigIcon fileInfo '
                                        + setClass(data[i].mimeType) + '"></span><span class="ellipsedText fileInfo '
                                        + setClass(data[i].mimeType) + '">' + data[i].name + '</span>';
                                } else {
                                    data[i]._big_name = '<span class="fileInfo folderIcon ' + sharedClass + ' ellipsedText">' + data[i].name + '</span>';
                                }
                                if (data[i].length) {
                                    if (data[i].length < 1000000) data[i]._length = '<div class="text-right">' + (data[i].length / 1000).toFixed(2) + 'KB</div>';
                                    if (data[i].length >= 1000000) data[i]._length = '<div class="text-right">' + (data[i].length / 1000000).toFixed(2) + 'MB</div>';
                                } else {
                                    data[i]._length = '<div class="text-center">-</div>';
                                }
                                retval.push(data[i]);
                            }
                            return retval;
                        }
                        catch (e) {
                            console.error(e);
                        }

                    },
                    leftActions: [
                        {
                            setProperties: ACTIONS.BACK,
                            conditionOnTop: function () {
                                if ($scope.currentFolder.id != -1) return true;
                                else return false;
                            },
                            click: function () {
                                $scope.back();
                            }
                        },
                        {
                            setProperties: ACTIONS.NEWFOLDER,
                            conditionOnTop: function () {
                                return true;
                            },
                            click: function (row) {
                                $scope.editItem();
                            }
                        },
                        {
                            setProperties: ACTIONS.UPLOAD,
                            type: 'primary',
                            click: function (row) {
                                $scope.uploadFiles();
                            }
                        },
                    ],
                    actions: [

                        {
                            setProperties: ACTIONS.DETAILS,
                            condition: function (row) {
                                if (row.isFolder)
                                    return true;
                                else return false;
                            },
                            click: function (row) {
                                $scope.changeFolder(row);
                            }
                        },
                        {
                            setProperties: ACTIONS.EDIT,
                            // displayOnRow: self.operations.Update ? true : false,
                            name: "Rename",
                            click: function (row) {
                                $scope.editItem(row);
                            },
                            condition: function (row) {
                                return true;
                            },
                        }
                    ]
                });
                var columns = [
                    { name: "_name", label: 'Name', type: 'checkbox', onTablet: true, onPhone: true, width: 30, replacementFor: 'name' },
                    { name: "createdBy", label: 'Owner', onTablet: true, onPhone: true, width: 30, type: 'text' },
                    { name: "createdOn", label: 'Created On', onTablet: true, onPhone: false, width: 15, type: 'date' },
                    { name: "updatedOn", label: 'Last Modified', onTablet: true, onPhone: false, width: 15, type: 'date' },
                    { name: "_length", label: 'File Size', type: 'checkbox', onTablet: true, onPhone: false, width: 10, replacementFor: 'length' }
                ]
                $scope.manager.set_Columns(columns);
                loadManagerStuff();

            }
            var managerLookup;
            var loadManagerStuff = function () {
                $scope.manager.loadPage()
                    .then(function () {
                        managerLookup = {};
                        //loadThumbs();
                        for (var i = 0; i < $scope.manager.rows.length; i++) {
                            managerLookup[$scope.manager.rows[i].id] = $scope.manager.rows[i];
                            if (filesIds.indexOf($scope.manager.rows[i].id) != -1) $scope.manager.rows[i].selected = true;
                        };
                    })
                    .catch(function (e) { console.error(e) })
            }

            $scope.$watch(function () { return $scope.fielddata.showmanager; }, function (n, o) {
                if (n == true) {
                    if ($scope.currentFolder.id == -1) $scope.loadManager();
                    else $scope.reloadManager();
                    $('#attachmentContainer' + $scope.indicative).addClass('zIndexGod');
                    $('#attachmentContainer' + $scope.indicative).appendTo('body');

                } else if (typeof n !== 'undefined' && o) {
                    $('#attachmentContainer' + $scope.indicative).removeClass('zIndexGod');
                    $('#attachmentContainer' + $scope.indicative).appendTo('#attachmentBase' + $scope.indicative);
                }
            });

            $scope.editItem = function (item) {
                $scope.ana = false;
                var item = item || { name: null, parentId: $scope.currentFolder.id }
                $scope.item = new Name(item);
                if (item.id && !item.isFolder) {
                    $scope.item.properties.name = $scope.item.properties.name.substring(0, $scope.item.properties.name.lastIndexOf("."))
                    $scope.extension = item.name.substring(item.name.lastIndexOf("."));
                }

                $scope.editingItem = true;
                $timeout(function () {
                    $scope.ana = true;
                }, 400)
            }
            $scope.saveItem = function () {
                $scope.item.form.validate();
                $scope.item.form.loading = true;
                $scope.disableBtns = true;
                //var p = $q.defer();
                var dataURL = !$scope.item.properties.id ? URI.MY_CIPO_DRIVE.CREATE_FOLDER :
                    ($scope.item.properties.isFolder ? URI.MY_CIPO_DRIVE.EDIT_FOLDER : URI.MY_CIPO_DRIVE.RENAME_FILE);

                if ($scope.item.form.isValid) {
                    // put back extension if file
                    if ($scope.item.properties.id && !$scope.item.properties.isFolder)
                        $scope.item.properties.name += $scope.extension;
                    $scope.item[dataURL.method](dataURL, { body: $scope.item.properties })
                        .then(function (result) {
                            loadManagerStuff();
                            if (!$scope.item.properties.isFolder) {
                                if (filesIds.indexOf($scope.item.properties.id) != -1) {
                                    $scope.data[filesIds.indexOf($scope.item.properties.id)].name = $scope.item.properties.name;
                                }
                            }
                            $scope.editingItem = false;
                        }).catch(function (e) {
                            console.error(e);
                            $scope.item.form.catch(e);

                        })
                        .finally(function () {
                            $scope.disableBtns = false;
                        })
                }
                else {
                    $scope.item.form.loading = false;
                    $scope.disableBtns = false;
                    // p.reject();
                }
            };

            var fileURLs = {
                generateFileIdURL: URI.MY_CIPO_DRIVE.GENERATE_FILEID,
                startUploadURL: URI.MY_CIPO_DRIVE.UPLOAD_FILE_CHUNKS,
                uploadChunkURL: URI.MY_CIPO_DRIVE.UPLOAD_CHUNK,
                saveFileInfoURL: URI.MY_CIPO_DRIVE.SAVE_FILE_INFO,
                uploadFileURL: URI.MY_CIPO_DRIVE.UPLOAD_FILE,
                // downloadFileURL: URI.MY_CIPO_DRIVE.DOWNLOAD,
                deleteFileURL: URI.MY_CIPO_DRIVE.DELETE,
                renameFileURL: URI.MY_CIPO_DRIVE.RENAME_FILE
            }

            $scope.test = {isCreateVersion: true};
            $scope.test.files = [];
            $scope.isUploadInProgress = false;
            $scope.$watch(function () { return $scope.test.files; }, function (n, o) {
                $scope.upload($scope.test.files);
            });
            $scope.filesToUpload = [];

            $scope.upload = function (files) {
                $scope.filesToUpload = files;
                var urlString = "parentId=" + $scope.currentFolder.id + "&";

                var generateFileId = function (file) {
                    Model[fileURLs.generateFileIdURL.method](fileURLs.generateFileIdURL)
                        .then(function (r) {
                            uploadFile(file, r);
                        })
                        .catch(function (e) {
                            Message.dberror(e);
                        })
                }

                var uploadFile = function (file, r) {

                    var physicalFileId = r.physicalFileId;
                    file.message = "Uploading file...";

                    var finalizeUpload = function (file, fileId, chunksNumber) {
                        var physicalFileId = fileId;
                        var p = $q.defer();                            

                        var params = {
                            mimeType: file.mimeType,
                            fileId: physicalFileId,
                            isCreateVersion: $scope.test.isCreateVersion,
                            totalBlocks: chunksNumber,
                            parentId: $scope.currentFolder.id ? $scope.currentFolder.id : -1,
                            contractId: userService.system.userdata.contractId ? userService.system.userdata.contractId : 0,
                            roleId: 0
                        };

                        Model[fileURLs.saveFileInfoURL.method](fileURLs.saveFileInfoURL, { url: params, urltype: 'obj', body: { filename: file.name, bytes: file.size } })
                            .then(function (r) {
                                p.resolve(r)
                            })
                            .catch(function (e) {
                                Message.dberror(e);
                                p.reject(e);
                            })

                        return p.promise;
                    }                        

                    if (!file.$error) {
                        if (file.size <= 62914560) { // 60 MB
                            $scope.uploadObject = Upload.upload({
                                url: fileURLs.uploadFileURL + '?' + urlString + 'isCreateVersion=' + $scope.test.isCreateVersion  + '&mimeType='+ file.mimeTypeQueryString,
                                data: {
                                    file: file
                                }
                            });
                        }
                        else {
                            $scope.uploadObject = Upload.upload({
                                url: fileURLs.uploadChunkURL.toString() + '?fileId=' + physicalFileId,
                                data: {
                                    file: file
                                },
                                resumeChunkSize: '50MB',
                                resumeSizeUrl: fileURLs.startUploadURL.toString() + '?name=' + encodeURIComponent(file.name),
                                resumeSizeResponseReader: function (data) { return data.size; }
                            });
                        }

                        $scope.isUploadInProgress = Upload.isUploadInProgress();

                        $scope.uploadObject
                            .then(function (result) {
                                $timeout(function () {

                                    if (file.size > 62914560) // 60 MB
                                    {
                                        //var chunksNumber = $scope.uploadObject.$$state.value.data;

                                        // calculate the total number of chuncks, every chunck should have 50 MB
                                        var chunksNumber = Math.ceil(file.size / 52428800);

                                        finalizeUpload(file, physicalFileId, chunksNumber)
                                            .then(function (r) {
                                                $scope.addAttachment(r, true);

                                                file.message = "File uploaded successfully.";
                                                $scope.isUploadInProgress = false;
                                                $scope.uploadedFiles = true;
                                            })
                                    }
                                    else {
                                        $scope.addAttachment(result.data, true);

                                        file.message = "File uploaded successfully.";
                                        $scope.isUploadInProgress = false;
                                        $scope.uploadedFiles = true;
                                    }           
                                    
                                });
                            }, function (response) {
                                if (response.status > 0) {
                                    file.message = "An error has occured. The file couldn't be uploaded.";
                                    file.error = true;
                                }
                            }, function (evt) {
                                file.progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                            });
                    }
                }

                if (files && files.length) {
                    angular.forEach(files, function (file) {
                        fileService.setMimeTypeFromExt(file);
                        generateFileId(file);
                    });
                }
            };
        }
    }
});
