import { cipo } from 'cipo';

cipo.factory('validationService', ['Message',
    function (Message) {
        var validationService = {};

        validationService.validateExpression = function (formula, fieldTypeId, skipLabel) {

            // Check if we have any formula
            if (!formula)
                return [null, null];

            var validFormula = true;
            if (!formula.label && !skipLabel) {
                formula.errors.label = "This field is required";
                validFormula = false;
            }
            for (var i = 0; i < formula.formula.length; i++) {
                if (formula.formula[i].type == 'function')
                    if (!formula.formula[i].field && !formula.formula[i].isDate) {
                        formula.formula[i].hasError = true;
                        validFormula = false;
                        Message.error("The formula contains a not valid function");
                    }
                if (formula.formula[i].type == 'number')
                    if (!formula.formula[i].value) {
                        formula.formula[i].hasError = true;
                        validFormula = false;
                        Message.error("The formula contains a not valid number");
                    }
            }
            if (validFormula && formula.formula.length > 0) {
                if (formula.formula[formula.formula.length - 1].type == "operator") {
                    formula.formula[formula.formula.length - 1].hasError = true;
                    validFormula = false;
                    Message.error("The formula cannot end with an operator");
                }

                // Check if we closed the parenthesis
                var countStartParenthesis = formula.formula.filter(s => s.type == 'parenthesis' && s.value == '(');
                var countEndParenthesis = formula.formula.filter(s => s.type == 'parenthesis' && s.value == ')');

                if (formula.formula[formula.formula.length - 1].value == "(") {
                    validFormula = false;
                    Message.error("The formula cannot end with a start parenthesis");
                }
                else if ((countStartParenthesis || []).length != (countEndParenthesis || []).length) {
                    validFormula = false;
                    Message.error("The formula needs to have the same number of start and close parenthesis");
                }
            }

            // if we are on date field, we need to have at least one date field
            if (fieldTypeId && fieldTypeId == 4) {
                if (formula.formula.filter(f => f.isDate == true).length == 0 && formula.formula.length > 0) {
                    validFormula = false;
                    Message.error("Formula needs to have at least one date field");
                }
            }

            if (formula.formula.filter(f => f.isDate == true
                                            && (f.has2ndParameter && (typeof f.secondParameter == 'undefined' || !f.secondParameter) || !f.has2ndParameter || typeof f.thirdParameter == 'undefined' || !f.thirdParameter)).length > 0
                                            && formula.formula.length > 0) {
                validFormula = false;
                Message.error("Date field needs to have second and third parameter.");
            }

            if (validFormula) {
                var expression = "", formattings = [], expressionFields = [];
                for (var i = 0; i < formula.formula.length; i++) {
                    if (formula.formula[i].type == 'function') {
                        // date function needs to be treated differently
                        if (formula.formula[i].isDate) {
                            expression += formula.formula[i].value + '(';

                            if (formula.formula[i].hasDatePart) {
                                expression += formula.formula[i].datePart.value;
                                expression += ",";
                            }

                            if (formula.formula[i].has2ndParameter) {
                                // If is a number field, then we need to add the key
                                if (formula.formula[i].secondParameter.key) {
                                    expression += '{{' + formula.formula[i].secondParameter.key + '}}';
                                    expressionFields.push(formula.numericFields.find(n => n.key == formula.formula[i].secondParameter.key) || formula.dateFields.find(n => n.key == formula.formula[i].secondParameter.key));
                                }
                                // is a number input, we need just the value
                                else {
                                    expression += formula.formula[i].secondParameter;
                                }

                                // if we have 3rd parameter, then we need a comma after between 2nd and 3rd
                                if (formula.formula[i].has3rdParameter) {
                                    expression += ",";
                                }
                            }

                            if (formula.formula[i].has3rdParameter) {
                                // If is a number field, then we need to add the key
                                if (formula.formula[i].thirdParameter.key) {
                                    expression += '{{' + formula.formula[i].thirdParameter.key + '}}';
                                    expressionFields.push(formula.numericFields.find(n => n.key == formula.formula[i].thirdParameter.key) || formula.dateFields.find(n => n.key == formula.formula[i].thirdParameter.key));
                                }
                                // is a number input, we need just the value
                                else {
                                    expression += formula.formula[i].thirdParameter;
                                }
                            }

                            expression += ')';
                        }
                        // We have a number function
                        else {
                            expression += formula.formula[i].value;
                            expression += '({{';
                            expression += formula.formula[i].field.key;
                            expression += '}})';

                            expressionFields.push(formula.numericFields.find(n => n.key == formula.formula[i].field.key) || formula.dateFields.find(n => n.key == formula.formula[i].field.key));
                        }
                    } else {
                        // We have a parenthesis or a number input
                        expression += formula.formula[i].value;
                    }
                }

                for (var i = 0; i < formula.formattingsList.length; i++) {
                    formattings.push({
                        key: formula.formattingsList[i].key,
                        value: formula.formattingsList[i].value,
                        formattingValue:  formula.formattingsList[i].formattingValue
                    })
                }

                return [expression, formattings, expressionFields];
            }
        };

        return validationService;
    }]);