import { cipo } from 'cipo';

cipo.controller('editExternalUserController',
    function ($scope, obj, operations, module, $mdDialog, ExternalContact, MODAL, $timeout) {

        $scope.modalInfo = MODAL;

        $scope.obj = obj || null;

        $scope.user = new ExternalContact(obj);

        $scope.operations = operations || {};
        $scope.module = module;
        $scope.changeSelectedRole = function (r) {
            $scope.selectedRole = r || module.roles[0];
            $scope.user.selectedRoleId = $scope.selectedRole ? $scope.selectedRole.key : 0;
        };
        $scope.changeSelectedRole();
        $scope.openMenu = function ($mdMenu, ev) {
            $timeout(function () {
                $scope.focusSearch = true;
                $timeout(function () {
                    $scope.focusSearch = false;

                }, 300);
            }, 300);
            $mdMenu.open(ev);
        };

        //$scope.disabledBtn = false;
        $scope.user.message = "";

        $scope.closingModal = false;

        $scope.revertTabInfo = function () {
            $scope.close(true);
        };

        $scope.cancelAction = function () {
            $scope.user.tabWarning = false;
            $scope.user.hightlight = false;
            //$scope.disabledBtn = false;
            $scope.closingModal = false;     
        };

        $scope.updateTabInfo = function () {
            $scope.user.hightlight = false;
            //$scope.actionBtnDisabled = true;
            
            $scope.user.save()
                .then(function (r) {
                    $scope.user.tabWarning = false;
                    $scope.close(true);

                })
                .catch(function (e) {
                    console.error(e);
                })
                .finally(function () {

                    //$scope.disabledBtn = false;
                    $scope.actionBtnDisabled = false;
                });
        };

        $scope.close = function (isWarning) {
            if (!$scope.user.form.dirty || isWarning)
                if ($scope.user.isModified) $mdDialog.hide();
                else $mdDialog.cancel();
            else {

                $scope.user.hightlight = true;
                $scope.user.tabWarning = true;
                $scope.closingModal = true;
            }
        }

        $scope.actionsOptions = {
            close: $scope.close,
            revertTabInfo: $scope.revertTabInfo,
            updateTabInfo: $scope.updateTabInfo,
            cancelAction: $scope.cancelAction,
        }

    });
