import { Injectable, inject } from '@angular/core';
import { UserStore } from '../../signal-store';
import { ContextInfo } from '../../system/context/context.model';
import { JsToNgService } from './js-to-ng.service';

@Injectable({
  providedIn: 'root',
})
export class BridgeService {
  userStore = inject(UserStore);
  jsToNgService = inject(JsToNgService);

  constructor() {
    this.jsToNgService.refreshContextData.subscribe(value => {
      this.userStore.setReloadContext(value);
    });

    this.jsToNgService.context.subscribe(context => {
      if (!context || this.getEntityInstanceId(this.userStore.userData().context) === this.getEntityInstanceId(context)) {
        return;
      }
      this.userStore.updateContext(context);
    });

    this.jsToNgService.closedModalData.subscribe(value => {
      this.userStore.closeJsDialog(value);
    });

    this.jsToNgService.refreshUser.subscribe(() => {
      this.userStore.refreshUserData();
    });
  }

  private getEntityInstanceId(context: ContextInfo): number {
    return (
      context?.contract?.entityInstanceId ?? context?.project?.entityInstanceId ?? context?.program?.entityInstanceId
    );
  }
}
