import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { ChangeDetectorRef } from '@angular/core';
import { Sort } from '@angular/material/sort';
import moment from 'moment';
import { BehaviorSubject, Observable, Subject, Subscription, catchError, filter, of, takeUntil, tap } from 'rxjs';
import { WeatherFieldModel } from '../../../models/module/fields/main';
import { NotificationService } from '../../../shared/services/notification.service';
import { WeatherTableModel } from './models/weather-table.model';
import { WeatherTableService } from './weather-table.service';

export class TableDataSource implements DataSource<WeatherFieldModel> {
  protected weatherTimeFields$ = new BehaviorSubject<WeatherFieldModel[]>([]);

  destroy$: Subject<boolean> = new Subject<boolean>();

  loadData$: Observable<WeatherFieldModel[]>;

  fieldDataInitialization: Subscription;

  table: WeatherTableModel = new WeatherTableModel();

  constructor(
    private weatherService: WeatherTableService,
    private notification: NotificationService,
    private cdRef: ChangeDetectorRef,
    private fielddata: {
      _fieldProperty: number;
      entityInstanceId: number;
      actionInstanceId: number;
      _editMode: boolean;
      _value: any;
      contractId: number;
    },
    public fieldId: number,
    private timeZoneOffsetMinutes: number,
  ) {}

  loadData() {
    this.weatherService
      .getTimeFieldInstances(
        this.fielddata._fieldProperty,
        this.fielddata.entityInstanceId,
        this.fielddata.actionInstanceId,
        null,
      )
      .pipe(
        filter(result => result.length > 0),
        catchError(err => {
          this.notification.error(err);
          return of([]);
        }),
      )
      .subscribe(res => {
        this.setRows(res);
      });

    this.fieldDataInitialization = this.weatherTimeFields$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      if (res && res.length > 0 && !this.table.timeFieldId && res[0].fieldId) {
        this.table.timeFieldId = res[0].fieldId;
      }
      this.initializeFieldDataValue();
    });

    if (this.fielddata._editMode) {
      this.weatherService
        .getTableTimeFieldId(this.fielddata._fieldProperty, this.fielddata.entityInstanceId)
        .pipe(
          catchError(err => {
            this.notification.error(err);
            return of();
          }),
        )
        .subscribe(timeFieldId => (this.table.timeFieldId = timeFieldId));
    }
    if (this.fielddata.entityInstanceId !== 0 && this.fielddata._editMode) {
      this.weatherService
        .getWeatherTableAssociatedDate(this.fielddata._fieldProperty, this.fielddata.entityInstanceId)
        .pipe(
          catchError(err => {
            this.notification.error(err);
            return of();
          }),
        )
        .subscribe(res => {
          this.updateDate(res.date);
        });
    }
  }

  addRow(associatedDate: string, contractId: number): Observable<WeatherFieldModel> {
    return this.weatherService.getContractWeatherDataByDateAndTime(associatedDate, contractId).pipe(
      tap(res => {
        const updatedData = this.data.concat({ ...res, fieldId: this.table.timeFieldId });
        this.setRows(updatedData);
      }),
    );
  }

  //we are using this method because the old fielddata object requires everytime to contain the exact rows we have in our table
  initializeFieldDataValue() {
    this.fielddata._value = this.data.map(x => {
      const obj = {};
      obj[this.table.timeFieldId] = x.dateTimeUtc;
      return obj;
    });
  }

  sortData(sort: Sort) {
    const data = this.data.slice();
    if (!sort.active || sort.direction === '') {
      return;
    }

    const sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'time':
          return this.compare(a.time.toLocaleString(), b.time.toLocaleString(), isAsc);
        case 'temperature':
          return this.compare(a.temperature, b.temperature, isAsc);
        case 'windSpeed':
          return this.compare(a.windSpeed, b.windSpeed, isAsc);
        case 'phrase':
          return this.compare(a.phrase, b.phrase, isAsc);
        case 'humidity':
          return this.compare(a.humidity, b.humidity, isAsc);
        default:
          return 0;
      }
    });

    this.setRows(sortedData);
  }

  changeDate(date: string): void {
    this.updateDate(date);

    if (!this.data.length) {
      return;
    }

    this.weatherService
      .getAllWeatherDataIndependent(
        this.data.map(x => `${date.split('T')[0]}T${x.dateTimeUtc.split('T')[1]}`),
        this.fielddata.contractId,
      )
      .pipe(
        tap(res => this.setRows(res)),
        tap(_ => this.cdRef.detectChanges()),
      )
      .subscribe();
  }

  isDateValid(date: string): boolean {
    const dt = new Date(date ?? 0);
    // check if date is valid and if bigger than 01.01.1970, trying to avoid null or default dates
    return dt.toString() !== 'Invalid Date' && dt > new Date(0);
  }

  private updateDate(date: string): void {
    if (!this.isDateValid(date)) {
      this.table.associatedDate = undefined;
    } else if (!this.isDateValid(this.table?.associatedDate) || !this.datesAreEqual(this.table.associatedDate, date)) {
      date = !date.endsWith('Z') ? date + 'Z' : date;
      this.table.associatedDate = moment.utc(date).startOf('day').toISOString();
    }
  }

  private datesAreEqual(date1: Date | string, date2: Date | string): boolean {
    const dt1 = (typeof date1 == 'string' ? date1 : date1.toISOString()).substring(0, 10);
    const dt2 = (typeof date2 == 'string' ? date2 : date2.toISOString()).substring(0, 10);
    return dt1 === dt2;
  }

  private compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  get data() {
    return this.weatherTimeFields$.getValue();
  }

  get associatedDate() {
    return this.table.associatedDate;
  }

  get timeFieldId() {
    return this.table.timeFieldId;
  }

  removeRow(value: WeatherFieldModel) {
    const updatedData = this.weatherTimeFields$.getValue().filter(x => x !== value);
    this.setRows(updatedData);
  }

  updateRow(time: string, element: WeatherFieldModel, onChange: () => void) {
    const data = this.weatherTimeFields$.getValue();
    let row = data.find(y => y === element);

    // strip off hours and minutes from date then add current time
    element.dateTimeUtc = moment
      .utc(element.dateTimeUtc)
      .utcOffset(this.timeZoneOffsetMinutes)
      .startOf('day')
      .add(time.split(':')[0], 'hours')
      .add(time.split(':')[1], 'minutes')
      .toISOString();

    this.weatherService
      .getContractWeatherDataByTime(
        this.fielddata._fieldProperty,
        this.fielddata.entityInstanceId,
        element.dateTimeUtc,
        element.fieldInstanceId,
        this.fielddata.contractId,
      )
      .subscribe(x => {
        if (x !== undefined && x !== null) {
          row.dateTimeUtc = x.dateTimeUtc;
          row.time = time;
          row.phrase = x.phrase;
          row.temperature = x.temperature;
          row.windSpeed = x.windSpeed;
          row.humidity = x.humidity;
          row.precipitation = x.precipitation;
          this.setRows(data);
          onChange();
        }
      });
  }

  destroy() {
    this.weatherTimeFields$.complete();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  connect(collectionViewer: CollectionViewer): Observable<Array<WeatherFieldModel>> {
    return this.weatherTimeFields$;
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.weatherTimeFields$.complete();
  }

  private setRows(rows: WeatherFieldModel[]) {
    rows.forEach(row => {
      row.dateTimeUtc = !row.dateTimeUtc.endsWith('Z') ? row.dateTimeUtc + 'Z' : row.dateTimeUtc;
      row.time = moment.utc(row.dateTimeUtc).utcOffset(this.timeZoneOffsetMinutes).format('HH:mm');
    });
    this.weatherTimeFields$.next(rows);
  }
}
