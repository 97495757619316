import { Component, computed, inject } from '@angular/core';

import { TenantStore, UserStore } from './signal-store';
import { BridgeService } from './ng2/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  //   this service includes methods that are used in the AngularJs service
  bridgeService = inject(BridgeService);

  userStore = inject(UserStore);
  tenantStore = inject(TenantStore);

  showHeader = computed(
    () => !this.userStore.isLoading() && !!this.tenantStore.selectedTenant() && !this.userStore.hideHeader(),
  );
}
