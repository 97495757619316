import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GridSearchEntityInstance } from '../../../models/module/grid';
import { DataListViewAction } from '../../../models/module/main/dataListViewAction';
import { CalendarService } from '../../../module/instance/calendar/calendar.service';
import { DataCardsViewService } from '../../../module/instance/data-card/data-cards-view/data-cards-view.service';
import { DemoCardsActions } from '../../data';
import { DemoCardsService } from '../cards-view/demo-cards.service';
import { DemoCalendarService } from './demo-calendar.service';

@Component({
  selector: 'app-demo-calendar-view',
  templateUrl: './demo-calendar-view.component.html',
  providers: [
    { provide: CalendarService, useClass: DemoCalendarService },
    { provide: DataCardsViewService, useClass: DemoCardsService },
  ],
})
export class DemoCalendarViewComponent {
  actions: DataListViewAction[] = [];

  constructor(private _snackBar: MatSnackBar) {
    this.actions = [...DemoCardsActions].map(d => ({ ...d, click: item => this.openSnackBar(d, item) }));
  }

  private openSnackBar(action: DataListViewAction, item: GridSearchEntityInstance) {
    this._snackBar.open(action.name + ' ' + item.full_doc_num, null, {
      duration: 5000,
    });
  }
}
