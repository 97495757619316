import { cipo } from 'cipo';
import { RestrictionType, RestrictionValueType } from 'src/app/models/module/fields/enums';
import { DisplayFormats } from 'src/app/shared/components/data-list/common';

cipo.factory("SOV", function (CommonEntityInstance, Form, WorkflowScreen, $q, Message, Dictionaries,
    URI, SOVCategory, userService, currencyService, $http, FileSaver, Blob, Manager, $mdDialog, PdfHandler, $location) {
    var SOV = CommonEntityInstance.extend(function (moduleId, entityInstanceId, obj = null) {
        var self = this;
        self.properties = { entityInstanceId: entityInstanceId || 0 };
        self.contractId = userService.system.userdata.contractId || 0;
        self.moduleId = moduleId;
        self.moduleCode = userService.getModuleIdentifierById(moduleId);
        self.entityInstanceId = entityInstanceId;
        self.userService = userService;
        self.loadedCategories = false;
        self.showSOV = true;
        self.dict = [];
        self.lookup = {};
        self.gridfields = [];
        self.coTotal = 0;
        self.wbs = [];
        self.loadedWbs = false;
        self.loadingWbs = true;
        self.toggleItems = obj.toggleItems || null;
        self.toggleSovItemView = obj.toggleSovItemView || null;
        self.importErrorsManager = undefined;
        self.importErrorsExists = false;
        self.moduleName = userService.system.modules[self.moduleCode]?.name || "SOV";
        self.otherDraftsColumns = [
            { id: 1, name: "userName", displayName: "User", sortable: true },
            { id: 2, displayFormatId: DisplayFormats.Status, name: "state_name", displayName: "Status", sortable: true },
            { id: 3, displayFormatId: DisplayFormats.Date, name: "createdOn", displayName: "Created On", sortable: true, restrictions: [{ key: RestrictionType.DateTime, value: RestrictionValueType.DateTime }] }
        ];
        self.currencyChange = obj.currencyChange || function (currency) {}

        currencyService.setCurrencyOnFormInit(self);

        self.pdfHandler = new PdfHandler({
            moduleId: self.moduleId,
            moduleCode: self.moduleCode,
            contractId: self.contractId,
            contractNo: userService.system.context.contract?.no,
        });

        // Get workflow for the module
        userService.getWorkflow(self.moduleId)
            .then(function () {
                self.get_general_info();
            })
            .catch(function () {
                self.get_general_info();
            });

        Object.defineProperty(self, 'grandTotal', {
            get: function () {
                var total = 0;
                if (self.dict.length)
                    for (var i = 0; i < self.dict.length; i++) {
                        total = total + self.dict[i].subTotal;
                    }
                return total;
            }
        });
        Object.defineProperty(self, 'hasImportErrors', {
            get: function () {
                return self.importErrorsManager && self.importErrorsManager.records !== 0;
            }
        });
    });


    SOV.prototype.setupApprovalForm = function () {
        var self = this;
        self.approvalContent = {
            comment: ""
        }
        self.approvalForm = new Form(self.approvalContent);
        // self.approvalForm.initializing = true;
        self.approvalForm.set_Description(
            { comment: { label: 'Comment', type: 'editor' } });
    }

    SOV.prototype.cancelApprove = function () {
        var self = this;
        var p = $q.defer();
        self.isBusy = true;
        var urlParams = {
            approvalId: self.generalInfo.approvalId,
            contractId: userService.system.userdata.contractId,
        };
        self[URI.MODULE_APPROVAL.CANCEL_APPROVAL.method](URI.MODULE_APPROVAL.CANCEL_APPROVAL, { url: urlParams, urltype: 'obj' })
            .then(function (result) {
                self.get_general_info();
                self.isModified = true;
                p.resolve();
            })
            .catch(function (e) {
                p.reject(e);
            })
            .finally(function () {
                self.isBusy = false;
            });

        return p.promise;
    }

    SOV.prototype.approve = function (isApprove) {
        var self = this;
        var p = $q.defer();
        self.isBusy = true;
        var urlParams = {
            // approvalId: self.data.approvalId,
            contractId: userService.system.userdata.contractId,
            moduleId: self.moduleId
        }
        var bodyParams = {
            id: self.generalInfo.approvalId,
            approved: isApprove ? true : false,
            comment: self.approvalContent.comment,
            // documentNumber: self.selectedDocNo ? self.selectedDocNo.key : null
        };
        self.approvalForm.loading = true;
        var dataURL = URI.SOV.SOV_DO_APPROVAL;
        self[dataURL.method](dataURL, { url: urlParams, urltype: 'obj', body: bodyParams })
            .then(function (result) {
                self.get_general_info();

                self.isModified = true;

                p.resolve();
            })
            .catch(function (e) {
                p.reject(e);
                Message.dberror(e);
                self.approvalForm.loading = false;
                // self.form.catch(e);
            })
            .finally(function () {
                self.isBusy = false;
            })

        return p.promise;
    }

    SOV.prototype.comment = function () {
        var self = this;
        var p = $q.defer();


        if (self.approvalContent.comment) {
            self.isBusy = true;
            var urlParams = {
                contractId: userService.system.userdata.contractId,
            }
            var bodyParams = {
                entityInstanceId: self.entityInstanceId,
                comment: self.approvalContent.comment
            };
            self.approvalForm.loading = true;
            self[URI.MODULE_APPROVAL.ADD_COMMENT.method](URI.MODULE_APPROVAL.ADD_COMMENT, { url: urlParams, urltype: 'obj', body: bodyParams })
                .then(function (result) {
                    self.get_activity_summary();
                    self.approvalContent.comment = "";
                    self.isModified = true;

                    p.resolve();
                })
                .catch(function (e) {
                    p.reject(e);
                    Message.dberror(e);

                })
                .finally(function () {
                    self.isBusy = false;
                    self.approvalForm.loading = false;
                })
        }
        else {
            Message.error("Please add comment");
            p.reject();
        }

        return p.promise;
    }


    SOV.prototype.goToSov = function() {
        var self = this;
        var tId = userService.getTenantIdentifierById(userService.tenantId);
        var cId = self.contractId;
        var eIId = self.entityInstanceId || 0;
        $location.url(`${tId}/sov/${cId}/${eIId}`);
    }

    SOV.prototype.get_general_info = function (ei) {
        var self = this;
        var p = $q.defer();
        self.isBusy = true;
        var dataURL = URI.SOV.GET_GENERAL_INFO;

        self.itemsLookup = {};
        self.isModulesLoaded = false;
        self.modulesLookup = {};
        self.modulesList = [];

        self.generalInfo = {};
        self.workflow = {
            transitions: [],
            transitionsInstancesList: [],
            pastAssignments: []
        };
        self.approvalsList = [];
        self.signInfo = {};
        self.loadedCategories = false;
        var params = {
            contractId: self.contractId,
            entityInstanceId: ei || self.entityInstanceId || 0,
        };
        self[dataURL.method](dataURL, { url: params, urltype: 'obj', body: {} })
            .then(function (r) {
                if (r) {
                    for (var key in r) {
                        if (r.hasOwnProperty(key))
                            self.generalInfo[key] = r[key];
                    }

                    var newEntityInstanceId = self.generalInfo.entityInstanceId || 0;
                    var loadedDifferentEntityInstanceId = self.entityInstanceId && newEntityInstanceId != self.entityInstanceId; 

                    self.entityInstanceId = newEntityInstanceId;

                    if (loadedDifferentEntityInstanceId) {
                        self.goToSov();
                        return;
                    }

                    self.get_RolesDict();

                    self.toggleSovItemView(r.hasWbs);

                    if (r.entityInstanceId) {
                        self.get_wbs();
                        self.get_categories();
                        self.get_gridfields();

                        if (r.hasManualDocumentAssignment || r.isClosedState) self.get_assignments();
                        if (r.isClosedState) self.get_signers();
                        if (!r.isDraftState) self.get_assignments_history();
                        self.get_activity_summary();
                        self.get_transitionList();
                    }
                    self.operations = userService.getOperationsFor("SOV");
                    self.hasManageDrafts = self.operations["Manage Drafts"];
                    self.isDraft = r.isDraftState;

                    if (self.generalInfo.otherDraftsUser && self.generalInfo.otherDraftsUser.length) {
                        self.get_other_drafts();
                    }

                    if (self.generalInfo.approvalId && self.generalInfo.canApprove) {
                        self.setupApprovalForm();
                    }

                }
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);

                p.reject(e)
            })
            .finally(function () {
                self.isBusy = false;
            })

        return p.promise;
    }

    SOV.prototype.get_assignments = function () {
        var self = this;
        var dataURL = URI.SOV.GET_ASSIGNMENT;
        var urlParams = {
            entityInstanceId: self.entityInstanceId || 0,
            contractId: self.contractId || 0
        };
        self.assignments = {
            lookup: {}
        };
        var p = $q.defer();
        self[dataURL.method](dataURL, { url: urlParams, urltype: 'obj' }, { headers: { 'moduleId': self.moduleId } })
            .then(function (r) {
                for (var key in (r || {})) {
                    if (r.hasOwnProperty(key))
                        self.assignments[key] = r[key];
                }
                for (var i = 0; i < (r.manualAssignUsers || []).length; i++) {
                    self.assignments.lookup[r.manualAssignUsers[i].key] = r.manualAssignUsers[i];
                }
                p.resolve();
            })
            .catch(function (e) {
                Message.dberror(e);
                console.error(e);
                p.reject(e);
            });

        return p.promise;

    }

    SOV.prototype.get_signers = function () {
        var self = this;
        var dataURL = URI.SOV.GET_SIGNERS;
        var urlParams = {
            entityInstanceId: self.entityInstanceId || 0,
            contractId: self.contractId || 0
        };
        self.signInfo = {
            lookup: {}
        };
        var p = $q.defer();
        self[dataURL.method](dataURL, { url: urlParams, urltype: 'obj' }, { headers: { 'moduleId': self.moduleId } })
            .then(function (r) {
                for (var key in (r || {})) {
                    if (r.hasOwnProperty(key))
                        self.signInfo[key] = r[key];
                }
                for (var i = 0; i < (r.signers || []).length; i++) {
                    self.signInfo.lookup[r.signers[i].key || r.signers[i].id] = r.signers[i];
                }
                p.resolve();
            })
            .catch(function (e) {
                Message.dberror(e);
                console.error(e);
                p.reject(e);
            });

        return p.promise;

    }

    SOV.prototype.get_assignments_history = function () {
        var self = this;
        var dataURL = URI.SOV.GET_ASSIGNMENT_HISTORY;
        self.workflow.pastAssignments = [];
        var urlParams = {
            entityInstanceId: self.entityInstanceId || 0,
            contractId: self.contractId || 0
        };

        var p = $q.defer();
        self[dataURL.method](dataURL, { url: urlParams, urltype: 'obj' }, { headers: { 'moduleId': self.moduleId } })
            .then(function (r) {
                for (var i = 0; i < (r || []).length; i++) {
                    if (r[i].validFrom)
                        r[i].validFrom = userService.formatDateTime(r[i].validFrom);
                    if (r[i].validThrough)
                        r[i].validThrough = userService.formatDateTime(r[i].validThrough);
                }

                self.workflow.pastAssignments = r;
                p.resolve();
            })
            .catch(function (e) {
                Message.dberror(e);
                console.error(e);
                p.reject(e);
            });

        return p.promise;

    }

    SOV.prototype.get_activity_summary = function () {
        var self = this;
        var dataURL = URI.SOV.GET_ACTIVITY_SUMMARY;
        var urlParams = {
            entityInstanceId: self.entityInstanceId || 0,
            contractId: self.contractId || 0
        };

        self.isActivityLoaded = false;

        var p = $q.defer();
        self[dataURL.method](dataURL, { url: urlParams, urltype: 'obj' }, { headers: { 'moduleId': self.moduleId } })
            .then(function (r) {
                for (var i = 0; i < (r.approvals || []).length; i++) {
                    r.approvals[i].approvedOn = userService.formatDateTime(r.approvals[i].approvedOn);
                }
                self.approvalsList = (r || {}).approvals || [];

                p.resolve();
            })
            .catch(function (e) {
                Message.dberror(e);
                console.error(e);
                p.reject(e);
            })
            .finally(function () {
                self.isActivityLoaded = true;
            });

        return p.promise;

    }

    SOV.prototype.get_transitionList = function () {
        var self = this;
        var dataURL = URI.SOV.GET_TRANSITIONS;
        var urlParams = {
            workflowId: userService.system.workflowId,
            entityInstanceId: self.entityInstanceId || 0,
            // paymentPeriodId: self.selectedPeriodId || 0,
            contractId: self.contractId || 0
        };
        self.workflow.transitions = [];
        self.loadingTransitions = true;
        var p = $q.defer();
        self[dataURL.method](dataURL, { url: urlParams, urltype: 'obj' }, { headers: { 'moduleId': self.moduleId } })
            .then(function (r) {
                self.workflow.transitions = r;
                p.resolve();
            })
            .catch(function (e) {
                Message.dberror(e);
                console.error(e);
                p.reject(e);
            })
            .finally(function () {
                self.loadingTransitions = false;
            })

        return p.promise;

    }

    SOV.prototype.get_past_transition_info = function (actionInstance) {
        var self = this;
        var p = $q.defer();

        var urlParams = {
            actionInstanceId: actionInstance.key,
            contractId: userService.system.userdata.contractId,
            noFields: actionInstance.isDraft || false
        };
        var dataURL = URI.SOV.GET_TRANSITION_INSTANCE;
        self[dataURL.method](dataURL, { url: urlParams, urltype: 'obj' }, { headers: { 'moduleId': self.moduleId } })
            .then(function (r) {
                for (var key in (r || {})) {
                    if (r.hasOwnProperty(key))
                        actionInstance[key] = r[key];
                }
                self.set_transition_info(actionInstance);

                p.resolve();
            })
            .catch(function (e) {
                Message.dberror(e);
                console.error(e);
                p.reject(e);
            })
            .finally(function () {

                actionInstance.loading = false;

            });


        return p.promise;
    }

    SOV.prototype.set_transition_info = function (actionInstance) {
        var self = this;
        if (actionInstance.fields) {
            actionInstance.screen = new WorkflowScreen({
                moduleId: self.moduleId,
                entityInstanceId: self.entityInstanceId,
                onFormInit: function (data) {
                    currencyService.setCurrencyOnFormInit(self, data);
                },
            });
            actionInstance.screen.data = { fields: actionInstance.fields };
            actionInstance.screen.setupForm(false);

            if ((actionInstance.preferredFields || []).length && actionInstance.screen.form.fieldsList) {
                for (var i = 0; i < actionInstance.preferredFields.length; i++) {
                    (actionInstance.screen.form.fieldsList[actionInstance.preferredFields[i].key] || {}).isHighlighted = true;
                }
            }
        }
        if (actionInstance.type == 1 && !actionInstance.stateTypeId) {
            actionInstance.msg = actionInstance.contactEmail
                ? 'The ' + self.moduleCode + ' was generated from e-mail sent by ' + actionInstance.contactEmail
                : 'The ' + self.moduleCode + ' draft has entered the workflow';
        }
    }

    SOV.prototype.assign_User = function (u) {
        var self = this;
        var p = $q.defer();
        self.isAssigningUser = true;
        self[URI.STATE_ASSIGNMENT.ASSIGN_USER.method](URI.STATE_ASSIGNMENT.ASSIGN_USER, { url: { entityInstanceId: self.entityInstanceId, contractId: self.contractId || 0, moduleId: self.moduleId }, urltype: 'obj', body: u })
            .then(function (result) {
                //self.dataOriginalObject = result;
                p.resolve(result);
                self.assignments.assignee = self.assignments.lookup[u.key];
                Message.info('User assignment changed');
                if (result && result.length) {
                    for (var i = 0; i < result.length; i++) {

                        if (result[i].validFrom)
                            result[i].validFrom = userService.formatDateTime(result[i].validFrom);
                        if (result[i].validThrough)
                            result[i].validThrough = userService.formatDateTime(result[i].validThrough);
                    }
                }
                self.workflow.pastAssignments = result;

                self.get_activity_summary();
            })
            .catch(function (e) {
                p.reject(e);
            })
            .finally(function () { self.isAssigningUser = false; });

        return p.promise;
    }

    SOV.prototype.get_fields = function (transitionId, isAction) {
        var self = this;
        var p = $q.defer();
        var params = {
            entityInstanceId: self.entityInstanceId,
            // transitionId: transitionId,
            contractId: self.contractId,
            workflowId: userService.system.workflowId
        };

        if (isAction) params.actionId = transitionId;
        else params.transitionId = transitionId;

        var dataURL = URI.SOV.GET_FIELDS;
        self[dataURL.method](dataURL, { url: params, urltype: 'obj' })
            .then(function (r) {
                p.resolve(r);
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject();
            })
        return p.promise;
    }


    SOV.prototype.get_RolesDict = function () {
        var self = this;
        var p = $q.defer();
        self.rolesDict = [];
        var params = {
            moduleId: self.moduleId,
            contractId: self.contractId
        };
        self.rolesDictLookup = {};
        var dataURL = URI.MODULE.MODULE_ROLES_DICT;
        self[dataURL.method](dataURL, { url: params, urltype: 'obj' })
            .then(function (r) {
                self.rolesDict = r;
                for (var i = 0; i < r.length; i++) {
                    self.rolesDictLookup[r[i].key] = r[i];
                }
                p.resolve();
            })
            .catch(function (e) {
                Message.dberror(e);
                console.error(e);
                p.reject();
            })
        return p.promise;
    }

    SOV.prototype.excludeRolesFromTransition = function (t) {
        var self = this;
        t.exclusionRoles = angular.copy(self.rolesDict);
        t.showExcludeRoles = true;
        if ((t.roleToExcludeIds || []).length) {
            for (var i = 0; i < t.exclusionRoles.length; i++) {
                if (t.roleToExcludeIds.indexOf(t.exclusionRoles[i].key) != -1)
                    t.exclusionRoles[i].isUsed = true;
            }
        }
    }

    SOV.prototype.cancelExcludeRoles = function (t) {
        var self = this;
        t.showExcludeRoles = false;
    }

    SOV.prototype.syncExcludeRoles = function (t) {
        var self = this;
        var p = $q.defer();
        var dataURL = URI.MODULE.SYNC_EXCLUDED_ROLES;
        var roleToExcludeIds = [];
        for (var i = 0; i < t.exclusionRoles.length; i++) {
            if (t.exclusionRoles[i].isUsed)
                roleToExcludeIds.push(t.exclusionRoles[i].key);
        }
        var params = {
            url: {
                moduleId: self.moduleId,
                actionInstanceId: t.actionInstanceId,
                contractId: self.contractId
            },
            body: roleToExcludeIds,
            urltype: 'obj'
        }
        self[dataURL.method](dataURL, params)
            .then(function (result) {
                t.roleToExcludeIds = roleToExcludeIds;
                t.showExcludeRoles = false;
                p.resolve();
            })
            .catch(function (e) {
                Message.dberror(e);
                p.reject(e);
            });
        return p.promise;
    }

    SOV.prototype.sign_Doc = function () {
        var self = this;
        var p = $q.defer();
        var dataURL = URI.MODULE_SIGNATURE.GET;
        var signId;
        self.signatureOptions = {
            sign: false
        };
        for (var i = 0; i < self.signInfo.signers.length; i++) {
            if (self.signInfo.signers[i].id == userService.system.userdata.id && !self.signInfo.signers[i].signed) {
                signId = self.signInfo.signers[i].signId;
                break;
            }
        }
        self[dataURL.method](dataURL, { id: signId })
            .then(function (result) {
                self.signatureOptions = {
                    sign: true,
                    signatureId: result.signId,
                    clientId: result.clientId,
                    url: result.signatureUrl,
                    // redirect: true,
                    isProd: result.isProd,
                    setSigned: function (sid) {
                        self.put(URI.MODULE_SIGNATURE.SET_SIGNED, { url: { id: sid }, urltype: 'obj' })
                            .then(function () { self.get_signers(); })
                            .catch(function (e) { Message.dberror(e); });
                    },
                    callback: function () {

                        return;
                    }
                };
            })
            .catch(function (e) {
                Message.dberror(e);
            });
    }

    SOV.prototype.exportsov = function (isBlank) {
        var self = this;
        self.isBusy = true;
        var p = $q.defer();
        var dataURL = URI.SOV.SOV_EXPORT;
        var urlParam = '?entityInstanceId=' + (isBlank ? 0 : self.entityInstanceId) + '&contractId=' + self.contractId;
        $http[dataURL.method](dataURL.toString() + urlParam, { responseType: "arraybuffer" })
            .then(function (r) {
                var type = r.headers('Content-Type');
                var disposition = r.headers('Content-Disposition');
                var defaultFileName = "SOV Items.xlsx";
                if (disposition) {
                    var match = disposition.match(/.*filename=\"?([^;\"]+)\"?.*/);
                    if (match[1])
                        defaultFileName = match[1];
                }
                defaultFileName = defaultFileName.replace(/[<>:"\/\\|?*]+/g, '_');
                var blob = new Blob([r.data], { type: type });
                FileSaver.saveAs(blob, defaultFileName);
                p.resolve(defaultFileName);
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e);
            })
            .finally(function () {
                self.isBusy = false;
            });
        return p.promise;
    }

    SOV.prototype.get_categories = function () {
        var self = this;
        self.dict = [];
        self.isBusy = true;
        // self.loadingCategories = true;
        Dictionaries.SOVCategories({ moduleId: -4 }, { sovEntityInstanceId: self.entityInstanceId })
            .then(function (r) {
                var temp;
                if (r && r.length)
                    for (var i = 0; i < r.length; i++) {
                        temp = new SOVCategory(r[i]);
                        temp.contractId = self.contractId;
                        self.dict.push(temp);
                        self.lookup[temp.key] = temp;
                    }
            })
            .catch(function (e) { Message.dberror(e); })
            .finally(function () { self.loadedCategories = true; self.isBusy = false; })
    }

    SOV.prototype.reload_categories = function (isReloadManagers) {
        var self = this;
        Dictionaries.SOVCategories({ moduleId: -4 }, { sovEntityInstanceId: self.entityInstanceId })
            .then(function (r) {
                if (r && r.length)
                    for (var i = 0; i < r.length; i++) {
                        self.lookup[r[i].key].itemsCount = r[i].itemsCount;
                        self.lookup[r[i].key].subTotal = r[i].subTotal;
                        self.lookup[r[i].key].isUsed = r[i].isUsed;

                        if (isReloadManagers) if (self.lookup[r[i].key].manager) self.lookup[r[i].key].manager.loadPage();
                    }

            })
            .catch(function (e) { Message.dberror(e); console.error(e); })
            .finally(function () { self.loadingCategories = false; })
    }

    SOV.prototype.processWBSData = function () {
        var self = this;
        var p = $q.defer()

        self[URI.WBS.PROCESS_DATA.method](`${URI.WBS.PROCESS_DATA}?contractId=${self.contractId}&sovEntityInstanceId=${self.entityInstanceId}`)
            .then(function (wbs) {
                p.resolve();
            })
            .catch(function (err) {
                p.reject();
            });

        return p.promise;
    }

    SOV.prototype.get_wbs = function () {
        var self = this;
        self.wbs = [];
        self.isBusy = true;
        self.loadedWbs = false;
        self.loadingWbs = true;
        self.wbsItemCount = 0;
        self.wbsItemTotal = 0;

        self[URI.WBS.GET_WITH_CATEGORIES.method](`${URI.WBS.GET_WITH_CATEGORIES}?contractId=${self.contractId}&sovEntityInstanceId=${self.entityInstanceId}`)
            .then(function (wbs) {
                if (wbs.length) {
                    self.wbs = wbs;
                    self.wbsItemCount = self.wbs.map(x => x.sovItemCount).length;
                    self.wbsItemTotal = self.wbs.map(x => x.sovItemTotal);
                    self.toggleSovItemView(true);
                }

            })
            .catch(function (err) {
                Message.dberror(err);
            })
            .finally(function () {
                self.loadedWbs = true;
                self.loadingWbs = false;
                self.isBusy = false;
            });
    }

    SOV.prototype.reload_wbs = function (isReloadManagers) {
        var self = this;
        var uri = URI.WBS.GET_WITH_CATEGORIES;
        var expandedNodeIds = self.get_expanded_nodes(self.wbs);
        var expandedCategoryIds = self.get_expanded_categories(self.wbs);
        self.loadedWbs = false;
        self.isBusy = true;

        self[uri.method](`${uri}?contractId=${self.contractId}&sovEntityInstanceId=${self.entityInstanceId}`)
            .then(function (wbs) {
                self.wbs = wbs;
                self.set_expanded_nodes(self.wbs, expandedNodeIds, expandedCategoryIds);
            })
            .catch(function (err) {
                Message.dberror(err);
            })
            .finally(function () {
                self.loadedWbs = true;
                self.isBusy = false;
            });
    }

    SOV.prototype.get_expanded_nodes = function (nodes) {
        var self = this;
        var expandedNodeIds = [];
        nodes.forEach(node => {
            if (node.isExpanded) {
                expandedNodeIds.push(node.id);
                if (node.children) {
                    expandedNodeIds = expandedNodeIds.concat(self.get_expanded_nodes(node.children));
                }
            }
        });

        return expandedNodeIds;
    }

    SOV.prototype.get_expanded_categories = function (nodes) {
        var self = this;
        var expandedCategoryIds = [];
        nodes.forEach(node => {
            if (node.isExpanded) {
                // Check current node's categories
                if (node.categories) {
                    var expandedCategories = node.categories.filter(c => c.isExpanded);
                    expandedCategoryIds = expandedCategoryIds.concat(expandedCategories.map(x => x.categoryId));
                }

                // Loop through nested child nodes
                if (node.children) {
                    expandedCategoryIds = expandedCategoryIds.concat(self.get_expanded_categories(node.children));
                }
            }
        });

        return expandedCategoryIds;
    }

    SOV.prototype.set_expanded_nodes = function (nodes, expandedNodeIds, expandedCategoryIds) {
        var self = this;
        nodes.forEach(node => {
            node.isExpanded = expandedNodeIds.includes(node.id);
            if (node.isExpanded) {
                // Expand necessary categories
                if (node.categories) {
                    node.categories.forEach(category => {
                        if (expandedCategoryIds.includes(category.categoryId)) {
                            // This function toggles isExpanded
                            self.toggleItems(category, category.id);
                        }
                    });
                }

                // Expand child nodes/categories
                if (node.children) {
                    self.set_expanded_nodes(node.children, expandedNodeIds, expandedCategoryIds);
                }
            }
        });
    }

    SOV.prototype.get_transitions = function () {
        var self = this;
        var p = $q.defer();
        var dataURL = URI.SOV.SOV_TRANSITIONS;
        var params = { contractId: self.contractId, entityInstanceId: self.entityInstanceId };
        self.loadingTransitions = true;
        self.pastTransitions = [];
        self[dataURL.method](dataURL, { url: params, urltype: 'obj' })
            .then(function (r) {
                self.transitions = r.transitions;
                // self.revisions = r.revisions;
                if (r && r.pastTransitions.length) {
                    var temp;
                    for (var i = 0; i < r.pastTransitions.length; i++) {
                        temp = r.pastTransitions[i];
                        temp.screen = new WorkflowScreen();
                        temp.screen.data = { fields: temp.fields };
                        temp.screen.setupForm(false);
                        self.pastTransitions.push(temp);
                    }
                }
                // self.pastTransitions = r.pastTransitions;
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e)
            })
            .finally(function () {
                self.loadingTransitions = false;
            })

        return p.promise;
    }

    SOV.prototype.get_Data = function (transitionId) {
        var self = this;
        var p = $q.defer();
        var dataURL = URI.SOV.SOV_GET;
        var params = { entityInstanceId: self.entityInstanceId, transitionId: transitionId || 0, contractId: self.contractId };
        self.loading = transitionId ? false : true;
        self[dataURL.method](dataURL, { url: params, urltype: 'obj' })
            .then(function (r) {
                if (r && !transitionId) {
                    for (var key in r) {
                        if (r.hasOwnProperty(key)) {
                            self.properties[key] = r[key];
                        }
                    }
                }
                if (!transitionId) if (r.entityInstanceId) self.get_transitions();
                self.userIsInitiator = r.userIsInitiator;
                if (r.entityInstanceId && !transitionId) {
                    self.get_wbs();
                    self.get_categories();
                    self.get_gridfields();
                    // self.get_co();
                }

                p.resolve(r);
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e)
            })
            .finally(function () {
                self.loading = false;
            })

        return p.promise;
    }
    SOV.prototype.get_co = function () {
        var self = this;
        var all = $q.all([self.get_co_fields(), self.get_co_items()]);
        self.loadingco = true;
        all
            .then(function () {

            })
            .catch(function (e) { Message.dberror(e); })
            .finally(function () { self.loadingco = false; })

    }

    SOV.prototype.get_co_fields = function () {
        var self = this;
        var dataURL = URI.SOV.ACO_FIELDS;
        var p = $q.defer();
        var params = { contractId: self.contractId };
        self.coColumns = [
            { name: 'full_doc_num', label: 'Document No' },
            { name: 'subject', label: 'Subject' },
            { name: 'duration_days', label: 'Duration Days', rightAligned: true },
            { name: '_cost', label: 'Cost', rightAligned: true },
            { name: 'updated_on', label: 'Approved on' }
        ];
        self.dcoColumns = [
            { name: 'full_doc_num', label: 'Document No' },
            { name: 'subject', label: 'Subject' },
            { name: 'duration_days', label: 'Duration Days', rightAligned: true },
            { name: '_cost', label: 'Cost', rightAligned: true },
            { name: 'updated_on', label: 'Approved on' }
        ];

        self[dataURL.method](dataURL, { url: params, urltype: 'obj' })
            .then(function (r) {
                p.resolve();
            })
            .catch(function (e) {
                p.reject();
                Message.dberror(e);
            })
        return p.promise;
    }
    SOV.prototype.get_co_items = function () {
        var self = this;
        var dataURL = URI.SOV.ACO_SEARCH;
        var p = $q.defer();

        var params = { contractId: self.contractId };

        var formatMoney = function (amount) {
            return pipe.transform(amount || '0', 2, true, true, false, $scope.currency);
        }
        self[dataURL.method](dataURL, { url: params, urltype: 'obj' })
            .then(function (r) {
                self.coTotal = 0;
                self.coItems = [];
                self.dcoItems = [];
                for (var i = 0; i < r.data.length; i++) {
                    self.coTotal = self.coTotal + r.data[i].cost;
                    r.data[i]._cost = formatMoney(r.data[i].cost);
                    if (r.data[i].cost < 0) r.data[i].isDanger = true;
                    self.coItems.push(r.data[i]);
                }
                // self.coItems = r.data;
                p.resolve();
            })
            .catch(function (e) {
                p.reject();
                Message.dberror(e);
            })
        return p.promise;
    }

    SOV.prototype.get_gridfields = function () {
        var self = this;
        var p = $q.defer();
        var dataURL = URI.SOV.SOVI_FIELDS;
        var params = { contractId: self.contractId, workflowId: userService.system.workflowId };
        //self.loading = true;
        self[dataURL.method](dataURL, { url: params, urltype: 'obj' })
            .then(function (r) {
                self.gridfields = r.fields;
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e)
            })
            .finally(function () {
                //self.loading = false;
            })

        return p.promise;
    }

    SOV.prototype.create = function () {
        var self = this;
        var p = $q.defer();
        var dataURL = URI.SOV.SOV_CREATE;
        var params = { contractId: self.contractId, workflowId: userService.system.workflowId };
        self.loading = true;
        self[dataURL.method](dataURL, { url: params, urltype: 'obj' })
            .then(function (r) {
                if (r) {
                    self.entityInstanceId = r;
                }
                self.get_general_info();
                Message.info(`${self.moduleName} created successfully.`)
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e)
            })
            .finally(function () {
                self.loading = false;
            })

        return p.promise;
    }

    SOV.prototype.save = function (transitionId, roleId, isRevise) {
        var self = this;
        var p = $q.defer();
        var dataURL = URI.SOV.SOV_SAVE;
        if (isRevise) dataURL = URI.SOV.SOV_REVISE;
        var params = { contractId: self.contractId, transitionId: transitionId, entityInstanceId: self.entityInstanceId, roleId: roleId, workflowId: userService.system.workflowId };
        self.loading = true;
        self[dataURL.method](dataURL, { url: params, urltype: 'obj', body: {} })
            .then(function (r) {

                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e);
                self.isBusy = false;
            })
            .finally(function () {
                self.loading = false;

            })

        return p.promise;
    }

    SOV.prototype.getOtherDraftUsers = function () {
        var self = this;

        if (self.generalInfo.otherDraftsUser && self.generalInfo.otherDraftsUser.length == 1)
            return self.generalInfo.otherDraftsUser[0];

        return self.generalInfo.otherDraftsUser.join(', ');
    }

    SOV.prototype.viewOtherDrafts = function () {
        var self = this;

        if (!self.hasManageDrafts || !self.generalInfo.isDraftState)
            return;
        else {
            self.showSOV = !self.showSOV;
        }
    }

    SOV.prototype.get_other_drafts = function () {
        var self = this;
        var p = $q.defer();
        var dataURL = URI.SOV.OTHER_DRAFTS;
        var params = { contractId: self.contractId };
        self[dataURL.method](dataURL, { url: params, urltype: 'obj', body: {} })
            .then(function (r) {
                self.otherDrafts = r.map(element => {
                    const row = {
                        id: element.entityInstanceId,
                    };
                    self.otherDraftsColumns.forEach(column => {
                        if (column.name === 'state_name') {
                            row.state_name = {
                                bgColor: element.state_color,
                                color: element.state_text_color,
                                label: element.state_name
                            };
                        } else {
                            row[column.name] = element[column.name] ?? element[column.id];
                        }
                        });
                        return row;
                    });
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                p.reject(e);
            });

        return p.promise;
    }

    SOV.prototype.iHaveADraft = function() {
        var self = this;
        return self.otherDrafts.some(e => e.iAmInitiator);
    }

    SOV.prototype.openSOV = function (row) {
        var self = this;
        self.showSOV = true;
        self.get_general_info(row.id);
    }

    SOV.prototype.openOtherDrafts = function() {
        var self = this;
        self.showSOV = !self.showSOV;
    }

    SOV.prototype.delete = function () {
        var self = this;
        var p = $q.defer();
        var dataURL = URI.SOV.SOV_DELETE;
        var params = { contractId: self.contractId, entityInstanceId: self.entityInstanceId };
        self.loading = true;
        self[dataURL.method](dataURL, { url: params, urltype: 'obj' })
            .then(function (r) {
                Message.info(`${self.moduleName} deleted successfully.`)
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e)
            })
            .finally(function () {
                self.loading = false;
            })

        return p.promise;
    }

    SOV.prototype.get_itemDetails = function (i) {
        var self = this;
        var p = $q.defer();
        var dataURL = URI.SOV.SOVI_GET;
        var params = { contractId: self.contractId, entityInstanceId: i ? i.entity_instance_id : 0 };
        self[dataURL.method](dataURL, { url: params, urltype: 'obj' })
            .then(function (r) {
                p.resolve(r);
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e)
            })
            .finally(function () {
                self.loading = false;
            })

        return p.promise;
    }

    SOV.prototype.printDocument = function () {
        var self = this;
        var row = {
            entity_instance_id: self.entityInstanceId,
            closed_on: self.generalInfo.isClosedState ? 'yes' : null,
            screen_id: self.generalInfo.screenId,
        };
        self.pdfHandler.printDocuments(row);
    }

    SOV.prototype.downloadPdfs = function () {
        var self = this;
        var contractNo = userService.system.context.contract?.no;
        var name = "Schedule of Values" + '-' + contractNo;

        var row = {
            entity_instance_id: self.entityInstanceId,
            closed_on: self.generalInfo.isClosedState ? 'yes' : null,
            screen_id: self.generalInfo.screenId,
            fileName: name,
        };
        self.pdfHandler.download(row);
    }

    SOV.prototype.emailPdfs = function (scope) {
        var self = this;
        var contractNo = userService.system.context.contract?.no;
        var name = "Schedule of Values" + '-' + contractNo;

        var emailSendWatch = scope.$watch(function () { return self.pdfHandler.isEmailSend; }, function (newParam, oldParam) {
            if (newParam) {
                self.pdfHandler.isEmailSend = false;
                emailSendWatch();
                self.get_activity_summary();
            }
        });

        var row = {
            entity_instance_id: self.entityInstanceId,
            closed_on: self.generalInfo.isClosedState ? 'yes' : null,
            screen_id: self.generalInfo.screenId,
            fileName: name,
        };
        self.pdfHandler.email(scope, row);
    }

    // unused?!
    SOV.prototype.pp_get = function () {
        var self = this;
        var p = $q.defer();
        var dataURL = URI.SOV.PP_GET;
        var params = { contractId: self.contractId, transitionId: 0, entityInstanceId: 0, paymentPeriodId: 0 };
        self[dataURL.method](dataURL, { url: params, urltype: 'obj' })
            .then(function (r) {
                p.resolve(r);
                // self.pp_create(r.id);
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e)
            })
            .finally(function () {
                self.loading = false;
            })

        return p.promise;
    }

    SOV.prototype.pp_create = function (id) {
        var self = this;
        var p = $q.defer();
        var dataURL = URI.SOV.PP_CREATE;
        var params = { contractId: self.contractId, paymentPeriodId: id, workflowId: userService.system.workflowId };
        self[dataURL.method](dataURL, { url: params, urltype: 'obj' })
            .then(function (r) {
                p.resolve(r);
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e)
            })
            .finally(function () {
                self.loading = false;
            })

        return p.promise;
    }

    SOV.prototype.undoLastAction = function (t) {
        var self = this;
        CommonEntityInstance.commonUndoLastAction(t, 'SOV', self.contractId, self.moduleId).then(function () {
            // console.log('undo done');
            self.get_general_info();
        })
    }

    // import

    SOV.prototype.openImportEntitiesDialog = function () {
        var self = this;

        $mdDialog.show({
            locals: {
                moduleId: -5,
                moduleCode: 'SOVI',
                importOptions: {
                    fieldsForImportDatasource: 'fieldsForImportSOV',
                    moduleIdForStates: -4,
                    importUrlData: URI.SOV.IMPORT_DOCS,
                    entityInstanceId: self.entityInstanceId,
                    closeOnSuccess: true,
                    closeOnError: true,
                }
            },
            controller: 'importEntitiesController',
            templateUrl: '/ng/controllers/files/importEntities.html',
            parent: angular.element(document.body),
            fullscreen: true,
            focusOnOpen: false,
            multiple: false,
            escapeToClose: false,
            clickOutsideToClose: false
        })
            .then(function (output) {
                self.handleInportOutput(output);
            }, function (output) {
                self.handleInportOutput(output);
            });
    }

    SOV.prototype.handleInportOutput = function (output) {
        var self = this;

        self.resetImportErrors();

        if (output.importError) {
            self.getImportErrors().then(function () {
                self.get_general_info();
            });
        }
        else {
            self.get_general_info();
        }
    }

    SOV.prototype.resetImportErrors = function () {
        this.importErrorsManager = undefined;
        this.importErrorsExists = false;
    }

    SOV.prototype.getImportErrors = function () {
        var self = this;
        var p = $q.defer();
        self.loadImportErrors().then(function (errors) {
            self.importErrorsExists = false;
            if (errors && errors.records) {
                self.showImportErrors(errors.data);
                self.importErrorsExists = true;
            }
            p.resolve();
        });
        return p.promise;
    }

    SOV.prototype.loadImportErrors = function () {
        var self = this;
        var p = $q.defer();
        self.isBusy = true;
        self[URI.SOV.SOV_ERRORS.method](URI.SOV.SOV_ERRORS, { url: { entityInstanceId: self.entityInstanceId }, urltype: 'obj' })
            .then(function (result) {
                p.resolve(result);
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e);
            })
            .finally(function () {
                self.isBusy = false;
            });
        return p.promise;
    }

    SOV.prototype.showImportErrors = function (errors) {
        var self = this;

        self.importErrorsManager = new Manager({
            rows: errors,
            objectsPerPage: 0,
            search: false,
            hideFilters: true,
            options: {
                multiselect: false
            },
            menuClass: "inlineManager",
            rowOnClick: function () { },
            dataWrapper: 'data',
            parseData: function (data, columns) {
                return (data || []).map(d => ({
                    row: d.row,
                    error: d.values || d.error
                }));
            },
            leftActions: [],
            actions: [],
            hasActionBar: false,
        });
        self.importErrorsManager.set_Columns([
            {
                name: 'row',
                label: 'Row',
                type: 'text',
                typeId: 1,
                fieldTypeId: 1,
                onTablet: true,
                onPhone: false,
                width: 10
            },
            {
                name: 'error',
                label: 'Error',
                type: 'text',
                typeId: 1,
                fieldTypeId: 1,
                onTablet: true,
                onPhone: true,
                width: 90
            },
        ]);
        self.importErrorsManager.loading = false;
    }

    return SOV;
});
