<div class="p-a-xl">
    <div fxLayout fxLayoutGap="12" fxLayoutAlign="start center" class="m-t m-b-xl">
        <button mat-raised-button color="primary" (click)="logItems()">Log items</button>
        <mat-button-toggle-group [(value)]="editMode">
            <mat-button-toggle [value]="true">Edit</mat-button-toggle>
            <mat-button-toggle [value]="false">View</mat-button-toggle>
        </mat-button-toggle-group>
        <button mat-flat-button color="accent" (click)="openBuilder()">Open Form builder</button>
    </div>
    <cipo-grid-form [form]="form" [editMode]="editMode()" />
</div>
