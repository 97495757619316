import { Component } from '@angular/core';
import { DemoTenantSettingsService } from './demo-tenant-settings.service';
import { TenantSettingsService } from '../../../module/instance/tenant-settings/tenant-settings.service';
import { TenantSettingsModule } from '../../../module/instance/tenant-settings/tenant-settings.module';

@Component({
  selector: 'app-demo-tenant-settings',
  template: `<div class="p-a-xl"><app-tenant-settings /></div>`,
  standalone: true,
  imports: [TenantSettingsModule],
  providers: [{ provide: TenantSettingsService, useClass: DemoTenantSettingsService }],
})
export class DemoTenantSettingsComponent {}
