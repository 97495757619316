var jquery = require("jquery");
window.jQuery = window.$ = jquery;

window.tinycolor = require("tinycolor");

window.materialKit = {};
window.materialKitDemo = {};

require("jquery-ui");
require("angular");
require("bootstrap");
require("moment");
require("@azure/msal-angularjs");
require("ng-cookies");
require("ng-sanitize");
require("angular-animate");
require("ng-flow");
require("ng-aria");
require("ng-tree");
require("ng-file-upload");
require("ng-storage");
require("ng-messages");
require("datepicker");
require("nouislider");
require("material");
require("material-kit");
require("floating-labels");
require("ng-material");
require("ngFileSaver");
require("mdColorPicker");
require("ui-select");
require("ui-sortable");
require("ui-bootstrap");
require("ui-tree");
require("rzTable");
require("dialogs");
require("toastr");

require("./services/authInterceptor");
require("./services/authService");
require("./services/angular-js.service");
require("./services/dictionariesService");
require("./services/fileService");
require("./services/currencyService");
require("./services/moduleService");
require("./services/userService");
require("./services/rememberManagerService");
require("./services/validationService");
require("./services/printService");
require("./services/officeService");

require("./models/admin/adjustment");
require("./models/admin/adjustmentRelease");
require("./models/admin/assignURC");
require("./models/admin/compProfile");
require("./models/admin/contractH");
require("./models/admin/dashboardModule");
require("./models/admin/externalContact");
require("./models/admin/organization");
require("./models/admin/paymentPeriod");
require("./models/admin/report");
require("./models/admin/role");
require("./models/admin/user");
require("./models/admin/userProfile");
require("./models/admin/userRoleContract");

require("./models/core/conversation");
require("./models/core/email");
require("./models/core/emails");
require("./models/core/headerOrFooter");

require("./models/dynamics/commonEntityInstance");
require("./models/dynamics/workflowEntityInstance");
require("./models/dynamics/workflowScreen");

require("./models/files/file");
require("./models/files/fileManagerConfig");
require("./models/files/folder");
require("./models/files/folderName");

require("./models/lists/list");
require("./models/lists/listElements");
require("./models/lists/list_management");
require("./models/lists/list_relation");

require("./models/maps/maps");

require("./models/modules/config/dataListConfig");
require("./models/modules/config/fieldConfig");
require("./models/modules/config/formConfig");
require("./models/modules/config/tableFieldConfig");
require("./models/modules/actionMod");
require("./models/modules/fieldMod");
require("./models/modules/formulasMod");
require("./models/modules/gridfields");
require("./models/modules/listMod");
require("./models/modules/moduleDicts");
require("./models/modules/moduleN");
require("./models/modules/paymentRelationMod");
require("./models/modules/permissionMod");
require("./models/modules/presetMod");
require("./models/modules/relationMod");
require("./models/modules/screen");
require("./models/modules/signatureMod");
require("./models/modules/stateMod");
require("./models/modules/statesMapping");
require("./models/modules/tableMod");
require("./models/modules/templateMod");
require("./models/modules/transitionGroupMod");
require("./models/modules/transitionMod");
require("./models/modules/url");
require("./models/modules/reportingModule");
require("./models/modules/workflowMod");
require("./models/modules/workflowGroupMod");
require("./models/modules/workflowAddMod");

require("./models/payment/adjustmentItem");
require("./models/payment/paymentItem");
require("./models/payment/progresspaymentSA");

require("./models/schedules/financial");
require("./models/schedules/schedule");
require("./models/schedules/shift");
require("./models/schedules/workday");

require("./models/sov/ppitem");
require("./models/sov/sov");
require("./models/sov/sovcategory");
require("./models/sov/sovitem");

require("./models/system/pspdfkit/pspdfkitAnnotationInspector");
require("./models/system/pspdfkit/pspdfkitAssociations");
require("./models/system/pspdfkit/pspdfkitInstance");

require("./models/system/attachmentHandler");
require("./models/system/entityfieldslayout");
require("./models/system/explorer1.2");
require("./models/system/form");
require("./models/system/functions");
require("./models/system/ganttModel");
require("./models/system/genericClass");
require("./models/system/GlobalGraphModel");
require("./models/system/graphModel");
require("./models/system/manager");
require("./models/system/message");
require("./models/system/model");
require("./models/system/pdfHandler");
require("./models/system/propField");
require("./models/system/rename");

require("./directives/itvSelect");
require("./directives/list");
require("./directives/setlayout");
require("./directives/tablists");
require("./directives/userRoleContractGrid");

require("./directives/admin/listrelationdetail");

require("./directives/dynamics/entitytabs");
require("./directives/dynamics/wfentityinstance");

require("./directives/filters/managerfilters");
require("./directives/filters/reportsfilters");

require("./directives/reportConfig/reportconfig");

require("./directives/system/attachment-menu");
require("./directives/system/attachmentdirective");
require("./directives/system/contractsdonutchart");
require("./directives/system/contractshalfdonutchart");
require("./directives/system/contractslineplot");
require("./directives/system/editlist");
require("./directives/system/emailConversation");
require("./directives/system/entityfieldsdrawer");
require("./directives/system/explorergrid1.2");
require("./directives/system/fabricsignature");
require("./directives/system/formfield");
require("./directives/system/formlayout");
require("./directives/system/formobject");
require("./directives/system/formtablecell");
require("./directives/system/heading");
require("./directives/system/impersonation");
require("./directives/system/listattachments");
require("./directives/system/managergrid");
require("./directives/system/modalactionbar");
require("./directives/system/modalactions");
require("./directives/system/ng-right-click");
require("./directives/system/svgganttchart");
require("./directives/system/table-sub");
require("./directives/system/validation");

require("./directives/tabdetails/createfielddialog");
require("./directives/tabdetails/datalistsettings");
require("./directives/tabdetails/fieldconfig");
require("./directives/tabdetails/formdetail");
require("./directives/tabdetails/gridsettings");
require("./directives/tabdetails/previewtableformulas");
require("./directives/tabdetails/screendetail");
require("./directives/tabdetails/tableconfiguration");
require("./directives/tabdetails/topformula");

require("./directives/visualworkflow/visualworkflow");

require("routes");

require('./controllers/admin/modals/archiveModulesController');
require('./controllers/admin/modals/archiveModuleSelectorController');
require('./controllers/admin/modals/archiveSelectorController');
require('./controllers/admin/modals/checkboxDialogController');
require('./controllers/admin/modals/changeEmailController');
require('./controllers/admin/modals/duplicateModuleController');
require('./controllers/admin/modals/editDraftController');
require('./controllers/admin/modals/editFolderDialogController');
require('./controllers/admin/modals/editFileDialogController');
require('./controllers/admin/modals/editExternalUserController');
require('./controllers/admin/modals/editFileController');
require('./controllers/admin/modals/editFinancialController');
require('./controllers/admin/modals/editHContractController');
require('./controllers/admin/modals/editListController');
require('./controllers/admin/modals/editMapController');
require('./controllers/admin/modals/editNModuleController');
require('./controllers/admin/modals/editRoleController');
require('./controllers/admin/modals/editScheduleController');
require('./controllers/admin/modals/editUrlController');
require('./controllers/admin/modals/editUserController');
require('./controllers/admin/modals/emailSettingsController');
require('./controllers/admin/modals/fmFileController');
require('./controllers/admin/modals/fmUploadController');
require('./controllers/admin/modals/importUploadController');
require('./controllers/admin/modals/manageReportsController');
require('./controllers/admin/modals/moveItemsController');
require('./controllers/admin/modals/pdfMarkupAddController');
require('./controllers/admin/modals/pdfMarkupDocumentLinkController');
require('./controllers/admin/modals/pspdfkitAttachmentViewerController');
require('./controllers/admin/modals/pspdfkitViewerController');
require("./controllers/admin/modals/renameItemController");
require("./controllers/admin/modals/restoreItemsController");
require("./controllers/admin/modals/saveOrganizationController");
require("./controllers/admin/modals/selectDialogController");
require("./controllers/admin/modals/pdfHandlerOptionsDialogController");
require("./controllers/admin/modals/shareItemsController");
require("./controllers/admin/modals/sendEmailController");
require("./controllers/admin/amenuController");
require("./controllers/admin/aorgController");
require("./controllers/admin/arolesController");
require("./controllers/admin/ausersController");
require("./controllers/admin/confController");
require("./controllers/admin/contractsController");
require("./controllers/admin/externalcontactsController");
require("./controllers/admin/financialsController");
require("./controllers/admin/listsmanagementController");
require("./controllers/admin/mapsController");
require("./controllers/admin/modulesController");
require("./controllers/admin/notifController");
require("./controllers/admin/pdfMarkupController");
require("./controllers/admin/reportsController");
require("./controllers/admin/schedulesController");
require("./controllers/admin/userprofileController");

require("./controllers/core/modals/editDefaultHFController");
require("./controllers/core/modals/editHeaderFooterController");
require("./controllers/core/modals/editSovCategoriesController");
require("./controllers/core/modals/editSovItemController");
require("./controllers/core/modals/editSovWbsController");
require("./controllers/core/modals/editTransitionScreenController");
require("./controllers/core/dashboardController");
require("./controllers/core/emailsController");
require("./controllers/core/idpController");
require("./controllers/core/meetingsController");
require("./controllers/core/progresspaymentController");
require("./controllers/core/smartmapController");
require("./controllers/core/sovController");
require("./controllers/core/tenantsettingsController");
require("./controllers/core/user_signController");

require("./controllers/dynamics/modals/managePresetsController");
require("./controllers/dynamics/modals/saveMultipleWorkflowEntityInstanceController");
require("./controllers/dynamics/modals/saveWorkflowEntityInstanceController");
require("./controllers/dynamics/dmController");
require("./controllers/dynamics/managementreportsController");
require("./controllers/dynamics/urlController");

require("./controllers/files/filecontractmanagerController");
require("./controllers/files/importEntitiesController");
require("./controllers/files/mycipodriveController");

require("./controllers/maps/mapsAuthoringController");

require("./controllers/module/linkToCorrespondenceController");

require("./controllers/super/modals/saveTenantController");
require("./controllers/super/sqmController");
require("./controllers/super/stntController");
require("./controllers/super/susersController");
require("./controllers/super/sutntController");

require("./controllers/system/modals/RegisterModalController");
require("./controllers/system/modals/genericClassController");
require("./controllers/system/mainController");
require("./controllers/system/modalDetailsController");

require("./controllers/tenant/tenantController");

require("cipo.impersonation");

require("./config/constants");
