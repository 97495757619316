import { Component, NgModule } from '@angular/core';
import { LegacyRoutes } from './constants/routes.constant';
import { JsToNgService } from '../ng2/services';

@Component({
  selector: 'app-legacy',
  template: '',
})
export class LegacyComponent {}

@NgModule({
  imports: [],
  declarations: [LegacyComponent],
  exports: [LegacyComponent],
  providers: [LegacyRoutes, JsToNgService],
})
export class LegacyModule {
  ngDoBootstrap() {
    // Required module bootstrap method
  }
}
