import { ComponentType } from '@angular/cdk/portal';
import { Component } from '@angular/core';
import { DemoThemeComponent } from 'src/app/demo/angular/demo-theme/demo-theme.component';
import { DemoFroalaComponent } from 'src/app/demo/demo-froala/demo-froala.component';
import { DemoScheduledEmailsComponent } from 'src/app/demo/module-instance/demo-scheduled-emails/demo-scheduled-emails.component';
import { TableComponent } from 'src/app/demo/module-instance/table/table.component';
import { MenuItem } from '../system/menu/menu.component';
import { DemoAngularButtonComponent } from './angular/button/demo-angular-button.component';
import { DemoAngularInputComponent } from './angular/input/demo-angular-input.component';
import { DemoDataCardSettingsComponent } from './module-definition/demo-data-card-settings/demo-data-card-settings.component';
import { DemoCalendarViewComponent } from './module-instance/calendar-view/demo-calendar-view.component';
import { DemoCardsViewComponent } from './module-instance/cards-view/demo-cards-view.component';
import { DemoAddressAutocompleteComponent } from './module-instance/demo-address-autocomplete/demo-address-autocomplete.component';
import { DemoFormBuilderComponent } from './module-definition/demo-form-builder/demo-form-builder.component';
import { DemoTenantSettingsComponent } from './module-instance/demo-tenant-settings/demo-tenant-settings.component';
import { DemoCipoFieldsComponent } from './demo-cipo-fields/demo-cipo-fields.component';
import { DemoCipoTextComponent } from './demo-cipo-fields/demo-cipo-text/demo-cipo-text.component';
import { DemoCipoNumberComponent } from './demo-cipo-fields/demo-cipo-number/demo-cipo-number.component';
import { DemoCipoListboxComponent } from './demo-cipo-fields/demo-cipo-listbox/demo-cipo-listbox.component';
import { DemoCipoCheckboxComponent } from './demo-cipo-fields/demo-cipo-checkbox/demo-cipo-checkbox.component';
import { DemoCipoAttachmentComponent } from './demo-cipo-fields/demo-cipo-attachment/demo-cipo-attachment.component';
import { DemoCipoSelectComponent } from './demo-cipo-fields/demo-cipo-select/demo-cipo-select.component';
import { DemoContextComponent } from './system/context/demo-context.component';
import { DemoCipoDateTimeComponent } from './demo-cipo-fields/demo-cipo-date-time/demo-cipo-date-time.component';
import { DemoTableFieldComponent } from './demo-cipo-fields/demo-table-field/demo-table-field.component';
import { DemoTypographyComponent } from './angular/typography/typography.component';

export interface DemoMenuItem extends MenuItem {
  component?: ComponentType<any>;
  children?: DemoMenuItem[];
}

@Component({
  selector: 'app-demo-empty',
  template: '',
})
export class DemoEmptyComponent {}

export const demoRoutesUrlDict = {
  101: 'demo/buttons',
  102: 'demo/inputs',
  112: 'demo/cipo-fields',
  103: 'demo/theme',
  104: 'demo/froala',
  105: 'demo/address',
  106: 'demo/typography',
  151: 'demo/custom-form',
  152: 'demo/cipo-text',
  153: 'demo/cipo-number',
  154: 'demo/cipo-listbox',
  155: 'demo/cipo-checkbox',
  157: 'demo/cipo-attachment',
  156: 'demo/cipo-select',
  158: 'demo/cipo-datetime',
  159: 'demo/cipo-table-field',
  201: 'demo/card-settings',
  203: 'demo/form-builder',
  301: 'demo/calendar-view',
  302: 'demo/cards-view',
  303: 'demo/example-table',
  304: 'demo/scheduled-emails',
  305: 'demo/tenant-settings',
  401: 'demo/context',
  1001: 'demo/test-2nd-level-m1',
  1002: 'demo/test-2nd-level-m2',
  1101: 'demo/test-3nd-level-m3-1',
  1102: 'demo/test-3nd-level-m3-2',
  1201: 'demo/test-3nd-level-m4-1',
  1202: 'demo/test-3nd-level-m4-2',
} as const;

export const flatMenuItems = [
  { id: 101, name: 'Button', iconId: 40, urlPath: demoRoutesUrlDict[101], component: DemoAngularButtonComponent },
  { id: 102, name: 'Input', iconId: 21, urlPath: demoRoutesUrlDict[102], component: DemoAngularInputComponent },
  { id: 112, name: 'Cipo Fields', iconId: 21, urlPath: demoRoutesUrlDict[112], component: DemoCipoFieldsComponent },
  { id: 103, name: 'Theme', iconId: 24, urlPath: demoRoutesUrlDict[103], component: DemoThemeComponent },
  { id: 104, name: 'Froala', iconId: 46, urlPath: demoRoutesUrlDict[104], component: DemoFroalaComponent },
  {
    id: 105,
    name: 'Address',
    iconId: 21,
    urlPath: demoRoutesUrlDict[105],
    component: DemoAddressAutocompleteComponent,
  },
  { id: 106, name: 'Typography', iconId: 20, urlPath: demoRoutesUrlDict[106], component: DemoTypographyComponent },
  { id: 151, name: 'Custom form', iconId: 22, urlPath: demoRoutesUrlDict[151], component: DemoCipoFieldsComponent },
  { id: 152, name: 'Cipo text', iconId: 23, urlPath: demoRoutesUrlDict[152], component: DemoCipoTextComponent },
  { id: 153, name: 'Cipo number', iconId: 24, urlPath: demoRoutesUrlDict[153], component: DemoCipoNumberComponent },
  {
    id: 154,
    name: 'Cipo listbox',
    iconId: 25,
    urlPath: demoRoutesUrlDict[154],
    component: DemoCipoListboxComponent,
  },
  {
    id: 155,
    name: 'Cipo checkbox',
    iconId: 26,
    urlPath: demoRoutesUrlDict[155],
    component: DemoCipoCheckboxComponent,
  },
  {
    id: 157,
    name: 'Cipo attachment',
    iconId: 28,
    urlPath: demoRoutesUrlDict[157],
    component: DemoCipoAttachmentComponent,
  },
  { id: 156, name: 'Cipo select', iconId: 27, urlPath: demoRoutesUrlDict[156], component: DemoCipoSelectComponent },
  {
    id: 158,
    name: 'Cipo DateTime',
    iconId: 28,
    urlPath: demoRoutesUrlDict[158],
    component: DemoCipoDateTimeComponent,
  },
  {
    id: 159,
    name: 'Cipo Table field',
    iconId: 29,
    urlPath: demoRoutesUrlDict[159],
    component: DemoTableFieldComponent,
  },
  { id: 151, name: 'Custom form', iconId: 22, urlPath: demoRoutesUrlDict[151], component: DemoCipoFieldsComponent },
  { id: 152, name: 'Cipo text', iconId: 23, urlPath: demoRoutesUrlDict[152], component: DemoCipoTextComponent },
  { id: 153, name: 'Cipo number', iconId: 24, urlPath: demoRoutesUrlDict[153], component: DemoCipoNumberComponent },
  {
    id: 154,
    name: 'Cipo listbox',
    iconId: 25,
    urlPath: demoRoutesUrlDict[154],
    component: DemoCipoListboxComponent,
  },
  {
    id: 155,
    name: 'Cipo checkbox',
    iconId: 26,
    urlPath: demoRoutesUrlDict[155],
    component: DemoCipoCheckboxComponent,
  },
  {
    id: 157,
    name: 'Cipo attachment',
    iconId: 28,
    urlPath: demoRoutesUrlDict[157],
    component: DemoCipoAttachmentComponent,
  },
  { id: 156, name: 'Cipo select', iconId: 27, urlPath: demoRoutesUrlDict[156], component: DemoCipoSelectComponent },
  {
    id: 158,
    name: 'Cipo DateTime',
    iconId: 28,
    urlPath: demoRoutesUrlDict[158],
    component: DemoCipoDateTimeComponent,
  },
  {
    id: 159,
    name: 'Cipo Table field',
    iconId: 29,
    urlPath: demoRoutesUrlDict[159],
    component: DemoTableFieldComponent,
  },
  {
    id: 201,
    name: 'Card settings',
    iconId: 65,
    urlPath: demoRoutesUrlDict[201],
    component: DemoDataCardSettingsComponent,
  },
  {
    id: 203,
    name: 'Form Builder',
    iconId: 13,
    urlPath: demoRoutesUrlDict[203],
    component: DemoFormBuilderComponent,
  },
  {
    id: 301,
    name: 'Calendar view',
    iconId: 95,
    urlPath: demoRoutesUrlDict[301],
    component: DemoCalendarViewComponent,
  },
  { id: 302, name: 'Cards view', iconId: 13, urlPath: demoRoutesUrlDict[302], component: DemoCardsViewComponent },
  { id: 303, name: 'Example table', iconId: 13, urlPath: demoRoutesUrlDict[303], component: TableComponent },
  {
    id: 304,
    name: 'Scheduled emails',
    iconId: 66,
    urlPath: demoRoutesUrlDict[304],
    component: DemoScheduledEmailsComponent,
  },
  {
    id: 305,
    name: 'Tenant Settings',
    iconId: 67,
    urlPath: demoRoutesUrlDict[305],
    component: DemoTenantSettingsComponent,
  },
  { id: 401, name: 'Context', iconId: 73, urlPath: demoRoutesUrlDict[401], component: DemoContextComponent },
  { id: 1001, name: 'Test 2nd level m1', urlPath: demoRoutesUrlDict[1001], component: DemoEmptyComponent },
  { id: 1002, name: 'Test 2nd level m2', urlPath: demoRoutesUrlDict[1002], component: DemoEmptyComponent },
  { id: 1101, name: 'Test 3nd level m3.1', urlPath: demoRoutesUrlDict[1101], component: DemoEmptyComponent },
  { id: 1102, name: 'Test 3nd level m3.2', urlPath: demoRoutesUrlDict[1102], component: DemoEmptyComponent },
  { id: 1201, name: 'Test 3nd level m4.1', urlPath: demoRoutesUrlDict[1201], component: DemoEmptyComponent },
  { id: 1202, name: 'Test 3nd level m4.2', urlPath: demoRoutesUrlDict[1202], component: DemoEmptyComponent },
];

export const DemoMenuItems: DemoMenuItem[] = [
  {
    id: 100,
    name: 'Angular',
    children: [
      { id: 101, name: 'Button', iconId: 40, urlPath: demoRoutesUrlDict[101], component: DemoAngularButtonComponent },
      { id: 102, name: 'Input', iconId: 21, urlPath: demoRoutesUrlDict[102], component: DemoAngularInputComponent },
      { id: 112, name: 'Cipo Fields', iconId: 21, urlPath: demoRoutesUrlDict[112], component: DemoCipoFieldsComponent },
      { id: 103, name: 'Theme', iconId: 24, urlPath: demoRoutesUrlDict[103], component: DemoThemeComponent },
      { id: 104, name: 'Froala', iconId: 46, urlPath: demoRoutesUrlDict[104], component: DemoFroalaComponent },
      {
        id: 105,
        name: 'Address',
        iconId: 21,
        urlPath: demoRoutesUrlDict[105],
        component: DemoAddressAutocompleteComponent,
      },
      { id: 106, name: 'Typography', iconId: 20, urlPath: demoRoutesUrlDict[106], component: DemoTypographyComponent },
    ],
  },
  {
    id: 150,
    name: 'Fields',
    children: [
      { id: 151, name: 'Custom form', iconId: 22, urlPath: demoRoutesUrlDict[151], component: DemoCipoFieldsComponent },
      { id: 152, name: 'Cipo text', iconId: 23, urlPath: demoRoutesUrlDict[152], component: DemoCipoTextComponent },
      { id: 153, name: 'Cipo number', iconId: 24, urlPath: demoRoutesUrlDict[153], component: DemoCipoNumberComponent },
      {
        id: 154,
        name: 'Cipo listbox',
        iconId: 25,
        urlPath: demoRoutesUrlDict[154],
        component: DemoCipoListboxComponent,
      },
      {
        id: 155,
        name: 'Cipo checkbox',
        iconId: 26,
        urlPath: demoRoutesUrlDict[155],
        component: DemoCipoCheckboxComponent,
      },
      {
        id: 157,
        name: 'Cipo attachment',
        iconId: 28,
        urlPath: demoRoutesUrlDict[157],
        component: DemoCipoAttachmentComponent,
      },
      { id: 156, name: 'Cipo select', iconId: 27, urlPath: demoRoutesUrlDict[156], component: DemoCipoSelectComponent },
      {
        id: 158,
        name: 'Cipo DateTime',
        iconId: 28,
        urlPath: demoRoutesUrlDict[158],
        component: DemoCipoDateTimeComponent,
      },
      {
        id: 159,
        name: 'Cipo Table field',
        iconId: 29,
        urlPath: demoRoutesUrlDict[159],
        component: DemoTableFieldComponent,
      },
    ],
  },
  {
    id: 200,
    name: 'Module Definition',
    children: [
      {
        id: 201,
        name: 'Card settings',
        iconId: 65,
        urlPath: demoRoutesUrlDict[201],
        component: DemoDataCardSettingsComponent,
      },
      {
        id: 203,
        name: 'Form Builder',
        iconId: 13,
        urlPath: demoRoutesUrlDict[203],
        component: DemoFormBuilderComponent,
      },
    ],
  },
  {
    id: 300,
    name: 'Module Instance',
    children: [
      {
        id: 301,
        name: 'Calendar view',
        iconId: 95,
        urlPath: demoRoutesUrlDict[301],
        component: DemoCalendarViewComponent,
      },
      { id: 302, name: 'Cards view', iconId: 13, urlPath: demoRoutesUrlDict[302], component: DemoCardsViewComponent },
      { id: 303, name: 'Example table', iconId: 13, urlPath: demoRoutesUrlDict[303], component: TableComponent },
      {
        id: 304,
        name: 'Scheduled emails',
        iconId: 66,
        urlPath: demoRoutesUrlDict[304],
        component: DemoScheduledEmailsComponent,
      },
      {
        id: 305,
        name: 'Tenant Settings',
        iconId: 67,
        urlPath: demoRoutesUrlDict[305],
        component: DemoTenantSettingsComponent,
      },
    ],
  },
  {
    id: 400,
    name: 'System',
    children: [
      { id: 401, name: 'Context', iconId: 73, urlPath: demoRoutesUrlDict[401], component: DemoContextComponent },
    ],
  },
  {
    id: 1000,
    name: 'Test 1st level',
    children: [
      { id: 1001, name: 'Test 2nd level m1', urlPath: demoRoutesUrlDict[1001], component: DemoEmptyComponent },
      { id: 1002, name: 'Test 2nd level m2', urlPath: demoRoutesUrlDict[1002], component: DemoEmptyComponent },
      {
        id: 1003,
        name: 'Test 2nd level m3',
        children: [
          { id: 1101, name: 'Test 3nd level m3.1', urlPath: demoRoutesUrlDict[1101], component: DemoEmptyComponent },
          { id: 1102, name: 'Test 3nd level m3.2', urlPath: demoRoutesUrlDict[1102], component: DemoEmptyComponent },
        ],
      },
      {
        id: 1004,
        name: 'Test 2nd level m4',
        children: [
          { id: 1201, name: 'Test 3nd level m4.1', urlPath: demoRoutesUrlDict[1201], component: DemoEmptyComponent },
          { id: 1202, name: 'Test 3nd level m4.2', urlPath: demoRoutesUrlDict[1202], component: DemoEmptyComponent },
        ],
      },
    ],
  },
];

export const NewDemoRoutes = DemoMenuItems.map(item => {
  const { component, ...rest } = item;
  return {
    ...rest,
    children: item.children
      ? item.children.map(child => {
          const { component, ...childRest } = child;
          return {
            ...childRest,
            children: child.children
              ? child.children.map(grandChild => {
                  const { component, ...grandChildRest } = grandChild;
                  return grandChildRest;
                })
              : undefined,
          };
        })
      : undefined,
  };
});
