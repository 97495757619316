import { cipo } from 'cipo';
import moment from 'moment';
import { FormattingType, RestrictionType } from 'src/app/models/module/fields/enums';

cipo.factory("Form", function (Model, $q, Message, userService, URI, $timeout, AttachmentHandler, PSPDFKIT_CONFIG, MIME_TYPES, OFFICE_MIME_TYPES, MAX_NR_VISIBLE_SELECT_ITEMS) {

    var Form = Model.extend(function (obj) {
        var self = this;
        self.loading = false;
        self.attachmentHandler = new AttachmentHandler({
            isLoading: function (isLoading) { self.loading = isLoading; }
        });
        var obj = obj || {};

        this.set_classes(obj);

        // -----
        self.TYPES = [
            'text', 'select', 'select_new_format', 'UIselect', 'date', 'textarea', 'editor', 'checkbox', 'list', 'contact'
        ];

        self.data = obj.data || null;
        self.storedData = null;
        self.description = null;
        self.fieldsList = {}

        self.fieldsOrder = [];

        self.initializing = false;
        self.isValid = true;
        self.display = obj.display || 'flow';
        self.pattern = obj.display || null;
        self.originalData = null;

        self.onSubmitForm = obj.onSubmitForm || function () { return; };

        self.set_Data(obj);

        Object.defineProperty(self, 'dirty', {
            get: function () { return !angular.equals(self.data, self.originalData); }
        });
        // -----

        self.layoutURL = obj.layoutURL || null;
        self.layoutParams = obj.layoutParams || {};
        self.dataURL = obj.dataURL || null;
        self.dataParams = obj.dataParams || [];
        self.noPadding = obj.noPadding || false;
        self.layoutResponse = null;
        self.dataResponse = null;
        self.editMode = ((typeof obj.editMode != "undefined") && obj.editMode == false) ? false : true;
        self.wrapper = obj.wrapper || [];
        self.fields = [];
        self.valuesArray = [];
        self.formMap = obj.formMap || null;
        self.mapKeep = obj.mapKeep || null;
        self.onChange = obj.onChange || function (fieldId, newValue, data, fields) { };

        if (self.formMap) self.process_map(self.formMap);

        self.afterGetData = obj.afterGetData || function () { return; }

        if (self.layoutURL) {
            self.initializing = true;
            self.get_Layout().then(function () {
                if (self.dataURL) {
                    self.get_Data().then(function () {
                        self.initializing = false;
                        self.afterGetData();
                    }).catch(function (e) {
                        self.set_formError('Could not receive form data: ' + e);
                    });
                } else {
                    self.initializing = false;
                }
            }).catch(function (e) {
                self.set_formError('Could not receive form layout: ' + e);
            });
        }
    });

    //---

    Form.prototype.set_Data = function (data) {
        var self = this;
        var obj = data || {};
        self.data = obj;

        if (self.description)
            self.bindDescriptionToData();
        self.originalData = angular.copy(self.data);
    }

    Form.prototype.set_Description = function (obj, individualDesc) {
        var self = this;
        var obj = obj || {};
        self.lookup = {};

        if (!individualDesc) {
            self.description = obj;

            for (var key in obj) {
                if (obj.hasOwnProperty(key)) {
                    self.lookup[obj[key].fieldName || key] = obj[key];
                    self.lookup[obj[key].fieldName || key].id = key;
                }
            }

            if (self.data)
                self.bindDescriptionToData();
        } else {
            for (var key in obj) {
                if (obj.hasOwnProperty(key)) {
                    self.description[key] = obj[key];
                }
                self.lookup[obj[key].fieldName || key] = obj[key];
                self.lookup[obj[key].fieldName || key].id = key;
            }
            if (self.data)
                self.bindDescriptionToData();
        }
    }

    Form.prototype.bindDescriptionToData = function () {
        var self = this;
        self.fieldsList = {};
        self.fieldsOrder = [];

        if (self.description && self.data) {
            for (var key in self.description) {
                if (self.description.hasOwnProperty(key) && self.data.hasOwnProperty(key)) {
                    var property = key;
                    var obj = self.description[property];
                    try {
                        var field;
                        var type = (typeof obj.type != 'undefined') ? obj.type : "text";

                        field = new (function () {
                            var fieldObj = this;

                            // settings defined properties
                            this.formattings = angular.copy(obj.formattings);
                            this.restrictions = angular.copy(obj.restrictions);

                            this.entityInstanceId = self.entityInstanceId;
                            this.annotation = (typeof obj.annotation != 'undefined') ? obj.annotation : null;
                            this.atwhoConfig = (typeof obj.atwhoConfig != 'undefined') ? angular.copy(obj.atwhoConfig) : null;
                            this.atwhoConfigTagging = (typeof obj.atwhoConfigTagging != 'undefined') ? angular.copy(obj.atwhoConfigTagging) : null;

                            this.dataSourceFields = (typeof obj.dataSourceFields != 'undefined') ? angular.copy(obj.dataSourceFields) : [];
                            this.defaultValue = (typeof obj.defaultValue != 'undefined') ? obj.defaultValue : null;
                            this.editable = (typeof obj.editable != 'undefined') ? obj.editable : true;
                            this.editMode = (typeof obj.editMode != 'undefined') ? obj.editMode : null;
                            this.fieldId = typeof obj.fieldId !== 'undefined' ? obj.fieldId : null;
                            this.focus = false;
                            this.fieldName = typeof obj.fieldName !== 'undefined' ? obj.fieldName : this.fieldId;
                            this.systemFieldName = (typeof obj.systemFieldName != 'undefined') ? obj.systemFieldName : null;
                            this.filter = (typeof obj.filter != 'undefined') ? obj.filter : null;
                            this.filterObj = (typeof obj.filterObj != 'undefined') ? obj.filterObj : null;
                            this.hideRequired = (typeof obj.hideRequired != 'undefined') ? obj.hideRequired : false;
                            this.hints = (typeof obj.hints != 'undefined') ? angular.copy(obj.hints) : null;
                            this.hintsTagg = (typeof obj.hintsTagg != 'undefined') ? angular.copy(obj.hintsTagg) : null;
                            this.info = (typeof obj.info != 'undefined') ? obj.info : "";
                            this.warning = (typeof obj.warning != 'undefined') ? obj.warning : "";
                            this.label = (typeof obj.label != 'undefined') ? obj.label : "";
                            this.labelOnTop = (typeof obj.labelOnTop != 'undefined') ? obj.labelOnTop : false;
                            this.loading = false;
                            this.multiple = (typeof obj.multiple != 'undefined') ? obj.multiple : false;
                            this.noErrorMsg = (typeof obj.noErrorMsg != 'undefined') ? obj.noErrorMsg : false;
                            this.noToolbarEditor = (typeof obj.noToolbarEditor != 'undefined') ? obj.noToolbarEditor : false;
                            this.options = (typeof obj.options != 'undefined') ? angular.copy(obj.options) : [];
                            this.optionsGroupBy = (typeof obj.optionsGroupBy != 'undefined') ? obj.optionsGroupBy : 'group_by';
                            this.optionsKeyProperty = (typeof obj.optionsKeyProperty != 'undefined') ? obj.optionsKeyProperty : 'key';
                            this.optionsValueProperty = (typeof obj.optionsValueProperty != 'undefined') ? obj.optionsValueProperty : 'value';
                            this.optionsIsUsedProperty = (typeof obj.optionsIsUsedProperty != 'undefined') ? obj.optionsIsUsedProperty : 'isUsed';
                            this.optionsDescriptionProperty = (typeof obj.optionsDescriptionProperty != 'undefined') ? obj.optionsDescriptionProperty : null;
                            this.optionsHasEmptyValue = (typeof obj.optionsHasEmptyValue != 'undefined') ? obj.optionsHasEmptyValue : true;
                            this.search = (typeof obj.search != 'undefined') ? obj.search : true;
                            this.showLabel = (typeof obj.showLabel != 'undefined') ? obj.showLabel : false;
                            this.toStringEmptyValue = (typeof obj.toStringEmptyValue != 'undefined') ? obj.toStringEmptyValue : '-';
                            this.type = type;
                            this.typeId = (typeof obj.typeId != 'undefined') ? obj.typeId : null;
                            this.validation = (typeof obj.validation != 'undefined') ? obj.validation : {};
                            this.values = (typeof obj.values != 'undefined') ? obj.values : true;
                            this.visible = (typeof obj.visible != 'undefined') ? obj.visible : true;
                            this.expression = (typeof obj.expression != 'undefined') ? obj.expression : "";

                            this.triggered = typeof obj.triggered !== 'undefined' ? obj.triggered : [];
                            this.updateModelOnBlur = typeof obj.updateModelOnBlur !== 'undefined' ? obj.updateModelOnBlur : true;
                            this.onClose = typeof obj.onClose !== 'undefined' ? obj.onClose : function (f) { };
                            this.onKeyUp = typeof obj.onKeyUp !== 'undefined' ? obj.onKeyUp : function (ev, data) { };
                            this.onChange = typeof obj.onChange !== 'undefined' ? obj.onChange : function (data, oldValue, newValue) { };
                            this.recreateFormulaField = typeof obj.recreateFormulaField !== 'undefined' ? obj.recreateFormulaField : function (f) { };

                            this.hasMultipleValues = obj.hasMultipleValues;
                            this.dataSourceId = obj.dataSourceId || null;
                            this.relationId = obj.relationId || null;
                            this.usedOnWeather = obj.usedOnWeather || null;
                            this.weatherDateId = obj.weatherDateId || null;

                            this.formattingsLookup = obj.formattingsLookup || {};
                            this.restrictionsLookup = obj.restrictionsLookup || {};

                            this._isDisabled = typeof obj.isDisabled !== 'undefined' ? obj.isDisabled : false;
                            this.parentFieldDataSourceId = obj.parentFieldDataSourceId || null;
                            this.childFieldIds = obj.childFieldIds || [];
                            this.parentFieldId = obj.parentFieldId || null;
                            this.parentFieldListId = obj.parentFieldListId || null; // list id for filtering data source
                            this.parentListElementIds = obj.parentListElementIds || []; // element id for filtering data source
                            this.isParent = obj.isParent || false;
                            this.actionInstanceId = obj.actionInstanceId || null;
                            this.previousActionInstanceId = obj.previousActionInstanceId || null;

                            if (this.hasMultipleValues) this.isDatasourceLoaded = false;

                            // field system properties
                            this._fieldProperty = property;
                            this._fieldName = 'cipo-' + property;
                            this._isValid = true;
                            this._errorMsg = null;
                            this._required = ((this.validation.required || this.validation.requiredIfFieldHasValue) && !this.hideRequired) || this.restrictionsLookup[2] || this.restrictionsLookup[3] ? true : false;
                            this._lookupOptions = {};
                            this.optionsPage = 1;
                            this.optionsShowLoadMore = true;


                            this._multipleFieldErrors = [];
                            this._multipleFieldFocus = [];

                            if (this.restrictionsLookup[4] == 2) {
                                this.validation["isInteger"] = true;
                            }

                            // set default value if default value and no value on field
                            //if (self.editMode && this.defaultValue !== null && !self.data[this._fieldProperty] && self.data[this._fieldProperty] !== 0)
                            //    self.data[this._fieldProperty] = this.defaultValue;

                            if (this.filter && Object.prototype.toString.call(this.filter) == '[object Object]') {
                                var filterKey = Object.keys(this.filter)[0];
                                var filterValue = this.filter[Object.keys(this.filter)[0]];
                                var filterTerms = function (i) {
                                    if (i[filterKey] === filterValue) return true;
                                }

                                this.options = this.options.filter(filterTerms);
                            }

                            if (this.filterObj && Object.prototype.toString.call(this.filterObj) == '[object Function]') {
                                this.options = this.options.filter(this.filterObj);
                            }

                            Object.defineProperty(this, '_submit', {
                                get: function () { return self.onSubmitForm(); }
                            });

                            Object.defineProperty(this, '_value', {
                                get: function () {
                                    return self.data[this._fieldProperty];
                                },
                                set: function (setValue) { 
                                    self.data[this._fieldProperty] = setValue; 
                                    self.onChange(this._fieldProperty, setValue, self.data, self.fieldsList);
                                }
                            });

                            Object.defineProperty(this, '_oldValue', {
                                get: function () {
                                    return self.data[this._fieldProperty + "_old"];
                                },
                                set: function (setValue) { self.data[this._fieldProperty + "_old"] = setValue; }
                            });

                            Object.defineProperty(this, '_hasOptions', {
                                get: function () {
                                    return this.options && this.options.length;
                                }
                            });

                            Object.defineProperty(this, '_aggregateValue', {
                                get: function () {
                                    return self.data[this._fieldProperty + "_aggregateValue"];
                                }
                            });

                            Object.defineProperty(this, '_aggregateOldValue', {
                                get: function () {
                                    return self.data[this._fieldProperty + "_aggregateOldValue"];
                                }
                            });

                            Object.defineProperty(this, '_showOldValue', {
                                get: function () {
                                    var arrayWithValues = Array.isArray(this._oldValue) && this._oldValue.length;
                                    var valueDefined = !Array.isArray(this._oldValue) && this._oldValue;
                                    return !this._editMode && (arrayWithValues || valueDefined) && !this.loading;
                                }
                            });

                            Object.defineProperty(this, 'isDisabled', {
                                get: function () {
                                    if (this._isDisabled)
                                        return true;
                                    else if (!fieldObj.parentFieldId)
                                        return false;
                                    return (self.fieldsList[fieldObj.parentFieldId] && self.fieldsList[fieldObj.parentFieldId]._value || "").toString() ? false : true;
                                },
                                set: function (setValue) { this._isDisabled = setValue; }
                            });

                            Object.defineProperty(this, '_valueToString', {
                                get: function () {
                                    if (this._value instanceof moment) {
                                        return userService.formatDateTimeBasedOnRestrictionsLookup(this._value, this.restrictionsLookup);
                                    } else if (Object.prototype.toString.call(this._value) == '[object Array]') {
                                        var retval = null;
                                        if (this._value.length) {
                                            retval = [];
                                            for (var i = 0; i < this._value.length; i++) {
                                                retval.push(this._lookupOptions[this._value[i]] ? this._lookupOptions[this._value[i]] : this._value[i]);
                                            }
                                        }
                                        return retval ? retval.join(', ') : '';
                                    }
                                    else return this._lookupOptions[this._value] ? this._lookupOptions[this._value] : this._value;
                                }
                            });

                            Object.defineProperty(this, '_editMode', {
                                get: function () {
                                    var edit = this.editMode == null ? self.editMode : this.editMode;
                                    return edit;
                                }
                            });

                            Object.defineProperty(this, '_decimals', {
                                get: function () {
                                    return this.validation['isInteger'] == true ? 0 : (this.formattings || []).find(x => x.key === FormattingType.Decimals)?.formattingValue ?? 0;
                                }
                            });

                            Object.defineProperty(this, '_showThousandSeparator', {
                                get: function () {
                                    return !!(this.restrictions || []).find(f => f.key == RestrictionType.ShowThousandSeparator);
                                }
                            });

                            Object.defineProperty(this, '_errors', {
                                get: function () {
                                    return this.errors || (this._errorMsg ? { not_valid: true } : {});
                                }
                            });

                            Object.defineProperty(this, '_decimalSeparator', {
                                get: function () {
                                    return userService.userSettings().decimalSeparator;
                                }
                            });

                            Object.defineProperty(this, '_valueNumberWithDecimalSeparator', {
                                get: function () {
                                    // formats the number
                                    if (typeof this._value == 'number') {
                                        var val = userService.formatNumber(this._value, this._decimals, false, false, false);
                                        return val;
                                    }
                                    return this._value;
                                },
                                set: function (value) {
                                    this.__valueNumberWithDecimalSeparator = value;
                                }
                            });

                            Object.defineProperty(this, '_valueNumberFromNumberWithDecimalSeparator', {
                                get: function () {
                                    // converts the number formatted as string to a number
                                    if (typeof this.__valueNumberWithDecimalSeparator == 'string') {
                                        var val = userService.revertLocaleNumber(this.__valueNumberWithDecimalSeparator);
                                        return val;
                                    }
                                    return this.__valueNumberWithDecimalSeparator;
                                }
                            });

                            this.numberValueChanged = function () {
                                this._value = this._valueNumberFromNumberWithDecimalSeparator;
                                this.onChange(this);
                            }

                            Object.defineProperty(this, '_valuesNumberWithDecimalSeparator', {
                                value: (Array.isArray(this._value) ? this._value : []).map(v => {
                                    if (typeof v == 'number') {
                                        var val = userService.formatNumber(v, this._decimals, false, false, false);
                                        return { value: val };
                                    }
                                    return { value: v };
                                }),
                            });
                
                            Object.defineProperty(this, '_valuesNumberFromNumberWithDecimalSeparator', {
                                get: function () { 
                                    // converts the number formatted as string to a number
                                    var values = (this._valuesNumberWithDecimalSeparator || []).map(v => {
                                        if (typeof v.value == 'string') {
                                            var val = userService.revertLocaleNumber(v.value);
                                            return val;
                                        }
                                        return v.value;
                                    });
                                    return values;
                                }
                            });
                
                            this.numberValuesChanged = function () {
                                this._value = this._valuesNumberFromNumberWithDecimalSeparator;
                            }
                
                            this.changeOptionsPage = function (page, searchTerm) {
                                this.optionsPage = page;
                                this._dataSourceSearch(undefined, searchTerm);
                            }

                            // field system properties parsing
                            var parseDatasource = function (options, keys) {
                                var ret = {
                                    options: [],
                                    lookupOptions: {},
                                    mapOptions: {},
                                }
                                if (!keys) var keys = {
                                    optionsKeyProperty: "key",
                                    optionsValueProperty: "value",
                                    optionsIsUsedProperty: "isUsed",
                                    optionsGroupBy: "",
                                    optionsDescriptionProperty: ""
                                }

                                for (var i = 0; i < options.length; i++) {
                                    options[i]._key = options[i][keys.optionsKeyProperty];
                                    options[i]._value = options[i][keys.optionsValueProperty];
                                    options[i]._isUsed = options[i][keys.optionsIsUsedProperty];
                                    options[i]._groupValue = options[i][keys.optionsGroupBy];
                                    options[i]._description =
                                        keys.optionsDescriptionProperty ? options[i][keys.optionsDescriptionProperty] : '';

                                    ret.lookupOptions[options[i]._key] = options[i]._value;
                                    ret.mapOptions[options[i]._key] = options[i];
                                    if (typeof options[i]._key == 'string' &&
                                        options[i]._key == "")
                                        this.optionsHasEmptyValue = true;

                                    ret.options.push(options[i]);
                                }

                                return ret;
                            }

                            if (this.options.length) {
                                this.selectOptions = {
                                    optionsKeyProperty: this.optionsKeyProperty,
                                    optionsValueProperty: this.optionsValueProperty,
                                    optionsIsUsedProperty: this.optionsIsUsedProperty,
                                    optionsGroupBy: this.optionsGroupBy,
                                    optionsDescriptionProperty: this.optionsDescriptionProperty
                                };
                                var ret = parseDatasource(this.options, this.selectOptions);
                                this.options = ret.options;
                                this.optionsShowLoadMore = ret.options.length > 0;
                                this._lookupOptions = ret.lookupOptions;
                                this._mapOptions = ret.mapOptions;
                            }

                            // field functions



                            // multiple checkbox
                            this.toggle = function (itemId) {
                                var idx = fieldObj._value.indexOf(itemId);
                                if (idx > -1) {
                                    fieldObj._value.splice(idx, 1);
                                }
                                else {
                                    fieldObj._value.push(itemId);
                                }
                            };

                            this.exists = function (itemId) {
                                return fieldObj._value.indexOf(itemId) > -1;
                            };

                            // multiple field functions
                            this._multipleFieldOnKeyUp = function ($event, $index) {
                                // if tab
                                if ($event.keyCode == 9) {
                                    $event.preventDefault();

                                    if ($index == fieldObj._value.length - 1) fieldObj._multipleField_setValue();
                                    else {
                                        fieldObj._multipleFieldFocus[$index] = false;
                                        fieldObj._multipleFieldFocus[$index + 1] = true;
                                    }

                                }
                            }
                            this._multipleFieldOnKeyDown = function ($event) {
                                // if tab
                                if ($event.keyCode == 9) {
                                    $event.preventDefault();
                                }
                            }

                            this._multipleFieldInit = function ($index) {
                                // set the errors and focus arrays if not set yet
                                if (fieldObj.hasMultipleValues && fieldObj._value.length) {
                                    if (fieldObj._value.length != fieldObj._multipleFieldErrors.length) {
                                        fieldObj._multipleFieldErrors = [];
                                        for (var i = 0; i < fieldObj._value.length; i++) {
                                            fieldObj._multipleFieldErrors.push("");
                                        }
                                    }
                                    if (fieldObj._value.length != fieldObj._multipleFieldFocus.length) {
                                        fieldObj._multipleFieldFocus = [];
                                        for (var i = 0; i < fieldObj._value.length; i++) {
                                            fieldObj._multipleFieldFocus.push(false);
                                        }
                                    }

                                }
                                fieldObj._multipleFieldFocus[$index] = true;
                                fieldObj._multipleFieldErrors[$index] = '';
                            }

                            this._multipleField_setValue = function ($event, index) {

                                var isAdd = typeof (index) != 'undefined' ? false : true;

                                if (!fieldObj._value) {
                                    fieldObj._value = [null];
                                }

                                var currentIndex = !isAdd ? index : fieldObj._value.length - 1;

                                if (fieldObj._value[currentIndex] != null && typeof fieldObj._value[currentIndex] != 'undefined') {
                                    // if ($event) $event.target.blur();
                                    if (isAdd) {
                                        fieldObj._multipleFieldFocus[currentIndex] = false;
                                        fieldObj._value.push(null);
                                        fieldObj._multipleFieldErrors.push("");
                                        fieldObj._multipleFieldFocus.push(true);

                                    } else fieldObj._multipleFieldErrors[currentIndex] = "";
                                } else {
                                    if (isAdd) {
                                        fieldObj._multipleFieldErrors[currentIndex] = "Please add a value";
                                        fieldObj._multipleFieldFocus[currentIndex] = false;
                                        $timeout(function () {
                                            fieldObj._multipleFieldErrors[currentIndex] = "";
                                            fieldObj._multipleFieldFocus[currentIndex] = true;
                                        }, 1000);
                                        if ($event) $event.target.focus();
                                    }
                                    else {
                                        fieldObj._multipleFieldErrors[currentIndex] = null;
                                        if (index !== 0 || fieldObj._value.length > 1) this._multipleField_remove(index);
                                        else if ($event) $event.target.blur();
                                    }
                                }
                            }

                            this._multipleField_remove = function (index) {
                                if (index == 0 && fieldObj._value.length == 1) {
                                    fieldObj._value[0] = null;
                                } else {
                                    fieldObj._value.splice(index, 1);
                                    fieldObj._multipleFieldErrors.splice(index, 1);
                                }

                            };

                            this._selectAllOptions = function () {
                                if (!(fieldObj.options || []).length) {
                                    return;
                                }
                                var oldValue = angular.copy(fieldObj._value);
                                fieldObj._value = [];
                                for (var i = 0; i < fieldObj.options.length; i++) {
                                    fieldObj._value.push(fieldObj.options[i]._key);
                                }
                                fieldObj.onChange(fieldObj, oldValue, fieldObj._value);
                            }

                            // end multiple

                            this._clearErrors = function () {
                                fieldObj._errorMsg = null;
                                fieldObj._isValid = true;
                                self.isValid = true;
                            };




                            this._clearValue = function () {
                                var oldValue = angular.copy(fieldObj._value);
                                if (this.type != 'select') fieldObj._value = null;
                                else fieldObj._value = [];
                                fieldObj.onChange(fieldObj, oldValue, fieldObj._value);
                            };

                            // dynamic datasources
                            this._dataSourceSearch = function (event, searchTerm) {
                                var field = this;
                                event && event.stopPropagation();
                                if (event && event.keyCode != 13) {
                                    if (field.timeout) $timeout.cancel(field.timeout);
                                    field.timeout = $timeout(function () {
                                        field._getDataSources(searchTerm);
                                        $timeout.cancel(field.timeout);
                                    }, 1000);
                                } else {
                                    if (field.timeout) $timeout.cancel(field.timeout);
                                    field._getDataSources(searchTerm);
                                }
                            }

                            this._getDataSources = function (searchTerm) {
                                var field = this;
                                field.loading = true;

                                // var p = $q.defer();

                                field.currentValue = field._value ? field._value.toString() : field._value;

                                var value = field._value && Object.prototype.toString.call(field._value) != '[object Array]' ? [field._value] : field._value;

                                var urlData = field.relationId ? URI.FIELD_DEFINITION.DATASOURCE_RELATION : URI.FIELD_DEFINITION.DATASOURCE_LIST;
                                var bodyParams = {
                                    selectedIds: value || [],
                                    entityInstanceId: self.entityInstanceId,
                                    search: {
                                        criteria: searchTerm || "",
                                        isCurrent: false,
                                        page: field.optionsPage,
                                        pagesize: MAX_NR_VISIBLE_SELECT_ITEMS
                                    }
                                }

                                if (field.parentListElementIds) {
                                    bodyParams.parentListId = field.parentFieldListId;
                                    bodyParams.ParentElementIds = field.parentListElementIds;
                                }

                                if (field.relationId) {
                                    bodyParams.relationId = field.relationId;
                                    bodyParams.contractId = userService.system.userdata.contractId;
                                    bodyParams.fieldId = field.fieldId;
                                } else {
                                    bodyParams.id = field.dataSourceId;
                                    bodyParams.contractId = userService.system.userdata.contractId;
                                }

                                self[urlData.method](urlData, { body: bodyParams },
                                    { headers: { moduleId: userService.system.selectedModuleId } })
                                    .then(function (r) {
                                        //console.error("select", field, r);

                                        field.isLoaded = true;

                                        $timeout(function () {
                                            // field.options = [];
                                            field.focusSearch = true;
                                            var ret = parseDatasource(((r || {}).data || []), field.selectOptions);
                                            if (field.optionsPage > 1) {

                                                field.options = [...field.options, ...ret.options].reduce((accumulator, currentValue) => {
                                                    const existingObject = accumulator.find(item => item.key === currentValue.key);
                                                    if (!existingObject) {
                                                        accumulator.push(currentValue);
                                                    }
                                                    return accumulator;
                                                }, [])

                                                field._lookupOptions = { ...field._lookupOptions, ...ret.lookupOptions };
                                                field._mapOptions = { ...field._mapOptions, ...ret.mapOptions };
                                            }
                                            else {
                                                field.options = ret.options;
                                                field._lookupOptions = ret.lookupOptions;
                                                field._mapOptions = ret.mapOptions;
                                            }
                                            field.optionsShowLoadMore = (r || {}).records > 0 && ((r || {}).records || 0) > (field.options || []).length;
                                        }, 0);

                                    }).catch(function (e) {
                                        Message.dberror(e);
                                    }).finally(function () {
                                        $timeout(function () {
                                            field.loading = false;

                                            $timeout(function () {
                                                field.focusSearch = false;

                                            }, 300);
                                        }, 1);
                                    })
                            }

                            if (this.type == "radio" && (this.dataSourceId || this.relationId)) {
                                this._getDataSources();
                            }

                            this._selectOpen = function () {
                                var field = this;
                                this._clearErrors();
                                if (field.dataSourceId || field.relationId) {
                                    field._getDataSources();
                                } else {
                                    $timeout(function () {
                                        field.focusSearch = true;
                                        $timeout(function () {
                                            field.focusSearch = false;
                                        }, 300);
                                    }, 300);
                                }
                            }

                            if (this.parentFieldDataSourceId) {
                                // this.warningMsg = "This field has " + self.fieldsList[fieldObj.parentFieldId].label +" as a parent."
                                Object.defineProperty(this, 'warningMsg', {
                                    get: function () {
                                        if(self.fieldsList[fieldObj.parentFieldId] && !fieldObj._value)
                                            return "This field has " + self.fieldsList[fieldObj.parentFieldId].label + " as a parent.";
                                        else
                                            return null;
                                    }
                                });
                            }

                            // all selects related

                            this.onSelectClose = function () {
                                if ((this.dataSourceId || this.relationId) && (this.childFieldIds || []).length) {
                                    for (var i = 0; i < this.childFieldIds.length; i++) {
                                        self.fieldsList[this.childFieldIds[i]]._value = self.fieldsList[this.childFieldIds[i]].multiple ? [] : null;
                                        self.fieldsList[this.childFieldIds[i]].isLoaded = false;
                                        self.fieldsList[this.childFieldIds[i]].options = [];
                                    }
                                }
                            }

                            this.setFocus = function () {
                                this.focus = true;
                            }

                            this._canDisplayInViewer = function (o) {
                                return PSPDFKIT_CONFIG.MIME_TYPES.includes(o.mimeType) || OFFICE_MIME_TYPES.includes(o.mimeType);
                            }

                            this._canDisplayInBrowser = function (o) {
                                return MIME_TYPES.PREVIEWABLE.includes(o.mimeType);
                            }

                            if (this.type == 'attachment') {
                                this._open = function (o) {
                                    self.attachmentHandler.open(o);
                                }

                                this._openInNewTab = function (o) {
                                    self.attachmentHandler.openInNewTab(o);
                                }

                                this._save = function (o) {
                                    self.attachmentHandler.save(o);
                                }
                            }

                            if (this.type == 'contact') {
                                self.set_contactField(this);
                            }
                            if (this.type == 'chips') {
                                self.set_chipsField(this);
                            }

                            if (this.type === 'submodule') {
                                var subModuleField = this;
                                if (!subModuleField._value) subModuleField._value = [];
                                subModuleField.obj = angular.copy(obj);
                                this.WorkflowScreen = function (o) {
                                    this.moduleId = o.moduleId || null;
                                    this.entityInstanceId = o.entityInstanceId || 0;
                                    this.transitionId = obj.transitionId || 0;
                                    this.relationId = o.relationId || null;
                                    this.parentEntityInstanceId = o.parentEntityInstanceId;
                                    this.preloaded = o.preloaded || null;
                                    this.propertiesKey = o.propertiesKey || "id";

                                    this.data = this.preloaded ? o.data : {};
                                    this.properties = {};
                                    this.transitions = [];
                                    this.loaded = false;
                                    this.loadingTransitions = false;

                                    var self = this;
                                    Object.defineProperty(this, 'valuesDictionary', {
                                        get: function () {
                                            var dictionary = {};
                                            dictionary.entityInstanceId = self.entityInstanceId;
                                            for (var key in self.properties) {
                                                if (self.properties.hasOwnProperty(key) && !key.includes('_aggregateValue')) {
                                                    dictionary[key.toString()] = userService.formatDateToIsoBasedOnPickerType(value, self.form.fieldsList[key]?.type);
                                                }
                                            }
                                            return dictionary;
                                        }
                                    });
                                };
                                this.WorkflowScreen.prototype = obj.WorkflowScreenPrototype;

                                this.WorkflowScreen.prototype.delete = function (i) {
                                    subModuleField._value.splice(i, 1);
                                }

                                this._addScreen = function (_screenData) {
                                    var self = this;
                                    var _screenData = _screenData || { entityInstanceId: 0, values: [] };

                                    var eId = _screenData.entityInstanceId || 0;

                                    // fields value mapping
                                    var _screenDataLookup = {};
                                    for (var i = 0; i < _screenData.values.length; i++) {
                                        _screenDataLookup[_screenData.values[i].id] = _screenData.values[i].value;
                                    }
                                    var _screenFields = angular.copy(obj.options);
                                    if (_screenFields.length) {
                                        for (var i = 0; i < _screenFields.length; i++) {
                                            if (_screenFields[i].id && typeof _screenDataLookup[_screenFields[i].id] !== 'undefined')
                                                _screenFields[i].value = _screenDataLookup[_screenFields[i].id];
                                        }
                                    }
                                    // console.log('fields with value', _screenFields);
                                    var screen = new subModuleField.WorkflowScreen({
                                        moduleId: self.obj.SubModuleId,
                                        relationId: self.obj.relationId,
                                        parentEntityInstanceId: self.obj.parentEntityInstanceId,
                                        entityInstanceId: eId,
                                        preloaded: true,
                                        propertiesKey: "fieldName",
                                        data: {
                                            fields: _screenFields,
                                            manualAssignUsers: []
                                        }
                                    });
                                    screen.init().then(function () {
                                        screen.form.editMode = subModuleField._editMode;
                                    });

                                    return screen;
                                };

                                this._add = function () {
                                    this._value.push(this._addScreen());
                                }

                                this._openMenu = function ($mdMenu, ev) {
                                    // originatorEv = ev;
                                    // console.log('clicked', $mdMenu);
                                    $mdMenu.open(ev);
                                };

                                this.screens = [];
                                if (subModuleField._value.length) {
                                    // add previous items.
                                    var screenData = angular.copy(subModuleField._value);
                                    subModuleField._value = [];
                                    for (var i = 0; i < screenData.length; i++) {
                                        this._value.push(this._addScreen(screenData[i]));
                                    }
                                }
                            }

                            if (this.type === "table") {
                                this.loading = true;

                                var get_columnDatasources = function (field) {
                                    var p = $q.defer();
                                    var urlData = URI.FIELD_DEFINITION.DATASOURCE_LIST;
                                    var value = field.value && Object.prototype.toString.call(field.value) != '[object Array]' ? [field.value] : field.value;
                                    var bodyParams = {
                                        selectedIds: value || [],
                                        search: {
                                            criteria: "",
                                            isCurrent: false, page: 1, pagesize: MAX_NR_VISIBLE_SELECT_ITEMS
                                        }
                                    }
                                    bodyParams.id = field.dataSourceId;
                                    bodyParams.contractId = userService.system.userdata.contractId;

                                    self[urlData.method](urlData, { body: bodyParams },
                                        { headers: { moduleId: userService.system.selectedModuleId } })
                                        .then(function (r) {
                                            field.columnDatasource = r.data || [];
                                            p.resolve(true);

                                        }).catch(function (e) {
                                            Message.dberror(e);
                                            p.resolve(false);
                                        })

                                    return p.promise;
                                }

                                var getValues = function () {
                                    var p = $q.defer();
                                    var urlData = URI.MODULE.GET_TABLE_VALUES;
                                    var urlParams = {
                                        entityInstanceId: self.entityInstanceId,
                                        fieldId: fieldObj.fieldId,
                                        contractId: userService.system.userdata.contractId
                                    };
                                    if (!fieldObj._editMode && self.isDraftState)
                                        urlParams.getDisplayValue = true;
                                    self[urlData.method](urlData, {
                                        url: urlParams, urltype: "obj"
                                    })
                                        .then(function (r) {
                                            fieldObj._value = (r || {}).data || [];
                                            p.resolve();
                                        })
                                        .catch(function (e) { p.reject(); Message.dberror(e); })

                                    return p.promise;
                                }

                                var getFields = function (isTakeDefaults) {
                                    self.get_tagg_users_dict()
                                        .then(function (taggUsers) {

                                            fieldObj._defaultValues = [{}];
                                            var urlData = URI.FIELD_DEFINITION.ASSIGNED_FIELDS;
                                            if (isTakeDefaults) fieldObj._value = [{}];
                                            self[urlData.method](urlData, {
                                                url: { id: fieldObj._fieldProperty, entityInstanceId: self.entityInstanceId }, urltype: "obj"
                                            })
                                                .then(function (r) {
                                                    var datasources = [];
                                                    var hasValues = false;
                                                    if (r.length) {
                                                        var parts = 0;
                                                        for (var i = 0; i < r.length; i++) {
                                                            parts += r[i].width;
                                                            fieldObj._defaultValues[0][r[i].id] = r[i].value && Object.prototype.toString.call(r[i].value) != '[object Array]' ? r[i].value : angular.copy(r[i].value);

                                                            if (isTakeDefaults) fieldObj._value[0][r[i].id] = r[i].value;

                                                            if (fieldObj._editMode && r[i].dataSourceId) {
                                                                datasources.push(get_columnDatasources(r[i]));
                                                            }

                                                            if (r[i].displayTypeId == 9)
                                                                r[i].hintsTagg = taggUsers;
                                                        }
                                                        for (var i = 0; i < r.length; i++) {
                                                            r[i].widthPercentage = 100 / parts * r[i].width;
                                                        }
                                                    }

                                                    if (fieldObj._editMode)
                                                        $q.all(datasources)
                                                            .then(function () {
                                                                fieldObj.options = r;
                                                                fieldObj.loading = false;
                                                            })
                                                    else {
                                                        fieldObj.options = r;
                                                        fieldObj.loading = false;
                                                    }
                                                })
                                                .catch(function (e) { Message.dberror(e) })
                                        });
                                }
                                var isTakeDefaults = false;

                                if (self.entityInstanceId && (fieldObj._editMode || self.isDraftState))
                                    getValues()
                                        .then(function () {
                                            if ((fieldObj._value || []).length == 1 && !fieldObj._value[0]) fieldObj._value = [];
                                            if (fieldObj._editMode && !(fieldObj._value || []).length) isTakeDefaults = true;
                                            getFields(isTakeDefaults);
                                        })
                                else {
                                    if ((fieldObj._value || []).length == 1 && !fieldObj._value[0]) fieldObj._value = [];
                                    if (fieldObj._editMode && !(fieldObj._value || []).length) isTakeDefaults = true;
                                    getFields(isTakeDefaults);
                                }


                                this._addTableRow = function () {
                                    if (!this._value) this._value = angular.copy(this._defaultValues);
                                    var row = {
                                        entityInstanceId: 0
                                    };
                                    for (var i = 0; i < this.options.length; i++) {
                                        row[this.options[i].id] = this._defaultValues[0][this.options[i].id] || null;
                                    }
                                    this._value.push(row);
                                    // console.log('pushed', row);
                                };
                            }
                        });

                        self.fieldsList[property] = field;
                        self.fieldsOrder.push(property);
                    } catch (e) {
                        console.error('form warning: field ' + key + ' could not be processed:', e);
                    }
                }
            }


        } else {
        }

        // console.log('description binded to data:', self);
    };

    Form.prototype.get_tagg_users_dict = function () {
        var p = $q.defer();
        /*
            * This is hidden now to exclude tag users for table fields
            * Tag functionality works now only for editors fields, not in table
            * 
        var self = this;
        var dataURL = URI.MODULE.TAGG_USERS_DICT;
        var urlParams = {
            contractId: userService.system.userdata.contractId || 0
        };
        self[dataURL.method](dataURL, { url: urlParams, urltype: 'obj' }, { headers: { 'moduleId': userService.system.selectedModuleId } })
            .then(function (r) {
                p.resolve(r);
            })
            .catch(function (e) {
                Message.dberror(e);
                console.error(e);
                p.reject(e);
            });
        */
        p.resolve();
        return p.promise;
    }

    Form.prototype.set_chipsField = function (field) {
        field.transformChip = function (chip) {
            var chipObj = {};
            chipObj[field.optionsValueProperty] = chip;
            chipObj[field.optionsKeyProperty] = 0;

            return chipObj;
        };
        field.onRemovedChip = function (chip) {
            if (chip.disableDelete) {
                field._value.push(chip);
            }
        };
    }

    Form.prototype.set_contactField = function (field) {
        field.readonly = false;
        field.selectedItem = null;
        /*
            searchValue is cleared by a watcher in formfield.js whenever the _value property is updated

            md-contact-chips is very buggy. Currently, validation works well. But if a user selects the "invalid" contact
            with their mouse and then hits backspace, the change is not detected. The backspace key must be used. 
        */
        field.searchValue = null;
        field.querySearch = querySearch;
        field.selectedItemChange = selectedItemChange;
        field.optionsToSearch = loadOptions();
        field.process_model = function (data) {
            return data;
        }
        //field.selectedVegetables = [];
        //field.numberChips = [];
        //field.numberChips2 = [];
        field.numberBuffer = '';
        field.autocompleteDemoRequireMatch = true;
        field.transformChip = transformChip;

        /**
         * Return the proper object when the append is called.
         */
        function transformChip(chip) {
            // If it is an object, it's already a known chip
            if (angular.isObject(chip)) {
                return chip;
            }

            // Otherwise, create a new one
            return { name: chip, code: 'new' }
        }

        /**
         * Search for options.
         */
        function querySearch(query) {
            var results = query ? field.optionsToSearch.filter(createFilterFor(query)).slice(0, 49) : [];

            // Used to determine whether a value was left in the input field that was not transformed into a chip
            // AKA not a contact or user
            field.searchValue = query != null && query.length ? query.trim() : null;

            return results;
        }

        /**
         * Create filter function for a query string
         */
        function createFilterFor(query) {
            var lowercaseQuery = angular.lowercase(query);

            return function filterFn(option) {
                return (option._lowername.indexOf(lowercaseQuery) !== -1) ||
                    (option._lowertype.indexOf(lowercaseQuery) !== -1);
            };

        }

        function loadOptions() {
            var options = field.options;
            //console.error('options', options)

            return options.map(function (opt) {
                opt._lowername = opt.name.toLowerCase();
                opt._lowertype = opt.email.toLowerCase();
                if (!opt.isSelected) return opt;
            });
        }

        function selectedItemChange(object, model) {
            if (object) {
                object.isSelected = true;
            }
        }
    }

    Form.prototype.setTemplate = function (template, pattern) {
        var self = this;
        var template = template || 'flow';
        var pattern = pattern || [];

        self.pattern = null;
        if (self.setPattern[template])
            self.pattern = self.setPattern[template](self.fieldsList, pattern);
        self.display = template;

        return;
    }

    Form.prototype.setPattern = {
        grid: function (fields, pattern) {
            var pattern = pattern || [];
            var result = [];

            for (var i = 0; i < pattern.length; i++) {
                var partials = [];
                for (var key in pattern[i]) {
                    if (fields[key]) {
                        partials.push({
                            name: key,
                            width: pattern[i][key]
                        });
                    }
                }
                result.push(partials);
            }

            return result;
        }
    }

    Form.prototype.store_Data = function () {
        // var storedData = angular.copy(this.data);
        // if (storedData.form) delete storedData.form;
        this.storedData = angular.copy(this.data);
        this.originalData = angular.copy(this.data);

        return;
    }

    Form.prototype.restore_Data = function () {
        if (this.storedData) {
            for (var key in this.storedData) {
                if (this.storedData.hasOwnProperty(key))
                    this.data[key] = this.storedData[key];
            }

        }
        this.clearErrors();

        return;
    }

    Form.prototype.clearErrors = function () {
        var self = this;
        self.isValid = true;

        for (var key in self.fieldsList) {
            if (self.fieldsList.hasOwnProperty(key)) {
                self.fieldsList[key]._clearErrors();
            }
        }
    }

    Form.prototype.catch = function (e) {
        var self = this;
        var globals = {
            messages: {}
        };

        if (e.data && e.data.messages) {
            if (Object.prototype.toString.call(e.data.messages) == '[object Array]') {
                Message.dberror(e);
            } else {
                for (var key in e.data.messages) {
                    // check if key is global
                    if (key == Message.constants.NO_PROPERTY || !self.description[key] && !self.lookup[key]) {
                        globals.messages[key] = e.data.messages[key];
                    } else {
                        // set key field on error, display error message
                        // TODO: translated dictionary of errors on userService
                        self.setFieldInvalid(key, e.data.messages[key][0].message);
                    }
                }

                Message.dberror(globals);
            }
        }
    }

    //--

    Form.prototype.process_map = function (obj) {
        var self = this;
        self.processedMap = [];
        if (obj.length) {
            for (var i = 0; i < obj.length; i++) {
                if (typeof obj[i] == "number") {
                    if (obj[i] == 1 || obj[i] <= 0) {
                        self.processedMap.push(1);
                    } else if (obj[i] > 4) {
                        self.processedMap.push(4);
                        // console.log("obj[i] 4", obj[i]);
                    }
                    else {
                        self.processedMap.push(obj[i]);
                    }
                } else if (Object.prototype.toString.call(obj[i]) == "[object Array]") {
                    var sum = 0;
                    for (var j = 0; j < obj[i].length; j++) {
                        if (typeof obj[i][j] == "number") sum += obj[i][j];
                        else {
                            sum = false;
                            break;
                        }
                    }
                    if (typeof sum == "number" && sum <= 12) {
                        self.processedMap.push(obj[i]);
                    } else if (typeof sum == "number" && sum > 12) {
                        var temp = [];
                        for (var j = 0; j < obj[i].length; j++) {
                            temp.push((Math.floor((obj[i][j] * 100 / sum) * 0.12)));
                        }
                        self.processedMap.push(temp);
                    } else {
                        for (var j = 0; j < obj[i].length; j++) {
                            self.processedMap.push(1);
                        }
                    }
                    // console.log("processedMap ", self.processedMap);
                }
            }
        }
    }

    Form.prototype.set_classes = function (obj) {
        var self = this;
        self.labelWidth = {
            xs: null,
            sm: null,
            md: 4,
            lg: 2
        };

        if (typeof obj.labelWidth != 'undefined') {
            self.labelWidth.xs = (typeof obj.labelWidth.xs != 'undefined' &&
                parseInt(obj.labelWidth.xs) > 0 && parseInt(obj.labelWidth.xs < 12)) ? parseInt(obj.labelWidth.xs) : null;
            self.labelWidth.sm = (typeof obj.labelWidth.sm != 'undefined' &&
                parseInt(obj.labelWidth.sm) > 0 && parseInt(obj.labelWidth.sm) < 12) ? parseInt(obj.labelWidth.sm) : null;
            self.labelWidth.md = (typeof obj.labelWidth.md != 'undefined' &&
                parseInt(obj.labelWidth.md) > 0 && parseInt(obj.labelWidth.md) < 12) ? parseInt(obj.labelWidth.md) : null;
            self.labelWidth.lg = (typeof obj.labelWidth.lg != 'undefined' &&
                parseInt(obj.labelWidth.lg) > 0 && parseInt(obj.labelWidth.lg) < 12) ? parseInt(obj.labelWidth.lg) : null;
        }

        self.labelClass = "";
        if (self.labelWidth.xs) self.labelClass += ' col-xs-' + self.labelWidth.xs;
        if (self.labelWidth.sm) self.labelClass += ' col-sm-' + self.labelWidth.sm;
        if (self.labelWidth.md) self.labelClass += ' col-md-' + self.labelWidth.md;
        if (self.labelWidth.lg) self.labelClass += ' col-lg-' + self.labelWidth.lg;

        self.inputClass = "";
        if (self.labelWidth.xs) self.inputClass += ' col-xs-' + (12 - self.labelWidth.xs);
        if (self.labelWidth.sm) self.inputClass += ' col-sm-' + (12 - self.labelWidth.sm);
        if (self.labelWidth.md) self.inputClass += ' col-md-' + (12 - self.labelWidth.md);
        if (self.labelWidth.lg) self.inputClass += ' col-lg-' + (12 - self.labelWidth.lg);

    };

    Form.prototype.set_formError = function (e) {
        var self = this;
        self.initializing = false;
    }

    Form.prototype.get_Layout = function () {
        var self = this;
        var p = $q.defer();

        try {
            self.get(self.layoutURL, self.layoutParams).then(function (result) {
                self.layoutResponse = result;

                if (result && result.detail && result.detail.length) {
                    for (var i = 0, fields = result.detail; i < fields.length; i++) {
                        self.set_Field(fields[i]);
                    }
                }

                p.resolve();
            }).catch(function () {
                p.reject();
            });
        } catch (e) {
            p.reject();
            console.error(e);
        }

        return p.promise;
    }

    Form.prototype.get_Data = function () {
        var self = this;
        var p = $q.defer();

        if (self.fields.length) {
            try {
                self.get(self.dataURL, self.dataParams).then(function (result) {
                    self.dataResponse = result;
                    var wrapper = result;
                    if (self.wrapper.length) {
                        for (var i = 0; i < self.wrapper.length; i++) {
                            wrapper = wrapper[self.wrapper[i]];
                        }
                    }

                    for (var i = 0; i < self.fields.length; i++) {
                        var path_to_field_value = wrapper[self.fields[i].name];

                        if (typeof path_to_field_value != 'undefined') {
                            self.fields[i].value = path_to_field_value;
                        }
                    }

                    p.resolve();
                }).catch(function () {
                    p.reject();
                });
            } catch (e) {
                self.dataResponse = null;
                p.reject();
                console.error(e);
            }
        } else {
            p.resolve();
        }

        return p.promise;
    }

    Form.prototype.set_Field = function (obj) {
        var self = this;

        if (obj) {
            try {
                var field;
                var name = (typeof obj.name != 'undefined')
                    ? obj.name : (typeof obj.column != 'undefined')
                        ? obj.column : null;

                if (name) {
                    var type = (typeof obj.type != 'undefined') ? obj.type : "text";
                    var value = (type == 'checkbox') ? "0" : "";

                    field = new (function () {
                        var tempObj = this;

                        this.name = name;
                        this.label = (typeof obj.label != 'undefined') ? obj.label : "";
                        this.type = type;
                        this.options = (typeof obj.options != 'undefined') ? obj.options : null;
                        if (this.type == 'UIselect') this.group_by = obj.group_by || null;

                        if (typeof obj.bindProperty != 'undefined') {
                            Object.defineProperty(this, 'value', {
                                get: function () {
                                    return obj.bindProperty;
                                },
                                set: function (setValue) {
                                    obj.bindProperty = setValue;
                                }
                            });
                        } else {
                            this.value = value;
                        }

                        this.validation = (typeof obj.validation != 'undefined') ? obj.validation : [];
                        this.isValid = true;
                        this.storedValue = null;
                        this.errorMsg = null;
                        this.required = (this.validation.indexOf('required') == -1) ? false : true;

                        if (this.type == 'select' || this.type == 'UIselect' || this.type == 'contact') {
                            this.hasEmptyValue = false;
                            this.lookupOptions = [];

                            if (this.options.length) {
                                for (var i = 0; i < this.options.length; i++) {
                                    this.lookupOptions[this.options[i].Key] = this.options[i].Val;
                                    if (typeof this.options[i].Key == 'string' && this.options[i].Key == "")
                                        this.hasEmptyValue = true;
                                }
                            }

                            // this.valueToString = (typeof this.lookupOptions[value] != 'undefined') ?
                            // this.lookupOptions[value] : "";

                            Object.defineProperty(this, 'valueToString', {
                                get: function () { return this.lookupOptions[tempObj.value]; }
                            });
                        }

                    });
                    self.fields.push(field);
                }
            } catch (e) {
                console.error(e);
            }
        }
    }

    Form.prototype.set_Fields = function (list) {
        var self = this;
        // console.log('list:', list)
        self.grouped = [];
        if (list) {
            self.fields = [];
            if (self.formMap) {
                var fieldIndex = 0;
                try {
                    for (var i = 0; i < self.formMap.length; i++) {

                    }
                    for (var i = 0; i < list.length; i++) {

                        self.set_Field(list[i]);
                    }
                    // console.log('self.fields:', self.fields);
                    self.group_Fields(self.fields);
                    self.labelClass = "";
                    self.inputClass = "";
                    // console.log("grouped ", self.grouped);

                } catch (e) {
                    console.error(e);
                }
            } else {
                try {
                    for (var i = 0; i < list.length; i++) {

                        self.set_Field(list[i]);
                    }
                    self.grouped.push(self.fields);
                    // console.log('self.fields:', self.fields);
                    // console.log('self.grouped:', self.grouped);

                } catch (e) {
                    console.error(e);
                }
            }
        }
    }

    Form.prototype.group_Fields = function (list) {
        var self = this;
        var fieldIndex = 0;
        var keepCol;

        for (var i = 0; i < self.processedMap.length; i++) {
            if (typeof list[fieldIndex] != 'undefined') {
                if (self.mapKeep == null || self.mapKeep.indexOf(i) != -1) keepCol = true;
                else keepCol = false;

                if ((typeof self.processedMap[i]) == "number") {
                    if (self.processedMap[i] == 1) {
                        var temp = [];
                        list[fieldIndex].class = "col-sm-12";
                        if (keepCol) list[fieldIndex].class += " col-xs-12";
                        temp.push(list[fieldIndex]);
                        self.grouped.push(temp);
                        fieldIndex++;
                    } else if (self.processedMap[i] > 1) {
                        var temp = [];
                        for (var j = 0; j < self.processedMap[i]; j++) {
                            if (typeof list[fieldIndex] != 'undefined') {
                                list[fieldIndex].class = "col-sm-" + (12 / self.processedMap[i]);
                                if (keepCol) list[fieldIndex].class += " col-xs-" + (12 / self.processedMap[i]);
                                temp.push(list[fieldIndex]);
                                fieldIndex++;
                            } else break;
                        }
                        if (temp.length) self.grouped.push(temp);
                    }
                } else if (Object.prototype.toString.call(self.processedMap[i]) == "[object Array]") {
                    var temp = [];
                    for (var j = 0; j < self.processedMap[i].length; j++) {
                        if (typeof list[fieldIndex] != 'undefined') {
                            list[fieldIndex].class = "col-sm-" + self.processedMap[i][j];
                            if (keepCol) list[fieldIndex].class += " col-xs-" + self.processedMap[i][j];
                            temp.push(list[fieldIndex]);
                            fieldIndex++;
                        } else break;
                    }
                    if (temp.length) self.grouped.push(temp);
                }
            }
        }
        if (fieldIndex < list.length) {
            // console.log("insufficient maps!")
            for (var i = fieldIndex; i < list.length; i++) {
                var temp = [];
                list[i].class = "col-sm-12";
                temp.push();
                self.grouped.push(temp);
            }
        }
    }

    Form.prototype.store_Values = function () {
        var self = this;
        for (var i = 0; i < self.fields.length; i++) {
            self.fields[i].storedValue = self.fields[i].value;
        }
    }
    Form.prototype.restore_Values = function () {
        var self = this;
        for (var i = 0; i < self.fields.length; i++) {
            self.fields[i].value = self.fields[i].storedValue;
        }
    }

    Form.prototype.map_Dynamic_values = function () {
        var self = this;
        var response = {}, r = {}, o = r;

        // creating wrapper object
        if (self.wrapper.length > 1) {
            for (var i = 1; i < self.wrapper.length; i++) {
                o = o[self.wrapper[i]] = {};
            }
        }

        // adding fields to wrapper object
        for (var i = 0; i < self.fields.length; i++) {
            o[self.fields[i]['name']] = self.fields[i].value;
            if (self.fields[i].type == 'select' || self.fields[i].type == 'UIselect') {
                o[self.fields[i]['name'] + '_toString'] = self.fields[i].valueToString;
            }
        }

        // if we have a wrapped, adding additional wrapped elements that are not fields
        if (self.wrapper.length && self.dataResponse) {
            var wrapper = angular.copy(self.dataResponse);
            for (var i = 0; i < self.wrapper.length; i++) {
                wrapper = wrapper[self.wrapper[i]];
            }

            for (var key in wrapper) {
                if (typeof o[key] == 'undefined') {
                    o[key] = wrapper[key];
                }
            }

            // adding wrapped data to response
            response[self.wrapper[0]] = r;
        } else {
            if (self.wrapper.length) {
                response[self.wrapper[0]] = r;
            } else {
                response = r;
            }
        }

        // adding additional non-wrapped properties to response if dataResponse
        if (self.dataResponse) {
            for (var key in self.dataResponse) {
                if (typeof response[key] == 'undefined') {
                    response[key] = self.dataResponse[key];
                }
            }
        }

        return response;
    }

    Form.prototype.select = function (field, value) {
        try {

        }
        catch (e) {
            console.error(e);
        }
    }

    Form.prototype.UIselect = function (field, value) {
        try {

        }
        catch (e) {
            console.error(e);
        }
    }

    Form.prototype.newField = function (obj, name, label, type_desc, validation) {

        var retval = {};
        var label = label || name;
        var type = type_desc[0] || 'text';
        var options = type_desc[1] || [];
        var keys = type_desc[2] || {};
        var validation = validation || [];

        if (name) {
            retval = new (function () {
                this.name = name;
                this.label = label;
                this.validation = validation;
                this.type = type;

                if (this.type == 'select' || this.type == 'select_new_format' || this.type == 'UIselect' || this.type == "autocomplete") {
                    this.options = options;
                }

                if (this.type == 'UIselect') {
                    this.keys = keys;
                    if (typeof keys.option == 'undefined' ||
                        typeof keys.value == 'undefined') {

                        this.options = [];
                    } else {
                        var opts = [];
                        for (var i = 0; i < options.length; i++) {
                            opts.push({
                                option: options[i][keys.option],
                                value: options[i][keys.value],
                                description_value: (keys.description_value) ? options[i][keys.description_value] : "",
                                group_by: (keys.group_by) ? options[i][keys.group_by] : null
                            })
                        }

                        this.options = opts;
                    }
                    //this.group_by = keys.group_by;
                    //console.log("ui select keys are ", this.keys)
                }

                Object.defineProperty(this, 'bindProperty', {
                    get: function () { return obj[name] },
                    set: function (v) { obj[name] = v }
                });
            });
        }
        //console.log("new field ", retval);
        return retval;

    }

    Form.prototype._validate = function () {
        var self = this;
        self.isValid = true;

        for (var i = 0; i < self.fields.length; i++) {
            self.fields[i].isValid = true;
            self.fields[i].errorMsg = null;

            if (self.fields[i].validation.length) {
                for (var j = 0; j < self.fields[i].validation.length; j++) {
                    if (typeof self.fields[i].validation[j] == "string") {
                        if (self.fields[i].isValid && !self.validate_functions()[self.fields[i].validation[j]](self.fields[i])) {
                            self.isValid = false;
                            // console.log('Field "' + self.fields[i].label + '" with value "' + self.fields[i].value + '" failed validation function "' + self.fields[i].validation[j] + '"');
                        }
                    }

                    if (Object.prototype.toString.call(self.fields[i].validation[j]) == "[object Object]") {
                        var o = self.fields[i].validation[j];
                        if (self.fields[i].isValid &&
                            !self.validate_functions()[Object.keys(o)[0]](self.fields[i], o[Object.keys(o)[0]])) {
                            self.isValid = false;
                            // console.log('Field "' + self.fields[i].label + '" with value "' + self.fields[i].value + '" failed validation function "' + self.fields[i].validation[j] + '"');
                        }
                    }
                }
            }
        }
    }

    Form.prototype.validate = function () {
        var self = this;
        self.isValid = true;

        for (var key in self.fieldsList) {
            if (self.fieldsList.hasOwnProperty(key)) {
                self.fieldsList[key]._isValid = true;
                self.fieldsList[key]._errorMsg = null;

                // If contact field has a value in it that wasn't selected from the autocomplete dropdown
                if (self.fieldsList[key].type == 'contact' &&
                    self.fieldsList[key].searchValue &&
                    self.fieldsList[key].searchValue.length > 1 &&
                    (JSON.stringify(self.fieldsList[key].validation) === JSON.stringify({}) || !self.fieldsList[key].validation)) {

                    // Set error msg
                    self.fieldsList[key]._isValid = false;
                    self.fieldsList[key]._errorMsg = 'Recipient not found';
                    self.isValid = false;
                }

                if (self.fieldsList[key].validation) {
                    for (var v in self.fieldsList[key].validation) {
                        if (self.fieldsList[key].validation.hasOwnProperty(v)) {
                            // console.log('validation', v, self.fieldsList[key], self.fieldsList[key].validation[v])
                            if (self.fieldsList[key]._isValid && !self.validate_functions()[v](self.fieldsList[key], self.fieldsList[key].validation[v])) {
                                self.isValid = false;
                            }
                        }

                    }
                }
            }
        }
    }

    Form.prototype.setFieldInvalid = function (field, msg) {
        var self = this;
        var msg = msg || "This field is invalid";
        var identifier = self.fieldsList[field] ? field : self.lookup[field].id;
        if (self.fieldsList[identifier]) {
            self.isValid = false;
            self.fieldsList[identifier]._isValid = false;
            self.fieldsList[identifier]._errorMsg = msg;
        }

        return;
    }

    Form.prototype.isValidTableField = function (field) {
        // If table, proceed with table logic
        if (field.type == 'table' || field.type == 'weather') {
            if (field._value == null || field._value.length <= 0 || typeof (field._value) != typeof ([]))
                return false;

            // Table column names
            var columns = field.options;
            // Loop through list of table rows we want to validate
            for (var i = 0; i < field._value.length; i++) {
                // Loop through properties of table for each record for current row
                for (var j = 0; j < columns.length; j++) {
                    var col;
                    if (field._value[i].hasOwnProperty(columns[j].id)) {
                        col = field._value[i][columns[j].id];
                    } else {
                        col = field._value[i][columns[j].name];
                    }
                    var isValue;
                    // If datetimepicker
                    if (col != null && col._isAMomentObject) {
                        isValue = col._isValid;
                    } else {
                        isValue = col != null && col.toString().trim().length > 0;
                    }

                    // Returns false if no value for a cell in a table field
                    if (!isValue)
                        return false;
                }
            }
        }

        // Returns true if not table, or if no fields in table value array are invalid
        return true;
    }

    Form.prototype.validate_functions = function () {
        var self = this;

        return new function () {
            this.required = function (field, message) {
                if (!field.hasMultipleValues || field.type == 'select' || field.type == 'radio' || field.type == 'table' || field.type == 'weather') {
                    if (field.restrictionsLookup[3]) 
                        message = "This field needs to be checked."
                    var errorMsg = field.type == 'contact' && field.searchValue && field.searchValue.length > 0
                        ? field._value.length ? 'Recipient not found' : 'A CIPO user or contact is required'
                        : Object.prototype.toString.call(message) == '[object String]' ? message : 'This field is required';

                    if ((field.type == 'contact' && field.searchValue && field.searchValue.length > 1) || !self.isValidTableField(field) || field._value === "" || field._value == null || (Object.prototype.toString.call(field._value) == "[object Array]" && !field._value.length) || (!field._value && field.type == 'checkbox')) {
                        field._isValid = false;
                        field._errorMsg = errorMsg;
                        return false;
                    } else {
                        return true;
                    }
                } else {

                    var checkValid = function (value) {
                        var value = angular.copy(value);
                        if (value?.length) {
                            if (value[value.length - 1]) return true;
                            else {
                                value.pop();
                                checkValid(value);
                            }
                        } else return false;
                    }

                    if (checkValid(field._value)) return true;
                    else {
                        field._isValid = false;
                        field._multipleFieldErrors[0] = 'This field is required';
                        return false;
                    }
                }
            }

            this.requiredIfFieldHasValue = function (field, param) {
                if ((field._value === "" || field._value == null || (Object.prototype.toString.call(field._value) == "[object Array]" && !field._value.length))
                    &&
                    ((self.fieldsList[param]._value && Object.prototype.toString.call(self.fieldsList[param]._value) != "[object Array]")
                        || (Object.prototype.toString.call(self.fieldsList[param]._value) == "[object Array]" && self.fieldsList[param]._value.length))) {
                    field._isValid = false;
                    field._errorMsg = 'This field is required';
                    return false;
                } else {
                    return true;
                }
            }

            this.requiredIfFieldDoesNotHaveValue = function (field, param) {
                if ((field._value === "" || field._value == null || (Object.prototype.toString.call(field._value) == "[object Array]" && !field._value.length))
                    &&
                    ((self.fieldsList[param]._value && Object.prototype.toString.call(self.fieldsList[param]._value) != "[object Array]")
                        || (Object.prototype.toString.call(self.fieldsList[param]._value) == "[object Array]" && self.fieldsList[param]._value.length))) {
                    return true;
                } else {
                    field._isValid = false;
                    field._errorMsg = 'This field is required';
                    return false;
                }
            }

            this.isNumber = function (field) {
                if (!field.hasMultipleValues) {
                    if (field._value && !(!isNaN(parseFloat(field._value)) && isFinite(field._value))) {
                        field._isValid = false;
                        field._errorMsg = 'This field must be a number';
                        return false;
                    } else {
                        return true;
                    }
                } else return true;

            }

            this.isInteger = function (field) {
                if (!field.hasMultipleValues) {
                    if (field._value && field._value != parseInt(field._value)) {
                        field._isValid = false;
                        field._errorMsg = 'This field must be an integer';
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    var noErrors = true;
                    if ((field._value || []).length) {
                        for (var i = 0; i < field._value.length; i++) {
                            if (field._value[i] && field._value[i] != parseInt(field._value[i])) {
                                field._isValid = false;
                                field._multipleFieldErrors[i] = 'This field must be an integer';
                                noErrors = false;
                            }

                        }
                    }
                    return noErrors;
                }

            }

            this.minChars = function (field, param) {
                var value = field._value ? field._value.length : 0;
                if (value < param) {
                    field._isValid = false;
                    field._errorMsg = 'This field requires at least ' + param + ' characters';
                    return false;
                } else {
                    return true;
                }
            }

            this.maxChars = function (field, param) {
                var value = field._value ? field._value.length : 0;
                if (value > param) {
                    field._isValid = false;
                    field._errorMsg = 'This field can have maximum ' + param + ' characters';
                    return false;
                } else {
                    return true;
                }
            }

            this.equals = function (field, param) {
                if (field._value != param) {
                    field._isValid = false;
                    field._errorMsg = 'This field needs to be ' + param;
                    return false;
                } else {
                    return true;
                }
            }

            this.equalsField = function (field, param) {
                if (typeof self.fieldsList[param] != 'undefined' && field._value != self.fieldsList[param]._value) {
                    field._isValid = false;
                    field._errorMsg = self.fieldsList[param].label + ' does not match';
                    return false;
                } else {
                    return true;
                }
            }

            this.smallerThan = function (field, param) {
                if (field._value > param) {
                    field._isValid = false;
                    field._errorMsg = 'This field needs to be smaller than ' + param;
                    return false;
                } else {
                    return true;
                }
            }

            this.greaterThan = function (field, param) {
                if (field._value < param) {
                    field._isValid = false;
                    field._errorMsg = 'This field needs to be greater than ' + param;
                    return false;
                } else {
                    return true;
                }
            }

            this.dateAfter = function (field, param) {
                if (moment.isMoment(field._value)
                    && moment.isMoment(self.fieldsList[param]._value)
                    && !self.fieldsList[param]._value.isBefore(field._value, 'day')) {
                    field._isValid = false;
                    field._errorMsg = field.label + ' must be after ' + self.fieldsList[param].label;
                    return false;
                } else {
                    return true;
                }
            }

            this.email = function (field) {
                //var re = /^((".+?(?<!\\)"@)|(([0-9a-z]((\.(?!\.))|[-!#\$%&'\*\+/=\?\^`{}|~\w])*)(?<=[0-9a-z])@))((\[\d{1,3}.\d{1,3}.\d{1,3}.\d{1,3}\])|(([0-9a-z][-0-9a-z]*[0-9a-z]*.)+[a-z0-9][-a-z0-9]{0,22}[a-z0-9]))$/;
                //var test = re.test(String(field._value).toLowerCase());
                if (field._value) {
                    try {
                        var re = new RegExp('^((".+?(?<!\\\\)"@)|(([0-9a-z]((\\.(?!\\.))|[-!#\\$%&\'\\*\\+/=\\?\\^`{}|~\\w])*)(?<=[0-9a-z])@))((\\[\\d{1,3}.\\d{1,3}.\\d{1,3}.\\d{1,3}\\])|(([0-9a-z][-0-9a-z]*[0-9a-z]*.)+[a-z0-9][-a-z0-9]{0,22}[a-z0-9]))$');
                        var test = re.test(String(field._value).toLowerCase());

                        if (!test) {
                            field._isValid = false;
                            field._errorMsg = 'Please insert a valid email address';
                            return false;
                        } else {
                            return true;
                        }
                    }
                    catch (e) {
                        console.log('failed try', e)
                        return true;
                    }

                } else return true;



            }

            this.number = function (field) {
                if (isNaN(field._value) && field._value != null) {
                    field._isValid = false;
                    field._errorMsg = 'This value should be a number';
                    return false;
                } else {
                    return true;
                }
            }

            this.regExp = function (field, obj) {
                var pattern = obj.pattern || '/^(.*)$/';
                var message = obj.message || 'RegExp Error';
                var test = pattern.test(String(field._value));

                if (!test) {
                    field._isValid = false;
                    field._errorMsg = message;
                    return false;
                } else {
                    return true;
                }
            }
        }
    }

    Form.prototype.clearDirty = function () {
        var self = this;

        self.originalData = angular.copy(self.data);
        self.store_Data();
    };

    return Form;
});
