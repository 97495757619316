import { Component, inject, input } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { TranslateModule } from '@ngx-translate/core';

import { UserStore } from '../../signal-store';

@Component({
  selector: 'cipo-loading',
  standalone: true,
  imports: [FlexLayoutModule, TranslateModule, MatCardModule, MatProgressBarModule],
  templateUrl: './loading.component.html',
  styleUrl: './loading.component.scss',
})
export class LoadingComponent {
  userStore = inject(UserStore);
  loading = input(false);
}
