import { Component, inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { KeyValueType } from '../../../../models/common';
import { TenantSettingRow, TenantSettingValue } from '../../../../models/tenant';
import { TenantSettingsService } from '../../../../module/instance/tenant-settings/tenant-settings.service';

@Component({
  selector: 'app-tenant-setting-modal',
  templateUrl: './tenant-setting-modal.component.html',
  styleUrls: ['./tenant-setting-modal.component.scss'],
})
export class TenantSettingModalComponent implements OnInit {
  tenantSettingsService = inject(TenantSettingsService);
  translate = inject(TranslateService);
  dialogRef = inject(MatDialogRef<TenantSettingModalComponent>);
  tenantSetting: TenantSettingRow = inject(MAT_DIALOG_DATA);

  selectOptions: KeyValueType<any>[] = [];
  valueControl = new FormControl<TenantSettingValue>(null, Validators.required);

  ngOnInit(): void {
    const value =
      this.tenantSetting.type === 'select' ? this.tenantSetting.rawValue?.toString() : this.tenantSetting.rawValue;
    this.valueControl.setValue(value);
    this.updateControl();
  }

  updateControl(): void {
    switch (this.tenantSetting.type) {
      case 'rangePercent':
        this.valueControl.setValidators([Validators.required, Validators.min(0), Validators.max(100)]);
        break;
      case 'select':
        this.tenantSettingsService
          .getSettingOptions(this.tenantSetting.id)
          .subscribe(options => (this.selectOptions = options));
        break;
      default:
        break;
    }
  }

  onSave(): void {
    const value = this.valueControl.value;
    this.dialogRef.close(this.tenantSetting.type === 'boolean' ? +value : value);
  }
}
