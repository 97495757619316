import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule, effect } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import localeRo from '@angular/common/locales/ro';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { DateAdapter } from '@angular/material/core';
import { UpgradeModule } from '@angular/upgrade/static';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { MsalBroadcastService, MsalRedirectComponent } from '@azure/msal-angular';

import { CustomBreakPointsModule } from '../app-breakpoints.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { DemoModule } from './demo/demo.module';
import { LegacyModule } from './legacy/legacy.module';
import { ModuleDefinitionModule } from './module/definition/module-definition.module';
import { ModuleInstanceModule } from './module/instance/module-instance.module';
import { SystemModule } from './system/system.module';
import { LoadingComponent } from './system/loading/loading.component';
import { AuthInterceptor, LocaleDateAdapter, LocaleService } from './shared/services';

registerLocaleData(localeRo);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './src/assets/i18n/', `.json?v=${new Date().getTime()}`);
}

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserAnimationsModule,
    UpgradeModule,
    BrowserModule,
    SystemModule,
    ModuleDefinitionModule,
    ModuleInstanceModule,
    LegacyModule,
    DemoModule,
    LoadingComponent,
    CustomBreakPointsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AppRoutingModule,
  ],
  declarations: [AppComponent],
  providers: [
    MsalBroadcastService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useFactory: (_: TranslateService, localeService: LocaleService) => localeService.getLocale(),
      deps: [TranslateService, LocaleService],
    },
    {
      provide: DateAdapter,
      useClass: LocaleDateAdapter,
    },
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: 'AngularJsUserService',
      useFactory: (i: any) => i.get('angularJsService'),
      deps: ['$injector'],
    },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {
  constructor(public upgrade: UpgradeModule) {}

  ngDoBootstrap() {
    // Bootstrap the hybrid application
    this.upgrade.bootstrap(document.body, ['cipo'], { strictDi: true });
  }
}
