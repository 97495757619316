<div class="p-a-xl">
    <h2>Input with a clear button</h2>
    <div class="pageSection">
        <mat-form-field class="example-form-field" appearance="fill">
            <mat-label>Clearable input</mat-label>
            <input matInput type="text" [(ngModel)]="clearValue" />
            @if (clearValue) {
                <button *ngIf="clearValue" matSuffix mat-icon-button aria-label="Clear" (click)="clearValue = ''">
                    <mat-icon>close</mat-icon>
                </button>
            }
        </mat-form-field>
    </div>

    <h2>Input with a custom ErrorStateMatcher</h2>
    <div class="pageSection">
        <form class="example-form">
            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Email</mat-label>
                <input
                    type="email"
                    matInput
                    [formControl]="emailFormControl"
                    [errorStateMatcher]="matcher"
                    placeholder="Ex. pat@example.com" />
                <mat-hint>Errors appear instantly!</mat-hint>
                <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                    Please enter a valid email address
                </mat-error>
                <mat-error *ngIf="emailFormControl.hasError('required')">
                    Email is
                    <strong>required</strong>
                </mat-error>
            </mat-form-field>
        </form>
    </div>

    <h2>Input with hints</h2>
    <div class="pageSection">
        <form class="example-form">
            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Message</mat-label>
                <input matInput #message maxlength="256" placeholder="Ex. I need help with..." />
                <mat-hint align="start"><strong>Don't disclose personal info</strong></mat-hint>
                <mat-hint align="end">{{ message.value.length }} / 256</mat-hint>
            </mat-form-field>
        </form>
    </div>

    <h2>Inputs with prefixes and suffixes</h2>
    <div class="pageSection">
        <form class="example-form">
            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Telephone</mat-label>
                <span matPrefix>+1 &nbsp;</span>
                <input type="tel" matInput placeholder="555-555-1234" />
                <mat-icon matSuffix>mode_edit</mat-icon>
            </mat-form-field>
        </form>
    </div>
</div>
