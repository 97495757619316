import { cipo } from 'cipo';
import { RestrictionType, RestrictionValueType } from 'src/app/models/module/fields/enums';

cipo.controller('emailsController',
	function ($transition$, $scope, $q, $state, $http, Manager, Message, URI, $timeout, $window, Conversation, ACTIONS, userService, $mdDialog) {
		let params = $transition$.params();

		$scope.moduleName = userService.system.mNames[$state.current.code];
		$scope.module = userService.system.modules['C'];
        $scope.datetimeFilter = userService.userSettings().datetimeFormat;

		// Watch for changes to contract
		$scope.$watch(function () { return userService.system.userdata.contractId; }, function (newContractId, oldContractId) {
			// If contract changed
			if (newContractId != oldContractId) {
				// Get modules for new contract id
				userService.Modules(newContractId)
					.then(function (res) {
						// If user has correspondence permissions on this contract, just reload the data
						if (userService.system.modules['C']) {
							$scope.contractId = newContractId;
							$scope.loadEmailList();
							// If user does not have correspondence permissions on this contract, hit 404 pages
						} else {
							$scope.display = false;
							$state.go("tenant.404", { tenantIdentifier: params.tenantIdentifier });
						}
					});
			}
		}, true);

		// Prevent page from loading and show 404 page if user doesn't have permissions
		// (module won't be in user module list if no permissions)
		if (!$scope.module) {
			$scope.display = false;
			$state.go("tenant.404", { tenantIdentifier: params.tenantIdentifier });
			return;
		}

		$scope.display = true;

		// get live operations
		Object.defineProperty($scope, 'operations', {
			get: function () { return userService.getOperationsFor("C") }
		});

		$scope.currentFolderName = "";
		$scope.contractId = userService.system.userdata.contractId;

		$scope.folderId = typeof params.f != "undefined" && !isNaN(parseInt(params.f)) ? parseInt(params.f) : -1;
		$scope.emailId = typeof params.e != "undefined" && !isNaN(parseInt(params.e)) ? parseInt(params.e) : "";
		if ($scope.folderId == -1) $scope.currentFolderName = "Inbox";
		$scope.firstLoad = true;
		$scope.loading = false;
		$scope.view = "list";
		$scope.disableBtns = false;
		//$scope.moveToFolder = "";
		//$scope.writeMode = false;
		//$scope.newEmailView = false;
		$scope.soloDraftsConversations = [];
		$scope.soloDraftsIndexes = [];
		$scope.menuExpand = true;
		$scope.folderExpand = false;
		$scope.newFolderName = "";
		$scope.createFolderForm = false;
		$scope.showFolderForm = function () {
			$scope.createFolderForm = !$scope.createFolderForm;
		}
		$scope.folderToMoveId = "";
		$scope.expandFolders = function () {
			$scope.folderExpand = !$scope.folderExpand;
		}
		$scope.folderIds = [];
		$scope.folderNames = [];
		$scope.contractEmailFolderId = -7;
		$scope.defaultFolderIds = [-1, -2, -3, -4, -5, -6, $scope.contractEmailFolderId, -8];

		$scope.getMoreMenuActions = function () {
			var moreActions = [{
				alwaysOnTop: true,
				visible: true,
				name: 'Mark as Read',
				click: function (rows) {
					$scope.markRead($scope.sendList(rows));
				}
			},
			{
				alwaysOnTop: true,
				visible: true,
				name: 'Mark as Unread',
				click: function (rows) {
					// $scope.mark($scope.sendList(rows), "read", 0);
					$scope.markUnread($scope.sendList(rows));
				}
			},
			{
				alwaysOnTop: true,
				visible: true,
				name: 'Mark as Important',
				click: function (rows) {
					// $scope.mark($scope.sendList(rows), "important", 1);
					$scope.markImportant($scope.sendList(rows));
				}
			},
			{
				alwaysOnTop: true,
				visible: true,
				name: 'Mark as Unimportant',
				click: function (rows) {
					// $scope.mark($scope.sendList(rows), "important", 0);
					$scope.markUnimportant($scope.sendList(rows));
				}
			}];

			return $scope.folderId == -2 || $scope.folderId == -3 || $scope.folderId == -8
				? moreActions.slice(2)
				: moreActions;
		}

		$scope.defaultManagerActions = [
			{
				setProperties: ACTIONS.ARCHIVE,
				conditionOnTop: function () {
					if ($scope.folderId != -3 && $scope.folderId != -4 && $scope.folderId != -5 && $scope.folderId != -6 && $scope.folderId != -7) {
						return true;
					}
					else return false;
				},
				condition: function () {
					if ($scope.folderId != -3 && $scope.folderId != -4 && $scope.folderId != -5 && $scope.folderId != -6 && $scope.folderId != -7) {
						return true;
					}
					else return false;
				},
				click: function (rows) {
					$scope.moveToFolder($scope.sendList(rows), -5, "Archive");
				}
			},
			{
				setProperties: ACTIONS.MOVE,
				conditionOnTop: function () {
					if ($scope.folderId == -3 || $scope.folderId == -6 || $scope.folderId == -7 || $scope.folderId == -8) {
						return false;
					}
					else return true;
				},
				condition: function () {
					if ($scope.folderId != -6 && $scope.folderId != -7 && $scope.folderId != -8) {
						return true;
					}
					else return false;
				},
				isGroup: true,
				actions: [
					{
						//feed the repeater
						alwaysOnTop: true,
						visible: true,
						dynamicData: true,
						id: "id",
						name: "name",
						orderBy: "name",
						model: $scope.folderToMoveId,
						data: $scope.emailFolders_custom,
						additionalActions: [
							{   //extra actions
								alwaysOnTop: true,
								visible: true,
								name: 'Inbox',
								hasBorder: true,
								condition: function () {
									if ($scope.folderId != -1 && $scope.folderId != -6 && $scope.folderId != -7 && $scope.folderId != -8) return true;
									else return false;
								},
								click: function (rows) {
									$scope.moveToFolder($scope.sendList(rows), -1, 'Inbox');
								}
							},
							{   //extra actions
								alwaysOnTop: true,
								visible: true,
								name: 'Create New',
								hasBorder: true,
								condition: function () {
									return true;
								},
								click: function (rows) {
									$scope.moveToFolder($scope.sendList(rows), 0);
								}
							},
							{   //extra actions
								alwaysOnTop: true,
								visible: true,
								name: 'Manage Folders',
								condition: function () {
									if ($scope.emailFolders_custom.length) return true;
									else return false;
								},
								click: function (rows) {
									$scope.settings($scope.emailFolders_custom);
								}
							}
						],
						conditionOnTop: function (dataId) {
							if ($scope.folderId != dataId) {
								return true;
							}
							else return false;
						},
						click: function (rows, dataId) {
							$scope.moveToFolder($scope.sendList(rows), dataId);
						}
					}
				]
			},
			{
				setProperties: ACTIONS.DELETE,
				conditionOnTop: function () {
					if ($scope.folderId != -2 && $scope.folderId != -3 && $scope.folderId != -6 && $scope.folderId != -7) {
						return true;
					}
					else
						return false;
				},
				condition: function () {
					if ($scope.folderId != -2 && $scope.folderId != -3 && $scope.folderId != -6 && $scope.folderId != -7) {
						return true;
					}
					else
						return false;
				},
				click: function (rows) {
					if ($scope.folderId == -4) {
						$scope.delete($scope.sendList(rows));
					}
					else if ($scope.folderId != -6 && $scope.folderId != -7) {
						$scope.moveToFolder($scope.sendList(rows), -4, "Trash");
					}
				}
			},
			{
				setProperties: ACTIONS.DETAILS,
				visible: true,
				click: function (row) {
					$scope.showConversation(row.threadId, row.messageCount, row.draftsMessage);
				}
			},
			{
				setProperties: ACTIONS.DISCARD,
				visible: true,
				conditionOnTop: function () {
					if ($scope.folderId == -3) {
						return true;
					}
					else
						return false;
				},
				condition: function () {
					if ($scope.folderId == -3) {
						return true;
					}
					else
						return false;
				},
				click: function (rows) {
					$scope.discardBulk($scope.sendList(rows));
				}
			},
			{
				setProperties: ACTIONS.MORE,
				conditionOnTop: function () {
					return $scope.folderId != -6 && $scope.folderId != -7;
				},
				actions: $scope.getMoreMenuActions()
			}
		];

		$scope.printMe = function (conversation) {
			//$("#section-to-print").remove();
			if ($("#section-to-print").length != 0) {
				$("#section-to-print").remove();
			}
			var div = $('<div />').appendTo('body');
			div.attr('id', 'section-to-print');

			$scope.messageToPrint = conversation;

			$timeout(function () {
				$("#section-to-print").html($("#print-message").html());
				$window.print();
			}, 400)
			// $window.print();
		}

		$scope.createFolder = function (name) {
			if (name != "") {
				$scope.disableBtns = true;

				$http[URI.EMAIL.ADD_FOLDER.method](`${URI.EMAIL.ADD_FOLDER}?contractId=${$scope.contractId}`, { name: name })
					.then(function (result) {
						var newFolder = { id: result.data, name: name, unreadCount: 0 };
						$scope.emailFolders_custom.push(newFolder);
						$scope.createFolderForm = false;
						$scope.newFolderName = "";
						$scope.getFolders();
					}).catch(function (e) {
						Message.dberror("An error has occured. The folder was not created.");
					});
			}
		}

		$scope.getFolders = function () {
			var p = $q.defer();
			$http[URI.EMAIL.FOLDERS.method](`${URI.EMAIL.FOLDERS}?onlyFolders=true&contractId=${$scope.contractId}`)
				.then(function (result) {
					$scope.getFoldersCounts();
					$scope.folderIds = [];
					// $scope.emailFolders = result.data.data;
					var tempFolders = result.data.data;
					$scope.emailFolders_system = [];
					$scope.emailFolders_custom = [];

					for (var i = 0; i < tempFolders.length; i++) {
						var folders = $scope.defaultFolderIds.includes(tempFolders[i].id)
							? $scope.emailFolders_system
							: $scope.emailFolders_custom;
						folders.push(tempFolders[i]);
						$scope.folderIds.push(tempFolders[i].id);
						$scope.folderNames.push(tempFolders[i].name);
						if ($scope.folderId == tempFolders[i].id) $scope.currentFolderName = tempFolders[i].name;
					}
					$scope.defaultManagerActions[1].actions[0].data = $scope.emailFolders_custom;
					p.resolve(true);
				}).catch(function (e) {
					p.resolve(false);
				}).finally(function () {

				});
			return p.promise;
		}

		$scope.getFoldersCounts = function () {
			$http[URI.EMAIL.FOLDERS.method](`${URI.EMAIL.FOLDERS}?contractId=${$scope.contractId}`)
				.then(function (result) {
					userService.get_EmailCounts();
					var tempFolders = result.data.data;
					for (var i = 0; i < tempFolders.length; i++) {
						var folders = $scope.defaultFolderIds.includes(tempFolders[i].id)
							? $scope.emailFolders_system
							: $scope.emailFolders_custom;
						var index = folders.findIndex(function (x) { return x.id == tempFolders[i].id; });
						folders[index].unreadCount = tempFolders[i].unreadCount;
						folders[index].count = tempFolders[i].count;
					}
				}).catch(function (e) {
				})
		}

		$scope.menuToggle = function () {
			$scope.menuExpand = !$scope.menuExpand;
		}

		// expand message and move to the 2nd place if the case
		$scope.showMessage = function (status, index) {
			$scope.soloDraftsConversations[index].expand = !$scope.soloDraftsConversations[index].expand;
			if (!status && index < $scope.soloDraftsConversations.length - 2) {
				var currentIndex = $scope.soloDraftsIndexes.splice(index, 1)[0];
				var currentMessage = $scope.soloDraftsConversations.splice(index, 1)[0];

				$scope.soloDraftsConversations[$scope.soloDraftsConversations.length - 2].expand = false;
				$scope.soloDraftsIndexes.splice($scope.soloDraftsIndexes.length - 1, 0, currentIndex);
				$scope.soloDraftsConversations.splice($scope.soloDraftsConversations.length - 1, 0, currentMessage);
			}
		}

		$scope.loadConversation = function (conversation) {
			// $scope.manager.loading = true;
			params = { tenantIdentifier: userService.getTenantIdentifierById(userService.tenantId), f: $scope.folderId, e: conversation.id };
			$state.go('tenant.emails', params, { notify: false });

			$scope.conversation = conversation;
			$scope.getFoldersCounts();
			$scope.view = "showEmail";
		}

		// add/show conversation to conversations array
		$scope.showSingleConvo = function (id) {
			var temp = {};
			var initData = {
				id: id,
				operations: $scope.operations,
				folderId: $scope.folderId,
				moduleId: $scope.module.moduleId
			};

			temp = new Conversation(initData);
			if (id != null && id <= 0) {
				temp.contractId = userService.system.userdata.contractId;
			}
			temp.operations = $scope.operations;
			// check if opened
			if ($scope.soloDraftsIndexes.indexOf(id) == -1) {
				temp.init()
					.then(function (result) {
						$scope.soloDraftsConversations[0].emails[0].form.clearDirty();
						// watch if the form gets dirty
						var clearWatch = $scope.$watch(function () { return temp.emails[0].form.dirty; }, function (newParam, oldParam) {
							if (newParam) {
								$scope.save(temp, $scope.soloDraftsIndexes.length);
								// stop watch listener
								clearWatch();
							}
						});
						temp.templateWatch = $scope.$watch(function () { return temp.emails[0].properties.templateId; }, function (n, o) {
							//temp.emails[0].set_Template(n);
							if (n != o) temp.emails[0].set_Template(n, temp.emails[0].properties.contractId);
						});
						temp.contractWatch = $scope.$watch(function () { return temp.emails[0].properties.contractId; }, function (n, o) {
							//temp.emails[0].set_Template(n);
							if (n != o) temp.emails[0].reload_Recipients(n, $scope.module.moduleId);
						});
						if ((id == 0) && ($scope.soloDraftsConversations[0].emails[0].form.dirty == true)) {
							$scope.save(temp, $scope.soloDraftsIndexes.length);
						}
					})
					.catch(function (e) {
					});
				temp.expand = true;

				$scope.soloDraftsIndexes.push(id);
				temp.isModal = true;
				$scope.soloDraftsConversations.push(temp);
				$scope.showAsModal(temp, $scope.soloDraftsIndexes.length - 1);

			} else {
				var tempIndex = $scope.soloDraftsIndexes.indexOf(id);
				$scope.soloDraftsIndexes = $scope.soloDraftsIndexes.concat($scope.soloDraftsIndexes.splice(tempIndex, 1));
				$scope.soloDraftsConversations = $scope.soloDraftsConversations.concat($scope.soloDraftsConversations.splice(tempIndex, 1));
				$scope.soloDraftsConversations[$scope.soloDraftsConversations.length - 1].expand = true;

			}
			if ($scope.soloDraftsConversations.length > 1) {
				$scope.soloDraftsConversations[$scope.soloDraftsConversations.length - 2].expand = false;
			}
		}

		// show conversation
		$scope.showConversation = function (id, count, draftsMessage = '') {
			count = $scope.getTotalMessageCount(count, draftsMessage);

			if (draftsMessage && count == 1 || !id) {
				$scope.showSingleConvo(id || 0);
				return;
			}

			var temp = {};
			var initData = {
				id: id,
				operations: $scope.operations,
				folderId: $scope.folderId,
				moduleId: $scope.module.moduleId
			};

			temp = new Conversation(initData);
			temp.operations = $scope.operations;

			params = { tenantIdentifier: userService.getTenantIdentifierById(userService.tenantId), f: $scope.folderId, e: id };
			$state.go('tenant.emails', params, { notify: false });
		};

		// removes a conversation from the solo conversation array
		$scope.removeDraftConversation = function (conv, index) {
			conv.hideEdit = true;
			var tempIndex = $scope.soloDraftsIndexes.indexOf(conv.id);
			$scope.soloDraftsIndexes.splice(tempIndex, 1);
			$scope.soloDraftsConversations.splice(tempIndex, 1);

		}

		// updates a solo conversation on first save
		$scope.updateDraftConversation = function (conv, index, newId) {
			var tempIndex = $scope.soloDraftsIndexes.indexOf(conv.id);

			$scope.soloDraftsIndexes.splice(tempIndex, 1, newId);
			$scope.soloDraftsConversations[tempIndex].id = newId;
		}

		$scope.send = function (conv, index) {
			var isValidContractId = !isNaN(parseInt(conv.emails[0].properties.contractId)) && conv.emails[0].properties.contractId != 0;
			if (!isValidContractId) {
				Message.error('Please select a contract.');
				return;
			}

			conv.disableBtns = true;
			conv.emails[0].form.validate();
			var p = $q.defer();
			if (conv.emails[0].form.isValid) {
				if (conv.id) {
					conv.emails[0].send_draft()
						.then(function (result) {

							if (result) {
								conv.templateWatch();
								conv.contractWatch();
								$scope.removeDraftConversation(conv, index);
								if ($scope.folderId == -2 || $scope.folderId == -3) {
									$scope.loadEmailList();
								} else {
									$scope.getFoldersCounts();
								}

							} else {
								Message.dberror("An error has occured. Your message couldn't be sent.");
							}
							conv.disableBtns = false;
						})
						.catch(function () {

						});
				} else {
					conv.emails[0].send_orphan()
						.then(function (result) {

							if (result) {
								conv.templateWatch();
								conv.contractWatch();
								$scope.removeDraftConversation(conv, index);
								if ($scope.folderId == -2) {
									$scope.loadEmailList();
								}
							} else {
								Message.dberror("An error has occured. Your message couldn't be sent.");
							}
							conv.disableBtns = false;
						})
						.catch(function () {

						});
				}
			}
			else {
				p.reject();
				conv.disableBtns = false;
			}
		}

		$scope.save = function (conv, index) {
			var isValidContractId = !isNaN(parseInt(conv.emails[0].properties.contractId)) && conv.emails[0].properties.contractId != 0;
			if (!isValidContractId) {
				Message.error('Please select a contract.');
				return;
			}

			conv.disableBtns = true;
			conv.saving = true;
			if (conv.id == 0 || !conv.id) {
				conv.emails[0].save_new_draft()
					.then(function (result) {
						if (result) {
							conv.saving = false;
							$scope.updateDraftConversation(conv, index, result);
							conv.emails[0].properties.id == 0
								? result
								: conv.emails[0].properties.id;

							//to reload drafts and folders
							$scope.getFoldersCounts();
						} else {
							Message.dberror("An error has occured. Your message couldn't be saved.");
						}
						conv.disableBtns = false;
					})
					.catch(function (e) {
					});
			} else {
				conv.emails[0].save_existing_draft()
					.then(function (result) {
						conv.saving = false;
						if (result) {
							if ($scope.folderId == -3) {
								$scope.loadEmailList();
							}
						} else {
							Message.dberror("An error has occured. Your message couldn't be saved.");
						}
						conv.disableBtns = false;
					})
					.catch(function () {

					});
			}
		};

		$scope.close = function (conv, index, event) {
			if (event) {
				event.preventDefault();
				event.stopPropagation();
			}
			conv.disableBtns = true;
			conv.emails[0].save_existing_draft(true)
				.then(function (result) {
					if (result) {
						conv.templateWatch();
						conv.contractWatch();
						$scope.removeDraftConversation(conv, index);
						conv.saving = false;
						if ($scope.folderId == -3) {
							$scope.loadEmailList();
						}
					} else {
						Message.dberror("Your message can't be saved.");
						$scope.removeDraftConversation(conv, index);
					}

				})
				.catch(function (e) {
					console.error(e);
				})
				.finally(function () {
					conv.disableBtns = false;
				})
		}

		$scope.showAsModal = function (conv, index, event) {
			if (event) {
				event.preventDefault();
				event.stopPropagation();
			}
			conv.isModal = true;
			conv.operations = $scope.operations;
			conv.contractName = $scope.contractId ? userService.system.context.contract?.name : '';

			if (!$scope.contractId) {
				Message.warning("You don't have any contract assigned. You could use Compose only after you have at least one contract assigned.");
				return;
			}

			$mdDialog.show({
				locals: { conv: conv, isCorrespondencePage: true, senderId: userService.system.userdata.id },
				controller: 'editDraftController',
				templateUrl: '/ng/views/admin/modals/editDraft.html',
				parent: angular.element(document.body),
				targetEvent: event || null,
				fullscreen: true,
				escapeToClose: false,
				clickOutsideToClose: false
			})
				.then(function (conv) {
					// conv = conv;
					conv.isModal = false;
					if (!conv.expand) $scope.showMessage(conv.expand, index);
				}, function (res) {
					$scope.removeDraftConversation(conv, index);
					var msg = res.msg;
					switch (msg) {
						// When closing the dialog and the draft isn't saved
						case 'cancel':
							// Do nothing
							break;
						// When closing the dialog and the draft was saved
						case 'close':
							if (!params.e && $scope.folderId == -3) {
								$scope.loadData();
							}

							$scope.getFoldersCounts();
							break;
						// After sending (duh)
						case 'send':
							// If not on detail view, in sent, or in inbox and I sent to myself, load data
							if (!params.e && ($scope.folderId == -2 || $scope.folderId == -3 || ($scope.folderId == -1 && res.isSenderRecipient))) {
								$scope.loadData();
							}

							// Always update counts because now we have one less draft and we may have a new item in our inbox (from us)
							$scope.getFoldersCounts();
							break;
						// When delete draft button clicked
						case 'discard':
							// If draft folder
							if ($scope.folderId == -3) {
								$scope.loadData();
							}

							$scope.getFoldersCounts();
							break;
					}
				});
		}

		$scope.discard = function (conv, index) {
			conv.disableBtns = true;
			conv.emails[0].discard_draft()
				.then(function (result) {
					conv.templateWatch();
					conv.contractWatch();
					if (result) {
						$scope.removeDraftConversation(conv, index);
						if ($scope.folderId == -3) {
							$scope.loadEmailList();
						} else {
							$scope.getFoldersCounts();
						}
					} else {
						Message.dberror("An error has occured. Your draft couldn't be deleted.");
					}
					conv.disableBtns = false;
				})
				.catch(function () {

				});
		}

		$scope.cancel = function (conv, index) {
			for (var i = 0; i < conv.emails.length; i++) {
				if (conv.emails[i].form.dirty == false) {
					$scope.removeDraftConversation(conv, index);
				}
				else {
					$scope.close(conv, index);
				}
			}
		}

		$scope.stopStuff = function (e) {

			e.preventDefault();
			e.stopPropagation();
		}

		$scope.sendMail = function () {
			var email = $scope.getContractEmail();
			$scope.copyClipboard(email);
			$window.open("mailto:" + email, "_self");
		};

		$scope.contractEmailFolderExists = function () {
			return $scope.emailFolders_system && $scope.emailFolders_system.find(f => f.id == $scope.contractEmailFolderId) != null;
		}

		$scope.getContractEmail = function () {
			if ($scope.emailFolders_system) {
				var folder = $scope.emailFolders_system.find(f => f.id == $scope.contractEmailFolderId);
				if (folder)
					return folder.email;
			}
		}

		$scope.copyClipboard = function (emailId) {
			// create temp element
			var copyElement = document.createElement("span");
			copyElement.appendChild(document.createTextNode(emailId));
			copyElement.id = 'tempCopyToClipboard';
			angular.element(document.body.append(copyElement));

			// select the text
			var range = document.createRange();
			range.selectNode(copyElement);
			window.getSelection().removeAllRanges();
			window.getSelection().addRange(range);

			// copy & cleanup
			document.execCommand('copy');
			window.getSelection().removeAllRanges();
			copyElement.remove();

			Message.info('Email copied to clipboard.');
		}

		$scope.changeParams = function (id, e) {
			$scope.menuExpand = true;
			// $scope.loading = true;
			$scope.folderId = id && $scope.folderIds.indexOf(parseInt(id)) != -1 ? id : -1;
			$scope.currentFolderName = $scope.folderNames[$scope.folderIds.indexOf(parseInt(id))];

			params = { tenantIdentifier: userService.getTenantIdentifierById(userService.tenantId), f: $scope.folderId, e: e };
			$state.go('tenant.emails', params, { notify: false });
		}

		$scope.loadEmailList = function () {
			$scope.view = "list";
			$scope.loading = false;
			$scope.getFoldersCounts();
			$scope.loadData();
		}

		$scope.setCurrentFolder = function (id) {
			$scope.currentFolderName = $scope.folderNames[parseInt($scope.folderIds.indexOf(id))];
		}

		$scope.$watch(function () { return params; }, function (newParams, oldParams) {
			// $scope.view = "";
			if (typeof newParams.e == "undefined" || isNaN(parseInt(newParams.e))) {
				if ($scope.folderIds.length > 0) {
					if ($scope.folderIds.indexOf(parseInt(newParams.f)) != -1) {
						$scope.changeParams(newParams.f);
						//$scope.setCurrentFolder(newParams.f);
						$scope.loadEmailList();
					} else if (newParams.f != oldParams.f) {
						$scope.changeParams(-1);
						//$scope.setCurrentFolder(1);
						$scope.loadEmailList();
					}
				} else {
					$scope.getFolders()
						.then(function () {
							if ($scope.folderIds.indexOf(parseInt(newParams.f)) != -1) {
								//$scope.setCurrentFolder(newParams.f);
								$scope.loadEmailList();
							} else if (!$scope.emailId) {
								$scope.changeParams(-1);
								//$scope.setCurrentFolder(1);
							}
						})
						.catch(function () {

						})
				}
			} else {
				if ($scope.folderIds.length == 0) {
					$scope.getFolders();
				}
				var temp = {};
				var initData = {
					id: newParams.e,
					operations: $scope.operations,
					folderId: $scope.folderId,
					moduleId: $scope.module.moduleId
				};

				temp = new Conversation(initData);
				temp.operations = $scope.operations;
				$scope.loading = true;
				temp.init(true)
					.then(function (result) {
						if (result) {
							$scope.changeParams($scope.folderId, "");
							Message.dberror("The conversation doesn't exist.");
						} else {
							$scope.loadConversation(temp);
						}

					})
					.catch(function (reject) {
						$scope.changeParams($scope.folderId, "");
						Message.dberror("The conversation doesn't exist.");

					})
					.finally(function () {
						$scope.loading = false;
					});
			}
			// $scope.loading = false;

		}, true);

		$scope.settings = function (customFolders, ev) {
			$scope.menuToggle();
			$mdDialog.show({
				locals: { customFolderList: customFolders, operations: $scope.operations, module: $scope.module },
				controller: 'emailSettingsController',
				templateUrl: '/ng/views/admin/modals/emailSettings.html',
				parent: angular.element(document.body),
				targetEvent: ev,
				fullscreen: true,
				escapeToClose: false,
				clickOutsideToClose: false
			})
				.then(function (result) {

				}, function () {
					$scope.getFolders()
						.then(function () {
							$scope.manager.set_Actions($scope.getUpdatedManagerActions());
						});
					$scope.changeParams(-1);
				});
		};

		$scope.createNewFolder = function (systemFolders, customFolders, ev, isBrowserEvent = false) {

			var p = $q.defer();
			$scope.menuToggle();
			$mdDialog.show({
				locals: { item: { name: "" }, dataURL: URI.EMAIL.ADD_FOLDER, showIcons: false, otherParams: { contractId: $scope.contractId } },
				controller: 'renameItemController',
				templateUrl: '/ng/views/admin/modals/renameItem.html',
				parent: angular.element(document.body),
				targetEvent: ev,
				fullscreen: true,
				focusOnOpen: false,
				escapeToClose: false,
				clickOutsideToClose: false
			})
				.then(function (result) {
					if (isBrowserEvent) {
						$scope.getFolders()
							.then(function () {
								$scope.manager.set_Actions($scope.getUpdatedManagerActions());
							})
						p.resolve(result);
					} else {
						$scope.getFolders();
						p.resolve(result);
					}
				}, function () {
					p.resolve(false);
				});
			return p.promise;
		};

		// manager related
		$scope.sendList = function (data) {
			var listToSend = [];
			if (Object.prototype.toString.call(data) == '[object Array]') {
				if (data.length) {
					for (var i = 0; i < data.length; i++) {
						if (typeof data[i].threadId != 'undefined') {
							listToSend.push(data[i].threadId);
						}
						else if (typeof data[i].conversationId != 'undefined') {
							listToSend.push(data[i].conversationId);
						}
					}
				}
			} else {
				if (typeof data.threadId != 'undefined') {
					listToSend.push(data.threadId);
				}
				else if (typeof data.conversationId != 'undefined') {
					listToSend.push(data.conversationId);
				}
			}
			return listToSend;
		}

		$scope.getUpdatedManagerActions = function () {
			$scope.getFolders()
				.then(function () {
					$scope.defaultManagerActions[1].actions[0].data = $scope.emailFolders_custom;
					return $scope.defaultManagerActions;
				});
		}

		$scope.loadData = function () {
			$scope.manager = new Manager({
				objectsPerPage: 20,
				dataURL: URI.EMAIL.LIST,
				dataWrapper: "data",
				menuClass: 'correspondenceMenu',
				gridClass: 'correspondenceGrid',
				keepViews: true,
				noSort: true,
				//noTabletVersion: true,
				correspondenceMenu: true,
				noFilters: true,
				hasGlobalSearchToggle: true,
				otherParams: {
					FolderId: function () {
						return $scope.manager.isGlobalSearch ? undefined : $scope.folderId;
					},
					contractId: $scope.contractId
				},
				rowClass: function (row) {
					if (row.isRead) { return "readEmails"; }
					else return "unreadEmails";
				},
				// layoutOptions: [1,2],
				options: {
					multiselect: true,
					gridMultiselect: true,
					noGridCols: true,
					hasTableHeader: true
				},
				rowOnClick: function (row) {
					$scope.showConversation(row.threadId, row.messageCount, row.draftsMessage);
					$scope.markRead($scope.sendList(row), true);

				},
				parseData: function (data) {
					var data = data || [];

					for (var i = 0; i < data.length; i++) {
						// If in draft folder, display updatedOn. Otherwise, display dateSent.
						if ($scope.folderId == -3) {
							data[i].dateSent = data[i].updatedOn;
						}

						data[i].conversationCount = data[i].messageCount > 1 ? "(" + data[i].messageCount + ")" : "";
					}

					return data;
				},

				leftActions: [
					{
						setProperties: ACTIONS.REFRESH,
						click: function () {
							$scope.loadEmailList();
						}
					}
				],
				actions: $scope.defaultManagerActions
			});

			var self = this;

			var attachmentLookup = {
				false: { name: '', class: 'none' },
				true: { name: '<i class="mdi mdi-attachment"></i>', class: 'none' },
			}
			var statusLookup = {
				false: { name: '', class: 'none' },
				true: { name: '<i class="fa fa-exclamation"></i>', class: 'none' },
			}
			var columns = {
				1: [
					{ name: "isImportant", label: ' ', type: 'status', lookupArr: statusLookup, width: 5 },
					{
						name: ["displayName", "conversationCount", "draftsMessage"],
						label: self.folderId && (self.folderId == -1 || self.folderId == -7 || self.folderId == -8 || self.folderId == -4 || self.folderId == -5 || self.folderId > 0) ? 'From' : self.folderId && (self.folderId == -2 || self.folderId == -3) ? 'To' : 'Recipients', width: 20,
						type: 'conditional_joint',
						separators: [[], [], []],
						conditions: [[], [], []]
						// condition, compare against, display if condition false, display the value
					},
					{ name: ['subject', 'preview'], label: 'Subject', type: 'joint', width: 55 },
					{ name: 'hasAttachments', label: 'ATT', type: 'status', lookupArr: attachmentLookup, width: 5 },
					{ name: 'dateSent', label: 'Date', type: 'date', width: 10, restrictions: [{ key: RestrictionType.DateTime, value: RestrictionValueType.Date }] }
				],
				2: [

					{ name: 'dateSent', label: ' ', type: 'date' },
					{ name: 'hasAttachments', label: 'ATT', type: 'status', lookupArr: attachmentLookup },
					{
						name: ["displayName", "conversationCount", "draftsMessage"],
						label: ' ',
						type: 'conditional_joint',
						separators: [[], [" (", ") "], []],
						conditions: [[], [">", 1, "", true], []],
						// condition, compare against, display if condition false, display the value
					},
					{ name: ['subject', 'preview'], label: ' ', type: 'joint' },
				]
			};

			$scope.manager.set_Columns(columns);
			$scope.getFolders();

			$scope.manager.loadPage();
		};

		$scope.changeContr = function (option) {
			$scope.manager.page = 1;
			$scope.manager.otherParams.ContractId = option;
			$scope.manager.loadPage();
		}
		$scope.filterPage = function (option, param) {
			$scope.manager.page = 1;
			$scope.manager.otherParams[param] = option;
			$scope.manager.loadPage();
		}

		$scope.markRead = function (list, isTriggeredByRowClick = false) {
			var list = list || [];

			if ($scope.folderId == -2 || $scope.folderId == -3 || $scope.folderId == -6 || $scope.folderId == -8)
				return;

			if (list.length) {
				$scope.manager.loading = true;
				var data = {
					header: { "Content-type": "application/json; charset=utf-8" },
					body: list
				};

				// This URI already has a URI param at the end of it in constants.js, hence the missing question mark
				$scope.manager[URI.EMAIL.READ.method](`${URI.EMAIL.READ}&contractId=${$scope.contractId}`, data)
					.then(function () {
						if (!isTriggeredByRowClick) {
							$scope.manager.loadPage();
						}
						$scope.getFoldersCounts();
					})
					.catch(function (e) {
						$scope.manager.loading = false;
						Message.dberror(e);
					});
			} else {
			}
		}
		$scope.markUnread = function (list) {
			var list = list || [];

			if ($scope.folderId == -2 || $scope.folderId == -3 || $scope.folderId == -6)
				return;

			if (list.length) {
				$scope.manager.loading = true;
				var data = {
					header: { "Content-type": "application/json; charset=utf-8" },
					body: list
				};

				// This URI already has a URI param at the end of it in constants.js, hence the missing question mark
				$scope.manager[URI.EMAIL.UNREAD.method](`${URI.EMAIL.UNREAD}&contractId=${$scope.contractId}`, data)
					.then(function () {
						$scope.manager.loadPage();
						$scope.getFoldersCounts();
					})
					.catch(function (e) {
						$scope.manager.loading = false;
						Message.dberror(e);
					});
			} else {
			}
		}
		$scope.markImportant = function (list) {
			var list = list || [];

			if (list.length) {
				$scope.manager.loading = true;
				var data = {
					header: { "Content-type": "application/json; charset=utf-8" },
					body: list
				};
				$scope.manager[URI.EMAIL.IMPORTANT.method](`${URI.EMAIL.IMPORTANT}&folderId=${$scope.folderId}&contractId=${$scope.contractId}`, data)
					.then(function () {
						$scope.manager.loadPage();
					})
					.catch(function (e) {
						$scope.manager.loading = false;
						Message.dberror(e);
					});
			} else {
			}
		}
		$scope.markUnimportant = function (list) {
			var list = list || [];

			if (list.length) {
				$scope.manager.loading = true;
				var data = {
					header: { "Content-type": "application/json; charset=utf-8" },
					body: list
				};
				$scope.manager[URI.EMAIL.UNIMPORTANT.method](`${URI.EMAIL.UNIMPORTANT}&folderId=${$scope.folderId}&contractId=${$scope.contractId}`, data)
					.then(function () {
						$scope.manager.loadPage();
					})
					.catch(function (e) {
						$scope.manager.loading = false;
						Message.dberror(e);
					});
			} else {
			}
		}

		$scope.moveEmails = function (moveDetails, isNewFolder = false) {
			if ($scope.manager) $scope.manager.loading = true;
			var action = "moved";
			var url = `${URI.EMAIL.MOVE}?
			destinationFolderId=${moveDetails.destinationFolderId}
			&currentFolderId=${moveDetails.currentFolderId}
			&contractId=${$scope.contractId}`;
			moveDetails.list.forEach(id => {
				url += `&correspondenceIds=${id}`
			});
			$scope.manager[URI.EMAIL.MOVE.method](url)
				.then(function () {
					$scope.view = 'list';
					$scope.disableBtns = false;
					if (isNewFolder) {
						// Refresh items in move to folder list
						$scope.manager.set_Actions($scope.getUpdatedManagerActions());
					}
					$scope.manager.loadPage();
					$scope.getFoldersCounts();
					if (moveDetails.folderName == "Trash") action = "moved to Trash";
					if (moveDetails.folderName == "Archive") action = "archived";
				})
				.catch(function (e) {
					$scope.manager.loading = false;
					Message.dberror(e);
				});
		}

		$scope.moveToFolder = function (correspondenceIds, destinationFolderId, folderName = null) {
			var correspondenceIds = correspondenceIds || [];

			if (destinationFolderId == -6) {
				Message.error('Items in the log folder cannot be moved.');
				return;
			}

			if (destinationFolderId == -7) {
				Message.error('Items in the contract inbox folder cannot be moved.');
				return;
			}

			if (destinationFolderId != 0 && correspondenceIds.length < 1) {
				Message.error('Please select correspondence.');
				return;
			}

			var moveDetails = {
				destinationFolderId: destinationFolderId,
				currentFolderId: $scope.folderId,
				folderName: folderName ? folderName : "",
				list: correspondenceIds
			}

			destinationFolderId != 0
				? $scope.moveEmails(moveDetails)
				: $scope.createNewFolder($scope.emailFolders_system, $scope.emailFolders_custom)
					.then(function (result) {
						if (result) {
							moveDetails.destinationFolderId = result.id;

							moveDetails.list.length > 0
								? $scope.moveEmails(moveDetails, true)
								: Message.info('Folder created.');
						}
					})
					.catch(function (e) {
						Message.dberror(e);
					});
		}

		// Used in front-end only
		$scope.addFolder = function (event) {
			$scope.createNewFolder($scope.emailFolders_system, $scope.emailFolders_custom, event, true)
				.then(function (res) {
					if (res)
						Message.info('Folder created.');
				})
				.catch(function (e) {
					Message.dberror(e);
				});
		}

		$scope.deleteORMoveToTrash = function (list) {
			// if we are in the 'Trash' folder
			if ($scope.folderId == '-4') {
				$scope.delete(list);
				$scope.changeParams(-4, null);
			}
			// if we are in other folders
			else {
				$scope.moveToFolder(list, -4, 'Trash');
			}
		}

		$scope.discardBulk = function (list) {
			var list = list || [];

			if (list.length) {
				$scope.manager.loading = true;
				var data = {
					header: { "Content-type": "application/json; charset=utf-8" },
					body: list
				};
				$scope.manager[URI.EMAIL.DISCARD_DRAFTS.method](`${URI.EMAIL.DISCARD_DRAFTS}?isCorrespondenceIds=false&contractId=${$scope.contractId}&moduleId=${$scope.module.moduleId}`, data)
					.then(function () {
						$scope.manager.loadPage();
						$scope.getFoldersCounts();
					})
					.catch(function (e) {
						$scope.manager.loading = false;
						Message.dberror(e);
					});
			} else {
			}
		}

		$scope.delete = function (list) {
			// EMPTY_TRASH
			var list = list || [];

			if (list.length) {
				$scope.manager.loading = true;
				var data = {
					header: { "Content-type": "application/json; charset=utf-8" },
					body: list
				};
				$scope.manager[URI.EMAIL.EMPTY_TRASH.method](`${URI.EMAIL.EMPTY_TRASH}?contractId=${$scope.contractId}`, data)
					.then(function () {
						$scope.manager.loadPage();
						$scope.getFoldersCounts();
					})
					.catch(function (e) {
						$scope.manager.loading = false;
						Message.dberror(e);
					});
			} else {
			}
		}

		// Includes draft count in thread message count by examining draftsMessage string
		$scope.getTotalMessageCount = function (messageCount, draftsMessage) {
			if (!draftsMessage.length)
				return messageCount;

			return draftsMessage.toLowerCase() == 'draft'
				? messageCount + 1
				: messageCount + parseInt(draftsMessage.replace(/\D/g, ''));
		}

		$scope.isContractClosed = function () {
			return userService.isContractClosed($scope.contractId);
		}
	});