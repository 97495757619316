import { cipo } from 'cipo';
import PSPDFKit from 'pspdfkit';

cipo.factory("PSPDFKitAnnotationInspector", function (Model, PSPDFKIT_CONFIG, $q, $http, URI, userService) {
    //constructor extending Model
    var PSPDFKitAnnotationInspector = Model.extend(function (obj) {
        var self = this;

        /* FUNCTIONS */
        self.getPropertiesByType = _getPropertiesByType;
        self.getSectionForType = _getSectionForType;
        self.generateListItem = _generateListItem;
        self.colorPicker = _colorPicker;
        self.dropDownColorPicker = _dropDownColorPicker;
        self.updateDate = _updateDate;
        self.numberInput = _numberInput;
        self.createSelectField = _createSelectField;
        self.toggleFields = _toggleFields;
        self.rgbToHex = _rgbToHex;
        self.hexToRgb = _hexToRgb;
        self.rgbPartToHex = _rgbPartToHex;
        self.throttled = _throttled;
        self.isAnyCustomType = _isAnyCustomType;
        self.isOfCustomType = _isOfCustomType;
        self.getDetails = _getDetails;
        self.getActions = _getActions;
        self.getUserDict = _getUserDict;
        self.getUserDisplayName = _getUserDisplayName;
        self.getDisplayDate = _getDisplayDate;
        /* END FUNCTIONS */

        /* VARIABLES */
        const strokeCollors = ['#000000', '#ffffff', '#ffc0cb', '#ff0000', '#ffd83f ', '#ffa500', '#0000ff', '#800080', '#28b293'];
        obj = obj || {};
        self.instance = obj.instance || null;
        self.customAnnotationsMap = obj.customAnnotationsMap || null;
        self.inspectors = {};
        self.userDict = [];
        self.entityInstanceId = obj.entityInstanceId ?? userService.system.context.contract?.entityInstanceId ?? userService.system.context.project?.entityInstanceId ?? userService.system.context.program?.entityInstanceId;
        self.COLLAPSE_BY_DEFAULT_HEIGHT = 850;
        self.COLLAPSE_BY_DEFAULT_WIDTH = 600;
        self.ASSETS_PATH = "Content/pspdfkit/icons";
        self.CLOUD_BORDER_EFFECT_BASE_RADIUS = 4.25;
        self.blendModes = [
            { label: "Normal", value: "normal" },
            { label: "Multiply", value: "multiply" },
            { label: "Screen", value: "screen" },
            { label: "Overlay", value: "overlay" },
            { label: "Darken", value: "darken" },
            { label: "Lighten", value: "lighten" },
            { label: "Color Dodge", value: "colorDodge" },
            { label: "Color Burn", value: "colorBurn" },
            { label: "Hard Light", value: "hardLight" },
            { label: "Soft Light", value: "softLight" },
            { label: "Difference", value: "difference" },
            { label: "Exclusion", value: "exclusion" }
        ];

        self.getUserDict();

        self.annotationsMap = {
            actions: {
                actions: {
                    label: "Actions",
                    icon: null,
                    getElements: (annotation) => {
                        // Return action buttons based on custom config
                    }
                }
            },
            details: {
                details: {
                    label: "Loading...",
                    icon: null,
                    getElements: () => {
                        let el = document.createElement('span');
                        return [el];
                    }
                }
            },
            annotation: {
                opacity: {
                    label: "Opacity",
                    icon: `${self.ASSETS_PATH}/opacity.svg`,
                    getElements: () => {
                        if (!(self.instance.getSelectedAnnotation() instanceof PSPDFKit.Annotations.NoteAnnotation)) {
                            return self.numberInput("opacity", { max: 1, step: 0.01, type: "range" });
                        }
                    }
                }
            },
            shapeAnnotation: {
                fillColor: {
                    label: "Fill color",
                    icon: `${self.ASSETS_PATH}/fill-color.svg`,
                    getElements: () => self.dropDownColorPicker("fillColor")
                },
                strokeColor: {
                    label: "Stroke color",
                    icon: `${self.ASSETS_PATH}/color.svg`,
                    getElements: () => self.dropDownColorPicker("strokeColor")
                },
                strokeWidth: {
                    label: "Stroke width",
                    icon: `${self.ASSETS_PATH}/stroke-width.svg`,
                    getElements: () => self.numberInput("strokeWidth", { min: 0, step: 0.5 })
                },
                strokeDashArray: {
                    label: "Line style",
                    icon: `${self.ASSETS_PATH}/line-style.svg`,
                    getElements: () => {
                        const annotation = self.instance.getSelectedAnnotation();
                        const possibleValues = [
                            { label: "solid", value: null },
                            { label: "dashed", value: [1, 1] },
                            { label: "dotted", value: [1, 3] },
                            { label: "dashed (3)", value: [3, 3] },
                            { label: "dashed (6)", value: [6, 6] }
                        ];

                        if ("cloudyBorderIntensity" in annotation) {
                            // Cloudy style is only supported on a subset of shape annotations
                            possibleValues.push({ label: "cloudy", value: null });
                        }
                        const [select] = self.createSelectField("strokeDashArray", possibleValues);

                        // Add another event listener to update the
                        // "cloudyBorderIntensity" property when "cloudy"
                        // is selected
                        if (self.instance.getSelectedAnnotation().cloudyBorderIntensity > 0) {
                            select.selectedIndex = 5;
                        }
                        select.addEventListener("change", e => {
                            const annotation = self.instance.getSelectedAnnotation();
                            if (e.target.selectedIndex === 5) {
                                // For cloudy borders, we set "strokeDashArray" to null
                                // but specify "cloudyBorderIntensity" and "cloudyBorderInset"
                                const inset =
                                    self.CLOUD_BORDER_EFFECT_BASE_RADIUS * 2 + annotation.strokeWidth / 2;
                                const updatedAnnotation = annotation
                                    .set("cloudyBorderIntensity", 2)
                                    .set(
                                        "cloudyBorderInset",
                                        PSPDFKit.Geometry.Inset.fromValue(inset)
                                    );
                                self.instance.update(updatedAnnotation);
                            } else if (annotation.cloudyBorderIntensity > 0) {
                                const updatedAnnotation = annotation.set(
                                    "cloudyBorderIntensity",
                                    0
                                );
                                self.instance.update(updatedAnnotation);
                            }
                        });
                        return [select];
                    }
                }
            },
            inkAnnotation: {
                lineWidth: {
                    label: "Line width",
                    icon: `${self.ASSETS_PATH}/stroke-width.svg`,
                    getElements: () => self.numberInput("lineWidth", { min: 0, step: 0.5 })
                },
                strokeColor: {
                    label: "Stroke color",
                    icon: `${self.ASSETS_PATH}/color.svg`,
                    getElements: () => self.dropDownColorPicker("strokeColor")
                },
                backgroundColor: {
                    label: "Background",
                    icon: `${self.ASSETS_PATH}/fill-color.svg`,
                    getElements: () => self.dropDownColorPicker("backgroundColor")
                },
                blendMode: {
                    label: "Blend mode",
                    icon: `${self.ASSETS_PATH}/blend-mode.svg`,
                    getElements: () => self.createSelectField("blendMode", self.blendModes)
                }
            },
            textAnnotation: {
                font: {
                    label: "Font",
                    icon: `${self.ASSETS_PATH}/font.svg`,
                    getElements: () =>
                        self.createSelectField("font", [
                            "Helvetica",
                            "Arial",
                            "Calibri",
                            "Century Gothic",
                            "Consolas",
                            "Courier",
                            "Dejavu Sans",
                            "Dejavu Serif",
                            "Georgia",
                            "Gill Sans",
                            "Impact",
                            "Lucida Sans",
                            "Myriad Pro",
                            "Open Sans",
                            "Palatino",
                            "Tahoma",
                            "Times New Roman",
                            "Trebuchet",
                            "Verdana",
                            "Zapfino",
                            "Comic Sans"
                        ])
                },
                fontSyle: {
                    label: "Font style",
                    icon: `${self.ASSETS_PATH}/font-style.svg`,
                    getElements: () =>
                        self.toggleFields(["isItalic", "isBold"], {
                            type: "checkbox",
                            options: [
                                {
                                    label: "Italic",
                                    icon: `${self.ASSETS_PATH}/italic.svg`
                                },
                                {
                                    label: "Bold",
                                    icon: `${self.ASSETS_PATH}/bold.svg`
                                }
                            ]
                        })
                },
                fontSize: {
                    label: "Text size",
                    icon: `${self.ASSETS_PATH}/font-size.svg`,
                    getElements: () => self.numberInput("fontSize", { min: 0, step: 1 })
                },
                fontColor: {
                    label: "Text color",
                    icon: `${self.ASSETS_PATH}/color.svg`,
                    getElements: () => self.dropDownColorPicker("fontColor")
                },
                horizontalAlign: {
                    label: "Horizontal alignment",
                    icon: `${self.ASSETS_PATH}/text-align-horizontal.svg`,
                    getElements: () =>
                        self.toggleFields("horizontalAlign", {
                            options: [
                                {
                                    label: "Left",
                                    value: "left",
                                    icon: `${self.ASSETS_PATH}/align-left.svg`
                                },
                                {
                                    label: "Center",
                                    value: "center",
                                    icon: `${self.ASSETS_PATH}/align-center.svg`
                                },
                                {
                                    label: "Right",
                                    value: "right",
                                    icon: `${self.ASSETS_PATH}/align-right.svg`
                                }
                            ]
                        })
                },
                verticalAlign: {
                    label: "Vertical alignment",
                    icon: `${self.ASSETS_PATH}/text-align-vertical.svg`,
                    getElements: () =>
                        self.toggleFields("verticalAlign", {
                            options: [
                                {
                                    label: "Top",
                                    value: "top",
                                    icon: `${self.ASSETS_PATH}/align-top.svg`
                                },
                                {
                                    label: "Center",
                                    value: "center",
                                    icon: `${self.ASSETS_PATH}/align-vcenter.svg`
                                },
                                {
                                    label: "Bottom",
                                    value: "bottom",
                                    icon: `${self.ASSETS_PATH}/align-bottom.svg`
                                }
                            ]
                        })
                },
                backgroundColor: {
                    label: "Background",
                    icon: `${self.ASSETS_PATH}/fill-color.svg`,
                    getElements: () => self.dropDownColorPicker("backgroundColor")
                }
            },
            noteAnnotation: {
                text: {                       
                    label: annotation => annotation['creatorName'],
                    icon:  ``,
                    getElements: () => self.updateDate()
                },
                color: {
                    label: "Background",
                    icon: `${self.ASSETS_PATH}/fill-color.svg`,
                    getElements: () => self.dropDownColorPicker("color", false)
                },
                icon: {
                    label: "Icon",
                    icon: `${self.ASSETS_PATH}/color.svg`,
                    getElements: () =>
                        self.createSelectField("icon", [
                            { label: "Comment", value: "COMMENT" },
                            { label: "Right Pointer", value: "RIGHT_POINTER" },
                            { label: "Right Arrow", value: "RIGHT_ARROW" },
                            { label: "Check", value: "CHECK" },
                            { label: "Circle", value: "CIRCLE" },
                            { label: "Cross", value: "CROSS" },
                            { label: "Insert", value: "INSERT" },
                            { label: "New Paragraph", value: "NEW_PARAGRAPH" },
                            { label: "Note", value: "NOTE" },
                            { label: "Paragraph", value: "PARAGRAPH" },
                            { label: "Help", value: "HELP" },
                            { label: "Star", value: "STAR" },
                            { label: "Key", value: "KEY" }
                        ])
                }
            },
            highlightAnnotation: {
                color: {
                    label: "Color",
                    icon: `${self.ASSETS_PATH}/color.svg`,
                    getElements: () => self.dropDownColorPicker("color")
                },
                blendMode: {
                    label: "Blend mode",
                    icon: `${self.ASSETS_PATH}/blend-mode.svg`,
                    getElements: () => self.createSelectField("blendMode", self.blendModes)
                }
            }
        };

        /* PRIVATE FUNCTION DECLARATION */
        // Construct the DOM tree for the different sections to display according
        // to the current annotation type.
        function _getPropertiesByType(annotation) {
            const sections = [];
            let title;

            if (annotation instanceof PSPDFKit.Annotations.ImageAnnotation && self.isOfCustomType(PSPDFKIT_CONFIG.ANNOTATION_TYPES.DOCUMENT_LINK, annotation)) {
                var nodeData = self.customAnnotationsMap[PSPDFKIT_CONFIG.ANNOTATION_TYPES.DOCUMENT_LINK].nodeData;
                title = nodeData.title;
                nodeData.sections.forEach(section => {
                    sections.push(self.getSectionForType(section.type, section.label, section.collapseByDefault));
                });
            }
            else if (annotation instanceof PSPDFKit.Annotations.ShapeAnnotation) {
                sections.push(self.getSectionForType("shapeAnnotation", "Shape style"));
                title = "Shape properties";
            } else if (annotation instanceof PSPDFKit.Annotations.InkAnnotation) {
                sections.push(self.getSectionForType("inkAnnotation", "Ink style"));
                title = "Ink properties";
            } else if (annotation instanceof PSPDFKit.Annotations.TextAnnotation) {
                sections.push(self.getSectionForType("textAnnotation", "Text style"));
                title = "Text properties";
            } else if (annotation instanceof PSPDFKit.Annotations.NoteAnnotation) {
                sections.push(self.getSectionForType("noteAnnotation", "Note style"));
            } else if (annotation instanceof PSPDFKit.Annotations.HighlightAnnotation) {
                sections.push(self.getSectionForType("highlightAnnotation", "Highlight style"));
                title = "Highlight properties";
            }

            return { title, sections: sections.reverse() };
        }

            function _getSectionForType(annotationKey, label, collapseByDefault = null, customAnnotation = null) {
                const ul = document.createElement("ul");
                ul.id = `ul-${annotationKey}`;

                // Set UL id
                Object.keys(self.annotationsMap[annotationKey]).forEach(prop => {
                    const { label, getElements, icon } = self.annotationsMap[annotationKey][prop];
                    var title = label;
                    if (annotationKey == 'noteAnnotation' && prop == 'text') {
                        title = self.getUserDisplayName(self.instance.getSelectedAnnotation()['creatorName']);
                        if (title.length == 0) {
                            title = userService.system.userdata.displayName;
                        }                        

                        //same listener for pressing the 'Delete' key
                        self.instance.addEventListener("annotations.willChange", e => {
                            if (
                                e.reason === PSPDFKit.AnnotationsWillChangeReason.TEXT_EDIT_START &&
                                e.annotations.first() instanceof PSPDFKit.Annotations.NoteAnnotation
                            ) {
                                const inputEl = self.instance.contentDocument.querySelector(".PSPDFKit-Note-Annotation-Content [contenteditable='true']"
                                );
                                if (inputEl) {
                                    inputEl.addEventListener("input", e => {
                                        var noteStr = e.target.textContent;
                                        if (inputEl.innerText.replace(/\s/g, '').endsWith("@")) {
                                            //if (noteStr.endsWith("@")) {                              

                                            inputEl.style.overflow = "visible";
                                            inputEl.parentElement.style.overflow = "visible";

                                            const optionsList = document.createElement('div');
                                            optionsList.classList.add('mention-options');
                                            optionsList.classList.add('show');                                                                                       
                                            inputEl.appendChild(optionsList);
                                            //inputEl.parentElement.appendChild(optionsList);

                                            for (const user of self.userDict) {
                                                const optionElement = document.createElement('div');
                                                optionElement.classList.add('mention-option');
                                                optionElement.innerHTML = user.name;                                                
                                                optionsList.appendChild(optionElement);

                                                optionElement.addEventListener('click', function (e) {                                                    
                                                    inputEl.innerHTML = noteStr + "<strong>" + user.name + "</strong>";                                                   
                                                    optionsList.classList.remove('show');
                                                })
                                            }
                                        }
                                    });
                                }
                            }
                        });
                    }
                    const domElements = customAnnotation ? getElements(customAnnotation) : getElements();
                    if (Array.isArray(domElements) && domElements.length > 0) {
                        if (customAnnotation == null) {
                            ul.appendChild(self.generateListItem(title, prop, domElements, icon));
                        }
                        else {
                            let kvps = self.customAnnotationsMap[customAnnotation.customData.annotationType].getDetails(customAnnotation.customData);                           
                            kvps.forEach(kvp => {
                                let el = document.createElement('span');
                                el.innerHTML = kvp.value;
                                ul.appendChild(self.generateListItem(kvp.key, null, [el]));
                            });
                        }                    
                    }
                });

            // If there's enough width the inspector can be rendered on the sides of
            // the annotation even when the height of the viewport wouldn't be enough
            const shouldCollapseSectionsByDefault = collapseByDefault != null
                ? collapseByDefault
                : window.innerHeight <= self.COLLAPSE_BY_DEFAULT_HEIGHT &&
                window.innerWidth <= self.COLLAPSE_BY_DEFAULT_WIDTH;

            if (shouldCollapseSectionsByDefault) {
                ul.classList.toggle("collapsed-list");
            }
            const sectionIcon = shouldCollapseSectionsByDefault
                ? "panel-expand.svg"
                : "panel-collapse.svg";
            const sectionEl = document.createElement("section");
            const collapseBtn = document.createElement("button");
            collapseBtn.className = "collapse-toggle-button";
            const collapseImg = document.createElement("img");
            collapseImg.src = `${self.ASSETS_PATH}/${sectionIcon}`;
            collapseImg.setAttribute("alt", "Expand");
            collapseBtn.appendChild(collapseImg);
            collapseBtn.id = `collapse-${annotationKey}`;
            const sectionDiv = document.createElement("div");
            sectionDiv.className = "section-title";
            sectionDiv.innerHTML = `<label class="group-title" for="${collapseBtn.id}">${label}</label>`;
            sectionDiv.firstChild.appendChild(collapseBtn);
            sectionEl.appendChild(sectionDiv);
            sectionEl.appendChild(ul);
            collapseBtn.addEventListener("click", () => {
                if (ul.classList.contains("collapsed-list")) {
                    collapseImg.src = `${self.ASSETS_PATH}/panel-collapse.svg`;
                    collapseImg.setAttribute("alt", "Collapse");
                    customAnnotation ? self.getDetails(customAnnotation, ul.id) : null;
                } else {
                    collapseImg.src = `${self.ASSETS_PATH}/panel-expand.svg`;
                    collapseImg.setAttribute("alt", "Expand");
                }
                ul.classList.toggle("collapsed-list");
            });
            return sectionEl;
        };

        function _generateListItem(label, key, domElements, icon = null) {
            const li = document.createElement("li");
            const valueSpan = document.createElement("span");
            valueSpan.className = "val";
            if (Array.isArray(domElements)) {
                domElements.forEach(el => valueSpan.appendChild(el));
            }
            li.innerHTML = `<span class="attr">${(icon ? `<img src="${icon}" class="property-icon" aria-hidden="true" alt="">` : '')} ${label}</span>`;
            li.appendChild(valueSpan);
            return li;
        }

        function _colorPicker(prop, nullable = true) {
            const currentColor = self.instance.getSelectedAnnotation()[prop];
            const colorPicker = document.createElement("input");
            try {
                colorPicker.type = "color";
            } catch (e) {
                colorPicker.type = "text";
                colorPicker.className = "annotation-input";
                colorPicker.placeholder = "(e.g.: #ff00aa)";
            }
            colorPicker.name = prop;
            colorPicker.value = currentColor
                ? // input[type="color"] only support hexadecimal
                // values.
                self.rgbToHex(currentColor.toCSSValue())
                : "#ffffff";

            // Event handler for "change" events. We throttle it
            // to prevent multiple calls when the user is exploring
            // color options using the browser's color picker wheel.
            const throttledChange = self.throttled(50, event => {
                const updatedAnnotation = self.instance
                    .getSelectedAnnotation()
                    // we need to convert the hex value representation used by the
                    // input[type="color"] element to the RGB value expected.
                    .set(prop, new PSPDFKit.Color(self.hexToRgb(event.target.value)));
                self.instance.update(updatedAnnotation);
            });
            colorPicker.addEventListener("change", throttledChange);

            if (nullable) {
                const checkbox = document.createElement("input");
                checkbox.type = "checkbox";
                checkbox.name = `transparent-${prop}`;
                checkbox.checked = self.instance.getSelectedAnnotation()[prop];
                if (!checkbox.checked) {
                    colorPicker.style.visibility = "hidden";
                }
                checkbox.addEventListener("change", e => {
                    colorPicker.style.visibility = e.target.checked ? "visible" : "hidden";
                    const updatedAnnotation = self.instance.getSelectedAnnotation().set(
                        prop,
                        e.target.checked
                            ? // we need to convert the hex value representation used by the
                            // input[type="color"] element to the RGB value expected.
                            new PSPDFKit.Color(self.hexToRgb(colorPicker.value))
                            : null
                    );
                    self.instance.update(updatedAnnotation);
                });
                return [checkbox, colorPicker];
            }
            return [colorPicker];
        }           

        function _dropDownColorPicker(prop, nullable = true) {
            var optionsHTML = '';                
            const selectColor = document.createElement("select");
            const currentColor = self.instance.getSelectedAnnotation()[prop];
            selectColor.className = "annotation-color-select";
            selectColor.style.backgroundColor = currentColor ? self.rgbToHex(currentColor.toCSSValue()) : "#ff0000";
            function shapeIterator(value, index, array) {
                optionsHTML = optionsHTML + '<option value=' + value + ' style="background-color:' + value + '"></option>';
            }

            strokeCollors.forEach(shapeIterator);
            selectColor.innerHTML = optionsHTML;

            selectColor.addEventListener("change", e => {                                        
                selectColor.style.backgroundColor = selectColor.value;
                const updatedAnnotation = self.instance
                    .getSelectedAnnotation()                       
                    .set(prop, new PSPDFKit.Color(self.hexToRgb(selectColor.value)));
                self.instance.update(updatedAnnotation);
            });

            if (nullable) {
                const checkbox = document.createElement("input");
                checkbox.type = "checkbox";
                checkbox.name = `transparent-${prop}`;
                checkbox.checked = self.instance.getSelectedAnnotation()[prop];
                if (!checkbox.checked) {
                    selectColor.style.visibility = "hidden";
                }
                checkbox.addEventListener("change", e => {
                    selectColor.style.visibility = e.target.checked ? "visible" : "hidden";
                    const updatedAnnotation = self.instance.getSelectedAnnotation().set(
                        prop,
                        e.target.checked ?
                            new PSPDFKit.Color(self.hexToRgb(selectColor.value))
                            : null
                    );
                    self.instance.update(updatedAnnotation);
                });
                return [checkbox, selectColor];
            }
            return [selectColor];
        }

        function _updateDate() {                
            const dateParagraph = document.createElement("p");               
            dateParagraph.innerHTML = self.getDisplayDate(self.instance.getSelectedAnnotation()['updatedAt'].toString()); 

            return [dateParagraph];
        }

        // Generator of either input[type="number"] or input[type="range"]
        // elements, according to the "type" property received as a parameter.
        function _numberInput(prop, { min = 0, step, max, type = "number" }) {
            const input = document.createElement("input");
            input.type = type;
            input.className = type === "number" ? "annotation-input" : "annotation-range";
            input.name = prop;
            input.min = min;
            if (step) {
                input.step = step;
            }
            if (max) {
                input.max = max;
            }
            input.value = self.instance.getSelectedAnnotation()[prop];
            let label;
            if (type === "range") {
                label = document.createElement("label");
                label.style.width = "2em";
                label.style.display = "inline-block";
                label.innerText = input.value;
            }
            // for input[type="number"] we listen to the "input" event
            // to prevent an edge case on mobile devices when the annotation
            // is deselected but the current value has changed.
            const event = type === "range" ? "change" : "input";
            input.addEventListener(event, e => {
                const value = parseFloat(e.target.value);
                const currentAnnotation = self.instance.getSelectedAnnotation();
                let updatedAnnotation = currentAnnotation.set(prop, value);
                if (label) {
                    label.innerText = value;
                }
                if (
                    prop === "strokeWidth" &&
                    updatedAnnotation.cloudyBorderIntensity > 0 &&
                    updatedAnnotation.cloudyBorderInset
                ) {
                    updatedAnnotation = updatedAnnotation.set(
                        "cloudyBorderInset",
                        new PSPDFKit.Geometry.Inset({
                            left:
                                updatedAnnotation.cloudyBorderInset.left +
                                (value - currentAnnotation.strokeWidth) / 2,
                            top:
                                updatedAnnotation.cloudyBorderInset.top +
                                (value - currentAnnotation.strokeWidth) / 2,
                            right:
                                updatedAnnotation.cloudyBorderInset.right +
                                (value - currentAnnotation.strokeWidth) / 2,
                            bottom:
                                updatedAnnotation.cloudyBorderInset.bottom +
                                (value - currentAnnotation.strokeWidth) / 2
                        })
                    );
                }
                self.instance.update(updatedAnnotation);
            });

            return label ? [input, label] : [input];
        }

        // Generator of select elements given an array of options.
        // For simple cases you can just specify string values on the array.
        // Otherwise add { label, value } objects as entries.
        function _createSelectField(prop, options = []) {
            const select = document.createElement("select");
            select.className = "annotation-select";
            const currentVal = self.instance.getSelectedAnnotation()[prop];
            const optionsHTML = options
                .map((option, i) => {
                    const optionValue = option.value || option;
                    const areValuesEqual = Array.isArray(optionValue)
                        ? JSON.stringify(optionValue) === JSON.stringify(currentVal)
                        : currentVal === optionValue;

                    return `<option value=${i} ${areValuesEqual ? "selected" : ""
                        }>${option.label || option}</option>`;
                })
                .join("");
            select.innerHTML = optionsHTML;
            select.addEventListener("change", e => {
                const updatedAnnotation = self.instance
                    .getSelectedAnnotation()
                    .set(
                        prop,
                        options[parseInt(e.target.value)].value === undefined
                            ? options[parseInt(e.target.value)]
                            : options[parseInt(e.target.value)].value
                    );
                self.instance.update(updatedAnnotation);
            });
            return [select];
        }

        // Group of buttons to handle radio buttons or checkboxes interactions.
        // In the case of checkbox-alike interactions, one should specif an array of
        // boolean properties that matches each of the possible options to display.
        // One example of this is the "Text style" row when inspecting text
        // annotations.
        function _toggleFields(prop, { type = "radio", options = [] }) {
            const form = document.createElement("form");
            form.className = "switch-form";
            let currentVal;
            if (type === "radio") {
                currentVal = self.instance.getSelectedAnnotation()[prop];
            }
            options.forEach((option, i) => {
                const input = document.createElement("input");
                input.type = type;
                input.id = `${type}-${prop}-${option.label || ""}`;
                input.name = prop;
                if (type === "radio") {
                    input.value = option.value;
                }

                if (Array.isArray(prop)) {
                    // For the checkboxes implementation, one can specify
                    // an array with properties to bind to each of the inputs.
                    input.checked = !!self.instance.getSelectedAnnotation()[prop[i]];
                } else {
                    if (option.value === currentVal) {
                        input.checked = true;
                    }
                }
                const label = document.createElement("label");
                label.htmlFor = input.id;
                label.setAttribute("role", "button");
                const icon = document.createElement("img");
                icon.setAttribute("src", option.icon);
                icon.setAttribute("alt", option.label);
                label.appendChild(icon);
                if (type === "checkbox") {
                    // In this case we can attach a change event listener
                    // for each checkbox.
                    input.addEventListener("change", e => {
                        let updatedAnnotation;
                        if (Array.isArray(prop)) {
                            updatedAnnotation = self.instance
                                .getSelectedAnnotation()
                                .set(prop[i], e.target.checked);
                        } else {
                            updatedAnnotation = self.instance
                                .getSelectedAnnotation()
                                .set(prop, e.target.checked);
                        }
                        self.instance.update(updatedAnnotation);
                    });
                }
                form.appendChild(input);
                form.appendChild(label);
            });

            if (type === "radio") {
                // For the radio buttons implementation, we attach the
                // change event handler to the form, to easily identify
                // the active radio button.
                form.addEventListener("change", () => {
                    const selectedRadio = form.querySelector("input:checked");
                    const updatedAnnotation = self.instance
                        .getSelectedAnnotation()
                        .set(prop, selectedRadio.value);
                    self.instance.update(updatedAnnotation);
                });
            }
            return [form];
        }

        // Helper functions to convert between RGB and Hex values.
        function _rgbToHex(rgb) {
            const csv = rgb.split("(")[1].split(")")[0];
            const split = csv.split(",");
            const [r, g, b] = [split[0].trim(), split[1].trim(), split[2].trim()];
            return `#${self.rgbPartToHex(r)}${self.rgbPartToHex(g)}${self.rgbPartToHex(b)}`;
        }

        function _hexToRgb(hex) {
            const numberPart = hex.split("#")[1];
            const number = parseInt(numberPart, 16);
            return {
                r: (number >> 16) & 255,
                g: (number >> 8) & 255,
                b: number & 255
            };
        }

        function _rgbPartToHex(part) {
            const number = Number.parseInt(part, 10);
            const hex = number.toString(16);
            return hex.length == 1 ? "0" + hex : hex;
        }

        function _throttled(delay, fn) {
            let lastCall = 0;
            return (...args) => {
                const now = Date.now();
                if (now - lastCall < delay) {
                    return;
                }
                lastCall = now;
                return fn(...args);
            };
        }

        function _isAnyCustomType(annotation) {
            for (var property in PSPDFKIT_CONFIG.ANNOTATION_TYPES) {
                if (PSPDFKIT_CONFIG.ANNOTATION_TYPES.hasOwnProperty(property)) {
                    if (self.isOfCustomType(PSPDFKIT_CONFIG.ANNOTATION_TYPES[property], annotation)) {
                        return true;
                    }
                }
            }
            return false;
        }

        function _isOfCustomType(customAnnotationType, annotation) {
            return annotation.customData &&
                annotation.customData.annotationType &&
                annotation.customData.annotationType == customAnnotationType;
        }

        function _getDetails(annotation, listId) {
            // Only get details if item has single (Loading...) element. Otherwise, it's loaded.
            let list = self.instance.contentDocument.getElementById(listId);
            if (list.childElementCount == 1 && list.firstChild.innerHTML.includes(self.annotationsMap.details.details.label)) {
                let kvps = self.customAnnotationsMap[annotation.customData.annotationType].getDetails(annotation.customData);
                list.innerHTML = '';
                kvps.forEach(kvp => {
                    let el = document.createElement('span');
                    el.innerHTML = kvp.value;
                    list.appendChild(self.generateListItem(kvp.key, null, [el]));
                });
            }
        }

        function _getActions(annotation) {
            // Get actions for this annotation
        }

        // Returns a list of users for the current contract
        function _getUserDict() {
            var deferred = $q.defer();
            $http.get(`${URI.PDF_MARKUP.GET_USER_DICT}?entityInstanceId=${self.entityInstanceId}`)                
                .then(res => {
                    self.userDict = res.data;
                    deferred.resolve();
                })
                .catch(err => {
                    deferred.reject(err);
                });
            return deferred.promise;
        }

        // Returns a display value by user id
        function _getUserDisplayName(userId) {
            const user = self.userDict.find(x => x.id == userId);
            return user ? user.name : '';
        }

        function _getDisplayDate(datetime) {
            return userService.formatDate(datetime);
        }
        /* END PRIVATE FUNCTION DECLARATION */
    });

    /* PUBLIC FUNCTIONS */
    PSPDFKitAnnotationInspector.prototype.getAnnotationInspector = function (annotation) {
        var self = this;
        let container;

        if (!self.inspectors[annotation.id] || self.inspectors[annotation.id].children.length === 0) {
            // There's a bug on IE 11 where the cached div can be empty
            // so if it doesn't contain any children we recreate the container
            container = document.createElement("div");
            container.className = "annotation-inspector";

            const { sections, title } = self.getPropertiesByType(annotation);

            const header = document.createElement("header");
            header.innerHTML = `<h1>${title}</h1>`;
            container.appendChild(header);

            if (self.isAnyCustomType(annotation)) {
                let details = self.getSectionForType('details', 'Details', null, annotation);
                // let actions = self.getSectionForType('actions', 'Actions', null, annotation);
                details ? sections.unshift(details) : null;
                // actions ? sections.push(actions) : null;                    
            }

                sections.forEach(section => container.appendChild(section));
                self.inspectors[annotation.id] = container;

               // const test = self.instance.contentDocument.querySelector('.PSPDFKit-c8up8r9n155axqjtb8dat8e5t');
                //var content = document.querySelector('.PSPDFKit-c8up8r9n155axqjtb8dat8e5t');
               // test.addEventListener('input', function (event) {
                    // 2. Retrive the text from inside the element
                 //   console.log('Guigaaaaaa!!' + test.innerHTML);
                //}, { passive: true });
            }

        container = self.inspectors[annotation.id];
        return [
            {
                type: "custom",
                id: "annotation-tooltip",
                className: "annotation-tooltip-container",
                node: container
            }
        ];
    }

    return PSPDFKitAnnotationInspector;
});
