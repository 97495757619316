import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged } from 'rxjs';

import { MenuModel } from '../../models/menu';
import { TenantStore, UserStore } from '../../signal-store';
import { UtilsService } from './../../shared/services/utils.service';
import { NgToJsService } from '../../ng2/services';
import { ContextInfo } from '../context/context.model';

export interface MenuItem extends MenuModel {
  active?: boolean;
  parent?: MenuItem;
  level?: number;
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
  searchControl = new FormControl('');
  treeControl = new NestedTreeControl<MenuItem>(node => node.children);
  dataSource = new MatTreeNestedDataSource<MenuItem>();
  userStore = inject(UserStore);
  tenantStore = inject(TenantStore);
  router = inject(Router);
  angularJsService = inject(NgToJsService);

  private _menuItems: MenuItem[];
  private _filteredMenuItems: MenuItem[];

  @Input() get menuItems(): MenuItem[] {
    return this._menuItems;
  }
  set menuItems(value: MenuItem[]) {
    if (this.userStore.theme.mode() === 'dark') {
      this._menuItems = value.map(item =>
        item.color ? { ...item, color: this.utilsService.invertHex(item.color) } : item,
      );
    } else {
      this._menuItems = value;
    }
    this.setParent(this._menuItems);
    this.search(this.searchControl.value);
    // this.store.dispatch(menuActions.populateMenu({ menuItems: structuredClone(cleanMenuItems) }));
  }

  @Input() menuFolderAutoCollapse: boolean;

  @Output() onMenuItemClick = new EventEmitter<MenuItem>();

  constructor(
    private dialog: MatDialog,
    private utilsService: UtilsService,
  ) {
    this.searchControl.valueChanges
      .pipe(takeUntilDestroyed(), debounceTime(500), distinctUntilChanged())
      .subscribe(value => this.search(value));
  }

  search(searchValue: string): void {
    // filter the menu items
    this._filteredMenuItems = this.filterMenuItems(searchValue, this.menuItems);
    this.dataSource.data = this._filteredMenuItems;

    this.expandFilteredMenuItems();
  }

  clearSearch(): void {
    this.searchControl.reset();
  }

  filterMenuItems(searchValue: string, menuItems: MenuItem[]) {
    if (!searchValue) {
      return menuItems.filter(m => !m.hidden);
    }
    // creates a new list of found menu items
    const items: MenuItem[] = [];
    for (const menuItem of menuItems || []) {
      if (menuItem.hidden) {
        continue;
      }

      if (this.hasChild(null, menuItem)) {
        // filter the children
        const children = this.filterMenuItems(searchValue, menuItem.children);
        if (children.length > 0) {
          // add found children
          items.push({ ...menuItem, children: children });
        }
      } else if (
        !searchValue ||
        menuItem.name.toLowerCase().includes(searchValue.toLowerCase()) ||
        (menuItem.code && menuItem.code.toLowerCase().includes(searchValue.toLowerCase()))
      ) {
        // add root level filtered menu items
        items.push({ ...menuItem });
      }
    }
    return items;
  }

  expandFilteredMenuItems() {
    if (!this.searchControl.value) {
      return;
    }
    for (const menuItem of this._filteredMenuItems || []) {
      this.treeControl.expandDescendants(menuItem);
    }
  }

  hasChild(_: number, menuItem: MenuItem): boolean {
    return (menuItem.children || []).length > 0;
  }

  menuItemClick(menuItem: MenuItem): void {
    this.closeAllDialogs();

    if (menuItem.openInNewTab) {
      window.open(menuItem.url, '_blank');
    } else {
      this.userStore.setActiveMenuItem(menuItem);
      this.router.navigate([menuItem.urlPath]);

      const currentContext = this.userStore.userData().context;
      if (this.getEntityInstanceId(this.userStore.userData().context) !== this.getEntityInstanceId(currentContext)) {
        this.angularJsService.changeContext(currentContext, true);
      }
    }
  }

  menuFolderClick(menuItem: MenuItem): void {
    if (this.menuFolderAutoCollapse) {
      const expanded = this.treeControl.isExpanded(menuItem);

      // collapse all siblings
      for (const mi of this.getSiblings(menuItem)) {
        this.treeControl.collapse(mi);
      }

      this.toggle(menuItem, expanded);
    }
  }

  private getEntityInstanceId(context: ContextInfo): number {
    return (
      context?.contract?.entityInstanceId ?? context?.project?.entityInstanceId ?? context?.program?.entityInstanceId
    );
  }

  private toggle(menuItem: MenuItem, expanded: boolean): void {
    if (expanded) {
      this.treeControl.expand(menuItem);
    } else {
      this.treeControl.collapse(menuItem);
    }
  }

  private getSiblings(menuItem: MenuItem): MenuItem[] {
    if (menuItem.parent) {
      return menuItem.parent.children.filter(c => c.id !== menuItem.id);
    }

    return this._filteredMenuItems.filter(c => c.id !== menuItem.id);
  }

  private clearMenuActive(menuItems?: MenuItem[]): void {
    // set all items as not active, including children
    for (const menuItem of menuItems || []) {
      menuItem.active = false;
      this.clearMenuActive(menuItem.children);
    }
  }

  private expandParents(menuItem: MenuItem): void {
    if (menuItem.parent && !this.treeControl.isExpanded(menuItem.parent)) {
      // expand the parent if any
      this.treeControl.expand(menuItem.parent);

      this.expandParents(menuItem.parent);
    }
  }

  private setParent(menuItems: MenuItem[], parent?: MenuItem, level?: number): void {
    if (!menuItems || menuItems.length === 0) {
      return;
    }

    let cleanParent: MenuItem = null;
    if (parent) {
      const { children, ...props } = parent;
      cleanParent = props;
    }

    for (const menuItem of menuItems) {
      if (cleanParent) {
        menuItem.parent = cleanParent;
      }
      menuItem.level = (level || 0) + 1;

      this.setParent(menuItem.children, menuItem, (level || 0) + 1);
    }
  }

  private findById(menuItems: MenuItem[], id: number): MenuItem {
    if (!menuItems || menuItems.length === 0) {
      return undefined;
    }
    for (const menuItem of menuItems) {
      if (menuItem.id === id) {
        return menuItem;
      }

      const sub = this.findById(menuItem.children, id);
      if (sub) {
        return sub;
      }
    }
    return undefined;
  }

  private closeAllDialogs() {
    for (const dialog of this.dialog.openDialogs) {
      dialog.close();
    }
  }
}
