import { cipo } from 'cipo';

cipo.factory("AdjustmentItem", function (Model, Form, $q, URI, userService, currencyService, Message) {
    var AdjustmentItem = Model.extend(function (obj, lookups, parentId) {
        var self = this;
        self.contractId = userService.system.userdata.contractId;
        self.parentId = parentId || null;
        self.properties = {};
        
        self.lookups = lookups;

        if (obj) {
            for (var key in obj) {
                if (obj.hasOwnProperty(key)) {
                    self.properties[key] = obj[key];
                }
            }
            if (self.properties.lastUpdatedDate) self.properties.lastUpdatedDate = userService.formatDate(self.properties.lastUpdatedDate);
            if (!self.properties.percentage) self.properties.percentage = 0;
        }
        self.form = new Form(self.properties);

        var form = {};
            
        currencyService.getAndSetCurrencyById($scope);

        // calls params
        self.params = {
            contractId: self.contractId,
        };

        form.selected = {
            label: "", type: 'checkbox'
        };

        self.form.set_Description(form);
        self.form.store_Data();
        //Pay Released Adjustment

        Object.defineProperty(self.form, 'editMode', {
            get: function () { return (userService.getOperationsFor("PP") || {})['Pay Released Adjustment'] ; }
        });


        self.form.fieldsList.selected.onChange = function (field) {
            self.toggleSelect();
            // self.save();
        }


    });
    AdjustmentItem.prototype.formatMoney = function (amount) {
        var self = this;
        return userService.formatNumber(amount || '0', 2, true, true, false, self.currency);
    }
    AdjustmentItem.prototype.get_Data = function (isReadonly) {
        var self = this;
        self.loading = true;
        var p = $q.defer();
        var params = {
            progressPaymentEntityInstanceId: self.parentId,
            contractId: self.contractId,
            relationInstanceId: self.properties.relation_instance_id,
            isParentSubItem: self.properties.isParentSubItem
        };
        if (self.params.editMode) params.editMode = true;
        var dataURL = URI.PROGRESS_PAYMENT.PPI_DETAILS;
        self[dataURL.method](dataURL, { url: params, urltype: 'obj' })
            .then(function (r) {
                if (r) {
                    if (isReadonly) r.readonly = true;
                    // newSelf = self.pp.parseData([r], self.fields)[0];
                    for (var key in r) {
                        if (r.hasOwnProperty(key)) {
                            if ((self.columnsLookup[key] || {}).fieldTypeId) {
                                switch (self.columnsLookup[key].fieldTypeId) {
                                    case 5: case 8: case 13:
                                        if (typeof self.columnsLookup[key].valueLookup[r[key].toString()] != 'undefined')
                                            r[key] = self.columnsLookup[key].valueLookup[r[key].toString()];
                                        else
                                            r[key] = "";
                                        break;
                                    default:
                                }
                            }


                            self.properties[key] = r[key];

                        }
                    }

                }
                self.form.store_Data();
                self.amountForm.store_Data();
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e)
            })
            .finally(function () {
                self.form.loading = false;
                self.amountForm.loading = false;
                self.loading = false;
            })


        return p.promise;
    }

    AdjustmentItem.prototype.toggleSelect = function () {
        var self = this;
        var p = $q.defer();
        var toSend = {
            progressPaymentEntityInstanceId: self.parentId,
            releaseId: self.properties.id,
        }
        self.lookups.modulesLookup[0].isBusy = true;
        self.loading = true;
        var dataURL = self.properties.selected ? URI.PROGRESS_PAYMENT.PAY_RELEASED : URI.PROGRESS_PAYMENT.WITHHOLD_RELEASED;
        self[dataURL.method](dataURL, { url: self.params, urltype: 'obj', body: toSend })
            .then(function (r) {
                for (var key in r.ppTotals) {
                    if (r.ppTotals.hasOwnProperty(key)) {
                        self.lookups.totals[key] = r.ppTotals[key];
                    }
                }
                self.lookups.modulesLookup[0].totalPayment = r.totals.payment_amount;
                self.lookups.modulesLookup[0].totals.amount = r.totals.payment_amount;


            })
            .catch(function (e) {
                Message.dberror(e);
                self.properties.selected = !self.properties.selected;
            })
            .finally(function () {
                self.loading = false;
                self.lookups.modulesLookup[0].isBusy = false;
            })
    }

    return AdjustmentItem;
});
