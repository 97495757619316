import { KeyValueModel } from '../../common/keyValueModel';
import { DataListAggregateFormulaFieldModel } from '../fields/main';
import { DataListComplexFormulaFieldModel } from '../fields/main/dataListComplexFormulaFieldModel';
import { DataListFields } from './dataListFields';

export interface GridFieldsModel {
  userIsInitiator: boolean;
  hasStartScreenFields: boolean;
  hasPresets: boolean;
  information: string;
  canPrint: boolean;
  topFormulas: DataListComplexFormulaFieldModel[];
  bottomFormulas: DataListAggregateFormulaFieldModel[];
  fields: DataListFields[];
  globalFilters: KeyValueModel<number, string>[];
  startScreens: KeyValueModel<number, string>[];
}
