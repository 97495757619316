import { KeyValueModel } from "../../../../models/common";

export enum FormattingType {
  None = 0,
  Number = 1,
  Decimals = 2,
  Text = 3,
}

export enum FormattingValueType {
  None = 0,
  Number = 1,
  Money = 2,
  Percentage = 3,
  Editor = 4,
  Multiline = 5,
  Text = 6,
}

export const FormattingTypeValues = new Map<FormattingType, FormattingValueType[]>([
  [FormattingType.None, []],
  [FormattingType.Number, [FormattingValueType.Number, FormattingValueType.Money, FormattingValueType.Percentage]],
  [FormattingType.Decimals, []],
  [FormattingType.Text, [FormattingValueType.Editor, FormattingValueType.Multiline, FormattingValueType.Text]],
]);

export interface KeyValueFormattingModel extends KeyValueModel<FormattingType, FormattingValueType> {
  formattingValue?: number;
}
