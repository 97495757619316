import { Component, inject, input } from '@angular/core';
import { UserStore } from '../../signal-store';

@Component({
  selector: 'cipo-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
  userStore = inject(UserStore);
  showHeader = input(true);
}
