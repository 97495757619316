import { inject, Injectable } from '@angular/core';
import { KeyValueType } from 'src/app/models/common';
import { GenericObjectType } from 'src/app/models/helpers';
import { Currency } from '../../models/system';
import { UserStore } from '../../signal-store';
import { CipoFieldConfig } from '../components/fields/common';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  userStore = inject(UserStore);

  private currencySymbolDefault = '$';
  private leftSideDefault = true;
  private noSpaceDefault = true;

  /**
   * Returns the inverted hex color (used for dark/light mode)
   * Accepts a string of 6 elements, or 7 elements if starting with 'x'
   *
   * @param {string} hexColor
   * @returns {string} If hexColor starts with 'x', returns new hex that also starts with 'x'
   */
  invertHex(hexColor: string): string {
    if (hexColor.length === 6) {
      return (Number(`0x1${hexColor}`) ^ 0xffffff).toString(16).toUpperCase();
    } else if (hexColor.startsWith('#') && hexColor.length === 7) {
      return `#${(Number(`0x1${hexColor.substring(1)}`) ^ 0xffffff).toString(16).toUpperCase()}`;
    } else {
      throw new Error('Invalid hex color');
    }
  }

  /**
   * Replace all spaces within a string with underscore
   *
   * @param {string} text string that might have spaces, ex: 'my string'
   * @returns {string} the updated string, ex: 'my_string'
   */
  formatText(text: string): string {
    return text.replace(/ /g, '_');
  }

  /**
   * Receives an object of keyValue pairs, and outputs an array of objects
   *
   * @param {{ [key: string]: string }} obj
   * @returns {{key: string, value: string}[]} KeyValueType[]
   */
  mapObjectToArray(obj: GenericObjectType): KeyValueType[] {
    return Object.keys(obj).map(key => ({ key: key, value: obj[key] }));
  }

  /**
   * Receives an array of objects, and outputs an object of keyValue pairs
   *
   * @param {{key: string, value: string}[]} array
   * @returns {{ [key: string]: string }} GenericObjectType
   */
  mapArrayToObject(array: KeyValueType[]): GenericObjectType {
    const obj: GenericObjectType = {};
    array.forEach(element => (obj[element.key] = element.value));
    return obj;
  }

  /**
   * Converts a number that was formatted with .toLocaleString() back to a number, using the locale calculated from decimalSeparator and thousandSeparator
   *
   * @param {string} value
   * @returns {number}
   */
  revertLocaleNumber(value: string, decimalSeparator: string, thousandSeparator: string): number | undefined {
    const baseValue = value.replaceAll(thousandSeparator, '').replace(decimalSeparator, '.');
    const newValue = parseFloat(baseValue);
    return isNaN(newValue) ? undefined : newValue;
  }

  /**
   * Converts a number to a string using a locale calculated from given decimalSeparator and thousandSeparator
   *
   * @param {number} value
   * @param {decimals} decimals
   * @param {string} decimalSeparator
   * @param {string} thousandSeparator
   * @param {boolean} showThousandSeparator
   * @param {boolean} enforceDecimals, when true will always show decimals, even if they are 0
   * @returns {string}
   */
  formatLocaleNumber(
    value: number,
    decimals: number = 0,
    decimalSeparator: string = '.',
    thousandSeparator: string = ',',
    showThousandSeparator: boolean = false,
    enforceDecimals: boolean = false,
  ): string {
    const numberLocale = this.getNumberLocaleFromDecimalAndThousandSeparators(decimalSeparator, thousandSeparator);
    const options = {
      maximumFractionDigits: decimals,
      useGrouping: showThousandSeparator,
    } as Intl.NumberFormatOptions;
    if (enforceDecimals) {
      options.minimumFractionDigits = decimals;
    }
    const result = new Intl.NumberFormat(numberLocale, options).format(value);
    return result;
  }

  /**
   * Updates the fieldConfig with the currency prefix and suffix
   * @param {CipoFieldConfig} fieldConfig
   * @returns {CipoFieldConfig}
   */
  updateCipoFieldConfigIfCurrencyIsSet(fieldConfig: CipoFieldConfig): CipoFieldConfig {
    if (!fieldConfig.currency) {
      return fieldConfig;
    }
    const updatedData = {
      ...fieldConfig,
      prefix: this.getCurrencyPrefix(fieldConfig.currency),
      suffix: this.getCurrencySuffix(fieldConfig.currency),
    };
    return updatedData;
  }

  /**
   * Convert multiple types of true to boolean;
   * @param value
   * @returns {boolean}
   */
  convertValueToBoolean(value: any): boolean {
    if (value === null || value === undefined) {
      return false;
    }

    if (typeof value === 'string') {
      value = value.toLocaleLowerCase();
    }

    switch (value) {
      case true:
      case 'true':
      case 1:
      case '1':
      case 'on':
      case 'yes':
        return true;
      default:
        return false;
    }
  }

  /**
   * Transforms a number or string value into a formatted currency or number string.
   * @param value - The input value to be transformed.
   * @param decimals - The number of decimal places to display (default: 0).
   * @param showComma - Whether to show thousand separators (default: true).
   * @param showCurrency - Whether to display the currency symbol (default: false).
   * @param showPercentage - Whether to display as a percentage (default: false).
   * @param currency - The currency object containing symbol and formatting options (optional).
   * @param percentageSymbol - The symbol to use for percentage (default: '%').
   * @returns An Observable that emits the formatted string.
   */
  public transformToNumberOrCurrencyString(
    value: number | string | null | undefined,
    decimals: number = 0,
    showComma: boolean = true,
    showCurrency: boolean = false,
    showPercentage: boolean = false,
    currency: Currency | null | undefined = undefined,
    percentageSymbol: string = '%',
  ): string {
    const userSettings = this.userStore.calculatedUserSettings();
    return this.convertValueToNumberOrCurrencyString(
      value,
      decimals,
      showComma,
      showCurrency,
      showPercentage,
      currency,
      percentageSymbol,
      userSettings?.decimalSeparator,
      userSettings.thousandSeparator,
    );
  }

  /**
   * Converts a number or string value into a formatted currency or number string.
   * @param value - The input value to be transformed.
   * @param decimals - The number of decimal places to display (default: 0).
   * @param showComma - Whether to show thousand separators (default: true).
   * @param showCurrency - Whether to display the currency symbol (default: false).
   * @param showPercentage - Whether to display as a percentage (default: false).
   * @param currency - The currency object containing symbol and formatting options (optional).
   * @param percentageSymbol - The symbol to use for percentage (default: '%').
   * @param decimalSeparator - The decimal separator.
   * @param thousandSeparator - The thousand separator.
   * @returns An Observable that emits the formatted string.
   */
  public convertValueToNumberOrCurrencyString(
    value: number | string | null | undefined,
    decimals: number = 0,
    showComma: boolean = true,
    showCurrency: boolean = false,
    showPercentage: boolean = false,
    currency: Currency | null | undefined = undefined,
    percentageSymbol: string = '%',
    decimalSeparator: string = '.',
    thousandSeparator: string = ',',
  ): string {
    if (value === undefined || value === null) {
      return '';
    }

    if (showCurrency && value?.toString().includes(currency?.symbol ?? this.currencySymbolDefault)) {
      return value.toString();
    } else if (showPercentage && value?.toString().includes(percentageSymbol)) {
      return value.toString();
    }

    let newValue = value;
    const numberValue: number = typeof newValue == 'string' ? Number((newValue as any)?.toString()) : newValue;
    if (numberValue === undefined || numberValue === null || isNaN(numberValue)) {
      return value?.toString();
    }

    if (showCurrency && (currency === null || currency === undefined)) {
      currency = { symbol: this.currencySymbolDefault } as Currency;
    }

    const numberValueAbs: number = Math.abs(numberValue);
    const ret = this.formatLocaleNumber(numberValueAbs, decimals, decimalSeparator, thousandSeparator, showComma, true);
    const prefixNegative = numberValue < 0 ? '-' : '';

    if (showPercentage) {
      return `${prefixNegative}${ret}${percentageSymbol}`;
    }
    
    if (showCurrency) {
      const prefixCurrency = this.getCurrencyPrefix(currency, prefixNegative);
      const suffixCurrency = this.getCurrencySuffix(currency);
      return `${prefixCurrency}${ret}${suffixCurrency}`;
    }

    return `${prefixNegative}${ret}`;
  }

  private getCurrencyPrefix(currency: Currency, prefixNegative?: string): string {
    const leftSide = currency?.leftSide ?? this.leftSideDefault;
    const noSpace = currency?.noSpace ?? this.noSpaceDefault;
    const symbol = currency?.symbol ?? this.currencySymbolDefault;

    const prefixSpace = !noSpace && leftSide ? ' ' : '';
    const prefixCurrency = leftSide ? symbol : '';
    prefixNegative = prefixNegative ?? '';

    if (noSpace) {
      return `${prefixNegative}${prefixCurrency}`;
    }

    return `${prefixCurrency}${prefixSpace}${prefixNegative}`;
  }

  private getCurrencySuffix(currency: Currency): string {
    const leftSide = currency?.leftSide ?? this.leftSideDefault;
    const noSpace = currency?.noSpace ?? this.noSpaceDefault;
    const symbol = currency?.symbol ?? this.currencySymbolDefault;

    const suffixSpace = !noSpace && !leftSide ? ' ' : '';
    const suffixCurrency = !leftSide ? symbol : '';

    if (noSpace) {
      return suffixCurrency;
    }

    return `${suffixSpace}${suffixCurrency}`;
  }

  private getNumberLocaleFromDecimalAndThousandSeparators(decimalSeparator: string, thousandSeparator: string): string {
    if (decimalSeparator === ',' && thousandSeparator === '.') {
      return 'ro-RO';
    }
    return 'en-US';
  }

  /**
   * Get time progress based on start and end date;
   * @param {string} start
   * @param {string} end
   * @returns {number} percentage
   */
  //   not used for now, but might be useful in the future
  //   getTimeProgress(start: string, end: string): number {
  //     const startDate = new Date(start);
  //     const endDate = new Date(end);
  //     const now = new Date();
  //     if (!startDate.getTime() || !endDate.getTime() || startDate.getTime() > endDate.getTime()) {
  //       return null;
  //     }
  //     const total = endDate.getTime() - startDate.getTime();
  //     const elapsed = now.getTime() - startDate.getTime();
  //     return Math.round((elapsed / total) * 100);
  //   }
}
