import { cipo } from 'cipo';

cipo.controller('sovController',
    function ($transition$, $scope, $state, Model, ACTIONS, userService, currencyService, fileService, URI, SOV, Manager, Message, Upload, $timeout, $window, $mdDialog) {
        const moduleCode = 'SOV';
        const routeParams = $transition$.params()
        $scope.module = userService.system.modules[moduleCode];

        // get live operations
        Object.defineProperty($scope, 'operations', {
            get: function () { return userService.getOperationsFor(moduleCode); }
        });
        
        currencyService.getCurrency().then(function (c) {
            $scope.currency = c;
        });;
        
        $scope.ppOperations = { Read: true };

        Object.defineProperty($scope, 'userService', {
            get: function () {
                return userService;
            }
        });

        Object.defineProperty($scope, 'nonBindingSignature', {
            get: function () {
                return (userService.system.userdata.signature || {}).signatureText }
        });
        
        $scope.toggleItems = _toggleItems;
        $scope.editItem = _editItem;
        $scope.deleteItem = _deleteItem;
        $scope.toggleSovItemView = _toggleSovItemView;
        $scope.additionalSovData = {
            toggleItems: $scope.toggleItems,
            toggleSovItemView: $scope.toggleSovItemView,
            currencyChange: function (currency) { 
                $scope.currency = currency; 
            }
        };
        $scope.Math = Math;
        $scope.sovView = true;
        $scope.usingWbsView = false;
        $scope.hasManageDrafts = $scope.operations["Manage Drafts"];

        function _toggleSovItemView (override = null) {
            $scope.usingWbsView = override ?? !$scope.usingWbsView;
        }

        $scope.printMe = function () {
            // $scope.pp
            if ($("#section-to-print").length != 0) {
                $("#section-to-print").remove();

            }
            var div = $('<div />').appendTo('body');
            div.attr('id', 'section-to-print');

            $timeout(function () {
                $("#section-to-print").html($("#print-content").html());
                $window.print();
            }, 400)
            // $window.print();
        }

        $scope.$on("$mdMenuClose", function () {
            try {
                $scope.sov.assignments.searchUser = '';
            } catch { }
        });

        $scope.deleteFromList = function (id) {
            $scope.loadingList = true;
            var dataURL = URI.SOV.SOV_DELETE;
            Model[dataURL.method](dataURL, { url: { contractId: userService.system.userdata.contractId, entityInstanceId: id }, urltype: 'obj' })
                .then(function () {
                    $scope.loadSOVList();
                    // $scope.loadingList = false;
                })
                .catch(function () {
                    Message.dberror(e);
                    $scope.loadingList = false;
                })
        }

        $scope.multiSOVCols = [
            // { name: 'paidAmount', label: "Paid amount" },
            { name: 'totalAmount', label: "Total amount" },
            { name: 'status', label: "Status", isStatus: true },
            { name: 'updatedOn', label: "Updated on" },
            { name: 'updatedBy', label: "Updated by" },
            { name: 'createdOn', label: "Created on" },
            { name: 'createdBy', label: "Created by" }
        ];

        $scope.loadSOVDetails = function (id) {
            var id = id || 0;
            // $scope.hasApproval = false;
            $scope.sov = new SOV($scope.module.moduleId, id, $scope.additionalSovData);
        }

        $scope.loadSOVList = function () {
            $scope.loadingList = true;
            $scope.sov = null;
            var sovSearchURL = URI.SOV.SOV_MULTIPLE_SEARCH;
            var params = {
                contractId: userService.system.userdata.contractId || 0
            };
            $scope.hasDraft = false;
            Model[sovSearchURL.method](sovSearchURL, { url: params, urltype: 'obj' })
                .then(function (r) {
                    for (var i = 0; i < r.data.length; i++) {
                        if (r.data[i].isDraft && (!r.data[i].is_approval || r.data[i].isOwner) || r.data[i].isRevision) {
                            $scope.hasDraft = true;
                        }
                    }
                    $scope.sovList = r.data;
                })
                .catch(function (e) {
                    Message.dberror(e);
                })
                .finally(function () {
                    $scope.loadingList = false;
                })
        }
        $scope.createSOV = function () {
            $scope.sov = new SOV($scope.module.moduleId, 0, $scope.additionalSovData);
            $scope.sov.create()
                .then(function (r) { $scope.hasDraft = true; })
                .catch(function (r) { })
        }

        $scope.approve = function (isApprove) {
            $scope.sov.approve(isApprove)
                .then(function (r) { $scope.loadSov(); })
                .catch(function () { })
        }

        $scope.loadSov = function (id) {
            var dataURL = URI.SOV.SOV_APPROVALS;
            var params = {
                contractId: userService.system.userdata.contractId || 0
            };

            Model[dataURL.method](dataURL, { url: params, urltype: 'obj' })
                .then(function (r) {
                    $scope.sov = null;

                    $scope.hasApproval = (r || {}).hasApprovals || false;
                    $scope.userIsInitiator = (r || {}).userIsInitiator || false;
                    if ($scope.hasApproval) {
                        $scope.loadSOVList();
                    } else {
                        $scope.sov = new SOV($scope.module.moduleId, id, $scope.additionalSovData);
                        $scope.sov.get_other_drafts()
                                .then(function () {
                                    $scope.iHaveADraft = $scope.sov.iHaveADraft();
                                });
                    }
                })
                .catch(function (e) {
                    Message.dberror(e);
                });
        }

        $scope.backToSOV = function() {
            if ($scope.iHaveADraft && $scope.sov) {
                $scope.sov.openOtherDrafts();
            }
            else {
                $scope.loadSov(0);
            }
        }

        $scope.processMyWbs = function () {
            $scope.importLoader = true;
            $scope.uploadMessage = "Processing WBS data";
            $scope.sov.hasWbs = true;
            $scope.sov.processWBSData()
                .then(function () {
                    Message.info("Successfully processed WBS data");
                })
                .catch(function () {
                    Message.error("Errors occured while processing WBS data");
                })
                .finally(function () {
                    $scope.uploadMessage = "";
                    $scope.importLoader = false;
                    $scope.sov.get_wbs();
                });
        }

        $scope.uploadFiles = function (file, errFiles, isWbs) {
            $scope.f = file;
            $scope.errFile = errFiles && errFiles[0];
            $scope.importLoader = true;
            var importType = isWbs ? 'WBS' : 'SoV';
            $scope.uploadMessage = `${importType} Import in progress. This may take a few minutes`;
            var uploadUri = isWbs ? URI.WBS.IMPORT : URI.SOV.SOV_IMPORT;
            var params = `?${(isWbs ? 'contractEntityInstanceId' : 'entityInstanceId')}=` + $scope.sov.entityInstanceId;
            params += true ? `&contractId=${userService.system.userdata.contractId}` : '';
            if (file) {
                file.upload = Upload.upload({
                    method: uploadUri.method,
                    url: uploadUri.toString() + params,
                    data: { file: file }
                });

                file.upload.then(function (response) {
                    $timeout(function () {
                        file.result = response.data;
                        Message.info("File imported successfully");
                        
                        
                        if (isWbs) {
                            $scope.uploadMessage = "Processing WBS data";
                            $scope.sov.hasWbs = true;
                            $timeout(function () {
                                $scope.sov.processWBSData()
                                    .then(function () {
                                        Message.info("Successfully processed WBS data");
                                    })
                                    .catch(function () {
                                        Message.error("Errors occured while processing WBS data");
                                    })
                                    .finally(function () {
                                        $scope.uploadMessage = "";
                                        $scope.importLoader = false;
                                        $scope.usingWbsView = true;
                                        $scope.loadSov();
                                    });
                            }, 1000)
                            
                        } else {
                            $scope.importLoader = false;
                            $scope.uploadMessage = "";
                            $scope.sov.get_categories();
                        }
                    });
                }, function (response) {
                    if (response.status > 0)
                        $scope.errorMsg = response.status + ': ' + response.data;
                    $scope.importLoader = false;

                    var errorMsg = response.data.messages.unitType != null
                        ? ' The field "Unit Type" is required.'
                        : 'Please check your file and try again.';
                    
                    isWbs || response
                        ? Message.dberror(response)
                        : Message.error(`${importType} import failed. ${errorMsg}`);
                    
                        $scope.uploadMessage = "";
                        $scope.loadSov();
                }, function (evt) {
                    file.progress = Math.min(100, parseInt(100.0 *
                        evt.loaded / evt.total));
                });
            }
        }

        $scope.$watch(function () {
            return userService.isLoadingMenu;
        },
            function (n, o) {
                //console.error('userService.isLoadingMenu', n, o);
                if (!n && typeof userService.getOperationsFor(moduleCode).Read != 'undefined') {
                    $scope.sovView = true;
                    $scope.loadSov(routeParams.entityInstanceId * 1);
                } else if (!n) {
                    $state.go("tenant.404", { tenantIdentifier: routeParams.tenantIdentifier });

                }
            });

        $scope.$watch(function () {
            return userService.system.userdata.contractId;
        });

        $scope.togglePPItems = function (c) {
            c.isCollapsed = !c.isCollapsed;
        }

        var formatMoney = function (amount) {
            return userService.formatNumber(amount || '0', 2, true, true, false, $scope.currency);
        }

        $scope.toggleNodeItems = function(node) {
            node.isExpanded = !node.isExpanded;
        }

        function _toggleItems (c, sovWbsId = null) {
            // Flag used for display when using tree
            if (sovWbsId) {
                c.isExpanded = !c.isExpanded;
                c.key = c.categoryId;
            }

            if (c.manager) delete c.manager;
            else {
                c.manager = new Manager({
                    objectsPerPage: 20,
                    dataURL: URI.SOV.SOVI_SEARCH,
                    urlParams: {
                        contractId: userService.system.userdata.contractId,
                        categoryId: c.key,
                        sovEntityInstanceId: $scope.sov.entityInstanceId,
                        sovWbsId: sovWbsId
                    },
                    options: {
                        multiselect: false
                    },
                    menuClass: "inlineManager",
                    rowOnClick: function (row) {
                        $scope.editItem(row, $scope.sov.entityInstanceId);
                    },
                    dataWrapper: 'data',
                    rowClass: function (row) {
                        return row.has_payment && !$scope.sov.generalInfo.isClosedState ? 'rowDisbled' : '';
                    },
                    parseData: function (data, columns) {
                        var data = data || [], retval = [], columnsLookup = {}, columns = columns || [];

                        for (var j = 0; j < columns.length; j++) {
                            columnsLookup[columns[j].name] = columns[j];
                        }
                        for (var i = 0; i < data.length; i++) {
                            for (var key in data[i]) {
                                if (data[i].hasOwnProperty(key) && typeof columnsLookup[key] != 'undefined' && key != 'category_id') {
                                    if (data[i][key] !== "" && data[i][key] !== null) {
                                        if (columnsLookup[key].typeId == 3) {
                                            data[i][key] = ([true, false].indexOf(data[i][key]) != -1) ? (data[i][key] ? "Yes" : "No") : data[i][key] || "-";
                                        } else if (columnsLookup[key].typeId == 2 && (columnsLookup[key].isPayment || columnsLookup[key].isPercent)) {
                                            data[i][key] = (data[i][key] || "") * 1;
                                        } else if (columnsLookup[key].typeId == 5 && columnsLookup[key].showThumbnail) {
                                            if (data[i][key] && data[i][key + '_thumb'])
                                                data[i][key + '_thumb'] = '<span class=""><img src="data:'
                                                    + 'image/jpeg;base64,'
                                                    + data[i][key + '_thumb'] + '" />'
                                                    + '</span>';
                                            else {
                                                var fieldValue = data[i][key];
                                                var file = { name: (fieldValue || '').split(',')[0].trim() };
                                                if (file.name) {
                                                    fileService.setMimeTypeFromExt(file);
                                                    data[i][key + '_thumb'] = `<span class='fileInfo ${file.fileClass}'></span>`;
                                                    data[i][key] = fieldValue;
                                                }
                                                else {
                                                    data[i][key + '_thumb'] = `<span>-</span>`;
                                                    data[i][key] = '';
                                                }
                                            }
                                        } else {
                                            // no parsing
                                            data[i][key] = data[i][key];
                                        }
                                    } else {
                                        data[i][key] = '-';
                                    }
                                }
                            }

                            // data[i].total = '<span class="text-right" style="display: block;">' + formatMoney(data[i][priceId] * data[i][quantityId]) + '</span>';
                            data[i].total = '<span class="text-right" style="display: block;">' + formatMoney(data[i].total) + '</span>';
                            data[i]._amount = '<span class="text-right" style="display: block;">' + formatMoney(data[i].amount) + '</span>';
                            retval.push(data[i]);
                        }

                        return retval;
                    },
                    leftActions: [],
                    actions: [
                        {
                            setProperties: ACTIONS.REFRESH,
                            click: function () {
                                c.manager.refresh();
                            }
                        },
                        {
                            setProperties: ACTIONS.DETAILS,
                            name: $scope.sov.generalInfo.isDraftState && !$scope.sov.generalInfo.approvalId 
                                  || $scope.sov.generalInfo.canModify && $scope.sov.generalInfo.canUpdate
                                        ? 'Edit' 
                                        : 'View',
                            click: function (row) {
                                $scope.editItem(row, $scope.sov.entityInstanceId);
                            },
                            setActionName: function (action, row) {
                                return row.has_payment ? 'View' : action.name;
                            }
                        },
                        {
                            setProperties: ACTIONS.DELETE,
                            alwaysOnTop: false,
                            //alwaysOnRow: true,
                            condition: function (row) {
                                return $scope.sov.generalInfo.isDraftState && !row.has_payment || $scope.sov.generalInfo.canModify && $scope.sov.generalInfo.canUpdate && !row.has_payment;
                            },
                            click: function (row) {
                                $scope.deleteItem(c, row.entity_instance_id);
                            }
                        }
                    ]
                });

                var quantityId, priceId;

                var setCols = function (c, fields) {
                    var r = fields || [];
                    var columns = [];
                    var parts = 0;

                    if (r && r.length) {
                        for (var i = 0; i < r.length; i++) {
                            if (!r[i].isFilter) {
                                if (r[i].name == 'amount')
                                    parts = 2 * r[i].width;
                                else
                                    parts = r[i].width;
                            }
                        }
                    }

                    if (r && r.length) {
                        for (var i = 0; i < r.length; i++) {

                            if (r[i].name == "Quantity") quantityId = r[i].id;
                            if (r[i].name == "Unit Price") priceId = r[i].id;
                            r[i].restrictionsLookup = {};

                            if (!r[i].isSystem || r[i].name == "Name") {
                                var dataSourceLookup = {};
                                if (r[i].dataSourceValues) {
                                    for (var j = 0; j < r[i].dataSourceValues.length; j++) {
                                        var value = r[i].dataSourceValues[j]['value'] ? r[i].dataSourceValues[j]['value'] : "";
                                        dataSourceLookup[r[i].dataSourceValues[j]['key'].toString()] = value;
                                    }
                                }

                                if ((r[i].restrictions || []).length) {
                                    for (var j = 0; j < r[i].restrictions.length; j++) {
                                        r[i].restrictionsLookup[r[i].restrictions[j].key] = r[i].restrictions[j].value || true;
                                    }
                                }

                                if (r[i].name != 'category_id' && r[i].name != 'amount') {
                                    if (r[i].name == 'item_description') {
                                        r[i].onTablet = false;
                                        r[i].onPhone = false;
                                    } else {
                                        r[i].onTablet = true;
                                        r[i].onPhone = true;
                                    }
                                    columns.push({
                                        name: r[i].id > 0 ? r[i].id : r[i].name,
                                        label: r[i].label,
                                        isFilter: r[i].isFilter,
                                        type: r[i].name == 'state_name' ? 'state' : r[i].typeId == 4 ? 'date' : r[i].typeId == 2 ? 'number_with_formattings' : r[i].typeId == 3 ? 'checkbox' : 'text',
                                        typeId: r[i].typeId,
                                        fieldTypeId: r[i].fieldTypeId,
                                        valueLookup: dataSourceLookup,
                                        onTablet: r[i].onTablet,
                                        onPhone: r[i].onPhone,
                                        width: 100 / parts * r[i].width,
                                        restrictionsLookup: r[i].restrictionsLookup,
                                        restrictions: r[i].restrictions,
                                        formattings: r[i].formattings,
                                    });
                                }

                                if (r[i].name == 'amount') {
                                    columns.push({
                                        name: '_amount',
                                        label: r[i].label,
                                        type: 'number',
                                        typeId: r[i].typeId,
                                        fieldTypeId: r[i].fieldTypeId,
                                        valueLookup: dataSourceLookup,
                                        replacementFor: 'amount',
                                        onTablet: true,
                                        onPhone: true,
                                        width: 100 / parts * r[i].width,
                                        restrictionsLookup: r[i].restrictionsLookup,
                                        restrictions: r[i].restrictions,
                                        formattings: r[i].formattings,
                                    });

                                    columns.push({
                                        name: 'total', label: "Total", type: 'number', fieldTypeId: 2, valueLookup: {}, onTablet: true,
                                        onPhone: true, width: r[i].width,
                                        restrictionsLookup: r[i].restrictionsLookup,
                                        restrictions: r[i].restrictions,
                                        formattings: r[i].formattings,
                                    });
                                }  
                            }
                        }
                    }

                    c.manager.set_Columns(columns);
                    c.manager.loading = true;
                    c.manager.loadPage().finally(function () {
                        if (c.manager)
                            c.manager.loading = false;
                    });
                }

                if ($scope.sov.gridfields && $scope.sov.gridfields.length) {
                    setCols(c, $scope.sov.gridfields);
                } else {
                    $scope.sov.get_gridfields()
                        .then(function () {
                            setCols(c, $scope.sov.gridfields);
                        })
                        .catch(function () {
                            c.manager.loading = false;
                        })
                }
            }
        }

        $scope.editWbs = function () {
            $mdDialog.show({
                locals: { 
                    contractId: userService.system.userdata.contractId,
                    sovEntityInstanceId: $scope.sov.entityInstanceId 
                },
                controller: 'editSovWbsController',
                templateUrl: '/ng/views/admin/modals/editSovWbs.html',
                parent: angular.element(document.body),
                fullscreen: true,
                escapeToClose: false,
                clickOutsideToClose: false
            })
                .then(function () {
                }, function(wasModified) {
                    if (wasModified) {
                        $scope.sov.reload_wbs();
                    }
                });
        }

        function _editItem (i, sovEntityInstanceId) {
            var item = i || {};
            item.editable = $scope.sov.generalInfo.isDraftState && !$scope.sov.generalInfo.approvalId && !item.has_payment || $scope.sov.generalInfo.canModify && $scope.sov.generalInfo.canUpdate && !item.has_payment;
            item.entityInstanceId = $scope.sov.entityInstanceId;
            $mdDialog.show({
                locals: { item: item, contractId: userService.system.userdata.contractId, operations: $scope.operations, sovEntityInstanceId: sovEntityInstanceId },
                controller: 'editSovItemController',
                templateUrl: '/ng/views/admin/modals/editSovItem.html',
                parent: angular.element(document.body),
                //targetEvent: ev,
                fullscreen: true,
                escapeToClose: false,
                clickOutsideToClose: false
            })
            .then(function (r) {
                // $scope.sov.reload_categories();
                $scope.sov.reload_categories(true);
                $scope.sov.reload_wbs(true);
                $scope.sov.get_activity_summary();
            });
        }

        var processCatOnRemoveItem = function (c) {
            if (c.manager.records > 1)
                c.manager.loadPage();
            else {
                delete c.manager;
                c.isUsed = false;
            }

            $scope.sov.reload_categories();
            $scope.sov.get_activity_summary();
        }

        var confirm = function (title, text, ev) {
            return $mdDialog.confirm()
                .title(title)
                .textContent(text)
                .ariaLabel('Confirm Dialogue')
                .targetEvent(ev)
                .ok('Proceed')
                .cancel('Cancel');
        }
        function _deleteItem (c, id) {
            $mdDialog.show(confirm('Deleting Item', 'Are you sure you want to delete the selected Item?'))
                .then(function () {
                    c.manager.loading = true;
                    $scope.sov[URI.SOV.SOVI_DELETE.method](URI.SOV.SOVI_DELETE, { url: { entityInstanceId: id, contractId: userService.system.userdata.contractId }, urltype: 'obj' })
                        .then(function () {
                            processCatOnRemoveItem(c);
                            Message.info('Document(s) deleted successfully');
                        })
                        .catch(function (e) {
                            Message.dberror(e, c.manager.rows, 'id', 'name');

                            // c.manager.loading = false;
                            //Message.dberror(e);
                        })
                        .finally(function () {
                            (c.manager || {}).loading = false;
                        })
                })
                .catch(function (e) {
                    // cancel pressed
                });
        }

        $scope.delete = function (isPP) {
            $mdDialog.show(confirm(`Deleting ${$scope.module.name}`, `Are you sure you want to delete the ${$scope.module.name}?`))
                .then(function () {
                    // $scope.manager.loading = true;
                    $scope.sov.delete()
                        .then(function () { $scope.loadSov(); })
                        .catch(function () { })
                })
                .catch(function (e) {
                    // cancel pressed
                });
        }

        $scope.startTransition = function (t, role) {
            // in case we have other drafts, then we need to show a message
            if ($scope.sov.isDraft && $scope.sov.generalInfo.otherDraftsUser && $scope.sov.generalInfo.otherDraftsUser.length) {
                $mdDialog.show(confirm(`Submit ${$scope.sov.moduleName}`, `Submitting the ${$scope.sov.moduleName} will delete drafts created by other users. Continue ?`))
                .then(function () {
                    doStartTransition(t, role);
                })
                .catch(function (e) {
                    // cancel pressed
                });
            }
            else {
                doStartTransition(t, role);
            }
        }

        var doStartTransition = function (t, role) {
            $scope.sov.isBusy = true;
            if (parseInt(t.stateTypeId) !== 0 && !t.isRevise) {
                $scope.sov.get_fields(t.id, t.isComment)
                    .then(function (r) {
                        $scope.sov.isBusy = false;
                        if (t.isAutoTrigger && !(r.requiresNonBindingSignature && !$scope.nonBindingSignature) 
                            || r && !r.fields.length && r.hideRolesNotify && !(r.requiresNonBindingSignature && !$scope.nonBindingSignature) 
                            || r && !r.fields.length && !r.emailsDataSource.length && !r.hideRolesNotify && !(r.requiresNonBindingSignature && !$scope.nonBindingSignature)) {
                            $scope.sov.save(t.id, role.key)
                                .then(function () { $scope.loadSov(); })
                                .catch(function () { })
                        }
                        else {
                            r.rolesDict = angular.copy($scope.sov.rolesDict);
                            r.roleId = role.key;
                            showTransitionScreen(r, t);
                        }
                    })
                    .catch(function () { })
            } else {
                $scope.sov.save(t.id, role.key, t.isRevise)
                    .then(function () { $scope.loadSov(); })
                    .catch(function () { })
            }
        }

        var showTransitionScreen = function (r, t) {
            var dataURL, entityInstanceId;
            dataURL = URI.SOV.SOV_SAVE;
            if (t.isComment) dataURL = URI.MODULE.ADD_COMMENT;
            
            entityInstanceId = $scope.sov.entityInstanceId;
            // ppPeriodId = $scope.sovView ? 0 : "";
            $mdDialog.show({
                locals: {
                    info: r,
                    transition: t,
                    entityInstanceId: entityInstanceId,
                    dataURL: dataURL,
                    contractId: userService.system.userdata.contractId,
                    isPayment: !$scope.sovView,
                    selectedRoleId: r.roleId
                },
                controller: 'editTransitionScreenController',
                templateUrl: '/ng/views/admin/modals/editTransitionScreen.html',
                parent: angular.element(document.body),
                //targetEvent: ev,
                fullscreen: true,
                escapeToClose: false,
                clickOutsideToClose: false
            })
                .then(function (r) {
                    if ($scope.sovView) $scope.loadSov();

                }, function () {
                    $scope.sov.isBusy = false;
                });
        }
    });
