import { cipo } from 'cipo';

cipo.controller('editSovCategoriesController',
    function ($scope, Message, MODAL, Name, $mdDialog, $http, URI, userService) {

        $scope.modalInfo = MODAL;
        
        $scope.disabledBtn = false;
        $scope.actionBtnDisabled = false;
        $scope.sovModuleName = userService.system.modules['SOV']?.name || 'SOV';

        $scope.options = {
            beforeDrop: function (e) {
                //self.processNode(e);
                return true;
            },
            dropped: function (e) {
                processDrop(e);
            }
        }
        $scope.categories = [];
        $scope.loading = true;
        
        $http[URI.SOV_CATEGORY.SEARCH.method](URI.SOV_CATEGORY.SEARCH.toString())
            .then(function (r) {
                $scope.categories = r.data.data;             
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
            })
            .finally(function () {
                $scope.loading = false;
            })
        
        $scope.toggleForm = function (item) {
            if (item.form) {
                delete item.form;
                $scope.disabledBtn = false;
            } else {
                $scope.disabledBtn = true;
                item.form = new Name({ name: item.name, number: item.number });
                item.form.form.set_Description({
                    number: { label: "Category No", type: 'text', validation: { required: true, maxChars: 20 } }
                }, true);
                item.form.form.setTemplate('grid', [{ name: 60, number: 40 }]);
            }
        }

        $scope.toggleDelete = function (item) {
            item.deleteConfirm = !item.deleteConfirm;
            $scope.disabledBtn = !$scope.disabledBtn;
        }

        $scope.newcat = null;

        $scope.toggleAddItem = function () {
            if ($scope.newcat) {
                $scope.newcat = null;
            } else {
                $scope.newcat = new Name({ name: "", number: "" });
                $scope.newcat.form.set_Description({
                    number: { label: "Category No", type: 'text', validation: { required: true, maxChars: 20 } }
                }, true);
                $scope.newcat.form.setTemplate('grid', [{ name: 60, number: 40 }]);

            }
            $scope.disabledBtn = !$scope.disabledBtn;
        }

        $scope.createItem = function () {
            $scope.message = "Saving category...";
            $scope.newcat.form.loading = true;
            //item.name = item.form.properties.name;
            $scope.newcat.form.validate();
            if ($scope.newcat.form.isValid) {
                
                $http[URI.SOV_CATEGORY.CREATE.method](URI.SOV_CATEGORY.CREATE.toString(), $scope.newcat.properties)
                    .then(function (r) {
                        Message.info('Category saved successfully');
                        delete $scope.newcat.form;
                        $scope.newcat.properties.id = r.data;
                        $scope.categories.splice(0, 0, $scope.newcat.properties);
                        $scope.newcat = null;
                        $scope.disabledBtn = false;
                        syncPosition();
                    })
                    .catch(function (e) {
                        console.error(e);
                        $scope.newcat.form.catch(e);
                        $scope.newcat.form.loading = false;

                    })
                    .finally(function () {
                        $scope.message = "";
                    });
            }
            else {
                $scope.newcat.form.loading = false;
            }
        }

        $scope.deleteItem = function (scope, item) {
            item.deleting = true;
            $scope.message = "Deleting category...";

            $http[URI.SOV_CATEGORY.DELETE.method](URI.SOV_CATEGORY.DELETE.toString() + '?id=' + item.id)
                .then(function (result) {
                    Message.info('Category deleted successfully');
                    scope.remove();
                    $scope.disabledBtn = false;
                    syncPosition();
                })
                .catch(function (e) {
                    console.error(e);
                    item.deleting = false;
                    Message.dberror(e);
                })
                .finally(function () {
                    $scope.message = "";
                });
        }

        $scope.saveItem = function (item) {
            $scope.message = "Saving category...";
            item.form.form.loading = true;
            
            item.form.form.validate();
            if (item.form.form.isValid) {
                
                var temp = angular.copy(item);
                temp.name = temp.form.properties.name;
                temp.number = temp.form.properties.number;
                delete temp.form;
                $http[URI.SOV_CATEGORY.UPDATE.method](URI.SOV_CATEGORY.UPDATE.toString(), temp)
                    .then(function (result) {
                        Message.info('Category saved successfully');
                        item.name = item.form.properties.name;
                        item.number = item.form.properties.number;
                        delete item.form;
                        
                        $scope.disabledBtn = false;
                        $scope.isModified = true;
                    })
                    .catch(function (e) {
                        console.error(e);
                        item.form.form.catch(e);
                        item.form.form.loading = false;

                    })
                    .finally(function () {
                        $scope.message = "";
                        
                    });
            }
            else {
                item.form.form.loading = false;
            }
        };

        var processDrop = function (e) {
            if (e.source.index != e.dest.index) {
                syncPosition();
            }
        }

        var syncPosition = function (e) {
            var temp = [];

            for (var i = 0; i < $scope.categories.length; i++) {
                var item = { id: $scope.categories[i].id, position: i + 1, number: $scope.categories[i].number, name: $scope.categories[i].name};
                temp.push(item);
            }
            $http[URI.SOV_CATEGORY.SYNC_POSITION.method](URI.SOV_CATEGORY.SYNC_POSITION.toString(), temp)
                .then(function (result) {
                    $scope.isModified = true;
                })
                .catch(function (e) {
                    console.error(e);
                    Message.dberror(e);

                });
        };

        $scope.close = function () {
            if (!$scope.isModified) $mdDialog.cancel();
            else $mdDialog.hide();
        };
    });
