import { cipo } from 'cipo';

cipo.controller('pdfMarkupDocumentLinkController',
    function ($scope, $mdDialog, $http, $q, URI, MODAL, userService, documentId, entityInstanceId, Message, Form, PSPDFKIT_CONFIG, annotationClick, createAnnotation, deleteAnnotation) {
        /* FUNCTIONS */
        $scope.getLinks = _getLinks;
        $scope.toggleAddNew = _toggleAddNew;
        $scope.addNew = _addNew;
        $scope.getModules = _getModules;
        $scope.getDocuments = _getDocuments;
        $scope.close = _close;
        $scope.placeIcon = _placeIcon;
        $scope.getDateTime = _getDateTime;            
        $scope.updateForm = _updateForm;
        $scope.getFormDescription = _getFormDescription;
        $scope.setMessage = _setMessage;
        $scope.toggleDeleteLink = _toggleDeleteLink;
        $scope.deleteLink = _deleteLink;
        $scope.reset = _reset;
        $scope.isContractClosed = _isContractClosed;
        $scope.createAnnotation = createAnnotation || function () { };
        $scope.deleteAnnotation = deleteAnnotation || function () { };
        /* END FUNCTIONS */

        /* VARIABLES */
        $scope.modalInfo = MODAL;
        $scope.isLoading = true;
        $scope.documentId = documentId || null;
        $scope.entityInstanceId = entityInstanceId || null;
        $scope.operations = userService.getOperationsFor("PDFM");
        $scope.modules = [];
        $scope.documents = [];
        $scope.linkedDocuments = null;
        $scope.newItem = null;
        $scope.formTemplate = [{ moduleTypeId: 50, moduleTypeInstanceId: 50 }];
        $scope.messages = {
            loadingModules: 'Loading...',
            loadingDocuments: 'Loading documents...',
            ready: 'Select a document type and corresponding document.',
            saving: 'Saving...'
        }
        $scope.message = '';
        $scope.annotationClick = annotationClick || null;
        $scope.isAddAssociation = annotationClick != null;
        $scope.reportModuleId = -1;
        /* END VARIABLES */

        /* INIT */
        if ($scope.annotationClick) {
            $scope.toggleAddNew();
        }
        else {
            $scope.getLinks();
        }
        /* END INIT */

        /* WATCHERS */
        // Get proper documents if selected module changes
        $scope.$watch(function () { return $scope.newItem; }, function (n, o) {
            if (n && o && n.properties && o.properties && n.properties.moduleTypeId != o.properties.moduleTypeId) {
                if (n.properties.moduleTypeId != null) {
                    $scope.getDocuments();
                }
            }
        }, true);
        /* END WATCHERS */

        /* FUNCTION DECLARATION */
        function _getLinks() {
            $http.get(`${URI.PDF_MARKUP.GET_ASSOCIATED_DOCUMENTS}?entityInstanceId=${$scope.entityInstanceId}&documentId=${$scope.documentId}`)
                .then(function (res) {                      
                    var linkData = res.data;                      
                    linkData.forEach(function (linkItem) {
                        var x = $scope.getDateTime(linkItem.createdOn);
                        linkItem.createdOn = x;
                    });
                    $scope.linkedDocuments = linkData;
                })
                .catch(function (err) {
                    Message.dberror(err);
                })
                .finally(function () {
                    $scope.isLoading = false;
                });
        }

        function _toggleAddNew() {
            if ($scope.newItem) {
                $scope.close();
            } else {
                // Trigger cancel of screen so user can click the doc to add an item
                if (!$scope.annotationClick) {
                    $scope.placeIcon();
                }
                $scope.newItem = {};
                $scope.newItem.properties = { moduleTypeId: null, moduleTypeInstanceId: null };
                $scope.newItem.form = new Form($scope.newItem.properties);
                $scope.newItem.form.initializing = true;
                $scope.setMessage($scope.messages.loadingModules);
                $scope.getModules()
                    .then(function (res) {
                        $scope.modules = res;
                    })
                    .catch(function (err) {
                        console.error(err);
                    })
                    .finally(function () {
                        if ($scope.newItem.form) {
                            $scope.newItem.form.set_Description($scope.getFormDescription());
                            $scope.newItem.form.setTemplate('grid', $scope.formTemplate);
                            $scope.newItem.form.initializing = false;
                        }
                        $scope.setMessage($scope.messages.ready);
                    });
            }
        }

        function _addNew() {
            $scope.newItem.form.validate();
            if ($scope.newItem.form.isValid && $scope.annotationClick) {
                $scope.newItem.form.loading = true;
                $scope.setMessage($scope.messages.saving);
                var moduleTypeInstanceId = $scope.newItem.properties.moduleTypeInstanceId;
                $http.post(`${URI.PDF_MARKUP.SAVE_DOC_LINK}?entityInstanceId=${$scope.entityInstanceId}&documentId=${$scope.documentId}&moduleTypeInstanceId=${moduleTypeInstanceId}`)
                    .then(function (res) {
                        var customData = {
                            moduleTypeInstanceId: moduleTypeInstanceId
                        }
                        $scope.createAnnotation($scope.annotationClick, customData, PSPDFKIT_CONFIG.ANNOTATION_TYPES.DOCUMENT_LINK)
                            .then(function () {
                                Message.info("Document reference saved successfully.");
                                $scope.close();
                            })
                            .catch(function (err) { console.error(err); });
                    })
                    .catch(function (err) {
                        Message.dberror(err);
                        $scope.newItem.form.loading = false;
                    })
                    .finally(function () {
                        $scope.setMessage($scope.messages.ready);
                    });
            } else if (!$scope.annotationClick) {
                Message.Error('Failed to save document reference.')
            }
        }

        function _toggleDeleteLink(doc) {
            doc.confirmDeletion = !doc.confirmDeletion;
        }

        function _deleteLink(doc) {
            $scope.isLoading = true;
            doc.deleting = true;
            var kvp = { key: 'moduleTypeInstanceId', value: doc.moduleTypeInstanceId };
            $scope.deleteAnnotation(kvp, PSPDFKIT_CONFIG.ANNOTATION_TYPES.DOCUMENT_LINK)
                .then(function () {
                    var docIndex = $scope.linkedDocuments.indexOf(doc);
                    if (docIndex > -1) {
                        $scope.linkedDocuments.splice(docIndex, 1);
                    }
                })
                .catch(function (err) {
                    Message.dberror(err);
                })
                .finally(function () {
                    $scope.toggleDeleteLink(doc);
                    $scope.isLoading = false;
                    doc.deleting = false;
                });
        }

        function _getModules() {
            var deferred = $q.defer();

            if ($scope.modules.length) {
                deferred.resolve($scope.modules);
            } else {
                $http.get(`${URI.PDF_MARKUP.GET_LINKABLE_MODULES}?entityInstanceId=${$scope.entityInstanceId}`)
                    .then(function (res) {
                        var vData = res.data;
                        if (vData) {
                            for (var i = 0; i < vData.length; i++) {
                                if (vData[i].value && vData[i].value.toLowerCase().includes('Daily Report'.toLowerCase())) {
                                    $scope.reportModuleId = vData[i].key;
                                    break;
                                } 
                            }
                        }

                        deferred.resolve(res.data);
                    })
                    .catch(function (err) {
                        deferred.reject(err);
                    });
            }

            return deferred.promise;
        }

        function _getDocuments() {
            $scope.setMessage($scope.messages.loadingDocuments);
            $scope.newItem.form.loading = true;
            $scope.isLoadingDocuments = true;
            $scope.documents = [];
            $http.get(`${URI.PDF_MARKUP.GET_LINKABLE_DOCUMENTS}?entityInstanceId=${$scope.entityInstanceId}&moduleTypeId=${$scope.newItem.properties.moduleTypeId}&documentId=${$scope.documentId}`)
                .then(function (res) {
                    var vData = res.data;
                    if ($scope.newItem.properties.moduleTypeId == $scope.reportModuleId) {                           
                        if (vData) {
                            for (var i = 0; i < vData.length; i++) {
                                if (vData[i].value) {
                                    var desc = vData[i].value.substring(0, vData[i].value.indexOf(',') + 1) + " ";
                                    var dateStr = vData[i].value.substring(vData[i].value.indexOf(',') + 1).trim();
                                    vData[i].value = desc + userService.formatDate(dateStr);
                                }
                            }
                        }
                    }

                    $scope.documents = vData;
                })
                .catch(function (err) {
                    console.error(err);
                })
                .finally(function () {
                    $scope.updateForm();
                    $scope.newItem.properties.moduleTypeInstanceId = null;
                    $scope.newItem.form.loading = false;
                    $scope.setMessage($scope.messages.ready);
                });
        }

        function _getFormDescription() {
            return {
                moduleTypeId: { label: 'Document Type', type: 'select', options: $scope.modules, validation: { required: true } },
                moduleTypeInstanceId: { label: 'Document', type: 'select', options: $scope.documents, validation: { required: true } },
            };
        }

        function _updateForm() {
            $scope.newItem.form.set_Description($scope.getFormDescription());
        }

        function _setMessage(message) {
            $scope.message = message;
        }

        function _reset(all = false) {
            $scope.newItem = null;
            $scope.modules = [];
            $scope.documents = [];

            if (all) {
                $scope.annotationClick = null;
            }
        }

        function _close() {
            $scope.reset(true);
            var placeIcon = false;
            $mdDialog.cancel(placeIcon);
        }

        function _placeIcon() {
            $scope.reset(true);
            var placeIcon = true;
            $mdDialog.cancel(placeIcon);
        }

        function _getDateTime(dateTime) {
            return userService.formatDate(dateTime);
        }

        function _isContractClosed() {
			return userService.isContractClosed(null, $scope.entityInstanceId);
		}
        /* END FUNCTION DECLARATION */
    });
