import { Injectable } from '@angular/core';
import { HttpService } from '../../../shared/services/http.service';
import { WeatherFieldModel } from 'src/app/models/module/fields/main/weatherField.model';

@Injectable()
export class WeatherTableService {
  route: string = '_api/weatherField';
  constructor(private httpService: HttpService) {}

  getContractWeatherDataByTime(
    weatherTableId: number,
    entityInstanceId: number,
    associatedDate: string,
    fieldInstanceId: number,
    contractId: number,
  ) {
    return this.httpService.post<WeatherFieldModel>(`${this.route}/GetContractWeatherDataByTime`, {
      weatherTableId,
      entityInstanceId,
      associatedDate,
      fieldInstanceId,
      contractId,
    });
  }

  getAllWeatherDataIndependent(times: string[], contractId: number) {
    return this.httpService.post<WeatherFieldModel[]>(`${this.route}/GetWeatherDataIndependent`, {
      times,
      contractId,
    });
  }

  getContractWeatherDataByDateAndTime(associatedDate: string, contractId: number) {
    return this.httpService.post<WeatherFieldModel>(`${this.route}/GetContractWeatherDataByDateAndTime`, {
      associatedDate,
      contractId,
    });
  }

  createTimeFieldInstance(weatherTableId: number, entityInstanceId: number) {
    const params = this.httpService.buildHttpParams({ weatherTableId, entityInstanceId });
    return this.httpService.post<any>(`${this.route}/CreateTimeFieldInstance`, {}, { httpOptions: { params } });
  }

  getTimeFieldInstances(
    weatherTableId: number,
    entityInstanceId: number,
    actionInstanceId: number,
    associatedDate: string,
  ) {
    return this.httpService.post<WeatherFieldModel[]>(`${this.route}/GetTimeFieldInstances`, {
      weatherTableId,
      entityInstanceId,
      actionInstanceId,
      associatedDate,
    });
  }

  getTableTimeFieldId(weatherTableId: number, entityInstanceId: number) {
    const params = this.httpService.buildHttpParams({ weatherTableId, entityInstanceId });
    return this.httpService.get<number>(`${this.route}/GetTableTimeFieldId`, { httpOptions: { params } });
  }

  getWeatherTableAssociatedDate(weatherTableId: number, entityInstanceId: number) {
    const params = this.httpService.buildHttpParams({ weatherTableId, entityInstanceId });
    return this.httpService.get<{ date: string; fieldId: number }>(`${this.route}/GetWeatherTableAssociatedDate`, {
      httpOptions: { params },
    });
  }

  getWeatherTableAssociatedDateFieldId(weatherTableId: number) {
    const params = this.httpService.buildHttpParams({ weatherTableId });
    return this.httpService.get<number>(`${this.route}/GetWeatherTableAssociatedDateFieldId`, {
      httpOptions: { params },
    });
  }
}
