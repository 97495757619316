import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';
import { map, Observable, of } from 'rxjs';
import { GridSearchEntityInstance } from '../../../../models/module/grid';
import { ItvDateTimeWithRestrictionsPipe } from '../../../../shared/pipes/itv-date.pipe';
import { ItvNumberWithFormattingsPipe } from '../../../../shared/pipes/itv-number.pipe';
import { LocaleDateAdapter } from '../../../../shared/services/date.adapter';
import { LocaleService } from '../../../../shared/services/locale.service';
import { CalendarBaseViewComponent } from '../calendar-base-view.component';
import { CalendarTile, CalendarTileDoc, CalendarViewMode } from '../calendar.model';
import { CalendarService } from '../calendar.service';

@Component({
  selector: 'app-calendar-month-view',
  templateUrl: './calendar-month-view.component.html',
  styleUrls: ['./calendar-month-view.component.scss'],
})
export class CalendarMonthViewComponent extends CalendarBaseViewComponent {
  @Input() showWeekends: boolean;
  @Input() showWeekNumber: boolean;

  constructor(
    protected localeService: LocaleService,
    protected dateAdapter: LocaleDateAdapter,
    protected calendarService: CalendarService,
    protected dialog: MatDialog,
    protected itvDateTimePipe: ItvDateTimeWithRestrictionsPipe,
    protected itvNumberPipe: ItvNumberWithFormattingsPipe,
  ) {
    super(localeService, dateAdapter, calendarService, dialog, itvDateTimePipe, itvNumberPipe);
  }

  get weeks() {
    return Math.ceil((moment(this.date.date).daysInMonth() + moment(this.date.date).date(1).weekday()) / 7);
  }

  getDayOfWeekNames(): string[] {
    let firstDayOfWeek = this.dateAdapter.getFirstDayOfWeek();
    let names = this.dateAdapter.getDayOfWeekNames('short');
    if (!this.showWeekends) {
      names = names.slice(1, 6);
    }
    return [...names.slice(firstDayOfWeek), ...names.slice(0, firstDayOfWeek)];
  }

  getTiles(empty: boolean, docs?: GridSearchEntityInstance[]): Observable<CalendarTile[]> {
    const result: CalendarTile[] = [];

    const prevMonth = moment(this.date.date).add(-1, 'M').month();
    const nextMonth = moment(this.date.date).add(1, 'M').month();

    const dayOfWeek = moment(this.date.date).date(1).toDate().getDay();
    const firstDay =
      !this.showWeekends && this.isDateSaturday(dayOfWeek)
        ? 3
        : !this.showWeekends && this.isDateSunday(dayOfWeek)
          ? 2
          : 1;
    const day = this.getFirstCalendarDay();
    const today = moment(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()));

    return of(empty ? [] : this.processDocs(CalendarViewMode.Month, docs)).pipe(
      map((processedDocs: CalendarTileDoc[]) => {
        this.startDate = day.toDate();
        for (let i = 0; i < this.weeks * 7; i++) {
          const dt = day.toDate();
          const dtDay = day.date();
          const dtMonth = day.month();
          const addMonthName =
            (i == 0 && prevMonth < this.date.month) ||
            (dtDay == firstDay && dtMonth == this.date.month) ||
            (dtDay == 1 && dtMonth == nextMonth);
          const monthName = day.format('MMM').replace('.', '').substring(0, 3);
          const skip = this.isDateWeekend(dt.getDay()) && !this.showWeekends;
          const isToday = today.diff(dt, 'days') === 0;

          if (!skip) {
            const tile = {
              viewMode: CalendarViewMode.Month,
              date: dt,
              year: day.year(),
              month: dtMonth,
              week: day.isoWeek(),
              day: dtDay,
              text: `${dtDay}${addMonthName ? ' ' + monthName : ''}`,
              isToday: isToday,
              docs: [],
            } as CalendarTile;
            if (!empty) {
              tile.docs = this.getTileDocs(tile, processedDocs);
            }
            result.push(tile);
          }

          day.add(1, 'd');
        }
        this.endDate = day.toDate();
        return result;
      }),
    );
  }

  getTileDocs(tile: CalendarTile, docs: CalendarTileDoc[]): CalendarTileDoc[] {
    const found = (docs || []).filter(
      d => d.ref.year === tile.year && d.ref.month === tile.month && d.ref.day === tile.day,
    );
    return found;
  }

  private getFirstCalendarDay() {
    let dt = moment(new Date(this.date.year, this.date.month, 1)).weekday(0);
    while (!this.showWeekends && this.isDateWeekend(dt.toDate().getDay())) {
      dt.add(1, 'd');
    }
    return dt;
  }

  private isDateWeekend(dayOfWeek: number) {
    return this.isDateSaturday(dayOfWeek) || this.isDateSunday(dayOfWeek);
  }

  private isDateSaturday(dayOfWeek: number) {
    return dayOfWeek === 6; // 6 = Saturday
  }

  private isDateSunday(dayOfWeek: number) {
    return dayOfWeek === 0; // 0 = Sunday
  }
}
