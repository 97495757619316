<mat-drawer-container class="drawer-container">
    <mat-drawer [opened]="userStore.menu.isOpen()" mode="side" class="drawer-sidenav cipo-bg--primary-50">
        @if (showHeader()) {
            <app-menu #appmenu [menuItems]="userStore.menu().items" [menuFolderAutoCollapse]="false"></app-menu>
        }
    </mat-drawer>

    <mat-drawer-content fxLayout="column">
        @if (showHeader()) {
            <app-header fxFlex="none"></app-header>
        }
        <div fxFlex="grow" class="overflow-y--auto">
            <ng-content></ng-content>
        </div>
    </mat-drawer-content>
</mat-drawer-container>
