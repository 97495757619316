export interface Currency {
  currencyId: number;
  symbol: string;
  name?: string;
  abbreviation?: string;
  leftSide?: boolean;
  noSpace?: boolean;
}

export type CurrencyFn = (data: any) => Currency;
