import { cipo } from 'cipo';
import moment from 'moment';
import { DATE_TIME_FORMATS } from 'src/app/shared/consts';

cipo.factory("Schedule", function ($q, Model, URI, Message, Form, userService, Dictionaries, Permissions) {
    var Schedule = Model.extend(function (id) {

        // console.log('field obj', obj);
        var self = this;

        self.tabWarning = false;
        self.hightlight = false;

        self.init(false, id ? false : true);
        self.days = [];
        self.setReferenceData(self.properties);
        self.form = new Form(self.properties);
        self.form.initializing = true;
        
        self.form.set_Description({
            name: { label: 'Name', type: 'text', validation: { required: true, maxChars: 64 } },
            startDate: { label: 'Template Start Date', type: 'datepicker', options: { format: userService.userSettings().dateFormat }, validation: { required: true } },
            endDate: { label: 'Generate Calendar Until', type: 'datepicker', options: { format: userService.userSettings().dateFormat }, info: "Leave empty to generate automatically." },
            endCycle: { label: 'Template End Date', type: 'datepicker', options: { format: userService.userSettings().dateFormat }, validation: { required: true, dateAfter: 'startDate' } }
        });

        self.form.setTemplate('grid', [
            { name: 25, startDate: 25, endCycle: 25, endDate: 25 },
        ]);

        Object.defineProperty(self.form.fieldsList.endDate, 'editMode', {
            get: function () { return self.properties.endCycle ? true : false; },
        });

        self.form.store_Data();

        self.form.initializing = false;

        if (id) {
            self.properties.id = id;
            self.get_data();
        } else {
            // 
        }
        self.loadingWorkdays = true;
        self.workdaysDict = [];
        self.workdaysLookup = {};
        self.get_WorkdaysDict();
        
        
        self.isScheduleValid = true;
        
    });


    Schedule.prototype.init = function (isAdd, isNew) {
        var self = this;
        self.properties = {
            id: null,
            name: "",
            startDate: null,
            endDate: null,
            endCycle: null,
            cycleDays: [],
            permissions: isAdd || isNew ? [1, 2] : [2]
        }
        self.days = [];

        if (isNew || isAdd) {

            self.operations = {};
            for (var j = 0; j < self.properties.permissions.length; j++) {
                Object.assign(self.operations, Permissions[self.properties.permissions[j]]);
            }
        }
    }

    Schedule.prototype.get_WorkdaysDict = function () {
        var self = this;
        Dictionaries.ScheduleDays()
            .then(function (r) {
                self.workdaysDict = r;
                if (r && r.length)
                    for (var i = 0; i < r.length; i++) {
                        self.workdaysLookup[r[i].key] = r[i].value;
                    }
            })
            .catch(function (e) {

                console.error(e)
            })
            .finally(function () {
                self.loadingWorkdays = false;
            })
    }

    Schedule.prototype.get_data = function () {
        var self = this;
        var p = $q.defer();
        self.form.loading = true;
        var dataURL = URI.SCHEDULE.GET;
        self[dataURL.method](dataURL, { url: { id: self.properties.id }, urltype: 'obj' })
            .then(function (r) {
                if (r) {
                    if (!r.permissions) r.permissions = [2];
                    self.setReferenceData(r);
                    for (var key in r) {
                        if (r.hasOwnProperty(key)) {
                            if (key != 'startDate' && key != 'endDate') self.properties[key] = r[key];

                        }
                    }
                    // convert dates to moment
                    self.properties.startDate = userService.momentDate(r.startDate);
                    self.reference.startDate = self.properties.startDate.toISOString();
                    self.properties.endDate = userService.momentDate(r.endDate);
                    self.reference.endDate = self.properties.endDate ? self.properties.endDate.toISOString() : null;
                    self.properties.endCycle = r.startDate && r.cycleDays.length ? angular.copy(self.properties.startDate).add(self.properties.cycleDays.length, 'days') : null;
                    //console.error('self.properties', self.properties);
                    self.form.set_Data(self.properties);
                    self.form.store_Data();
                    //
                    // self.properties.permissions = [1, 2, 3];

                    self.operations = {};
                    if ((self.properties.permissions || []).length) {
                        for (var j = 0; j < self.properties.permissions.length; j++) {
                            Object.assign(self.operations, Permissions[self.properties.permissions[j]]);
                        }
                    }
                    if (!self.operations.Update) {
                        self.form.editMode = false;
                        // self.periodForm.editMode = false;
                    }
                    p.resolve();
                }
            })
            .catch(function (e) {
                console.error(e);
                p.reject();
            })
            .finally(function () {
                self.form.loading = false;
            })
        return p.promise;
    }

    Schedule.prototype.setReferenceData = function (data) {
        var self = this;
        self.reference = angular.copy(data);
        delete self.reference.endCycle;
    }

    Schedule.prototype.check_generateUntill = function () {
        var self = this;
        if (self.form && self.properties.endDate && self.properties.endCycle) {
            var endOfTemplateGen = self.properties.endDate.diff(self.properties.endCycle, 'years', true);
            if (endOfTemplateGen < 3) {

                self.form.setFieldInvalid('endDate', 'The calendar must be generated for at least 3 years');
            }
        }
        
    }


    Schedule.prototype.workdays = function () {
        var self = this;
        try {
            self.form.clearErrors();
            self.isScheduleValid = true;
            self.days = [];
            if (!self.lookup) {
                self.lookup = {};
            }
            if (moment(self.properties.startDate).isBefore(self.properties.endCycle, 'day')) {
                var scheduleDays = moment.duration(self.properties.endCycle.diff(self.properties.startDate)).asDays();
                var tempDay = angular.copy(self.properties.startDate);
                self.startDay = tempDay.day();
                for (var i = 0; i < scheduleDays; i++) {
                    var dateFormated = tempDay.format(userService.userSettings().dateFormat);
                    if (self.properties.cycleDays && self.properties.cycleDays.length) {
                        self.lookup[dateFormated] = self.properties.cycleDays[i].scheduleDayId;
                    }
                    else {
                        self.lookup[dateFormated] = self.lookup[dateFormated] ? self.lookup[dateFormated] : null;
                    }
                    self.days.push({
                        weekDay: tempDay.day(),
                        date: dateFormated,
                        scheduleDayId: self.lookup[dateFormated]
                    });
                    tempDay = tempDay.add(1, 'days');
                }

                self.updateLookup();
                if (self.properties.cycleDays.length) {
                    self.properties.cycleDays = [];
                    self.form.set_Data(self.properties);
                    self.form.store_Data();
                }
            }
            else {
                self.form.setFieldInvalid('endCycle', 'The end date must be after the start date');
            }
        } catch (e) { }
    }

    Schedule.prototype.updateLookup = function () {
        var self = this;
        self.lookup = {};
        for (var i = 0; i < self.days.length; i++) {
            self.lookup[self.days[i].date] = self.days[i].scheduleDayId;
        }
    }
    Schedule.prototype.validate_period = function () {
        var self = this;
        self.isScheduleValid = true;
        if (self.days && self.days.length)
            for (var i = 0; i < self.days.length; i++) {
                if (!self.days[i].scheduleDayId) {
                    self.isScheduleValid = false;
                    break;
                }
            }
    }

    Schedule.prototype.checkDirty = function () {
        var self = this;
        //console.error('check dirty', self.process_scheduleData(), self.reference)
        return !angular.equals(self.process_scheduleData(), self.reference);
    }

    Schedule.prototype.process_scheduleData = function () {
        var self = this;
        // $timeout(function () { console.log('schedule data', self); }, 500)
        
        var temp = angular.copy(self.properties);
        temp.startDate = temp.startDate ? temp.startDate.toISOString() : null;
        temp.endDate = temp.endDate ? temp.endDate.set({ h: 12, m: 0 }).toISOString() : null;
        temp.cycleDays = angular.copy(self.days);
        if (temp.cycleDays && temp.cycleDays.length) {
            for (var i = 0; i < self.days.length; i++) {
                temp.cycleDays[i].dayOfCycle = i + 1;
                delete temp.cycleDays[i].weekDay;
                delete temp.cycleDays[i].date;
                if (temp.cycleDays[i].details) delete temp.cycleDays[i].details;
            }
        }
        delete temp.endCycle;
        return temp;
    }

    Schedule.prototype.save = function (isAdd) {
        var self = this;
        self.form.validate();
        self.validate_period();
        self.check_generateUntill();

        var p = $q.defer();

        if (self.form.isValid && self.isScheduleValid) {

            var params = {
                url: !self.properties.id ? { roleId: self.selectedRoleId } : {}, urltype: 'obj',
                body: self.process_scheduleData()
            };
            self.form.loading = true;
            var dataURL = self.properties.id ? URI.SCHEDULE.UPDATE : URI.SCHEDULE.CREATE;
            self[dataURL.method](dataURL, params)
                .then(function (r) {
                    Message.info('Schedule saved successfully');
                    p.resolve();
                    if (!self.properties.id) {
                        self.properties.id = r;
                        self.get_data();
                        return;
                    }
                    if (isAdd) {
                        self.init(isAdd);
                    }
                    self.form.store_Data();
                    self.form.set_Data(self.properties);
                    self.setReferenceData(self.process_scheduleData());
                    
                })
                .catch(function (e) {
                    console.error(e);
                    // Message.dberror(e);
                    self.form.catch(e);
                    p.reject();
                })
                .finally(function () {
                    self.form.loading = false;
                })
        }
        else {
            // self.form.loading = false;
            p.reject();
        }
        return p.promise;
    }

    return Schedule;
});
