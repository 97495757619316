import { ChangeDetectionStrategy, inject } from '@angular/core';
import { Component } from '@angular/core';
import { UserStore } from '../../signal-store';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  userStore = inject(UserStore);
  year = new Date().getFullYear();

  enableDemo() {
    if (!environment.production) {
      this.userStore.enableDemo();
    }
  }
}
