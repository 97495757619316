import { Injectable, effect, inject } from '@angular/core';
import { UserStore } from '../../../../signal-store';
import { Language, LanguageNames } from '../../../../models/system';

@Injectable({
  providedIn: 'root',
})
export class DateTimeFormatService {
  private userStore = inject(UserStore);

  private defaultDateTimeFormats = {
    parseInput: 'MM/DD/YYYY',
    fullPickerInput: 'MM/DD/YYYY HH:mm A',
    datePickerInput: 'MM/DD/YYYY',
    timePickerInput: 'HH:mm',
    monthYearLabel: 'MMM YYYY',
  };

  getFormats() {
    const dateFormat = this.userStore.calculatedUserSettings().dateFormat;
    const timeFormat = this.userStore.calculatedUserSettings().timeFormat;
    if (!dateFormat || !timeFormat) {
      return this.defaultDateTimeFormats;
    }
    return {
      ...this.defaultDateTimeFormats,
      parseInput: dateFormat,
      fullPickerInput: `${dateFormat} ${timeFormat}`,
      datePickerInput: dateFormat,
    };
  }

  getLocale() {
    return this.userStore.language() ?? LanguageNames.get(Language.English);
  }
}
