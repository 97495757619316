import { cipo } from 'cipo';

cipo.controller('mainController', ['$scope', '$state', 'userService', 'authService', 
function ($scope, $state, userService, authService)
{
    $scope.chooseCompany = false;
    $scope.loading = false;

    $scope.setError = function (msg, retry)
    {
        var retry = retry || false;
        $scope.loading = false;
        $scope.chooseCompany = true;
        $scope.error = true;
        $scope.retry = retry;
        $scope.errorMsg = 'Error: ' + msg;
    };

    $scope.cancelError = function ()
    {
        $scope.error = false;
        $scope.retry = true;
        $scope.errorMsg = '';
        $scope.chooseCompany = false;

        userService.error = false;
    };

    $scope.back = function ()
    {
        userService.error = false;
        authService.logout();
    };

    $scope.loadTenant = function (tenantId)
    {
        $scope.chooseCompany = false;
        $scope.loading = true;

        userService.changeTenant(tenantId).then(function ()
        {
            var identifier = userService.getTenantIdentifierById(tenantId);
            var targetState = userService.targetState;

            $state.go(targetState, { tenantIdentifier: identifier });
        }).catch(function ()
        {
            $scope.setError('Could not load tenant data');
        });
    };

    $scope.processTenants = function ()
    {
        $scope.cancelError();

        if (!authService.isLoggedIn())
        {
            $state.go("login");
        }
    };

    $scope.processTenants();
}

]);