import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { computed, effect, inject } from '@angular/core';
import { patchState, signalStore, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';
import { tap } from 'rxjs/operators';

import { MeasurementSystem } from '../models/module/fields/enums/measurementSystem';
import { Tenant } from '../models/module/tenant';
import { TenantSetting, TenantSettingsEnum, TenantSettingValue } from '../models/tenant';
import {
  DateFormats,
  DateFormatType,
  DecimalSeparator,
  DecimalSeparatorType,
  ThousandSeparator,
  ThousandSeparatorType,
  TimeFormats,
  TimeFormatType,
} from '../models/user';
import { TenantSettingsService } from '../module/instance/tenant-settings/tenant-settings.service';
import { TENANT } from '../shared/consts';
import { TenantService } from '../shared/services';

export type TenantState = {
  settings: TenantSetting[];
  availableTenants: Tenant[];
  selectedTenant: Tenant;
  isLoading: boolean;
  loadingSettings?: boolean;
};

const initialState: TenantState = {
  settings: [],
  availableTenants: [],
  selectedTenant: null,
  isLoading: false,
  loadingSettings: false,
};

export const TenantStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withComputed(store => {
    return {
      selectedTenantName: computed(() => store.selectedTenant()?.name.toLowerCase()),
    };
  }),
  withDevtools('tenant'),
  withMethods((store, tenantSettingsService = inject(TenantSettingsService), tenantService = inject(TenantService)) => {
    const selectTenant = (tenant?: Tenant) => {
      if (tenant) {
        sessionStorage.setItem(TENANT, tenant.id.toString());
      } else {
        sessionStorage.removeItem(TENANT);
      }
      patchState(store, { selectedTenant: tenant });
    };

    const getCalculatedValues = (settings: TenantSetting[]): TenantSetting[] => {
      return [...(settings || [])].map(setting => ({ ...setting, calculatedValue: getCalculatedValue(setting) }));
    };

    const getCalculatedValue = (setting: TenantSetting): TenantSettingValue | undefined => {
      switch (setting.id) {
        case TenantSettingsEnum.date_format:
          return getValueFromMap(DateFormats, DateFormatType as any, setting.value);
        case TenantSettingsEnum.time_format:
          return getValueFromMap(TimeFormats, TimeFormatType as any, setting.value);
        case TenantSettingsEnum.decimal_separator:
          return getValueFromMap(DecimalSeparator, DecimalSeparatorType as any, setting.value);
        case TenantSettingsEnum.thousand_separator:
          return getValueFromMap(ThousandSeparator, ThousandSeparatorType as any, setting.value);
        case TenantSettingsEnum.load_more:
          return getBool(setting.value);
        case TenantSettingsEnum.measurement_system:
          return getValueFromEnum(MeasurementSystem, setting.value);
      }
      return setting.value;
    };

    const getValueFromMap = <T, V>(map: Map<T, V>, enumValue: T, value: TenantSettingValue | undefined | null): V => {
      if (!Number.isNaN(Number(value))) {
        return map.get(Number(value) as any);
      }
      return map.get(enumValue[value.toString()]);
    };

    const getValueFromEnum = <T>(enumValue: T, value: TenantSettingValue | undefined | null): keyof T => {
      if (!Number.isNaN(Number(value))) {
        return enumValue[Number(value)] as keyof T;
      }
      return value as keyof T;
    };

    const getBool = (value: TenantSettingValue | undefined | null): boolean => {
      return ['true', 'yes', '1'].includes(value?.toString()?.toLowerCase());
    };

    return {
      loadSettings: () => {
        patchState(store, { loadingSettings: true });
        tenantSettingsService
          .getTenantSettings()
          .subscribe(settings =>
            patchState(store, { settings: getCalculatedValues(settings), loadingSettings: false }),
          );
      },
      loadTenants: () => {
        patchState(store, { isLoading: true });
        return tenantService
          .getTenants()
          .pipe(tap(availableTenants => patchState(store, { availableTenants, isLoading: false })));
      },
      getIdByName: (name: string) => {
        const tenant = store.availableTenants().find(t => t.name.toLowerCase() === name.toLowerCase());
        return tenant ? tenant.id : null;
      },
      selectTenant,
      updateSettings: (settings: TenantSetting[]) => {
        patchState(store, { loadingSettings: true });
        setTimeout(() => {
          patchState(store, { settings: getCalculatedValues(settings), loadingSettings: false });
        }, 0);
      },
      updateSetting: (id: number, value: TenantSettingValue) => {
        const settings = store.settings().map(s => (s.id === id ? { ...s, value } : s));
        patchState(store, { settings: getCalculatedValues(settings) });
      },
      getSettings: (ids: TenantSettingsEnum[]) => store.settings().filter(s => ids.includes(s.id)),
      getSetting: (id: TenantSettingsEnum) => store.settings().find(s => id === s.id),
      getSettingValue: (id: TenantSettingsEnum) => store.settings().find(s => id === s.id)?.value,
    };
  }),
  withHooks(store => ({
    onInit: () => {
      effect(
        () => {
          if (store.selectedTenant()?.id) {
            store.loadSettings();
          }
        },
        { allowSignalWrites: true },
      );
    },
  })),
);
