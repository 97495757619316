import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Sort } from '@angular/material/sort';
import moment from 'moment';
import { WeatherFieldModel } from '../../../models/module/fields/main/weatherField.model';
import { NotificationService } from '../../../shared/services/notification.service';
import { UserStore } from '../../../signal-store';
import { TableDataSource } from './table-data-source.service';
import { WeatherTableService } from './weather-table.service';

@Component({
  selector: 'app-weather-table',
  templateUrl: './weather-table.component.html',
  styleUrls: ['./weather-table.component.scss'],
})
export class WeatherTableComponent implements OnInit, OnChanges, OnDestroy {
  userStore = inject(UserStore);

  displayedColumns: string[] = ['time', 'temperature', 'windSpeed', 'phrase', 'precipitation', 'delete'];
  dataSource: WeatherFieldModel[] = [];
  weatherTableDataSource: TableDataSource;

  @Input() editMode: boolean = false;
  @Input() fielddata: {
    _fieldProperty: number;
    entityInstanceId: number;
    actionInstanceId: number;
    _editMode: boolean;
    _value: any;
    contractId: number;
  };
  @Input('fieldid') fieldId: number;
  @Input() date: string;

  @Output('onchange') onChange = new EventEmitter();

  constructor(
    private weatherService: WeatherTableService,
    private changeDetectorRef: ChangeDetectorRef,
    private notification: NotificationService,
  ) {}

  ngOnInit(): void {
    this.weatherTableDataSource = new TableDataSource(
      this.weatherService,
      this.notification,
      this.changeDetectorRef,
      this.fielddata,
      this.fieldId,
      this.userStore.userData()?.timeZoneOffsetMinutes ?? 0,
    );
    this.weatherTableDataSource.loadData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.date && changes.date.currentValue !== undefined && this.weatherTableDataSource !== undefined) {
      this.weatherTableDataSource.changeDate(changes.date.currentValue);
    }
    if (changes.fieldId && changes.fieldId.currentValue !== undefined && this.weatherTableDataSource !== undefined) {
      this.weatherTableDataSource.fieldId = changes.fieldId.currentValue as number;
    }
  }

  ngOnDestroy(): void {
    this.weatherTableDataSource.destroy();
  }

  createTimeFieldInstance() {
    if (!this.weatherTableDataSource.isDateValid(this.weatherTableDataSource.associatedDate)) {
      this.notification.error('Missing date value.');
      return;
    }

    const dt = this.weatherTableDataSource.associatedDate.substring(0, 11) + moment.utc().format('HH:mm') + ':00Z';
    this.weatherTableDataSource.addRow(dt, this.fielddata.contractId).subscribe({
      next: () => {
        this.weatherTableDataSource.initializeFieldDataValue();
        this.onChange.emit();
      },
      error: error => this.notification.error(error),
    });
  }

  getWeatherDataByTime($event: any, element: WeatherFieldModel) {
    this.weatherTableDataSource.updateRow($event.target.value as string, element, () => {
      this.changeDetectorRef.detectChanges();
      this.onChange.emit();
    });
  }

  deleteRow(element: WeatherFieldModel) {
    this.weatherTableDataSource.removeRow(element);
    this.onChange.emit();
  }

  sortData(sort: Sort) {
    this.weatherTableDataSource.sortData(sort);
  }

  trackBy(index: number, stuff) {
    return stuff.fieldInstanceId;
  }
}
