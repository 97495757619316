import { MenuPermissionViewModel } from '../permission';
import { MenuType } from './menuType';

export interface MenuModel {
  id?: number;
  name: string;
  code?: RouteCodeEnum;
  iconId?: number;
  color?: string;
  position?: number;
  parentId?: number;
  typeId?: MenuType;
  moduleId?: number;
  perContract?: boolean;
  url?: string;
  email?: string;
  manageWorkflowGroups?: boolean;
  children?: MenuModel[];
  operations?: MenuPermissionViewModel[];
  openInNewTab?: boolean;
  hidden?: boolean;
  useDocMinor?: boolean;
  moduleTypeId?: MenuModuleTypeEnum;
  urlPath?: string;
}

export enum RouteCodeEnum {
  CompanyProfile = 'CP',
  Contacts = 'CTA',
  Contracts = 'CT',
  Correspondence = 'C',
  Dashboard = 'D',
  Entity = 'E',
  FileContractManager = 'FCM',
  FinancialSchedules = 'FSCH',
  Holidays = 'HLD',
  ListsManagement = 'LSTM',
  ManagementReports = 'MREP',
  Maps = 'MAPS',
  Menu = 'MN',
  Modules = 'M',
  MyCIPODrive = 'MCD',
  Organizations = 'O',
  Payments = 'PY',
  PDFMarkup = 'PDFM',
  PowerBI = 'PBI',
  ProgressPayment = 'PP',
  Roles = 'R',
  Schedules = 'SCH',
  SmartMap = 'SM',
  SOV = 'SOV',
  TenantSettings = 'TST',
  Users = 'U',
  ChangeOrder = 'CO',
  Programs = 'PRG',
  Projects = 'P',
}

export enum MenuModuleTypeEnum {
  System = 1,
  URL = 2,
  Custom = 3,
  Internal = 4,
  Table = 5,
  Reporting = 6,
}
