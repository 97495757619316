import { CommonModule } from '@angular/common';
import { Component, effect, inject, input, signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import moment from 'moment';

import { KeyValueType } from '../../../../models/common';
import { UserSettings } from '../../../../models/core';
import { Currency } from '../../../../models/system';
import { UtilsService } from '../../../services';
import {
  CipoDateTimePickerType,
  CipoDateTimeSelectMode,
  CipoFieldConfig,
  CipoFieldTypes,
  CipoFieldValue,
} from '../common';
import { UserStore } from '../../../../signal-store';

@Component({
  selector: 'cipo-text-view',
  standalone: true,
  imports: [FlexLayoutModule, MatIconModule, TranslateModule, CommonModule],
  templateUrl: './cipo-text-view.component.html',
})
export class CipoTextViewComponent {
  displayValue = signal('-');
  utilsService = inject(UtilsService);
  userStore = inject(UserStore);
  translate = inject(TranslateService);

  value = input<CipoFieldValue>('-');
  type = input<CipoFieldTypes>(CipoFieldTypes.Text);
  config = input<CipoFieldConfig>({});

  translationsLoaded = signal(false);

  userSettings = signal<UserSettings | null>(null);

  constructor() {
    this.translate.get('general.yes').subscribe(() => this.translationsLoaded.set(true));

    this.userSettings.set(this.userStore.calculatedUserSettings());

    effect(
      () => {
        // this esures that the effect is run after the translations are loaded
        if (!this.translationsLoaded() || !this.userSettings()) {
          return;
        }
        const {
          prefix = '',
          suffix = '',
          showThousandSeparator,
          multiple,
          options,
          pickerType,
          selectMode,
          decimals,
          currency,
        } = this.config();
        const value = this.value();
        if (!value) return;

        switch (this.type()) {
          case CipoFieldTypes.Text:
            this.handleText(multiple, prefix, suffix);
            break;

          case CipoFieldTypes.Number:
            this.handleNumber(multiple, prefix, suffix, decimals, currency, showThousandSeparator);
            break;

          case CipoFieldTypes.Select:
          case CipoFieldTypes.Listbox:
            this.handleSelect(options);
            break;

          case CipoFieldTypes.DateTime:
            this.handleDateTime(pickerType, selectMode);
            break;
        }
      },
      { allowSignalWrites: true },
    );
  }

  private handleText(multiple: boolean, prefix: string, suffix: string) {
    const baseVal = multiple ? (this.value() as string[]).join(', ') : this.value();
    this.displayValue.set(prefix + (baseVal || '-') + suffix);
  }

  private handleNumber(
    multiple: boolean,
    prefix: string,
    suffix: string,
    decimals: number,
    currency: Currency,
    showThousandSeparator: boolean,
  ) {
    if (multiple) {
      const values = this.value() as number[];
      const formattedVals = values.map(value =>
        this.handleNumberSingle(value, prefix, suffix, decimals, currency, showThousandSeparator),
      );
      this.displayValue.set(formattedVals.join(', '));
      return;
    }

    this.displayValue.set(
      this.handleNumberSingle(this.value() as number, prefix, suffix, decimals, currency, showThousandSeparator),
    );
  }

  private handleNumberSingle(
    value: number,
    prefix: string,
    suffix: string,
    decimals: number,
    currency: Currency,
    showThousandSeparator: boolean,
  ) {
    if (!currency?.symbol && prefix) {
      currency = { symbol: prefix, leftSide: true, noSpace: false } as Currency;
    }
    const formattedVal = this.utilsService.convertValueToNumberOrCurrencyString(
      value,
      decimals,
      showThousandSeparator,
      !!currency?.symbol,
      !!suffix,
      currency,
      suffix,
      this.userSettings().decimalSeparator,
      this.userSettings().thousandSeparator,
    );
    return formattedVal;
  }

  private handleSelect(options: KeyValueType[]) {
    const listboxVal = this.value() as string[];
    if (options?.length) {
      const selectedOptions = options.filter(option => listboxVal.includes(option.key));
      const displayValue = selectedOptions.map(option => option.value).join(', ');
      this.displayValue.set(displayValue || '-');
    } else {
      this.displayValue.set(listboxVal.join(', ') || '-');
    }
  }

  private handleDateTime(pickerType: CipoDateTimePickerType, selectMode: CipoDateTimeSelectMode) {
    const format = this.getDateTimeFormat(pickerType);

    if (selectMode === 'range') {
      const dateRange = this.value() as string[];
      const displayValue = dateRange.map(date => moment(date).format(format) || '-').join(' - ');
      this.displayValue.set(displayValue);
    } else {
      const date = this.value() as string;
      this.displayValue.set(moment(date).format(format) || '-');
    }
  }

  private getDateTimeFormat(pickerType: CipoDateTimePickerType) {
    switch (pickerType) {
      case 'time':
        return this.userSettings().timeFormat;
      case 'date':
        return this.userSettings().dateFormat;
      case 'dateTime':
      default:
        return this.userSettings().datetimeFormat;
    }
  }
}
