import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';
import { BehaviorSubject, Observable, Subject, distinctUntilChanged, map, of, switchMap, takeUntil, tap } from 'rxjs';
import { FieldTypeEnum, RestrictionType, RestrictionValueType } from '../../../models/module/fields/enums';
import { DataListFieldPropertiesModel, ScreenFieldModel } from '../../../models/module/fields/main';
import { GridSearchEntityInstance, GridViewModel, SearchViewModel } from '../../../models/module/grid';
import { DataListViewAction } from '../../../models/module/main/dataListViewAction';
import { Currency, CurrencyFn } from '../../../models/system/currency';
import { PRESET_BG_COLOR } from '../../../shared/consts';
import { ItvDateTimeWithRestrictionsPipe } from '../../../shared/pipes/itv-date.pipe';
import { ItvNumberWithFormattingsPipe } from '../../../shared/pipes/itv-number.pipe';
import { LocaleDateAdapter } from '../../../shared/services/date.adapter';
import { LocaleService } from '../../../shared/services/locale.service';
import { CalendarDialogDocDetailPrefix } from './calendar-dialog-doc-detail/calendar-dialog-doc-detail.component';
import {
  CalendarDialogMoreComponent,
  CalendarDialogMoreOuterHeight,
  CalendarDialogMoreOuterWidth,
  CalendarDialogMorePrefix,
} from './calendar-dialog-more/calendar-dialog-more.component';
import {
  CalendarDate,
  CalendarReferenceField,
  CalendarTile,
  CalendarTileDoc,
  CalendarViewMode,
} from './calendar.model';
import { CalendarService } from './calendar.service';

@Component({
  selector: 'app-calendar-base-view',
  template: '<p>calendar base view</p>',
})
export abstract class CalendarBaseViewComponent implements OnChanges, OnDestroy {
  @Input() date: CalendarDate;
  @Input() dateField: CalendarReferenceField;
  @Input() descField: CalendarReferenceField;
  @Input() actions: DataListViewAction[];
  @Input() search: SearchViewModel;
  @Input() contractId: number;
  @Input() screenFields: ScreenFieldModel[];
  @Input() currency: Currency | CurrencyFn;

  @Output() onLoading = new EventEmitter<boolean>();

  @ViewChildren('tileRef') tilesRef: QueryList<ElementRef>;

  tiles: CalendarTile[] = [];
  maxTileDocs = 1;
  isLoading: boolean;
  startDate: Date;
  endDate: Date;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  private loadData$ = new BehaviorSubject<boolean>(false);
  private dataListFields: DataListFieldPropertiesModel[];
  private descDataListField: DataListFieldPropertiesModel;
  private dateDataListField: DataListFieldPropertiesModel;
  private tileTitleHeightPx = 26;
  private tileMoreHeightPx = 34;
  private tileDocHeightPx = 20;

  constructor(
    protected localeService: LocaleService,
    protected dateAdapter: LocaleDateAdapter,
    protected calendarService: CalendarService,
    protected dialog: MatDialog,
    protected itvDateTimePipe: ItvDateTimeWithRestrictionsPipe,
    protected itvNumberPipe: ItvNumberWithFormattingsPipe,
  ) {
    this.loadData$.pipe(distinctUntilChanged(), takeUntil(this.destroy$)).subscribe(load => {
      if (load) {
        this.getData();
      }
    });

    localeService.localeChanged.pipe(takeUntil(this.destroy$)).subscribe(_ => this.loading(true));
  }

  abstract getTiles(empty: boolean, docs?: GridSearchEntityInstance[]): Observable<CalendarTile[]>;

  abstract getTileDocs(tile: CalendarTile, docs: CalendarTileDoc[]): CalendarTileDoc[];

  @HostListener('window:resize')
  onResize() {
    this.loading(true);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.descField && changes.descField.currentValue) ||
      (changes.dateField && changes.dateField.currentValue)
    ) {
      this.getDataListFields().subscribe(fieldsResult => {
        this.descDataListField = fieldsResult.find(f => f.id == this.descField?.id);
        this.dateDataListField = fieldsResult.find(f => f.id == this.dateField?.id);
        this.loading(true);
      });
    } else {
      this.loading(true);
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  showTileDocs(docs: CalendarTileDoc[]): CalendarTileDoc[] {
    const m = this.getMoreTileDocsNumber(docs);
    const reducedDocs = m > 0 ? (docs || []).slice(0, this.maxTileDocs) : docs || [];
    return reducedDocs;
  }

  getMoreTileDocsNumber(docs: CalendarTileDoc[]): number {
    var n = (docs || []).length - this.maxTileDocs;
    return n > 1 ? n : 0;
  }

  more(tile: CalendarTile, moreButton: any): void {
    // find and close all doc detail dialogs
    const differentDocDetailDialogs = this.dialog.openDialogs.filter(
      d => d.id && d.id.startsWith(CalendarDialogDocDetailPrefix),
    );
    for (const dialog of differentDocDetailDialogs) {
      dialog.close();
    }

    // find and close all more dialogs, we will keep only one open
    const id = `${CalendarDialogMorePrefix}-${moment(tile.date).format('YYYY-MM-DD')}`;
    const differentMoreDialogs = this.dialog.openDialogs.filter(
      d => d.id && d.id.startsWith(CalendarDialogMorePrefix) && d.id != id,
    );
    for (const dialog of differentMoreDialogs) {
      dialog.close();
    }

    // open a new more dialog
    this.openDialog(tile, moreButton);
  }

  protected processDocs(viewMode: CalendarViewMode, docs: GridSearchEntityInstance[]): CalendarTileDoc[] {
    const data = (docs || []).map(d => {
      const dt = this.processDate(d);
      if (!dt) {
        return undefined;
      }

      return {
        stateColor: d.entity_template_id ? PRESET_BG_COLOR : d.state_color,
        desc: this.formatDescFieldValue(d),
        time: this.formatTime(dt, viewMode),
        currency: this.currency,
        ref: {
          ...d,
          date: dt.toDate(),
          year: dt.year(),
          month: dt.month(),
          day: dt.date(),
        },
      } as CalendarTileDoc;
    });
    return data.filter(d => !!d).sort((a, b) => a.ref!.date!.getTime() - b.ref!.date!.getTime());
  }

  private loading(value: boolean) {
    setTimeout(() => {
      this.loadData$.next(value);
      this.isLoading = value;
      this.onLoading.emit(value);
    }, 0);
  }

  private processDate(d: GridSearchEntityInstance): moment.Moment | undefined {
    let dateString = this.getFieldValue(d, this.dateField);
    if (!dateString || !this.dateDataListField) {
      return undefined;
    }

    const datetimeFormatting = (this.dateDataListField.restrictions || []).find(f => f.key == RestrictionType.DateTime);
    const handleTimezone = !datetimeFormatting || datetimeFormatting.value == RestrictionValueType.DateTime;

    const hasTimezone = dateString.endsWith('Z');
    if (handleTimezone && !hasTimezone) {
      dateString = dateString + 'Z';
    } else if (!handleTimezone && hasTimezone) {
      dateString = dateString.slice(0, -1);
    }

    const dt = moment(dateString);

    if (!dt.isValid()) {
      return undefined;
    }

    return dt;
  }

  private getDataListFields(): Observable<DataListFieldPropertiesModel[]> {
    if (this.dataListFields) {
      return of(this.dataListFields);
    }
    return this.calendarService.getDataListFields().pipe(
      map(fieldsResult => {
        this.dataListFields = fieldsResult.data;
        return this.dataListFields;
      }),
    );
  }

  private getData() {
    if (!this.loadData$.value) {
      return;
    }

    this.maxTileDocs = 1;

    this.getTiles(true).pipe(
      tap((tiles: CalendarTile[]) => {
        this.tiles = tiles;

        const field = this.getFieldId(this.dateField) || this.getFieldName(this.dateField);
        if (!field) {
          this.loading(false);
          return;
        }

        this.calendarService
          .getData(this.contractId, field, this.startDate, this.endDate, this.search)
          .pipe(
            switchMap((result: GridViewModel<GridSearchEntityInstance[]>) => this.getTiles(false, result.data)),
            tap((tiles: CalendarTile[]) => (this.tiles = tiles)),
          )
          .subscribe(() => {
            this.getTileHeight();
            this.loading(false);
          });
      }),
    ).subscribe();
  }

  private getFieldId(field: CalendarReferenceField) {
    return `${field?.id || ''}`;
  }

  private getFieldName(field: CalendarReferenceField) {
    return field?.name;
  }

  private getFieldValue(d: GridSearchEntityInstance, field: CalendarReferenceField): string {
    return d[this.getFieldId(field)] || d[this.getFieldName(field)] || '';
  }

  private formatTime(dt: moment.Moment, viewMode: CalendarViewMode): string {
    let time = '';
    if (viewMode == CalendarViewMode.Year) {
      time = `${dt.format('DD')} | `;
    } else if (viewMode == CalendarViewMode.Month) {
      const ftime = dt.format('HH:mm');
      time = ftime == '00:00' ? '' : ftime;
    }
    return time;
  }

  private formatDescFieldValue(d: GridSearchEntityInstance): string {
    const prefix = d.full_doc_num;
    let descString = this.getFieldValue(d, this.descField);
    if (!this.descField || !descString) {
      return prefix;
    }
    if (this.descDataListField.typeId == FieldTypeEnum.Date) {
      const dtValue = this.itvDateTimePipe.transform(descString, this.descDataListField.restrictions);
      return this.formatDescFieldValueString(prefix, dtValue);
    } else if (this.descDataListField.typeId == FieldTypeEnum.Number) {
      return this.itvNumberPipe.transform(
        descString,
        this.descDataListField.formattings,
        this.descDataListField.restrictions,
        typeof this.currency == 'function' ? this.currency(d) : this.currency,
      );
    }
    return this.formatDescFieldValueString(prefix, descString);
  }

  private formatDescFieldValueString(prefix: string, descString: string): string {
    return descString ? `${prefix} - ${descString}` : prefix;
  }

  private getTileHeight() {
    if (!this.tilesRef?.length) return;
    const tile = this.tilesRef.first.nativeElement;
    this.maxTileDocs = Math.floor(
      (tile.clientHeight - this.tileTitleHeightPx - this.tileMoreHeightPx) / this.tileDocHeightPx,
    );
  }

  private openDialog(tile: CalendarTile, moreButton: any) {
    const moreButtonRect: DOMRect = moreButton._elementRef.nativeElement.getBoundingClientRect();
    const parentRect: DOMRect = document.body.getBoundingClientRect();

    const width = CalendarDialogMoreOuterWidth,
      height = CalendarDialogMoreOuterHeight,
      margin = 8;
    let top = moreButtonRect.top;
    let left = moreButtonRect.left;

    if (top + height > parentRect.bottom) {
      top = parentRect.bottom - height - margin;
    }
    if (left + width > parentRect.right) {
      left = parentRect.right - width - margin;
    }

    this.dialog.open(CalendarDialogMoreComponent, {
      data: {
        tile: tile,
        screenFields: this.screenFields,
        actions: this.actions,
      },
      hasBackdrop: false,
      autoFocus: false,
      id: `${CalendarDialogMorePrefix}-${moment(tile.date).format('YYYY-MM-DD')}`,
      position: {
        top: `${top}px`,
        left: `${left}px`,
      },
    });
  }
}
