import { MenuModel, RouteCodeEnum } from '../models/menu';

export type CipoTheme = 'default' | 'orange';
export type CipoThemeMode = 'dark' | 'light' | 'browser';
export type Theme = {
  color: CipoTheme;
  mode: CipoThemeMode;
};

// This is still needed, because the overlay is outside the app-root, so the classes don't get applied
export function appendClassesToBody({ theme, mode }: { theme: CipoTheme; mode: CipoThemeMode }) {
  const classesToRemove = Array.from(document.body.classList);

  // Remove existent classes
  classesToRemove.forEach(className => {
    if (className.endsWith('-theme') || className.endsWith('-mode')) {
      document.body.classList.remove(className);
    }
  });

  const themeMode = mode === 'browser' ? getBrowserThemeMode() : mode;

  document.body.classList.add(theme + '-theme', themeMode + '-mode');
}

function getBrowserThemeMode(): CipoThemeMode {
  const darkModeOn = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  return darkModeOn ? 'dark' : 'light';
}

export function getMenuItems(menuItems: MenuModel[]): MenuModel[] {
  return menuItems.reduce((acc: MenuModel[], item) => {
    acc.push(item);
    if (item.children) {
      acc.push(...getMenuItems(item.children));
    }

    return acc;
  }, []);
}

export function findMenuItemByCode(menuItems: MenuModel[], code: RouteCodeEnum): MenuModel | undefined {
  for (const item of menuItems) {
    if (item.code === code) {
      return item;
    }
  }
  return undefined;
}
