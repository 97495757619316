import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { flatMenuItems } from './demo.menu';
import { DevGuard } from '../shared/guards';

const routes: Routes = flatMenuItems.map(({ urlPath, component }) => ({
  path: urlPath,
  component,
  canActivate: [DevGuard],
}));

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DemoRoutingModule {}
