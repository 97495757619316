import { cipo } from 'cipo';

cipo.controller('pspdfkitViewerController',
    function ($scope, $mdDialog, MODAL, module, documentId, fileUrl, mimeType, title, entityInstanceId, fileId, userService, PSPDFKitInstance) {

        /* VARIABLES */
        $scope.module = module || {};
        $scope.documentId = documentId || null;
        $scope.fileUrl = fileUrl || null;
        $scope.fileId = fileId || null;
        $scope.mimeType = mimeType || null;
        $scope.isViewOnly = !$scope.documentId;
        $scope.title = title || '';
        $scope.entityInstanceId = entityInstanceId;
        $scope.modalInfo = MODAL;
        $scope.isBusy = false;
        $scope.setTitle = _setTitle;
        $scope.loadDocument = _loadDocument;
        $scope.close = _close;
        $scope.showClickMessage = false;
        $scope.datetimeFilter = userService.userSettings().datetimeFormat;
        /* END VARIABLES */

        /* INIT */
        // $scope.setTitle($scope.isViewOnly);
        $scope.pspdfkitInstance = new PSPDFKitInstance({
            moduleId: $scope.module.moduleId,
            entityInstanceId: $scope.entityInstanceId,
            currentUserId: userService.system.userdata.id,
            isLoading: function (isLoading) { 
                if (isLoading) {
                    $('.loaderOverlayWrapperGlobal').show();
                }
                else {
                    $('.loaderOverlayWrapperGlobal').hide();
                }
            },
            placeIconCallback: function (isVisible) { 
                $scope.showClickMessage = isVisible; 
                $scope.$apply();
            }
        });
        $scope.loadDocument($scope.isViewOnly);
        /* END INIT */

        /* WATCHERS */
        $scope.$watch(function () {
            return document.querySelector('#pspdfkit').innerHTML;
        },
        function (n, o) {
            if (n != o) {
                n.length > 0 ? $('#pspdfkitLoadingMsg').hide() : $('#pspdfkitLoadingMsg').show();
            }
        });
        /* END WATCHERS */

        // Sets the title of the dialog
        function _setTitle(isViewOnly) {
            if (!isViewOnly)
                $scope.title = `${$scope.module.name} - ${$scope.title}`;
        }

        // Loads the document provided to the viewer
        function _loadDocument(isViewOnly) {
            // If view only, we display the document in view-only mode. Otherwise, we load it via pspdfkit document_id.
            isViewOnly
                ? $scope.pspdfkitInstance.view($scope.fileUrl, $scope.fileId, $scope.mimeType)
                : $scope.pspdfkitInstance.markup($scope.documentId, $scope.fileId);
        }

        // Unloads the document instance and then closes dialog.
        function _close(isViewOnly) {
            $scope.pspdfkitInstance.unload(isViewOnly);
            $scope.pspdfkitInstance.saveDocCovers(isViewOnly, fileId, documentId);
            $mdDialog.cancel();
        }
    });
